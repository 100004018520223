/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CrunchyRollLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 169,
  height: 32,
  viewBox: "0 0 169 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M164.599 24.775V5.94h3.312v18.861h-3.312v-.027ZM157.455 24.775V5.94h3.312v18.861h-3.312v-.027ZM154.663 17.984c0 4.544-2.272 6.98-6.461 6.98-4.188 0-6.46-2.436-6.46-6.98 0-4.544 2.272-6.98 6.46-6.98 4.189 0 6.461 2.436 6.461 6.98Zm-3.148 0c0-3.668-1.232-4.352-3.148-4.352-1.917 0-3.148.684-3.148 4.352s1.231 4.353 3.148 4.353c1.752 0 3.148-.685 3.148-4.353ZM140.346 11.005v2.792c-1.752 0-3.833 0-3.833.876v10.129h-3.312V12.757c.164-2.108 7.145-1.752 7.145-1.752ZM120.445 30.195c2.464-6.105 2.272-5.585 2.464-6.105l-4.9-12.92h3.504l3.148 9.417 2.956-9.417h3.504l-5.229 13.96-1.916 5.065h-3.531ZM116.093 16.59v8.212h-3.313v-8.404c0-1.232-.356-2.436-3.312-2.436a9.148 9.148 0 0 0-2.272.356v10.484h-3.312V5.942h3.312v5.42a6.5 6.5 0 0 1 2.792-.52c3.997.164 6.105 2.244 6.105 5.748ZM97.78 22.173c1.204-.055 2.381-.274 3.503-.712v2.792a9.603 9.603 0 0 1-4.024.684c-4.188 0-6.46-2.436-6.46-6.98 0-4.544 2.272-7.145 6.46-7.145 1.369.055 2.71.274 4.024.712v2.792a10.225 10.225 0 0 0-3.504-.684c-2.272 0-3.668.684-3.668 4.188 0 3.64 1.396 4.353 3.668 4.353ZM88.692 16.233v8.54h-3.148v-8.54c0-1.232 0-2.437-2.793-2.437-.876 0-2.627.165-2.627.685v10.292h-3.148V13.44c0-2.272 2.792-2.627 6.268-2.627 3.531.191 5.448 1.587 5.448 5.42ZM73.855 11.17v7.5c0 4.024-1.396 6.46-6.104 6.46-4.709 0-5.94-2.436-5.94-6.46v-7.5h3.148v7.856c0 2.272.876 2.957 2.792 2.957s2.792-.685 2.792-2.957V11.17h3.312ZM59.21 11.005v2.792c-1.752 0-3.832 0-3.832.876v10.129h-3.313V12.757c.165-2.108 7.145-1.752 7.145-1.752ZM45.578 22.173a9.09 9.09 0 0 0 3.312-.712v2.792a9.602 9.602 0 0 1-4.023.684c-4.189 0-6.46-2.436-6.46-6.98 0-4.544 2.271-7.145 6.46-7.145 1.368.055 2.71.274 4.023.712v2.792c-1.067-.41-2.19-.657-3.312-.684-2.272 0-3.668.684-3.668 4.188-.164 3.64 1.424 4.353 3.668 4.353ZM4.244 17.984c0-7.637 6.186-13.796 13.823-13.796A13.83 13.83 0 0 1 31.81 16.917v-.876c0-8.678-7.035-15.713-15.713-15.713C7.42.328.384 7.391.384 16.068c0 8.678 7.035 15.685 15.712 15.685h1.04C9.91 31.261 4.272 25.238 4.245 17.984Z",
    fill: "#F47521"
  }), _jsx("path", {
    d: "M25.513 18.67a5.059 5.059 0 0 1-5.064-5.01 5.06 5.06 0 0 1 3.312-4.763 10.497 10.497 0 0 0-5.064-1.232c-6.077 0-11.005 4.928-11.005 11.005 0 6.076 4.928 11.004 11.005 11.004 6.077 0 11.004-4.927 11.004-11.005a9.125 9.125 0 0 0-.164-2.107c-.849 1.396-2.41 2.19-4.024 2.107Z",
    fill: "#F47521"
  })]
}), 'CrunchyRollLogo', 'Company');
export default CrunchyRollLogo;