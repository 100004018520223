/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ModeLogo = createCompanyLogosComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 116,
  height: 33,
  viewBox: "0 0 126 36",
  children: _jsx("path", {
    fill: "#37b067",
    d: "M68.96 26.89V9.11H64.8l-4.72 7.54-4.73-7.54h-4.16v17.78h3.82V15.3l3.6 5.51h2.81l3.61-5.51v11.59h3.93zM86.4 18a5.39 5.39 0 0 0-5.4-5.63 5.27 5.27 0 0 0-5.29 5.52 5.45 5.45 0 0 0 5.4 5.62A5.27 5.27 0 0 0 86.4 18m-14.85 0A9.18 9.18 0 0 1 81 8.77a9.09 9.09 0 0 1 9.45 9.12A9.18 9.18 0 0 1 81 27.11 9 9 0 0 1 71.55 18M100 23.4a5.11 5.11 0 0 0 5.4-5.29 5.13 5.13 0 0 0-5.4-5.4h-3V23.4ZM93 9.11h7c5.63 0 9.45 3.83 9.45 8.89 0 4.95-3.82 8.89-9.45 8.89h-7V9.11ZM112.5 9.11v17.78H126V23.4h-9.56v-3.71h8.32V16.2h-8.32v-3.6h9.45V9.11H112.5zM40.95.23 20.48 12.04 0 .23v35.54l10.24-5.96V18l10.24 5.96L30.71 18v11.81l10.24 5.96V.23z"
  })
}), 'ModeLogo', 'Company');
export default ModeLogo;