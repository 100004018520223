/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SlackLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 108,
  height: 32,
  viewBox: "0 0 108 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M34.5496 23.7601L35.8985 20.6646C37.3562 21.7394 39.2925 22.2983 41.2071 22.2983C42.6213 22.2983 43.5133 21.7609 43.5133 20.9441C43.4915 18.6654 35.05 20.4496 34.9847 14.7315C34.9629 11.8294 37.5737 9.59375 41.2724 9.59375C43.4698 9.59375 45.6672 10.1312 47.2337 11.3565L45.9718 14.5165C44.5359 13.6136 42.7518 12.9687 41.0548 12.9687C39.9017 12.9687 39.1402 13.5062 39.1402 14.1941C39.162 16.4297 47.6688 15.2044 47.7558 20.6646C47.7558 23.6312 45.2103 25.7163 41.5552 25.7163C38.8791 25.7163 36.4206 25.0929 34.5496 23.7601Z",
    fill: "black"
  }), _jsx("path", {
    d: "M86.3086 19.5468C85.6342 20.7076 84.3723 21.503 82.9146 21.503C80.7607 21.503 79.0202 19.7832 79.0202 17.655C79.0202 15.5269 80.7607 13.8071 82.9146 13.8071C84.3723 13.8071 85.6342 14.6025 86.3086 15.7633L90.029 13.7211C88.6366 11.2705 85.9605 9.59375 82.9146 9.59375C78.411 9.59375 74.7559 13.2052 74.7559 17.655C74.7559 22.1049 78.411 25.7163 82.9146 25.7163C85.9823 25.7163 88.6366 24.0611 90.029 21.589L86.3086 19.5468Z",
    fill: "black"
  }), _jsx("path", {
    d: "M54.2829 2.9082H49.627V25.4153H54.2829V2.9082Z",
    fill: "black"
  }), _jsx("path", {
    d: "M91.8567 2.9082V25.4153H96.5126V18.6653L102.039 25.4153H108L100.973 17.397L107.478 9.91615H101.778L96.5126 16.1287V2.9082H91.8567Z",
    fill: "black"
  }), _jsx("path", {
    d: "M68.1199 19.5898C67.4455 20.6861 66.053 21.503 64.4866 21.503C62.3327 21.503 60.5921 19.7832 60.5921 17.655C60.5921 15.5269 62.3327 13.8071 64.4866 13.8071C66.053 13.8071 67.4455 14.667 68.1199 15.7848V19.5898ZM68.1199 9.9162V11.7434C67.3584 10.4751 65.4656 9.59375 63.4858 9.59375C59.3955 9.59375 56.1755 13.1622 56.1755 17.6335C56.1755 22.1049 59.3955 25.7163 63.4858 25.7163C65.4656 25.7163 67.3584 24.835 68.1199 23.5667V25.3939H72.7758V9.9162H68.1199Z",
    fill: "black"
  }), _jsx("path", {
    d: "M5.76572 19.5685C5.76572 21.1378 4.48208 22.4061 2.89385 22.4061C1.30561 22.4061 0.0219727 21.1378 0.0219727 19.5685C0.0219727 17.9993 1.30561 16.731 2.89385 16.731H5.76572V19.5685Z",
    fill: "#E01E5A"
  }), _jsx("path", {
    d: "M7.20166 19.5685C7.20166 17.9993 8.4853 16.731 10.0735 16.731C11.6618 16.731 12.9454 17.9993 12.9454 19.5685V26.6625C12.9454 28.2317 11.6618 29.5 10.0735 29.5C8.4853 29.5 7.20166 28.2317 7.20166 26.6625V19.5685Z",
    fill: "#E01E5A"
  }), _jsx("path", {
    d: "M10.0733 8.17515C8.48506 8.17515 7.20142 6.90684 7.20142 5.33757C7.20142 3.76831 8.48506 2.5 10.0733 2.5C11.6615 2.5 12.9452 3.76831 12.9452 5.33757V8.17515H10.0733Z",
    fill: "#36C5F0"
  }), _jsx("path", {
    d: "M10.0733 9.61548C11.6615 9.61548 12.9452 10.8838 12.9452 12.4531C12.9452 14.0223 11.6615 15.2906 10.0733 15.2906H2.87187C1.28364 15.2906 0 14.0223 0 12.4531C0 10.8838 1.28364 9.61548 2.87187 9.61548H10.0733Z",
    fill: "#36C5F0"
  }), _jsx("path", {
    d: "M21.5825 12.4531C21.5825 10.8838 22.8662 9.61548 24.4544 9.61548C26.0426 9.61548 27.3263 10.8838 27.3263 12.4531C27.3263 14.0223 26.0426 15.2906 24.4544 15.2906H21.5825V12.4531Z",
    fill: "#2EB67D"
  }), _jsx("path", {
    d: "M20.1466 12.453C20.1466 14.0223 18.8629 15.2906 17.2747 15.2906C15.6865 15.2906 14.4028 14.0223 14.4028 12.453V5.33758C14.4028 3.76831 15.6865 2.5 17.2747 2.5C18.8629 2.5 20.1466 3.76831 20.1466 5.33758V12.453Z",
    fill: "#2EB67D"
  }), _jsx("path", {
    d: "M17.2747 23.825C18.8629 23.825 20.1466 25.0933 20.1466 26.6625C20.1466 28.2318 18.8629 29.5001 17.2747 29.5001C15.6865 29.5001 14.4028 28.2318 14.4028 26.6625V23.825H17.2747Z",
    fill: "#ECB22E"
  }), _jsx("path", {
    d: "M17.2747 22.4061C15.6865 22.4061 14.4028 21.1378 14.4028 19.5685C14.4028 17.9993 15.6865 16.731 17.2747 16.731H24.4762C26.0644 16.731 27.348 17.9993 27.348 19.5685C27.348 21.1378 26.0644 22.4061 24.4762 22.4061H17.2747Z",
    fill: "#ECB22E"
  })]
}), 'SlackLogo', 'Company');
export default SlackLogo;