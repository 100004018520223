/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StanleySecurityLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 107,
  height: 32,
  viewBox: "0 0 107 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.72604 22.1027C5.10451 21.8609 4.76375 21.751 4.12961 21.751C3.44664 21.751 2.64072 21.9944 2.64072 22.8798C2.64072 24.5062 6.3001 24.1309 6.3001 26.9207C6.3001 28.7278 4.90341 29.4465 3.18964 29.4465C2.59181 29.4465 2.00666 29.2756 1.44657 29.0934L1.56808 27.9647C1.98706 28.1357 2.71476 28.3818 3.33629 28.3818C4.03468 28.3818 5.01231 27.9939 5.01231 26.9917C5.01231 25.0984 1.35437 25.6197 1.35437 22.9854C1.35437 21.4814 2.54852 20.6793 4.08632 20.6793C4.70367 20.684 5.31609 20.7891 5.89927 20.9907L5.72885 22.1027",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M12.6173 29.053C12.1773 29.2115 11.6773 29.4548 10.6535 29.4548C8.44673 29.4548 7.43408 28.1189 7.43408 26.1047C7.43408 24.2712 8.59193 22.9728 10.3491 22.9728C12.4217 22.9728 13.0922 24.4783 13.0922 26.4689H8.65345C8.64836 26.7392 8.69772 27.0078 8.79865 27.2588C8.89951 27.5098 9.04988 27.7381 9.2408 27.9303C9.4318 28.1226 9.65948 28.2748 9.91048 28.3782C10.1616 28.4815 10.4308 28.5336 10.7024 28.5317C11.458 28.5317 12.3365 28.144 12.6173 27.9132V29.053ZM11.8728 25.6182C11.8728 24.6716 11.3142 23.8959 10.3238 23.8959C9.20647 23.8959 8.73858 24.8175 8.64783 25.6182H11.8728Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M18.5799 24.1602C18.1868 23.9922 17.7646 23.9015 17.3368 23.8933C16.0561 23.8933 15.3367 25.0332 15.3367 26.2105C15.3367 27.3157 15.9346 28.5292 17.4095 28.5292C17.8446 28.5258 18.2705 28.4042 18.6414 28.1775L18.7266 29.1964C18.2705 29.3817 17.7802 29.4689 17.288 29.4522C15.4094 29.4522 14.1174 27.9829 14.1174 26.2105C14.1174 24.3298 15.3856 22.9703 17.288 22.9703C17.7638 22.9735 18.2359 23.0558 18.6847 23.2135L18.5869 24.1602",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M25.5437 29.306H24.4347V28.3219H24.4096C24.0688 29.0266 23.2391 29.452 22.3732 29.452C20.7628 29.452 20.0435 28.4567 20.0435 26.7928V23.116H21.1902V26.3201C21.1902 27.7644 21.5072 28.4678 22.5072 28.529C23.8118 28.529 24.3971 27.485 24.3971 25.9796V23.116H25.5437V29.306Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M27.4951 23.1159H28.5692V24.0751H28.5929C28.8974 23.4315 29.5566 22.97 30.2145 22.97C30.4693 22.9684 30.7234 22.9969 30.9716 23.0548V24.0751C30.7692 23.998 30.5539 23.9607 30.3374 23.9652C29.3122 23.9652 28.6418 24.9383 28.6418 26.4896V29.3059H27.4951V23.1159Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M33.6055 21.7316H32.3121V20.4457H33.6055V21.7316ZM32.3862 23.1216H33.5315V29.3117H32.3862V23.1216Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M38.9356 24.0388H37.2888V27.4237C37.2888 28.141 37.7274 28.5357 38.2526 28.5357C38.5286 28.5397 38.7998 28.4639 39.0334 28.3176V29.2906C38.7171 29.3926 38.388 29.4502 38.0557 29.4616C36.8727 29.4616 36.1408 28.9055 36.1408 27.6642V24.05H34.744V23.127H36.1408V21.6855L37.2875 21.3227V23.1186H38.9342V24.0417",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M39.9106 30.9811C40.0833 31.0406 40.2643 31.073 40.4469 31.077C41.4722 31.077 41.8129 29.6703 41.8129 29.5479C41.8129 29.4256 41.6733 29.0989 41.5937 28.8932L39.4846 23.116H40.7417L42.4177 28.237H42.4429L44.1497 23.116H45.3075L43.0155 29.4395C42.5881 30.628 42.137 32 40.6258 32C40.3632 31.9988 40.1016 31.9704 39.845 31.9152L39.9176 30.9811",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M1.34854 11.9369C2.77273 12.6872 4.36027 13.0781 5.97166 13.0753C7.29993 13.0753 8.83211 12.8126 8.83211 11.6157C8.83211 8.82446 0.904417 10.4258 0.904417 4.72657C0.904417 0.962235 4.39619 0.000246728 7.54857 0.000246728C9.17804 -0.00886661 10.799 0.234535 12.3533 0.721719L11.9622 4.07877C10.8574 3.62424 9.45789 3.1669 7.87954 3.1669C6.81951 3.1669 5.18389 3.23639 5.18389 4.60279C5.18389 7.06878 13.1536 5.79276 13.1536 11.2613C13.1536 15.3175 9.46062 16.2433 5.97166 16.2433C4.24332 16.2382 2.52492 15.982 0.87085 15.483L1.34998 11.9369H1.34854ZM27.4545 0.300531H14.25V3.47547H18.76V15.9389H22.9501V3.47547H27.4573L27.4545 0.300531ZM101.642 0.300531L98.6015 6.57393L95.5167 0.300531H90.698L96.5062 10.0463V15.9417H100.697V10.0463L106.463 0.300531H101.642ZM45.5084 0.300531V15.9417H49.5393V7.50667L56.3762 15.9361H59.8023V13.5869L48.9555 0.300531H45.5084ZM59.8023 8.22397V0.300531H53.3872L59.8023 8.22397ZM81.6188 9.61406H88.2237V6.49335H81.6188V3.37537H88.8821V0.300531H77.3242V15.9417H89.1236V12.8223H81.6188V9.61406ZM67.92 0.300531H63.6251V15.9417H74.7922V12.7195H67.92V0.300531ZM37.7664 12.8112H31.8849L30.8178 15.9361H26.731L32.4925 0.300531H37.3586L43.2052 15.9417H38.7958L37.7664 12.8112ZM36.8432 9.8545L34.8878 3.57419L32.85 9.8545H36.8432Z",
    fill: "#231F20"
  })]
}), 'StanleySecurityLogo', 'Company');
export default StanleySecurityLogo;