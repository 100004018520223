/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CraftJackLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 113,
  height: 33,
  viewBox: "0 0 113 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.128 7.85376C12.4583 7.85376 10.4697 7.87662 9.20341 9.24347C8.40798 10.112 8.33484 11.072 8.33484 12.496V22.0252C8.33484 22.9966 8.33484 24.3612 9.72455 25.28C10.7554 25.9612 12.2091 26.1395 13.128 26.1395C17.896 26.1395 17.896 23.0835 17.896 21.6709V19.1818H14.696V21.4675C14.696 22.5349 14.696 23.6275 13.1828 23.6275C11.6697 23.6275 11.5691 22.6835 11.5691 21.6412V12.2538C11.5691 11.4583 11.6423 10.3406 13.0594 10.3406C14.4766 10.3406 14.6983 11.0858 14.6983 12.3978V14.6332H17.8983V11.9315C17.896 10.688 17.896 7.85376 13.128 7.85376Z",
    fill: "black"
  }), _jsx("path", {
    d: "M29.8274 23.3967V19.8264C29.8274 17.4675 28.2137 16.9944 27.4457 16.7978C28.4811 16.5692 29.1326 16.3132 29.4571 15.5224C29.6491 14.9761 29.7337 14.3955 29.7063 13.8172V11.9315C29.7063 10.8618 29.7063 9.57724 28.792 8.82981C28.3348 8.45038 27.6263 8.20581 26.5611 8.20581H20.0948V25.7624H23.3268V18.0412H24.8674C26.5566 18.0412 26.5566 18.8847 26.5566 20.0755V23.8264C26.552 24.5007 26.7143 25.1658 27.0274 25.7624H30.5726C29.9028 25.2252 29.8274 24.2972 29.8274 23.3967ZM26.4994 13.8424C26.4994 14.7384 26.4743 15.5315 25.0091 15.5315H23.3268V10.7315H25.016C25.736 10.7315 26.5063 10.7315 26.5063 12.1715L26.4994 13.8424Z",
    fill: "black"
  }), _jsx("path", {
    d: "M38.8697 8.23096H35.16L31.7566 25.7624H34.984L35.4572 22.9098H38.4286L38.8697 25.7624H42.1383L38.8697 8.23096ZM35.8572 20.1967L37.0229 12.6721L38.0903 20.1967H35.8572Z",
    fill: "black"
  }), _jsx("path", {
    d: "M43.9417 25.7601H47.1966V17.9407H51.6057V15.3327H47.1966V10.935H51.9051V8.23096H43.9417V25.7601Z",
    fill: "black"
  }), _jsx("path", {
    d: "M63.0571 8.23096H53.3474V10.9441H56.5748V25.7601H59.8274V10.9441H63.0571V8.23096Z",
    fill: "black"
  }), _jsx("path", {
    d: "M67.32 21.7075C67.32 22.4755 67.32 23.5201 66.1771 23.5201C65.0343 23.5201 65.0114 22.551 65.0114 21.8058V20.1418H62.04V22.5007C62.04 24.5372 62.9543 26.0778 66.136 26.0778C70.5566 26.0778 70.5794 23.2732 70.5794 21.735V8.23096H67.32V21.7075Z",
    fill: "black"
  }), _jsx("path", {
    d: "M78.9451 8.23096H75.2468L71.8457 25.7624H75.0731L75.5417 22.9098H78.5131L78.936 25.7624H82.216L78.9451 8.23096ZM75.944 20.1967L77.1097 12.6721L78.184 20.1967H75.944Z",
    fill: "black"
  }), _jsx("path", {
    d: "M88.3509 7.85366C87.4778 7.83766 86.6069 7.99309 85.7932 8.3108C85.2767 8.52109 84.8081 8.8388 84.4218 9.24109C83.6287 10.1097 83.5532 11.0697 83.5532 12.4937V22.0251C83.5532 22.9965 83.5532 24.3611 84.9429 25.2799C85.9624 25.9519 87.4275 26.1234 88.3464 26.1234C93.1144 26.1234 93.1144 23.0674 93.1144 21.6548V19.1817H89.9144V21.4674C89.9144 22.5348 89.9144 23.6274 88.3989 23.6274C86.8835 23.6274 86.7852 22.6834 86.7852 21.6411V12.2537C86.7852 11.4582 86.8584 10.3405 88.2755 10.3405C89.6927 10.3405 89.9167 11.0857 89.9167 12.3977V14.6331H93.1167V11.9314C93.1167 10.7885 93.1052 8.3428 89.4595 7.9268C89.0915 7.88109 88.7212 7.85595 88.3509 7.85366Z",
    fill: "black"
  }), _jsx("path", {
    d: "M98.488 17.1955L101.914 25.7601H105.416L101.318 15.8058L105.293 8.23096H101.987L98.488 15.0584V8.23096H95.208V25.7601H98.488V17.1955Z",
    fill: "black"
  }), _jsx("path", {
    d: "M0.0125732 0.996582V32.9966H112.47V0.996582H0.0125732ZM108.584 28.8823H4.12686V5.11087H108.584V28.8823Z",
    fill: "black"
  })]
}), 'CraftJackLogo', 'Company');
export default CraftJackLogo;