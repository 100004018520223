/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var McKinseyCompanyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 104,
  height: 33,
  viewBox: "0 0 104 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M1.62429 2.49922C1.62429 2.24911 0.932795 1.59072 0.701071 1.59072H0.108887V0.998535H3.60314L7.19302 10.0431H7.27762L11.0661 0.998535H14.1962V1.59072H13.6041C13.3723 1.59072 12.6808 2.24911 12.6808 2.49922V11.4923C12.6808 11.7424 13.3723 12.4008 13.6041 12.4008H14.1962V12.993H9.55073V12.4008H10.1429C10.3746 12.4008 11.0661 11.7424 11.0661 11.4923V2.76037L6.70015 13.2064L2.33417 2.58014V11.4923C2.33417 11.7424 3.02567 12.4008 3.25739 12.4008H3.84958V12.993H0.108887V12.4008H0.701071C0.932795 12.4008 1.62429 11.7424 1.62429 11.4923V2.49922Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M19.235 7.8694C19.452 6.66664 19.235 5.5779 17.9182 5.5779C16.3697 5.5779 15.612 6.81376 15.612 8.64181C15.612 10.3742 16.7155 11.8087 18.4957 11.8087C19.8456 11.8087 20.4231 11.3305 20.9674 10.422H21.4419C20.9307 12.1214 19.8934 13.1917 17.9477 13.1917C15.7886 13.1917 14.3872 11.3526 14.3872 9.14572C14.3872 6.64089 16.0534 4.99307 18.0948 4.99307C18.6055 4.97026 19.1153 5.05692 19.5898 5.24723C20.0643 5.43754 20.4926 5.72711 20.8461 6.09652L19.7095 7.85836L19.235 7.8694Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M34.9298 3.37093C35.0634 3.37722 35.1968 3.35556 35.3214 3.30732C35.4461 3.25909 35.5593 3.18534 35.6539 3.09082C35.7484 2.99629 35.8221 2.88306 35.8704 2.75838C35.9186 2.6337 35.9403 2.50033 35.934 2.36679C35.9415 2.23313 35.9206 2.09939 35.8728 1.97436C35.8249 1.84933 35.7511 1.73584 35.6563 1.64135C35.5615 1.54686 35.4477 1.47351 35.3225 1.42611C35.1973 1.37871 35.0635 1.35834 34.9298 1.36633C34.7961 1.35753 34.662 1.37683 34.5362 1.42299C34.4103 1.46916 34.2956 1.54116 34.1992 1.63436C34.1029 1.72756 34.0272 1.8399 33.9769 1.96415C33.9266 2.0884 33.9029 2.22179 33.9073 2.35576C33.9009 2.49139 33.9231 2.62685 33.9723 2.7534C34.0216 2.87994 34.0968 2.99475 34.1931 3.09042C34.2895 3.18608 34.4049 3.26048 34.5317 3.30879C34.6586 3.35711 34.7943 3.37828 34.9298 3.37093Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M45.9682 12.4008C45.7365 12.4008 45.1112 11.8381 45.1112 11.5769V7.25141C45.1253 6.96104 45.0797 6.67085 44.9771 6.39886C44.8744 6.12686 44.717 5.87884 44.5146 5.67019C44.3122 5.46155 44.069 5.29672 43.8002 5.18592C43.5315 5.07513 43.2428 5.02074 42.9521 5.02612C41.5507 5.02612 40.8261 5.83532 39.8882 6.78796L39.5903 5.19164H37.1369V5.78014H37.6261C37.8579 5.78014 38.4831 6.35762 38.4831 6.60405V11.5769C38.4831 11.8381 37.8579 12.4008 37.6261 12.4008H36.6698C36.4454 12.4008 35.7871 11.8381 35.7871 11.5769V5.18428H33.0174V5.78014H33.4956C33.7273 5.78014 34.3526 6.35762 34.3526 6.60405V11.5769C34.3526 11.8381 33.7273 12.4008 33.4956 12.4008H32.6238C32.3737 12.4008 31.4027 11.6652 31.2041 11.3967L27.0036 5.80957L29.9204 2.67945C30.0859 2.49922 31.2225 1.57601 31.4542 1.57601H32.028V0.998535H27.6951V1.59072H28.2726C28.5006 1.59072 29.1774 2.41463 29.0082 2.62796L24.7231 7.40957V2.49922C24.7231 2.24911 25.4146 1.59072 25.6464 1.59072H26.2238V0.998535H21.5342V1.59072H22.1264C22.3581 1.59072 23.0496 2.24911 23.0496 2.49922V11.4923C23.0496 11.7424 22.3581 12.4008 22.1264 12.4008H21.5342V12.993H26.2238V12.4008H25.6206C25.3889 12.4008 24.6974 11.7424 24.6974 11.4923V8.3144L25.9149 6.99762L30.0675 12.993H41.205V12.4008H40.7599C40.5319 12.4008 39.9029 11.8381 39.9029 11.5769V7.61922C39.9029 7.09325 41.3374 6.00451 42.4408 6.00451C43.2831 6.00451 43.6767 6.58198 43.6767 7.33968V11.5769C43.6767 11.8381 43.0514 12.4008 42.8197 12.4008H42.3746V12.993H46.3618V12.4008H45.9682Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M47.4135 10.2564C47.4135 10.992 48.4691 12.5479 50.15 12.5479C51.1211 12.5479 51.6654 12.2022 51.6654 11.4776C51.6654 10.6058 50.8893 10.3079 50.1942 10.0063C49.6498 9.77826 49.2084 9.59435 48.5795 9.31481C47.6415 8.88814 46.9316 8.25918 46.9316 7.18883C46.9316 5.79113 47.7739 4.98193 49.271 4.98193C50.128 4.98193 50.6061 5.39389 51.202 5.39389C51.43 5.39389 51.5698 5.34607 51.6287 5.18055H52.0553V7.58607H51.6029C51.6029 6.94239 50.6944 5.57412 49.2452 5.57412C48.3404 5.57412 47.91 5.97136 47.91 6.61504C47.91 7.25872 48.3735 7.53458 49.179 7.88032C49.7712 8.13044 50.2162 8.32538 50.8599 8.5755C51.6985 8.90285 52.6549 9.57963 52.6549 10.8302C52.6549 12.3015 51.636 13.1879 50.1684 13.1879C49.179 13.1879 48.6015 12.7245 47.9284 12.7245C47.8152 12.7178 47.702 12.7383 47.5983 12.7844C47.4946 12.8305 47.4035 12.9007 47.3326 12.9893H46.9647V10.2564H47.4135Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M53.1187 9.09047C53.1187 6.78426 54.6819 4.98932 56.6938 5.00771C58.4557 5.00771 59.4451 6.22518 59.526 7.74058L59.5591 8.29966L54.3692 8.72265C54.3692 10.2895 55.4727 11.8381 57.2198 11.8381C58.555 11.8381 59.1656 11.3268 59.6437 10.4367H60.1219C59.6106 12.136 58.6506 13.1879 56.7269 13.1879C54.5458 13.1732 53.1187 11.2974 53.1187 9.09047ZM58.2239 7.8215C58.3564 6.71805 58.1761 5.59621 56.6607 5.59621C55.046 5.59621 54.4023 6.93139 54.3545 8.14886L58.2239 7.8215Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M60.8941 6.66662C60.7764 6.4018 60.0849 5.77651 59.8863 5.77651H59.5405V5.18433H63.6785V5.78019H63.2003C62.9833 5.78019 62.5088 6.32456 62.5088 6.62249L64.3185 10.7898L66.0987 6.6372C66.0699 6.44791 65.9924 6.26936 65.8737 6.1191C65.7551 5.96885 65.5994 5.85205 65.4219 5.78019H64.9474V5.18433H68.0591V5.78019H67.6913C67.5074 5.78019 66.9557 6.37237 66.7828 6.6372L64.1309 12.6179L62.1704 16.9361L61.4605 16.605L63.5387 12.3531L60.8941 6.66662Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M23.2815 19.1871C23.2815 20.1434 23.9399 20.9159 24.9771 21.3131C26.5256 22.9756 28.3868 24.7375 30.0199 26.1903C30.4654 25.4146 30.6798 24.5276 30.6378 23.634C30.6378 22.5306 29.9022 21.8722 28.7435 21.8391V21.2469H32.9992V21.8391H32.5394C32.3077 21.8391 31.4838 22.777 31.4691 23.1411C31.4409 24.3456 31.0695 25.517 30.3987 26.5177C31.1794 27.2086 31.9959 27.8581 32.8447 28.4634H33.5803V29.0556H32.2599C31.7061 29.073 31.1548 28.9747 30.6411 28.767C30.1275 28.5593 29.6628 28.2467 29.2769 27.8492C28.3205 28.7687 27.1031 29.28 25.3376 29.28C23.3955 29.28 21.5343 27.8492 21.5343 25.7379C21.5281 25.0187 21.701 24.3092 22.0375 23.6735C22.374 23.0378 22.8634 22.4958 23.4617 22.0965C22.8444 21.4073 22.4992 20.5169 22.4907 19.5917C22.4907 17.8814 23.5941 16.925 25.3707 16.925C26.4741 16.925 27.0516 17.2929 27.5776 17.2929C27.8424 17.2929 28.0079 17.2929 28.1366 17.0795H28.6479V19.9154H28.1219C28.1219 19.1062 26.9376 17.4915 25.3376 17.4915C24.0539 17.4731 23.2815 18.0984 23.2815 19.1871ZM22.7702 24.9545C22.7702 26.6685 24.6093 28.3126 26.3123 28.3126C27.2447 28.3422 28.1603 28.0587 28.9127 27.5071C27.0722 25.9281 25.3395 24.2274 23.7265 22.4165C23.0976 23.0676 22.7702 23.7186 22.7702 24.9545Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M45.8726 21.3129C45.8726 20.077 44.4896 17.5722 42.2349 17.5722C40.1568 17.5722 38.9246 18.7235 38.7076 21.4269C38.6377 22.4426 38.6487 23.4622 38.7407 24.4761C38.9393 26.569 40.3885 28.2977 42.8271 28.2977C44.9016 28.2977 45.9057 27.426 46.6818 25.8444H47.3071C46.5347 27.9851 44.8538 29.2688 42.463 29.2688C39.0202 29.2688 36.7949 26.4366 36.7949 23.0159C36.7949 19.5952 38.8069 16.855 42.1687 16.855C43.4524 16.855 44.2616 17.381 45.1995 17.381C45.3385 17.3949 45.4788 17.3735 45.6074 17.3189C45.736 17.2642 45.8487 17.178 45.9352 17.0683H46.3802V21.3129H45.8726Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M48.1162 25.1492C48.1162 22.6297 49.9295 21.0481 51.9893 21.0481C54.0491 21.0481 55.8587 22.6297 55.8587 25.1492C55.8587 27.6688 54.0491 29.2688 51.9893 29.2688C49.9295 29.2688 48.1162 27.6725 48.1162 25.1492ZM54.2955 26.4513C54.3948 25.5701 54.3948 24.6806 54.2955 23.7994C54.1447 22.5488 53.4532 21.7065 52.004 21.7065C50.5548 21.7065 49.8964 22.5488 49.7162 23.7846C49.5985 24.6647 49.5985 25.5565 49.7162 26.4366C49.8964 27.6394 50.5695 28.5295 52.004 28.5295C53.4385 28.5295 54.1447 27.6725 54.2955 26.4513Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M68.4932 27.6395C68.4932 27.9006 69.0854 28.4634 69.3172 28.4634H69.685V29.0556H65.7935V28.4634H66.2385C66.4666 28.4634 67.0588 27.9006 67.0588 27.6395V23.3066C67.0588 22.5489 66.6909 22.0192 65.8413 22.0192C64.8188 22.0192 63.5498 23.0087 63.5498 23.5346V27.6395C63.5498 27.9006 64.1457 28.4634 64.3737 28.4634H64.8188V29.0556H60.85V28.4634H61.2951C61.5231 28.4634 62.119 27.9006 62.119 27.6395V23.3066C62.119 22.5489 61.7217 22.0192 60.8831 22.0192C59.8606 22.0192 58.5916 23.0601 58.5916 23.5861V27.6321C58.5916 27.8933 59.1838 28.456 59.4155 28.456H59.8606V29.0482H55.8882V28.4634H56.3332C56.565 28.4634 57.1572 27.9006 57.1572 27.6395V22.6629C57.087 22.4745 56.977 22.3034 56.8349 22.1613C56.6927 22.0191 56.5216 21.9092 56.3332 21.839H55.8882V21.2468H58.279L58.5916 22.7438C59.4817 21.8059 60.0629 21.0813 61.4275 21.0813C62.4647 21.0813 63.3365 21.6404 63.4836 22.7291C64.4399 21.7912 65.0505 21.0997 66.3673 21.0997C66.6563 21.0891 66.9443 21.1395 67.2124 21.2478C67.4806 21.356 67.7229 21.5196 67.9236 21.7279C68.1242 21.9362 68.2786 22.1845 68.3768 22.4565C68.475 22.7285 68.5146 23.0182 68.4932 23.3066V27.6395Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M71.9839 21.2467L72.2487 22.843C72.9218 21.5925 73.7788 21.0481 75.0662 21.0481C76.9604 21.0481 78.1117 22.6481 78.1117 25.0352C78.1117 27.4223 76.7949 29.221 74.9007 29.221C73.5324 29.221 72.8078 28.6913 72.2487 27.6872V31.4132C72.2487 31.6743 72.8556 32.2371 73.0726 32.2371H73.5839V32.8292H69.4644V32.2371H69.994C70.2221 32.2371 70.8179 31.6743 70.8179 31.4132V22.6628C70.8179 22.4164 70.2221 21.8389 69.994 21.8389H69.4827V21.2467H71.9839ZM72.2303 24.0973V26.1387C72.2269 26.4362 72.2829 26.7314 72.3952 27.007C72.5075 27.2825 72.6737 27.5329 72.8841 27.7433C73.0945 27.9537 73.3448 28.1199 73.6204 28.2322C73.896 28.3445 74.1912 28.4005 74.4887 28.3971C75.8239 28.3971 76.4492 27.5548 76.6147 26.3189C76.714 25.5484 76.714 24.7684 76.6147 23.998C76.4492 22.7621 75.7761 21.9713 74.6542 21.9713C73.3522 21.964 72.6092 22.8761 72.2303 24.0973Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M95.0275 28.4634C94.7957 28.4634 94.1704 27.9007 94.1704 27.6395V23.3067C94.182 23.0178 94.1345 22.7296 94.0307 22.4598C93.9269 22.19 93.7691 21.9442 93.5669 21.7375C93.3647 21.5309 93.1225 21.3677 92.855 21.2581C92.5875 21.1484 92.3004 21.0945 92.0114 21.0998C90.6137 21.0998 89.8854 21.9089 88.9475 22.8616L88.6532 21.2653H86.1962V21.8575H86.6744C86.9061 21.8575 87.5314 22.4349 87.5314 22.6814V27.6395C87.5314 27.9007 86.9061 28.4634 86.6744 28.4634H85.7364C85.5194 28.4634 84.8794 27.9007 84.8794 27.6395V23.3876C84.8794 21.8575 83.6436 21.0667 81.9626 21.0667C80.381 21.0667 79.4725 21.6073 78.8325 22.2694L79.8698 23.8326H80.4288C79.855 22.4975 80.2817 21.6257 81.6169 21.6257C83.0183 21.6257 83.456 22.2547 83.456 23.3581V24.3292L81.4477 24.7411C80.3442 24.9729 78.9281 25.5135 78.9281 27.0657C78.9127 27.3361 78.9522 27.6069 79.0441 27.8616C79.136 28.1164 79.2786 28.3499 79.4631 28.5482C79.6476 28.7464 79.8703 28.9053 80.1178 29.0153C80.3653 29.1252 80.6325 29.184 80.9033 29.188C82.0583 29.188 82.8822 28.2832 83.5075 27.474L83.7061 29.0409H90.2937V28.4634H89.8523C89.6206 28.4634 88.9953 27.9007 88.9953 27.6395V23.6671C88.9953 23.1411 90.4261 22.0524 91.5295 22.0524C92.3718 22.0524 92.7654 22.6299 92.7654 23.3876V27.6395C92.7654 27.9007 92.1401 28.4634 91.9084 28.4634H91.467V29.0556H95.4541V28.4634H95.0275ZM83.4449 26.7641C83.1139 27.3416 82.29 28.1324 81.5323 28.1324C81.3665 28.138 81.2014 28.1095 81.0471 28.0487C80.8928 27.9878 80.7527 27.8959 80.6355 27.7787C80.5182 27.6614 80.4263 27.5213 80.3654 27.367C80.3046 27.2127 80.2761 27.0476 80.2817 26.8818C80.2817 26.1057 80.6127 25.5798 81.5838 25.3333L83.4449 24.8552V26.7641Z",
    fill: "#051C2C"
  }), _jsx("path", {
    d: "M95.9949 22.7291C95.8809 22.4643 95.1894 21.839 94.9908 21.839H94.6377V21.2468H98.7719V21.839H98.3159C98.1025 21.839 97.6244 22.3834 97.6244 22.6813L99.4193 26.8487L101.203 22.6997C101.172 22.5103 101.093 22.332 100.974 22.1815C100.855 22.0309 100.7 21.9131 100.523 21.839H100.045V21.2468H103.16V21.839H102.792C102.612 21.839 102.057 22.4312 101.887 22.696L99.2317 28.6767L97.2786 32.9986L96.5724 32.6675L98.6469 28.4156L95.9949 22.7291Z",
    fill: "#051C2C"
  })]
}), 'McKinseyCompanyLogo', 'Company');
export default McKinseyCompanyLogo;