/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var IntegraBeautyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 61,
  height: 32,
  viewBox: "0 0 61 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#IntegraBeautyLogo_svg__clip0_11741_136401)",
    children: [_jsx("path", {
      d: "M1.00651 0.0908203H0.186768V13.2665H1.00651V0.0908203Z",
      fill: "black"
    }), _jsx("path", {
      d: "M16.7137 13.2665H17.5334V0.910202H21.6331V0.0908203H12.614V0.910202H16.7137V13.2665Z",
      fill: "black"
    }), _jsx("path", {
      d: "M40.6211 19.2885H44.7217V31.6448H45.5415V19.2885H49.6411V18.4691H40.6211V19.2885Z",
      fill: "black"
    }), _jsx("path", {
      d: "M22.4644 13.2665H29.5566V12.4471H23.2841V7.09026H28.5324V6.27088H23.2841V0.910202H29.5566V0.0908203H22.4644V13.2665Z",
      fill: "black"
    }), _jsx("path", {
      d: "M11.6119 13.2665H11.7074V0.0908203H10.8877V12.0991L3.42802 0.0908203H2.46362V13.2665H3.28336V1.41051L10.661 13.2877H11.6254L11.6119 13.2665Z",
      fill: "black"
    }), _jsx("path", {
      d: "M35.9237 7.07779H39.3232V12.1589C38.6237 12.4099 37.886 12.538 37.1427 12.5378C33.7374 12.5378 30.9706 9.90899 30.9706 6.6787C30.9706 3.44841 33.7374 0.818676 37.1427 0.818676C37.9616 0.816589 38.7733 0.970689 39.5344 1.27271L39.6685 1.32669L39.9723 0.56515L39.8382 0.512131C38.9807 0.17084 38.0657 -0.00323762 37.1427 -0.000705184C33.2851 -0.000705184 30.147 2.99534 30.147 6.6787C30.147 10.3621 33.2851 13.3571 37.1427 13.3571C38.1451 13.3566 39.1367 13.1506 40.0562 12.7518L40.143 12.7142V6.25551H35.9237V7.07779Z",
      fill: "black"
    }), _jsx("path", {
      d: "M47.1529 7.09033H47.3265C48.8493 7.09033 50.0876 5.52001 50.0876 3.59012C50.0876 1.66024 48.8464 0.0957031 47.3236 0.0957031H41.6423V13.2665H42.4621V7.09033H46.2126L49.643 13.2665H50.5804L47.1529 7.09033ZM42.465 6.27095V0.910264H47.3265C48.397 0.910264 49.2678 2.11234 49.2678 3.59012C49.2678 5.0679 48.3999 6.27095 47.3265 6.27095H42.465Z",
      fill: "black"
    }), _jsx("path", {
      d: "M59.912 0.0957384V0.0610352L59.8927 0.0909185H59.2582L50.6335 13.2666H51.6134L53.8315 9.87915H59.6227V13.2666H60.4425V0.0957384H59.912ZM54.3667 9.06073L59.6227 1.03272V9.06073H54.3667Z",
      fill: "black"
    }), _jsx("path", {
      d: "M28.2817 18.4393L28.2624 18.4692H27.6288L19.0032 31.6449H19.983L22.2011 28.2575H27.9933V31.6449H28.813V18.4692H28.2826L28.2817 18.4393ZM22.7364 27.4381L27.9923 19.4101V27.4381H22.7364Z",
      fill: "black"
    }), _jsx("path", {
      d: "M54.5604 24.2848L50.9429 18.4691H49.9775L54.1505 25.1774V31.5387H54.9702V25.1774L59.1422 18.4691H58.1769L54.5604 24.2848Z",
      fill: "black"
    }), _jsx("path", {
      d: "M11.6042 31.6448H18.6955V30.8254H12.424V25.4686H17.6713V24.6492H12.424V19.2885H18.6955V18.4691H11.6042V31.6448Z",
      fill: "black"
    }), _jsx("path", {
      d: "M38.9757 27.2423C38.9838 27.7556 38.8897 28.2655 38.6988 28.7421C38.5078 29.2187 38.2239 29.6526 37.8636 30.0185C37.5033 30.3843 37.0738 30.6749 36.6 30.8733C36.1263 31.0716 35.6178 31.1737 35.1042 31.1737C34.5905 31.1737 34.082 31.0716 33.6083 30.8733C33.1345 30.6749 32.705 30.3843 32.3447 30.0185C31.9844 29.6526 31.7005 29.2187 31.5095 28.7421C31.3186 28.2655 31.2245 27.7556 31.2326 27.2423V18.4701H30.4128V27.2423C30.4039 27.8637 30.5187 28.4808 30.7504 29.0575C30.9822 29.6343 31.3263 30.1592 31.7629 30.6018C32.1994 31.0444 32.7195 31.3959 33.2932 31.6358C33.8668 31.8757 34.4824 31.9993 35.1042 31.9993C35.726 31.9993 36.3415 31.8757 36.9152 31.6358C37.4888 31.3959 38.0089 31.0444 38.4455 30.6018C38.882 30.1592 39.2261 29.6343 39.4579 29.0575C39.6896 28.4808 39.8044 27.8637 39.7955 27.2423V18.4701H38.9757V27.2423Z",
      fill: "black"
    }), _jsx("path", {
      d: "M8.26555 24.8353C8.86612 24.4163 9.32001 23.8195 9.56325 23.1289C9.80648 22.4384 9.8268 21.689 9.62134 20.9863C9.41587 20.2836 8.99499 19.6631 8.418 19.2122C7.84101 18.7613 7.13701 18.5028 6.40522 18.473H2.16187V31.6448H7.83254V31.5725C8.58457 31.4182 9.26501 31.021 9.76905 30.4422C10.2731 29.8634 10.5728 29.1349 10.622 28.3691C10.6712 27.6033 10.4672 26.8426 10.0414 26.204C9.61564 25.5655 8.99165 25.0845 8.26555 24.8353ZM9.80859 28.1446C9.80757 28.8546 9.52521 29.5353 9.02329 30.0378C8.52137 30.5402 7.84078 30.8234 7.13045 30.8254H2.98353V25.4686H7.27511C7.9602 25.5061 8.6048 25.8048 9.07613 26.3032C9.54746 26.8016 9.80962 27.4617 9.80859 28.1475V28.1446ZM8.94063 21.9684C8.93961 22.6784 8.65725 23.3591 8.15533 23.8616C7.65341 24.364 6.97282 24.6472 6.26249 24.6492H2.98353V19.2885H6.26249C6.97307 19.2898 7.65416 19.5726 8.15652 20.0749C8.65888 20.5773 8.94154 21.2581 8.94256 21.9684H8.94063Z",
      fill: "black"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "IntegraBeautyLogo_svg__clip0_11741_136401",
      children: _jsx("rect", {
        width: 60.2557,
        height: 32,
        fill: "white",
        transform: "translate(0.186768 -0.000732422)"
      })
    })
  })]
}), 'IntegraBeautyLogo', 'Company');
export default IntegraBeautyLogo;