/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ParabolLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 121,
  height: 32,
  viewBox: "0 0 121 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M36.5522 28V9.37015H39.3405V10.8992C39.6807 10.38 40.1574 9.96469 40.7184 9.69919C41.4021 9.33202 42.1677 9.14416 42.9438 9.15316C43.7658 9.0976 44.5899 9.23092 45.3525 9.54292C46.1151 9.85492 46.7964 10.3373 47.3439 10.9532C48.4272 12.404 48.9603 14.1923 48.8478 15.9995C48.948 17.7936 48.4089 19.5651 47.3268 20.9996C46.7781 21.6254 46.0929 22.1166 45.324 22.4351C44.5551 22.7537 43.7232 22.8911 42.8925 22.8368C42.1641 22.8454 41.4444 22.6802 40.7925 22.3547C40.2171 22.0818 39.7188 21.6693 39.3435 21.1547V28H36.5522ZM40.1274 12.7743C39.4548 13.7105 39.1218 14.8483 39.1836 15.9995C39.1215 17.1506 39.4545 18.2885 40.1274 19.2246C40.4166 19.5956 40.7862 19.896 41.2086 20.1031C41.6307 20.3101 42.0948 20.4184 42.5649 20.4196C43.0353 20.4209 43.4997 20.3151 43.923 20.1103C44.3466 19.9055 44.7177 19.6071 45.0087 19.2376C45.6831 18.297 46.0173 17.1551 45.9567 15.9995C46.0185 14.8486 45.6864 13.711 45.0147 12.7743C44.7249 12.4029 44.3541 12.1025 43.9305 11.8959C43.5072 11.6893 43.0422 11.582 42.5712 11.582C42.0999 11.582 41.6352 11.6893 41.2116 11.8959C40.7883 12.1025 40.4175 12.4029 40.1274 12.7743Z",
    fill: "#363636"
  }), _jsx("path", {
    d: "M50.5072 18.8146C50.4847 18.194 50.6176 17.5776 50.8933 17.0212C51.1693 16.4648 51.5797 15.9861 52.0873 15.6284C53.3152 14.8065 54.7747 14.4012 56.2507 14.4724H58.5376V13.8943C58.5637 13.5663 58.5115 13.2367 58.3855 12.9328C58.2592 12.6289 58.0624 12.3593 57.8116 12.1463C57.2746 11.7526 56.6197 11.5533 55.9546 11.5812C55.4053 11.5544 54.8632 11.7136 54.4153 12.033C53.9677 12.3523 53.641 12.8133 53.4874 13.3413L50.9824 12.8143C51.2323 11.7407 51.859 10.7924 52.7485 10.1412C53.6887 9.4678 54.8236 9.12046 55.9795 9.15214C59.5432 9.15214 61.3249 10.8995 61.3249 14.3944V22.6297H59.0116L58.7116 20.8957C58.249 21.5352 57.6328 22.0481 56.92 22.3871C56.2072 22.7261 55.4206 22.8804 54.6325 22.8358H54.5944C53.5267 22.8733 52.4836 22.5089 51.6715 21.8147C51.2689 21.4309 50.956 20.963 50.7553 20.4444C50.5543 19.9258 50.4703 19.3693 50.5093 18.8146H50.5072ZM53.4004 18.8146C53.3854 19.0338 53.4184 19.2537 53.497 19.4588C53.5756 19.664 53.6983 19.8495 53.8561 20.0024C54.0139 20.1554 54.2029 20.272 54.4105 20.3442C54.6181 20.4165 54.8389 20.4426 55.0576 20.4206C55.9513 20.435 56.8195 20.122 57.4987 19.5406C57.8494 19.2324 58.1254 18.8483 58.3057 18.4176C58.4863 17.9868 58.5661 17.5207 58.5397 17.0545V16.7545H56.1397C55.4305 16.716 54.7285 16.9098 54.1396 17.3065C53.9026 17.4767 53.7115 17.7026 53.5825 17.9642C53.4535 18.2258 53.3911 18.5151 53.4004 18.8066",
    fill: "#363636"
  }), _jsx("path", {
    d: "M64.4258 22.6299V9.37023H67.2179V11.5803C67.6262 10.839 68.2253 10.2203 68.9531 9.78825C69.6101 9.38223 70.3649 9.16239 71.1371 9.15222C71.7137 9.1479 72.2855 9.25698 72.8201 9.47325L72.3443 11.9784C71.8793 11.7001 71.3444 11.5612 70.8032 11.5783C70.2818 11.6049 69.7763 11.7654 69.335 12.0445C68.894 12.3235 68.5322 12.7117 68.285 13.1714C67.4936 14.5474 67.1225 16.1251 67.2179 17.7096V22.6299H64.4258Z",
    fill: "#363636"
  }), _jsx("path", {
    d: "M73.5277 18.8146C73.5052 18.194 73.6381 17.5776 73.9138 17.0212C74.1898 16.4648 74.6002 15.9861 75.1078 15.6284C76.3357 14.8065 77.7952 14.4012 79.2712 14.4724H81.558V13.8943C81.5841 13.5663 81.5319 13.2368 81.4056 12.9328C81.2793 12.6289 81.0828 12.3593 80.832 12.1463C80.2951 11.7527 79.6402 11.5534 78.9751 11.5812C78.4258 11.5544 77.8837 11.7136 77.4361 12.033C76.9882 12.3523 76.6615 12.8133 76.5079 13.3413L74.0029 12.8143C74.2531 11.7407 74.8795 10.7924 75.769 10.1412C76.7092 9.4678 77.8441 9.12046 79 9.15214C82.5636 9.15214 84.3453 10.8995 84.3453 14.3944V22.6297H82.032L81.732 20.8957C81.2694 21.5352 80.6533 22.0481 79.9405 22.3871C79.2277 22.7261 78.4411 22.8804 77.653 22.8358H77.6149C76.5472 22.8732 75.5041 22.5088 74.692 21.8147C74.2894 21.4309 73.9765 20.963 73.7758 20.4444C73.5748 19.9258 73.4908 19.3693 73.5298 18.8146H73.5277ZM76.4209 18.8146C76.4059 19.0338 76.4389 19.2537 76.5175 19.4588C76.5961 19.664 76.7188 19.8495 76.8766 20.0024C77.0344 20.1554 77.2234 20.272 77.431 20.3442C77.6386 20.4165 77.8594 20.4426 78.0781 20.4206C78.9718 20.435 79.84 20.122 80.5192 19.5406C80.8699 19.2324 81.1458 18.8483 81.3264 18.4176C81.5067 17.9868 81.5865 17.5207 81.5601 17.0545V16.7545H79.1602C78.451 16.7161 77.749 16.9099 77.1601 17.3065C76.9231 17.4767 76.732 17.7026 76.603 17.9642C76.474 18.2258 76.4116 18.5151 76.4209 18.8066",
    fill: "#363636"
  }), _jsx("path", {
    d: "M87.4468 22.6308V4H90.235V10.8353C90.6415 10.3278 91.165 9.92662 91.7608 9.66625C92.3884 9.34189 93.0819 9.16603 93.7878 9.15223C94.6191 9.09868 95.4516 9.23758 96.2205 9.5581C96.9894 9.87862 97.674 10.3722 98.2212 11.0003C99.3012 12.4359 99.8397 14.2067 99.7422 16.0005C99.8532 17.805 99.3186 19.5903 98.2341 21.0367C97.686 21.6519 97.0047 22.1338 96.2424 22.4458C95.4798 22.7577 94.656 22.8915 93.834 22.8368C93.061 22.8443 92.2981 22.6586 91.615 22.2968C91.0507 22.0358 90.5707 21.6218 90.2299 21.1017V22.6308H87.4468ZM91.0219 12.7764C90.3496 13.7124 90.0166 14.8497 90.0778 16.0005C90.016 17.1517 90.349 18.2895 91.0219 19.2257C91.3108 19.5967 91.6807 19.8971 92.1028 20.1042C92.5252 20.3112 92.989 20.4194 93.4593 20.4207C93.9297 20.4219 94.3941 20.3162 94.8174 20.1114C95.2407 19.9066 95.6121 19.6082 95.9031 19.2387C96.5772 18.2981 96.9117 17.1562 96.8511 16.0005C96.9126 14.8495 96.5799 13.7119 95.9079 12.7754C95.6181 12.404 95.2473 12.1036 94.824 11.897C94.4004 11.6904 93.9354 11.583 93.4644 11.583C92.9934 11.583 92.5284 11.6904 92.1049 11.897C91.6816 12.1036 91.3108 12.404 91.021 12.7754",
    fill: "#363636"
  }), _jsx("path", {
    d: "M101.592 15.9748C101.542 14.1926 102.176 12.459 103.365 11.1305C103.988 10.5034 104.729 10.0057 105.545 9.66611C106.361 9.32648 107.236 9.15161 108.119 9.15161C109.003 9.15161 109.878 9.32648 110.694 9.66611C111.51 10.0057 112.251 10.5034 112.874 11.1305C114.063 12.4687 114.697 14.2101 114.647 15.9998C114.698 17.7877 114.063 19.5276 112.874 20.863C112.274 21.5195 111.537 22.0364 110.716 22.3772C109.894 22.7179 109.008 22.8742 108.119 22.8351C108.118 22.8347 108.116 22.8348 108.114 22.8353C108.112 22.8358 108.111 22.8368 108.109 22.8381C108.108 22.8394 108.107 22.8409 108.107 22.8427C108.106 22.8444 108.106 22.8463 108.106 22.8481C107.22 22.8846 106.336 22.7264 105.517 22.3846C104.698 22.0429 103.963 21.5259 103.365 20.87C102.175 19.5203 101.541 17.7689 101.592 15.9698V15.9748ZM105.446 19.1049C105.745 19.5292 106.146 19.8715 106.612 20.1006C107.078 20.3297 107.594 20.4381 108.112 20.416C108.632 20.4377 109.148 20.3304 109.616 20.1035C110.083 19.8766 110.487 19.5373 110.791 19.1159C111.419 18.1982 111.755 17.1122 111.755 16.0003C111.755 14.8883 111.419 13.8023 110.791 12.8846C110.473 12.478 110.066 12.1493 109.602 11.9235C109.137 11.6976 108.628 11.5805 108.111 11.5811C107.595 11.5817 107.085 11.6999 106.621 11.9268C106.157 12.1537 105.751 12.4833 105.433 12.8906C104.784 13.785 104.45 14.8692 104.482 15.9738C104.453 17.096 104.792 18.197 105.446 19.1089",
    fill: "#363636"
  }), _jsx("path", {
    d: "M120.069 4H117.281V22.6308H120.069V4Z",
    fill: "#363636"
  }), _jsx("path", {
    d: "M13.292 23.7869C12.41 23.7869 11.5389 23.7449 10.6919 23.6649C13.9241 27.2071 17.5852 28.8711 19.9413 27.5101C21.4414 26.643 22.1414 24.702 22.0824 22.2249C19.278 23.295 16.2964 23.825 13.295 23.7869",
    fill: "#FD6157"
  }), _jsx("path", {
    d: "M13.2918 8.21003C14.1748 8.21003 15.0449 8.25203 15.8919 8.33204C12.6638 4.79088 9.00258 3.12681 6.64649 4.48687C5.1464 5.3539 4.44638 7.295 4.50539 9.7721C7.30983 8.70194 10.2913 8.17196 13.2928 8.21003",
    fill: "#FD6157"
  }), _jsx("path", {
    d: "M23.0781 11.5023C22.6025 14.4658 21.5706 17.3126 20.037 19.8927C19.5939 20.6607 19.1219 21.3927 18.6289 22.0867C23.3121 21.0587 26.5843 18.7206 26.5843 15.9985C26.5843 14.2644 25.2542 12.6853 23.0781 11.4983",
    fill: "#55C0CF"
  }), _jsx("path", {
    d: "M6.54729 12.1055C6.9903 11.3374 7.46232 10.6054 7.95534 9.91138C3.27114 10.9394 0 13.2785 0 15.9996C0 17.7337 1.32906 19.3128 3.50616 20.4998C3.98163 17.536 5.01348 14.6888 6.54729 12.1085",
    fill: "#55C0CF"
  }), _jsx("path", {
    d: "M20.0358 12.1041C20.4769 12.8681 20.8759 13.6432 21.2359 14.4202C22.684 9.84801 22.2939 5.84783 19.9378 4.48778C18.4378 3.61974 16.4017 3.98275 14.2876 5.27681C16.6175 7.16962 18.568 9.48654 20.0358 12.1051",
    fill: "#A06BD6"
  }), _jsx("path", {
    d: "M6.54711 19.893C6.10611 19.129 5.70708 18.3539 5.34705 17.5769C3.89499 22.1471 4.28502 26.1493 6.64212 27.5103C8.14218 28.3784 10.1783 28.0153 12.2923 26.7213C9.96239 24.8285 8.01195 22.5116 6.54411 19.893",
    fill: "#A06BD6"
  })]
}), 'ParabolLogo', 'Company');
export default ParabolLogo;