/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RugsComLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 104,
  height: 33,
  viewBox: "0 0 104 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M103.996 13.2026C103.664 10.3705 100.596 9.19705 97.9007 10.0192C97.1076 10.2591 96.4228 10.8491 95.6277 11.3154C93.5887 9.18738 91.2924 9.40404 89.1412 10.793C88.6459 10.5145 88.3403 10.2127 88.0056 10.1798C87.2703 10.0956 86.5278 10.0956 85.7925 10.1798C85.5391 10.2107 85.1328 10.6054 85.129 10.8375C85.0877 14.3855 85.0877 17.9321 85.129 21.4775C85.129 21.7096 85.5429 22.1042 85.8002 22.1371C86.5296 22.2281 87.2782 22.1371 88.0153 22.1778C88.704 22.2164 88.911 21.9185 88.9032 21.2705C88.8781 19.3205 88.8878 17.3704 88.9032 15.4185C88.9032 14.014 89.6442 13.188 90.859 13.1919C92.0372 13.1919 92.7355 13.9792 92.7588 15.374C92.782 16.7688 92.7588 18.123 92.7588 19.4984C92.7588 22.2242 92.7588 22.2068 95.4923 22.1836C96.2003 22.1836 96.4595 21.9688 96.4421 21.2492C96.4034 19.3321 96.4189 17.413 96.4421 15.4939C96.4421 14.045 97.2159 13.1725 98.4482 13.1919C99.6805 13.2112 100.313 13.985 100.329 15.4843C100.344 16.8907 100.329 18.2971 100.329 19.7054C100.329 22.1836 100.329 22.199 102.871 22.1545C103.248 22.1545 103.715 22.1225 103.996 21.844V13.5788C103.996 13.5751 104.004 13.2781 103.996 13.2026Z",
    fill: "black"
  }), _jsx("path", {
    d: "M13.2264 9.09256C12.7408 6.83109 10.9436 5.24671 8.41135 5.11516C5.84616 4.98168 3.26936 5.06099 0.698366 5.03004C0.125745 5.03004 0 5.30861 0 5.80385C0.0116072 11.0168 0.0116072 16.229 0 21.4407C0 22.0404 0.251489 22.2145 0.816372 22.1932C1.55343 22.1603 2.29629 22.1565 3.03335 22.1932C3.66787 22.2261 3.88841 21.9688 3.87874 21.3536C3.85165 19.9163 3.83811 18.475 3.89228 17.0396C3.90775 16.6411 4.17085 15.9195 4.33335 15.9157C5.06074 15.9021 5.96997 15.4514 6.44586 16.4805C6.96432 17.6045 7.65494 18.684 8.03605 19.8582C8.65316 21.7599 9.76358 22.5027 11.8006 22.2242C13.2748 22.0307 13.3947 22.0191 12.7176 20.663C11.8297 18.9006 10.9495 17.1363 10.048 15.3295C10.2627 15.2037 10.3904 15.1244 10.5239 15.0529C12.8492 13.7722 13.7893 11.6964 13.2264 9.09256ZM9.40183 10.6402C9.33412 11.6674 8.56224 12.3813 7.40345 12.4393C6.92562 12.4664 6.43619 12.4393 5.96223 12.4393C3.86906 12.4586 3.86906 12.4586 3.86906 10.41C3.88261 8.02663 3.61177 8.42901 5.9158 8.39806C6.49136 8.3676 7.06845 8.38378 7.6414 8.44642C8.89885 8.63794 9.47921 9.41949 9.40183 10.6402Z",
    fill: "black"
  }), _jsx("path", {
    d: "M37.4911 10.1488C36.8953 10.1488 36.2995 10.085 36.3652 10.9787C33.4441 9.404 30.7977 9.7174 28.9695 11.799C27.1414 13.8805 27.004 17.2756 28.7026 19.5235C30.4011 21.7715 32.9992 22.2029 36.1911 20.7771C36.431 22.1448 35.8313 23.292 34.659 23.7021C33.2216 24.207 31.8017 23.766 31.0743 22.642C30.9041 22.3789 30.5191 22.0191 30.3005 22.0616C29.368 22.2396 28.4607 22.5511 27.5573 22.8103C27.6416 23.4452 27.8633 24.054 28.2071 24.5942C28.551 25.1345 29.0085 25.5933 29.548 25.9384C31.6218 27.3332 33.8852 27.4435 36.1853 26.689C38.6751 25.8727 40.0099 23.6905 40.0351 20.5604C40.0583 17.9392 40.0351 15.3179 40.0351 12.6985C40.0447 10.1449 40.0447 10.1507 37.4911 10.1488ZM33.773 18.4092C32.2544 18.3976 31.1865 17.293 31.2271 15.7744C31.227 15.4295 31.296 15.0881 31.4302 14.7703C31.5644 14.4525 31.7609 14.1649 32.0082 13.9245C32.2555 13.684 32.5485 13.4957 32.8699 13.3705C33.1913 13.2453 33.5346 13.1859 33.8794 13.1957C35.2858 13.2286 36.3672 14.3719 36.3711 15.8325C36.3691 17.3762 35.3206 18.4209 33.773 18.4092Z",
    fill: "black"
  }), _jsx("path", {
    d: "M77.4334 9.77151C73.781 9.74056 70.9934 12.4605 70.9701 16.0742C70.9469 19.775 73.6533 22.5298 77.3541 22.5684C80.9852 22.6052 83.7651 19.893 83.8116 16.2503C83.8638 12.563 81.1458 9.80247 77.4334 9.77151ZM77.3812 19.1134C75.8103 19.1134 74.6574 17.8404 74.6728 16.1323C74.6844 14.4724 75.8162 13.2305 77.3464 13.2034C78.9249 13.1744 80.0644 14.4008 80.0741 16.1439C80.0837 17.8869 78.9733 19.1172 77.3812 19.1134Z",
    fill: "black"
  }), _jsx("path", {
    d: "M26.1742 12.8785C26.1645 10.1024 26.1742 10.1237 23.4078 10.1392C22.7075 10.1392 22.4405 10.3326 22.456 11.0619C22.5005 12.9481 22.4792 14.8343 22.4676 16.7205C22.4676 18.2158 21.7693 18.9839 20.4654 18.9858C19.2137 18.9858 18.5811 18.27 18.5521 16.7533C18.527 15.4746 18.5521 14.194 18.5521 12.9152C18.5521 10.0134 18.5521 10.0366 15.6213 10.1411C14.9694 10.1643 14.803 10.4178 14.8088 11.0136C14.8301 13.3137 14.7837 15.6178 14.8282 17.9179C14.8726 20.1736 16.1398 21.7986 18.173 22.2938C18.863 22.4783 19.5849 22.5102 20.2885 22.3872C20.9921 22.2643 21.6605 21.9895 22.2471 21.5819C22.6959 21.8238 23.0209 22.1217 23.3672 22.1623C24.0984 22.2435 24.8452 22.1623 25.5842 22.1913C26.1645 22.2068 26.2554 21.9321 26.2458 21.4175C26.1993 18.5718 26.1839 15.7222 26.1742 12.8785Z",
    fill: "black"
  }), _jsx("path", {
    d: "M69.3955 18.0591C68.831 17.9376 68.2773 17.7706 67.7396 17.56C67.2211 17.353 66.9542 17.4884 66.6427 17.9469C65.9134 19.0225 64.8281 19.3495 63.6055 18.9742C62.4235 18.6124 61.7271 17.62 61.7019 16.2813C61.6768 14.8304 62.3074 13.7877 63.4565 13.3795C64.5225 13.0042 65.7586 13.1686 66.2539 14.0218C66.8342 15.0122 67.4726 14.9232 68.3083 14.5634C68.6064 14.4448 68.9155 14.356 69.2311 14.2984C70.0397 14.1262 70.1887 13.7026 69.827 12.9848C68.6894 10.7292 66.7414 9.86638 64.4818 9.76966C60.3574 9.79287 57.6317 12.9404 58.036 17.059C58.2933 19.7132 60.4599 21.9746 63.178 22.4196C66.1804 22.9109 68.7591 21.7038 69.9063 19.3108C70.2235 18.653 70.2235 18.241 69.3955 18.0591Z",
    fill: "black"
  }), _jsx("path", {
    d: "M48.6204 14.9425C47.8292 14.6891 46.9993 14.5556 46.1848 14.3757C45.6451 14.2596 45.0473 14.0372 45.262 13.4259C45.4013 13.0273 45.9972 12.5514 46.3976 12.5418C46.8909 12.5282 47.5835 12.8436 47.8466 13.2401C48.2335 13.8205 48.6204 13.9153 49.2008 13.7779C49.5451 13.6986 49.8953 13.6464 50.2415 13.5845C51.2185 13.4065 51.3674 13.1395 50.9341 12.2303C50.0423 10.3538 48.3534 9.89146 46.6452 9.77539C44.0626 9.76185 42.0391 11.2611 41.8089 13.2343C41.5342 15.4765 42.6736 16.8306 45.3723 17.5097C45.9952 17.6664 46.6452 17.7592 47.2237 18.0126C47.51 18.1364 47.8408 18.5659 47.8272 18.8406C47.8137 19.1153 47.4674 19.5196 47.1676 19.6628C46.3938 20.0342 45.2795 19.7034 44.91 18.9219C44.5966 18.2525 44.1864 18.2196 43.5983 18.3608C43.225 18.4518 42.84 18.4963 42.4608 18.5659C41.54 18.7361 41.3872 19.0031 41.7818 19.8737C41.94 20.2584 42.1632 20.613 42.4415 20.9222C44.1632 22.6633 46.2699 22.8702 48.5043 22.2551C49.3364 22.0447 50.0753 21.5646 50.6055 20.8898C51.1358 20.215 51.4275 19.3835 51.4351 18.5253C51.4584 16.8906 50.4098 15.5132 48.6204 14.9425Z",
    fill: "black"
  }), _jsx("path", {
    d: "M55.0159 17.411C54.6883 17.3996 54.3617 17.4529 54.0547 17.5678C53.7476 17.6827 53.4663 17.8569 53.2266 18.0806C52.987 18.3043 52.7937 18.5729 52.658 18.8713C52.5222 19.1697 52.4465 19.4919 52.4353 19.8195C52.4066 20.1602 52.4478 20.5031 52.5562 20.8273C52.6646 21.1516 52.838 21.4503 53.0657 21.7053C53.2935 21.9603 53.5708 22.1661 53.8808 22.3103C54.1908 22.4545 54.5269 22.5339 54.8687 22.5438C55.2104 22.5537 55.5506 22.4938 55.8684 22.3677C56.1862 22.2417 56.4749 22.0522 56.717 21.8108C56.9591 21.5694 57.1495 21.2812 57.2764 20.9638C57.4034 20.6463 57.4642 20.3063 57.4554 19.9646C57.4659 19.6359 57.4109 19.3085 57.2935 19.0014C57.176 18.6943 56.9986 18.4136 56.7715 18.1759C56.5443 17.9381 56.2721 17.748 55.9707 17.6167C55.6693 17.4854 55.3447 17.4154 55.0159 17.411Z",
    fill: "#00B2A9"
  })]
}), 'RugsComLogo', 'Company');
export default RugsComLogo;