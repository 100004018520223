/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BrightlineLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 131,
  height: 32,
  viewBox: "0 0 131 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#BrightlineLogo_svg__clip0_1329_10767)",
    children: [_jsx("path", {
      d: "M30.3755 4.73633C31.2408 4.73633 31.9502 5.44574 31.9502 6.31102C31.9502 7.17632 31.2408 7.88572 30.3755 7.88572C29.5102 7.88572 28.8008 7.17632 28.8008 6.31102C28.8008 5.44574 29.5102 4.73633 30.3755 4.73633Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M85.9191 4.73633C86.7847 4.73633 87.4935 5.44574 87.4935 6.31102C87.4935 7.17632 86.7847 7.88572 85.9191 7.88572C85.0535 7.88572 84.3447 7.17632 84.3447 6.31102C84.3423 5.44574 85.0535 4.73633 85.9191 4.73633Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M5.02005 18.8279C5.02005 19.9282 4.95905 21.277 5.8153 22.3795C6.36656 23.0844 7.31545 23.726 8.87656 23.726C10.7134 23.726 11.9062 22.9918 12.6111 21.4306C13.1307 20.2987 13.316 18.8912 13.316 16.7766C13.316 15.0618 13.2844 13.2566 12.0915 11.9711C11.5718 11.4199 10.684 10.8686 9.27424 10.8686C8.38632 10.8686 7.59108 11.1736 6.79582 11.7249C5.93957 12.3078 5.38831 12.981 5.02005 13.7762V18.8279ZM5.17368 12.1542C7.01045 10.349 8.66424 10.1954 9.91808 10.1954C11.0816 10.1954 12.4891 10.288 13.9577 11.7565C15.4871 13.2883 15.8238 15.1544 15.8238 16.7156C15.8238 19.4696 14.9359 21.399 13.8357 22.5309C12.2135 24.1846 10.1011 24.3993 8.9692 24.3993C7.40808 24.3993 5.87857 24.0942 4.80542 23.2674C2.78566 21.7062 2.75402 19.1669 2.75402 18.218V6.38177C2.75402 6.01577 2.75402 5.40126 1.95877 5.40126L0 5.43288V4.75962L2.01977 4.63763C2.72466 4.606 3.12228 4.51562 3.67354 4.51562C4.95905 4.51562 5.02005 5.31088 5.02005 5.77177V9.48825C5.02005 10.6518 4.89805 11.7904 4.86642 12.066L5.17368 12.1542Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M15.6816 10.7482L17.3964 10.6262C18.1307 10.5652 18.4063 10.5043 18.9892 10.5043C20.3673 10.5043 20.3967 11.3311 20.3967 11.7603V13.5971H20.704C20.948 13.0165 21.4088 12.097 21.7748 11.5774C22.2041 11.0261 22.9993 10.1992 24.2239 10.1992C25.3264 10.1992 25.5704 10.7821 25.5704 11.1797C25.5704 11.731 25.0508 12.4043 23.7042 12.4043C23.153 12.4043 22.7848 12.3432 22.3577 12.158C21.8675 12.5556 21.4404 13.0752 21.0722 14.2387C20.8576 14.9436 20.7062 15.7389 20.7062 16.6268V22.4737C20.7062 22.9323 20.6746 23.2396 21.8088 23.3299L23.1552 23.4203V24.0936C22.0528 24.0032 20.8892 23.9716 19.6964 23.9716C18.5938 23.9716 17.462 24.0032 16.2984 24.0936V23.4203L17.584 23.3299C18.3792 23.269 18.4402 22.9007 18.4402 22.4737V12.3703C18.4402 12.0043 18.4402 11.3899 17.6449 11.3899L15.6862 11.4215V10.7482H15.6816Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M28.5074 10.6259C29.2123 10.5943 29.6099 10.5039 30.1612 10.5039C31.4467 10.5039 31.5077 11.2992 31.5077 11.7601V22.4734C31.5077 22.9026 31.5687 23.2686 32.3639 23.3297L33.6494 23.4223V24.0955C32.547 24.0345 31.4761 23.9735 30.3735 23.9735C29.271 23.9735 28.1686 24.0345 27.0977 24.0955V23.4223L28.3832 23.3297C29.1784 23.2686 29.2394 22.9004 29.2394 22.4734V12.3701C29.2394 12.0041 29.2394 11.3895 28.4442 11.3895L26.4854 11.4212V10.7479L28.5074 10.6259Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M45.1783 13.6265C44.81 12.8312 44.261 12.1874 43.6781 11.7603C42.8512 11.1481 41.8413 10.8725 40.8924 10.8725C38.9337 10.8725 36.6699 12.036 36.6699 17.7293C36.6699 19.0464 36.8236 20.2077 37.2212 21.1882C37.7114 22.3834 38.7823 23.7299 40.9241 23.7299C42.4242 23.7299 43.3121 22.9956 43.9854 22.2003C44.9659 21.0368 45.1783 20.0879 45.1783 19.4147V13.6265ZM45.0269 22.0128C43.5584 24.3083 41.629 24.4008 40.7117 24.4008C38.0797 24.4008 36.4259 23.3616 35.4476 22.0445C34.3452 20.5759 34.1621 18.8612 34.1621 17.5757C34.1621 15.7683 34.4378 13.8411 35.8453 12.28C36.9771 10.9945 38.6603 10.1992 40.5897 10.1992C41.8459 10.1992 43.9266 10.5359 45.4268 12.4336H45.5488C45.6414 11.914 45.5781 11.609 45.7318 11.2723C46.039 10.5381 46.7416 10.5065 47.2613 10.5065H49.6493V11.1797L48.5468 11.2407C48.0272 11.2723 47.4759 11.2723 47.4759 12.158V24.1343C47.4759 28.0518 46.0074 32.001 41.0483 32.001C40.1311 32.001 38.2943 31.879 36.7016 30.4399C35.8747 29.6762 35.66 29.0007 35.66 28.5127C35.66 27.7468 36.2113 27.5028 36.5773 27.5028C37.5872 27.5028 38.1091 28.7883 38.292 29.2786C38.5993 30.1055 38.9969 31.3299 41.1703 31.3299C41.9655 31.3299 43.2511 31.1447 44.139 30.0739C45.2731 28.7567 45.2732 26.6466 45.2732 26.1563V22.0128H45.0269Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M48.8112 4.75962L50.831 4.63763C51.6262 4.57663 51.9018 4.51562 52.453 4.51562C53.7996 4.51562 53.8312 5.34251 53.8312 5.77177V13.1053H53.9532C54.2605 12.6466 54.8705 11.9124 55.5143 11.3611C56.5242 10.5049 57.5342 10.1976 58.6366 10.1976C60.2271 10.1976 61.5443 10.6269 62.5226 11.7271C63.503 12.8297 63.5934 13.8712 63.5934 14.8494V22.4722C63.5934 22.9014 63.6544 23.2674 64.4497 23.3284L65.7352 23.4187V24.092C64.6326 24.0311 63.5618 23.97 62.4593 23.97C61.359 23.97 60.2565 24.0311 59.1856 24.092V23.4187L60.4711 23.3284C61.2664 23.2674 61.3274 22.8991 61.3274 22.4722V14.3886C61.3274 13.2566 60.9614 12.5518 60.6225 12.1248C59.9176 11.2979 58.971 11.1759 58.2345 11.1759C57.4076 11.1759 56.705 11.3905 55.9074 12.0028C54.0097 13.5029 53.8267 15.3691 53.8267 16.257V22.4698C53.8267 22.8991 53.8877 23.2651 54.6852 23.3262L55.9707 23.4165V24.0898C54.8682 24.0287 53.7974 23.9678 52.6948 23.9678C51.5923 23.9678 50.4898 24.0287 49.4189 24.0898V23.4165L50.7044 23.3262C51.4997 23.2651 51.5606 22.8969 51.5606 22.4698V6.38177C51.5606 6.01577 51.5606 5.40126 50.7654 5.40126L48.8066 5.43288V4.75962H48.8112Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M67.5758 4.86328H69.8283V10.5046H73.6239V11.1778H69.8283V21.218C69.8283 21.7082 69.921 23.545 71.4799 23.545C72.1847 23.545 72.6727 23.3304 73.1019 22.6571C73.4996 22.0449 73.5923 21.4642 73.6216 20.791H74.2339C74.2023 22.1375 73.6216 24.4013 71.2652 24.4013C69.826 24.4013 69.0015 23.8206 68.5111 23.24C67.5623 22.1081 67.5622 20.608 67.5622 19.4738V11.1778H64.9912V10.5046H67.5736V4.86328H67.5758Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M76.6861 4.63763C77.3909 4.606 77.7886 4.51562 78.3398 4.51562C79.6253 4.51562 79.6864 5.31088 79.6864 5.77177V22.4722C79.6864 22.9014 79.7473 23.2674 80.5428 23.3284L81.8284 23.4187V24.092C80.6644 23.9994 79.5327 23.97 78.4302 23.97C77.2373 23.97 76.0738 24.0017 74.9713 24.092V23.4187L76.2568 23.3284C77.3277 23.2674 77.4203 22.9307 77.4203 22.4722V6.38177C77.4203 6.01577 77.4203 5.40126 76.6228 5.40126L74.6641 5.43288V4.75962L76.6861 4.63763Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M84.0513 10.6259C84.7561 10.5943 85.1536 10.5039 85.7048 10.5039C86.9904 10.5039 87.0513 11.2992 87.0513 11.7601V22.4734C87.0513 22.9026 87.112 23.2686 87.9096 23.3297L89.1953 23.4223V24.0955C88.0929 24.0345 87.0217 23.9735 85.9193 23.9735C84.8169 23.9735 83.7168 24.0345 82.6432 24.0955V23.4223L83.9289 23.3297C84.7241 23.2686 84.7849 22.9004 84.7849 22.4734V12.3701C84.7849 12.0041 84.7848 11.3895 83.9896 11.3895L82.0312 11.4212V10.7479L84.0513 10.6259Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M106.294 24.092C105.193 24.0309 104.12 23.97 103.02 23.97C101.917 23.97 100.815 24.0309 99.7445 24.092V23.4187L101.029 23.326C101.827 23.2651 101.886 22.8968 101.886 22.4698V14.4201C101.886 13.6542 101.703 12.7053 101.212 12.1247C100.847 11.6955 100.142 11.1758 99.0077 11.1758C97.9661 11.1758 97.1709 11.5124 96.4365 12.1564C95.3341 13.1368 94.4165 14.759 94.4165 16.6252V22.472C94.4165 22.9013 94.4781 23.2673 95.2733 23.3284L96.5589 23.421V24.0942C95.4581 24.0332 94.3853 23.9722 93.2829 23.9722C92.1821 23.9722 91.0797 24.0332 90.0093 24.0942V23.421L91.2949 23.3284C92.0901 23.2673 92.1509 22.8991 92.1509 22.472V12.3687C92.1509 12.0027 92.1509 11.3882 91.3557 11.3882L89.3965 11.4198V10.7466L91.1413 10.6246C91.7533 10.5929 92.3045 10.5026 92.7925 10.5026C94.0781 10.5026 94.1389 11.2978 94.1389 11.7587V13.5639H94.4437C94.6269 13.2566 95.3613 12.1247 96.1269 11.4515C96.8005 10.8392 97.9029 10.1953 99.4637 10.1953C101.176 10.1953 102.372 10.9002 103.074 11.6955C104.055 12.798 104.145 13.8982 104.145 14.8471V22.4698C104.145 22.8991 104.206 23.2651 105.001 23.326L106.287 23.4187V24.092H106.294Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M115.732 14.7568C115.793 13.9592 115.703 12.6444 114.815 11.7564C114.02 10.9589 113.009 10.8686 112.458 10.8686C111.661 10.8686 110.683 11.0222 109.765 12.0931C109.124 12.859 108.785 13.8372 108.724 14.7568H115.732ZM112.763 24.3992C108.324 24.3992 105.968 20.972 105.968 17.3594C105.968 13.9321 107.773 10.1953 112.609 10.1953C116.001 10.1953 118.009 12.432 118.001 15.0301C118.001 15.1272 117.966 15.2221 117.901 15.2944C117.826 15.3803 117.716 15.43 117.603 15.43H108.631C108.57 16.1032 108.477 17.0521 108.477 18.0327C108.477 23.604 111.966 23.6649 113.129 23.6649C114.385 23.6649 116.252 23.4187 117.569 19.7768L118.149 20.1134C116.71 24.0016 114.476 24.3992 112.763 24.3992Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M110.425 4.46484L109.809 4.5969L110.597 8.27507L111.213 8.14299L110.425 4.46484Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M118.963 6.03906L116.719 9.15619L117.231 9.52443L119.475 6.4073L118.963 6.03906Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M114.541 4.46875L113.753 8.14689L114.369 8.27905L115.158 4.60087L114.541 4.46875Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M118.689 0.894531L115.288 8.51579L115.864 8.77267L119.265 1.15139L118.689 0.894531Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M112.797 0H112.167V8.07H112.797V0Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M122.396 9.05078L119.038 11.134L119.371 11.6697L122.728 9.58646L122.396 9.05078Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M129.107 8.84375L119.838 12.4082L120.063 12.9966L129.334 9.43207L129.107 8.84375Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M124.476 4.00781L117.984 10.0336L118.414 10.4956L124.904 4.46978L124.476 4.00781Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M124.305 13.0586L120.344 13.7909L120.459 14.4108L124.42 13.6784L124.305 13.0586Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M130.133 15.2539H120.561V15.8842H130.133V15.2539Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M100.495 4.00586L100.066 4.46784L106.557 10.4936L106.986 10.0317L100.495 4.00586Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M106.01 6.03516L105.499 6.40333L107.742 9.52084L108.253 9.15268L106.01 6.03516Z",
      fill: "#0D1F2C"
    }), _jsx("path", {
      d: "M106.252 0.845703L105.677 1.1025L109.099 8.77326L109.674 8.51646L106.252 0.845703Z",
      fill: "#0D1F2C"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "BrightlineLogo_svg__clip0_1329_10767",
      children: _jsx("rect", {
        width: 130.4,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'BrightlineLogo', 'Company');
export default BrightlineLogo;