/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SwoopLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 93,
  height: 32,
  viewBox: "0 0 93 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.31678 14.3038C6.25441 13.7716 4.92385 13.2394 4.92385 12.5741V12.5075C4.92385 11.7757 5.9883 11.0439 7.6515 11.0439C8.84901 11.0439 10.2461 11.5096 11.3771 12.308C11.7097 12.5741 12.1754 12.6406 12.5746 12.5741C12.9737 12.5075 13.3729 12.2414 13.639 11.9088L13.9717 11.3766C14.4374 10.6448 14.2378 9.71337 13.5725 9.18115C11.8428 7.98364 9.71387 7.31836 7.6515 7.31836C3.8594 7.31836 1.06522 9.51379 1.06522 12.5075V12.5741C1.06522 16.1666 4.65774 17.1645 7.25233 17.9629L7.38539 18.0294C9.7804 18.6947 10.7783 19.0938 10.7783 19.9587V20.0252C10.7783 20.8901 9.51429 21.6219 7.85108 21.6219C6.32094 21.6219 4.65774 21.0231 3.46023 19.9587C3.12759 19.6926 2.6619 19.5595 2.26273 19.6261C1.86356 19.6926 1.46439 19.8922 1.19828 20.2248L0.79911 20.6905C0.266885 21.3558 0.399941 22.3537 1.06522 22.8859C2.92801 24.4161 5.45607 25.3475 7.85108 25.3475C11.7762 25.3475 14.6369 23.0855 14.6369 19.9587V19.8922C14.6369 16.0335 10.8448 15.0356 8.31678 14.3038Z",
    fill: "#383A39"
  }), _jsx("path", {
    d: "M38.9196 8.24939C38.6535 7.85022 38.1213 7.58411 37.6556 7.58411H37.0568C36.3916 7.58411 35.7928 7.98327 35.5267 8.64856L32.3333 17.7629L29.2065 8.58203C29.0069 7.91675 28.4082 7.51758 27.6764 7.51758H27.2107C26.5454 7.51758 25.9466 7.91675 25.6805 8.58203L22.5537 17.7629L19.4269 8.64856C19.2273 7.98327 18.6286 7.58411 17.8967 7.58411H17.298C16.7658 7.58411 16.3001 7.85022 16.034 8.24939C15.7678 8.64855 15.6348 9.18078 15.8344 9.713L20.824 24.2826C21.0236 24.9479 21.6223 25.3471 22.3541 25.3471H22.8198C23.4851 25.3471 24.0839 24.9479 24.35 24.2826L27.4768 14.9022L30.6036 24.2826C30.8032 24.9479 31.4019 25.3471 32.1338 25.3471H32.5995C33.2647 25.3471 33.8635 24.9479 34.1296 24.2826L39.1192 9.713C39.2523 9.18078 39.1857 8.64855 38.9196 8.24939Z",
    fill: "#383A39"
  }), _jsx("path", {
    d: "M82.7612 7.25C80.8319 7.25 79.1022 7.84875 77.7051 8.84667C77.572 8.11487 76.9067 7.58264 76.1749 7.58264H75.5762C74.7113 7.58264 73.9795 8.31445 73.9795 9.17931V29.9361C73.9795 30.8009 74.7113 31.5327 75.5762 31.5327H76.1749C77.0398 31.5327 77.7716 30.8009 77.7716 29.9361V23.8155C79.1687 24.8134 80.8984 25.3456 82.7612 25.3456C87.6843 25.3456 91.4764 21.354 91.4764 16.2978V16.2313C91.4764 11.2417 87.6177 7.25 82.7612 7.25ZM82.6947 11.0421C85.5554 11.0421 87.6843 13.3041 87.6843 16.3643V16.4309C87.6843 19.5577 85.6219 21.6866 82.6947 21.6866C79.9005 21.6866 77.7051 19.3581 77.7051 16.3643V16.2978C77.7051 13.3041 79.834 11.0421 82.6947 11.0421Z",
    fill: "#383A39"
  }), _jsx("path", {
    d: "M62.67 7.38477C60.2084 7.38477 58.146 8.1831 56.6159 9.64672C55.0858 11.1103 54.1544 13.3058 53.7552 16.0334C53.356 18.495 52.225 21.3557 48.6325 21.3557C45.8384 21.3557 43.5764 19.0937 43.5764 16.2995C43.5764 14.969 44.1086 13.6384 45.04 12.707C45.9714 11.7756 47.2354 11.2434 48.6325 11.2434C49.4974 11.2434 50.2292 11.443 50.6284 11.6426C50.6949 11.7091 50.7614 11.7091 50.828 11.7756C50.961 11.8421 51.1606 11.9087 51.3602 11.9752C52.1585 12.1748 52.8903 11.9087 53.4226 11.2434C53.9548 10.5116 53.9548 9.64672 53.4891 8.98144C53.223 8.58227 52.8903 8.38269 52.2916 8.11657C51.2271 7.65088 50.1627 7.38477 48.6325 7.38477C46.2375 7.38477 43.9756 8.31616 42.3124 10.0459C40.6492 11.7091 39.7178 13.971 39.7178 16.3661C39.7178 21.2891 43.7095 25.2808 48.6325 25.2808C51.0275 25.2808 53.1564 24.4159 54.7531 22.8193C56.2167 21.2891 57.2147 19.2268 57.6138 16.6322C57.8799 14.8359 58.4122 13.4388 59.277 12.574C60.0754 11.7091 61.2063 11.3099 62.7365 11.3099C65.5307 11.3099 67.7926 13.5719 67.7926 16.3661C67.7926 19.1602 65.5307 21.4222 62.7365 21.4222C61.8051 21.4222 61.3394 21.2226 60.6741 20.9565C60.2749 20.8234 59.9423 20.6239 59.4766 20.6904C58.9444 20.7569 58.4787 21.023 58.146 21.4222C57.7469 21.8879 57.6138 22.4201 57.7469 23.0189C57.8799 23.8172 58.4122 24.2164 59.277 24.6821C59.9423 25.0147 60.8072 25.2808 62.67 25.2808H62.7365C65.2646 25.2808 67.5265 24.3494 69.1897 22.6197C70.8529 20.9565 71.7178 18.6945 71.7178 16.2995C71.5847 11.443 67.593 7.38477 62.67 7.38477Z",
    fill: "#007FC7"
  }), _jsx("path", {
    d: "M21.4894 0.798828C22.0881 0.798828 22.4208 1.13147 22.4208 1.59716C22.4208 2.12939 22.0881 2.3955 21.4894 2.3955H20.7576V3.39342H20.3584V0.798828H21.4894ZM21.4894 2.12939C21.822 2.12939 22.0216 1.92981 22.0216 1.66369C22.0216 1.33105 21.822 1.198 21.4894 1.198H20.7576V2.12939H21.4894Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M24.4834 3.45983L24.2173 2.79455H23.0198L22.7537 3.45983H22.3545L23.3524 0.865234H23.8181L24.8826 3.45983H24.4834ZM23.2194 2.46191H24.1508L24.0842 2.32885C23.9512 1.99621 23.8181 1.59704 23.6851 1.2644C23.6185 1.53052 23.4855 1.86316 23.2859 2.32885L23.2194 2.46191Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M27.4099 3.12731C27.4099 3.26037 27.4764 3.39342 27.4764 3.45995H27.0773C27.0107 3.39342 27.0107 3.26037 27.0107 3.12731V2.8612C27.0107 2.52856 26.8777 2.3955 26.545 2.3955H25.8132V3.45995H25.4141V0.798828H26.545C27.0773 0.798828 27.4764 1.06494 27.4764 1.53064C27.4764 1.86328 27.3434 2.06286 27.0107 2.19592C27.2768 2.26245 27.4099 2.46203 27.4099 2.72814V3.12731ZM26.545 2.06286C26.8112 2.06286 27.0107 1.92981 27.0107 1.59716C27.0107 1.33105 26.8112 1.198 26.4785 1.198H25.7467V2.06286H26.545Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M29.9385 0.798828V1.13147H29.0736V3.39342H28.6744V1.198H27.8096V0.798828H29.9385Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M31.4014 2.12951C31.4014 1.33117 31.8671 0.732422 32.6654 0.732422C33.4637 0.732422 33.9294 1.33117 33.9294 2.12951C33.9294 2.92785 33.4637 3.5266 32.6654 3.5266C31.8671 3.5266 31.4014 2.92785 31.4014 2.12951ZM33.5968 2.12951C33.5968 1.59729 33.2642 1.13159 32.6654 1.13159C32.0666 1.13159 31.8005 1.59729 31.8005 2.12951C31.8005 2.66174 32.0666 3.12743 32.6654 3.12743C33.2642 3.19396 33.5968 2.66174 33.5968 2.12951Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M36.392 1.198H34.9949V1.92981H36.2589V2.26245H34.9949V3.45995H34.5957V0.798828H36.392V1.198Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M39.9841 3.12731C39.9841 3.26037 40.0507 3.39342 40.0507 3.45995H39.6515C39.585 3.39342 39.585 3.26037 39.585 3.12731V2.8612C39.585 2.52856 39.4519 2.3955 39.1193 2.3955H38.3875V3.45995H37.9883V0.798828H39.1193C39.6515 0.798828 40.0507 1.06494 40.0507 1.53064C40.0507 1.86328 39.9176 2.06286 39.585 2.19592C39.8511 2.26245 39.9176 2.46203 39.9841 2.72814V3.12731ZM39.1193 2.06286C39.3854 2.06286 39.585 1.92981 39.585 1.59716C39.585 1.33105 39.3854 1.198 39.0527 1.198H38.3209V2.06286H39.1193Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M42.5786 1.198H41.115V1.92981H42.4455V2.26245H41.115V3.12731H42.5786V3.45995H40.7158V0.798828H42.5786V1.198Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M45.041 3.45995L44.7749 2.79467H43.5774L43.3113 3.45995H42.9121L43.91 0.798828H44.3757L45.4402 3.39342L45.041 3.45995ZM43.7104 2.46203H44.6418L44.5753 2.26245C44.4423 1.92981 44.3092 1.53064 44.1761 1.198C44.0431 1.46411 43.91 1.79675 43.777 2.26245L43.7104 2.46203Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M46.3044 3.12731H47.5685V3.45995H45.9053V0.798828H46.3044V3.12731Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M49.0986 2.12951C49.0986 1.3977 49.4978 0.732422 50.3627 0.732422C50.9614 0.732422 51.4271 1.06506 51.4936 1.66381H51.0945C50.9614 1.33117 50.6953 1.13159 50.2961 1.13159C49.7639 1.13159 49.4978 1.53076 49.4978 2.12951C49.4978 2.72826 49.8304 3.12743 50.2961 3.12743C50.6953 3.12743 51.0279 2.86132 51.0945 2.46215H51.4936C51.4271 3.12743 50.9614 3.46007 50.2961 3.46007C49.4978 3.5266 49.0986 2.92785 49.0986 2.12951Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M53.8225 0.798828H54.2217V3.39342H53.8225V2.26245H52.4919V3.45995H52.0928V0.798828H52.4919V1.86328H53.8225V0.798828Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M56.8823 1.198H55.4187V1.92981H56.7493V2.26245H55.4187V3.12731H56.8823V3.45995H55.0195V0.798828H56.8823V1.198Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M59.6105 0.798828H60.1427V3.39342H59.7435V2.66161C59.7435 2.12939 59.7435 1.66369 59.7435 1.33105C59.677 1.46411 59.6105 1.66369 59.5439 1.92981L58.9452 3.45995H58.6125L58.0138 1.92981C57.8807 1.66369 57.8142 1.46411 57.8142 1.33105C57.8142 1.66369 57.8142 2.12939 57.8142 2.66161V3.45995H57.415V0.798828H57.9473L58.546 2.19592C58.6791 2.52856 58.7456 2.79467 58.8121 2.92773C58.8787 2.66161 59.0117 2.3955 59.0782 2.12939L59.6105 0.798828Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M60.874 3.45995V0.798828H61.2732V3.39342L60.874 3.45995Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M61.8721 2.59533H62.2712C62.2712 2.9945 62.5373 3.19408 63.003 3.19408C63.4022 3.19408 63.6018 3.06103 63.6018 2.72839C63.6018 2.5288 63.4687 2.39575 63.003 2.26269L62.8035 2.19616C62.2712 2.0631 62.0051 1.86352 62.0051 1.39782C62.0051 0.998656 62.3378 0.666016 62.9365 0.666016C63.6683 0.666016 64.001 0.998656 64.001 1.46435H63.6018C63.6018 1.13171 63.4022 0.998656 62.9365 0.998656C62.6039 0.998656 62.4043 1.13171 62.4043 1.39782C62.4043 1.73046 62.7369 1.79699 63.003 1.86352L63.2026 1.93005C63.8014 2.0631 64.0675 2.26269 64.0675 2.66186C64.0675 3.12755 63.6683 3.46019 63.0696 3.46019C62.2712 3.52672 61.8721 3.19408 61.8721 2.59533Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M66.3955 0.798828V1.13147H65.5306V3.39342H65.1315V1.198H64.2666V0.798828H66.3955Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M68.99 3.12731C68.99 3.26037 69.0565 3.39342 69.0565 3.45995H68.6573C68.5908 3.39342 68.5908 3.26037 68.5908 3.12731V2.8612C68.5908 2.52856 68.4578 2.3955 68.1251 2.3955H67.3933V3.45995H66.9941V0.798828H68.1251C68.6573 0.798828 69.0565 1.06494 69.0565 1.53064C69.0565 1.86328 68.9235 2.06286 68.5908 2.19592C68.8569 2.26245 68.9235 2.46203 68.99 2.72814V3.12731ZM68.1251 2.06286C68.3912 2.06286 68.5908 1.92981 68.5908 1.59716C68.5908 1.33105 68.3912 1.198 68.0586 1.198H67.3268V2.06286H68.1251Z",
    fill: "#A8A9AD"
  }), _jsx("path", {
    d: "M71.1862 0.798828H71.6518L70.6539 2.3955V3.45995H70.2548V2.3955L69.2568 0.798828H69.7225L70.0552 1.33105C70.0552 1.39758 70.1217 1.46411 70.2548 1.66369C70.3213 1.79675 70.3878 1.92981 70.4543 1.99633C70.5874 1.73022 70.7205 1.53064 70.8535 1.33105L71.1862 0.798828Z",
    fill: "#A8A9AD"
  })]
}), 'SwoopLogo', 'Company');
export default SwoopLogo;