/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DigitalOceanLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 178,
  height: 33,
  viewBox: "0 0 178 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M14.9577 25.2693V31C24.752 31 32.3774 21.616 29.1739 11.4372C27.7737 6.98919 24.2099 3.45457 19.725 2.06584C9.46189 -1.11172 0 6.45164 0 16.1654H5.79156C5.79238 16.1654 5.793 16.164 5.793 16.164C5.79393 10.0679 11.8768 5.35495 18.3391 7.68041C20.7328 8.54222 22.6425 10.4358 23.5119 12.81C25.8571 19.2151 21.1123 25.2458 14.971 25.2546V19.5423C14.971 19.5423 14.9707 19.5417 14.9701 19.5417H9.19631C9.19569 19.5417 9.19518 19.5428 9.19518 19.5428V25.2693H14.9577Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M4.75788 29.6707H9.19415L9.19508 29.6694L9.19518 25.2693H4.75995V21.58C4.75995 21.58 4.75871 21.5788 4.75716 21.5788H1.04101C1.03947 21.5788 1.03833 21.5814 1.03833 21.5814V25.268C1.03833 25.268 1.03947 25.2693 1.04101 25.2693H4.75665V29.6694L4.75788 29.6707Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M46.9197 8.13259C49.4104 8.13259 51.5418 8.7301 53.2547 9.90862C54.1919 10.5365 54.928 11.4323 55.4427 12.5716C55.9551 13.7058 56.2148 15.0448 56.2148 16.552C56.2148 18.0771 55.9551 19.4336 55.443 20.5836C54.9283 21.74 54.1928 22.6608 53.2568 23.3194C51.5365 24.5623 49.4041 25.1931 46.9197 25.1931H41.498V8.13259H46.9197ZM46.3663 11.0166H44.6645V22.3091H46.3899C48.3134 22.3091 49.8624 21.9324 50.9946 21.1898C52.3095 20.3362 52.9775 18.7762 52.9775 16.5519C52.9775 14.3928 52.3119 12.8999 50.9998 12.1153C49.8182 11.386 48.2595 11.0166 46.3663 11.0166Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M58.6572 8.46478C59.0187 8.10596 59.4678 7.92258 59.9891 7.92258C60.5094 7.92258 60.9585 8.10596 61.3232 8.46746C61.6869 8.81132 61.8726 9.24939 61.8726 9.76693C61.8726 10.2833 61.688 10.7284 61.3232 11.0904C60.9585 11.4516 60.5094 11.6353 59.9891 11.6353C59.4677 11.6353 59.0187 11.4516 58.6545 11.0904C58.2903 10.7289 58.1055 10.2839 58.1055 9.76693C58.1055 9.24877 58.2913 8.81049 58.6572 8.46478Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M61.4836 25.193H58.4353V13.1772H61.4836V25.193Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M69.4992 12.8967C70.6091 12.8967 71.636 13.37 72.5552 14.1809L72.5551 13.1772H75.5563V24.0895C75.5563 25.8879 75.0064 27.3566 73.9222 28.4533C72.8442 29.5441 71.3798 30.0976 69.5702 30.0976C68.2286 30.0976 67.0888 29.8041 66.182 29.2259C65.2754 28.6455 64.5887 28.0346 64.1402 27.4102L64.0843 27.3324L66.1601 25.3541C67.234 26.7935 68.1159 27.3419 69.5232 27.3419C70.443 27.3419 71.1781 27.0726 71.7082 26.5407C72.2389 26.0078 72.5081 25.2323 72.5081 24.2352V23.9587C71.6851 24.5997 70.6746 24.9242 69.4992 24.9242C67.8088 24.9242 66.4027 24.355 65.3193 23.2322C64.2449 22.0717 63.7012 20.6245 63.7012 18.9279C63.7012 17.1924 64.2535 15.74 65.3428 14.6119C66.4178 13.4744 67.8165 12.8967 69.4992 12.8967ZM69.6643 22.1686C70.5385 22.1686 71.2228 21.8775 71.7555 21.2775C72.2859 20.681 72.5551 19.8905 72.5551 18.9279C72.5551 17.9503 72.2859 17.1502 71.7553 16.5496C71.2221 15.9454 70.5378 15.6523 69.6643 15.6523C68.8025 15.6523 68.1032 15.9539 67.5851 16.5484C67.0621 17.149 66.7967 17.9497 66.7967 18.9279C66.7967 19.8911 67.0621 20.6818 67.5848 21.278C68.1097 21.8776 68.7901 22.1686 69.6643 22.1686ZM72.5552 14.1809C72.5553 14.1809 72.5552 14.1809 72.5552 14.1809V14.1809Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M81.214 25.193H78.1654V13.1772H81.214V25.193Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M78.3873 8.46478C78.749 8.10599 79.1977 7.9226 79.719 7.92258C80.2398 7.92258 80.6888 8.10596 81.0533 8.46746C81.4171 8.81132 81.6026 9.24939 81.6026 9.76693C81.6026 10.2833 81.4181 10.7284 81.0533 11.0904C80.6888 11.4516 80.2398 11.6353 79.7191 11.6353C79.1978 11.6353 78.7491 11.4516 78.3848 11.0904C78.0204 10.7289 77.8358 10.2839 77.8358 9.76693C77.8358 9.24877 78.0212 8.81049 78.3873 8.46478Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M84.8947 9.93029H87.8959V13.1772H90.7335V15.9329H87.8959V20.4953C87.8959 21.2969 88.0142 21.8321 88.2474 22.0849C88.4778 22.3337 88.9623 22.4602 89.6881 22.4602L90.7335 22.406V25.1596L90.5963 25.1687C90.1088 25.2003 89.6103 25.2164 89.115 25.2164C87.5577 25.2164 86.4525 24.8896 85.8301 24.2449C85.2091 23.6034 84.8947 22.4857 84.8947 20.9239V15.9328H83.1519V13.1772H84.8947V9.93029Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M108.077 25.193H105.028V8.13269H108.077V25.193Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M137.343 22.3012C137.772 22.0281 138.33 21.5026 138.876 20.8964L140.858 22.7853C139.657 24.5167 137.875 25.4617 135.668 25.4617C133.987 25.4617 132.535 24.857 131.354 23.6644C130.174 22.4731 129.575 20.9621 129.575 19.1732C129.575 17.3843 130.174 15.8772 131.356 14.6935C132.543 13.5017 133.994 12.8967 135.668 12.8967C137.668 12.8967 139.344 13.686 140.658 15.3818L138.643 17.298C137.756 16.258 136.72 15.6523 135.738 15.6523C134.841 15.6523 134.122 15.9751 133.542 16.6391C132.964 17.2989 132.671 18.1518 132.671 19.1732C132.671 20.1945 132.968 21.0486 133.553 21.7132C134.141 22.3816 134.864 22.7061 135.762 22.7061C136.389 22.7061 136.921 22.5694 137.343 22.3012Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M149.638 13.426C150.367 13.7775 150.956 14.2584 151.388 14.8557C151.817 15.4478 152.143 16.1078 152.352 16.808C152.564 17.5113 152.671 18.2639 152.671 19.045L152.643 20.1485H144.567C144.644 20.9058 144.936 21.519 145.437 21.9734C145.972 22.4596 146.671 22.7061 147.516 22.7061C148.087 22.7061 148.599 22.5951 149.054 22.3702C149.356 22.2208 149.639 22.0029 149.94 21.7211C150.179 21.4981 150.44 21.2482 150.624 21.0274L152.613 22.9232L152.545 23.0106C151.274 24.6372 149.586 25.4617 147.531 25.4617C145.716 25.4617 144.234 24.8805 143.127 23.7345C142.014 22.5832 141.45 21.076 141.45 19.2547C141.45 17.4844 141.964 15.9684 142.979 14.7486C144.024 13.5196 145.417 12.8966 147.118 12.8966C148.063 12.8966 148.91 13.0747 149.638 13.426ZM145.521 16.2596C145.139 16.6314 144.872 17.1103 144.725 17.6848L149.426 17.6847C149.384 17.1291 149.18 16.6543 148.819 16.2729C148.43 15.8606 147.858 15.6523 147.119 15.6523C146.472 15.6523 145.934 15.8565 145.521 16.2596Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M163.096 14.0762C164.027 14.8648 164.498 16.0058 164.498 17.4685V25.193H161.496V24.2037C160.511 24.9979 159.425 25.4617 158.264 25.4617C157.103 25.4617 156.113 25.1276 155.321 24.47C154.535 23.7885 154.138 22.8712 154.138 21.7422C154.138 20.4548 154.609 19.4437 155.538 18.7366C156.455 18.0388 157.689 17.6847 159.206 17.6847C159.94 17.6847 160.694 17.8003 161.45 18.0283V17.445C161.45 16.9099 161.253 16.488 160.849 16.1561C160.443 15.8219 159.914 15.6524 159.277 15.6524C158.091 15.6524 157.197 16.0939 156.393 17.3655L154.441 15.512L154.411 15.4751C154.906 14.6096 155.685 13.9486 156.479 13.5492C157.337 13.1179 158.286 12.8966 159.3 12.8966C160.895 12.8966 162.172 13.2939 163.096 14.0762ZM159.135 20.2418C158.517 20.2418 158.029 20.362 157.683 20.6012C157.349 20.8307 157.187 21.1431 157.187 21.5553C157.187 21.918 157.321 22.2044 157.598 22.4294C157.88 22.6594 158.29 22.7756 158.818 22.7756C159.902 22.7756 160.787 22.4658 161.45 21.8549V20.6763C160.647 20.3876 159.869 20.2418 159.135 20.2418Z",
    fill: "#136EFB"
  }), _jsx("path", {
    d: "M172.679 12.8966C174.184 12.8966 175.377 13.3732 176.226 14.3114C177.072 15.2462 177.5 16.5633 177.5 18.2276V25.193H174.451V18.2276C174.451 17.4333 174.255 16.7972 173.867 16.3355C173.481 15.8759 172.957 15.6523 172.267 15.6523C171.482 15.6523 170.89 15.9023 170.461 16.417C170.03 16.9328 169.812 17.6557 169.812 18.5662V25.193H166.763V13.1772H169.753V13.9209C170.488 13.2411 171.47 12.8966 172.679 12.8966Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M101.197 14.0762C102.127 14.8648 102.599 16.0058 102.599 17.4685V25.193H99.5973V24.2037C98.6115 24.9979 97.5259 25.4617 96.3653 25.4617C95.2041 25.4617 94.214 25.1276 93.4217 24.47C92.6358 23.7885 92.2388 22.8712 92.2388 21.7422C92.2388 20.4548 92.7097 19.4437 93.6383 18.7366C94.5553 18.0388 95.79 17.6847 97.307 17.6847C98.0406 17.6847 98.7944 17.8003 99.5505 18.0283V17.445C99.5505 16.9099 99.3538 16.488 98.9497 16.1561C98.5435 15.8219 98.0145 15.6524 97.3776 15.6524C96.1926 15.6524 95.2983 16.0939 94.4941 17.3655L92.542 15.512L92.5115 15.4751C93.0064 14.6096 93.7859 13.9486 94.5799 13.5492C95.4382 13.1179 96.3866 12.8966 97.401 12.8966C98.9952 12.8966 100.273 13.2939 101.197 14.0762ZM97.2362 20.2418C96.6183 20.2418 96.1295 20.362 95.7837 20.6012C95.45 20.8307 95.2875 21.1431 95.2875 21.5553C95.2875 21.918 95.4222 22.2044 95.6991 22.4294C95.981 22.6594 96.391 22.7756 96.9188 22.7756C98.0028 22.7756 98.8879 22.4658 99.5505 21.8549V20.6763C98.7483 20.3876 97.9702 20.2418 97.2362 20.2418Z",
    fill: "#136EFB"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M110.27 16.6552C110.27 21.5046 114.248 25.4497 119.138 25.4497C124.027 25.4497 128.005 21.5046 128.005 16.6552C128.005 11.8055 124.027 7.86035 119.138 7.86035C114.248 7.86035 110.27 11.8055 110.27 16.6552ZM113.396 16.6552C113.396 13.5146 115.972 10.9599 119.138 10.9599C122.304 10.9599 124.88 13.5146 124.88 16.6552C124.88 19.7958 122.304 22.3504 119.138 22.3504C115.972 22.3504 113.396 19.7958 113.396 16.6552Z",
    fill: "#136EFB"
  })]
}), 'DigitalOceanLogo', 'Company');
export default DigitalOceanLogo;