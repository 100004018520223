/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ZenjobLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 171,
  height: 32,
  viewBox: "0 0 171 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("g", {
    clipPath: "url(#ZenjobLogo_svg__clip0_3_119)",
    children: _jsx("path", {
      d: "M15.5127 7.47418H2.54111V1.34618H26.2109L11.4618 25.6327H25.5128V31.7607H0.727295L15.5127 7.47418ZM31.7135 1.34618H50.4248V7.47418H37.9593V13.4669H47.8313V19.4684H37.9593V25.6327H50.4248V31.7607H31.7135V1.34618ZM62.8626 14.6276V31.7622H56.6168V1L76.4873 18.4778V1.34473H82.7418V32.1055L62.864 14.6276H62.8626ZM86.9949 22.8036H93.2495C93.2495 25.2429 94.5004 26.2756 96.3506 26.2756C98.1993 26.2756 99.4502 25.2516 99.4502 22.8036V1.34618H105.705V23.1935C105.705 29.448 101.436 32.1055 96.3506 32.1055C91.2189 32.1055 86.9949 29.1964 86.9949 22.8138M110.746 16.552C110.746 7.62109 118.262 0.479273 127.372 1.032C135.178 1.50327 141.533 7.81309 142.013 15.5629C142.575 24.6204 135.386 32.0996 126.393 32.0996C117.727 32.0996 110.746 25.1571 110.746 16.552ZM135.75 16.552C135.75 11.2851 131.743 6.99564 126.393 6.99564C121.044 6.99564 117.039 11.2851 117.039 16.552C117.039 21.8189 121.044 26.1069 126.393 26.1069C131.743 26.1069 135.748 21.8189 135.748 16.5505L135.75 16.552ZM158.25 1.34618C163.425 1.34618 167.306 5.33455 167.306 10.1316C167.306 12.488 166.4 14.2829 164.931 15.6153C167.606 16.9389 169.455 19.3869 169.455 22.8138C169.455 27.9527 165.312 31.7695 159.881 31.7695H146.991V1.34618H158.25ZM157.606 13.8116C159.763 13.8116 161.232 12.3149 161.232 10.4298C161.232 8.58982 159.763 7.048 157.606 7.048H153.254V13.8204H157.606V13.8116ZM159.501 26.0589C161.83 26.0589 163.29 24.5171 163.29 22.5869C163.29 20.6189 161.821 19.0785 159.501 19.0785H153.245V26.0589H159.501Z",
      fill: "#3E1191"
    })
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "ZenjobLogo_svg__clip0_3_119",
      children: _jsx("rect", {
        width: 170.182,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'ZenjobLogo', 'Company');
export default ZenjobLogo;