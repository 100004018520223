/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CriteoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 102,
  height: 33,
  viewBox: "0 0 102 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20.4848 29.794H17.7415V13.4943H20.4848V15.4878C21.461 14.0974 23.0935 13.3101 24.9953 13.3101C25.3151 13.3101 25.6517 13.3268 25.9715 13.3771L26.1734 13.4106V16.3254L25.8873 16.2584C25.3993 16.1579 24.9112 16.1076 24.4231 16.1076C22.1173 16.1076 20.5016 17.7996 20.5016 20.2286L20.4848 29.794Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M52.3783 29.7605C55.0879 29.7605 57.0403 28.9229 58.7233 27.0466L58.8748 26.8791L57.1244 25.0699L56.9561 25.2374C55.7107 26.5441 54.5662 27.3147 52.3951 27.3147C49.3488 27.3147 47.346 25.3547 47.144 22.2053H59.5143L59.5312 21.9875C59.5648 21.5352 59.5648 21.0327 59.5648 21.0159C59.5648 18.7879 58.8748 16.7609 57.6125 15.2867C56.2829 13.7288 54.4652 12.908 52.3446 12.908C47.6489 12.908 44.3839 16.4594 44.3839 21.552V21.6692C44.367 26.5106 47.5816 29.7605 52.3783 29.7605ZM47.2955 19.793C47.5648 17.4142 49.7696 15.5045 52.3278 15.5045C54.7008 15.5045 56.6363 17.3975 56.7542 19.793H47.2955Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M31.593 13.4775H28.8496V29.794H31.593V13.4775Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M41.0177 29.9782C40.1762 29.9782 38.9307 29.8944 37.9546 29.1406C36.8774 28.3365 36.3389 26.9126 36.3389 24.9358V9.69153H39.0822V13.4607H42.6502V16.0405H39.0822V24.9358C39.0822 26.9963 39.8059 27.3984 40.984 27.3984C41.4553 27.4151 41.9265 27.3816 42.381 27.3146L42.6502 27.2644V29.7772L42.4819 29.8274C42.0107 29.9447 41.5058 29.9949 41.0177 29.9782Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M69.6966 29.9782C71.9183 29.995 74.0557 29.1239 75.6209 27.5659C77.2366 25.941 78.1118 23.7297 78.095 21.4514C78.1286 19.1732 77.2366 16.9619 75.6209 15.337C72.339 12.1206 67.0543 12.1206 63.7724 15.337C62.1567 16.9619 61.2815 19.1732 61.3151 21.4514C61.2815 23.7465 62.1735 25.941 63.7892 27.5827C65.3376 29.1406 67.475 29.995 69.6966 29.9782ZM69.6966 15.5045C71.2282 15.4877 72.6924 16.1076 73.7359 17.1964C74.7626 18.2853 75.3348 19.793 75.3348 21.4514C75.3348 23.1099 74.7626 24.6176 73.7359 25.7065C71.5648 27.9177 67.9968 27.968 65.7584 25.807C65.7247 25.7735 65.691 25.74 65.6574 25.7065C64.6307 24.6176 64.0585 23.1099 64.0585 21.4514C64.0585 19.793 64.6307 18.2853 65.6574 17.1964C66.7008 16.1076 68.1651 15.4877 69.6966 15.5045Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M8.51835 29.9783C6.27992 29.9951 4.14246 29.124 2.54358 27.566C0.92787 25.9411 0.0358621 23.7466 0.0695228 21.4516C0.0358621 19.1733 0.92787 16.962 2.54358 15.3371C4.12563 13.7791 6.27992 12.908 8.51835 12.9248C10.9083 12.9248 12.9952 13.7624 14.5268 15.3371L14.6783 15.4879L12.9952 17.4813L12.8269 17.2971C11.7161 16.1244 10.235 15.5046 8.51835 15.5046C6.98679 15.4879 5.50572 16.1077 4.44541 17.1966C3.40193 18.2854 2.8297 19.7931 2.8297 21.4516C2.8297 23.11 3.40193 24.6177 4.44541 25.7066C5.50572 26.8122 6.98679 27.4153 8.51835 27.3985C10.2182 27.3985 11.6993 26.7787 12.8101 25.6228L12.9784 25.4385L14.6614 27.4153L14.5099 27.566C12.9952 29.1575 10.9251 29.9783 8.51835 29.9783Z",
    fill: "#2C2F33"
  }), _jsx("path", {
    d: "M30.2126 6.72649C29.0513 6.74324 28.092 5.80513 28.0752 4.63249C28.0583 3.4766 29.0008 2.52173 30.179 2.50498C31.3403 2.48823 32.2996 3.42634 32.3164 4.59898V4.61573C32.3164 5.78837 31.3739 6.72649 30.2126 6.72649Z",
    fill: "url(#CriteoLogo_svg__paint0_linear)"
  }), _jsx("path", {
    d: "M98.9648 29.9782C97.8035 29.9949 96.8442 29.0568 96.8274 27.8842C96.8105 26.7283 97.753 25.7734 98.9312 25.7567C100.092 25.7399 101.052 26.678 101.069 27.8507V27.8674C101.069 29.0401 100.126 29.9782 98.9648 29.9782Z",
    fill: "url(#CriteoLogo_svg__paint1_linear)"
  }), _jsx("path", {
    d: "M92.9731 26.8792H87.3854C85.938 26.8792 84.7767 25.7066 84.7767 24.2659V7.88245C84.7767 5.52042 82.8581 3.62744 80.5018 3.62744H36.238V5.52041H80.2494C81.6968 5.52041 82.8581 6.69305 82.8581 8.11697V24.5004C82.8581 26.8625 84.7767 28.7722 87.133 28.7722H92.9563V26.8792H92.9731Z",
    fill: "url(#CriteoLogo_svg__paint2_linear)"
  }), _jsxs("defs", {
    children: [_jsxs("linearGradient", {
      id: "CriteoLogo_svg__paint0_linear",
      x1: 12.8538,
      y1: 4.62605,
      x2: 107.634,
      y2: 4.62605,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#F68C21"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#F16324"
      })]
    }), _jsxs("linearGradient", {
      id: "CriteoLogo_svg__paint1_linear",
      x1: 12.8542,
      y1: 27.879,
      x2: 107.634,
      y2: 27.879,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#F68C21"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#F16324"
      })]
    }), _jsxs("linearGradient", {
      id: "CriteoLogo_svg__paint2_linear",
      x1: 12.8539,
      y1: 16.2137,
      x2: 107.634,
      y2: 16.2137,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#F68C21"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#F16324"
      })]
    })]
  })]
}), 'CriteoLogo', 'Company');
export default CriteoLogo;