/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DriftLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 92,
  height: 33,
  viewBox: "0 0 92 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0 26.9024V5.37903H9.43482C16.087 5.37903 21.028 9.25098 21.028 16.1245C21.028 22.9981 16.087 26.9024 9.46713 26.9024H0ZM14.5387 16.1245C14.5387 13.2199 12.8908 11.0589 9.47101 11.0589H6.39758V21.2238H9.43482C12.6375 21.2238 14.5387 18.8352 14.5387 16.1245Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M33.4807 26.9024L30.6864 20.3379H28.2876V26.9024H21.9314V5.37903H33.1343C38.0456 5.37903 40.6873 8.74145 40.6873 12.7686C40.6873 16.4802 38.5793 18.4485 36.9108 19.2878L40.7196 26.9024H33.4807ZM34.2354 13.1734C34.2354 11.7094 33.2286 11.0589 32.1585 11.0589H28.2876V15.3266H32.1585C33.2286 15.3292 34.2354 14.6787 34.2354 13.1734Z",
    fill: "black"
  }), _jsx("path", {
    d: "M56.1456 5.39972C56.0626 5.38677 55.9788 5.37985 55.8948 5.37903V5.38549H47.0016C46.6345 5.38549 46.3644 5.52516 46.2041 5.81485L46.1266 5.94417L42.0412 13.1514C41.9771 13.24 41.9477 13.349 41.9585 13.4579C41.9998 13.6713 42.1885 13.8122 42.4535 13.8122H44.511C44.9065 13.8239 45.1133 14.1213 44.9427 14.4317C44.9259 14.4627 44.8897 14.5222 44.8897 14.5222L41.7555 19.7792C41.7555 19.7792 41.6883 19.8892 41.6612 19.9461C41.5436 20.1995 41.7077 20.4608 42.0192 20.5112C42.0904 20.5192 42.1621 20.5231 42.2337 20.5228H44.1414C44.5175 20.5694 44.6868 20.8604 44.5175 21.2005L44.4438 21.3518L41.9184 26.5597C41.9184 26.5597 41.8602 26.6735 41.8396 26.7317C41.8244 26.7733 41.8248 26.8191 41.8408 26.8604C41.8567 26.9018 41.887 26.936 41.9261 26.9567C41.9694 26.9849 42.0199 26.9999 42.0715 26.9999C42.1232 26.9999 42.1737 26.9849 42.2169 26.9567C42.2777 26.9153 42.3863 26.817 42.3863 26.817L50.411 19.9021C50.411 19.9021 50.486 19.8387 50.5209 19.8064C50.5894 19.7562 50.6383 19.6836 50.6591 19.6012C50.6798 19.5188 50.6712 19.4318 50.6346 19.3551C50.5907 19.2721 50.5239 19.2035 50.442 19.1576C50.3602 19.1118 50.2668 19.0905 50.1732 19.0964H49.8798C49.5192 19.0757 49.3189 18.7602 49.4921 18.4925C49.5337 18.4344 49.579 18.3791 49.6278 18.3269L53.5904 13.8575C53.5904 13.8575 53.6977 13.7424 53.7416 13.6803C53.9316 13.4152 53.7455 13.0841 53.3849 13.0466H51.1878C50.8078 13.0259 50.6113 12.6923 50.8078 12.4168C50.8479 12.3599 50.9474 12.2539 50.9474 12.2539L56.3226 6.14075C56.3628 6.09701 56.4008 6.05126 56.4364 6.00366C56.5966 5.76053 56.4609 5.47343 56.1456 5.39972Z",
    fill: "black"
  }), _jsx("path", {
    d: "M57.4075 26.9024V5.37903H73.3911V11.0589H63.7882V13.9416H73.1701V19.6215H63.7882V26.9024H57.4075Z",
    fill: "black"
  }), _jsx("path", {
    d: "M79.9517 11.0589V26.9024H86.3351V11.0589H91.9611V5.37903H74.2947V11.0589H79.9517Z",
    fill: "black"
  })]
}), 'DriftLogo', 'Company');
export default DriftLogo;