/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AllerganLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 144,
  height: 32,
  viewBox: "0 0 144 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M108.894 12.0133C109.022 11.6046 109.169 11.2681 109.226 10.917C109.3 10.4544 109.544 10.3661 109.971 10.3797C110.96 10.4111 111.951 10.3897 112.998 10.3897C112.816 11.8119 112.646 13.1826 112.465 14.5518C112.115 17.1995 111.767 19.8475 111.403 22.4934C111.136 24.4379 110.509 26.2115 108.923 27.5093C107.826 28.4077 106.545 28.8525 105.159 29.0193C102.765 29.3073 100.526 28.8508 98.4671 27.578C98.3174 27.4855 98.1823 27.3692 98.002 27.2355C98.5377 26.3514 99.0564 25.4955 99.581 24.6299C100.977 25.5361 102.406 26.1144 104.049 26.0453C105.767 25.9729 106.815 25.1882 107.271 23.5295C107.431 22.945 107.523 22.3416 107.558 21.7059C107.385 21.8805 107.216 22.0582 107.04 22.2294C105.025 24.1906 101.488 24.0101 99.7965 21.8598C99.1994 21.1006 98.8735 20.2201 98.7638 19.2772C98.4511 16.59 99.0043 14.124 100.857 12.0777C102.331 10.4493 104.19 9.77425 106.378 10.1839C107.483 10.3907 108.131 10.8878 108.894 12.0133ZM108.34 15.9207C108.322 15.7635 108.3 15.5301 108.268 15.298C108.009 13.3874 106.278 12.5257 104.62 13.5169C104.214 13.7593 103.834 14.1139 103.565 14.5021C102.741 15.6932 102.451 17.0327 102.709 18.465C103.028 20.2292 104.574 21.0306 106.16 20.1871C106.705 19.8974 107.188 19.3922 107.551 18.8802C108.149 18.0367 108.3 17.0212 108.34 15.9207Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M55.8381 5.65745C57.0266 11.815 58.205 17.92 59.3899 24.0588C57.9936 24.0588 56.6605 24.0588 55.2679 24.0588C55.0547 22.8304 54.84 21.5938 54.6163 20.305C54.4432 20.2958 54.2697 20.279 54.0963 20.2788C52.3253 20.2768 50.5542 20.2926 48.7836 20.2665C48.3677 20.2604 48.1889 20.4128 48.0399 20.7763C47.5886 21.8769 47.0997 22.9622 46.6211 24.0636C45.3637 24.0636 44.1197 24.0636 42.7925 24.0636C42.8613 23.8796 42.9104 23.7199 42.9789 23.5691C45.6281 17.7412 48.2819 11.9155 50.922 6.08359C51.0713 5.7537 51.2445 5.64163 51.6066 5.64782C52.996 5.67159 54.386 5.65745 55.8381 5.65745ZM49.5277 17.1089C51.1143 17.1089 52.6176 17.1089 54.1551 17.1089C53.7181 14.5665 53.2893 12.0711 52.8604 9.57571C52.8261 9.57514 52.7919 9.57457 52.7577 9.574C51.6887 12.0678 50.6196 14.5617 49.5277 17.1089Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M124.59 12.0987C124.732 11.5939 124.831 11.1599 124.984 10.7463C125.038 10.6019 125.224 10.409 125.353 10.4057C126.434 10.3784 127.516 10.3902 128.681 10.3902C128.084 14.974 127.493 19.5076 126.901 24.0568C125.695 24.0568 124.528 24.0568 123.298 24.0568C123.298 23.5044 123.298 22.9739 123.298 22.3432C123.139 22.4922 123.061 22.5559 122.994 22.6303C121.615 24.1714 119.911 24.7345 117.908 24.2253C115.963 23.7307 114.98 22.3019 114.624 20.443C114.061 17.5061 114.528 14.7441 116.424 12.3564C117.819 10.5993 119.681 9.81782 121.929 10.1406C122.981 10.2916 123.804 10.8624 124.372 11.7758C124.426 11.8627 124.486 11.9457 124.59 12.0987ZM123.981 16.3263C123.981 16.0735 124.002 15.8188 123.978 15.5683C123.86 14.3364 123.241 13.4525 122.307 13.2077C121.337 12.9534 120.528 13.2905 119.841 13.9617C118.495 15.2777 117.933 18.2064 118.679 19.9423C119.079 20.8746 119.8 21.3333 120.815 21.2696C121.87 21.2034 122.577 20.5813 123.105 19.7349C123.753 18.6961 124.006 17.5418 123.981 16.3263Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M131.678 10.4094C132.905 10.4094 134.056 10.4094 135.251 10.4094C135.251 10.9789 135.251 11.5305 135.251 12.1705C135.437 11.9932 135.563 11.8833 135.677 11.7619C137.14 10.2023 138.952 9.77585 140.975 10.2324C142.566 10.5915 143.541 11.9241 143.481 13.7091C143.434 15.113 143.238 16.5137 143.07 17.9113C142.825 19.9516 142.547 21.9879 142.279 24.0518C140.981 24.0518 139.708 24.0518 138.389 24.0518C138.56 22.7343 138.723 21.4484 138.893 20.1633C139.086 18.6955 139.304 17.2306 139.472 15.76C139.563 14.9686 139.528 14.1479 138.727 13.702C137.883 13.2319 137.037 13.41 136.292 13.9796C135.341 14.7067 134.853 15.6742 134.72 16.8819C134.464 19.2145 134.131 21.5386 133.828 23.866C133.821 23.9177 133.795 23.9669 133.767 24.0482C132.499 24.0482 131.227 24.0482 129.901 24.0482C130.494 19.4954 131.083 14.9705 131.678 10.4094Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M85.3418 19.9715C85.7844 20.7212 86.2232 21.4644 86.6952 22.2638C86.4012 22.5045 86.1336 22.7732 85.8213 22.9714C83.7596 24.2792 81.5137 24.7068 79.1319 24.2015C77.0956 23.7694 75.7612 22.4909 75.2599 20.472C74.5329 17.5441 75.0371 14.836 77.0121 12.4971C78.869 10.298 81.9755 9.4774 84.7023 10.4166C86.1634 10.9198 87.1344 11.8706 87.3618 13.4584C87.5825 14.9998 87.0177 16.2206 85.8042 17.152C84.5097 18.1455 83.0004 18.6201 81.4146 18.8675C80.5793 18.9978 79.7335 19.0612 78.8884 19.1552C78.7458 20.2474 79.6081 21.2225 80.8726 21.3717C82.346 21.5455 83.6474 21.0717 84.8658 20.2957C85.0178 20.1988 85.1641 20.0929 85.3418 19.9715ZM78.9718 16.507C79.4208 16.4621 79.8787 16.4711 80.3064 16.362C81.1897 16.1367 82.101 15.9427 82.9181 15.5594C83.7199 15.1832 83.962 14.2972 83.6621 13.5741C83.3931 12.9256 82.6407 12.5975 81.7857 12.756C80.215 13.0472 78.9553 14.7001 78.9718 16.507Z",
    fill: "#2E5696"
  }), _jsx("path", {
    d: "M64.9218 24.0601C63.6174 24.0601 62.3596 24.0601 61.0254 24.0601C61.8467 17.7417 62.6641 11.4542 63.4848 5.14038C64.7867 5.14038 66.0593 5.14038 67.3814 5.14038C66.5612 11.4495 65.7453 17.7253 64.9218 24.0601Z",
    fill: "#2E5696"
  }), _jsx("path", {
    d: "M71.7962 24.0531C70.4801 24.0531 69.2057 24.0531 67.8989 24.0531C68.7218 17.7291 69.5406 11.4373 70.3602 5.13879C71.6717 5.13879 72.9283 5.13879 74.2548 5.13879C73.4342 11.4516 72.6185 17.7272 71.7962 24.0531Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M92.309 24.0815C91.1262 24.0815 89.9703 24.0815 88.8144 24.0815C88.7265 24.0815 88.6386 24.0815 88.4609 24.0815C89.0569 19.5016 89.6469 14.9682 90.2394 10.4154C91.4484 10.4154 92.6142 10.4154 93.8309 10.4154C93.8309 11.059 93.8309 11.6784 93.8309 12.2821C94.3109 11.773 94.7291 11.2295 95.2447 10.8041C96.1743 10.0372 97.2594 9.88898 98.4105 10.2336C98.7561 10.3371 98.888 10.5175 98.7774 10.9163C98.4888 11.9563 98.2524 13.0109 97.9881 14.0887C97.6989 14.0182 97.4441 13.9347 97.1827 13.8962C96.1669 13.7466 95.2936 14.0229 94.5764 14.7786C93.597 15.8108 93.1995 17.0592 93.0633 18.4525C92.886 20.2666 92.618 22.0719 92.3868 23.8806C92.3803 23.9318 92.3493 23.9798 92.309 24.0815Z",
    fill: "#2D5696"
  }), _jsx("path", {
    d: "M13.0176 7.32286C13.0173 5.51837 14.4812 4.04314 16.284 4.0312C18.0967 4.01919 19.612 5.53893 19.5894 7.34617C19.5668 9.15175 18.0826 10.6194 16.2875 10.6114C14.486 10.6033 13.0179 9.12683 13.0176 7.32286Z",
    fill: "#44AF2C"
  }), _jsx("path", {
    d: "M13.0176 24.652C13.0269 22.8538 14.5161 21.3787 16.3124 21.3883C18.1094 21.3979 19.5906 22.8845 19.5904 24.6785C19.5902 26.495 18.0692 27.9972 16.2591 27.9686C14.4535 27.9401 13.0083 26.4614 13.0176 24.652Z",
    fill: "#44AF2C"
  }), _jsx("path", {
    d: "M15.7333 16.0204C15.7265 17.8229 14.2475 19.2946 12.4467 19.2906C10.6472 19.2867 9.17136 17.8096 9.16554 16.0067C9.15968 14.1877 10.6731 12.6855 12.4877 12.7094C14.2954 12.7333 15.7401 14.2071 15.7333 16.0204Z",
    fill: "#44AF2C"
  }), _jsx("path", {
    d: "M33.6294 15.9957C33.6312 14.1976 35.1094 12.7156 36.907 12.7096C38.6994 12.7036 40.1914 14.1791 40.2116 15.9775C40.2318 17.7806 38.7093 19.3026 36.8977 19.2904C35.0975 19.2782 33.6276 17.7964 33.6294 15.9957Z",
    fill: "#2E5696"
  }), _jsx("path", {
    d: "M29.7994 7.34802C29.7874 5.52896 31.2228 4.05696 33.0335 4.03145C34.8327 4.0061 36.3302 5.46854 36.3488 7.26915C36.3679 9.11352 34.9142 10.6028 33.0851 10.6128C31.2811 10.6226 29.8114 9.16225 29.7994 7.34802Z",
    fill: "#2E5696"
  }), _jsx("path", {
    d: "M36.3497 24.6844C36.347 26.4953 34.8869 27.9618 33.0798 27.9686C31.2551 27.9754 29.7833 26.4838 29.7994 24.6439C29.8153 22.8303 31.2908 21.3713 33.0929 21.387C34.9022 21.4029 36.3523 22.871 36.3497 24.6844Z",
    fill: "#2E5696"
  }), _jsx("path", {
    d: "M24.6233 8.45586e-05C26.4241 0.012832 27.8861 1.49912 27.8728 3.30361C27.8595 5.10148 26.3699 6.576 24.5741 6.56904C22.7764 6.56207 21.2864 5.07153 21.29 3.28372C21.2937 1.46429 22.7932 -0.0128698 24.6233 8.45586e-05Z",
    fill: "#3E86CA"
  }), _jsx("path", {
    d: "M24.6004 32C22.7765 32.0052 21.2739 30.5041 21.2902 28.6931C21.3063 26.8998 22.8122 25.4149 24.5974 25.432C26.4083 25.4493 27.87 26.9162 27.8729 28.7194C27.8759 30.5222 26.4069 31.9948 24.6004 32Z",
    fill: "#3E86CA"
  }), _jsx("path", {
    d: "M30.2349 17.7989C29.2345 17.7916 28.4407 17.0043 28.4332 16.012C28.4255 15.0019 29.2395 14.1975 30.2657 14.2009C31.2571 14.2043 32.0632 15.0021 32.0719 15.9884C32.0807 16.9911 31.2536 17.8063 30.2349 17.7989Z",
    fill: "#4087CA"
  }), _jsx("path", {
    d: "M28.739 9.80963C28.7254 10.8116 27.8807 11.6244 26.8774 11.601C25.8998 11.5781 25.0895 10.7395 25.1087 9.77046C25.1284 8.77673 25.9323 7.98157 26.9163 7.98254C27.9297 7.98355 28.7525 8.80842 28.739 9.80963Z",
    fill: "#4087CA"
  }), _jsx("path", {
    d: "M26.9157 24.0021C25.8922 23.9996 25.0863 23.184 25.1084 22.1732C25.1298 21.1938 25.9539 20.3924 26.933 20.3988C27.9363 20.4055 28.7562 21.2434 28.739 22.2446C28.722 23.231 27.9195 24.0046 26.9157 24.0021Z",
    fill: "#4087CA"
  }), _jsx("path", {
    d: "M5.80554 14.2006C6.82791 14.2098 7.61434 15.0204 7.59412 16.0441C7.57451 17.0372 6.77957 17.8025 5.77165 17.7987C4.74611 17.7949 3.97466 16.9962 3.99199 15.9563C4.00867 14.9545 4.79685 14.1915 5.80554 14.2006Z",
    fill: "#A3D235"
  }), _jsx("path", {
    d: "M11.9483 22.2142C11.9456 23.2288 11.1847 23.9957 10.1732 24.0035C9.13679 24.0115 8.34599 23.2316 8.34864 22.2041C8.35126 21.1887 9.16749 20.3866 10.1838 20.4006C11.172 20.4142 11.9511 21.215 11.9483 22.2142Z",
    fill: "#A3D235"
  }), _jsx("path", {
    d: "M11.9483 9.80476C11.9446 10.7996 11.153 11.5963 10.1647 11.5997C9.14818 11.6033 8.34399 10.796 8.34865 9.77672C8.35324 8.77582 9.13052 8.00247 10.1382 7.99626C11.1764 7.98985 11.9522 8.76493 11.9483 9.80476Z",
    fill: "#A3D235"
  }), _jsx("path", {
    d: "M5.77006 12.8886C5.12305 12.8804 4.56823 12.3172 4.56885 11.6692C4.56947 11.0224 5.12521 10.461 5.77335 10.4524C6.44333 10.4435 7.01556 11.0244 7.00144 11.6991C6.98791 12.3457 6.41745 12.8967 5.77006 12.8886Z",
    fill: "#C5D506"
  }), _jsx("path", {
    d: "M7.00271 20.3352C7.00005 20.9846 6.44244 21.5437 5.79319 21.548C5.1477 21.5522 4.58172 20.9982 4.56953 20.3503C4.55699 19.6833 5.14139 19.0967 5.80459 19.1107C6.45434 19.1244 7.00535 19.6876 7.00271 20.3352Z",
    fill: "#C5D506"
  }), _jsx("path", {
    d: "M1.72108e-05 15.9939C0.00351281 15.3348 0.547574 14.7847 1.20009 14.7805C1.88037 14.7762 2.4209 15.3376 2.40652 16.0336C2.39275 16.6995 1.84491 17.2325 1.18723 17.2198C0.530414 17.2071 -0.00349308 16.6558 1.72108e-05 15.9939Z",
    fill: "#C5D507"
  }), _jsx("path", {
    d: "M23.0567 12.3711C22.3823 12.3652 21.8358 11.8382 21.8268 11.1853C21.8175 10.5143 22.3853 9.96877 23.0865 9.9749C23.7463 9.98068 24.2933 10.536 24.2815 11.1881C24.2695 11.849 23.7229 12.3769 23.0567 12.3711Z",
    fill: "#81ADE2"
  }), _jsx("path", {
    d: "M27.3538 16.0171C27.3482 16.6895 26.8019 17.2448 26.1618 17.2291C25.511 17.213 24.9589 16.6561 24.9537 16.0103C24.9483 15.3453 25.5329 14.7617 26.1957 14.7705C26.8397 14.7791 27.3596 15.3386 27.3538 16.0171Z",
    fill: "#81ADE2"
  }), _jsx("path", {
    d: "M23.058 22.0252C22.3843 22.0198 21.8371 21.4931 21.8268 20.8402C21.8162 20.1686 22.3827 19.6232 23.0852 19.6288C23.7459 19.634 24.2919 20.187 24.2815 20.8404C24.271 21.5004 23.7234 22.0306 23.058 22.0252Z",
    fill: "#81ADE2"
  })]
}), 'AllerganLogo', 'Company');
export default AllerganLogo;