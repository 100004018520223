/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CryptoComLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 167,
  height: 33,
  viewBox: "0 0 167 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.8737 0.996582L0.0125732 8.99659V24.9966L13.8737 32.9966L27.7348 24.9966V8.99659L13.8737 0.996582ZM13.8737 0.996582L0.0125732 8.99659V24.9966L13.8737 32.9966L27.7348 24.9966V8.99659L13.8737 0.996582Z",
    fill: "white"
  }), _jsx("path", {
    d: "M19.3459 26.7466H17.3737L15.0126 24.5799V23.4688L17.457 21.1355V17.441L20.6515 15.3577L24.2903 18.1077L19.3459 26.7466ZM11.1792 20.9132L11.5403 17.441L10.3459 14.3299H17.4015L16.2348 17.441L16.5681 20.9132H13.8459H11.1792ZM12.7903 24.5799L10.4292 26.7744H8.42924L3.45702 18.1077L7.12368 15.3855L10.3459 17.441V21.1355L12.7903 23.4688V24.5799ZM8.40146 7.91326H19.3181L20.6237 13.4688H7.12368L8.40146 7.91326ZM13.8737 0.996582L0.0125732 8.99659V24.9966L13.8737 32.9966L27.7348 24.9966V8.99659L13.8737 0.996582Z",
    fill: "#03316C"
  }), _jsx("path", {
    d: "M43.2904 24.4134C39.6237 24.4134 36.9293 21.5523 36.9293 18.0245C36.9293 14.4967 39.6515 11.5522 43.3181 11.5522C45.6515 11.5522 47.0959 12.4134 48.2348 13.6634L46.4848 15.5523C45.6237 14.6356 44.707 14.0245 43.2904 14.0245C41.2348 14.0245 39.7348 15.8022 39.7348 17.9689C39.7348 20.1911 41.2626 21.9689 43.4293 21.9689C44.7626 21.9689 45.7348 21.33 46.6237 20.4411L48.3181 22.1356C47.0959 23.4411 45.6793 24.4134 43.2904 24.4134Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M53.6792 24.4133H50.8459V11.58H53.6792V14.58C54.457 12.7466 55.8737 11.4689 58.0403 11.58V14.58H57.8737C55.4014 14.58 53.6792 16.1911 53.6792 19.4689V24.4133Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M72.8459 11.5244L66.5959 27.8577H63.7348L65.0959 24.4133L59.7348 11.5244H62.7626L66.4015 21.0522L69.9015 11.5244H72.8459Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M81.1792 13.9966C79.2626 13.9966 77.5959 15.5522 77.5959 17.9689C77.5959 20.3855 79.2903 21.9411 81.1792 21.9411C83.1237 21.9411 84.707 20.4133 84.707 17.9689C84.707 15.5244 83.0959 13.9966 81.1792 13.9966ZM81.8181 24.3855C79.7903 24.3855 78.5126 23.3577 77.6515 22.2189V27.8577H74.8181V11.5244H77.6515V13.83C78.5681 12.5522 79.8459 11.5244 81.8181 11.5244C84.7348 11.5244 87.5681 13.83 87.5681 17.9411C87.5681 22.0522 84.7626 24.3855 81.8181 24.3855Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M93.7626 14.2189V20.2744C93.7626 21.3578 94.3182 21.8022 95.2904 21.8022H96.4015V24.33H94.4571C92.4015 24.33 90.9293 23.4133 90.9293 20.7189V14.2189H89.3737V11.8022H90.9293V8.41333H93.7626V11.8022H96.4015V14.2189H93.7626Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M105.318 13.9967C103.04 13.9967 101.596 15.7745 101.596 17.9411C101.596 20.1356 103.151 21.9411 105.346 21.9411C107.624 21.9411 109.068 20.1634 109.068 17.9967C109.124 15.8022 107.54 13.9967 105.318 13.9967ZM105.318 24.4134C101.568 24.4134 98.7903 21.5523 98.7903 18.0245C98.7903 14.4689 101.596 11.5522 105.374 11.5522C109.151 11.5522 111.929 14.4134 111.929 17.9689C111.929 21.4689 109.151 24.4134 105.318 24.4134Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M138.957 13.9967C136.679 13.9967 135.235 15.7745 135.235 17.9411C135.235 20.1356 136.79 21.9411 138.985 21.9411C141.263 21.9411 142.707 20.1634 142.707 17.9967C142.735 15.8022 141.179 13.9967 138.957 13.9967ZM138.957 24.4134C135.207 24.4134 132.429 21.5523 132.429 18.0245C132.429 14.4689 135.235 11.5522 139.013 11.5522C142.79 11.5522 145.568 14.4134 145.568 17.9689C145.568 21.4689 142.763 24.4134 138.957 24.4134Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M162.346 11.5244C165.04 11.5244 166.679 13.2189 166.679 16.2466V24.3855H163.846V17.1077C163.846 15.1355 162.929 14.1077 161.346 14.1077C159.818 14.1077 158.707 15.1911 158.707 17.1633V24.4133H155.874V17.1077C155.874 15.1911 154.929 14.1077 153.374 14.1077C151.818 14.1077 150.735 15.2744 150.735 17.1911V24.4133H147.901V11.5522H150.735V13.6633C151.54 12.58 152.596 11.5522 154.485 11.5522C156.263 11.5522 157.485 12.4133 158.151 13.7189C159.179 12.3855 160.485 11.5244 162.346 11.5244Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M117.124 21.8021V23.2465L117.04 23.3854L115.79 24.0799H115.624L114.401 23.3854L114.318 23.2465V21.8021L114.401 21.6632L115.624 20.9688H115.79L117.04 21.6632L117.124 21.8021Z",
    fill: "#002E73"
  }), _jsx("path", {
    d: "M125.707 24.3856C122.04 24.3022 119.401 21.3856 119.457 17.8578C119.513 14.33 122.29 11.4411 125.985 11.5245C128.318 11.58 129.763 12.4689 130.874 13.7467L129.096 15.6078C128.263 14.6911 127.346 14.0245 125.929 13.9967C123.874 13.9689 122.346 15.6911 122.318 17.8856C122.263 20.1078 123.763 21.9133 125.929 21.9411C127.263 21.9689 128.263 21.3578 129.151 20.4689L130.818 22.1911C129.568 23.4967 128.124 24.4411 125.707 24.3856Z",
    fill: "#002E73"
  })]
}), 'CryptoComLogo', 'Company');
export default CryptoComLogo;