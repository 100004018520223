/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ToggleLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 123,
  height: 33,
  viewBox: "0 0 123 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#ToggleLogo_svg__clip0_11879_153392)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M9.20333 22.902H23.6773C28.4404 22.902 32.3542 18.9882 32.3542 14.2251C32.3542 9.42504 28.4404 5.5481 23.6773 5.5481H9.20333C4.44023 5.5481 0.526367 9.42504 0.526367 14.2251C0.526367 18.9882 4.44023 22.902 9.20333 22.902ZM29.1047 14.299C29.1047 17.6025 26.4267 20.2805 23.1232 20.2805C19.8196 20.2805 17.1416 17.6025 17.1416 14.299C17.1416 10.9954 19.8196 8.31739 23.1232 8.31739C26.4267 8.31739 29.1047 10.9954 29.1047 14.299Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M23.1232 20.2805C26.4267 20.2805 29.1047 17.6025 29.1047 14.299C29.1047 10.9954 26.4267 8.31738 23.1232 8.31738C19.8196 8.31738 17.1416 10.9954 17.1416 14.299C17.1416 17.6025 19.8196 20.2805 23.1232 20.2805Z",
      fill: "#DEF3F7"
    }), _jsx("path", {
      d: "M45.0196 8.20662H39.5181V5.36353H53.6228V8.20662H48.1212V22.7544H45.0566V8.20662H45.0196Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M52.958 16.2557V16.2188C52.958 12.4526 55.9488 9.35107 59.9734 9.35107C63.9981 9.35107 66.9519 12.4157 66.9519 16.145V16.1819C66.9519 19.9111 63.9611 23.0127 59.9365 23.0127C55.9119 23.0496 52.958 19.985 52.958 16.2557ZM63.9242 16.2557V16.2188C63.9242 13.8926 62.2627 12.0096 59.8996 12.0096C57.4996 12.0096 55.9488 13.8926 55.9488 16.1819V16.2188C55.9488 18.508 57.6103 20.4281 59.9365 20.4281C62.3734 20.4281 63.9242 18.545 63.9242 16.2557Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M69.2046 16.2557V16.2188C69.2046 12.4526 72.1954 9.35107 76.22 9.35107C80.2446 9.35107 83.1985 12.4157 83.1985 16.145V16.1819C83.1985 19.9111 80.2077 23.0127 76.1831 23.0127C72.1584 23.0496 69.2046 19.985 69.2046 16.2557ZM80.1708 16.2557V16.2188C80.1708 13.8926 78.5093 12.0096 76.1462 12.0096C73.7462 12.0096 72.1954 13.8926 72.1954 16.1819V16.2188C72.1954 18.508 73.8569 20.4281 76.1831 20.4281C78.62 20.4281 80.1708 18.545 80.1708 16.2557Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M86.189 16.2557V16.2188C86.189 12.4526 89.1797 9.35107 93.2044 9.35107C97.229 9.35107 100.183 12.4157 100.183 16.145V16.1819C100.183 19.9111 97.1921 23.0127 93.1675 23.0127C89.1428 23.0496 86.189 19.985 86.189 16.2557ZM97.1552 16.2557V16.2188C97.1552 13.8926 95.4936 12.0096 93.1305 12.0096C90.7305 12.0096 89.1798 13.8926 89.1798 16.1819V16.2188C89.1798 18.508 90.8413 20.4281 93.1675 20.4281C95.6044 20.4281 97.1552 18.545 97.1552 16.2557Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M97.2663 13.4494V9.2771H100.257V21.9049C100.257 24.1203 99.7033 25.7449 98.5956 26.8526C97.414 28.0341 95.5679 28.6249 93.2048 28.6249C91.0263 28.6249 89.0325 28.071 87.334 27.0741L88.4417 24.8218C89.8448 25.708 91.3217 26.2249 93.094 26.2249C95.7894 26.2249 97.2294 24.8218 97.2294 22.2002V21.1664",
      fill: "#490761"
    }), _jsx("path", {
      d: "M80.281 13.4494V9.2771H83.2718V21.9049C83.2718 24.1203 82.7179 25.7449 81.6102 26.8526C80.4287 28.0341 78.5825 28.6249 76.2194 28.6249C74.041 28.6249 72.0471 28.071 70.3486 27.0741L71.4563 24.8218C72.8594 25.708 74.3363 26.2249 76.1087 26.2249C78.8041 26.2249 80.2441 24.8218 80.2441 22.2002V21.1664",
      fill: "#490761"
    }), _jsx("path", {
      d: "M103.838 4.625H106.829V22.7543H103.838V4.625Z",
      fill: "#490761"
    }), _jsx("path", {
      d: "M109.672 16.2559V16.219C109.672 12.4528 112.331 9.38818 116.097 9.38818C120.269 9.38818 122.374 12.6744 122.374 16.4405C122.374 16.699 122.337 16.9944 122.337 17.2898H112.7C113.032 19.4313 114.546 20.6129 116.466 20.6129C117.943 20.6129 118.977 20.059 120.011 19.0621L121.783 20.6129C120.528 22.0898 118.829 23.0867 116.429 23.0867C112.626 23.0498 109.672 20.2805 109.672 16.2559ZM119.383 15.2959C119.198 13.3759 118.054 11.862 116.06 11.862C114.214 11.862 112.922 13.2651 112.663 15.2959H119.383Z",
      fill: "#490761"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "ToggleLogo_svg__clip0_11879_153392",
      children: _jsx("rect", {
        width: 121.847,
        height: 32,
        fill: "white",
        transform: "translate(0.526367 0.625)"
      })
    })
  })]
}), 'ToggleLogo', 'Company');
export default ToggleLogo;