/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CiscoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 61,
  height: 32,
  viewBox: "0 0 61 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M29.9298 21.4084C29.8506 21.3868 28.6309 21.0723 27.3349 21.0723C24.881 21.0723 23.4036 22.3973 23.4036 24.3705C23.4036 26.1172 24.6395 26.9882 26.1228 27.4568C26.2873 27.5111 26.5296 27.5869 26.6909 27.6404C27.3512 27.8481 27.8782 28.1567 27.8782 28.6913C27.8782 29.2892 27.2691 29.6762 25.9449 29.6762C24.7812 29.6762 23.6656 29.3434 23.4306 29.2841V31.7119C23.5616 31.7397 24.8875 32 26.3038 32C28.3411 32 30.6716 31.1098 30.6716 28.4597C30.6716 27.1743 29.8846 25.991 28.1567 25.4417L27.4223 25.2079C26.9841 25.0696 26.2018 24.8455 26.2018 24.2033C26.2018 23.6959 26.7814 23.3386 27.8487 23.3386C28.7695 23.3386 29.8902 23.6481 29.9298 23.6605V21.4084ZM52.4281 26.5363C52.4281 28.0713 51.2523 29.3131 49.6746 29.3131C48.0952 29.3131 46.9225 28.0713 46.9225 26.5363C46.9225 25.0043 48.0952 23.762 49.6746 23.762C51.2523 23.762 52.4281 25.0043 52.4281 26.5363ZM49.6746 21.0756C46.4297 21.0756 44.1026 23.523 44.1026 26.5363C44.1026 29.5543 46.4297 32 49.6746 32C52.9191 32 55.2496 29.5543 55.2496 26.5363C55.2496 23.523 52.9191 21.0756 49.6746 21.0756ZM19.8336 31.8122H17.1711V21.2626H19.8336",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M13.5117 21.4537C13.2615 21.3795 12.3368 21.0757 11.0843 21.0757C7.8445 21.0757 5.46625 23.3856 5.46625 26.5363C5.46625 29.9354 8.09157 32 11.0843 32C12.2733 32 13.1809 31.7184 13.5117 31.6245V28.7926C13.3973 28.8561 12.5271 29.3525 11.2751 29.3525C9.50149 29.3525 8.35748 28.1042 8.35748 26.5363C8.35748 24.9206 9.55096 23.7224 11.2751 23.7224C12.5484 23.7224 13.4046 24.2297 13.5117 24.2857",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M41.2997 21.4537C41.0495 21.3795 40.1247 21.0757 38.8722 21.0757C35.6325 21.0757 33.2542 23.3856 33.2542 26.5363C33.2542 29.9354 35.8795 32 38.8722 32C40.0612 32 40.9688 31.7184 41.2997 31.6245V28.7926C41.1853 28.8561 40.315 29.3525 39.0631 29.3525C37.2895 29.3525 36.1454 28.1042 36.1454 26.5363C36.1454 24.9206 37.3389 23.7224 39.0631 23.7224C40.3364 23.7224 41.1926 24.2297 41.2997 24.2857",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M17.1633 1.31688C17.1633 0.589714 16.5693 0 15.8413 0C15.1136 0 14.5197 0.589714 14.5197 1.31688V15.3261C14.5197 16.0575 15.1136 16.6469 15.8413 16.6469C16.5693 16.6469 17.1633 16.0575 17.1633 15.3261V1.31688ZM9.90315 6.28981C9.90315 5.56293 9.3095 4.97237 8.58149 4.97237C7.85348 4.97237 7.25984 5.56293 7.25984 6.28981V12.6943C7.25984 13.4254 7.85348 14.0151 8.58149 14.0151C9.3095 14.0151 9.90315 13.4254 9.90315 12.6943V6.28981ZM2.64331 9.92479C2.64331 9.19763 2.04966 8.60819 1.32166 8.60819C0.594493 8.60819 0 9.19763 0 9.92479V12.6943C0 13.4254 0.594493 14.0151 1.32166 14.0151C2.04966 14.0151 2.64331 13.4254 2.64331 12.6943",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M14.5197 1.31688C14.5197 0.589714 15.1136 0 15.8416 0C16.5693 0 17.1633 0.589714 17.1633 1.31688V15.3261C17.1633 16.0575 16.5693 16.6469 15.8416 16.6469C15.1136 16.6469 14.5197 16.0575 14.5197 15.3261V1.31688ZM21.7798 6.28981C21.7798 5.56293 22.3734 4.97237 23.1014 4.97237C23.8294 4.97237 24.4231 5.56293 24.4231 6.28981V12.6943C24.4231 13.4254 23.8294 14.0151 23.1014 14.0151C22.3734 14.0151 21.7798 13.4254 21.7798 12.6943V6.28981ZM29.0396 9.92479C29.0396 9.19763 29.6333 8.60819 30.3613 8.60819C31.0884 8.60819 31.6829 9.19763 31.6829 9.92479V12.6943C31.6829 13.4254 31.0884 14.0151 30.3613 14.0151C29.6333 14.0151 29.0396 13.4254 29.0396 12.6943",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M43.5508 1.31688C43.5508 0.589714 44.1448 0 44.8728 0C45.6005 0 46.1944 0.589714 46.1944 1.31688V15.3261C46.1944 16.0575 45.6005 16.6469 44.8728 16.6469C44.1448 16.6469 43.5508 16.0575 43.5508 15.3261V1.31688ZM50.811 6.28981C50.811 5.56293 51.4046 4.97237 52.1326 4.97237C52.8606 4.97237 53.4543 5.56293 53.4543 6.28981V12.6943C53.4543 13.4254 52.8606 14.0151 52.1326 14.0151C51.4046 14.0151 50.811 13.4254 50.811 12.6943V6.28981ZM58.0708 9.92479C58.0708 9.19763 58.6644 8.60819 59.3925 8.60819C60.1196 8.60819 60.7141 9.19763 60.7141 9.92479V12.6943C60.7141 13.4254 60.1196 14.0151 59.3925 14.0151C58.6644 14.0151 58.0708 13.4254 58.0708 12.6943",
    fill: "#54B9E6"
  }), _jsx("path", {
    d: "M46.1945 1.31688C46.1945 0.589714 45.6005 0 44.8725 0C44.1448 0 43.5509 0.589714 43.5509 1.31688V15.3261C43.5509 16.0575 44.1448 16.6469 44.8725 16.6469C45.6005 16.6469 46.1945 16.0575 46.1945 15.3261V1.31688ZM38.9343 6.28981C38.9343 5.56293 38.3407 4.97237 37.6127 4.97237C36.8847 4.97237 36.291 5.56293 36.291 6.28981V12.6943C36.291 13.4254 36.8847 14.0151 37.6127 14.0151C38.3407 14.0151 38.9343 13.4254 38.9343 12.6943V6.28981ZM31.6745 9.92479C31.6745 9.19763 31.0809 8.60819 30.3528 8.60819C29.6257 8.60819 29.0312 9.19763 29.0312 9.92479V12.6943C29.0312 13.4254 29.6257 14.0151 30.3528 14.0151C31.0809 14.0151 31.6745 13.4254 31.6745 12.6943",
    fill: "#54B9E6"
  })]
}), 'CiscoLogo', 'Company');
export default CiscoLogo;