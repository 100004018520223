/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MeUndiesLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 119,
  height: 32,
  viewBox: "0 0 119 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M80.9708 15.4293C80.4737 14.9342 79.8821 14.5442 79.2312 14.2824C78.5803 14.0206 77.8834 13.8925 77.1819 13.9056C73.4755 13.9468 71.4575 17.2826 71.4575 20.4948C71.4575 24.819 74.3815 27.1253 77.0996 27.1253C77.8356 27.1477 78.5675 27.0081 79.2436 26.7163C79.9197 26.4245 80.5234 25.9877 81.0119 25.4368V26.837H84.8419V8.05762H80.9708V15.4293ZM78.2527 23.5424C77.8528 23.5481 77.4559 23.4728 77.0859 23.3212C76.7158 23.1695 76.3803 22.9446 76.0994 22.6599C75.8186 22.3752 75.5982 22.0366 75.4516 21.6645C75.305 21.2925 75.2352 20.8946 75.2463 20.4948C75.2463 19.7084 75.5587 18.9542 76.1148 18.3981C76.6709 17.8421 77.4251 17.5297 78.2115 17.5297C78.9979 17.5297 79.7521 17.8421 80.3082 18.3981C80.8643 18.9542 81.1767 19.7084 81.1767 20.4948C81.1933 20.8891 81.13 21.2826 80.9906 21.6518C80.8511 22.0209 80.6384 22.358 80.3652 22.6427C80.092 22.9275 79.764 23.154 79.4009 23.3086C79.0379 23.4632 78.6473 23.5427 78.2527 23.5424Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M39.994 20.2478C39.7984 20.1553 39.5853 20.1055 39.3689 20.1018C39.1525 20.0981 38.9379 20.1405 38.7391 20.2262C38.5404 20.3119 38.3623 20.439 38.2165 20.5989C38.0707 20.7589 37.9607 20.948 37.8937 21.1538C37.0289 23.213 34.1049 24.572 32.2517 23.1718C33.9813 22.1422 35.711 20.742 36.6582 18.6005C37.6054 16.459 36.823 11.723 32.4576 12.4643C30.1102 12.8761 26.3625 15.8824 27.3509 21.3597C24.221 22.0187 24.7564 17.5297 26.1154 11.3111C26.5273 9.49909 26.692 8.14006 25.4153 7.11049C24.1386 6.08091 22.9855 6.86339 22.0383 7.68704C19.938 9.49909 17.3435 12.8761 15.9844 14.6881C16.561 11.2288 17.467 6.82221 16.7669 5.66909C16.0668 4.51597 14.7901 4.51597 13.5958 5.29844C10.919 7.48113 6.18293 14.0292 6.18293 14.0292C6.32086 13.5022 6.41725 12.9652 6.47121 12.4231C6.5446 12.0035 6.45263 11.5718 6.2146 11.2186C5.97656 10.8654 5.61097 10.6181 5.19454 10.5287C4.74697 10.4147 4.27256 10.48 3.8725 10.7108C3.47244 10.9416 3.17838 11.3196 3.05304 11.7641C1.98228 16.912 1.11744 19.6301 0.0466886 24.8191C-0.0568 25.2662 0.0131729 25.7358 0.242482 26.1333C0.471791 26.5308 0.843358 26.8264 1.28217 26.9606C1.72672 27.0704 2.19618 27.0117 2.60002 26.7959C2.93766 26.5628 3.19625 26.2324 3.34132 25.8487C5.27691 20.9067 10.0541 14.1528 13.2664 10.5287C13.2858 10.5089 13.3113 10.4963 13.3388 10.4928C13.3663 10.4894 13.3942 10.4952 13.4179 10.5095C13.4417 10.5237 13.4599 10.5455 13.4698 10.5714C13.4797 10.5973 13.4805 10.6258 13.4723 10.6522C13.1016 12.7937 12.0721 18.0239 12.0309 18.7652C11.9897 19.5065 12.0721 20.4537 12.9781 20.7832C13.8841 21.1126 14.6666 20.9479 15.7374 20.0419C16.8081 19.1359 20.5969 14.441 21.8324 12.9173C21.956 12.7937 22.1619 12.7937 22.0795 13.082C19.5262 21.5245 22.6149 26.2605 28.7511 24.572C32.993 29.3492 39.1704 26.1781 40.653 22.4305C40.9824 21.5657 40.8177 20.6185 39.994 20.2478ZM33.2812 15.7177C34.3932 16.2943 33.6519 19.0947 30.4808 20.4949C30.3573 17.6121 32.1281 15.1411 33.2812 15.7177Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M91.2254 14.647H87.1895V26.8947H91.2254V14.647Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M65.6098 13.9468C63.7154 13.9468 62.8505 14.5645 61.8622 15.6765V14.2351H57.991V26.9194H61.9033V19.9183C61.9033 18.2298 62.6858 17.2414 64.0037 17.2414C65.3215 17.2414 66.0216 18.1062 66.0216 19.7124V26.9194H70.0164V18.724C70.0164 15.8 68.3279 13.9468 65.6098 13.9468Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M51.8548 20.495C51.8548 22.2247 50.8252 23.4602 49.0955 23.4602C47.3659 23.4602 46.4598 22.3894 46.4598 20.495V8.0166H42.3416V20.3303C42.3416 24.9839 45.2243 27.1666 49.0955 27.1666C54.2434 27.1666 55.9319 23.6661 55.9319 20.3303V8.0166H51.8548V20.495Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M89.2075 12.7938C90.5266 12.7938 91.5961 11.7244 91.5961 10.4052C91.5961 9.08602 90.5266 8.0166 89.2075 8.0166C87.8883 8.0166 86.8188 9.08602 86.8188 10.4052C86.8188 11.7244 87.8883 12.7938 89.2075 12.7938Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M99.6679 13.8645C98.7872 13.8477 97.9121 14.0088 97.0951 14.3381C96.2781 14.6675 95.5359 15.1583 94.913 15.7812C94.2901 16.4041 93.7993 17.1462 93.47 17.9633C93.1406 18.7803 92.9795 19.6553 92.9963 20.5361C92.9963 24.8603 96.2086 27.2489 99.6679 27.2489C103.127 27.2489 105.228 25.025 106.01 23.213H101.892C101.616 23.514 101.278 23.751 100.901 23.9075C100.524 24.064 100.117 24.1362 99.7091 24.119C98.9825 24.1504 98.2691 23.9174 97.7011 23.463C97.1332 23.0087 96.7492 22.3638 96.6204 21.648H106.381V20.5773C106.381 16.6649 103.498 13.8645 99.6679 13.8645ZM96.7439 19.0123C96.962 18.3947 97.3707 17.8623 97.9111 17.4922C98.4515 17.1221 99.0956 16.9333 99.7503 16.9532C101.398 16.9532 102.345 17.818 102.715 19.0123H96.7439Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M111.693 18.3122C111.585 18.2708 111.491 18.1974 111.425 18.1019C111.359 18.0064 111.323 17.8931 111.323 17.7769C111.323 17.3239 111.652 16.8297 112.641 16.8297C113.629 16.8297 114 17.4886 114.041 17.8181H117.953C117.931 17.2687 117.791 16.7306 117.542 16.2402C117.293 15.7499 116.942 15.3189 116.512 14.9764C115.565 14.2351 114.247 13.8645 112.641 13.8645C111.034 13.8645 108.728 14.5646 107.822 16.2943C107.57 16.7767 107.442 17.3148 107.451 17.8592C107.423 18.445 107.551 19.0279 107.822 19.5477C108.319 20.4098 109.131 21.0448 110.087 21.3186C112.188 22.0599 114.247 21.854 114.247 22.8835C114.247 23.9131 113.588 23.9543 112.805 23.9543C112.023 23.9543 111.611 23.8307 111.405 23.213H107.369C107.657 25.684 109.099 26.3841 110.293 26.7959C111.103 27.0614 111.953 27.1868 112.805 27.1666C113.604 27.1845 114.399 27.0589 115.153 26.7959C116.471 26.3017 118.118 25.1898 118.118 22.8424C118.128 22.2626 118.016 21.6872 117.788 21.1539C117.389 20.3505 116.696 19.7314 115.853 19.4242C114.782 19.0124 112.641 18.7653 111.693 18.3122Z",
    fill: "#1C0732"
  })]
}), 'MeUndiesLogo', 'Company');
export default MeUndiesLogo;