/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApartmentListLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 186,
  height: 33,
  viewBox: "0 0 186 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M133.879 26.8113C133.41 26.9656 132.942 27.1193 132.478 27.273C132.469 26.9165 132.459 26.5586 132.449 26.1999C132.342 22.2377 132.233 18.1752 132.707 14.7171C135.062 14.5547 136.93 12.6223 136.93 10.2738C136.93 9.13363 136.49 8.11117 135.767 7.33398C138.821 4.14019 143.915 3.61972 147.627 6.18307C151.708 9.00111 152.728 14.6372 149.853 18.637C147.143 22.4511 140.349 24.6847 133.879 26.8113Z",
    fill: "#02516E"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M133.407 26.0307C133.095 26.4467 132.785 26.861 132.478 27.2729C132.23 26.9419 131.98 26.6099 131.73 26.2772C127.585 20.7625 123.296 15.0548 123.296 10.4253C123.296 5.51642 127.469 1.5166 132.478 1.5166C137.579 1.5166 141.66 5.48612 141.66 10.4253C141.66 15.0092 137.426 20.6631 133.407 26.0307ZM132.385 14.7281C134.89 14.7281 136.93 12.7282 136.93 10.2738C136.93 7.81934 134.89 5.91033 132.385 5.81943C129.881 5.81943 127.841 7.81934 127.841 10.2738C127.841 12.7282 129.881 14.7281 132.385 14.7281Z",
    fill: "#28B4E9"
  }), _jsx("path", {
    d: "M136.93 10.2738C136.93 12.7282 134.89 14.7281 132.385 14.7281C129.881 14.7281 127.841 12.7282 127.841 10.2738C127.841 7.81937 129.881 5.81946 132.385 5.81946C134.89 5.91036 136.93 7.81937 136.93 10.2738Z",
    fill: "white"
  }), _jsx("path", {
    d: "M0 23.5458C0 20.7278 2.13325 19.3642 5.28675 19.3642C6.58525 19.3642 7.6055 19.546 8.533 19.9097V19.7278C8.533 18.1825 7.6055 17.3643 5.7505 17.3643C4.35925 17.3643 3.339 17.637 2.13325 18.0916L1.20575 15.2735C2.68975 14.6372 4.081 14.2736 6.307 14.2736C8.3475 14.2736 9.8315 14.819 10.759 15.728C11.7792 16.728 12.1502 18.0916 12.1502 19.8188V27.2729H8.44025V25.8185C7.51275 26.8184 6.307 27.4548 4.452 27.4548C2.0405 27.4548 0 26.0912 0 23.5458ZM8.533 22.7277V22.0914C7.88375 21.8187 7.049 21.6369 6.1215 21.6369C4.54475 21.6369 3.5245 22.2732 3.5245 23.4549V23.5458C3.5245 24.5458 4.35925 25.0912 5.565 25.0912C7.32725 24.9094 8.533 24.0004 8.533 22.7277Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M14.5618 14.3645H18.2718V16.1826C19.1993 15.0008 20.405 14.0917 22.3528 14.0917C25.4135 14.0917 28.2888 16.4553 28.2888 20.7278V20.8187C28.2888 25.0912 25.4135 27.4547 22.3528 27.4547C20.405 27.4547 19.1065 26.5457 18.2718 25.5457V31H14.5618V14.3645ZM24.7024 20.8187C24.7024 18.637 23.2184 17.1825 21.4562 17.1825C19.6939 17.1825 18.3027 18.637 18.3027 20.7278V20.8187C18.3027 22.9095 19.7867 24.364 21.4562 24.364C23.2184 24.364 24.7024 23.0004 24.7024 20.8187Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M29.4326 23.5458C29.4326 20.7278 31.5659 19.3642 34.7194 19.3642C36.0179 19.3642 37.0381 19.546 37.9656 19.9097V19.7278C37.9656 18.1825 37.0381 17.3643 35.1831 17.3643C33.7919 17.3643 32.7716 17.637 31.5659 18.0916L30.6384 15.2735C32.1224 14.6372 33.5136 14.2736 35.7396 14.2736C37.7801 14.2736 39.2641 14.819 40.1916 15.728C41.2119 16.728 41.5829 18.0916 41.5829 19.8188V27.2729H37.9656V25.8185C37.0381 26.8184 35.8324 27.4548 33.9774 27.4548C31.4731 27.4548 29.4326 26.0912 29.4326 23.5458ZM37.9656 22.7277V22.0914C37.3164 21.8187 36.4816 21.6369 35.5541 21.6369C33.9774 21.6369 32.9571 22.2732 32.9571 23.4549V23.5458C32.9571 24.5458 33.7919 25.0912 34.9976 25.0912C36.7599 24.9094 37.9656 24.0004 37.9656 22.7277Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M43.9944 14.3645H47.7044V17.0007C48.4464 15.1826 49.6521 14.0917 51.8781 14.1826V18.0006H51.6926C49.1884 18.0006 47.7044 19.4551 47.7044 22.5459V27.2729H43.9944V14.3645Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M62.5754 14.3645H66.2854V16.1826C67.1202 15.0917 68.2332 14.0917 70.1809 14.0917C71.8504 14.0917 73.1489 14.819 73.8909 16.0916C75.0039 14.7281 76.3952 14.0917 78.1574 14.0917C80.9399 14.0917 82.6094 15.728 82.6094 18.8188V27.182H78.8994V20.0005C78.8994 18.2734 78.1574 17.3643 76.7662 17.3643C75.3749 17.3643 74.5402 18.2734 74.5402 20.0005V27.182H70.8302V20.0005C70.8302 18.2734 70.0882 17.3643 68.6969 17.3643C67.3057 17.3643 66.4709 18.2734 66.4709 20.0005V27.182H62.7609V14.3645H62.5754Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M84.4954 20.9096C84.4954 17.1825 87.1851 14.1827 90.9879 14.1827C95.3471 14.1827 97.3876 17.5462 97.3876 21.1823C97.3876 21.4551 97.3876 21.8187 97.2948 22.1823H88.2054C88.5764 23.8186 89.7821 24.7276 91.4516 24.7276C92.6574 24.7276 93.5848 24.364 94.6051 23.3641L96.7383 25.1822C95.5326 26.6366 93.7703 27.5457 91.3588 27.5457C87.4634 27.5457 84.4954 24.8185 84.4954 20.9096ZM93.7703 19.8188C93.5849 18.1825 92.5646 17.0916 90.9879 17.0916C89.4111 17.0916 88.4836 18.1825 88.1126 19.8188H93.7703Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M99.3352 14.3645H103.045V16.1826C103.88 15.0917 104.993 14.0917 106.848 14.0917C109.63 14.0917 111.3 15.9098 111.3 18.9097V27.2729H107.59V20.0915C107.59 18.3643 106.755 17.4552 105.364 17.4552C103.973 17.4552 103.045 18.3643 103.045 20.0915V27.2729H99.3352V14.3645Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M53.6714 23.0913V10.5464H57.3814V14.3644H60.4421V17.4552H57.3814V23.0913C57.3814 23.9094 57.7524 24.364 58.5871 24.364C59.2364 24.2731 59.7929 24.0913 60.0711 23.9095L60.9986 26.5457C60.2566 27.0002 59.0509 27.4547 57.7524 27.4547C55.4336 27.4547 53.6714 26.0912 53.6714 23.0913Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M113.464 23.0913V10.5464H117.174V14.3644H120.235V17.4552H117.174V23.0913C117.174 23.9094 117.545 24.364 118.38 24.364C119.029 24.2731 119.586 24.0913 119.864 23.9095L120.791 26.5457C120.049 27.0002 118.844 27.4547 117.545 27.4547C115.319 27.4547 113.464 26.0912 113.464 23.0913Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M178.173 23.0913V10.5464H181.883V14.3644H184.944V17.4552H181.883V23.0913C181.883 23.9094 182.254 24.364 183.089 24.364C183.738 24.2731 184.294 24.0913 184.573 23.9095L185.5 26.5457C184.758 27.0002 183.552 27.4547 182.254 27.4547C180.028 27.4547 178.173 26.0912 178.173 23.0913Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M158.2 10.5464H154.49V27.2123H158.2V10.5464Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M160.89 10.5464H164.786V13.819H160.89V10.5464ZM160.983 15.1826H164.693V27.182H160.983V15.1826Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M166.27 25.5457L167.847 23.1822C169.238 24.1822 170.722 24.7276 172.02 24.7276C173.133 24.7276 173.597 24.364 173.597 23.7276V23.6367C173.597 22.8186 172.299 22.5459 170.907 22.1823C169.052 21.6368 167.012 20.8187 167.012 18.2734V18.1825C167.012 15.5462 169.145 14.0917 171.835 14.0917C173.504 14.0917 175.267 14.6372 176.751 15.5462L175.359 18.0915C174.061 17.3643 172.762 16.9098 171.835 16.9098C170.907 16.9098 170.444 17.2734 170.444 17.8188V17.9097C170.444 18.637 171.649 19.0006 173.133 19.4551C174.988 20.0915 177.122 20.9096 177.122 23.2731V23.364C177.122 26.1821 174.896 27.5456 172.113 27.5456C170.103 27.4547 168.032 26.9093 166.27 25.5457Z",
    fill: "#231F20"
  })]
}), 'ApartmentListLogo', 'Company');
export default ApartmentListLogo;