/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TrunkClubLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 164,
  height: 32,
  viewBox: "0 0 164 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M110.113 11.5421L110.012 11.8444C109.257 13.557 107.796 14.5141 105.982 14.5141C103.313 14.5141 101.701 12.9526 101.701 10.4843V7.56277C101.701 5.04417 103.313 3.48265 105.882 3.48265C108.249 3.48265 109.458 5.14492 109.962 6.10198L110.113 6.35384L112.883 5.14492L112.783 4.89306C111.977 2.82781 109.508 0.61145 105.982 0.61145C101.55 0.61145 98.4771 3.53302 98.4771 7.71388V10.4843C98.4771 13.96 100.794 17.486 105.882 17.486C109.609 17.486 111.876 15.2697 112.783 13.154L112.883 12.9022L110.113 11.5421Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M0 3.88572V1.01453H13.7011V3.88572H8.46247V16.8817H5.23867V3.88572H0Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.6525 24.8404L17.8317 30.9857C17.7813 31.1369 17.8821 31.2376 18.0835 31.2376H18.6376C18.7146 31.2376 18.7622 31.1788 18.8028 31.1286C18.8153 31.1131 18.8272 31.0984 18.8391 31.0865C18.9399 30.885 19.028 30.6709 19.1162 30.4568C19.2043 30.2428 19.2925 30.0286 19.3932 29.8272H22.3652L22.9192 31.0865C22.9696 31.1872 23.02 31.2376 23.1207 31.2376H23.6748C23.8259 31.2376 23.8763 31.0865 23.8259 30.9857L21.0555 24.8404C21.0555 24.79 20.9547 24.7396 20.9044 24.7396H20.8036C20.7533 24.7396 20.7029 24.79 20.6525 24.8404ZM20.9044 26.553L22.0125 29.0716H19.7458L20.854 26.553H20.9044Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M28.4097 24.7396C28.3593 24.7396 28.2585 24.79 28.2585 24.8907H28.3593V31.0865C28.3593 31.1369 28.4097 31.2376 28.5104 31.2376H29.0645C29.1652 31.2376 29.2156 31.1872 29.2156 31.0865V26.6034L33.1446 31.288H33.3965C33.4468 31.288 33.5476 31.2376 33.5476 31.1369V24.9915C33.5476 24.9411 33.4972 24.8404 33.3965 24.8404H32.792C32.6913 24.8404 32.6409 24.8907 32.6409 24.9915V29.3235L28.6615 24.7396H28.4097Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M38.9373 24.7396C40.7506 24.7396 42.2114 26.2004 42.2114 28.0642C42.2114 29.9279 40.7506 31.3383 38.9373 31.3383C37.1239 31.3383 35.6631 29.8776 35.6631 28.0642C35.6631 26.2508 37.0735 24.7396 38.9373 24.7396ZM38.9373 30.4316C40.2469 30.4316 41.3047 29.3738 41.3047 28.0642C41.3047 26.7545 40.2469 25.6967 38.9373 25.6967C37.6276 25.6967 36.5698 26.8049 36.5698 28.0642C36.5698 29.3738 37.6276 30.4316 38.9373 30.4316Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M44.4282 24.8402C44.3275 24.8402 44.2771 24.8906 44.2771 24.9913H44.3275V31.0359C44.3275 31.0863 44.3778 31.1871 44.4786 31.1871H45.083C45.1334 31.1871 45.2342 31.1367 45.2342 31.0359V28.7188H46.5942L47.8031 31.1367C47.8031 31.1871 47.9039 31.2374 47.9542 31.2374H48.6595C48.8106 31.2374 48.8609 31.0863 48.8106 30.9856L47.5513 28.6685C48.3572 28.3662 48.9113 27.661 48.9113 26.8047C48.9113 25.6965 48.0046 24.8402 46.8964 24.8402H44.4282ZM47.9542 26.8047C47.9542 27.4595 47.4505 27.9633 46.8461 27.9633V27.9129H45.2342V25.6965H46.8461C47.4505 25.6965 47.9542 26.2003 47.9542 26.8047Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M51.1274 24.9913C51.1274 24.8906 51.1778 24.8402 51.2786 24.8402H53.3942C55.1572 24.8402 56.618 26.301 56.618 28.0136C56.618 29.7767 55.1572 31.2374 53.3942 31.2374H51.2786C51.1778 31.2374 51.1274 31.1367 51.1274 31.0863V24.9913ZM53.2431 30.3811C54.6031 30.3811 55.5602 29.3737 55.5602 28.0136C55.5602 26.6536 54.6031 25.6965 53.2431 25.6965H52.0341V30.3811H53.2431Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M58.3558 30.1546C58.318 30.2175 58.2802 30.2805 58.2299 30.3308C58.1583 30.4382 58.1884 30.4947 58.2299 30.5726C58.2468 30.6043 58.2657 30.6397 58.2802 30.6834C58.2952 30.6934 58.3155 30.7083 58.3411 30.727C58.5747 30.8977 59.2461 31.3886 60.2448 31.3886C61.4537 31.3886 62.31 30.6331 62.31 29.676C62.31 28.5678 61.4033 28.0641 60.3455 27.6107C59.5899 27.3085 59.1869 26.9559 59.1869 26.4522C59.1869 26.15 59.4892 25.6462 60.2448 25.6462C60.7988 25.6462 61.4537 26.0492 61.5041 26.0996C61.6048 26.15 61.7559 26.0996 61.8063 25.9988C61.907 25.8981 62.0078 25.747 62.0581 25.6462C62.1589 25.4951 62.0581 25.3944 62.0078 25.344C61.907 25.2433 61.2018 24.7899 60.2951 24.7899C58.8343 24.7899 58.2802 25.7974 58.2802 26.5026C58.2802 27.6107 58.9855 28.0641 59.9425 28.4671C60.8492 28.8197 61.3026 29.1723 61.3026 29.7264C61.3026 30.1797 60.8996 30.5827 60.2448 30.5827C59.5395 30.5827 58.8343 29.9782 58.784 29.9278C58.6832 29.8271 58.5825 29.8775 58.4817 29.9782C58.4314 30.0286 58.3936 30.0916 58.3558 30.1546Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M65.1811 25.6967H63.6699C63.5692 25.6967 63.5188 25.596 63.5188 25.5456V25.0419C63.5188 24.9411 63.5692 24.8907 63.6699 24.8907H67.5989C67.6997 24.8907 67.75 24.9915 67.75 25.0419V25.5456C67.75 25.6463 67.6997 25.6967 67.5989 25.6967H66.0878V31.0865C66.0878 31.1872 65.987 31.2376 65.9367 31.2376H65.3322C65.2314 31.2376 65.1811 31.1369 65.1811 31.0865V25.6967Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M69.6643 24.8402C69.5636 24.8402 69.5132 24.8906 69.5132 24.9913H69.5636V31.0359C69.5636 31.0863 69.6139 31.1871 69.7147 31.1871H70.3191C70.3695 31.1871 70.4702 31.1367 70.4702 31.0359V28.7188H71.8303L73.0392 31.1367C73.0392 31.1871 73.14 31.2374 73.1903 31.2374H73.8955C74.0467 31.2374 74.097 31.0863 74.0467 30.9856L72.7874 28.6685C73.5933 28.3662 74.1474 27.661 74.1474 26.8047C74.1474 25.6965 73.2407 24.8402 72.1325 24.8402H69.6643ZM73.2407 26.8047C73.2407 27.4595 72.737 27.9633 72.1325 27.9633V27.9129H70.5206V25.6965H72.1325C72.737 25.6965 73.2407 26.2003 73.2407 26.8047Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M79.1846 24.7396C80.998 24.7396 82.4587 26.2004 82.4587 28.0642C82.4587 29.9279 80.998 31.3383 79.1846 31.3383C77.3712 31.3383 75.9104 29.8776 75.9104 28.0642C75.9104 26.2508 77.3712 24.7396 79.1846 24.7396ZM79.1846 30.4316C80.4942 30.4316 81.552 29.3738 81.552 28.0642C81.552 26.7545 80.4942 25.6967 79.1846 25.6967C77.8749 25.6967 76.8171 26.8049 76.8171 28.0642C76.8171 29.3738 77.8749 30.4316 79.1846 30.4316Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M85.3805 24.7396C85.3301 24.7396 85.2294 24.8404 85.2294 24.8907L84.1212 31.0361C84.0708 31.1369 84.1212 31.2376 84.2723 31.2376H84.8768C84.9271 31.2376 85.0279 31.1369 85.0279 31.0865L85.6827 27.0064H85.7331L87.3954 31.2376C87.3954 31.288 87.4961 31.3383 87.5465 31.3383H87.6976C87.7983 31.3383 87.8487 31.288 87.8487 31.2376L89.511 27.0064H89.5613L90.2162 31.0865C90.2162 31.1872 90.2665 31.2376 90.3673 31.2376H90.9718C91.0725 31.2376 91.1732 31.1872 91.1229 31.0361L90.0147 24.8907C90.0147 24.79 89.9139 24.7396 89.8636 24.7396H89.7125C89.6621 24.7396 89.5613 24.79 89.5613 24.8404L87.6472 29.525H87.5968L85.6827 24.8404C85.6827 24.79 85.582 24.7396 85.5316 24.7396H85.3805Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M98.8298 24.7396C99.7364 24.7396 100.442 25.0419 101.046 25.5959C101.147 25.6463 101.147 25.7974 101.046 25.8478L100.643 26.2508C100.593 26.3515 100.492 26.3515 100.391 26.2508C99.9379 25.8982 99.3838 25.6463 98.8298 25.6463C97.5201 25.6463 96.5126 26.7545 96.5126 28.0138C96.5126 29.2731 97.5201 30.3813 98.8298 30.3813C99.4846 30.3813 99.9883 30.1294 100.391 29.7768C100.492 29.7264 100.542 29.7264 100.593 29.7768L100.996 30.1798C101.046 30.2302 101.046 30.3813 100.996 30.4316C100.391 31.0361 99.5853 31.3383 98.7794 31.3383C96.966 31.3383 95.4548 29.8776 95.4548 28.0642C95.4548 26.2508 96.966 24.7396 98.8298 24.7396Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M109.156 28.0642C109.156 26.2004 107.695 24.7396 105.882 24.7396C104.068 24.7396 102.608 26.2508 102.608 28.0642C102.608 29.8776 104.068 31.3383 105.882 31.3383C107.695 31.3383 109.156 29.9279 109.156 28.0642ZM108.249 28.0642C108.249 29.3738 107.192 30.4316 105.882 30.4316C104.572 30.4316 103.514 29.3738 103.514 28.0642C103.514 26.8049 104.572 25.6967 105.882 25.6967C107.192 25.6967 108.249 26.7545 108.249 28.0642Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M111.926 24.8907C111.926 24.8404 112.027 24.7396 112.077 24.7396H112.229C112.279 24.7396 112.38 24.79 112.38 24.8404L114.294 29.525H114.344L116.258 24.8404C116.258 24.79 116.359 24.7396 116.409 24.7396H116.561C116.611 24.7396 116.712 24.79 116.712 24.8907L117.82 31.0361C117.87 31.1872 117.77 31.2376 117.669 31.2376H117.064C116.964 31.2376 116.913 31.1872 116.913 31.0865L116.258 27.0064H116.208L114.546 31.2376C114.546 31.288 114.495 31.3383 114.395 31.3383H114.243C114.193 31.3383 114.092 31.288 114.092 31.2376L112.43 27.0064H112.38L111.725 31.0865C111.725 31.1369 111.624 31.2376 111.574 31.2376H110.969C110.818 31.2376 110.768 31.1369 110.818 31.0361L111.926 24.8907Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M120.086 24.8402C119.986 24.8402 119.935 24.8906 119.935 24.9913H119.986V31.0863C119.986 31.1367 120.036 31.2374 120.137 31.2374H120.741C120.792 31.2374 120.892 31.1871 120.892 31.0863V28.9203H122.152C123.31 28.9203 124.217 28.0136 124.217 26.8551C124.217 25.7469 123.26 24.8402 122.152 24.8402H120.086ZM123.31 26.8551C123.31 27.5099 122.806 28.0136 122.152 28.0136H120.892V25.7469H122.152C122.756 25.7469 123.31 26.2506 123.31 26.8551Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M124.922 30.9857L127.743 24.8404C127.793 24.79 127.844 24.7396 127.894 24.7396H127.995C128.045 24.7396 128.146 24.79 128.146 24.8404L130.917 30.9857C130.967 31.0865 130.917 31.2376 130.765 31.2376H130.211C130.111 31.2376 130.06 31.1872 130.01 31.0865L129.456 29.8272H126.433C126.333 30.0287 126.245 30.2428 126.156 30.4569C126.068 30.6709 125.98 30.885 125.879 31.0865C125.867 31.0984 125.856 31.1131 125.843 31.1286C125.802 31.1788 125.755 31.2376 125.678 31.2376H125.124C124.973 31.2376 124.872 31.1369 124.922 30.9857ZM129.103 29.0716L127.995 26.553H127.945L126.836 29.0716H129.103Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M132.73 24.7396C132.68 24.7396 132.579 24.79 132.579 24.8907H132.68V31.0361C132.68 31.0865 132.73 31.1872 132.831 31.1872H133.385C133.486 31.1872 133.536 31.1369 133.536 31.0361V26.553L137.515 31.288H137.717C137.767 31.288 137.868 31.2376 137.868 31.1369V24.9915C137.868 24.9411 137.818 24.8404 137.717 24.8404H137.112C137.012 24.8404 136.961 24.8907 136.961 24.9915V29.3235L132.982 24.7396H132.73Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M141.696 28.1648L139.53 25.0921C139.48 24.9913 139.53 24.8402 139.681 24.8402H140.336C140.386 24.8402 140.437 24.8906 140.487 24.941L142.149 27.2581L143.812 24.941C143.862 24.8906 143.912 24.8402 143.963 24.8402H144.668C144.819 24.8402 144.87 24.9913 144.819 25.0921L142.653 28.1648V31.0863C142.653 31.1871 142.552 31.2374 142.502 31.2374H141.898C141.797 31.2374 141.746 31.1367 141.746 31.0863V28.1648H141.696Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M31.3313 15.4713C30.8276 15.169 30.6261 14.8164 30.6261 14.1112V12.3482C30.6261 10.7363 29.9209 9.67849 28.5608 9.17477C29.9713 8.57031 30.6765 7.46212 30.6765 5.79985V5.29613C30.6765 3.68424 29.9209 1.01453 24.9844 1.01453H17.9324V16.932H21.1562V10.4844H24.8837C26.8482 10.4844 27.2512 11.0889 27.3015 12.2475L27.3519 14.2623C27.4023 15.4209 27.6541 16.932 29.6186 16.932H31.4824V15.4713H31.3313ZM27.3519 6.15246C27.3519 7.31101 26.5963 7.96584 25.1356 7.96584H21.1562V3.83535H25.1356C26.5963 3.83535 27.3519 4.38944 27.3519 5.44725V6.15246Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M45.9392 11.3911V1.01453H49.163V11.3911C49.163 15.1186 46.5436 17.4358 42.3124 17.4358C38.1315 17.4358 35.5122 15.1186 35.5122 11.3911V1.01453H38.7864V11.3911C38.7864 13.4564 39.9953 14.5142 42.4132 14.5142C44.831 14.5142 45.9392 13.5067 45.9392 11.3911Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M64.2242 1.01453V11.24L56.5173 1.01453H53.7468V16.8817H56.9203V6.50506L64.7783 16.8817H67.448V1.01453H64.2242Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M82.9626 1.01453H87.1435L79.7892 8.11696L86.8412 16.8817H82.8619L77.6232 10.2829L75.9609 11.8948V16.8817H72.6868V1.01453H75.9609V7.81473L82.9626 1.01453Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M120.439 1.01453V13.9601H128.7V16.8817H117.216V1.01453H120.439Z",
    fill: "#00273D"
  }), _jsx("path", {
    d: "M141.545 1.01453V11.3911C141.545 13.5067 140.436 14.5142 138.019 14.5142C135.601 14.5142 134.392 13.4564 134.392 11.3911V1.01453H131.118V11.3911C131.118 15.1186 133.737 17.4358 137.918 17.4358C142.149 17.4358 144.768 15.1186 144.768 11.3911V1.01453H141.545Z",
    fill: "#00273D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M163.155 5.54799C163.155 7.10952 162.449 8.26807 161.19 8.82216C162.55 9.37626 163.356 10.4844 163.356 12.0963V12.7008C163.356 13.9601 162.852 16.932 158.017 16.932H150.461V1.01453H157.916C162.449 1.01453 163.155 3.63386 163.155 5.19539V5.54799ZM157.815 7.61324C158.823 7.61324 159.981 7.36138 159.981 5.9006V5.44725C159.981 4.33907 159.326 3.83535 158.017 3.83535H153.735V7.61324H157.815ZM157.866 10.2326H153.685V14.1112H158.067C159.377 14.1112 160.032 13.5571 160.032 12.4993V11.9452C160.032 10.837 159.276 10.2326 157.866 10.2326Z",
    fill: "#00273D"
  })]
}), 'TrunkClubLogo', 'Company');
export default TrunkClubLogo;