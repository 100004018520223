/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TredenceLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 152,
  height: 32,
  viewBox: "0 0 152 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#TredenceLogo_svg__clip0_3_8)",
    children: [_jsx("mask", {
      id: "TredenceLogo_svg__mask0_3_8",
      style: {
        maskType: 'luminance'
      },
      maskUnits: "userSpaceOnUse",
      x: 0,
      y: 0,
      width: 152,
      height: 32,
      children: _jsx("path", {
        d: "M151.866 0H0V32H151.866V0Z",
        fill: "white"
      })
    }), _jsxs("g", {
      mask: "url(#TredenceLogo_svg__mask0_3_8)",
      children: [_jsx("path", {
        d: "M66.6077 -0.000640869H58.803V16.5622H66.6077C71.6066 16.5622 74.6958 12.6726 74.6958 8.26845C74.6958 3.81182 71.6047 -0.000640869 66.6077 -0.000640869ZM66.6077 14.1146L61.4822 14.2925V2.44693H66.6077C69.9563 2.44693 71.8597 5.28049 71.8597 8.29439C71.8597 11.2823 69.9538 14.1159 66.6077 14.1159",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M0 2.44693H6.46505V16.5622H9.14421V2.44693H14.1741L15.5833 0.00315577V-0.000640869H0V2.44693Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M139.296 9.50489H148.647V7.03201H139.296V2.44757H150.46L151.866 0H136.616V16.5629H151.866V14.1159H139.296V9.50489Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M31.1407 9.47894C33.9249 9.24735 35.1329 7.10921 35.1329 4.76541C35.1335 2.21407 33.5876 -0.000640869 30.5997 -0.000640869H19.2414V16.5622H21.9199V9.50425H27.844L31.9133 16.5622H34.9013L30.806 9.47894H31.1407ZM30.5997 7.03201H21.9193V2.44693H30.5997C31.8874 2.44693 32.4543 3.58023 32.4543 4.78819C32.4543 6.04994 31.9393 7.02884 30.5997 7.02884",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M120.711 8.2944C120.711 5.2805 122.617 2.44694 125.963 2.44694H131.124L132.535 0H125.966C120.967 0 117.878 3.81183 117.878 8.26845C117.878 12.6732 120.969 16.5629 125.966 16.5629H132.535V14.1153H125.966C122.617 14.1153 120.714 11.2817 120.714 8.29377",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M111.142 0V12.3125L100.762 0H98.2381V16.5629H100.915V4.2244L111.293 16.5635H113.824V0H111.145H111.142Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M92.5336 2.44693L93.9365 -0.000640869H78.6866V16.5622H93.9365V14.1153H81.3665V9.50425H90.717V7.03201H81.3665V2.44693H92.5336Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M53.0973 2.44693L54.5027 -0.000640869H39.2529V16.5622H54.5027V14.1153H41.9327V9.50425H51.2832V7.03201H41.9327V2.44693H53.0973Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M66.6077 -0.000640869H58.803V16.5622H66.6077C71.6066 16.5622 74.6958 12.6726 74.6958 8.26845C74.6958 3.81182 71.6047 -0.000640869 66.6077 -0.000640869ZM66.6077 14.1146L61.4822 14.2925V2.44693H66.6077C69.9563 2.44693 71.8597 5.28049 71.8597 8.29439C71.8597 11.2823 69.9538 14.1159 66.6077 14.1159",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M0 2.44693H6.46505V16.5622H9.14421V2.44693H14.1741L15.5833 0.00315577V-0.000640869H0V2.44693Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M139.296 9.50489H148.647V7.03201H139.296V2.44757H150.46L151.866 0H136.616V16.5629H151.866V14.1159H139.296V9.50489Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M31.1407 9.47894C33.9249 9.24735 35.1329 7.10921 35.1329 4.76541C35.1335 2.21407 33.5876 -0.000640869 30.5997 -0.000640869H19.2414V16.5622H21.9199V9.50425H27.844L31.9133 16.5622H34.9013L30.806 9.47894H31.1407ZM30.5997 7.03201H21.9193V2.44693H30.5997C31.8874 2.44693 32.4543 3.58023 32.4543 4.78819C32.4543 6.04994 31.9393 7.02884 30.5997 7.02884",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M120.711 8.2944C120.711 5.2805 122.617 2.44694 125.963 2.44694H131.124L132.535 0H125.966C120.967 0 117.878 3.81183 117.878 8.26845C117.878 12.6732 120.969 16.5629 125.966 16.5629H132.535V14.1153H125.966C122.617 14.1153 120.714 11.2817 120.714 8.29377",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M111.142 0V12.3125L100.762 0H98.2381V16.5629H100.915V4.2244L111.293 16.5635H113.824V0H111.145H111.142Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M92.5336 2.44693L93.9365 -0.000640869H78.6866V16.5622H93.9365V14.1153H81.3665V9.50425H90.717V7.03201H81.3665V2.44693H92.5336Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M53.0973 2.44693L54.5027 -0.000640869H39.2529V16.5622H54.5027V14.1153H41.9327V9.50425H51.2832V7.03201H41.9327V2.44693H53.0973Z",
        fill: "#FE5000"
      }), _jsx("path", {
        d: "M43.2697 26.8701C43.4823 27.1369 43.6067 27.4633 43.6256 27.804C43.6446 28.1447 43.5571 28.4828 43.3754 28.7716C43.1948 29.0425 42.9409 29.2565 42.6433 29.3885C42.2931 29.5453 41.9125 29.6223 41.5289 29.6138H38.9688V23.2146H41.4094C41.7957 23.2044 42.1794 23.2798 42.5332 23.4354C42.8214 23.5618 43.0675 23.7679 43.2425 24.0296C43.4019 24.2781 43.485 24.5677 43.4817 24.8629C43.4935 25.1948 43.3892 25.5204 43.1868 25.7836C42.9799 26.0366 42.705 26.2251 42.3946 26.3272C42.744 26.3922 43.0561 26.5862 43.2691 26.8707M40.0185 25.9038H41.317C41.605 25.921 41.8901 25.8378 42.1238 25.6685C42.2214 25.586 42.2985 25.482 42.3489 25.3645C42.3993 25.2471 42.4216 25.1196 42.4142 24.992C42.4207 24.8645 42.3979 24.7371 42.3475 24.6198C42.2972 24.5024 42.2206 24.3981 42.1238 24.3149C41.8927 24.1401 41.6068 24.0534 41.3176 24.0707H40.0191L40.0185 25.9038ZM42.275 28.4982C42.3768 28.4093 42.4571 28.2983 42.5097 28.1738C42.5623 28.0493 42.5859 27.9144 42.5787 27.7794C42.5847 27.6389 42.5588 27.4989 42.5031 27.3698C42.4473 27.2407 42.363 27.1259 42.2566 27.034C42.0145 26.8405 41.71 26.7421 41.4005 26.7575H40.0191V28.7558H41.4372C41.7383 28.7734 42.0358 28.682 42.275 28.4982Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M49.3842 27.4693H45.5072C45.5226 27.8415 45.6848 28.1923 45.9584 28.4451C46.2302 28.6913 46.5865 28.8232 46.9531 28.8133C47.2009 28.83 47.448 28.7711 47.6618 28.6445C47.8755 28.5178 48.0458 28.3294 48.1503 28.104H49.2829C49.1337 28.5649 48.8419 28.9664 48.4496 29.2506C48.013 29.5591 47.4873 29.716 46.9531 29.6973C46.4976 29.7047 46.0482 29.5919 45.6502 29.3702C45.2684 29.1546 44.9553 28.8354 44.7472 28.4495C44.5227 28.0259 44.4103 27.5519 44.4207 27.0726C44.4099 26.5944 44.5186 26.1211 44.7371 25.6957C44.9392 25.3096 45.2498 24.9911 45.6306 24.7794C46.0356 24.5593 46.4909 24.4483 46.9518 24.4573C47.3984 24.4494 47.8394 24.5573 48.2319 24.7706C48.6012 24.9745 48.9046 25.2796 49.1064 25.6501C49.3219 26.0501 49.4308 26.4987 49.4228 26.953C49.4239 27.1256 49.4114 27.298 49.3855 27.4687M48.3268 26.6214C48.3306 26.4454 48.2954 26.2707 48.2237 26.1099C48.1521 25.9491 48.0458 25.8061 47.9124 25.6912C47.6327 25.4544 47.2751 25.3297 46.9088 25.3413C46.566 25.3339 46.2334 25.4574 45.9786 25.6868C45.7142 25.9301 45.5501 26.2634 45.5186 26.6214H48.3268Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M55.0197 24.5402L51.907 31.9994H50.8206L51.852 29.5315L49.8537 24.5402H51.0231L52.45 28.4077L53.9326 24.5402H55.0197Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M56.7143 29.3702C56.33 29.1549 56.014 28.8359 55.8024 28.4495C55.5747 28.0269 55.4605 27.5525 55.4709 27.0726C55.4617 26.593 55.5794 26.1196 55.8119 25.7001C56.0285 25.3115 56.3508 24.9922 56.7415 24.7794C57.148 24.5674 57.5997 24.4566 58.0583 24.4566C58.5168 24.4566 58.9685 24.5674 59.3751 24.7794C59.7659 24.9923 60.0884 25.3115 60.3053 25.7001C60.5374 26.1197 60.6548 26.5931 60.6457 27.0726C60.6547 27.553 60.5338 28.027 60.2958 28.4444C60.0705 28.8357 59.7405 29.1563 59.3428 29.3702C58.9345 29.5893 58.4774 29.7016 58.014 29.6967C57.5601 29.7032 57.1124 29.5906 56.7155 29.3702M58.7739 28.5919C59.0165 28.4565 59.2159 28.2554 59.3491 28.0116C59.5041 27.7235 59.5805 27.3996 59.5706 27.0726C59.5807 26.7481 59.5077 26.4264 59.3586 26.138C59.2302 25.8964 59.0352 25.6968 58.7967 25.5628C58.5659 25.4343 58.3059 25.3677 58.0418 25.3691C57.7791 25.3673 57.5204 25.434 57.2914 25.5628C57.0562 25.6966 56.8657 25.8966 56.7434 26.138C56.6005 26.4283 56.5309 26.7492 56.5409 27.0726C56.5113 27.5328 56.6615 27.9865 56.9598 28.3381C57.0935 28.4835 57.2567 28.5985 57.4385 28.6756C57.6204 28.7526 57.8166 28.7899 58.014 28.7849C58.2794 28.7859 58.5408 28.7193 58.7733 28.5912",
        fill: "#404041"
      }), _jsx("path", {
        d: "M65.3231 24.7066C65.6388 24.872 65.8972 25.1287 66.0647 25.4432C66.2515 25.8074 66.3431 26.2129 66.3311 26.622V29.6151H65.2908V26.779C65.3164 26.3997 65.1945 26.0251 64.9504 25.7336C64.8301 25.6105 64.6849 25.5143 64.5245 25.4516C64.3641 25.3889 64.1922 25.361 64.0202 25.3698C63.8476 25.3613 63.6752 25.3893 63.5141 25.452C63.3531 25.5147 63.2071 25.6106 63.0856 25.7336C62.8385 26.0237 62.7145 26.3988 62.7401 26.779V29.6151H61.6904V24.5409H62.7401V25.1211C62.9173 24.9081 63.143 24.7406 63.3982 24.6326C63.6669 24.5159 63.957 24.4564 64.2499 24.458C64.6235 24.4514 64.9929 24.5368 65.3257 24.7066",
        fill: "#404041"
      }), _jsx("path", {
        d: "M67.6315 25.7007C67.8302 25.3204 68.1306 25.0027 68.4994 24.7831C68.8681 24.5636 69.2906 24.4508 69.7196 24.4573C70.0583 24.4589 70.3926 24.5326 70.7004 24.6738C70.9995 24.8004 71.2633 24.9978 71.4693 25.2489V22.8001H72.5285V29.6144H71.4693V28.8507C71.2839 29.1079 71.039 29.3163 70.7555 29.4582C70.4319 29.6223 70.0729 29.7045 69.7102 29.6973C69.2829 29.7016 68.863 29.5851 68.499 29.3613C68.1301 29.1333 67.8307 28.8086 67.6334 28.4223C67.4171 27.9994 67.3085 27.5298 67.317 27.0549C67.3074 26.5843 67.4162 26.1189 67.6334 25.7014M71.2529 26.1614C71.1213 25.9171 70.9254 25.7134 70.6865 25.5723C70.4573 25.4384 70.1964 25.3684 69.931 25.3698C69.6609 25.3675 69.3952 25.4387 69.1625 25.5758C68.9297 25.7129 68.7387 25.9107 68.6097 26.1481C68.4597 26.4263 68.3851 26.7389 68.3933 27.0549C68.3863 27.3752 68.4607 27.692 68.6097 27.9756C68.7402 28.2253 68.9374 28.434 69.1793 28.5786C69.4061 28.7141 69.6655 28.7856 69.9297 28.7855C70.1952 28.7869 70.4561 28.7169 70.6853 28.583C70.9252 28.441 71.1212 28.2354 71.2516 27.9889C71.4009 27.707 71.4754 27.3915 71.468 27.0726C71.4758 26.7551 71.4012 26.4409 71.2516 26.1608",
        fill: "#404041"
      }), _jsx("path", {
        d: "M80.5464 26.0418C80.3775 26.3454 80.1207 26.5909 79.8099 26.7461C79.4059 26.9418 78.96 27.035 78.5114 27.0175H77.3528V29.6119H76.303V23.2146H78.5133C78.9432 23.2017 79.3703 23.2883 79.7611 23.4677C80.0783 23.6155 80.3444 23.8542 80.5255 24.1536C80.6956 24.4472 80.783 24.7813 80.7786 25.1205C80.7796 25.4418 80.7005 25.7582 80.5483 26.0412M79.4093 25.8893C79.5106 25.7897 79.5894 25.6695 79.6402 25.5369C79.6911 25.4043 79.7129 25.2623 79.7042 25.1205C79.7042 24.4206 79.3081 24.0707 78.5158 24.0707H77.3528V26.1589H78.5127C78.8341 26.1842 79.1532 26.0872 79.4062 25.8874",
        fill: "#404041"
      }), _jsx("path", {
        d: "M82.6832 29.3702C82.299 29.1548 81.9831 28.8358 81.7714 28.4495C81.5438 28.0269 81.4296 27.5525 81.4398 27.0726C81.4307 26.5931 81.5481 26.1197 81.7803 25.7001C81.9971 25.3114 82.3196 24.9922 82.7104 24.7794C83.117 24.5674 83.5687 24.4566 84.0273 24.4566C84.4858 24.4566 84.9375 24.5674 85.3441 24.7794C85.7348 24.9924 86.0573 25.3116 86.2742 25.7001C86.5064 26.1197 86.6238 26.5931 86.6147 27.0726C86.6238 27.553 86.5029 28.027 86.2647 28.4444C86.0396 28.8358 85.7095 29.1564 85.3118 29.3702C84.9035 29.5893 84.4464 29.7016 83.983 29.6967C83.5291 29.7031 83.0814 29.5905 82.6845 29.3702M84.7429 28.5919C84.9857 28.4565 85.1853 28.2554 85.3187 28.0116C85.4734 27.7234 85.5495 27.3995 85.5396 27.0726C85.5497 26.7481 85.4767 26.4264 85.3276 26.138C85.1992 25.8964 85.0042 25.6968 84.7657 25.5628C84.5347 25.4343 84.2745 25.3676 84.0102 25.3691C83.7474 25.3673 83.4887 25.434 83.2597 25.5628C83.0245 25.6965 82.8339 25.8966 82.7117 26.138C82.5687 26.4282 82.4992 26.7492 82.5092 27.0726C82.4797 27.5328 82.6298 27.9865 82.9281 28.3381C83.0618 28.4835 83.2251 28.5985 83.4069 28.6756C83.5887 28.7526 83.7849 28.7899 83.9823 28.7849C84.2478 28.7859 84.5091 28.7193 84.7417 28.5912",
        fill: "#404041"
      }), _jsx("path", {
        d: "M88.4244 29.4809C88.1278 29.3523 87.8693 29.1494 87.674 28.8918C87.4925 28.6508 87.3896 28.3599 87.3791 28.0585H88.4656C88.475 28.1652 88.5075 28.2686 88.5608 28.3616C88.6141 28.4546 88.687 28.5349 88.7744 28.5969C88.9782 28.7464 89.2266 28.8227 89.4793 28.8134C89.7199 28.8275 89.9591 28.7681 90.1652 28.6431C90.2397 28.5971 90.3012 28.5327 90.3438 28.4562C90.3865 28.3797 90.4089 28.2935 90.4088 28.2059C90.411 28.1162 90.3863 28.0279 90.3379 27.9524C90.2896 27.8768 90.2197 27.8175 90.1374 27.7819C89.8599 27.6552 89.5717 27.5536 89.2762 27.4782C88.9604 27.3954 88.6498 27.2939 88.346 27.1745C88.1059 27.0757 87.8928 26.921 87.7246 26.7233C87.5415 26.4977 87.448 26.2124 87.462 25.9222C87.4605 25.6558 87.5443 25.3959 87.7012 25.1806C87.8765 24.9441 88.1139 24.7607 88.3871 24.651C88.7125 24.5167 89.0621 24.4508 89.4141 24.4573C89.9153 24.4339 90.4086 24.589 90.8062 24.8952C90.982 25.0406 91.1241 25.2226 91.2224 25.4285C91.3208 25.6344 91.3731 25.8592 91.3757 26.0874H90.3259C90.3205 25.9825 90.293 25.88 90.2454 25.7865C90.1978 25.693 90.131 25.6105 90.0494 25.5444C89.8559 25.4006 89.6182 25.3289 89.3774 25.342C89.1555 25.3285 88.9347 25.383 88.7446 25.4982C88.6757 25.543 88.6193 25.6044 88.5807 25.6769C88.542 25.7494 88.5225 25.8306 88.5238 25.9127C88.5226 25.9767 88.5352 26.0402 88.5606 26.0989C88.586 26.1576 88.6238 26.2102 88.6712 26.2531C88.7751 26.3489 88.8974 26.4225 89.0306 26.4696C89.1715 26.5219 89.3803 26.5879 89.6571 26.6676C89.9659 26.7461 90.269 26.8461 90.5639 26.9669C90.7992 27.0675 91.0087 27.2203 91.1764 27.4137C91.3567 27.6339 91.4515 27.9118 91.4434 28.1964C91.4472 28.4731 91.3635 28.7439 91.2042 28.9703C91.032 29.2072 90.7977 29.3921 90.5271 29.5043C90.2021 29.6398 89.8522 29.7057 89.5002 29.698C89.1301 29.7046 88.7631 29.6301 88.4244 29.4809Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M93.3423 29.4809C93.0457 29.3523 92.7872 29.1494 92.5919 28.8918C92.4104 28.6509 92.3075 28.3599 92.297 28.0585H93.3835C93.3929 28.1652 93.4254 28.2686 93.4788 28.3616C93.5321 28.4546 93.6049 28.5349 93.6923 28.597C93.8959 28.7464 94.1441 28.8227 94.3965 28.8134C94.6371 28.8275 94.8764 28.7681 95.0825 28.6431C95.1571 28.5971 95.2187 28.5328 95.2614 28.4562C95.3042 28.3797 95.3266 28.2935 95.3267 28.2059C95.3289 28.1162 95.3042 28.0279 95.2558 27.9524C95.2075 27.8768 95.1376 27.8175 95.0553 27.7819C94.7778 27.6552 94.4896 27.5535 94.1941 27.4782C93.8783 27.3953 93.5677 27.2939 93.2639 27.1745C93.0238 27.0757 92.8108 26.921 92.6425 26.7233C92.4595 26.4976 92.3661 26.2124 92.3799 25.9222C92.3785 25.6558 92.4623 25.396 92.6191 25.1806C92.7944 24.9441 93.0318 24.7608 93.305 24.651C93.6304 24.5167 93.98 24.4508 94.332 24.4573C94.8332 24.4338 95.3266 24.589 95.7241 24.8952C95.9 25.0406 96.042 25.2226 96.1404 25.4285C96.2387 25.6344 96.291 25.8592 96.2936 26.0874H95.2438C95.2385 25.9825 95.211 25.88 95.1634 25.7864C95.1158 25.6929 95.0489 25.6104 94.9673 25.5445C94.7736 25.4005 94.5356 25.3289 94.2947 25.342C94.0728 25.3284 93.852 25.3829 93.6619 25.4983C93.593 25.543 93.5366 25.6045 93.4979 25.677C93.4593 25.7495 93.4397 25.8306 93.4411 25.9127C93.44 25.9767 93.4526 26.0402 93.478 26.0989C93.5034 26.1576 93.5411 26.2102 93.5885 26.2532C93.6925 26.3488 93.8147 26.4224 93.9479 26.4696C94.0888 26.5219 94.2976 26.5879 94.5743 26.6676C94.8832 26.7461 95.1862 26.8461 95.4811 26.9669C95.7165 27.0676 95.9259 27.2203 96.0936 27.4137C96.2739 27.634 96.3687 27.9119 96.3607 28.1964C96.3644 28.4731 96.2807 28.744 96.1215 28.9703C95.9495 29.2072 95.7154 29.392 95.4451 29.5043C95.1199 29.6397 94.7701 29.7057 94.418 29.698C94.048 29.7046 93.681 29.6301 93.3423 29.4809Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M97.5459 23.6746C97.4506 23.5805 97.3857 23.4601 97.3593 23.3288C97.3329 23.1975 97.3463 23.0614 97.3977 22.9377C97.4491 22.8141 97.5363 22.7087 97.648 22.6349C97.7598 22.5611 97.891 22.5223 98.0249 22.5236C98.1122 22.522 98.199 22.5384 98.2797 22.5717C98.3605 22.605 98.4335 22.6545 98.4944 22.7172C98.6188 22.8456 98.6884 23.0174 98.6884 23.1962C98.6884 23.375 98.6188 23.5468 98.4944 23.6752C98.4335 23.7379 98.3605 23.7874 98.2797 23.8207C98.1989 23.854 98.1122 23.8704 98.0249 23.8688C97.9361 23.8709 97.8477 23.8548 97.7654 23.8215C97.683 23.7882 97.6083 23.7378 97.5459 23.6746ZM98.5406 29.6151H97.4908V24.5377H98.5406V29.6151Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M101.704 24.6927C102.026 24.5332 102.381 24.4525 102.74 24.4573C103.167 24.4518 103.587 24.5634 103.955 24.7801C104.321 24.999 104.618 25.3166 104.811 25.6963C105.025 26.1164 105.133 26.5828 105.124 27.0542C105.132 27.5287 105.025 27.9979 104.811 28.4217C104.617 28.8084 104.319 29.1335 103.95 29.3607C103.611 29.5614 103.229 29.6761 102.835 29.6946C102.442 29.7131 102.05 29.635 101.694 29.467C101.41 29.33 101.162 29.1279 100.971 28.8773V29.6138H99.9213V22.8001H100.971V25.2958C101.163 25.0387 101.414 24.8319 101.703 24.6927M103.839 26.1481C103.707 25.9054 103.51 25.7045 103.27 25.5679C103.038 25.4372 102.777 25.3689 102.511 25.3698C102.247 25.3697 101.988 25.4396 101.76 25.5723C101.519 25.7123 101.322 25.9162 101.191 26.1614C101.041 26.4415 100.966 26.7557 100.974 27.0732C100.967 27.3921 101.041 27.7076 101.191 27.9895C101.318 28.2298 101.508 28.4309 101.741 28.5713C101.974 28.7116 102.241 28.7859 102.512 28.7861C102.78 28.7869 103.042 28.7154 103.272 28.5792C103.514 28.4347 103.711 28.226 103.841 27.9762C103.99 27.6926 104.065 27.3758 104.058 27.0555C104.066 26.7396 103.991 26.4269 103.841 26.1487",
        fill: "#404041"
      }), _jsx("path", {
        d: "M107.215 22.8001H106.166V29.6145H107.215V22.8001Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M113.21 27.4693H109.333C109.349 27.8415 109.511 28.1923 109.784 28.4451C110.056 28.6913 110.412 28.8232 110.779 28.8134C111.027 28.8301 111.274 28.7712 111.488 28.6446C111.702 28.518 111.872 28.3295 111.976 28.104H113.109C112.96 28.5648 112.668 28.9663 112.276 29.2506C111.839 29.5591 111.313 29.716 110.779 29.6973C110.324 29.7046 109.874 29.5917 109.476 29.3702C109.095 29.1545 108.782 28.8353 108.574 28.4495C108.349 28.026 108.236 27.552 108.247 27.0726C108.236 26.5944 108.345 26.1212 108.563 25.6957C108.765 25.3097 109.075 24.9912 109.456 24.7794C109.861 24.5592 110.317 24.4482 110.778 24.4573C111.224 24.4493 111.665 24.5572 112.058 24.7706C112.427 24.9744 112.731 25.2795 112.933 25.6501C113.148 26.0501 113.257 26.4988 113.249 26.953C113.251 27.1256 113.238 27.2981 113.212 27.4687M112.153 26.6214C112.157 26.4454 112.121 26.2708 112.05 26.11C111.978 25.9492 111.872 25.8062 111.738 25.6912C111.459 25.4544 111.101 25.3297 110.735 25.3413C110.392 25.3339 110.059 25.4574 109.805 25.6868C109.54 25.9301 109.376 26.2634 109.345 26.6214H112.153Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M151.866 26.7524H118.3V27.4295H151.866V26.7524Z",
        fill: "#404041"
      }), _jsx("path", {
        d: "M33.5661 26.7524H0V27.4295H33.5661V26.7524Z",
        fill: "#404041"
      })]
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "TredenceLogo_svg__clip0_3_8",
      children: _jsx("rect", {
        width: 151.866,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'TredenceLogo', 'Company');
export default TredenceLogo;