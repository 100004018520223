/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var OktaLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 78,
  height: 32,
  viewBox: "0 0 78 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M10.3179 9.52417C4.95187 9.52417 0.608887 13.8672 0.608887 19.2332C0.608887 24.5992 4.95187 28.9421 10.3179 28.9421C15.6839 28.9421 20.0269 24.5992 20.0269 19.2332C20.0269 13.8672 15.6839 9.52417 10.3179 9.52417ZM10.3179 24.0973C7.63487 24.0973 5.45373 21.9162 5.45373 19.2332C5.45373 16.5502 7.63487 14.369 10.3179 14.369C13.0009 14.369 15.182 16.5502 15.182 19.2332C15.182 21.9162 13.0009 24.0973 10.3179 24.0973Z",
    fill: "#007DC1"
  }), _jsx("path", {
    d: "M27.3808 22.4952C27.3808 21.7231 28.3073 21.3563 28.8478 21.8968C31.2798 24.3675 35.2947 28.614 35.314 28.6333C35.3719 28.6912 35.4298 28.7877 35.6614 28.8649C35.7579 28.9035 35.9124 28.9035 36.0861 28.9035H40.4677C41.2591 28.9035 41.4907 27.9963 41.1239 27.533L33.8663 20.1017L33.4803 19.7157C32.6503 18.7313 32.7468 18.3452 33.6926 17.3415L39.4446 10.9139C39.8114 10.4506 39.5798 9.56273 38.7691 9.56273H34.7928C34.6384 9.56273 34.5226 9.56273 34.4068 9.60134C34.1752 9.67854 34.0787 9.75575 34.0207 9.83296C34.0014 9.85226 30.8166 13.288 28.8478 15.392C28.3073 15.971 27.3422 15.585 27.3422 14.7936V3.77209C27.3422 3.21232 26.8789 3 26.5122 3H23.2694C22.7097 3 22.4395 3.36674 22.4395 3.69488V28.1314C22.4395 28.6912 22.9027 28.8456 23.2887 28.8456H26.5315C27.0334 28.8456 27.3615 28.4788 27.3615 28.1121V27.8612V22.4952H27.3808Z",
    fill: "#007DC1"
  }), _jsx("path", {
    d: "M53.8634 28.0156L53.5159 24.7728C53.4773 24.3289 53.0527 24.02 52.6087 24.0973C52.3578 24.1359 52.1069 24.1552 51.856 24.1552C49.2695 24.1552 47.1655 22.1284 47.0111 19.5612C47.0111 19.484 47.0111 19.3875 47.0111 19.291V15.3148C47.0111 14.7936 47.3972 14.369 47.9183 14.369H52.2613C52.5701 14.369 53.0334 14.0987 53.0334 13.539V10.4699C53.0334 9.87157 52.6473 9.56273 52.2999 9.56273H47.9183C47.4165 9.56273 47.0111 9.19599 46.9918 8.69414V3.77209C46.9918 3.46325 46.7602 3 46.1618 3H42.9384C42.533 3 42.147 3.25093 42.147 3.75278C42.147 3.75278 42.147 19.484 42.147 19.5612C42.2821 24.8114 46.5865 29 51.856 29C52.2999 29 52.7246 28.9614 53.1492 28.9035C53.6125 28.8456 53.9213 28.4595 53.8634 28.0156Z",
    fill: "#007DC1"
  }), _jsx("path", {
    d: "M77.2576 23.9429C74.5167 23.9429 74.0921 22.9585 74.0921 19.2718C74.0921 19.2525 74.0921 19.2525 74.0921 19.2332V10.3735C74.0921 10.0646 73.8604 9.54349 73.2428 9.54349H70C69.5947 9.54349 69.1507 9.87162 69.1507 10.3735V10.7788C67.7416 9.96813 66.101 9.50488 64.3638 9.50488C58.9978 9.50488 54.6548 13.8479 54.6548 19.2139C54.6548 24.5799 58.9978 28.9228 64.3638 28.9228C66.7765 28.9228 68.977 28.0349 70.6756 26.5873C71.5828 27.977 73.0497 28.9035 75.3467 28.9228C75.7327 28.9228 77.8174 29.0001 77.8174 28.0156V24.5606C77.8174 24.271 77.5857 23.9429 77.2576 23.9429ZM64.3831 24.0973C61.7001 24.0973 59.5189 21.9162 59.5189 19.2332C59.5189 16.5502 61.7001 14.369 64.3831 14.369C67.0661 14.369 69.2472 16.5502 69.2472 19.2332C69.2279 21.9162 67.0468 24.0973 64.3831 24.0973Z",
    fill: "#007DC1"
  })]
}), 'OktaLogo', 'Company');
export default OktaLogo;