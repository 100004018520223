/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UptvLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 52,
  height: 32,
  viewBox: "0 0 52 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M47.6019 2.05139L47.3166 9.55899L42.1828 4.01318L38.7148 5.28724L46.2719 13.5285C46.9099 14.242 47.6538 14.6171 48.3931 14.6171C48.6481 14.6171 48.9025 14.5725 49.1518 14.4817C50.1176 14.1303 50.6871 13.1779 50.7142 11.8707L51.0391 0.800293L47.6019 2.05139Z",
    fill: "#F8931D"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M42.9058 12.5386L42.7187 12.6675C42.2052 13.0209 41.5975 13.4123 40.9839 13.6355C40.1767 13.9293 39.1107 13.9928 38.6135 12.6273L37.4034 9.30231L39.6226 8.48569L38.4895 5.37246L36.269 6.18583L35.1366 3.07434L31.5295 4.38715L32.6621 7.49911L30.666 8.22559L31.8004 11.3424L33.7965 10.6159L35.015 13.9636C35.5504 15.435 36.448 16.5266 37.6107 17.1205C38.2466 17.4453 38.9357 17.6083 39.6545 17.6083C40.2571 17.6083 40.8807 17.4937 41.5113 17.2642C42.6123 16.8636 43.2043 16.5265 44.3903 15.6248L44.5305 15.5182L42.9058 12.5386Z",
    fill: "#F8931D"
  }), _jsx("mask", {
    id: "UptvLogo_svg__mask0_11098_127000",
    style: {
      maskType: 'alpha'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 0,
    width: 32,
    height: 32,
    children: _jsx("path", {
      d: "M0 0H31.985V32H0V0Z",
      fill: "white"
    })
  }), _jsxs("g", {
    mask: "url(#UptvLogo_svg__mask0_11098_127000)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M24.3118 10.8636L19.9336 12.4571L21.4203 16.542L25.7984 14.9485C26.8815 14.5541 27.2261 13.2532 26.8648 12.2601C26.5033 11.2673 25.3949 10.4693 24.3118 10.8636Z",
      fill: "#F8931D"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M27.0884 18.4917L22.7102 20.0854L23.8025 23.0868L20.2593 24.3765L15.1008 10.2038L23.0222 7.32059C26.0914 6.20338 29.3236 7.99162 30.4079 10.9709C31.4923 13.9496 30.1577 17.3746 27.0884 18.4917ZM12.7313 27.4997C8.87226 28.9046 4.76792 26.6158 3.4043 22.8694L0.693631 15.4222L4.23669 14.1326L6.94736 21.5799C7.59646 23.3629 9.53783 24.6241 11.4335 23.9341C13.3293 23.2442 14.0056 21.0301 13.3569 19.2469L10.6463 11.7995L14.1894 10.51L16.9 17.9574C18.2637 21.7037 16.5907 26.0951 12.7313 27.4997ZM16.0837 0C7.24969 0 -0.00732422 7.16989 -0.00732422 16.0044C-0.00732422 24.8385 7.15421 32 15.9883 32C24.8228 32 31.9847 24.8386 31.9847 16.0042C31.9847 7.16978 24.9183 0 16.0837 0Z",
      fill: "#F8931D"
    })]
  })]
}), 'UptvLogo', 'Company');
export default UptvLogo;