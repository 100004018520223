/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TradeMeLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 181,
  height: 33,
  viewBox: "0 0 181 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M161.74 23.649a15.16 15.16 0 0 0-4.875.01l-.084.015c-1.642.28-3.222.825-4.695 1.62-2.612 1.208-.797 4.245 1.25 3.047 2.411-1.424 5.028-2.305 7.974-2.156 1.926.143 2.01-2.314.43-2.536ZM130.07 28.073a15.826 15.826 0 0 0 2.665-4.17l.034-.078a16.07 16.07 0 0 0 1.247-4.898c.443-2.894-3.04-3.009-3.181-.604-.155 2.84-.868 5.558-2.605 7.992-1.174 1.565.792 2.986 1.84 1.758Z",
    fill: "#148FE2"
  }), _jsx("path", {
    d: "M179.485 12.569a.705.705 0 0 0-.048-.013l-.691-.16a50.706 50.706 0 0 0-3.065-.609c-.439-.077-.904-.142-1.386-.213-.482-.063-.981-.129-1.491-.179-.509-.058-1.029-.094-1.548-.134a41.987 41.987 0 0 0-1.553-.074c-.513-.02-1.018-.016-1.507-.018h-.11a5.45 5.45 0 0 1 .173 2.296l.05-.004c.461-.026.938-.06 1.424-.07.485-.02.979-.022 1.473-.023.495.007.99.01 1.477.036.488.016.968.05 1.432.08.464.04.915.075 1.34.123a48.645 48.645 0 0 1 2.996.404l.681.113.016.003a.79.79 0 0 0 .901-.61.813.813 0 0 0-.564-.948Z",
    fill: "#F9AF2C"
  }), _jsx("path", {
    d: "M168.086 11.17c-.502-1.69-1.83-3.1-3.751-3.718-7.361-2.366-17.187-2.721-23.561.185-2.645 1.206-4.844 3.45-5.712 6.512-1.513 5.348 1.505 10.935 6.741 12.481 3.316.979 6.71.132 9.165-2.031 3.144-2.772 5.663-5.802 12.172-6.393 2.615-.237 4.301-1.646 4.953-3.949.075-.264.129-.529.165-.792a5.448 5.448 0 0 0-.172-2.295Z",
    fill: "#148FE2"
  }), _jsx("path", {
    d: "M165.988 13.593c-.457 1.612-2.106 2.541-3.685 2.075-1.578-.466-2.487-2.15-2.031-3.762.457-1.612 2.106-2.54 3.684-2.075 1.578.466 2.488 2.15 2.032 3.762Z",
    fill: "#fff"
  }), _jsx("path", {
    d: "M165.176 11.96c.004.03.01.06.01.09a.765.765 0 0 1-.757.773.765.765 0 0 1-.757-.773c0-.41.313-.741.707-.768l-.096-.034a1.526 1.526 0 0 0-1.894 1.067c-.235.829.233 1.695 1.045 1.935a1.526 1.526 0 0 0 1.894-1.067 1.582 1.582 0 0 0-.152-1.224Z",
    fill: "#333"
  }), _jsx("path", {
    d: "M169.61 4.71c-.087-.035-.183-.018-.232.06-.054.084-.04.194.051.25 1.598.975 1.91 3.483.007 4.377l.013-.077a3.542 3.542 0 0 0-.048-1.42 3.713 3.713 0 0 0-.297-.801 4.256 4.256 0 0 0-1.141-1.372 4.812 4.812 0 0 0-.74-.489 5.337 5.337 0 0 0-.78-.342 5.23 5.23 0 0 0-.766-.196 4.961 4.961 0 0 0-.704-.074 5.029 5.029 0 0 0-.597.022c-.186.02-.337.045-.457.072-.091.016-.161.037-.217.053a1.734 1.734 0 0 1-.066.018l-.097.027a.21.21 0 0 0-.146.246.205.205 0 0 0 .244.16l.096-.02.069-.016a3.841 3.841 0 0 1 1.145-.07 4.66 4.66 0 0 1 1.304.313c.192.083.442.195.676.342.263.165.461.312.626.463.384.339.702.762.894 1.191.106.253.17.456.2.642a2.727 2.727 0 0 1-.019 1.09 3.367 3.367 0 0 1-.108.376c-.022.065-.046.121-.066.166l-.029.068-.047.112-.008.02a.453.453 0 0 0-.021.08l-.002.013a.455.455 0 0 0 .014.203.442.442 0 0 0 .553.294c3.506-1.112 2.693-4.897.696-5.78Z",
    fill: "#148FE2"
  }), _jsx("path", {
    d: "M100.492 11.613c-2.443 0-4.321 1.056-5.574 2.718-1.065-1.758-2.944-2.718-5.136-2.718-2.035 0-3.57.736-4.634 1.95v-1.47h-5.01v16.052h5.01V20.28c0-2.686 1.19-4.061 3.006-4.061 1.503 0 2.662 1.151 2.662 2.974v8.953h5.01V20.28c0-2.686 1.222-4.061 3.07-4.061 1.471 0 2.63 1.151 2.63 2.974v8.953h4.979v-10.04c0-3.902-2.662-6.492-6.013-6.492ZM112.895 21.43h11.524v-1.31c-.094-5.245-3.664-8.507-8.111-8.507-4.572 0-8.329 3.454-8.329 8.506 0 5.085 3.757 8.506 8.298 8.506 3.449 0 6.205-1.501 7.615-4.605l-4.506-.953c-.847 1.223-2.055 1.465-3.109 1.465-1.691 0-2.975-1.183-3.382-3.102Zm.094-3.134a3.453 3.453 0 0 1 3.319-2.526c1.441 0 2.693.8 3.163 2.526h-6.482Z",
    fill: "#F9AF2C"
  }), _jsx("path", {
    d: "M9.927 23.413c-.658.384-1.597.64-2.286.64-1.315 0-2.098-.672-2.098-2.335v-5.564h4.635v-4.061H5.543V7.36H.533v14.87c0 4.22 2.348 6.395 6.262 6.395 1.786 0 3.07-.511 4.291-1.183l-1.159-4.03ZM41.642 12.093h-5.01v1.28c-1.222-1.12-2.85-1.76-4.886-1.76-4.165 0-7.672 3.454-7.672 8.506 0 5.084 3.507 8.506 7.672 8.506 2.036 0 3.664-.64 4.886-1.759v1.28h5.01V12.092ZM32.78 24.276c-2.192 0-3.79-1.663-3.79-4.157 0-2.462 1.598-4.157 3.79-4.157 2.035 0 3.852 1.695 3.852 4.157 0 2.494-1.817 4.157-3.852 4.157ZM60.7 5.122h-5.01v8.25c-1.222-1.12-2.85-1.759-4.886-1.759-4.165 0-7.672 3.454-7.672 8.506 0 5.084 3.507 8.506 7.672 8.506 2.035 0 3.664-.64 4.885-1.759v1.28h5.01V5.121Zm-8.863 19.154c-2.192 0-3.789-1.663-3.789-4.157 0-2.462 1.597-4.157 3.79-4.157 2.035 0 3.851 1.695 3.851 4.157 0 2.494-1.816 4.157-3.852 4.157ZM67.138 21.43h11.525v-1.31c-.094-5.245-3.664-8.507-8.111-8.507-4.573 0-8.33 3.454-8.33 8.506 0 5.085 3.757 8.506 8.299 8.506 3.45 0 6.205-1.501 7.615-4.605l-4.506-.953c-.847 1.223-2.055 1.465-3.11 1.465-1.69 0-2.974-1.183-3.382-3.102Zm.094-3.134a3.453 3.453 0 0 1 3.32-2.526c1.44 0 2.693.8 3.162 2.526h-6.482ZM23.319 11.872c-.05-.001-.098-.003-.152-.003-2.567 0-4.29 1.375-5.417 3.293v-3.069h-5.01v16.052h5.01v-5.02c0-4.13 1.886-5.984 5.348-6.01l.22-5.243Z",
    fill: "#148FE2"
  })]
}), 'TradeMeLogo', 'Company');
export default TradeMeLogo;