/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DockerLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 125,
  height: 32,
  viewBox: "0 0 125 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20.4924 4.19562H24.4287C24.6189 4.19562 24.7733 4.04203 24.7744 3.85193V0.343691C24.7733 0.153528 24.6189 0 24.4287 0H20.4924C20.3025 0 20.1487 0.15384 20.1487 0.343691V3.85187C20.1487 4.04172 20.3026 4.19562 20.4924 4.19562Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M13.4795 9.24185H9.54313C9.35266 9.24079 9.19857 9.0867 9.19751 8.89622V5.38996C9.19857 5.19979 9.35303 5.04626 9.54313 5.04626H13.4795C13.6693 5.04626 13.8232 5.20011 13.8232 5.38996V8.89622C13.8232 9.08632 13.6696 9.24079 13.4795 9.24185Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M44.1546 12.0798C44.0336 11.9838 42.9046 11.1313 40.5235 11.1313C39.8948 11.134 39.2674 11.1879 38.6474 11.2925C38.1867 8.13489 35.5768 6.59483 35.4581 6.52479L34.8186 6.15601L34.3981 6.76282C33.8715 7.57795 33.487 8.47653 33.2614 9.42036C32.835 11.2234 33.0943 12.917 34.0102 14.3648C32.9042 14.9812 31.1299 15.1329 30.7709 15.1463H1.39592C0.628655 15.1474 0.0060548 15.7674 0.00187334 16.5346C-0.0323896 19.1081 0.403605 21.6661 1.28839 24.0829C2.3003 26.7366 3.80575 28.6914 5.76436 29.8876C7.95912 31.2317 11.5249 31.9998 15.5669 31.9998C17.3929 32.0056 19.2154 31.8403 21.0106 31.5063C23.5062 31.0481 25.9075 30.1759 28.1153 28.9256C29.9345 27.8721 31.5717 26.5319 32.9638 24.9566C35.2911 22.322 36.6775 19.388 37.7086 16.7804H38.1195C40.6675 16.7804 42.2345 15.7608 43.0985 14.9063C43.6728 14.3613 44.121 13.6975 44.4119 12.9612L44.5944 12.4274L44.1546 12.0798Z",
    fill: "#0091E2"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M57.1272 10.4579C57.1488 9.80389 57.6964 9.29126 58.3504 9.31279V9.31859C58.6768 9.31129 58.9925 9.43592 59.226 9.66422C59.4644 9.89276 59.5948 10.2116 59.585 10.5418V21.5578C59.5863 21.6329 59.5863 21.7079 59.585 21.783C59.5228 25.4209 56.5233 28.3197 52.8853 28.2575C49.2472 28.1953 46.3485 25.1958 46.4106 21.5578C46.4102 20.0564 46.9224 18.5999 47.8625 17.4292C50.1415 14.5915 54.2894 14.1384 57.1272 16.4175V10.536C57.1263 10.5099 57.1263 10.4839 57.1272 10.4579ZM54.6098 25.3502C55.5969 24.9234 56.382 24.1334 56.8027 23.1439V23.1555C57.2354 22.137 57.2354 20.9864 56.8027 19.968C56.382 18.9783 55.5968 18.1885 54.6098 17.7616C53.5757 17.3238 52.4083 17.3238 51.3743 17.7616C50.3904 18.1802 49.6081 18.9653 49.193 19.9507C48.7602 20.9731 48.7602 22.1272 49.193 23.1497C49.6072 24.1384 50.3893 24.9274 51.3743 25.3502C52.4084 25.788 53.5758 25.788 54.6098 25.3502Z",
    fill: "#0091E2"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M62.7877 16.8978C65.3594 14.3238 69.5308 14.3219 72.1048 16.8936C72.724 17.4991 73.2144 18.2234 73.5468 19.0231C73.8695 19.8271 74.0351 20.6856 74.0346 21.552C74.0359 21.627 74.0359 21.7021 74.0346 21.7771C73.9724 25.4151 70.9728 28.3139 67.3348 28.2517C63.6967 28.1895 60.798 25.1899 60.8601 21.5519C60.8607 19.8064 61.5539 18.1325 62.7877 16.8978ZM70.3709 24.4496C70.7443 24.0711 71.0438 23.6263 71.2542 23.138V23.1554C71.6843 22.1364 71.6843 20.9868 71.2542 19.9679C71.0438 19.4796 70.7443 19.0348 70.3709 18.6564C69.9941 18.2779 69.5492 17.9743 69.0593 17.7616C68.0259 17.3236 66.8591 17.3236 65.8257 17.7616C64.8419 18.1778 64.0588 18.9609 63.6425 19.9448C63.2097 20.9672 63.2097 22.1214 63.6425 23.1438C64.0566 24.1332 64.8396 24.9225 65.8257 25.3444C66.8591 25.7823 68.0259 25.7823 69.0593 25.3444C69.5492 25.1317 69.9941 24.828 70.3709 24.4496Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M99.3408 16.1873C99.3417 16.0253 99.3083 15.865 99.243 15.7168C99.1765 15.5746 99.0862 15.4447 98.9761 15.3327C98.8673 15.2194 98.7367 15.1292 98.592 15.0678C98.4393 15.0047 98.2754 14.9728 98.1101 14.9736C97.8765 14.9721 97.6475 15.0388 97.4514 15.1657L90.4178 19.7415V10.5535C90.424 10.2246 90.2941 9.90772 90.0587 9.67786C89.8324 9.44008 89.517 9.30783 89.1889 9.31301C88.5113 9.30877 87.9584 9.85467 87.9542 10.5323V26.898C87.9503 27.2248 88.08 27.539 88.3133 27.7679C88.5413 28.0065 88.8588 28.1388 89.1889 28.1327C89.5159 28.138 89.8302 28.0056 90.0549 27.7679C90.2881 27.539 90.4179 27.2248 90.414 26.898V22.6583L91.8483 21.7174L97.2844 27.8217C97.5046 28.0334 97.8009 28.1476 98.1062 28.1385C98.2716 28.1403 98.4356 28.1083 98.5882 28.0444C98.7332 27.9843 98.864 27.8948 98.9722 27.7813C99.0841 27.6664 99.1745 27.5325 99.2391 27.3857C99.3045 27.2375 99.3379 27.0773 99.3371 26.9153C99.3379 26.5963 99.2161 26.289 98.9972 26.057L93.9433 20.3636L98.8705 17.1646C99.1888 16.9447 99.3675 16.5733 99.3408 16.1873Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M80.2521 17.7465C79.7603 17.9546 79.3126 18.2543 78.9329 18.6298L78.9386 18.6355L78.9211 18.653C77.3167 20.267 77.3246 22.8761 78.9386 24.4806C79.3188 24.8553 79.7664 25.155 80.2578 25.3638C80.767 25.5805 81.3154 25.6903 81.8688 25.6864C82.3584 25.6912 82.8447 25.6052 83.3031 25.4329C83.7598 25.2551 84.1816 24.9983 84.5493 24.6745C84.7747 24.5085 85.0471 24.4184 85.327 24.4172C85.353 24.4163 85.3791 24.4163 85.4052 24.4172C86.0592 24.4388 86.5718 24.9863 86.5502 25.6403C86.5526 25.9818 86.4136 26.3092 86.1662 26.5447C84.9665 27.5821 83.4325 28.1513 81.8465 28.1473C78.2133 28.1381 75.2753 25.1855 75.2844 21.5522C75.2844 17.9138 78.234 14.9641 81.8726 14.9641C83.4542 14.9375 84.9875 15.5087 86.1661 16.5636C86.439 16.7948 86.5946 17.1355 86.5905 17.493C86.5992 17.8224 86.4737 18.1412 86.243 18.3763C86.0086 18.6105 85.6869 18.7359 85.3559 18.722C85.0654 18.7267 84.7824 18.6303 84.5551 18.4493C84.1814 18.1253 83.7563 17.8658 83.2974 17.6812C82.8395 17.5067 82.3531 17.4194 81.8631 17.4239C81.3096 17.4201 80.7614 17.5298 80.2521 17.7465Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M123.775 15.788C123.537 15.5647 123.254 15.3945 122.946 15.2887C122.592 15.1619 122.226 15.0769 121.853 15.0352C121.484 14.9899 121.113 14.9668 120.741 14.9661C119.987 14.9638 119.238 15.0937 118.529 15.3502C117.834 15.6036 117.185 15.9695 116.609 16.4332V16.1874C116.582 15.5082 116.01 14.9793 115.33 15.0062C114.689 15.0315 114.175 15.5458 114.149 16.1874V26.9154C114.176 27.5947 114.748 28.1235 115.428 28.0967C116.069 28.0714 116.584 27.5571 116.609 26.9154V21.5523C116.605 20.9983 116.715 20.4493 116.931 19.9394C117.139 19.4504 117.438 19.0057 117.813 18.6299C118.19 18.2552 118.635 17.9555 119.124 17.7466C119.634 17.5299 120.183 17.4202 120.737 17.424C121.288 17.4185 121.835 17.5183 122.348 17.7177C122.511 17.7958 122.687 17.8396 122.867 17.8464C123.032 17.8479 123.196 17.8159 123.349 17.7523C123.494 17.6912 123.624 17.601 123.733 17.4874C123.843 17.3755 123.932 17.2456 123.998 17.1034C124.065 16.9515 124.099 16.7873 124.098 16.6214C124.11 16.3123 123.99 16.0128 123.768 15.7976L123.775 15.788Z",
    fill: "#0091E2"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M106.24 14.9813C108.9 14.9956 111.298 16.5872 112.344 19.0329L112.342 19.0368C112.668 19.8418 112.832 20.7031 112.824 21.5714C112.835 21.9013 112.705 22.22 112.465 22.447C112.232 22.6766 111.916 22.802 111.589 22.7946H102.275C102.404 23.2185 102.603 23.6181 102.862 23.9774C103.116 24.3293 103.424 24.6391 103.774 24.8952C104.126 25.1507 104.514 25.3508 104.926 25.4886C105.35 25.6307 105.793 25.702 106.24 25.6998C106.724 25.7059 107.206 25.6266 107.663 25.4655C108.094 25.3064 108.495 25.0724 108.845 24.7743L108.863 24.7589C108.866 24.7561 108.873 24.7503 108.882 24.7423L108.894 24.7309L108.935 24.6941L108.975 24.6571L108.976 24.656L108.981 24.6513C109.004 24.6303 109.025 24.6113 109.037 24.5995C109.452 24.2095 110.079 24.1532 110.556 24.4632C111.126 24.8589 111.267 25.641 110.871 26.2105C110.794 26.3249 110.7 26.427 110.593 26.5139C110.592 26.5156 110.588 26.519 110.584 26.5233L110.57 26.536C110.562 26.5438 110.552 26.5525 110.545 26.56C109.348 27.5917 107.82 28.1592 106.24 28.1588C102.601 28.1582 99.6509 25.2071 99.6516 21.5675V21.5656C99.6527 17.9281 102.602 14.9803 106.24 14.9813ZM102.849 19.1654C102.591 19.5239 102.396 19.9239 102.273 20.3482H110.201C110.07 19.9234 109.869 19.5234 109.606 19.1654C109.09 18.4585 108.366 17.9302 107.536 17.6542C107.117 17.5139 106.679 17.4425 106.238 17.443C105.794 17.4426 105.353 17.514 104.932 17.6542C104.516 17.7923 104.123 17.9923 103.766 18.2475C103.414 18.5031 103.104 18.8129 102.849 19.1654Z",
    fill: "#0091E2"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M119.77 27.2319V27.2206C119.864 27.2024 119.935 27.1243 119.944 27.0286C119.948 26.9553 119.917 26.8844 119.862 26.8366C119.772 26.7889 119.67 26.7676 119.568 26.7752C119.459 26.7737 119.351 26.7827 119.243 26.8021V27.647H119.435V27.3071H119.528C119.637 27.3071 119.687 27.3474 119.704 27.4415C119.716 27.5129 119.738 27.5821 119.77 27.647H119.983C119.95 27.5807 119.928 27.5093 119.919 27.4357C119.911 27.3452 119.853 27.2669 119.77 27.2319ZM119.439 27.1688H119.533L119.537 27.1784C119.641 27.1784 119.729 27.1304 119.729 27.0536C119.729 26.9768 119.672 26.9249 119.549 26.9249C119.512 26.9206 119.476 26.9206 119.439 26.9249V27.1688Z",
    fill: "#0091E2"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M119.574 26.3583C119.103 26.3658 118.727 26.7534 118.735 27.2243C118.742 27.6951 119.13 28.0707 119.601 28.0632C120.068 28.0559 120.443 27.6729 120.44 27.2051C120.434 26.7322 120.047 26.3531 119.574 26.3583ZM119.574 27.881C119.201 27.898 118.885 27.6094 118.868 27.2366C118.851 26.8636 119.139 26.5476 119.512 26.5306C119.885 26.5137 120.201 26.8022 120.218 27.1751C120.219 27.1869 120.219 27.1989 120.219 27.2108C120.232 27.568 119.952 27.8678 119.595 27.8806C119.588 27.8809 119.581 27.881 119.574 27.881Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M8.05496 14.2862H4.11859C3.92874 14.2862 3.7749 14.1323 3.7749 13.9424V10.4343C3.7749 10.2445 3.92874 10.0906 4.11859 10.0906H8.05689C8.24668 10.0916 8.39971 10.2464 8.39865 10.4362V13.9425C8.39865 14.1323 8.24481 14.2862 8.05496 14.2862Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M9.5432 14.2862H13.4796C13.6694 14.2862 13.8233 14.1323 13.8233 13.9425V10.4362C13.8243 10.2464 13.6713 10.0916 13.4814 10.0906H9.54314C9.35222 10.0906 9.19751 10.2453 9.19751 10.4362V13.9424C9.19863 14.1326 9.35303 14.2862 9.5432 14.2862Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M18.9867 14.2862H15.0503C14.8605 14.2862 14.7066 14.1323 14.7065 13.9424V10.4343C14.7065 10.2445 14.8604 10.0906 15.0502 10.0906H18.9886C19.1784 10.0916 19.3314 10.2464 19.3304 10.4362V13.9425C19.3304 14.1323 19.1765 14.2862 18.9867 14.2862Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M20.4924 14.2862H24.4287C24.6189 14.2862 24.7733 14.1327 24.7744 13.9425V10.4362C24.7744 10.2453 24.6197 10.0906 24.4287 10.0906H20.4924C20.3025 10.0906 20.1487 10.2445 20.1487 10.4343V13.9424C20.1487 14.1323 20.3026 14.2862 20.4924 14.2862Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M15.0502 9.24185H18.9866C19.1767 9.24079 19.3303 9.08632 19.3303 8.89622V5.38996C19.3303 5.20011 19.1764 5.04626 18.9866 5.04626H15.0502C14.8604 5.04626 14.7065 5.20017 14.7065 5.38996V8.89622C14.7065 9.08632 14.8601 9.24079 15.0502 9.24185Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M24.4287 9.24185H20.4924C20.3023 9.24079 20.1487 9.08632 20.1487 8.89622V5.38996C20.1487 5.20017 20.3025 5.04626 20.4924 5.04626H24.4287C24.6189 5.04626 24.7733 5.19979 24.7744 5.38996V8.89622C24.7733 9.0867 24.6192 9.24079 24.4287 9.24185Z",
    fill: "#0091E2"
  }), _jsx("path", {
    d: "M29.9182 14.2862H25.9819C25.7921 14.2862 25.6382 14.1323 25.6382 13.9424V10.4343C25.6382 10.2445 25.7921 10.0906 25.9819 10.0906H29.9182C30.1092 10.0906 30.2639 10.2453 30.2639 10.4362V13.9425C30.2628 14.1327 30.1084 14.2862 29.9182 14.2862Z",
    fill: "#0091E2"
  })]
}), 'DockerLogo', 'Company');
export default DockerLogo;