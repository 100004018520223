/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PagerDutyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 118,
  height: 32,
  viewBox: "0 0 118 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M114.765 9.02811L112.125 16.5161C111.645 17.8601 111.405 18.6281 110.961 19.9241H110.913C110.565 18.8081 110.253 17.9081 109.797 16.5881L107.229 9.01611H104.157L109.473 22.9121C109.353 23.2121 109.233 23.5241 109.101 23.8481C108.657 24.9281 107.601 25.6361 106.437 25.6361H105.117V28.0001H106.473C108.669 28.0001 110.649 26.6681 111.465 24.6161C113.157 20.3801 116.037 13.1681 117.681 9.02811H114.765Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M41.049 20.2479C41.049 25.2759 38.745 27.9999 34.089 27.9999C30.621 27.9999 28.533 26.1759 27.789 23.4279H30.597C31.077 24.6999 32.073 25.8399 34.137 25.8399C37.317 25.8399 38.445 23.8599 38.445 20.0919C38.421 20.0919 38.421 20.0679 38.397 20.0679C37.713 21.2559 36.177 22.3119 33.741 22.3119C29.829 22.3119 27.333 19.4799 27.333 15.5079C27.333 11.3799 30.033 8.59595 33.765 8.59595C36.177 8.59595 37.629 9.62795 38.421 10.7679C38.397 10.4799 38.397 10.1799 38.397 9.89195V9.01595H41.049V20.2479ZM30.249 15.4839C30.249 18.1839 31.761 20.1159 34.353 20.1159C36.681 20.1159 38.481 18.4719 38.481 15.3999C38.481 12.6759 36.897 10.8519 34.353 10.8519C31.809 10.8519 30.249 12.6759 30.249 15.4839Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M45.429 16.6721C45.537 19.1081 47.097 21.0401 49.797 21.0401C51.705 21.0401 52.629 20.1401 53.337 18.9521H56.013C55.221 21.4601 53.025 23.2601 49.689 23.2601C45.405 23.2601 42.729 20.3801 42.729 16.0361C42.729 11.6921 45.513 8.63208 49.689 8.63208C54.213 8.63208 56.301 12.0761 56.301 15.8081V16.6841H45.429V16.6721ZM53.517 14.5601C53.277 12.3641 51.897 10.8041 49.629 10.8041C47.457 10.8041 45.789 12.2321 45.525 14.5601H53.517Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M60.5851 9.02802V10.804C61.0291 9.50802 62.3851 8.69202 63.9451 8.69202C64.2931 8.69202 64.4491 8.71602 64.6291 8.74002V11.176C64.3891 11.128 63.9931 11.092 63.7051 11.092C61.1971 11.092 60.7171 12.94 60.7171 15.724V22.924H58.0411V11.524C58.0411 10.648 58.0411 9.88002 58.0171 9.04002H60.5851V9.02802Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M92.1089 20.9321C91.3889 22.1201 90.0449 23.2601 87.6929 23.2601C84.4649 23.2601 82.8809 21.2801 82.8809 17.6801V9.02808H85.5449V16.6721C85.5449 19.3961 86.4449 21.0401 88.6169 21.0401C91.4729 21.0401 91.9769 18.4481 91.9769 15.4001V9.02808H94.6529V20.8241C94.6529 21.5441 94.6529 22.2281 94.6769 22.9121H92.1089V20.9321Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M100.665 9.0281H103.257V11.0921H100.665V19.4321C100.665 20.7521 101.325 21.1481 102.357 21.1481C102.537 21.1481 102.861 21.1241 103.017 21.1001V23.0801C102.621 23.1281 102.333 23.1881 101.985 23.1881C99.525 23.1881 97.965 22.3721 97.965 19.5881V11.0921H95.877V9.0281H97.965V5.27209H100.665V9.0281Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M3.47708 17.8719H0.705078V22.9119H3.47708V17.8719Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M11.1451 4.924C9.65708 4.132 8.62508 4 6.18908 4H0.705078V15.472H3.47708H3.83708H6.16508C8.33708 15.472 9.95708 15.34 11.3851 14.392C12.9451 13.36 13.7491 11.644 13.7491 9.664C13.7491 7.516 12.7531 5.8 11.1451 4.924ZM6.80108 13.072H3.47708V6.46L6.60908 6.436C9.46508 6.4 10.8931 7.408 10.8931 9.688C10.8931 12.136 9.12908 13.072 6.80108 13.072Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M66.2612 4H70.8932C77.8292 4.048 81.1652 7.432 81.1892 13.504C81.2132 18.676 78.5372 22.852 71.3492 22.924H66.2732V4H66.2612ZM69.0692 20.56H70.6892C75.7172 20.56 78.3452 18.64 78.3452 13.492C78.3332 8.968 75.7292 6.376 70.9532 6.376C70.0292 6.376 69.0692 6.4 69.0692 6.4V20.56Z",
    fill: "#06AC38"
  }), _jsx("path", {
    d: "M23.4689 22.924C23.3849 22.372 23.3849 22.024 23.3369 21.016C22.2809 22.528 20.8769 23.188 18.6569 23.188C15.7409 23.188 13.6289 21.76 13.6289 19.168C13.6289 16.18 16.4849 15.196 20.1929 14.692C21.1169 14.56 22.1489 14.452 23.0489 14.38C23.0489 11.632 21.5249 10.804 19.9289 10.804C18.3329 10.804 17.1689 11.92 17.1689 13.384H14.5769C14.5769 10.564 16.7969 8.66797 19.9889 8.66797C23.1809 8.66797 25.6409 9.98797 25.6409 15.076V17.776C25.6409 20.02 25.7489 21.664 25.9889 22.936H23.4689V22.924ZM16.5089 19.132C16.5089 20.428 17.5649 21.244 19.2569 21.244C21.7409 21.244 23.0729 19.732 23.0729 17.032C23.0729 16.684 23.0729 16.444 23.0969 16.288C18.5729 16.756 16.5089 17.2 16.5089 19.132Z",
    fill: "#06AC38"
  })]
}), 'PagerDutyLogo', 'Company');
export default PagerDutyLogo;