/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var JumboLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 103,
  height: 32,
  viewBox: "0 0 103 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M55.2343 25.6891V18.9533L49.6278 25.6891L43.9695 18.9533V25.6891H38.8821V6L49.6278 18.6942L60.3217 6V25.6891H55.2343Z",
    fill: "#FF4D26"
  }), _jsx("path", {
    d: "M35.4556 17.5026C35.4556 22.8393 32.1852 26 26.7864 26C21.3356 26 18.0652 22.8393 18.0652 17.5026V6.41455H23.1526V17.4507C23.1526 20.0932 24.3464 21.5959 26.7864 21.5959C29.2261 21.5959 30.4201 20.0932 30.4201 17.4507V6.41455H35.4556V17.5026Z",
    fill: "#FF4D26"
  }), _jsx("path", {
    d: "M72.6765 6.41455C76.3103 6.41455 78.9578 8.12445 78.9578 11.9068C78.9578 13.5648 78.1792 14.7048 77.3485 15.5338C78.5944 16.1554 79.7366 17.6581 79.7366 19.9379C79.7366 23.6683 77.1408 25.7409 73.1956 25.7409H63.9033V6.41455H72.6765ZM68.9907 13.7203H72.0536C73.1437 13.7203 73.8185 13.3057 73.8185 12.2695C73.8185 11.2333 73.1437 10.8187 72.1055 10.8187H68.9907V13.7203ZM68.9907 21.2849H72.5726C73.8185 21.2849 74.5452 20.7669 74.5452 19.6269C74.5452 18.4871 73.8185 17.9689 72.5207 17.9689H68.9388V21.2849H68.9907Z",
    fill: "#FF4D26"
  }), _jsx("path", {
    d: "M102.733 16.0518C102.733 22.1139 98.3729 25.9998 92.3511 25.9998C86.2255 25.9998 81.9167 22.1139 81.9167 16.0518C81.9167 9.98961 86.2774 6.10352 92.3511 6.10352C98.4248 6.10352 102.733 10.0413 102.733 16.0518ZM87.1081 16.0518C87.1081 19.4714 89.1326 21.5957 92.3511 21.5957C95.5178 21.5957 97.5423 19.5233 97.5423 16.0518C97.5423 12.6321 95.5178 10.5076 92.3511 10.5076C89.1326 10.5076 87.1081 12.6321 87.1081 16.0518Z",
    fill: "#FF4D26"
  }), _jsx("path", {
    d: "M9.60369 6.41431V18.5387C9.60369 20.5595 8.825 21.5957 7.31957 21.5957C6.12559 21.5957 5.39883 20.7148 5.13927 19.2123H0.0519118H0C0.311471 23.5647 3.06279 25.9998 7.26766 25.9998C11.9397 25.9998 14.691 23.1501 14.691 18.3833V6.41431H9.60369Z",
    fill: "#FF4D26"
  })]
}), 'JumboLogo', 'Company');
export default JumboLogo;