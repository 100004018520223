/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LandbotLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 140,
  height: 32,
  viewBox: "0 0 140 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#LandbotLogo_svg__clip0_11066_127559)",
    children: [_jsx("path", {
      d: "M14.0132 29.1027C11.426 29.1027 8.89652 28.3381 6.74308 26.9051C4.58967 25.4722 2.90844 23.4349 1.9109 21.0496C0.913361 18.6642 0.644072 16.0373 1.13691 13.4994C1.62975 10.9615 2.8627 8.62588 4.68067 6.78644C5.88703 5.55457 7.32623 4.57436 8.91468 3.90269C10.5032 3.23101 12.2093 2.88123 13.9341 2.87366H14.0158C16.3969 2.87304 18.7334 3.51948 20.7752 4.74378C22.8169 5.96807 24.487 7.72413 25.6067 9.82397C26.7264 11.9239 27.2536 14.2885 27.1317 16.6646C27.0099 19.0409 26.2437 21.3392 24.9151 23.3138C24.7584 23.5451 24.6971 23.8278 24.744 24.1032C24.9165 25.0714 25.2428 26.0059 25.7104 26.8713L26.0185 27.4397C26.0525 27.5031 26.071 27.5736 26.0723 27.6454C26.0737 27.7173 26.0579 27.7884 26.0262 27.853C25.9945 27.9175 25.9479 27.9737 25.8903 28.0166C25.8326 28.0596 25.7656 28.0882 25.6946 28.1001L25.0547 28.2028C24.5645 28.2825 24.0688 28.3238 23.5721 28.3265H23.501C22.4546 28.3225 21.416 28.1447 20.4279 27.8002C20.3136 27.7605 20.1935 27.7401 20.0725 27.7397C19.9165 27.7395 19.7625 27.7745 19.6221 27.8423C17.8923 28.6576 16.0048 29.0842 14.0922 29.0922L14.0132 29.1027Z",
      fill: "white"
    }), _jsx("path", {
      d: "M16.3648 13.9305C16.37 14.3963 16.2366 14.8532 15.9814 15.2431C15.7262 15.6329 15.3608 15.9382 14.9315 16.1201C14.5024 16.302 14.0287 16.3523 13.5708 16.2646C13.113 16.1769 12.6915 15.9553 12.36 15.6277C12.0285 15.3001 11.8019 14.8815 11.709 14.425C11.6161 13.9685 11.6611 13.4947 11.8383 13.0638C12.0155 12.6329 12.3169 12.2644 12.7041 12.005C13.0913 11.7456 13.5471 11.6071 14.0133 11.6071C14.3203 11.6053 14.6248 11.6641 14.9091 11.7799C15.1935 11.8957 15.4523 12.0664 15.6706 12.2821C15.889 12.4979 16.0626 12.7545 16.1818 13.0373C16.3009 13.3201 16.3631 13.6237 16.3648 13.9305Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M16.5149 22.2981C16.3645 22.1086 16.1453 21.9863 15.9051 21.9577C15.6648 21.9292 15.423 21.9966 15.2324 22.1455C14.8888 22.4093 14.4659 22.549 14.0327 22.5419C13.5993 22.5347 13.1813 22.381 12.8466 22.106C12.6719 21.9574 12.4488 21.8778 12.2194 21.8821C11.9899 21.8865 11.77 21.9746 11.601 22.1297C11.4756 22.2483 11.3866 22.4001 11.3441 22.5673C11.3016 22.7346 11.3077 22.9104 11.3614 23.0744C11.4231 23.2506 11.5348 23.4051 11.6827 23.5191C12.3409 24.0554 13.1616 24.3532 14.011 24.3637C14.8603 24.3742 15.6881 24.0969 16.3595 23.5769C16.4543 23.5039 16.5336 23.4127 16.5928 23.3087C16.652 23.2048 16.6899 23.0901 16.7044 22.9713C16.7188 22.8526 16.7095 22.7322 16.677 22.6171C16.6444 22.5019 16.5893 22.3944 16.5149 22.3007V22.2981Z",
      fill: "#34405F"
    }), _jsx("path", {
      d: "M14.0134 7.99155C12.8402 8.00505 11.6973 8.36502 10.7285 9.02619C9.75964 9.68731 9.00817 10.62 8.56872 11.707C8.12921 12.7939 8.02137 13.9865 8.25865 15.1346C8.496 16.2826 9.06791 17.3349 9.9024 18.1589C10.7369 18.9829 11.7967 19.5418 12.9484 19.7653C14.1 19.9887 15.2921 19.8668 16.3746 19.4147C17.4571 18.9627 18.3815 18.2008 19.0316 17.2249C19.6816 16.249 20.0283 15.1028 20.0278 13.9305C20.0181 12.3461 19.379 10.8303 18.2511 9.71664C17.1233 8.60297 15.5989 7.98245 14.0134 7.99155ZM14.0134 18.0748C13.1958 18.0655 12.3992 17.8147 11.7239 17.3541C11.0486 16.8934 10.5247 16.2436 10.2182 15.4861C9.91168 14.7287 9.83613 13.8976 10.0012 13.0974C10.1662 12.2972 10.5644 11.5636 11.1457 10.989C11.7269 10.4144 12.4653 10.0244 13.2677 9.86814C14.0703 9.71186 14.9012 9.79623 15.6558 10.1106C16.4105 10.4251 17.0552 10.9555 17.509 11.6351C17.9626 12.3148 18.205 13.1135 18.2056 13.9305C18.1993 15.0354 17.7542 16.0927 16.9681 16.8698C16.1819 17.647 15.1192 18.0804 14.0134 18.0748Z",
      fill: "#ED4078"
    }), _jsx("path", {
      d: "M26.906 27.1633C26.3312 26.292 25.9215 25.3225 25.6973 24.3031C25.669 24.1799 25.6653 24.0523 25.6865 23.9276C25.7078 23.803 25.7535 23.6838 25.8211 23.5769C27.2653 21.2852 28.0279 18.6305 28.0199 15.9223C28.0032 12.2228 26.5202 8.68049 23.8955 6.07112C21.2708 3.46175 17.7183 1.99788 14.0159 2H13.929C11.2509 2.01434 8.63311 2.79571 6.38595 4.25147C4.13878 5.70723 2.35647 7.77634 1.25033 10.2135C0.144186 12.6507 -0.239398 15.3536 0.145053 18.002C0.529503 20.6504 1.66586 23.133 3.41942 25.1557C4.73137 26.6792 6.35772 27.901 8.1868 28.7372C10.016 29.5734 12.0044 30.0041 14.0159 30H14.0897C16.0446 29.9892 17.9763 29.5763 19.7644 28.7869C19.9564 28.7023 20.1725 28.6901 20.3728 28.7527C21.3921 29.057 22.4504 29.2112 23.5143 29.2106H23.5696C24.4888 29.2079 25.4035 29.0831 26.2898 28.8395C26.5679 28.7619 26.8044 28.5784 26.9486 28.3286C27.0929 28.0786 27.1334 27.7822 27.0614 27.5028C27.0302 27.3813 26.9776 27.2663 26.906 27.1633ZM24.5834 27.3607C23.2847 27.5225 21.9661 27.3856 20.7282 26.9608C20.2395 26.79 19.7031 26.8184 19.2351 27.0397C17.6227 27.8005 15.8624 28.1976 14.0791 28.2028H14.0159C11.6035 28.2081 9.24371 27.4989 7.23444 26.1649C5.22518 24.8309 3.65658 22.9318 2.72676 20.7075C1.79692 18.4833 1.54757 16.0336 2.01018 13.6677C2.47279 11.302 3.62661 9.1262 5.32594 7.41527C6.45056 6.27086 7.79115 5.36068 9.27008 4.73738C10.7491 4.11409 12.3371 3.79006 13.9422 3.78404H14.0159C16.2313 3.78351 18.4051 4.38484 20.3048 5.52368C22.2045 6.66252 23.7586 8.29604 24.8009 10.2494C25.843 12.2029 26.3342 14.4027 26.2217 16.6135C26.1092 18.8243 25.3973 20.9629 24.1621 22.8006C23.8671 23.2322 23.7545 23.7627 23.8487 24.2768C24.0242 25.2303 24.3336 26.1543 24.7678 27.0213C24.7857 27.055 24.7951 27.0928 24.7949 27.131C24.7947 27.1693 24.7851 27.2069 24.7669 27.2405C24.7486 27.2741 24.7223 27.3027 24.6903 27.3237C24.6583 27.3447 24.6216 27.3574 24.5834 27.3607Z",
      fill: "#34405F"
    }), _jsx("path", {
      d: "M38.6638 22.7216H48.2044V26.4713H34.7271V5.51538H38.677L38.6638 22.7216Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M60.4651 12.0306H63.7858V26.4713H60.3414L60.0965 24.2583C59.2643 25.8582 57.5738 26.8107 55.3302 26.8107C51.978 26.8107 48.6257 24.2294 48.6257 19.2509C48.6257 14.2724 51.978 11.7358 55.3302 11.7358C57.6053 11.7358 59.296 12.6884 60.1254 14.3171L60.4651 12.0306ZM60.0332 19.2509C60.0332 16.6196 58.1268 15.1329 56.3466 15.1329C54.1636 15.1329 52.473 16.7301 52.473 19.2509C52.473 21.8323 54.1952 23.3979 56.3466 23.3979C58.1899 23.3979 60.0332 22.048 60.0332 19.2509Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M65.292 26.4713V18.2668C65.292 14.4567 67.7224 11.7228 72.057 11.7228C76.3916 11.7228 78.7933 14.4567 78.7933 18.2668V26.4713H74.9169V18.5773C74.9169 16.4565 73.9636 15.1356 72.057 15.1356C70.1504 15.1356 69.1971 16.4513 69.1971 18.5773V26.4713H65.292Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M91.2641 5.51538H95.1379V26.4713H91.6012L91.3563 24.2583C90.5268 25.8581 88.8651 26.8107 86.6167 26.8107C83.3249 26.8107 80.0042 24.2294 80.0042 19.2509C80.0042 14.2724 83.3303 11.7358 86.6221 11.7358C88.7729 11.7358 90.403 12.6278 91.2641 14.104V5.51538ZM91.2931 19.2509C91.2931 16.4538 89.4497 15.1329 87.6359 15.1329C85.5134 15.1329 83.8544 16.6696 83.8544 19.2509C83.8544 21.8322 85.5134 23.3979 87.6359 23.3979C89.4497 23.3979 91.2931 22.048 91.2931 19.2509Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M111.804 19.2509C111.804 24.2294 108.484 26.8107 105.192 26.8107C102.949 26.8107 101.287 25.8581 100.452 24.2583L100.213 26.4713H96.676V5.51538H100.55V14.0883C101.411 12.6147 103.041 11.72 105.192 11.72C108.484 11.7358 111.804 14.2724 111.804 19.2509ZM107.96 19.2509C107.96 16.6696 106.33 15.1329 104.179 15.1329C102.364 15.1329 100.518 16.4485 100.518 19.2509C100.518 22.0533 102.362 23.3979 104.179 23.3979C106.33 23.3979 107.96 21.8322 107.96 19.2509Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M112.72 19.2509C112.72 14.9803 115.946 11.7358 120.434 11.7358C124.921 11.7358 128.152 14.9803 128.152 19.2509C128.152 23.5216 124.924 26.8107 120.434 26.8107C115.944 26.8107 112.72 23.5216 112.72 19.2509ZM124.307 19.2509C124.307 16.8222 122.677 15.1039 120.431 15.1039C118.185 15.1039 116.557 16.8222 116.557 19.2509C116.557 21.6796 118.187 23.3979 120.431 23.3979C122.675 23.3979 124.307 21.7086 124.307 19.2509Z",
      fill: "#33405E"
    }), _jsx("path", {
      d: "M139.007 15.133H134.793V20.5114C134.793 22.3849 135.467 23.2454 137.405 23.2454C137.59 23.2454 138.051 23.2454 138.635 23.2138V26.3714C137.896 26.5866 137.13 26.7 136.36 26.7082C133.1 26.7082 130.888 24.7426 130.888 21.0245V15.133H128.273V12.0307H130.89V7.14429H134.796V12.0307H139.009L139.007 15.133Z",
      fill: "#33405E"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "LandbotLogo_svg__clip0_11066_127559",
      children: _jsx("rect", {
        width: 139.009,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'LandbotLogo', 'Company');
export default LandbotLogo;