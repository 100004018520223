/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VeoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 63,
  height: 32,
  viewBox: "0 0 63 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M15.936 7.76833L9.77034 20.9562C9.66569 17.1798 9.41245 12.2982 9.12806 8.53142C8.87322 5.45273 6.57329 5.79401 4.36364 6.52202C3.05111 6.9542 1.60596 7.60726 0.498738 8.04502C-0.211452 8.3257 -0.157928 9.25863 0.625757 9.46436C2.65088 9.99541 2.66046 11.1484 2.90172 13.043L4.12478 22.6379C4.29094 23.9448 4.46989 25.2047 5.83674 25.6201C8.12469 26.3162 12.933 26.372 14.7888 22.9903L23.6099 6.91673C24.6156 5.08434 16.6078 6.33065 15.936 7.76833Z",
    fill: "#69B561"
  }), _jsx("path", {
    d: "M40.7768 14.1202C41.2937 12.0869 41.1043 9.9244 39.6304 8.29854C38.0807 6.58816 35.7096 6.00049 33.4393 6.00049C29.2013 6.00049 25.4802 7.92776 23.0716 11.2672C21.4475 13.519 20.5744 16.0842 20.6167 18.4269C20.715 23.8578 25.4634 25.9502 30.271 25.8011C33.2923 25.7078 36.8824 24.1433 37.9113 22.1251C38.4361 21.0941 37.988 19.0377 36.2976 20.0663C33.8874 21.5319 26.9677 21.9417 26.4939 18.4763L37.2458 16.9605C39.0521 16.7053 40.3239 15.9024 40.7768 14.1202ZM34.4746 13.0366L27.5508 14.0772C28.1771 12.1427 29.8516 10.8079 32.0149 10.8079C33.4129 10.8079 34.6224 11.5774 34.4746 13.0366Z",
    fill: "#69B561"
  }), _jsx("path", {
    d: "M61.9467 11.8238C61.3164 7.70848 57.8349 6.00049 53.859 6.00049C49.335 6.00049 45.3008 8.04178 42.8307 11.7855C41.3456 14.0357 40.3342 17.3472 40.744 20.0224C41.3791 24.1664 44.855 25.8074 48.8413 25.8074C53.4028 25.8074 57.3692 23.8945 59.8457 20.1181C61.3284 17.8559 62.3581 14.5125 61.9467 11.8238ZM53.0202 19.5289C51.7052 20.9067 48.8725 21.5255 47.9035 19.548C46.9744 17.6502 48.255 13.8954 49.6873 12.4354C50.5006 11.6069 51.4576 11.1922 52.5584 11.1922C53.6593 11.1922 54.4062 11.6061 54.7992 12.4354C55.702 14.3395 54.4286 18.0537 53.0202 19.5289Z",
    fill: "#69B561"
  })]
}), 'VeoLogo', 'Company');
export default VeoLogo;