/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TheVintageBarLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 177,
  height: 32,
  viewBox: "0 0 177 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M21.2464 14.5959H16.0083V8.55493H13.6079V22.9238H16.0083V17.0552H21.2464V22.9238H23.6468V8.55493H21.2464V14.5959Z",
    fill: "black"
  }), _jsx("path", {
    d: "M27.5986 22.9238H36.1956V20.8849H29.9528V16.9249H35.5944V14.886H29.9528V10.5938H36.1956V8.55493H27.5986V22.9238Z",
    fill: "black"
  }), _jsx("path", {
    d: "M121.106 22.9238H129.707V20.8849H123.46V16.9249H129.102V14.886H123.46V10.5938H129.707V8.55493H121.106V22.9238Z",
    fill: "black"
  }), _jsx("path", {
    d: "M51.9433 18.5139L47.6343 8.55493H45.0615L51.8928 24L58.8881 8.55493H56.3153L51.9433 18.5139Z",
    fill: "black"
  }), _jsx("path", {
    d: "M62.9193 8.55493H60.5693V22.9196H62.9193V8.55493Z",
    fill: "black"
  }), _jsx("path", {
    d: "M75.5691 17.4461L66.7031 8V23.0751H69.0447V14.02L77.9107 23.4409V8.45822H75.5691V17.4461Z",
    fill: "black"
  }), _jsx("path", {
    d: "M91.7207 8.43726H80.5342V10.8965H84.9567V22.8061H87.3108V10.8965H91.7207V8.43726Z",
    fill: "black"
  }), _jsx("path", {
    d: "M0 10.8965H4.42249V22.8061H6.77247V10.8965H11.1865V8.43726H0V10.8965Z",
    fill: "black"
  }), _jsx("path", {
    d: "M91.0771 22.9239H93.7845L94.9279 19.9812H100.944L101.999 22.9239H104.74L98.0304 8.28174L91.0771 22.9239ZM96.2311 17.6228L97.9968 13.3432L99.6783 17.6228H96.2311Z",
    fill: "black"
  }), _jsx("path", {
    d: "M151.445 22.9239H154.148L155.296 19.9812H161.311L162.367 22.9239H165.107L158.398 8.28174L151.445 22.9239ZM156.599 17.6228L158.36 13.3432L160.042 17.6228H156.599Z",
    fill: "black"
  }), _jsx("path", {
    d: "M147.804 15.2476C148.238 14.9588 148.594 14.5693 148.844 14.1124C149.094 13.6555 149.229 13.1449 149.238 12.6244C149.236 12.0381 149.091 11.4612 148.817 10.9428C148.544 10.4025 148.151 9.93112 147.67 9.56395C147.137 9.17357 146.524 8.9065 145.875 8.78202C144.969 8.61063 144.047 8.53454 143.125 8.55501H140.54V23.05H144.471C145.459 23.069 146.446 22.9774 147.413 22.7768C148.122 22.5902 148.78 22.2468 149.339 21.772C149.783 21.4366 150.142 21.0026 150.39 20.5043C150.637 20.006 150.765 19.4571 150.764 18.9008C150.778 18.0722 150.493 17.2663 149.961 16.6307C149.407 15.9531 148.651 15.4688 147.804 15.2476ZM142.76 11.0942H144.635C145.281 11.052 145.925 11.2121 146.476 11.5524C146.691 11.7017 146.864 11.9031 146.979 12.1376C147.095 12.3722 147.149 12.6323 147.136 12.8934C147.153 13.1549 147.105 13.4166 146.997 13.6554C146.89 13.8943 146.725 14.1032 146.518 14.2639C145.927 14.6236 145.236 14.7855 144.546 14.7263H142.764L142.76 11.0942ZM147.59 20.3427C147.11 20.7108 146.511 20.8876 145.908 20.8388H142.764V17.1225H145.707C146.619 17.1225 147.249 17.2991 147.603 17.6564C147.957 18.0177 148.153 18.5044 148.149 19.0102C148.145 19.516 147.941 19.9997 147.582 20.3553L147.59 20.3427Z",
    fill: "black"
  }), _jsx("path", {
    d: "M113.085 17.3199H115.842V20.086C115.035 20.7849 114.001 21.1672 112.933 21.1622C111.616 21.1445 110.355 20.6294 109.402 19.7203C108.837 19.2315 108.389 18.6235 108.088 17.9401C107.787 17.2567 107.641 16.5151 107.662 15.7686C107.65 15.0522 107.782 14.3407 108.05 13.6762C108.318 13.0117 108.716 12.4078 109.222 11.9002C109.728 11.3925 110.33 10.9915 110.994 10.7209C111.657 10.4503 112.368 10.3155 113.085 10.3246C114.267 10.3179 115.413 10.7356 116.313 11.5017L117.814 10.0345C117.333 9.61342 116.8 9.25466 116.229 8.96675C115.232 8.5056 114.145 8.27019 113.047 8.27731C112.048 8.2576 111.055 8.44123 110.13 8.81702C109.204 9.19282 108.364 9.75294 107.662 10.4633C106.947 11.1474 106.382 11.9718 106.001 12.8848C105.621 13.7978 105.433 14.7797 105.45 15.7686C105.435 16.7474 105.619 17.719 105.992 18.624C106.365 19.529 106.919 20.3484 107.62 21.0319C108.296 21.7316 109.109 22.2857 110.007 22.6602C110.906 23.0346 111.872 23.2216 112.845 23.2095C113.816 23.2347 114.781 23.0459 115.671 22.6565C116.561 22.2671 117.354 21.6866 117.995 20.9562V15.2516H113.085V17.3199Z",
    fill: "black"
  }), _jsx("path", {
    d: "M173.431 16.6641C174.294 16.4562 175.085 16.0207 175.723 15.403C176.056 15.0561 176.313 14.6439 176.479 14.1923C176.645 13.7408 176.715 13.2598 176.685 12.7797C176.719 12.1456 176.587 11.5135 176.305 10.9449C176.022 10.3764 175.597 9.89045 175.071 9.53433C174.012 8.81967 173.389 8.56323 169.913 8.56323H166.73V22.9237H169.446V17.0383H170.758L173.995 22.9237H176.874L173.431 16.6641ZM169.446 14.785V10.8712H171.716C173.381 10.8712 174.213 11.5032 174.213 12.7671C174.213 14.1124 173.357 14.785 171.645 14.785H169.446Z",
    fill: "black"
  })]
}), 'TheVintageBarLogo', 'Company');
export default TheVintageBarLogo;