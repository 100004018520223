/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StaplesCanadaLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 158,
  height: 32,
  viewBox: "0 0 158 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#StaplesCanadaLogo_svg__clip0_6_206)",
    children: [_jsx("mask", {
      id: "StaplesCanadaLogo_svg__mask0_6_206",
      style: {
        maskType: 'luminance'
      },
      maskUnits: "userSpaceOnUse",
      x: 0,
      y: 0,
      width: 157,
      height: 32,
      children: _jsx("path", {
        d: "M156.436 0H0.245605V31.3327H156.436V0Z",
        fill: "white"
      })
    }), _jsxs("g", {
      mask: "url(#StaplesCanadaLogo_svg__mask0_6_206)",
      children: [_jsx("path", {
        d: "M138.413 15.885H134.022V3.55196H138.413V0H133.974C133.046 0.00315771 132.156 0.373346 131.5 1.0298C130.843 1.68625 130.473 2.57569 130.47 3.50405V15.9329C130.475 16.8608 130.846 17.7493 131.502 18.4054C132.158 19.0616 133.046 19.4322 133.974 19.437H138.413V15.885Z",
        fill: "white"
      }), _jsx("path", {
        d: "M141.965 3.55196H146.355V15.885H141.965V19.437H146.397C147.326 19.4338 148.215 19.0636 148.872 18.4072C149.528 17.7507 149.898 16.8613 149.901 15.9329V3.50405C149.896 2.57617 149.526 1.68764 148.869 1.03153C148.213 0.375413 147.325 0.0047221 146.397 0H141.965V3.55196Z",
        fill: "white"
      }), _jsx("path", {
        d: "M10.0869 26.5289C4.80385 26.5289 0.892502 23.7497 0.245605 19.2752H5.63644C6.36121 21.0722 8.49956 21.6712 10.0749 21.6712C12.2073 21.6712 13.8245 21.0242 13.8245 19.8143C13.8245 18.6044 13.0938 18.1612 9.46992 17.5981C5.76822 17.059 0.808647 16.0467 0.808647 11.4106C0.808647 8.14615 3.67178 4.75592 9.67959 4.75592C14.4714 4.75592 17.9875 7.33753 18.6643 11.2489H13.1476C12.8242 10.2845 11.6562 9.5178 9.67959 9.5178C7.22374 9.5178 6.21147 10.4043 6.21147 11.171C6.21147 12.2552 7.74489 12.6206 10.3265 13.0219C15.4897 13.8724 19.1974 14.9985 19.1974 19.5148C19.1974 23.6298 15.4058 26.5289 10.0869 26.5289Z",
        fill: "white"
      }), _jsx("path", {
        d: "M31.5782 26.0078C25.4866 26.6547 22.2641 22.9829 22.2641 17.2986V11.2489H19.7664V6.53489H22.2641V0H27.589V6.53489H31.5782V11.2489H27.589V17.3704C27.589 20.2696 28.9187 21.3597 31.5782 21.1979V26.0078Z",
        fill: "white"
      }), _jsx("path", {
        d: "M67.5292 26.493C65.2562 26.5027 63.0706 25.6185 61.4436 24.0312V31.3327H56.0527V5.28301H61.174V7.45732C61.9978 6.60602 62.9858 5.93083 64.0783 5.47265C65.1706 5.01448 66.3447 4.78287 67.5292 4.79185C72.974 4.79185 77.4126 9.46987 77.4126 15.5735C77.4126 21.6772 72.974 26.463 67.5292 26.463V26.493ZM66.7685 9.72747C63.8216 9.72747 61.4436 12.351 61.4436 15.5735C61.4436 18.796 63.8396 21.5035 66.7685 21.5035C69.6975 21.5035 72.0875 18.9637 72.0875 15.5735C72.0875 12.1833 69.6916 9.72747 66.7685 9.72747Z",
        fill: "white"
      }), _jsx("path", {
        d: "M84.4685 0H79.1436V26.0498H84.4685V0Z",
        fill: "white"
      }), _jsx("path", {
        d: "M91.6023 17.7419C91.8689 18.9182 92.5303 19.9679 93.4758 20.7166C94.4221 21.4651 95.5954 21.8675 96.8015 21.8568C97.683 21.8976 98.5592 21.6958 99.3348 21.2734C100.11 20.851 100.754 20.2241 101.198 19.461H106.864C105.666 23.6957 101.989 26.5528 96.8251 26.5528C93.9794 26.5166 91.2632 25.3564 89.2693 23.3254C87.2754 21.2944 86.1653 18.5574 86.1813 15.7113C86.1813 9.72145 90.9737 4.77988 96.8251 4.77988C102.953 4.77988 108.032 9.57173 107.266 17.7658L91.6023 17.7419ZM102.007 13.3873C101.72 12.2334 101.048 11.2122 100.101 10.4931C99.1543 9.77402 97.9901 9.4003 96.8015 9.43398C95.632 9.43718 94.496 9.82644 93.5695 10.5413C92.6438 11.256 91.9794 12.2565 91.68 13.3873H102.007Z",
        fill: "white"
      }), _jsx("path", {
        d: "M118.053 13.0219C115.472 12.6206 113.938 12.2552 113.938 11.171C113.938 10.4043 114.945 9.5178 117.406 9.5178C119.383 9.5178 120.551 10.2845 120.875 11.2489H126.397C125.714 7.33753 122.162 4.75592 117.412 4.75592C111.422 4.75592 108.542 8.14615 108.542 11.4106C108.542 16.0467 113.501 17.059 117.209 17.622C120.916 18.1851 121.563 18.6703 121.563 19.8383C121.563 21.0063 119.952 21.6951 117.814 21.6951C116.292 21.6951 114.22 21.0962 113.459 19.437H108.002C108.721 23.8155 112.596 26.5289 117.814 26.5289C123.139 26.5289 126.93 23.6298 126.93 19.5148C126.93 14.9985 123.217 13.8724 118.059 13.0219",
        fill: "white"
      }), _jsx("path", {
        d: "M42.1743 4.83976C44.4478 4.83071 46.6345 5.71225 48.266 7.29558V5.28301H53.6568V26.0497H48.5056V23.8694C47.6837 24.7162 46.6993 25.3882 45.6113 25.8452C44.5234 26.3022 43.3543 26.5347 42.1743 26.5289C36.7296 26.5289 32.2971 21.8568 32.2971 15.7472C32.2971 9.63763 36.7296 4.83976 42.1743 4.83976ZM42.941 21.5633C45.882 21.5633 48.266 18.9458 48.266 15.7172C48.266 12.4887 45.8701 9.79336 42.941 9.79336C40.012 9.79336 37.6161 12.333 37.6161 15.7172C37.6161 19.1016 40.012 21.5633 42.941 21.5633Z",
        fill: "white"
      }), _jsx("path", {
        d: "M152.232 1.54535H153.915V2.09641H153.363V3.55194H152.764V2.09641H152.213L152.232 1.54535ZM156.424 1.54535V3.55194H155.825V2.47377L155.274 3.1626L154.783 2.47377V3.55194H154.184V1.54535H154.699L155.298 2.35397L155.897 1.54535H156.424Z",
        fill: "white"
      })]
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "StaplesCanadaLogo_svg__clip0_6_206",
      children: _jsx("rect", {
        width: 157.714,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'StaplesCanadaLogo', 'Company');
export default StaplesCanadaLogo;