/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VrboLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 32,
  viewBox: "0 0 87 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M44.2584 24.7534C43.7423 25.8955 43.374 27.0998 43.1625 28.3368C43.1773 28.8124 43.4414 29.2442 43.8561 29.4706C43.923 29.5046 43.9926 29.5327 44.0642 29.5546V29.4916C43.8642 29.296 43.7755 29.0117 43.8284 28.7357C44.1151 27.4803 44.536 26.26 45.0838 25.0963L54.1005 2L52.8313 2.25196C52.8831 2.47167 51.5361 5.90152 50.8119 7.74567C50.5672 8.36861 50.3935 8.81091 50.369 8.8869L44.2584 24.7534Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M45.0214 29.5196V29.5826C49.8766 28.2668 54.378 19.7842 54.7318 15.5848C54.8289 14.514 54.3572 13.8841 53.5804 13.7791C52.4221 13.6182 50.9517 14.479 49.8766 15.8788V15.9208C50.7713 14.843 51.9574 14.0801 52.8244 14.1991C53.3862 14.276 53.7538 14.773 53.6775 15.5638C53.407 19.2313 49.2454 27.6999 45.0214 29.5196Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M75.4009 11.0427C67.8685 11.6516 62.3752 16.9148 62.2642 23.3468C62.2295 25.5794 63.8109 29.4988 69.0961 29.9187C63.041 29.5688 61.2862 25.0895 61.3348 22.6679C61.4458 16.1169 66.9668 11.5257 75.4009 11.0007V11.0427Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M49.5854 15.4865C51.5621 12.463 55.0717 10.7412 57.9779 11.1402C59.9477 11.4131 61.2794 12.8899 61.0367 15.7874C60.187 24.0949 53.0907 30.3091 44.8204 29.9882V29.9392C53.7539 29.9602 59.3374 22.4504 59.9547 15.7664C60.1974 13.1419 58.9975 11.8051 57.2496 11.5671C54.6556 11.2032 51.4928 12.8199 49.6201 15.5355L49.5854 15.4865Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M76.2958 11.0917V11.0497C82.3579 11.4066 84.1057 15.8439 84.1196 18.2655C84.0086 24.8445 78.8552 29.4358 70.0535 29.9677V29.9327C78.0645 29.2958 83.0307 24.0886 83.1555 17.6216C83.2318 15.389 81.6573 11.5466 76.2958 11.0917Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M82.2954 16.9497C82.1384 23.7998 76.8286 29.3976 70.0535 29.8557L70.0951 29.8137C76.5317 29.1488 81.3244 22.5418 81.4354 16.3058C81.4631 14.4511 80.1037 11.6166 76.2473 11.2036V11.1686C80.9083 11.5466 82.2954 14.906 82.2954 16.9497Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M70.1086 29.7367C75.803 29.1418 80.3877 21.7509 80.5125 15.6269C80.5125 13.9682 79.4652 11.6515 76.2122 11.2806V11.3226C78.7716 11.7145 79.6802 13.5132 79.6802 14.99C79.5761 20.9881 75.1094 29.0368 70.2057 29.6877L70.1086 29.7367Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M67.5704 27.2937C67.6675 21.7646 71.9886 12.0501 75.2 11.3922V11.3432C71.1771 11.9451 66.7312 20.9247 66.6271 26.6218C66.572 27.3632 66.8167 28.0955 67.3054 28.652C67.7941 29.2084 68.4852 29.5415 69.2212 29.5753V29.5333C68.7063 29.4608 68.2417 29.1829 67.9315 28.762C67.6212 28.341 67.4912 27.8121 67.5704 27.2937Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M70.1852 29.5753V29.6243C74.3468 29.0154 78.6402 20.1898 78.7442 14.3107C78.8039 12.7953 77.6568 11.5074 76.1571 11.4062V11.4482C77.2118 11.627 77.9486 12.6 77.8426 13.6739C77.683 19.4199 73.5214 28.9524 70.1852 29.5753Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M65.8014 25.9783C65.9055 20.1342 70.5595 11.9315 75.2898 11.2806V11.2386C69.7411 11.8335 65.0038 19.3363 64.8859 25.3064C64.8859 26.9721 65.9679 29.4357 69.1862 29.7017V29.6597C66.6754 29.3308 65.7737 27.455 65.8014 25.9783Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M75.3454 11.1544C69.0822 11.8193 64.1369 18.5383 64.0329 24.6623C64.0051 26.496 65.1495 29.4005 69.1724 29.7645V29.8065C64.5045 29.4985 63.1173 26.0271 63.1173 23.9834C63.3977 17.1971 68.638 11.6814 75.3454 11.1124V11.1544Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M56.5006 12.0223C58.1028 12.2393 59.1224 13.4361 58.9073 15.7387C58.3247 22.0097 52.7135 29.8135 44.8481 29.8205C51.8881 29.3796 57.3259 21.4708 57.86 15.7177C58.082 13.6881 57.1664 12.6382 55.7792 12.4493C53.7747 12.1693 51.2778 13.5131 49.7172 15.6687L49.6825 15.6268C51.3818 13.1282 54.1909 11.7074 56.5006 12.0223Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M44.9453 29.6804V29.7224C51.4998 28.9315 56.355 20.8968 56.8752 15.6896C57.0278 13.9819 56.251 13.072 55.0927 12.89C53.3448 12.6451 51.1669 13.8349 49.8213 15.7386V15.7806C51.1045 14.1078 52.894 13.114 54.3089 13.31C55.3077 13.4499 55.918 14.2128 55.7863 15.6056C55.3493 20.3508 50.8895 28.4276 44.9453 29.6804Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M42.6007 24.0326C42.1423 25.1409 41.8118 26.2986 41.6158 27.4831C41.5048 28.7499 42.6007 29.4358 44.0711 29.6598V29.6178C43.0654 29.4358 42.2886 28.9179 42.3787 27.938C42.6097 26.738 42.963 25.5653 43.433 24.4386L49.4395 8.84507C49.4623 8.78043 49.5375 8.58938 49.6467 8.31171C50.2352 6.81565 51.8128 2.80515 51.5203 2.50408L50.2857 2.75604V2.82603C50.3264 3.96267 49.2393 6.75221 48.697 8.14395C48.5915 8.41471 48.5066 8.6326 48.4546 8.77508L42.6007 24.0326Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M40.9221 23.3185L46.5819 8.69785C46.6303 8.57352 46.6981 8.40862 46.7783 8.21362C47.3303 6.87175 48.4689 4.104 47.8997 3.3157V3.24572L49.0927 3.00775C49.4312 3.99668 48.2693 6.9103 47.7456 8.22364C47.6578 8.44378 47.5878 8.61929 47.546 8.73284L41.7752 23.6965C41.2986 24.7736 40.9584 25.907 40.7626 27.0699C40.6342 28.1274 41.3111 29.1142 42.337 29.3656C42.8656 29.559 43.4151 29.6883 43.9739 29.7505C41.262 29.5615 39.8123 28.3297 39.9649 26.545C40.1481 25.4331 40.4697 24.3491 40.9221 23.3185Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M44.5292 8.99877L39.2995 22.6046C38.8678 23.5705 38.5736 24.5931 38.4255 25.6421C38.2383 27.8748 40.1595 29.7015 43.9743 29.8414C40.7768 29.6595 39.0359 28.1337 39.2093 26.118C39.3698 25.0398 39.6779 23.9892 40.1249 22.9965L45.528 8.99877C45.591 8.83255 45.67 8.63497 45.7581 8.41468L45.7586 8.4135C46.3494 6.9362 47.3482 4.43895 46.6724 3.52564L45.4447 3.7776V3.84759C46.1978 4.89249 45.2928 7.11774 44.7027 8.5688C44.6406 8.72145 44.582 8.86553 44.5292 8.99877Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M43.9185 29.9258C39.6113 29.7368 37.4403 27.6162 37.6762 25.1526C37.8045 24.1678 38.0708 23.2063 38.4669 22.297L43.4538 9.2931C43.5271 9.10248 43.6078 8.90048 43.6918 8.69043C44.323 7.11128 45.1358 5.07761 44.3278 4.00895L43.1972 4.24691V4.3169C44.0332 5.2637 43.4714 6.73683 42.8778 8.29355C42.7868 8.53198 42.6952 8.77238 42.6076 9.01315L37.6415 21.8911C37.2409 22.7777 36.9811 23.7225 36.8716 24.6906C36.6358 27.3712 39.0009 29.7998 43.9047 29.9468L43.9185 29.9258Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M38.2449 13.5344C38.1547 11.8057 37.1421 11.0148 35.9144 11.0148C33.681 11.0148 31.7528 12.7925 30.539 15.2142V14.3603C30.4419 12.4776 29.1518 11.0148 26.856 11.0148C22.0009 11.0148 19.6079 14.2413 18.8103 17.8947H18.8519C19.6981 14.4723 21.9592 11.3858 26.322 11.3858C27.1664 11.3098 28.004 11.5917 28.6342 12.1639C29.2644 12.7361 29.6303 13.547 29.6443 14.4023C29.7562 16.5085 29.1056 18.1732 28.3747 20.043L28.2571 20.3443L24.7198 29.6738H25.6631L29.2004 20.4213L29.281 20.2038C29.4869 19.6482 29.6812 19.1237 29.8593 18.6156C30.5529 17.1458 31.4199 15.6411 31.8707 15.6411C32.2037 15.6411 32.3077 15.928 32.3285 16.187C32.3324 17.5799 31.9711 18.949 31.2812 20.1554L31.3228 20.1974C32.3988 18.9725 33.0405 17.4213 33.147 15.7881C33.147 15.3051 32.9111 14.9622 32.4534 14.9622C31.6973 14.9622 30.7332 16.5299 30.0258 18.1887C30.0991 17.9543 30.1617 17.7199 30.223 17.4903L30.2477 17.3978C31.0037 15.7391 32.0511 14.3253 33.0221 14.3253C33.6186 14.3253 33.9793 14.7382 34.014 15.4171C34.0972 17.0689 32.6268 19.4765 31.3922 20.2673L31.4338 20.3093C33.1123 19.5535 34.9573 17.0689 34.8532 15.0392C34.8047 14.1363 34.333 13.6394 33.5839 13.6394C32.3493 13.6394 31.1563 15.0882 30.331 16.8799C30.331 16.7953 30.3512 16.7108 30.3718 16.6246C30.3853 16.5682 30.399 16.5111 30.4073 16.453C31.3297 14.5283 32.6823 13.0025 34.1874 13.0025C35.0058 13.0025 35.63 13.5624 35.6855 14.6542C35.8173 17.0619 33.5631 19.6235 31.5239 20.3723L31.5656 20.4143C34.0694 19.7704 36.6843 17.0619 36.5387 14.2763C36.4693 12.9605 35.7271 12.3236 34.763 12.3236C33.0291 12.3306 31.4823 13.8984 30.5182 15.998V15.6551C31.621 13.4014 33.362 11.6937 35.3734 11.6937C36.4554 11.6937 37.3502 12.3936 37.4542 13.9194C37.6207 17.1179 34.6798 19.8264 31.6835 20.5053L31.7251 20.5403C35.1931 20.0084 38.4322 17.1109 38.2449 13.5344Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M28.7771 14.4724C28.7638 13.7031 28.4374 12.9732 27.8746 12.4542C27.3117 11.9353 26.562 11.6729 25.8015 11.7288C21.9313 11.7288 19.7048 14.6333 18.9211 17.8948H18.9696C19.8297 14.8573 21.7995 12.1067 25.2605 12.1067C25.9321 12.0438 26.5989 12.2689 27.0977 12.727C27.5965 13.185 27.8813 13.8338 27.8823 14.5143C27.9692 16.1605 27.2905 17.9578 26.6549 19.6409C26.5798 19.8398 26.5053 20.0372 26.4327 20.2324L22.7844 29.6739H23.7554L27.3275 20.3094L27.4006 20.1251C28.14 18.2622 28.8778 16.4033 28.7771 14.4724Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M26.3738 12.9904C26.8072 13.3904 27.0528 13.9564 27.0503 14.549C27.1097 16 26.4055 17.8107 25.7925 19.3868C25.69 19.6504 25.59 19.9074 25.4966 20.1551L21.8136 29.6735H20.8079L24.5603 20.0921C24.6072 19.9739 24.6559 19.852 24.7058 19.7271C25.3561 18.0998 26.2125 15.9565 26.1417 14.5979C26.1371 14.1038 25.9305 13.6334 25.5707 13.2981C25.2109 12.9629 24.7298 12.7925 24.2412 12.8272C21.7165 12.8272 19.9686 15.2908 19.1155 17.9014H19.0739C19.8785 15.0949 21.7373 12.4493 24.7753 12.4493C25.3599 12.394 25.9405 12.5905 26.3738 12.9904Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M25.2812 14.6609C25.2847 14.2507 25.1165 13.8581 24.8181 13.5796C24.5197 13.3011 24.1187 13.1626 23.7137 13.1981C21.6329 13.1981 19.9752 15.4797 19.1845 17.8943H19.2331C20.0584 15.7387 21.4734 13.569 23.1727 13.569C23.4886 13.532 23.8047 13.6362 24.0381 13.8542C24.2715 14.0721 24.3987 14.3822 24.3865 14.7028C24.4375 15.6524 23.6685 17.5884 23.0828 19.0627C22.9519 19.3923 22.8302 19.6988 22.7288 19.966L18.8724 29.6735H19.8365L23.6235 20.043L23.6652 19.9446C24.3202 18.3984 25.3491 15.9695 25.2812 14.6609Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M12.9286 23.0808L13.5529 21.758L11.4374 10.8678C10.8478 7.80227 11.5553 6.46548 13.9413 6.00356V5.96156H11.7357C11.0975 6.28351 9.74503 7.24936 10.501 10.8608L12.9286 23.0808Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M23.5337 14.7731C23.5549 14.5364 23.4714 14.3023 23.3056 14.1335C23.1397 13.9648 22.9083 13.8787 22.6736 13.8982C21.4459 13.8982 20.1212 15.8509 19.3235 17.8806H19.3721C20.1766 16.0749 21.4182 14.2692 22.1465 14.2692C22.4447 14.2692 22.6389 14.4232 22.6597 14.8011C22.6956 15.4822 21.7336 17.7774 21.1291 19.2195C21.0316 19.4521 20.9434 19.6625 20.8702 19.8403L16.9445 29.6387H17.9155L21.8066 19.8963C21.88 19.7144 21.9656 19.5062 22.0587 19.2798C22.6606 17.8164 23.5757 15.5913 23.5337 14.7731Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M0 5.96156H1.67157C2.32355 6.43749 3.07263 7.05339 3.78704 10.3989L7.92781 29.6738H6.99146L2.87843 10.5178C2.275 7.78127 1.52591 6.61246 0 6.00356V5.96156Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M4.79276 5.96158H3.23217V6.00357C3.87138 6.79049 4.09823 7.7379 4.5642 9.684C4.60384 9.84957 4.64522 10.0224 4.68872 10.2029L8.8503 29.6808H9.78666L5.62508 10.1399C5.26554 8.76507 4.98761 7.36982 4.79276 5.96158Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M11.0212 27.0352L10.4039 28.337L6.53367 10.0769C6.1701 8.73636 5.9927 7.35136 6.00653 5.96158H7.3313C7.08161 6.76645 6.94289 7.94226 7.55325 10.5109L11.0212 27.0352Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M12.2975 24.3966L11.6802 25.7124L8.52436 10.5458C7.99722 7.93526 8.13594 6.94842 8.59372 5.96158H10.0919V6.00357C9.22489 6.78745 9.00294 8.15923 9.52314 10.7978L12.2975 24.3966Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M22.9372 5.96158C20.9674 7.12339 19.7952 9.27205 17.3399 14.5982L17.4023 14.6332C20.2391 9.27205 21.8552 7.13739 25.5174 6.02457V5.96158H22.9372Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M17.2636 14.5352L17.2011 14.4932C18.727 10.2799 19.4623 8.23621 19.2681 6.01757V5.96158H21.1546V6.00357C20.3029 7.30221 19.7242 8.67957 17.9349 12.9383L17.9291 12.9522C17.7239 13.4407 17.5028 13.9669 17.2636 14.5352Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M17.0767 14.4092L17.1391 14.4512C18.6164 9.489 19.095 7.29835 17.7911 5.96156H15.953V6.00356C18.0338 6.74544 18.4638 8.8661 17.0767 14.4092Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M84.6191 13.2753V12.3304H85.0352V12.1975H84.0781V12.3304H84.4804V13.2753H84.6191Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M86.2144 12.3584V13.2753H86.4363L86.3947 12.1975H86.1728L85.8537 13.1003L85.5763 12.1975H85.3335L85.2919 13.2753H85.4237V12.3584L85.7219 13.2613H85.8814L86.2144 12.3584Z",
    fill: "#0F1632"
  })]
}), 'VrboLogo', 'Company');
export default VrboLogo;