/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SchibstedLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 126,
  height: 32,
  viewBox: "0 0 126 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.761601 15.1669L0.777004 15.1981C0.862506 15.3715 0.979867 15.6094 1.15716 15.8332C1.27966 15.9878 1.45201 16.0936 1.65992 16.0936C1.98559 16.0936 2.20795 15.8573 2.20795 15.5282C2.20795 15.4151 2.16987 15.3129 2.12131 15.2176C2.10476 15.1852 2.0791 15.1333 2.05566 15.0858L2.01793 15.0097C2.00073 14.9752 2.00142 14.9407 2.03101 14.9247C2.0658 14.905 2.09868 14.9223 2.11696 14.9526C2.14525 14.9995 2.23303 15.1396 2.23303 15.1396C2.33894 15.3048 2.51832 15.4256 2.72922 15.4256C3.06276 15.4256 3.32205 15.1493 3.32205 14.8187C3.32205 14.7238 3.29873 14.6347 3.25989 14.5548C3.25253 14.5397 3.24194 14.5173 3.23117 14.4945C3.21915 14.4691 3.20682 14.443 3.19856 14.4261C3.18143 14.3913 3.18105 14.3562 3.2111 14.338C3.24215 14.3189 3.27618 14.3371 3.2946 14.3655C3.31731 14.4004 3.36342 14.4698 3.38766 14.5036C3.48753 14.6431 3.64749 14.7273 3.82681 14.7273C4.12372 14.7273 4.36925 14.5041 4.36925 14.1635C4.36925 14.0568 4.32345 13.924 4.27489 13.8163C4.03395 13.2821 3.91627 12.7615 3.91627 11.9603C3.91627 10.0712 5.39374 8.53988 7.17952 8.53988C9.54499 8.53988 10.4249 10.3598 10.5936 10.9011C10.6137 10.966 10.6829 11.002 10.7479 10.982L14.2659 9.89937C14.3314 9.87926 14.3692 9.8099 14.3489 9.7446C13.5721 7.24027 11.0946 4.61475 7.21913 4.61475C4.0078 4.61475 1.33907 6.76626 0.360233 9.67005C-0.250881 11.4829 -0.0630816 13.499 0.761601 15.1669Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M7.29316 13.4154C7.10383 13.4154 6.95166 13.2619 6.95166 13.0742C6.95166 12.8848 7.10513 12.7327 7.29293 12.7327C7.48225 12.7327 7.63442 12.8861 7.63442 13.0739C7.63442 13.2632 7.48096 13.4154 7.29316 13.4154Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M6.95166 16C6.95166 16.1877 7.10383 16.3412 7.29316 16.3412C7.48096 16.3412 7.63442 16.189 7.63442 15.9997C7.63442 15.812 7.48225 15.6584 7.29293 15.6584C7.10513 15.6584 6.95166 15.8107 6.95166 16Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M13.8093 16.802L13.8247 16.8332C14.6494 18.5011 14.8372 20.5173 14.2261 22.3301C13.2472 25.2339 10.5785 27.3854 7.36718 27.3854C3.49175 27.3854 1.01418 24.7598 0.237441 22.2556C0.217102 22.1903 0.254952 22.1209 0.320407 22.1008L3.83836 21.0181C3.90336 20.9981 3.97256 21.0341 3.99275 21.099C4.16143 21.6404 5.04132 23.4603 7.40679 23.4603C9.19257 23.4603 10.67 21.929 10.67 20.0398C10.67 19.2387 10.5523 18.718 10.3114 18.1838C10.2629 18.0762 10.2171 17.9434 10.2171 17.8366C10.2171 17.496 10.4626 17.2728 10.7595 17.2728C10.9388 17.2728 11.0988 17.357 11.1986 17.4966C11.2229 17.5303 11.269 17.5997 11.2917 17.6346C11.3101 17.6631 11.3442 17.6812 11.3752 17.6622C11.4053 17.6439 11.4049 17.6089 11.3877 17.574C11.3792 17.5567 11.3665 17.5297 11.3541 17.5035C11.3437 17.4815 11.3335 17.4599 11.3264 17.4453C11.2876 17.3654 11.2643 17.2763 11.2643 17.1815C11.2643 16.8508 11.5235 16.5745 11.8571 16.5745C12.068 16.5745 12.2474 16.6953 12.3533 16.8606C12.3533 16.8606 12.4411 17.0006 12.4694 17.0476C12.4876 17.0778 12.5205 17.0951 12.5553 17.0754C12.5849 17.0594 12.5856 17.025 12.5684 16.9905L12.5317 16.9164C12.508 16.8685 12.4818 16.8154 12.465 16.7825C12.4164 16.6873 12.3784 16.5851 12.3784 16.472C12.3784 16.1428 12.6007 15.9065 12.9264 15.9065C13.1343 15.9065 13.3067 16.0124 13.4292 16.1669C13.6064 16.3907 13.7238 16.6286 13.8093 16.802Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M6.95166 14.5371C6.95166 14.7249 7.10383 14.8783 7.29316 14.8783C7.48096 14.8783 7.63442 14.7262 7.63442 14.5368C7.63442 14.3491 7.48225 14.1956 7.29293 14.1956C7.10513 14.1956 6.95166 14.3478 6.95166 14.5371Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M9.98334 16.5286C9.91092 16.4532 9.81243 16.3724 9.72656 16.3129C9.52584 16.174 9.34118 16.0591 9.11981 15.9571L9.0825 15.9399C8.96495 15.8855 8.89539 15.8533 8.73687 15.8086C8.60695 15.7718 8.48323 15.7556 8.36593 15.8043C8.18264 15.8803 8.08133 16.0749 8.14036 16.2864C8.18555 16.4481 8.31638 16.5126 8.45639 16.5798C8.68089 16.6876 8.79231 16.745 8.98309 16.8627C9.13181 16.9544 9.25852 17.0389 9.40961 17.1468C9.58885 17.2748 9.81572 17.2957 9.97584 17.154C10.1568 16.9938 10.1597 16.7121 9.98334 16.5286Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M10.4651 15.1095C10.7157 15.2381 10.9752 15.4402 11.2046 15.6746C11.351 15.8242 11.4092 16.0546 11.3145 16.2414C11.2272 16.4137 11.0356 16.5142 10.8442 16.488C10.718 16.4708 10.633 16.3966 10.5439 16.3189C10.5287 16.3056 10.5134 16.2923 10.4978 16.2791C10.3326 16.1397 10.2099 16.0423 10.033 15.9138L10.0014 15.891C9.89714 15.8159 9.80795 15.7517 9.73869 15.6334C9.64769 15.4782 9.65939 15.2884 9.77386 15.1496C9.87931 15.0218 10.0483 14.9723 10.21 15.0103C10.3196 15.0359 10.3817 15.0667 10.4651 15.1095Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M9.06596 14.4298C8.96281 14.3856 8.87755 14.3504 8.73005 14.3127C8.62093 14.2848 8.49064 14.2688 8.37777 14.2952C8.12192 14.3553 8.00064 14.6631 8.14715 14.8817C8.20649 14.9704 8.28433 15.0089 8.39223 15.0471C8.47901 15.0779 8.54324 15.101 8.64693 15.139L8.68062 15.1515C8.74861 15.1768 8.8221 15.2041 8.88214 15.2157C9.00861 15.2398 9.14159 15.1997 9.23411 15.1104C9.45158 14.9004 9.37856 14.5636 9.06596 14.4298Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M8.51408 12.8364C8.58374 12.8421 8.65539 12.8576 8.71182 12.8733C8.9208 12.9315 9.05011 13.1311 8.99574 13.3409C8.94887 13.5219 8.77216 13.6437 8.58611 13.6226C8.50238 13.613 8.41055 13.5837 8.33125 13.5559C8.24439 13.5256 8.17052 13.4625 8.12663 13.3816C8.03617 13.2152 8.08916 13.0011 8.246 12.8952C8.32606 12.8412 8.42577 12.8292 8.51408 12.8364Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M10.5373 13.4864C10.438 13.4245 10.35 13.3779 10.2402 13.3364C10.1199 13.291 10.0036 13.2572 9.88826 13.2495C9.61642 13.2313 9.39368 13.4819 9.44223 13.7497C9.46586 13.8801 9.55211 13.9942 9.67048 14.0534L9.70078 14.0686C9.78009 14.1084 9.84645 14.1417 9.93628 14.1872C9.95686 14.1977 9.97763 14.2086 9.99859 14.2197C10.0779 14.2614 10.1599 14.3046 10.2447 14.3273C10.3753 14.3624 10.5173 14.3334 10.624 14.2506C10.8781 14.0535 10.825 13.6654 10.5373 13.4864Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M12.1613 14.584C12.2708 14.68 12.3729 14.7842 12.4755 14.9102C12.6087 15.0738 12.6254 15.2764 12.5391 15.4479C12.4609 15.6033 12.2952 15.7012 12.1214 15.6949C11.9757 15.6894 11.8696 15.6132 11.7723 15.514C11.6642 15.404 11.5537 15.3036 11.4381 15.2015C11.4131 15.1795 11.3894 15.1591 11.3663 15.1394C11.3041 15.0861 11.2469 15.0371 11.1858 14.972C11.072 14.8508 11.0367 14.6705 11.0969 14.5155C11.1509 14.3762 11.2762 14.2716 11.423 14.2439C11.5668 14.2168 11.7058 14.2564 11.8257 14.3306C11.9647 14.4167 12.0444 14.4816 12.1613 14.584Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M7.63442 18.926C7.63442 18.7382 7.48226 18.5847 7.29293 18.5847C7.10513 18.5847 6.95166 18.7369 6.95166 18.9262C6.95166 19.114 7.10383 19.2675 7.29316 19.2675C7.48088 19.2675 7.63442 19.1152 7.63442 18.926Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M7.29293 17.1216C7.48226 17.1216 7.63442 17.2751 7.63442 17.4628C7.63442 17.6522 7.48088 17.8044 7.29316 17.8044C7.10383 17.8044 6.95166 17.6509 6.95166 17.4632C6.95166 17.2737 7.10513 17.1216 7.29293 17.1216Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M4.85925 15.6869C4.77338 15.6275 4.67489 15.5466 4.60247 15.4713C4.42607 15.2876 4.42897 15.0061 4.60997 14.8458C4.77009 14.7041 4.99696 14.7251 5.1762 14.8531C5.32729 14.961 5.454 15.0454 5.60272 15.1372C5.7935 15.2548 5.90491 15.3122 6.12942 15.4201C6.26943 15.4873 6.40026 15.5518 6.44545 15.7135C6.50448 15.925 6.40317 16.1196 6.21988 16.1956C6.10258 16.2443 5.97886 16.228 5.84894 16.1913C5.69023 16.1465 5.6207 16.1143 5.5029 16.0597L5.466 16.0427C5.24463 15.9408 5.05997 15.8259 4.85925 15.6869Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M3.38158 16.3254C3.61097 16.5597 3.8705 16.7618 4.12108 16.8904C4.2045 16.9332 4.26659 16.9641 4.37617 16.9897C4.53789 17.0277 4.70688 16.9782 4.81233 16.8503C4.9268 16.7116 4.93849 16.5218 4.8475 16.3665C4.77824 16.2483 4.68906 16.1841 4.5848 16.109L4.55318 16.0862C4.37632 15.9577 4.25359 15.8603 4.08835 15.7208C4.07273 15.7077 4.05746 15.6943 4.0423 15.6811C3.9532 15.6033 3.86822 15.5292 3.74196 15.5119C3.55057 15.4858 3.35902 15.5863 3.27169 15.7585C3.17703 15.9453 3.23522 16.1758 3.38158 16.3254Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M5.85602 17.6872C5.70852 17.6495 5.62326 17.6142 5.52011 17.5701C5.20752 17.4362 5.13449 17.0995 5.35196 16.8895C5.44449 16.8002 5.57746 16.76 5.70393 16.7843C5.76385 16.7957 5.83717 16.823 5.90504 16.8482L5.93914 16.8609C6.04283 16.8989 6.10706 16.922 6.19385 16.9528C6.30174 16.991 6.37951 17.0296 6.43892 17.1183C6.58543 17.3368 6.46408 17.6446 6.2083 17.7047C6.09544 17.7311 5.96514 17.7151 5.85602 17.6872Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M5.87445 19.1267C5.93088 19.1423 6.00253 19.1579 6.07226 19.1635C6.16051 19.1708 6.26029 19.1589 6.34035 19.1048C6.49711 18.9989 6.5501 18.7848 6.45972 18.6183C6.41582 18.5374 6.34196 18.4744 6.25509 18.444C6.17572 18.4163 6.08396 18.3869 6.00023 18.3774C5.81412 18.3563 5.6374 18.4781 5.59053 18.6591C5.53624 18.869 5.66547 19.0685 5.87445 19.1267Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M4.34633 18.6635C4.23645 18.622 4.14851 18.5753 4.04918 18.5135C3.76152 18.3344 3.70838 17.9464 3.96255 17.7493C4.06929 17.6664 4.21129 17.6375 4.34189 17.6726C4.42661 17.6953 4.50855 17.7385 4.58783 17.7802C4.60877 17.7912 4.62964 17.8022 4.6502 17.8126C4.74337 17.8599 4.81136 17.8939 4.89468 17.9357L4.91608 17.9464C5.03444 18.0056 5.12062 18.1198 5.14425 18.2501C5.19288 18.518 4.97014 18.7686 4.69822 18.7504C4.58284 18.7426 4.46653 18.7088 4.34633 18.6635Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M2.111 17.0897C2.21361 17.2157 2.31569 17.3198 2.42527 17.4159C2.54211 17.5183 2.62186 17.5831 2.7608 17.6692C2.8807 17.7435 3.01964 17.783 3.16355 17.7559C3.31036 17.7282 3.43569 17.6236 3.48967 17.4844C3.54985 17.3293 3.51452 17.149 3.40074 17.0278C3.33978 16.9629 3.28275 16.914 3.22075 16.8609C3.19752 16.841 3.17358 16.8204 3.14848 16.7983C3.03287 16.6963 2.9223 16.5959 2.81425 16.4859C2.71691 16.3867 2.61085 16.3104 2.46518 16.305C2.2913 16.2986 2.12568 16.3966 2.04745 16.552C1.96112 16.7235 1.97779 16.926 2.111 17.0897Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M36.5816 13.982C37.5004 12.8909 39.0508 12.4315 40.4005 12.4315C43.9896 12.4315 45.655 14.9296 45.655 18.0306V26.8092C45.655 26.8769 45.6001 26.9317 45.5325 26.9317H41.9586C41.891 26.9317 41.8361 26.8769 41.8361 26.8092V18.6909C41.8361 17.1117 41.0608 15.8191 39.2232 15.8181C37.477 15.8171 36.5669 17.0569 36.5816 18.6336V26.8092C36.5816 26.8769 36.5267 26.9317 36.459 26.9317H32.8853C32.8176 26.9317 32.7627 26.8769 32.7627 26.8092V6.26581C32.7627 6.19814 32.8176 6.14331 32.8853 6.14331H36.459C36.5267 6.14331 36.5816 6.19814 36.5816 6.26581V13.982Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M50.8561 5.79866C49.5558 5.78856 48.4834 6.85472 48.4834 8.18171C48.4834 9.44515 49.5457 10.5076 50.8379 10.5076C52.2617 10.5076 53.352 9.27295 53.1731 7.88265C53.0234 6.71823 52.0301 5.80775 50.8561 5.79866Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M49.0654 12.8047H52.6392C52.7069 12.8047 52.7617 12.8595 52.7617 12.9272V26.8092C52.7617 26.8769 52.7069 26.9317 52.6392 26.9317H49.0654C48.9977 26.9317 48.9429 26.8769 48.9429 26.8092V12.9272C48.9429 12.8595 48.9977 12.8047 49.0654 12.8047Z",
    fill: "#1C0732"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M64.0905 12.4315C61.9945 12.4315 60.4439 13.3504 59.8121 14.3553V6.26581C59.8121 6.19814 59.7573 6.14331 59.6896 6.14331H56.1734C56.1056 6.14331 56.0508 6.19814 56.0508 6.26581V26.8092C56.0508 26.8769 56.1056 26.9317 56.1734 26.9317H59.6323C59.7 26.9317 59.7548 26.8769 59.7548 26.8092V25.209C60.5012 26.4149 61.9945 27.2764 63.947 27.2764C67.9667 27.2764 70.6084 24.089 70.6084 19.8109C70.6084 15.6187 68.2252 12.4315 64.0905 12.4315ZM63.2866 23.889C61.3627 23.889 59.7238 22.3949 59.7238 19.8395C59.7238 17.2553 61.3627 15.819 63.2866 15.819C65.2391 15.819 66.7896 17.2553 66.7896 19.8395C66.7896 22.4238 65.2391 23.889 63.2866 23.889Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M92.4441 23.7445C92.8175 23.7445 93.2287 23.7066 93.4775 23.6616C93.5526 23.6479 93.6214 23.7067 93.6214 23.7831V26.7028C93.6214 26.7552 93.5896 26.8017 93.5406 26.8204C93.1742 26.9599 92.5063 27.1328 91.4679 27.1328C88.7114 27.1328 86.9887 25.496 86.9887 22.7681V16.1928H84.5556C84.488 16.1928 84.4331 16.1379 84.4331 16.0703V12.9271C84.4331 12.8595 84.488 12.8046 84.5556 12.8046H86.997L87.0024 8.70616C87.0024 8.63849 87.0573 8.58374 87.1248 8.58374H90.6562C90.7239 8.58374 90.7787 8.63864 90.7787 8.70624V12.8046H93.4988C93.5665 12.8046 93.6214 12.8595 93.6214 12.9271V16.0703C93.6214 16.1379 93.5665 16.1928 93.4988 16.1928H90.7787V22.1078C90.7787 23.3425 91.3531 23.7445 92.4441 23.7445Z",
    fill: "#1C0732"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M125.377 6.14331H121.823C121.755 6.14331 121.7 6.19814 121.7 6.26581V14.2117C121.298 13.4651 120.15 12.4602 117.709 12.4602C113.718 12.4602 110.933 15.7047 110.933 19.8395C110.933 24.1179 113.804 27.2764 117.824 27.2764C119.719 27.2764 121.155 26.4149 121.786 25.2951L121.794 26.8098C121.794 26.8773 121.849 26.9317 121.916 26.9317H125.377C125.445 26.9317 125.5 26.8769 125.5 26.8092V6.26581C125.5 6.19814 125.445 6.14331 125.377 6.14331ZM118.283 23.889C116.273 23.889 114.78 22.3663 114.78 19.8395C114.78 17.3128 116.331 15.8476 118.283 15.8476C120.236 15.8476 121.802 17.284 121.802 19.8109C121.802 22.3663 120.236 23.889 118.283 23.889Z",
    fill: "#1C0732"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M95.2197 19.8108C95.2197 15.3027 98.5506 12.374 102.168 12.374C106.533 12.374 109.146 15.1592 109.146 19.696C109.146 20.1098 109.113 20.5401 109.097 20.7488L109.096 20.7608C109.091 20.8246 109.038 20.8733 108.974 20.8733H98.9812C99.0674 22.7396 100.647 24.0891 102.542 24.0891C103.936 24.0891 104.837 23.543 105.383 22.6994C105.415 22.6488 105.479 22.6282 105.534 22.6523L108.397 23.8963C108.463 23.9249 108.491 24.0037 108.457 24.0667C107.442 25.9487 105.406 27.3624 102.513 27.3624C98.6366 27.3624 95.2197 24.5772 95.2197 19.8108ZM102.226 15.4752C100.245 15.4752 99.1534 16.9682 99.0674 18.2603H105.384C105.327 16.8533 104.408 15.4752 102.226 15.4752Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M79.3504 18.3348L77.3405 17.9042C76.5652 17.7605 76.0484 17.3702 76.0484 16.6811C76.0484 15.8771 76.8523 15.2741 77.8574 15.2741C79.235 15.2741 79.8737 16.0537 80.1007 16.8009C80.121 16.868 80.1957 16.9022 80.2611 16.8769L83.1061 15.7746C83.167 15.751 83.1998 15.6838 83.1787 15.6219C82.674 14.1495 81.1795 12.374 77.8286 12.374C74.7849 12.374 72.5453 14.4701 72.5453 16.9969C72.5453 18.9781 73.78 20.6699 76.5077 21.2728L78.3742 21.7036C79.4653 21.9334 79.896 22.395 79.896 23.0267C79.896 23.7733 79.2931 24.4337 78.0296 24.4337C76.4293 24.4337 75.5979 23.4791 75.4588 22.4111C75.4496 22.3396 75.3821 22.2941 75.3118 22.3095L72.2813 22.9741C72.2194 22.9877 72.1769 23.0461 72.1857 23.1089C72.4121 24.7292 73.9048 27.3624 78.0583 27.3624C81.7623 27.3624 83.5426 25.008 83.5426 22.7109C83.5426 20.6435 82.1356 18.9091 79.3504 18.3348Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M27.3849 21.5164L30.512 22.5589C30.5743 22.5796 30.6102 22.6459 30.592 22.7089C29.9209 25.027 27.7208 27.3624 24.0194 27.3624C19.8559 27.3624 16.5825 24.204 16.5825 19.8682C16.5825 15.5038 19.7986 12.374 23.9045 12.374C27.4582 12.374 29.5901 14.4069 30.3703 16.5952C30.3931 16.6592 30.3582 16.7296 30.2939 16.7518L27.09 17.8572C27.0291 17.8782 26.9634 17.8481 26.9376 17.7892C26.5012 16.7936 25.6165 15.9058 23.9907 15.9058C22.0382 15.9058 20.4016 17.3129 20.4016 19.8682C20.4016 22.4238 22.0669 23.8595 24.0194 23.8595C25.9002 23.8595 26.875 22.6606 27.2265 21.5973C27.2485 21.5308 27.3184 21.4942 27.3849 21.5164Z",
    fill: "#1C0732"
  })]
}), 'SchibstedLogo', 'Company');
export default SchibstedLogo;