/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var InvisionLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 33,
  viewBox: "0 0 87 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M67.0959 24.7884C63.4109 24.7884 60.6874 21.9669 60.6874 18.5037C60.6874 15.0168 63.4342 12.15 67.1419 12.15C70.8492 12.15 73.5734 14.9715 73.5734 18.4577C73.5734 21.9212 70.8269 24.7884 67.0959 24.7884ZM67.0959 14.5811C64.8757 14.5811 63.4568 16.3249 63.4568 18.4577C63.4568 20.6139 64.9901 22.3803 67.1419 22.3803C69.3843 22.3803 70.8036 20.6368 70.8036 18.5037C70.8036 16.3472 69.2699 14.5811 67.0959 14.5811ZM58.8801 12.4028H56.1103V24.5134H58.8801V12.4028ZM40.4106 12.4028H43.1799V24.5134H40.4106V12.4028ZM31.6003 24.6047H34.0949L39.0384 12.4028H36.1318L32.8589 21.3018L29.609 12.4028H28.9353V18.0574L31.6003 24.6047ZM49.7944 24.7427C48.0878 24.7427 46.2012 24.1292 44.6953 22.9506C45.3599 22.0034 46.0922 21.7859 46.6245 21.7859C46.9774 21.7859 47.2426 21.8816 47.3416 21.9234C48.2052 22.3313 49.0716 22.5405 49.863 22.5405C51.053 22.5405 51.7399 22.0359 51.7399 21.2328V21.1871C51.7399 20.3102 50.625 19.9719 49.3234 19.5769L49.3232 19.5768C49.2292 19.5483 49.1342 19.5195 49.0386 19.4901C47.2535 18.9854 45.2625 18.2514 45.2625 15.935V15.8889C45.2625 13.5947 47.1621 12.1957 49.5648 12.1957C51.0764 12.1957 52.7237 12.7234 54.0056 13.5725C53.3151 14.6471 52.5353 14.8874 51.976 14.8874C51.7288 14.8874 51.5246 14.8404 51.3904 14.7978L51.3448 14.7805C51.3071 14.7661 51.2694 14.7517 51.2322 14.7385C51.2207 14.7333 51.2148 14.7303 51.2148 14.7303L51.2152 14.7318C50.6083 14.5184 50.021 14.3978 49.4963 14.3978C48.4207 14.3978 47.8026 14.9025 47.8026 15.6136V15.6596C47.8026 16.4811 48.9623 16.8539 50.2842 17.2789C50.3646 17.3047 50.4455 17.3307 50.5268 17.357C52.289 17.9074 54.2801 18.7104 54.2801 20.8891V20.9348C54.2801 23.4817 52.3113 24.7427 49.7944 24.7427ZM83.3222 24.5134H86.0916V16.8063C86.0916 13.9851 84.5123 12.15 81.7889 12.15C79.9118 12.15 78.7906 13.1364 78.0123 14.2831V12.4028H75.2433V24.5134H78.0123V17.6776C78.0123 15.7969 79.1339 14.6732 80.7361 14.6732C82.3842 14.6732 83.3222 15.7509 83.3222 17.6317V24.5134Z",
    fill: "#DE466C"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M26.3122 31H2.62302C1.1744 31 0 29.823 0 28.3712V4.62956C0 3.17773 1.1744 2.00073 2.62302 2.00073H26.3122C27.7608 2.00073 28.9352 3.17773 28.9352 4.62956V12.4028V18.0574V28.3712C28.9352 29.823 27.7608 31 26.3122 31ZM5.8726 12.7656L5.24874 15.0724H7.30204L5.9765 20.5001C5.8726 20.943 5.82043 21.4212 5.82043 21.8114C5.82043 23.3487 6.65232 24.3693 8.41989 24.3693C9.88594 24.3693 11.0744 23.4967 11.93 22.0879L11.4076 24.1896H14.3187L15.9825 17.5017C16.3982 15.8083 17.204 14.9295 18.4259 14.9295C19.3877 14.9295 19.9855 15.5289 19.9855 16.5185C19.9855 16.8053 19.9595 17.1176 19.8556 17.4564L18.9977 20.5304C18.8677 20.9733 18.8159 21.4165 18.8159 21.8329C18.8159 23.2923 19.6735 24.3598 21.4671 24.3598C23.0007 24.3598 24.2225 23.3703 24.8983 20.9995L23.7547 20.557C23.1828 22.1453 22.6888 22.4325 22.2991 22.4325C21.909 22.4325 21.7009 22.1721 21.7009 21.6514C21.7009 21.4169 21.7532 21.1567 21.8309 20.8434L22.6629 17.8485C22.8709 17.1453 22.9489 16.5216 22.9489 15.9487C22.9489 13.7083 21.597 12.5391 19.9595 12.5391C18.4259 12.5391 16.8661 13.9255 16.0865 15.3846L16.6582 12.7656H12.2134L11.5895 15.0724H13.669L12.3886 20.2107C11.504 22.181 9.96869 22.4466 9.45377 22.4466C9.38319 22.4466 9.33168 22.4417 9.30384 22.4354C8.92307 22.3494 8.67976 22.2045 8.67976 21.7088C8.67976 21.4228 8.7316 21.0119 8.8617 20.5165L10.8114 12.7656H5.8726ZM7.87405 9.40642C7.87405 8.41682 8.67974 7.66142 9.64151 7.66142C10.6034 7.66142 11.4091 8.41682 11.4091 9.40642C11.4091 10.3967 10.6034 11.1523 9.64151 11.1523C8.67974 11.1523 7.87405 10.3967 7.87405 9.40642Z",
    fill: "#DE466C"
  })]
}), 'InvisionLogo', 'Company');
export default InvisionLogo;