/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TrivagoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 107,
  height: 32,
  viewBox: "0 0 107 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M33.4666 7.19995H38.7999L42.9332 18.2666H43.0666L46.7999 7.19995H51.8666L45.4666 23.4666H40.1332L33.4666 7.19995Z",
    fill: "#F48F00"
  }), _jsx("path", {
    d: "M13.8667 11.2H8.93333V16.6666C8.93333 17.6 9.06667 18.8 9.86667 19.3333C10.6667 19.8666 11.8667 19.7333 12.8 19.6C13.3333 19.6 13.6 19.4666 13.8667 19.2V23.3333C13.2 23.4666 12.6667 23.6 12 23.7333C8.53333 24.1333 3.46667 23.4666 3.46667 18.9333V11.2H0V7.19997H3.46667V2.39997H8.93333V7.19997H13.8667V11.2ZM27.8667 7.19997H32.8V23.4666H27.8667V7.19997ZM27.6 2.79997C27.6 1.3333 28.8 0.133301 30.2667 0.133301C31.7333 0.133301 32.9333 1.3333 32.9333 2.79997C32.9333 4.26663 31.7333 5.46663 30.2667 5.46663C28.9333 5.46663 27.6 4.26663 27.6 2.79997Z",
    fill: "#007FAD"
  }), _jsx("path", {
    d: "M89.4667 15.3334C89.4667 17.4667 90.8 19.4667 93.0667 19.4667C95.4667 19.4667 96.6667 17.6001 96.6667 15.3334C96.6667 13.2001 95.3334 11.2001 93.0667 11.2001C90.6667 11.2001 89.4667 13.0667 89.4667 15.3334ZM84.9333 15.3334C84.9333 11.8667 86.8 8.53339 90.1333 7.33339C93.4667 6.13339 97.4667 7.06672 99.6 9.86672C101.6 12.5334 101.867 16.5334 100.267 19.6001C98.6667 22.8001 94.9333 24.2667 91.4667 23.6001C87.3333 22.9334 84.9333 19.3334 84.9333 15.3334Z",
    fill: "#C94A30"
  }), _jsx("path", {
    d: "M103.6 11.7334C102.4 11.7334 101.467 10.8 101.467 9.60005C101.467 8.40005 102.4 7.46672 103.6 7.46672C104.8 7.46672 105.733 8.40005 105.733 9.60005C105.867 10.6667 104.8 11.7334 103.6 11.7334ZM103.6 6.80005C102.133 6.80005 100.933 8.00005 100.933 9.46672C100.933 10.9334 102.133 12.1334 103.6 12.1334C105.067 12.1334 106.267 10.9334 106.267 9.46672C106.267 8.00005 105.2 6.80005 103.6 6.80005ZM104 9.06672C104 8.80005 103.733 8.80005 103.467 8.80005H103.2V9.33338H103.333C103.733 9.33338 104 9.33338 104 9.06672ZM105.067 10.8H104.267L103.6 9.86672H103.333V10.8H102.667V8.26672H104.133C104.667 8.40005 104.933 8.93338 104.533 9.46672C104.4 9.60005 104.267 9.73338 104.133 9.73338L105.067 10.8Z",
    fill: "#CDD0D2"
  }), _jsx("path", {
    d: "M79.6001 7.0666V9.33326H79.4667C79.3334 9.19993 79.2001 9.0666 78.9334 8.6666C75.7334 5.8666 70.1334 6.39993 68.1334 10.3999C67.0667 12.3999 66.9334 14.9333 67.2001 17.1999C67.4667 19.1999 68.2667 21.4666 70.0001 22.6666C72.0001 23.9999 74.6667 24.2666 76.9334 23.3333C78.0001 22.9333 78.6667 22.1333 78.9334 21.7333V23.3333C78.9334 24.5333 78.6667 25.4666 78.0001 26.1333C76.5334 27.5999 73.8667 27.4666 72.0001 27.0666C71.0667 26.7999 70 26.3999 69.0667 25.7333L66.8 29.3333C68.1334 30.2666 69.3334 30.7999 70.6667 31.1999C74.2667 32.1333 79.2001 32.1333 81.8667 29.3333C83.4667 27.7333 84.0001 25.4666 84.0001 22.2666V7.0666H79.6001ZM78.4001 18.2666C77.0667 19.8666 74.4001 19.8666 73.0667 18.2666C71.7334 16.6666 71.7334 13.8666 73.0667 12.2666C74.4001 10.6666 77.0667 10.6666 78.4001 12.2666C79.7334 13.8666 79.7334 16.6666 78.4001 18.2666C77.7334 19.0666 79.0667 17.4666 78.4001 18.2666Z",
    fill: "#C94A30"
  }), _jsx("path", {
    d: "M15.2 7.19993H20.2667V9.8666C20.8 8.79993 21.4667 8.13326 22.1333 7.59993C23.7333 6.53326 26.2667 6.93326 26.5333 7.0666V11.5999C25.4667 11.3333 23.3333 10.9333 21.8666 11.8666C20.9333 12.3999 20.1333 13.1999 20.1333 16.7999V23.5999H15.0667V7.19993H15.2Z",
    fill: "#007FAD"
  }), _jsx("path", {
    d: "M56.5334 16.9334C55.6001 17.6 54.8001 18.8 55.7334 19.7334C56.6667 20.6667 58.2667 20.5334 59.3334 20.1334C60.5334 19.7334 61.4667 18.8 61.4667 17.3334V16.2667C61.6001 16.4 57.8667 16.1334 56.5334 16.9334ZM61.6001 21.4667C60.9334 22.2667 60.2667 22.9334 59.3334 23.3334C55.7334 24.8 50.6667 23.3334 50.6667 18.9334C50.6667 14.2667 55.7334 13.3334 59.3334 13.2H61.6001C61.6001 12.2667 61.3334 11.6 60.6667 11.0667C59.4667 10 57.6001 10.1334 56.1334 10.8C55.4667 11.2 54.8001 11.6 54.2667 12.2667L51.6001 9.46671C52.5334 8.66671 53.6001 8.00005 54.9334 7.46671C57.0667 6.66671 59.7334 6.53338 61.8667 7.20005C64.0001 7.73338 65.3334 9.46671 65.8667 11.6C66.1334 12.6667 66.1334 13.8667 66.1334 15.2V23.4667H61.6001V21.4667Z",
    fill: "#F48F00"
  })]
}), 'TrivagoLogo', 'Company');
export default TrivagoLogo;