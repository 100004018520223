/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FoxLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 76,
  height: 32,
  viewBox: "0 0 76 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#FoxLogo_svg__clip0_1329_10753)",
    children: [_jsx("path", {
      d: "M0 0V32H9.68533V21.6721H18.4911V12.9163H9.68533V8.72812H20.4831L19.8968 0H0Z",
      fill: "#111212"
    }), _jsx("path", {
      d: "M36.0216 0C27.1575 0 20.0215 7.13605 20.0215 16.0001C20.0215 24.8641 27.1575 32 36.0216 32C44.8856 32 52.0215 24.8641 52.0215 16.0001C52.0215 7.13605 44.8856 0 36.0216 0ZM36.0216 6.59017C37.5182 6.59017 38.7229 7.79488 38.7229 9.29154V22.7085C38.7229 24.2051 37.5182 25.41 36.0216 25.41C34.5249 25.41 33.32 24.2051 33.32 22.7085V9.29154C33.32 7.79488 34.5249 6.59017 36.0216 6.59017Z",
      fill: "#111212"
    }), _jsx("path", {
      d: "M47.2217 0L56.3005 16L47.2217 32H57.6014L61.4903 25.1461L65.3793 32H75.7589L66.6802 16L75.7589 0H65.3793L61.4903 6.85369L57.6016 0H47.2217Z",
      fill: "#111212"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "FoxLogo_svg__clip0_1329_10753",
      children: _jsx("rect", {
        width: 75.7593,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'FoxLogo', 'Company');
export default FoxLogo;