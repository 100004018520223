/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GlossierLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 91,
  height: 33,
  viewBox: "0 0 91 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M20.3683 26H17L20.6192 7H24L20.3683 26Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M63.4972 8.00001C62.62 7.99722 62 8.62967 62 9.51427C62.0056 10.403 62.634 11.0243 63.5085 10.9993C63.9142 11.0015 64.3033 10.839 64.5848 10.5494C64.8664 10.2599 65.016 9.86864 64.9986 9.4669C64.9768 8.65778 64.314 8.01019 63.4972 8.00001Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M24.0137 19.9519C23.78 23.9897 26.5655 26.1578 29.7524 25.991C33.945 25.9877 36.9659 22.9473 36.9998 18.7011C37.0218 15.6807 35.4944 13.6877 32.7428 13.1506C28.1979 12.2634 24.2779 15.3938 24.0137 19.9519ZM28.9751 23.1424C27.949 22.7222 27.397 21.6081 27.4325 20.0303C27.4867 17.6954 28.6246 16.101 30.4957 15.7441C31.7539 15.5056 32.758 16.0159 33.2779 17.1484C33.9823 18.6811 33.5471 21.1427 32.3618 22.3386C31.4931 23.2092 30.0013 23.5627 28.9751 23.1424Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M45.2445 16.3027C45.4381 16.4117 45.6421 16.5266 45.8649 16.6416L48 15.2014C47.0051 12.7234 41.9461 12.163 39.9741 14.4214C38.4306 16.1843 38.8985 18.5591 41.021 19.7665C41.3546 19.956 41.7006 20.1276 42.0463 20.299C42.5775 20.5625 43.1078 20.8254 43.59 21.1519C43.8965 21.3631 44.1582 21.9285 44.0758 22.2811C43.9933 22.6337 43.5326 23.0361 43.1382 23.1725C42.0626 23.5434 40.8382 23.0311 40.2699 22.0915C40.1547 21.9011 40.0507 21.7018 39.9408 21.4911C39.8865 21.387 39.8308 21.2801 39.7715 21.1702L37 22.2345C37.072 22.5211 37.1612 22.8037 37.2671 23.081C38.6851 26.176 43.7495 27.0208 46.27 24.5878C46.7554 24.1186 47.0883 23.5857 47.2746 23.0318C47.3287 23.1646 47.3856 23.2964 47.4453 23.4271C48.5382 25.4638 50.9139 26.3854 53.6408 25.8504C55.8586 25.4154 57.0881 24.1838 57.3134 22.1721C57.5103 20.4155 56.719 19.3155 54.59 18.3955C54.4715 18.3443 54.3525 18.2935 54.2336 18.2428C53.8379 18.0741 53.4417 17.9051 53.0571 17.7155C52.4734 17.4272 52.0919 16.9772 52.3829 16.3472C52.6739 15.7172 53.3055 15.5172 53.9584 15.7339C54.3761 15.8745 54.774 16.0884 55.197 16.3158C55.3956 16.4225 55.5999 16.5324 55.8142 16.6388L58 15.1389C56.9248 13.5022 55.3352 12.9605 53.5397 13.0022C51.7442 13.0439 50.1776 13.5822 49.4111 15.2872C48.6447 16.9922 49.1929 18.6855 50.8642 19.6855C51.2312 19.9088 51.6216 20.1045 52.0124 20.3004C52.517 20.5534 53.0224 20.8068 53.4794 21.1205C53.8111 21.3488 54.1021 21.9388 54.0116 22.3038C53.9211 22.6688 53.4084 23.0805 52.9897 23.2038C51.8826 23.5321 50.7577 22.9955 50.2095 22.0188C50.1102 21.8422 50.0205 21.6604 49.9242 21.465C49.8777 21.3708 49.8297 21.2735 49.7784 21.1721L47.4503 22.0953C47.5082 20.7841 46.7912 19.4889 45.3737 18.7487C44.9891 18.5472 44.5842 18.3788 44.1791 18.2104C43.8421 18.0703 43.505 17.9302 43.1794 17.7708C42.5843 17.4798 42.1002 17.0507 42.405 16.3622C42.7097 15.6737 43.3928 15.5274 44.0722 15.7419C44.4694 15.8662 44.8336 16.0713 45.2445 16.3027Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M64 13H60.6121L58 26H61.404L64 13Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M67.5305 25.1956C70.5699 27.0074 75.3723 25.6244 77.0032 22.3914L74.5342 21.1797C72.6616 23.1694 71.2393 23.7096 69.7248 23.0596C68.6885 22.6158 68.2347 21.7781 68.4086 20.5098H77.8448C77.8619 20.1406 77.8901 19.7799 77.9179 19.4256L77.9179 19.4246C77.9777 18.6613 78.0352 17.9278 77.9734 17.2037C77.7909 15.0711 76.259 13.5468 74.0752 13.1363C69.69 12.3102 65.4301 15.337 65.0424 19.5906C64.8354 21.8595 65.3432 23.8924 67.5305 25.1956ZM72.5155 15.6861C73.9361 15.741 74.6542 16.4524 74.7933 18.0315H69.1197C69.3284 16.7383 70.9645 15.6263 72.5155 15.6861Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M84.638 13.2302H81.4766L79 26H82.2159C82.3415 25.3764 82.4611 24.7594 82.5799 24.1467C82.7962 23.0315 83.0096 21.9306 83.2515 20.8297C84.0316 17.2901 85.815 15.7907 88.9967 16.0226L90 13.3138C87.7567 12.5359 86.0398 13.2626 84.4404 14.849L84.638 13.2302Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M88 24.5074C88.0056 25.3874 88.64 26.0084 89.5272 25.9999C89.9188 26.0033 90.2954 25.8481 90.5723 25.569C90.8493 25.2899 91.0033 24.9104 90.9999 24.5158C91.0023 23.681 90.3326 23.0023 89.5042 23C88.6758 22.9977 88.0023 23.6725 88 24.5074Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M13.8497 13.7318L17 12.9143C16.5607 9.79489 14.87 7.70939 12.3235 7.2217C9.32546 6.63826 6.49892 7.18446 4.07492 9.121C0.777581 11.7545 -0.286529 15.3669 0.0635071 19.4598C0.418794 23.6468 3.3591 26.1686 7.50352 25.9912C9.05768 25.9238 10.3843 25.3227 11.5867 24.1859L11.6987 25.7305H14.5077L16.2737 16.5089H9.54948L9.02443 19.2612H12.2535C12.0505 20.3182 11.3347 21.3095 10.3283 21.9993C7.64179 23.8401 4.3392 22.5278 3.70913 19.3197C3.56704 18.4674 3.53702 17.5998 3.61987 16.7395C3.85265 13.4463 6.11388 10.7862 9.02618 10.2968C11.0354 9.95627 12.5423 10.772 13.3806 12.6536C13.4815 12.8779 13.5789 13.104 13.6935 13.3699C13.7419 13.4825 13.7935 13.6021 13.8497 13.7318Z",
    fill: "#0F1632"
  })]
}), 'GlossierLogo', 'Company');
export default GlossierLogo;