/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TelusDigitalLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 198,
  height: 33,
  viewBox: "0 0 198 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M81.6406 10.827V8.02661H71.6572V22.4312H81.6406V19.5095H75.7035V16.3176H80.6429V13.5172H75.7035V10.827H81.6406Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M99.2591 19.5866H93.0188V8.02661H88.9229V22.4312H99.2591V19.5866Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M132.225 13.5172C129.463 12.8943 128.498 12.8226 128.498 11.7256C128.498 10.7058 129.866 10.386 130.654 10.386C131.933 10.386 133.432 10.6837 134.959 11.472L136.073 8.82045C134.507 7.96048 132.49 7.60767 130.549 7.60767C126.74 7.60767 124.182 9.15121 124.077 12.1666C123.967 14.9119 126.2 15.9428 128.256 16.3673C130.235 16.7752 132.242 17.128 132.286 18.3022C132.324 19.3882 131.349 19.8513 129.507 19.8513C127.804 19.8513 126.216 19.3055 124.596 18.6275L123.653 21.5051C125.511 22.2989 127.401 22.7344 129.353 22.7344C132.06 22.7344 134.033 22.3154 135.489 20.9483C136.497 20.0111 136.828 18.881 136.828 17.8006C136.823 15.5183 135.406 14.2339 132.225 13.5172Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M65.8475 10.8711V8.02661H53.2842V10.8711H57.5289V22.4312H61.6028V10.8711H65.8475Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M113.222 8.02661V17.1776C113.222 17.8943 113.222 18.0927 113.178 18.3739C113.051 19.2449 112.202 19.752 110.852 19.7686C109.854 19.752 109.121 19.4599 108.779 18.9362C108.608 18.666 108.526 18.1864 108.526 17.4147V8.02661H104.281V17.9549C104.281 19.3331 104.463 19.9505 105.086 20.7333C106.111 22.0177 108.107 22.6903 110.863 22.6903C110.89 22.6903 110.924 22.6903 110.951 22.6903C110.968 22.6903 110.99 22.6903 111.012 22.6903C114.694 22.6903 116.596 21.2515 117.147 19.9064C117.423 19.2559 117.445 18.9472 117.445 17.6297V8.02661H113.222Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M55.2016 4.07939L31.3979 8.82027V10.4796L31.4751 10.4575C47.2027 6.63726 55.2016 4.33848 55.2677 4.32195C55.3559 4.2999 55.389 4.22823 55.378 4.1731C55.3615 4.11247 55.3008 4.06285 55.2016 4.07939Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M35.2239 5.40796C35.5932 5.17092 35.6979 5.31976 35.5877 5.58437C35.4168 6.00884 34.612 8.58876 28.6142 13.5061C27.0486 14.785 25.9405 15.5071 23.6859 16.8412C27.3738 11.6758 32.7597 7.00663 35.2239 5.40796ZM35.8468 3.62737C34.6891 3.69352 32.5447 5.40796 31.3705 6.4278C26.6848 10.4796 23.2063 14.2062 20.6704 18.1036C15.3893 20.9316 8.66388 23.8368 1.97703 26.108L1.81716 26.1631L0.532715 29.6251L1.2659 29.3384C5.51616 27.7177 12.6109 24.6747 19.0442 21.2954C18.6804 22.2601 18.4985 23.1477 18.4985 23.958C18.4985 24.5644 18.5977 25.1212 18.8017 25.6339C19.2041 26.6482 20.0034 27.42 21.1059 27.861C22.7542 28.517 24.9979 28.5336 27.5613 27.7563C34.1048 25.7717 41.8721 19.6802 43.5645 18.4674L43.6251 18.4233C43.6913 18.3737 43.6968 18.3021 43.6692 18.2524C43.6307 18.1973 43.5535 18.1808 43.4928 18.2194L43.4101 18.269C41.5965 19.355 32.7818 24.5865 27.0486 26.1245C24.1324 26.9073 21.9053 26.4773 21.0674 25.3031C20.7752 24.9007 20.6374 24.3935 20.6374 23.8037C20.6374 22.6515 21.1776 21.1797 22.2305 19.5479C23.1952 18.9967 24.0938 18.4619 24.9042 17.9548C30.494 14.8126 36.9383 8.70453 37.2084 4.97797C37.2084 4.95041 37.2084 4.92285 37.2084 4.89528C37.2084 4.57555 37.0706 4.24479 36.8225 3.99672C36.5689 3.73763 36.2216 3.60532 35.8468 3.62737Z",
    fill: "#66CC00"
  }), _jsx("path", {
    d: "M31.3213 8.83699L6.27736 14.2229L5.30713 16.8248L31.3985 10.4798L31.4371 10.4687V8.81494L31.3213 8.83699Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M147.42 8.0708H152.392C156.72 8.17003 158.991 10.546 158.991 15.2538C158.991 19.9616 156.72 22.3376 152.392 22.4368H147.42V8.0708ZM148.787 21.2681H151.703C155.827 21.2901 157.618 19.5371 157.618 15.2538C157.618 10.9705 155.827 9.21743 151.703 9.23948H148.787V21.2681Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M161.13 8.0708H162.398V10.105H161.13V8.0708ZM161.13 12.0509H162.398V22.4368H161.13V12.0509Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M173.611 21.5878C173.589 24.6859 172.403 26.5767 169.002 26.5767C166.929 26.5767 164.879 25.6506 164.697 23.44H165.965C166.246 24.967 167.596 25.5128 169.002 25.5128C171.334 25.5128 172.343 24.1236 172.343 21.5878V20.182H172.304C171.72 21.4499 170.491 22.3154 169.002 22.3154C165.684 22.3154 164.316 19.9395 164.316 16.9847C164.316 14.1291 166.009 11.7532 169.002 11.7532C170.513 11.7532 171.797 12.6958 172.304 13.848H172.343V12.0564H173.611V21.5878ZM172.343 17.1225C172.343 15.0883 171.4 12.8171 169.002 12.8171C166.588 12.8171 165.579 14.9726 165.579 17.1225C165.579 19.2173 166.665 21.246 169.002 21.246C171.196 21.246 172.343 19.2338 172.343 17.1225Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M176.047 8.0708H177.315V10.105H176.047V8.0708ZM176.047 12.0509H177.315V22.4368H176.047V12.0509Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M181.791 12.0512H183.903V13.1151H181.791V20.1217C181.791 20.9486 181.913 21.4282 182.817 21.4888C183.181 21.4888 183.539 21.4668 183.903 21.4282V22.5142C183.522 22.5142 183.159 22.5528 182.778 22.5528C181.086 22.5528 180.507 21.9905 180.523 20.2209V13.1206H178.715V12.0567H180.523V8.93652H181.791V12.0512Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M185.562 15.2319C185.683 12.7953 187.392 11.7534 189.768 11.7534C191.598 11.7534 193.589 12.3157 193.589 15.0941V20.6067C193.589 21.0919 193.831 21.373 194.333 21.373C194.471 21.373 194.636 21.3344 194.735 21.2903V22.3543C194.454 22.4149 194.25 22.4369 193.908 22.4369C192.618 22.4369 192.42 21.7148 192.42 20.6233H192.381C191.494 21.9739 190.59 22.7346 188.6 22.7346C186.687 22.7346 185.121 21.7865 185.121 19.6972C185.121 16.781 187.96 16.6817 190.694 16.3565C191.742 16.2352 192.326 16.0974 192.326 14.9452C192.326 13.2363 191.097 12.8118 189.608 12.8118C188.037 12.8118 186.874 13.534 186.83 15.2264H185.562V15.2319ZM192.326 16.7589H192.288C192.128 17.0621 191.565 17.1613 191.224 17.222C189.068 17.6023 186.395 17.5858 186.395 19.6145C186.395 20.8824 187.519 21.6652 188.71 21.6652C190.639 21.6652 192.354 20.4359 192.332 18.4072V16.7589H192.326Z",
    fill: "#4B286D"
  }), _jsx("path", {
    d: "M196.218 8.0708H197.486V22.4368H196.218V8.0708Z",
    fill: "#4B286D"
  })]
}), 'TelusDigitalLogo', 'Company');
export default TelusDigitalLogo;