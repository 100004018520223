/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VervoeLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 120,
  height: 32,
  viewBox: "0 0 120 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("g", {
    clipPath: "url(#VervoeLogo_svg__a)",
    fill: "#023055",
    children: _jsx("path", {
      d: "M54.855 6.308v5.006a8.524 8.524 0 0 0-1.99-.276c-2.66 0-4.804 1.605-4.804 5.159V26h-5.515V6.063h5.515v2.592a5.764 5.764 0 0 1 4.962-2.58c.617.023 1.23.1 1.832.233ZM97.547 16.025a9.968 9.968 0 1 1-19.937 0 9.968 9.968 0 0 1 19.937 0Zm-14.54 0a4.57 4.57 0 1 0 9.142 0 4.57 4.57 0 0 0-9.141 0ZM15.844 6.002l-5.097 9.558-5.11-9.54L0 6.002l10.765 19.943L21.487 6.002h-5.643ZM72.605 6.002l-5.098 9.558-5.11-9.54-5.637-.018 10.765 19.943L78.248 6.002h-5.643ZM112.565 20.565c-.841.601-1.86.9-2.892.852a4.704 4.704 0 0 1-4.902-4.099h14.27c.074-.613.116-1.526.116-1.954a9.334 9.334 0 0 0-2.766-6.656 9.353 9.353 0 0 0-6.681-2.706 9.804 9.804 0 0 0-9.969 9.889 9.965 9.965 0 0 0 10.085 10.085 10.117 10.117 0 0 0 5.043-1.226l-2.304-4.185Zm-3.064-10.09a3.794 3.794 0 0 1 3.946 3.32h-8.388a4.292 4.292 0 0 1 4.418-3.32h.024ZM33.662 20.565a4.608 4.608 0 0 1-2.898.852 4.705 4.705 0 0 1-4.902-4.099h14.282a19 19 0 0 0 .123-1.955 9.341 9.341 0 0 0-9.46-9.361 9.803 9.803 0 0 0-9.975 9.907 9.965 9.965 0 0 0 10.091 10.085 10.116 10.116 0 0 0 5.043-1.226l-2.304-4.203Zm-3.088-10.09a3.792 3.792 0 0 1 3.945 3.32h-8.363a4.29 4.29 0 0 1 4.418-3.32Z"
    })
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "VervoeLogo_svg__a",
      children: _jsx("path", {
        fill: "#fff",
        d: "M0 0h119.158v32H0z"
      })
    })
  })]
}), 'VervoeLogo', 'Company');
export default VervoeLogo;