/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VeronicaBeardLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 265,
  height: 32,
  viewBox: "0 0 265 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M7.14678 18.6299C7.05274 19.0061 6.86467 19.3822 6.77063 19.8524C6.60412 19.524 6.47783 19.1767 6.39449 18.818L2.72706 8.28589H0L5.6422 23.7079H7.711L13.4472 8.28589H10.8142L7.14678 18.6299ZM12.5069 8.94414L7.24082 23.0496H6.11238L0.846329 9.03818H2.16284L5.73623 18.818L5.83027 19.0061C6.09284 19.7451 6.31262 20.4986 6.48853 21.2629H7.14678C7.24137 20.4194 7.46331 19.595 7.80504 18.818L11.2844 8.94414H12.5069Z",
    fill: "black"
  }), _jsx("path", {
    d: "M21.9102 23.7079H30.2794V21.2629H24.5432V16.9373H30.2794V14.5863H24.5432V10.6368H30.2794V8.28589H21.9102V23.7079ZM22.5684 9.03818H29.6212V10.0726H23.8849V15.3386H29.6212V16.373H23.8849V22.0152H29.6212V23.0496H22.5684V9.03818Z",
    fill: "black"
  }), _jsx("path", {
    d: "M50.3095 13.0818C50.3095 10.0726 48.0526 8.28589 44.3852 8.28589H40.7178V23.7079H43.2568V18.912L48.0526 23.7079H51.3439L45.7958 17.9717C46.4134 17.9474 47.0201 17.8017 47.5814 17.5428C48.1426 17.2838 48.6473 16.9168 49.0665 16.4626C49.4857 16.0084 49.8113 15.476 50.0246 14.8959C50.2379 14.3158 50.3347 13.6993 50.3095 13.0818ZM49.7453 23.0496H48.2407L42.5985 17.3134V23.0496H41.282V8.94414H44.2912C47.6765 8.94414 49.5572 10.4487 49.5572 13.0818C49.5777 13.635 49.4854 14.1867 49.2859 14.7031C49.0864 15.2196 48.7839 15.69 48.3969 16.0859C48.0098 16.4817 47.5463 16.7947 47.0344 17.0057C46.5226 17.2168 45.9732 17.3214 45.4196 17.3134H44.1971L49.7453 23.0496Z",
    fill: "black"
  }), _jsx("path", {
    d: "M48.3349 13.1757C48.3349 12.0473 47.7706 10.0725 44.1973 10.0725H42.5986V16.561H44.2913C46.8303 16.561 48.2408 15.3386 48.3349 13.1757ZM43.1629 15.9028V10.7308H44.1032C46.4541 10.7308 47.5826 11.5771 47.5826 13.1757C47.5826 15.4326 45.7018 15.9028 44.1973 15.9028H43.1629Z",
    fill: "black"
  }), _jsx("path", {
    d: "M242.52 13.0818C242.52 10.0726 240.264 8.28589 236.596 8.28589H232.929V23.7079H235.468V18.912L240.264 23.7079H243.555L238.007 17.9717C238.624 17.9474 239.231 17.8017 239.792 17.5428C240.354 17.2838 240.858 16.9168 241.277 16.4626C241.697 16.0084 242.022 15.476 242.236 14.8959C242.449 14.3158 242.546 13.6993 242.52 13.0818ZM241.956 23.0496H240.452L234.715 17.3134V23.0496H233.493V8.94414H236.502C239.887 8.94414 241.768 10.4487 241.768 13.0818C241.789 13.635 241.696 14.1867 241.497 14.7031C241.297 15.2196 240.995 15.69 240.608 16.0859C240.221 16.4817 239.757 16.7947 239.245 17.0057C238.734 17.2168 238.184 17.3214 237.631 17.3134H236.408L241.956 23.0496Z",
    fill: "black"
  }), _jsx("path", {
    d: "M240.546 13.1757C240.546 12.0473 239.982 10.0725 236.408 10.0725H234.81V16.561H236.502C239.041 16.561 240.452 15.3386 240.546 13.1757ZM235.468 10.7308H236.408C238.759 10.7308 239.888 11.5771 239.888 13.1757C239.888 15.4326 238.007 15.9028 236.502 15.9028H235.468V10.7308Z",
    fill: "black"
  }), _jsx("path", {
    d: "M66.5781 9.69648C65.8086 9.67505 65.0431 9.81454 64.3306 10.106C63.6181 10.3975 62.9743 10.8345 62.4405 11.3891C61.2807 12.6421 60.6419 14.2897 60.6538 15.9969C60.6305 16.8452 60.7765 17.6895 61.0832 18.4807C61.39 19.2719 61.8514 19.9939 62.4405 20.6047C62.9688 21.1665 63.6116 21.6084 64.3255 21.9004C65.0393 22.1924 65.8075 22.3278 66.5781 22.2974C67.3616 22.3188 68.1412 22.1797 68.8689 21.8886C69.5966 21.5975 70.2571 21.1606 70.8097 20.6047C71.9695 19.3518 72.6083 17.7042 72.5964 15.9969C72.624 15.1482 72.48 14.3026 72.173 13.5108C71.866 12.7191 71.4023 11.9974 70.8097 11.3891C70.2571 10.8333 69.5966 10.3964 68.8689 10.1053C68.1412 9.81419 67.3616 9.67507 66.5781 9.69648ZM70.3395 20.2286C69.8565 20.7336 69.2708 21.1292 68.622 21.3887C67.9731 21.6483 67.2762 21.7657 66.5781 21.7332C65.8941 21.7645 65.2115 21.6461 64.5781 21.3863C63.9446 21.1264 63.3755 20.7313 62.9107 20.2286C62.3785 19.6827 61.9624 19.0346 61.6877 18.3235C61.4129 17.6123 61.2851 16.8529 61.312 16.091C61.2851 15.3291 61.4129 14.5696 61.6877 13.8585C61.9624 13.1473 62.3785 12.4993 62.9107 11.9534C63.3755 11.4507 63.9446 11.0556 64.5781 10.7957C65.2115 10.5358 65.8941 10.4174 66.5781 10.4488C67.2751 10.4251 67.9694 10.5467 68.617 10.8057C69.2645 11.0647 69.8511 11.4555 70.3395 11.9534C71.3968 13.0681 71.9713 14.555 71.9382 16.091C71.9651 16.8529 71.8373 17.6123 71.5625 18.3235C71.2878 19.0346 70.8717 19.6827 70.3395 20.2286Z",
    fill: "black"
  }), _jsx("path", {
    d: "M66.5783 8.00376C65.5555 7.97156 64.5367 8.14656 63.5832 8.51825C62.6297 8.88994 61.7613 9.45063 61.0301 10.1666C60.2654 10.9297 59.663 11.8396 59.259 12.8415C58.855 13.8435 58.6578 14.9168 58.6792 15.9969C58.6449 17.0786 58.8362 18.1557 59.2409 19.1595C59.6457 20.1633 60.255 21.0718 61.0301 21.8271C61.7613 22.5431 62.6297 23.1038 63.5832 23.4755C64.5367 23.8472 65.5555 24.0222 66.5783 23.99C67.6036 24.044 68.6287 23.8791 69.5853 23.5062C70.542 23.1332 71.4082 22.5609 72.1264 21.8271C72.8911 21.064 73.4936 20.1541 73.8976 19.1522C74.3016 18.1503 74.4988 17.077 74.4774 15.9969C74.5117 14.9151 74.3204 13.838 73.9156 12.8343C73.5109 11.8305 72.9016 10.922 72.1264 10.1666C71.3953 9.45063 70.5268 8.88994 69.5734 8.51825C68.6199 8.14656 67.6011 7.97156 66.5783 8.00376ZM71.6563 21.357C70.2694 22.6257 68.4579 23.3293 66.5783 23.3293C64.6987 23.3293 62.8871 22.6257 61.5003 21.357C60.7938 20.6579 60.2375 19.8219 59.8656 18.9002C59.4937 17.9785 59.3139 16.9905 59.3375 15.9969C59.3231 15.0045 59.5071 14.0192 59.8785 13.0988C60.2498 12.1783 60.8012 11.3413 61.5003 10.6368C62.8871 9.36804 64.6987 8.6644 66.5783 8.6644C68.4579 8.6644 70.2694 9.36804 71.6563 10.6368C72.3697 11.3307 72.9305 12.1658 73.3029 13.0887C73.6753 14.0117 73.8511 15.0021 73.8191 15.9969C73.8334 16.9893 73.6495 17.9746 73.2781 18.895C72.9067 19.8154 72.3553 20.6524 71.6563 21.357Z",
    fill: "black"
  }), _jsx("path", {
    d: "M106.167 23.7079H108.706V8.28589H106.167V23.7079ZM106.731 8.94414H107.954V23.0496H106.731V8.94414Z",
    fill: "black"
  }), _jsx("path", {
    d: "M129.112 20.1345C128.627 20.6365 128.041 21.0302 127.393 21.2895C126.744 21.5488 126.048 21.668 125.351 21.639C124.655 21.65 123.965 21.5227 123.319 21.2644C122.673 21.0061 122.085 20.622 121.589 20.1345C120.532 19.0197 119.958 17.5328 119.991 15.9969C119.958 14.4609 120.532 12.974 121.589 11.8592C122.078 11.3614 122.664 10.9706 123.312 10.7116C123.959 10.4526 124.654 10.331 125.351 10.3547C126.709 10.335 128.023 10.8403 129.018 11.7652L129.3 12.0473L130.993 10.1666L130.711 9.97851C129.183 8.67219 127.231 7.97012 125.221 8.00476C123.212 8.03941 121.284 8.80839 119.803 10.1666C119.038 10.9297 118.435 11.8396 118.031 12.8415C117.627 13.8434 117.43 14.9167 117.452 15.9969C117.417 17.0786 117.609 18.1557 118.013 19.1595C118.418 20.1632 119.027 21.0717 119.803 21.8271C120.534 22.5431 121.402 23.1038 122.356 23.4755C123.309 23.8472 124.328 24.0222 125.351 23.99C127.425 24.0383 129.441 23.2991 130.993 21.9212L131.181 21.7331L129.394 19.8524L129.112 20.1345ZM125.351 23.3317C124.414 23.366 123.481 23.2085 122.608 22.8689C121.735 22.5293 120.94 22.0148 120.273 21.3569C119.559 20.663 118.999 19.8279 118.626 18.905C118.254 17.982 118.078 16.9916 118.11 15.9969C118.096 15.0044 118.28 14.0191 118.651 13.0987C119.022 12.1783 119.574 11.3413 120.273 10.6368C120.94 9.97891 121.735 9.46439 122.608 9.1248C123.481 8.78521 124.414 8.62771 125.351 8.66199C127.042 8.6262 128.696 9.15569 130.053 10.1666L129.3 11.1069C128.197 10.1755 126.794 9.67445 125.351 9.6964C124.567 9.67499 123.788 9.81411 123.06 10.1052C122.332 10.3963 121.672 10.8332 121.119 11.3891C119.959 12.642 119.32 14.2896 119.332 15.9969C119.32 17.7041 119.959 19.3517 121.119 20.6046C121.672 21.1605 122.332 21.5974 123.06 21.8885C123.788 22.1796 124.567 22.3187 125.351 22.2973C126.092 22.3386 126.834 22.2263 127.53 21.9674C128.226 21.7085 128.86 21.3086 129.394 20.7927L130.241 21.639C128.877 22.7903 127.134 23.3935 125.351 23.3317Z",
    fill: "black"
  }), _jsx("path", {
    d: "M145.099 8.28589L138.798 23.7079H141.525L142.842 20.3226H149.33L150.647 23.7079H153.562L147.168 8.28589H145.099ZM151.023 23.0496L149.801 19.6643H142.372L141.055 23.0496H139.739L145.475 8.94414H146.603L152.528 23.0496H151.023Z",
    fill: "black"
  }), _jsx("path", {
    d: "M145.757 10.8248C145.475 11.4831 142.748 18.5358 142.748 18.5358H149.236C149.236 18.5358 146.603 11.6712 146.321 10.8248H145.757ZM143.688 17.9716L145.851 12.5175C145.847 12.3858 145.879 12.2557 145.945 12.1413C146.039 12.3294 146.133 12.4235 146.133 12.5175L148.296 17.9716H143.688Z",
    fill: "black"
  }), _jsx("path", {
    d: "M216.754 8.28589L210.454 23.7079H213.181L214.497 20.3226H220.986L222.302 23.7079H225.217L218.823 8.28589H216.754ZM222.772 23.0496L221.55 19.6643H214.121L212.805 23.0496H211.488L217.224 8.94414H218.353L224.277 23.0496H222.772Z",
    fill: "black"
  }), _jsx("path", {
    d: "M217.506 10.8248C217.224 11.4831 214.497 18.5358 214.497 18.5358H220.986C220.986 18.5358 218.353 11.6712 218.07 10.8248H217.506ZM215.437 17.9716L217.6 12.5175C217.596 12.3858 217.629 12.2557 217.694 12.1413C217.788 12.3294 217.882 12.4235 217.882 12.5175L220.045 17.9716H215.437Z",
    fill: "black"
  }), _jsx("path", {
    d: "M179.61 16.373H177.259V22.0152H179.516C182.713 22.0152 183.183 20.2285 183.183 19.1941C183.183 17.8776 182.619 16.373 179.61 16.373ZM179.422 21.357H177.823V17.0313H179.516C181.49 17.0313 182.431 17.7836 182.431 19.1941C182.431 20.6047 181.396 21.357 179.422 21.357Z",
    fill: "black"
  }), _jsx("path", {
    d: "M182.243 12.7056C182.243 11.4831 181.679 9.97852 179.045 9.97852H177.259V15.3386H179.14C181.02 15.3386 182.243 14.3042 182.243 12.7056ZM177.823 14.6803V10.6368H178.951C181.208 10.6368 181.49 11.8592 181.49 12.7056C181.49 13.9281 180.644 14.6803 179.045 14.6803H177.823Z",
    fill: "black"
  }), _jsx("path", {
    d: "M182.619 15.4327C183.06 15.1073 183.418 14.682 183.663 14.1917C183.909 13.7013 184.034 13.1598 184.03 12.6116C184.04 11.872 183.853 11.1429 183.49 10.4987C183.127 9.85447 182.599 9.31808 181.961 8.94416C180.754 8.3958 179.426 8.16903 178.105 8.2859H175.284V23.7079H179.986C182.901 23.7079 185.158 21.8272 185.064 19.2882C185.098 18.473 184.881 17.6671 184.444 16.9781C184.008 16.2891 183.371 15.7499 182.619 15.4327ZM179.986 23.0496H175.942V9.03819H178.105C179.293 8.9558 180.484 9.14889 181.585 9.60241C182.133 9.91227 182.588 10.3637 182.902 10.9096C183.217 11.4555 183.379 12.0758 183.371 12.7056C183.364 13.2349 183.211 13.752 182.929 14.2C182.647 14.6479 182.247 15.0094 181.773 15.2446L181.114 15.6208L181.867 15.8088C182.624 16.0103 183.29 16.4658 183.752 17.0991C184.214 17.7324 184.445 18.5052 184.406 19.2882C184.406 21.451 182.525 23.0496 179.986 23.0496Z",
    fill: "black"
  }), _jsx("path", {
    d: "M193.998 23.7079H202.367V21.2629H196.631V16.9373H202.367V14.5863H196.631V10.6368H202.367V8.28589H193.998V23.7079ZM194.656 9.03818H201.709V10.0726H195.972V15.3386H201.709V16.373H195.972V22.0152H201.709V23.0496H194.656V9.03818Z",
    fill: "black"
  }), _jsx("path", {
    d: "M255.967 8.28589H252.582V23.7079H255.967C261.704 23.7079 264.713 21.0749 264.807 15.9969C264.901 10.9189 261.704 8.28589 255.967 8.28589ZM255.967 23.0496H253.24V8.94414H255.967C261.327 8.94414 264.149 11.3891 264.149 15.9969C264.149 20.6047 261.327 23.0496 255.967 23.0496Z",
    fill: "black"
  }), _jsx("path", {
    d: "M256.25 10.0725H254.557V22.0152H256.25C260.575 22.0152 262.832 19.9464 262.832 15.9968C262.832 12.0473 260.575 10.0725 256.25 10.0725ZM256.25 21.3569H255.121V10.8248H256.25C260.199 10.8248 262.174 12.6115 262.174 16.0909C262.174 19.5702 260.199 21.3569 256.25 21.3569Z",
    fill: "black"
  }), _jsx("path", {
    d: "M93.0964 18.724L89.2409 13.3639L85.7616 8.28589H83.4106V23.7079H85.9496V13.0818L89.9932 18.724L93.5666 23.7079H95.6354V8.28589H93.0964V18.724ZM93.7547 9.03818H94.9771V23.0496H93.9427L90.5292 18.2726L85.2914 11.013V23.0496H84.0689V9.03818H85.3854L88.7707 13.74L93.7547 20.6987V9.03818Z",
    fill: "black"
  })]
}), 'VeronicaBeardLogo', 'Company');
export default VeronicaBeardLogo;