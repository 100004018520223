/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StylepitLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 105,
  height: 33,
  viewBox: "0 0 105 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M38.8958 10.7743C38.5415 10.7743 38.3754 10.885 38.2569 11.2172C37.783 12.5314 37.2836 13.8369 36.7898 15.1445C36.0635 17.0689 35.3349 18.9921 34.561 21.0372C34.468 20.8058 34.417 20.6884 34.3727 20.5688C33.3866 17.8915 32.4 15.2172 31.4131 12.5458C31.2039 11.9778 31.1319 11.1917 30.7211 10.9127C30.2849 10.617 29.5187 10.792 28.8975 10.7875C27.5755 10.7776 26.2523 10.7875 24.865 10.7875V6.94214H20.7151V10.7953H17.9326V14.2277H20.7339V25.6587H24.8794V14.2233C25.6544 14.2233 26.3741 14.251 27.0872 14.2133C27.5101 14.1901 27.6939 14.3484 27.8501 14.7282C29.3614 18.4219 30.8972 22.1045 32.4052 25.8005C32.503 26.0652 32.5077 26.3554 32.4185 26.6231C31.7386 28.4888 31.0245 30.3423 30.3236 32.2003C30.2572 32.3763 30.2129 32.559 30.1376 32.797C31.3356 32.797 32.4417 32.7705 33.5445 32.8092C34.0162 32.8258 34.2089 32.652 34.375 32.2324C37.1098 25.2834 39.8532 18.3392 42.605 11.3998C42.677 11.2183 42.7412 11.0334 42.8353 10.7798C41.4701 10.7798 40.1824 10.7931 38.8958 10.7743Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M13.6718 13.9762C12.8403 13.6008 11.939 13.3694 11.0555 13.1247C9.7445 12.7604 8.4103 12.477 7.10377 12.0961C6.33757 11.8747 5.70312 11.4196 5.50825 10.5681C5.21594 9.29924 5.929 8.20751 7.27982 7.82552C9.20086 7.28409 10.8008 7.87977 11.9811 9.61037L16.1421 7.79009C15.5674 7.09143 15.14 6.4127 14.5654 5.89784C11.7386 3.37114 6.13937 3.27038 3.1875 5.6476C0.370717 7.9141 0.120483 12.3574 2.68593 14.7324C3.72672 15.6979 5.01111 16.2017 6.35971 16.5527C7.8899 16.9502 9.4367 17.2945 10.9436 17.7706C12.0221 18.1095 12.4938 18.8458 12.4938 19.8179C12.4938 20.8498 11.959 21.7301 10.9093 22.0324C10.0657 22.2807 9.18724 22.3903 8.30843 22.3568C6.40068 22.2638 5.49386 21.471 4.93471 19.6895C4.91089 19.6274 4.87283 19.5719 4.82359 19.5273C4.77436 19.4826 4.71533 19.4502 4.65126 19.4326C3.25504 19.416 1.85772 19.4215 0.428293 19.4215C0.419736 19.5657 0.419736 19.7103 0.428293 19.8544C0.736102 22.1918 1.91198 23.9379 4.00796 25.0385C5.84706 26.004 7.83122 26.1922 9.86409 26.0671C11.567 25.963 13.1492 25.5013 14.5742 24.517C17.9269 22.2062 18.3178 16.0744 13.6718 13.9762Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M75.1076 11.113C74.4632 11.4596 73.9173 11.9921 73.3272 12.435C73.2474 11.9242 73.1637 11.3835 73.0758 10.8129H69.6611V32.7859H73.7734V24.3809C73.8534 24.4304 73.9303 24.4848 74.0037 24.5437C76.2547 26.7581 81.865 26.9308 84.4282 23.1685C85.8577 21.0648 86.1555 18.7318 85.7004 16.3037C84.5888 10.3778 78.7116 9.17203 75.1076 11.113ZM81.4354 19.477C80.9482 21.47 79.2907 22.5252 77.1172 22.2849C75.3102 22.0856 73.9528 20.5709 73.8752 18.6654C73.8752 18.5181 73.8752 18.3709 73.8752 18.2225C73.8752 16.1066 74.9415 14.6019 76.754 14.1645C79.2763 13.5555 81.5018 15.2319 81.5904 17.8361C81.6129 18.3875 81.5608 18.9395 81.4354 19.477Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M63.7228 19.6817C63.6354 19.6817 63.5014 19.8156 63.4704 19.9142C63.0098 21.3458 61.9081 22.0567 60.5628 22.4509C58.8743 22.9458 57.0905 22.1187 56.5945 20.6461L66.4998 16.3157C66.3182 15.7621 66.1853 15.286 66.0059 14.8254C64.6152 11.2535 61.391 9.51067 57.7427 10.3488C53.2883 11.3741 50.7173 15.0955 51.6396 19.3484C52.2553 22.1851 53.7367 24.4627 56.5358 25.6109C59.7844 26.9396 64.2144 25.4647 66.2672 22.4309C66.8447 21.6125 67.2131 20.6652 67.3401 19.6717C66.1045 19.6717 64.9131 19.6662 63.7228 19.6817ZM55.474 18.0485C54.8163 16.6158 55.5847 14.9903 57.2743 14.1732C59.0625 13.3074 60.7377 13.768 61.556 15.3912L55.474 18.0485Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M48.9483 0.809082C47.686 1.3627 46.5013 1.89859 45.2944 2.38467C44.8604 2.5585 44.7474 2.79434 44.7485 3.24055C44.7618 10.4936 44.7652 17.7467 44.7585 24.9998V25.694C45.9942 25.694 47.1523 25.6675 48.3083 25.7062C48.8331 25.7228 48.9914 25.5789 48.9903 25.0419C48.9726 17.1813 48.9682 9.31998 48.977 1.45792C48.977 1.26526 48.9604 1.07371 48.9483 0.809082Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M101.841 10.7742V6.94653H97.6339V10.793H94.9102V14.2387H97.6638V25.6521H101.882V14.19H104.628V10.7742H101.841Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M88.3477 25.6576H92.4444V10.8274H88.3477V25.6576Z",
    fill: "#422152"
  }), _jsx("path", {
    d: "M90.4007 3.57057C89.072 3.57057 88.0755 4.51614 88.0611 5.77173C88.049 6.98969 89.0886 7.99173 90.3863 8.00501C91.684 8.0183 92.6882 7.05169 92.6905 5.78059C92.6938 4.4796 91.7482 3.56724 90.4007 3.57057Z",
    fill: "#422152"
  })]
}), 'StylepitLogo', 'Company');
export default StylepitLogo;