/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CampingWorldLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 79,
  height: 33,
  viewBox: "0 0 79 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#CampingWorldLogo_svg__clip0_2457_25976)",
    children: [_jsx("path", {
      d: "M12.2094 21.3263C11.7782 22.7705 11.3419 24.1929 10.894 25.6136C10.2421 27.6862 9.59193 29.7588 8.95344 31.8348C8.87488 32.0905 8.77125 32.2008 8.48543 32.1891C7.91212 32.1624 7.33714 32.1657 6.76383 32.1891C6.48637 32.2008 6.37104 32.1055 6.28914 31.8415C5.26453 28.5587 4.22823 25.281 3.19527 22.0016C2.56681 20.0059 1.93667 18.0102 1.3082 16.0144C1.26809 15.8891 1.238 15.7621 1.19287 15.5949C2.37292 15.5949 3.51954 15.5983 4.66448 15.5916C4.90851 15.5916 4.89347 15.7821 4.93526 15.9192C5.53865 17.8815 6.13202 19.8454 6.73876 21.806C7.09979 22.9761 7.44077 24.1494 7.6731 25.3412C7.80013 25.3579 7.8135 25.2843 7.82687 25.2258C8.37511 22.5833 9.30945 20.0527 10.0716 17.472C10.2304 16.9354 10.4126 16.4056 10.563 15.8657C10.6199 15.6634 10.7034 15.5866 10.9174 15.5882C11.825 15.5983 12.7343 15.6049 13.6419 15.5849C13.9026 15.5799 13.9778 15.6868 14.043 15.9091C14.9606 19.0548 16.0872 22.142 16.7508 25.3612C16.7558 25.3846 16.7742 25.4064 16.7859 25.4314C16.9279 25.3896 16.8895 25.2593 16.9096 25.1657C17.4511 22.6167 18.3554 20.1747 19.1008 17.6859C19.2847 17.0725 19.4869 16.4657 19.6758 15.854C19.7209 15.7069 19.7527 15.5866 19.9566 15.5882C21.0096 15.5966 22.0643 15.5916 23.1708 15.5916C22.8767 16.5293 22.5958 17.4268 22.3134 18.3244C20.9043 22.7989 19.492 27.2734 18.0946 31.7529C17.9876 32.0955 17.8355 32.2108 17.4795 32.1891C16.9346 32.1557 16.3847 32.1674 15.8382 32.1858C15.5958 32.1941 15.4821 32.1139 15.4119 31.8799C14.3756 28.4066 13.1688 24.9851 12.2596 21.4717C12.2462 21.4216 12.2295 21.3731 12.2144 21.3247L12.2094 21.3263Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M26.8196 6.93341C26.7411 9.24002 26.5021 11.4413 26.3383 13.6476C26.2831 14.3831 26.3583 14.231 25.7098 14.241C25.0161 14.251 24.3208 14.2343 23.6272 14.2477C23.3881 14.2527 23.2996 14.2026 23.323 13.9385C23.6121 10.5638 23.8896 7.18747 24.1687 3.81113C24.2406 2.94699 24.3141 2.08285 24.3676 1.21871C24.386 0.931217 24.4562 0.794158 24.7888 0.810872C25.3755 0.840959 25.9639 0.834273 26.5505 0.812544C26.8313 0.802515 26.9751 0.894445 27.0971 1.15018C28.3674 3.82283 29.7781 6.4303 30.9247 9.16146C30.9381 9.19322 30.9682 9.21662 31.0083 9.26843C31.351 8.48118 31.6602 7.70562 32.0195 6.95514C32.9572 4.99452 33.9166 3.04394 34.8644 1.08833C34.9446 0.92286 35.0265 0.809201 35.2471 0.814215C35.914 0.829258 36.5826 0.83093 37.2495 0.814215C37.5103 0.80753 37.5554 0.931217 37.5721 1.14851C37.7543 3.34145 37.9448 5.5344 38.1287 7.72568C38.3009 9.77154 38.4613 11.8191 38.6469 13.8649C38.6736 14.1658 38.6301 14.2594 38.3126 14.251C37.5521 14.2276 36.7899 14.2293 36.0294 14.251C35.7252 14.2594 35.6148 14.1725 35.5948 13.8616C35.4427 11.6001 35.1652 9.34699 35.0967 7.07883C35.0967 7.04373 35.0833 7.00863 35.0666 6.92673C34.7173 7.71398 34.403 8.47115 34.052 9.2116C33.2832 10.8246 32.4926 12.4258 31.717 14.0371C31.6234 14.2327 31.5064 14.3012 31.2874 14.3029C30.3498 14.3079 30.3431 14.3196 29.9369 13.4671C28.9273 11.3494 27.7924 9.29016 26.9383 7.10056C26.9249 7.06713 26.8965 7.04038 26.8213 6.93508L26.8196 6.93341Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M61.7495 8.34077C61.7495 7.66885 61.7495 7.05877 61.7495 6.44869C61.7495 4.70202 61.7562 2.95367 61.7361 1.207C61.7328 0.876056 61.8464 0.807526 62.1473 0.814212C62.9212 0.832598 63.6967 0.829255 64.4706 0.814212C64.733 0.809198 64.845 0.861013 64.8433 1.1602C64.8333 5.45584 64.835 9.74981 64.8433 14.0454C64.8433 14.3279 64.7581 14.4031 64.4806 14.4048C63.2789 14.4098 63.2805 14.4165 62.4816 13.4772C60.3137 10.9265 58.0388 8.46446 55.9896 5.78345C55.8693 5.90546 55.9245 6.04252 55.9295 6.15785C56.0214 8.71852 55.9646 11.2792 55.988 13.8399C55.9913 14.1374 55.9378 14.2644 55.6002 14.2527C54.7996 14.226 53.9973 14.236 53.1966 14.2494C52.9576 14.2527 52.8724 14.1875 52.874 13.9368C52.8807 9.66791 52.8807 5.40068 52.874 1.13179C52.874 0.879399 52.9626 0.812541 53.2 0.817555C53.8402 0.829255 54.482 0.832598 55.1222 0.815884C55.3461 0.809198 55.4631 0.924528 55.5851 1.06994C57.5842 3.43171 59.6802 5.71325 61.584 8.15524C61.6241 8.20538 61.6692 8.25219 61.7495 8.34244V8.34077Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M77.2337 10.1159C77.2337 11.0235 77.2236 11.9294 77.2404 12.837C77.2454 13.0961 77.1601 13.2498 76.9529 13.3953C75.8681 14.1641 74.6279 14.4499 73.3358 14.5419C70.9958 14.7073 68.8831 14.1257 67.2601 12.364C65.3246 10.2613 64.9986 7.74908 65.841 5.11654C66.6584 2.56089 68.5655 1.12511 71.1629 0.642057C72.8845 0.321138 74.5644 0.540099 76.1422 1.36245C76.3979 1.49617 76.5049 1.63992 76.4648 1.95248C76.3745 2.65282 76.3261 3.35984 76.291 4.0652C76.2743 4.39447 76.2024 4.40952 75.935 4.2407C74.6229 3.41166 73.1871 3.13253 71.6761 3.46849C69.6553 3.91811 68.385 5.73498 68.522 7.88614C68.6909 10.5555 71.0159 12.2888 73.6217 11.6887C74.3621 11.5182 74.3855 11.4881 74.3855 10.7477C74.3855 10.4268 74.3721 10.1075 74.3905 9.78826C74.4039 9.55258 74.3086 9.49575 74.0897 9.50077C73.5297 9.51414 72.9681 9.49743 72.4082 9.50912C72.1809 9.51414 72.0672 9.46901 72.0739 9.20492C72.0906 8.56475 72.0856 7.92459 72.0739 7.28442C72.0705 7.06379 72.1391 6.98021 72.3697 6.98021C73.8924 6.98857 75.4135 6.99024 76.9361 6.98021C77.2019 6.97854 77.2387 7.09387 77.237 7.31785C77.2287 8.25219 77.2337 9.18486 77.2337 10.1192V10.1159Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M53.4957 23.8168C53.4957 21.2043 53.5024 18.5902 53.4873 15.9777C53.4857 15.6551 53.5826 15.5799 53.8885 15.5866C54.903 15.605 55.9176 15.6066 56.9305 15.5866C57.2364 15.5799 57.3016 15.6702 57.3016 15.9643C57.2916 20.0176 57.2932 24.0709 57.2966 28.1242C57.2966 28.7142 57.223 28.6423 57.7963 28.6423C59.5982 28.6457 61.4 28.6507 63.2018 28.6373C63.4927 28.6356 63.5963 28.6975 63.5896 29.0084C63.5695 29.9277 63.5746 30.8486 63.5863 31.7679C63.5896 32.0103 63.5144 32.0855 63.267 32.0855C60.1163 32.0788 56.9673 32.0755 53.8166 32.0872C53.494 32.0872 53.489 31.9368 53.489 31.6977C53.494 29.0719 53.4923 26.4443 53.4923 23.8185L53.4957 23.8168Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M11.1596 11.0987C10.9741 11.6085 10.7902 12.1166 10.603 12.6264C10.4877 12.939 10.3272 13.2432 10.2654 13.5658C10.1467 14.1775 9.774 14.3848 9.194 14.465C6.60492 14.8227 4.30166 14.2879 2.45637 12.344C1.16601 10.9851 0.65287 9.31194 0.672928 7.47334C0.714714 3.83124 2.98121 1.17697 6.57483 0.601989C8.04739 0.366314 9.50155 0.535131 10.8889 1.13685C11.1396 1.2455 11.2566 1.37922 11.2215 1.68008C11.1429 2.36872 11.0944 3.0607 11.0627 3.75268C11.0476 4.08029 11.0125 4.15884 10.6716 3.98C9.36449 3.2947 7.97886 3.1192 6.56313 3.56882C4.67105 4.17054 3.59797 6.00246 3.84535 8.11685C4.07099 10.0608 5.56528 11.5166 7.52422 11.6887C8.77113 11.7991 9.97123 11.6302 11.1613 11.0987H11.1596Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M48.7972 7.56192C48.7972 5.45589 48.8039 3.34986 48.7906 1.24382C48.7889 0.926247 48.8491 0.799217 49.2001 0.810917C49.9856 0.83766 50.7746 0.827631 51.5618 0.81426C51.8159 0.809245 51.9095 0.876104 51.9078 1.14688C51.8995 5.39906 51.9011 9.64957 51.9078 13.9017C51.9078 14.1508 51.856 14.2561 51.5785 14.2494C50.7645 14.231 49.9506 14.2294 49.1366 14.2494C48.8357 14.2578 48.7922 14.1391 48.7922 13.8767C48.8006 11.7706 48.7972 9.66461 48.7972 7.55858V7.56192Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M38.7772 22.5498C38.2206 17.8079 33.7127 14.48 29.0026 15.3225C24.8206 16.0696 22.0059 19.4894 22.0293 23.8168C22.0293 24.0558 22.0209 24.2965 22.0326 24.5355C22.041 24.7344 22.0644 24.935 22.0961 25.1322C22.6176 28.4601 24.4061 30.8269 27.5919 31.9534C30.8111 33.0917 33.7813 32.4432 36.2717 30.1081C38.4463 28.0706 39.1182 25.4531 38.7772 22.5498ZM33.2581 27.8416C31.0551 29.6869 27.8961 29.0718 26.5322 26.5362C26.0742 25.6838 25.8586 24.7745 25.8636 23.4023C25.8937 22.0267 26.5138 20.5257 28.0114 19.4743C29.7798 18.2325 32.1248 18.5433 33.6743 20.1998C35.6148 22.274 35.4093 26.0381 33.2581 27.8416Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M77.1986 20.6494C76.1121 17.5956 73.1269 15.6551 69.7456 15.6032C67.9972 15.5765 66.2472 15.6032 64.4989 15.5849C64.1679 15.5815 64.1077 15.6902 64.1094 15.991C64.1211 18.5918 64.1161 21.1926 64.1161 23.7934C64.1161 26.3942 64.1211 29.0484 64.1111 31.6759C64.1111 31.9735 64.1662 32.0921 64.5022 32.0871C66.2104 32.0671 67.9203 32.1055 69.6286 32.0671C71.8449 32.0169 73.8607 31.34 75.4235 29.7337C77.9708 27.1145 78.3853 23.9789 77.2002 20.6494H77.1986ZM73.8573 25.057C73.4428 26.9875 71.9017 28.333 69.8392 28.5787C69.2675 28.6473 68.6942 28.6255 68.1226 28.6489C67.8301 28.6606 67.7699 28.5503 67.7732 28.2829C67.7849 26.7903 67.7783 25.2977 67.7783 23.8067C67.7783 22.3158 67.7866 20.875 67.7716 19.4108C67.7682 19.1166 67.8434 19.0214 68.1476 19.0264C69.0018 19.0414 69.8525 19.0264 70.6849 19.2721C72.5603 19.827 73.7604 21.2528 73.9459 23.19C74.0061 23.8151 73.9877 24.4402 73.8557 25.0587L73.8573 25.057Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M53.1547 31.6743C51.6537 29.4529 50.1544 27.2299 48.6451 25.0135C48.493 24.7912 48.4629 24.6826 48.737 24.5422C49.1315 24.3399 49.4624 24.0391 49.7366 23.6898C51.0019 22.0801 51.1991 20.2984 50.4035 18.4581C49.6062 16.6145 48.0668 15.7019 46.1045 15.6166C44.1589 15.5331 42.205 15.6016 40.2561 15.5849C39.9452 15.5815 39.9535 15.7336 39.9535 15.9509C39.9569 21.1943 39.9586 26.436 39.9502 31.6793C39.9502 31.9835 40.0171 32.0955 40.3447 32.0871C41.2924 32.0654 42.2418 32.0654 43.1895 32.0871C43.5154 32.0938 43.619 32.0119 43.6157 31.6743C43.599 29.8875 43.6073 28.099 43.6073 26.3123C43.6073 25.5952 43.6107 25.6337 44.3177 25.5818C44.8175 25.5451 45.0715 25.7423 45.3273 26.1468C46.5274 28.0389 47.7609 29.9092 48.9844 31.788C49.0763 31.9284 49.1465 32.0871 49.3688 32.0855C50.6876 32.0788 52.0064 32.0821 53.4104 32.0821C53.2934 31.8983 53.2266 31.7846 53.1547 31.6776V31.6743ZM45.6148 22.3927C45.0214 22.4579 44.4146 22.4161 43.8146 22.4295C43.5823 22.4345 43.6057 22.2807 43.6057 22.1336C43.6057 21.6138 43.6057 21.094 43.6057 20.5742C43.6057 20.0543 43.6057 19.5345 43.6057 19.0147C43.6057 18.8776 43.5956 18.7372 43.7996 18.7439C44.4247 18.7656 45.0548 18.6854 45.6766 18.7991C46.6277 18.9746 47.1976 19.6682 47.1826 20.626C47.1675 21.5754 46.5675 22.2891 45.6131 22.3944L45.6148 22.3927Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M22.9018 14.0188C21.2905 9.66795 19.6775 5.31883 18.0696 0.966361C17.996 0.767458 17.8857 0.708957 17.6835 0.713971C17.1904 0.725671 16.694 0.739043 16.2009 0.710628C15.9 0.692242 15.7864 0.817601 15.6928 1.07835C15.2415 2.33361 14.7735 3.58052 14.3088 4.83076C13.1956 7.83103 12.0874 10.8313 10.9642 13.8282C10.8422 14.1541 10.8606 14.2678 11.2484 14.2528C11.9554 14.2243 12.6641 14.2327 13.3711 14.2511C13.6386 14.2578 13.7739 14.1892 13.8609 13.9185C14.0898 13.1947 14.3673 12.486 14.6013 11.7623C14.6815 11.5132 14.8086 11.4414 15.061 11.4447C16.236 11.4564 17.411 11.4564 18.5861 11.4447C18.8267 11.4414 18.9371 11.5183 19.014 11.7472C19.263 12.4927 19.5371 13.2298 19.8046 13.9703C19.853 14.104 19.8781 14.2494 20.0854 14.2477C21.0314 14.2411 21.9774 14.2444 22.9469 14.2444C22.9268 14.1458 22.9201 14.0806 22.8984 14.0204L22.9018 14.0188ZM17.8189 8.98767C17.1403 8.9743 16.46 8.97764 15.7797 8.986C15.5641 8.98934 15.4905 8.93753 15.5691 8.71188C15.9869 7.49841 16.3965 6.28326 16.8277 5.01295C16.9631 5.44419 17.0784 5.83197 17.2071 6.21473C17.4812 7.03541 17.7537 7.8561 18.0445 8.6701C18.1364 8.92583 18.0896 8.99436 17.8189 8.98934V8.98767Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M47.8899 3.34813C47.3384 1.79033 45.8558 0.84429 44.0255 0.824233C42.6115 0.807518 41.1958 0.830919 39.7817 0.812533C39.4524 0.80919 39.3538 0.894434 39.3555 1.23207C39.3705 3.32473 39.3638 5.41739 39.3638 7.51005C39.3638 9.62946 39.3689 11.7489 39.3572 13.8683C39.3572 14.1624 39.4274 14.2611 39.7349 14.2527C40.4687 14.2293 41.2024 14.2293 41.9362 14.2527C42.2588 14.2627 42.3691 14.1825 42.3641 13.8399C42.3457 12.3339 42.3641 10.8279 42.3507 9.32191C42.3491 9.04445 42.4243 8.94917 42.7084 8.96422C43.1614 8.98595 43.616 8.97425 44.069 8.96923C47.1411 8.93915 48.8828 6.15785 47.8899 3.35147V3.34813ZM44.0991 6.33C43.5876 6.46205 43.0628 6.37848 42.543 6.39519C42.3374 6.40188 42.3574 6.2481 42.3574 6.11606C42.3574 5.68984 42.3574 5.26195 42.3574 4.83572C42.3574 4.43625 42.3624 4.0351 42.3558 3.63562C42.3524 3.46847 42.4076 3.39159 42.5847 3.3966C43.0243 3.41164 43.4673 3.38657 43.9052 3.43003C44.6289 3.5019 45.1471 4.05014 45.1922 4.73878C45.2474 5.56281 44.8613 6.13277 44.1008 6.32833L44.0991 6.33Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M77.4547 32.4198C76.8747 32.4198 76.4033 31.9484 76.4033 31.3684C76.4033 30.7884 76.8747 30.3171 77.4547 30.3171C78.0347 30.3171 78.506 30.7884 78.506 31.3684C78.506 31.9484 78.0347 32.4198 77.4547 32.4198ZM77.4547 30.531C76.9933 30.531 76.6173 30.9071 76.6173 31.3684C76.6173 31.8297 76.9933 32.2058 77.4547 32.2058C77.916 32.2058 78.2921 31.8297 78.2921 31.3684C78.2921 30.9071 77.916 30.531 77.4547 30.531Z",
      fill: "#00669E"
    }), _jsx("path", {
      d: "M77.3979 31.2831C77.4564 31.2831 77.4999 31.2714 77.525 31.2497C77.55 31.228 77.5651 31.1929 77.5651 31.1411C77.5651 31.0893 77.5517 31.0558 77.525 31.0341C77.4999 31.0124 77.4564 31.0023 77.3979 31.0023H77.2793V31.2848H77.3979M77.2793 31.4804V31.8966H76.9951V30.7968H77.428C77.5734 30.7968 77.6787 30.8218 77.7456 30.8703C77.8141 30.9188 77.8476 30.9957 77.8476 31.101C77.8476 31.1728 77.8308 31.233 77.7957 31.2798C77.7606 31.3249 77.7088 31.36 77.637 31.3818C77.6771 31.3918 77.7105 31.4118 77.7423 31.4419C77.774 31.4737 77.8041 31.5205 77.8359 31.584L77.9913 31.8949H77.6888L77.5551 31.6225C77.5283 31.5673 77.4999 31.5289 77.4732 31.5105C77.4447 31.4904 77.408 31.4787 77.3628 31.4787H77.2826",
      fill: "#00669E"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "CampingWorldLogo_svg__clip0_2457_25976",
      children: _jsx("rect", {
        width: 77.833,
        height: 32,
        fill: "white",
        transform: "translate(0.672852 0.5)"
      })
    })
  })]
}), 'CampingWorldLogo', 'Company');
export default CampingWorldLogo;