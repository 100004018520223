/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GithubLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 119,
  height: 32,
  viewBox: "0 0 119 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M22.7886 13.6955H12.848C12.7866 13.6951 12.7257 13.7068 12.6689 13.7301C12.6121 13.7534 12.5605 13.7878 12.5171 13.8312C12.4737 13.8746 12.4394 13.9262 12.4161 13.983C12.3928 14.0398 12.381 14.1006 12.3815 14.162V19.0206C12.3815 19.0819 12.3935 19.1426 12.417 19.1991C12.4404 19.2557 12.4748 19.3072 12.5181 19.3505C12.5614 19.3938 12.6129 19.4282 12.6694 19.4516C12.726 19.475 12.7867 19.4871 12.848 19.4871H16.7244V25.5284C16.7244 25.5284 15.8538 25.824 13.4393 25.824C10.5977 25.824 6.6293 24.786 6.6293 16.0608C6.6293 7.33559 10.7685 6.18253 14.6482 6.18253C18.0088 6.18253 19.4543 6.77384 20.3741 7.05964C20.4417 7.07264 20.5113 7.07063 20.578 7.05376C20.6447 7.03689 20.7069 7.00557 20.7602 6.96201C20.8134 6.91845 20.8565 6.86372 20.8863 6.80168C20.9161 6.73965 20.9319 6.67183 20.9326 6.60302L22.0396 1.90535C22.0418 1.83522 22.0267 1.76563 21.9958 1.70265C21.9649 1.63967 21.919 1.5852 21.8622 1.54399C21.4877 1.27461 19.2079 0 13.4458 0C6.80998 0 0 2.82517 0 16.3991C0 29.9731 7.7955 32 14.3657 32C19.8025 32 23.104 29.6742 23.104 29.6742C23.1563 29.6326 23.1974 29.5788 23.2238 29.5174C23.2502 29.4561 23.2609 29.3892 23.2551 29.3227V14.162C23.2551 14.0383 23.206 13.9196 23.1185 13.8321C23.031 13.7447 22.9123 13.6955 22.7886 13.6955Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M74.0326 1.64257C74.0352 1.58147 74.0257 1.52044 74.0047 1.46302C73.9836 1.4056 73.9514 1.35292 73.9098 1.30803C73.8683 1.26313 73.8183 1.2269 73.7626 1.20144C73.707 1.17598 73.6469 1.16178 73.5858 1.15967H68.0012C67.9362 1.15545 67.8711 1.16497 67.81 1.18761C67.749 1.21025 67.6934 1.2455 67.6469 1.29107C67.6004 1.33663 67.5641 1.39149 67.5402 1.45205C67.5163 1.51262 67.5055 1.57754 67.5084 1.64257V12.4472H58.7832V1.64257C58.7837 1.58147 58.772 1.52088 58.7489 1.46431C58.7258 1.40773 58.6918 1.35628 58.6487 1.31292C58.6057 1.26956 58.5545 1.23514 58.498 1.21166C58.4416 1.18818 58.3811 1.17609 58.32 1.17609H52.7354C52.6732 1.17432 52.6113 1.18511 52.5533 1.2078C52.4954 1.23049 52.4426 1.26463 52.3981 1.30817C52.3537 1.3517 52.3185 1.40376 52.2946 1.46121C52.2707 1.51867 52.2586 1.58035 52.259 1.64257V30.9225C52.2586 30.9838 52.2702 31.0445 52.2933 31.1013C52.3163 31.1581 52.3503 31.2097 52.3933 31.2533C52.4364 31.297 52.4875 31.3317 52.544 31.3555C52.6004 31.3794 52.661 31.3919 52.7222 31.3923H58.3069C58.3681 31.3919 58.4287 31.3794 58.4852 31.3555C58.5416 31.3317 58.5928 31.297 58.6358 31.2533C58.6788 31.2097 58.7128 31.1581 58.7358 31.1013C58.7589 31.0445 58.7705 30.9838 58.7701 30.9225V18.3965H67.5084V30.9291C67.508 30.9904 67.5196 31.0511 67.5426 31.1079C67.5657 31.1646 67.5997 31.2163 67.6427 31.2599C67.6857 31.3035 67.7369 31.3383 67.7933 31.3621C67.8498 31.3859 67.9103 31.3984 67.9716 31.3989H73.5858C73.7093 31.3971 73.8271 31.3469 73.9137 31.259C74.0004 31.1711 74.049 31.0526 74.049 30.9291L74.0326 1.64257Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M33.3535 5.46967C33.3463 4.75655 33.1284 4.06149 32.727 3.47201C32.3256 2.88253 31.7588 2.42499 31.0979 2.15702C30.437 1.88905 29.7116 1.82262 29.013 1.96611C28.3144 2.10959 27.6739 2.45657 27.1722 2.96335C26.6704 3.47013 26.3298 4.11405 26.1933 4.81401C26.0568 5.51398 26.1304 6.23869 26.4049 6.89688C26.6795 7.55508 27.1426 8.11732 27.7361 8.51282C28.3295 8.90831 29.0267 9.11936 29.7399 9.1194C30.2175 9.11942 30.6903 9.02477 31.1311 8.84091C31.5719 8.65706 31.9719 8.38765 32.3079 8.04826C32.644 7.70887 32.9094 7.30623 33.0888 6.86363C33.2683 6.42103 33.3582 5.94725 33.3535 5.46967Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M32.9526 24.7432V11.2186C32.9526 11.0954 32.9039 10.9772 32.8172 10.8899C32.7304 10.8025 32.6126 10.7529 32.4894 10.7521H26.9048C26.7743 10.7649 26.6531 10.8252 26.5641 10.9215C26.4751 11.0177 26.4245 11.1433 26.4219 11.2744V30.6564C26.4219 31.228 26.7767 31.3955 27.2366 31.3955H32.2628C32.8146 31.3955 32.9493 31.1262 32.9493 30.6498L32.9526 24.7432Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M95.3069 10.7981H89.7519C89.691 10.7985 89.6309 10.8109 89.5749 10.8346C89.5188 10.8583 89.468 10.8928 89.4253 10.9361C89.3826 10.9794 89.3488 11.0307 89.3259 11.0871C89.3031 11.1434 89.2915 11.2037 89.2919 11.2646V25.6237C88.2766 26.2903 87.09 26.6486 85.8755 26.6552C83.8716 26.6552 83.3394 25.7485 83.3394 23.784V11.2646C83.3398 11.2037 83.3283 11.1434 83.3054 11.0871C83.2825 11.0307 83.2487 10.9794 83.206 10.9361C83.1633 10.8928 83.1125 10.8583 83.0565 10.8346C83.0004 10.8109 82.9403 10.7985 82.8795 10.7981H77.239C77.1781 10.7985 77.118 10.8109 77.062 10.8346C77.0059 10.8583 76.9551 10.8928 76.9124 10.9361C76.8697 10.9794 76.8359 11.0307 76.8131 11.0871C76.7902 11.1434 76.7786 11.2037 76.7791 11.2646V24.7334C76.7791 30.5612 80.0247 31.9869 84.4924 31.9869C88.1553 31.9869 91.1119 29.96 91.1119 29.96C91.1583 30.3619 91.2262 30.7611 91.3156 31.1558C91.3561 31.2293 91.4148 31.2912 91.4861 31.3355C91.5575 31.3798 91.639 31.405 91.7229 31.4087H95.3102C95.4334 31.4078 95.5512 31.3583 95.6379 31.2709C95.7247 31.1835 95.7734 31.0654 95.7734 30.9422V11.2646C95.7726 11.1411 95.7231 11.023 95.6358 10.9357C95.5485 10.8484 95.4304 10.799 95.3069 10.7981Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M110.494 10.1377C108.632 10.1261 106.802 10.6127 105.192 11.547V1.64251C105.192 1.5814 105.181 1.52082 105.157 1.46424C105.134 1.40766 105.1 1.35621 105.057 1.31285C105.014 1.26949 104.963 1.23508 104.907 1.2116C104.85 1.18811 104.79 1.17602 104.729 1.17603H99.1143C99.0532 1.17602 98.9927 1.18811 98.9363 1.2116C98.8799 1.23508 98.8287 1.26949 98.7856 1.31285C98.7426 1.35621 98.7085 1.40766 98.6855 1.46424C98.6624 1.52082 98.6507 1.5814 98.6511 1.64251V30.9225C98.6507 30.9837 98.6623 31.0445 98.6854 31.1012C98.7084 31.158 98.7424 31.2097 98.7854 31.2533C98.8285 31.2969 98.8796 31.3316 98.9361 31.3555C98.9925 31.3793 99.0531 31.3918 99.1143 31.3922H103.01C103.094 31.3908 103.177 31.367 103.248 31.323C103.32 31.2791 103.378 31.2168 103.418 31.1426C103.53 30.6989 103.609 30.2476 103.654 29.7924C103.654 29.7924 105.954 31.9671 110.297 31.9671C115.398 31.9671 118.322 29.3785 118.322 20.3478C118.322 11.3171 113.664 10.1377 110.494 10.1377ZM108.303 26.6453C107.162 26.6161 106.048 26.2949 105.067 25.7123V16.4254C105.95 15.9205 106.927 15.6029 107.938 15.4924C109.939 15.315 111.88 15.9195 111.88 20.6894C111.867 25.732 110.996 26.7274 108.303 26.6453Z",
    fill: "#100E0F"
  }), _jsx("path", {
    d: "M49.0429 10.7489H44.8446V5.20363C44.8446 4.99339 44.7362 4.87512 44.4931 4.87512H38.7639C38.5405 4.87512 38.4354 4.97039 38.4354 5.1872V10.9328C38.4354 10.9328 35.5675 11.626 35.3737 11.6818C35.2781 11.7109 35.1944 11.7701 35.1352 11.8506C35.076 11.9311 35.0444 12.0286 35.0452 12.1286V15.7422C35.0474 15.8587 35.0931 15.9702 35.1733 16.0547C35.2536 16.1392 35.3626 16.1906 35.4788 16.1988H38.4354V24.8649C38.4354 31.3036 42.9524 31.9344 45.9911 31.9344C47.1219 31.8999 48.2432 31.7154 49.3254 31.3857C49.407 31.3509 49.4759 31.292 49.5229 31.2168C49.5699 31.1416 49.5927 31.0538 49.5882 30.9653V27.0034C49.5874 26.8806 49.5384 26.7629 49.4518 26.6757C49.3652 26.5885 49.2479 26.5387 49.125 26.537C48.8787 26.537 48.2545 26.6355 47.6106 26.6355C45.5476 26.6355 44.8479 25.6763 44.8479 24.4345V16.1988H49.0462C49.1075 16.1984 49.168 16.1859 49.2245 16.162C49.2809 16.1382 49.3321 16.1035 49.3751 16.0599C49.4181 16.0162 49.4521 15.9646 49.4752 15.9078C49.4982 15.851 49.5098 15.7903 49.5094 15.729V11.2186C49.5098 11.1571 49.4981 11.0961 49.4748 11.0391C49.4516 10.9821 49.4173 10.9303 49.3739 10.8866C49.3306 10.843 49.279 10.8083 49.2222 10.7847C49.1654 10.761 49.1045 10.7489 49.0429 10.7489Z",
    fill: "#100E0F"
  })]
}), 'GithubLogo', 'Company');
export default GithubLogo;