/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TravelPerkLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 141,
  height: 33,
  viewBox: "0 0 141 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M71.7843 23.0943L68.6094 14.0618H65.9917L70.2632 25.0463H73.1728L77.4355 14.0618H75.0123L71.7843 23.0943Z",
    fill: "#454B56"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M64.4352 14.1771H61.9059V15.437C61.0256 14.4477 59.7698 13.8774 58.4481 13.8665C57.0488 13.8342 55.7063 14.4216 54.7779 15.4725C53.7866 16.5735 53.2602 18.0178 53.3099 19.5008C53.2697 21.0211 53.8144 22.4985 54.831 23.6266C55.7334 24.6132 56.9967 25.1893 58.3309 25.2228C59.6651 25.2563 60.9556 24.7442 61.9059 23.8041V24.9309H64.4352V14.1771ZM56.5998 22.1625C55.9937 21.4118 55.6795 20.4661 55.7154 19.5007C55.6879 18.5662 56.0025 17.654 56.5998 16.9364C57.1742 16.286 58.0069 15.9252 58.8726 15.9515C59.7575 15.8901 60.6235 16.2285 61.2339 16.8743C61.8465 17.6214 62.1616 18.5697 62.1183 19.5361C62.1616 20.5026 61.8465 21.4509 61.2339 22.198C60.6325 22.8649 59.7685 23.2318 58.8726 23.2006C57.9975 23.2175 57.1618 22.8358 56.5998 22.1625Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M46.3323 14.0439H48.6405V14.9312C48.9745 14.5763 49.3626 14.2768 49.7902 14.0439C50.1681 13.8635 50.5833 13.7753 51.0018 13.7866C51.6883 13.8098 52.3566 14.0149 52.9385 14.3811L51.8596 16.5017C51.488 16.2075 51.0326 16.0397 50.5596 16.0226C49.2861 16.0226 48.6405 16.9808 48.6405 18.8796V25.0906H46.3323V14.0439Z",
    fill: "#454B56"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.3479 17.0266C88.3949 17.1327 88.4407 17.2361 88.4812 17.3447H88.4193C88.6839 18.0232 88.8189 18.7456 88.8173 19.4742V19.9533V20.3171H79.8675C79.9007 20.7763 80.0208 21.225 80.2212 21.6392C80.4048 22.0242 80.6599 22.3705 80.9729 22.6595C81.2725 22.9497 81.6275 23.1761 82.0165 23.325C82.4083 23.4782 82.8253 23.5564 83.2458 23.5557C83.5589 23.5568 83.8709 23.518 84.1744 23.4403C84.4683 23.3667 84.751 23.2532 85.0145 23.1032C85.265 22.9582 85.4942 22.779 85.6955 22.5708C85.8942 22.3687 86.0532 22.1309 86.1642 21.8698L88.1717 22.4377V22.5087C87.7694 23.3829 87.1045 24.1089 86.2703 24.5849C84.604 25.5235 82.6026 25.6508 80.8314 24.931C80.146 24.6381 79.5264 24.2095 79.0096 23.671C78.5814 23.2145 78.2318 22.6898 77.9749 22.1183C77.9146 22.0064 77.8615 21.8909 77.8157 21.7722C77.2619 20.364 77.2619 18.7973 77.8157 17.3891C77.8584 17.2749 77.9068 17.1721 77.9564 17.067C77.9684 17.0415 77.9805 17.0158 77.9926 16.9898C78.2511 16.4314 78.5941 15.9166 79.0096 15.4637C79.5234 14.9195 80.1436 14.4875 80.8314 14.1948C81.5809 13.8773 82.3881 13.7201 83.2016 13.7335C84.0099 13.7228 84.8114 13.883 85.554 14.2037C86.2185 14.5063 86.8189 14.934 87.3227 15.4637C87.7272 15.8948 88.0588 16.3893 88.3044 16.9277C88.3189 16.9611 88.3335 16.994 88.3479 17.0266ZM81.8484 15.8186C81.4668 15.9649 81.1158 16.1816 80.8137 16.4575L80.8756 16.4397C80.5712 16.7234 80.3248 17.064 80.1504 17.4424C79.9816 17.7916 79.8711 18.1663 79.8232 18.5515H86.5091C86.4584 18.1658 86.3449 17.7911 86.173 17.4424C85.9924 17.0654 85.7435 16.7254 85.439 16.4397C85.135 16.1619 84.7807 15.945 84.3954 15.8009C83.9911 15.6485 83.5627 15.5703 83.1308 15.5702C82.6914 15.5699 82.2561 15.6542 81.8484 15.8186Z",
    fill: "#454B56"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M118.789 15.4635C119.193 15.8947 119.525 16.3892 119.77 16.9275H119.744L119.929 17.3446C120.187 18.0248 120.319 18.7464 120.319 19.4741C120.319 19.5313 120.324 19.5885 120.329 19.6447C120.338 19.754 120.348 19.8594 120.319 19.9532C120.274 20.0952 120.274 20.2194 120.274 20.317H111.316C111.353 20.7754 111.473 21.2233 111.669 21.639C111.853 22.024 112.108 22.3704 112.421 22.6594C112.721 22.9496 113.076 23.1759 113.465 23.3249C113.857 23.4773 114.274 23.5555 114.694 23.5556C114.992 23.553 115.289 23.5142 115.578 23.4402C115.888 23.3705 116.185 23.2569 116.463 23.103C116.714 22.9559 116.946 22.777 117.153 22.5707C117.345 22.3635 117.503 22.1269 117.621 21.8697L119.655 22.4198V22.4908C119.253 23.365 118.588 24.091 117.754 24.5671C116.088 25.5056 114.086 25.633 112.315 24.9131C111.647 24.6172 111.045 24.1886 110.546 23.6532C110.113 23.1992 109.76 22.674 109.503 22.1004L109.344 21.7544C108.79 20.3461 108.79 18.7794 109.344 17.3712C109.386 17.257 109.435 17.1543 109.484 17.0492C109.496 17.0236 109.508 16.9979 109.52 16.9719C109.779 16.4115 110.125 15.8962 110.546 15.4458C111.042 14.9049 111.644 14.4729 112.315 14.177C113.055 13.8705 113.849 13.7195 114.65 13.7333C116.21 13.7053 117.71 14.3325 118.789 15.4635ZM111.616 17.4421C111.451 17.7926 111.34 18.1667 111.289 18.5512H118.002C117.951 18.1655 117.837 17.7908 117.666 17.4421C117.485 17.0651 117.236 16.7251 116.931 16.4395C116.628 16.1616 116.273 15.9447 115.888 15.8006C115.484 15.6469 115.055 15.5687 114.623 15.5699C114.197 15.5684 113.774 15.6467 113.376 15.8006C112.995 15.9469 112.644 16.1636 112.342 16.4395C112.037 16.7231 111.791 17.0637 111.616 17.4421Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M125.713 14.0173C125.29 14.253 124.906 14.5522 124.573 14.9046V14.0173H122.229V25.0196H124.573V18.8086C124.573 16.9098 125.209 15.9516 126.492 15.9516C126.965 15.9687 127.42 16.1365 127.792 16.4307L128.862 14.3101C128.28 13.9439 127.612 13.7388 126.925 13.7156C126.507 13.7075 126.092 13.7955 125.713 13.9729",
    fill: "#454B56"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M105.284 10.5481C105.844 10.8188 106.347 11.192 106.77 11.6483H106.726C107.139 12.1075 107.468 12.6361 107.699 13.2099C107.929 13.7733 108.049 14.3757 108.052 14.9845C108.052 15.6063 107.938 16.2228 107.716 16.8034C107.513 17.3683 107.214 17.8936 106.832 18.3562C106.429 18.8134 105.939 19.1846 105.391 19.4475C104.808 19.7261 104.17 19.8658 103.524 19.8557H99.4298V25.0463H97.0332V10.1311H103.401C104.052 10.1216 104.697 10.2644 105.284 10.5481ZM104.975 16.972C105.181 16.7056 105.34 16.4054 105.444 16.0847L105.47 16.1024C105.586 15.7445 105.643 15.3698 105.638 14.9933C105.644 14.6067 105.581 14.222 105.452 13.8576C105.33 13.5302 105.147 13.2291 104.913 12.9703C104.693 12.7339 104.429 12.5439 104.135 12.4113C103.858 12.2814 103.556 12.2119 103.25 12.2072H99.4298V17.7705H103.374C103.681 17.7687 103.984 17.6958 104.258 17.5576C104.535 17.4138 104.779 17.2145 104.975 16.972Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M33.1816 12.2066H38.2402V25.0456H40.6369V12.2066H45.669V10.1304H33.1816V12.2066Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M90.4622 22.7126C90.4161 23.3998 90.6638 24.0742 91.1432 24.5671C91.6006 24.9773 92.1923 25.2046 92.8058 25.2059V10.1221H90.4622V22.7126Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M140.244 14.0794H137.254L132.558 18.9683V18.8086V9.70508H130.206V25.0462H132.558V21.7012L134.416 19.9798L137.98 25.0462H140.5L135.928 18.6755L140.244 14.0794Z",
    fill: "#454B56"
  }), _jsx("path", {
    d: "M14.9548 28.5155C12.1545 29.3614 9.16793 29.3614 6.36761 28.5155C5.87919 28.3587 5.35655 28.6288 5.20024 29.1189C5.11799 29.3529 5.13717 29.6107 5.25312 29.8299C5.36906 30.0491 5.57119 30.2095 5.81045 30.2723C7.38698 30.755 9.02619 31.0002 10.6745 30.9999C12.3132 30.9975 13.9429 30.7554 15.512 30.2812C15.7513 30.2184 15.9534 30.058 16.0693 29.8388C16.1853 29.6196 16.2045 29.3617 16.1222 29.1277C16.049 28.8908 15.8846 28.6929 15.6655 28.578C15.4463 28.4631 15.1906 28.4406 14.9548 28.5155Z",
    fill: "#1396E4"
  }), _jsx("path", {
    d: "M15.9187 24.3719C12.5798 25.8747 8.76005 25.8747 5.42118 24.3719C4.95696 24.1559 4.40592 24.3534 4.18306 24.8155C4.07101 25.0375 4.05701 25.2964 4.14445 25.5293C4.23189 25.7621 4.41275 25.9474 4.64293 26.04C8.47662 27.7667 12.8632 27.7667 16.6969 26.04C16.9247 25.9471 17.1037 25.7634 17.191 25.5327C17.2782 25.3021 17.2658 25.0455 17.1568 24.8244C16.9372 24.3589 16.3852 24.1572 15.9187 24.3719Z",
    fill: "#1396E4"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.6744 2.64228C16.5047 2.60764 21.2711 7.29856 21.3487 13.1477C21.2711 18.9969 16.5047 23.6878 10.6744 23.6532C4.84399 23.6878 0.0776374 18.9969 0 13.1477C0.0776374 7.29856 4.84399 2.60764 10.6744 2.64228ZM4.45637 19.3265C6.11621 20.9535 8.35399 21.8496 10.6743 21.8165C12.9946 21.8496 15.2324 20.9535 16.8922 19.3265C18.5521 17.6995 19.4971 15.4758 19.518 13.1477C19.4947 10.8204 18.549 8.59806 16.8896 6.97156C15.2303 5.34506 12.9939 4.44816 10.6743 4.47893C8.35468 4.44816 6.11827 5.34506 4.45895 6.97156C2.79962 8.59806 1.85388 10.8204 1.83058 13.1477C1.85153 15.4758 2.79652 17.6995 4.45637 19.3265Z",
    fill: "#1396E4"
  }), _jsx("path", {
    d: "M13.92 17.8236L13.4513 13.0855L13.3982 12.5709C13.4173 11.9447 13.708 11.3581 14.1941 10.9649C14.5833 10.5834 15.9629 8.98627 15.4499 8.44502C14.937 7.90378 13.2832 9.33231 12.8941 9.68722C12.4829 10.1624 11.8937 10.4451 11.2669 10.468L5.96064 9.9534C5.76983 9.93835 5.58122 10.0025 5.43886 10.1309L5.0409 10.5301C4.8198 10.7431 5.1028 10.8673 5.1028 10.8673L9.42738 12.7217C9.42738 12.7217 9.70153 12.8371 9.42738 13.1565C9.03825 13.6179 8.49879 14.2922 7.65863 15.3037C7.50534 15.4404 7.31955 15.5351 7.11917 15.5788L5.67764 15.4013H5.55383C5.35927 15.4362 5.17845 15.5254 5.03205 15.6586L4.8994 15.7917C4.8994 15.7917 4.73137 15.9603 4.99668 16.1023C5.06743 16.1023 5.9518 16.608 6.41167 16.8653C6.59647 16.9971 6.7579 17.1591 6.88923 17.3445C7.11032 17.7171 7.46407 18.3649 7.66748 18.7286C7.87088 19.0924 7.97701 18.8262 7.97701 18.8262L8.10966 18.6932C8.24709 18.5519 8.33945 18.3727 8.37497 18.1785C8.38 18.1551 8.38 18.1309 8.37497 18.1075C8.37497 18.0543 8.26001 17.167 8.1981 16.679C8.24165 16.4858 8.33637 16.3081 8.47226 16.1644C9.49813 15.357 10.1791 14.8335 10.6567 14.4342C10.9839 14.1325 11.0988 14.4342 11.0988 14.4342L12.9914 18.6843C13.1064 18.9593 13.3275 18.7464 13.3275 18.7464L13.7254 18.356C13.8662 18.2165 13.9375 18.0213 13.92 17.8236Z",
    fill: "#1396E4"
  })]
}), 'TravelPerkLogo', 'Company');
export default TravelPerkLogo;