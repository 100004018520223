/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MeredithLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 139,
  height: 32,
  viewBox: "0 0 139 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M134.126 12.428C134.355 12.574 134.564 12.7409 134.752 12.9496C135.253 13.5128 135.503 14.2638 135.503 15.2232V22.3574H138.381V14.3265C138.381 13.6378 138.298 12.9913 138.089 12.428C137.881 11.8231 137.568 11.2807 137.13 10.8426C136.254 9.92481 135.065 9.46581 133.563 9.46581C132.061 9.46581 130.851 10.0499 129.954 11.1973V4.9392H127.054V22.3574H129.954V15.2232C129.954 14.2429 130.205 13.471 130.705 12.9287C130.893 12.72 131.101 12.5532 131.331 12.428C131.706 12.2195 132.186 12.0942 132.728 12.0942C133.25 12.0942 133.729 12.2195 134.126 12.428Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M124.509 12.2195V9.73702H121.651V4.93923H118.752V17.9142C118.752 19.6249 119.252 20.8558 120.254 21.6065C121.151 22.2739 122.569 22.6287 124.509 22.6287V20.1256C123.592 20.1256 122.924 20.0003 122.527 19.7497C121.943 19.3746 121.651 18.6654 121.651 17.6223V12.2195H124.509Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M112.41 7.77612H115.268V4.9391H112.41V7.77612Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M106.111 16.0159V16.0784C106.111 17.2467 105.735 18.2271 105.005 18.999C104.296 19.7291 103.44 20.1045 102.439 20.1045C101.417 20.1045 100.583 19.7497 99.8939 19.0199C99.1849 18.2482 98.8096 17.2677 98.8096 16.0784V16.0159C98.8096 14.8061 99.1642 13.8257 99.8939 13.0748C100.124 12.8035 100.395 12.5949 100.687 12.428C101.187 12.136 101.793 11.9899 102.439 11.9899C103.086 11.9899 103.67 12.136 104.171 12.428C104.463 12.6157 104.734 12.8245 105.005 13.0956C105.735 13.8674 106.111 14.848 106.111 16.0159ZM106.048 4.93923V11.1763C105.068 10.0499 103.649 9.46584 101.751 9.46584C100.166 9.46584 98.8096 10.0499 97.7039 11.1763C97.3283 11.5728 97.0153 11.9899 96.7652 12.428C96.1811 13.4502 95.8889 14.6393 95.8889 16.0159V16.0784C95.8889 18.0602 96.4939 19.6873 97.7039 20.9182C98.8096 22.0446 100.166 22.6287 101.751 22.6287C103.482 22.6287 104.901 22.0446 106.048 20.9182V22.3575H108.948V4.93923H106.048Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M112.41 22.3574H115.289V9.737H112.41V22.3574Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M90.0481 12.428L90.4446 12.8034C90.9871 13.4085 91.2999 14.2011 91.4042 15.1608H84.9165C85.0626 14.1802 85.4173 13.3667 86.0013 12.7617L86.3769 12.428C86.8773 12.0316 87.5035 11.8439 88.1916 11.8439C88.9215 11.8439 89.5476 12.0316 90.0481 12.428ZM93.3861 12.428C93.1773 12.1151 92.9688 11.8023 92.7391 11.5101C91.6545 10.1541 90.1315 9.46576 88.2122 9.46576C86.4393 9.46576 84.9584 10.1125 83.7902 11.4058C83.498 11.7396 83.2268 12.0733 83.0182 12.428C82.3717 13.4919 82.0586 14.6809 82.0586 16.0576V16.0994C82.0586 18.0185 82.6845 19.6039 83.9154 20.8345C85.1462 22.0445 86.6688 22.6494 88.5253 22.6494C90.6531 22.6494 92.3844 21.8567 93.6989 20.2713L91.9883 18.7696C90.9659 19.7708 89.8396 20.2713 88.588 20.2713C87.6284 20.2713 86.8358 20.0002 86.1892 19.437C85.5006 18.8738 85.1044 18.081 84.9374 17.0798H94.2203L94.2621 16.2661C94.2621 14.7852 93.9702 13.5128 93.3861 12.428Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M80.4528 12.4698H81.0787V9.50759C78.9716 9.50759 77.4906 10.2585 76.5937 11.7813V9.737H73.7146V22.3574H76.5937V16.433C76.5937 15.2232 76.9274 14.2638 77.5949 13.5753C78.2623 12.8452 79.222 12.4698 80.4528 12.4698Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M65.2036 11.8439C65.9337 11.8439 66.5596 12.0316 67.0812 12.428L67.4565 12.8034C67.999 13.3876 68.3119 14.1802 68.4161 15.1608H61.9494C62.0746 14.1802 62.4295 13.3667 63.0133 12.7617L63.3886 12.428C63.9102 12.0316 64.5154 11.8439 65.2036 11.8439ZM70.3981 12.428C70.2102 12.1151 70.0014 11.8023 69.7722 11.5101C68.6665 10.1541 67.1646 9.46576 65.2454 9.46576C63.4513 9.46576 61.9915 10.1125 60.8022 11.4058C60.5102 11.7396 60.2596 12.0733 60.0304 12.428C59.4045 13.4919 59.0706 14.6809 59.0706 16.0576V16.0994C59.0706 18.0185 59.6967 19.6039 60.9482 20.8345C62.1582 22.0445 63.7017 22.6494 65.5584 22.6494C67.6862 22.6494 69.3967 21.8567 70.7109 20.2713L69.0211 18.7696C67.999 19.7708 66.8515 20.2713 65.6 20.2713C64.6404 20.2713 63.8477 20.0002 63.201 19.437C62.5335 18.8738 62.1164 18.081 61.9703 17.0798H71.2325L71.2947 16.2661C71.2947 14.7852 71.0028 13.5128 70.3981 12.428Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M56.7971 14.3053C56.7971 13.6171 56.6928 12.9913 56.4843 12.428C56.2757 11.8439 55.9211 11.3015 55.4831 10.8217C54.5859 9.92481 53.4596 9.46581 52.0826 9.46581C50.3304 9.46581 48.9955 10.2169 48.0983 11.6979C47.3059 10.2169 46.0123 9.46581 44.2185 9.46581C43.2589 9.46581 42.3617 9.73699 41.5482 10.3001C41.1729 10.5506 40.8183 10.8635 40.5052 11.239V9.73699H37.6057V22.3574H40.5052V15.2232C40.5052 14.2429 40.7347 13.471 41.2145 12.9287C41.3815 12.72 41.5691 12.5532 41.7986 12.428C42.1741 12.2195 42.6121 12.0942 43.1337 12.0942C43.6342 12.0942 44.0933 12.2195 44.4689 12.428C44.6774 12.5532 44.8651 12.72 45.0321 12.9287C45.5119 13.471 45.7622 14.2429 45.7622 15.2232V22.3574H48.6408V15.2232C48.6408 14.2429 48.8912 13.471 49.3707 12.9078C49.538 12.72 49.7256 12.5532 49.9551 12.428C50.3304 12.2195 50.7686 12.0942 51.2691 12.0942C51.7907 12.0942 52.2498 12.2195 52.6251 12.428C52.8337 12.574 53.0216 12.7409 53.2092 12.9287C53.6681 13.4919 53.9185 14.2429 53.9185 15.2232V22.3574H56.7971V14.3053Z",
    fill: "#7D756A"
  }), _jsx("path", {
    d: "M26.8062 8.28203C27.4109 8.32382 27.9116 8.55332 28.3496 8.97044C28.8297 9.4503 29.0589 10.0135 29.0382 10.681C28.9964 11.2859 28.7879 11.8076 28.3496 12.2454L26.6601 13.9144L28.746 16.0005L30.4356 14.3315C31.4788 13.2884 32.0002 12.0369 31.9795 10.5559C31.9795 9.13723 31.4579 7.90652 30.4356 6.88446C29.3719 5.84147 28.1202 5.34076 26.6183 5.34076C26.6807 3.90133 26.1803 2.62889 25.0955 1.5441C24.0733 0.542917 22.8635 0.0215454 21.4242 0.000641823C19.943 -0.0202618 18.6914 0.501354 17.6484 1.5441L15.9797 3.21298L18.0655 5.2992L19.7345 3.63032C20.1933 3.17142 20.7357 2.94196 21.3824 2.94196C22.0083 2.94196 22.5507 3.17142 23.0095 3.63032C23.4685 4.08923 23.698 4.6524 23.677 5.2992C23.677 5.90394 23.4475 6.44622 23.0095 6.88446L21.3406 8.55332L23.4267 10.6394L25.0955 8.97044C25.5753 8.4906 26.1594 8.26128 26.8062 8.28203Z",
    fill: "#2982BE"
  }), _jsx("path", {
    d: "M21.3406 8.55331L18.0655 5.29919L15.9797 7.38492L19.2546 10.6394L21.3406 8.55331Z",
    fill: "#E13348"
  }), _jsx("path", {
    d: "M21.3406 19.2545L18.0655 16.0006L15.9797 18.0866L19.2546 21.3406L21.3406 19.2545Z",
    fill: "#88AD6E"
  }), _jsx("path", {
    d: "M24.5952 16.0006L21.3408 19.2545L23.4269 21.3406L26.6603 18.0866L24.5952 16.0006Z",
    fill: "#2982BE"
  }), _jsx("path", {
    d: "M21.3408 12.7252L24.5952 16.0005L26.6603 13.9144L23.4269 10.6394L21.3408 12.7252Z",
    fill: "#88AD6E"
  }), _jsx("path", {
    d: "M18.0655 16.0005L21.3406 12.7252L19.2546 10.6394L15.9797 13.9144L18.0655 16.0005Z",
    fill: "#2982BE"
  }), _jsx("path", {
    d: "M21.3406 12.7251L23.4267 10.6393L21.3406 8.55322L19.2546 10.6393L21.3406 12.7251Z",
    fill: "#5C5557"
  }), _jsx("path", {
    d: "M26.6601 18.0866L28.3496 19.7553C28.7879 20.1724 28.9964 20.6938 29.0382 21.3199C29.0589 21.9662 28.8297 22.5293 28.3496 23.0095C27.9116 23.4266 27.4109 23.656 26.8062 23.698C26.1594 23.7189 25.5753 23.51 25.0955 23.0095L23.4267 21.3405L21.3406 23.4266L23.0095 25.0953C23.4475 25.5334 23.677 26.0756 23.677 26.7015C23.698 27.3271 23.4685 27.8905 23.0095 28.3701C22.5507 28.8088 22.0083 29.038 21.3824 29.038C20.7357 29.038 20.1933 28.8088 19.7345 28.3701L18.0655 26.6806L15.9797 28.7875L17.6484 30.4357C18.6914 31.4994 19.943 32 21.4242 31.9791C22.8635 31.9585 24.0733 31.4578 25.0955 30.4357C26.1803 29.3718 26.6807 28.0994 26.6183 26.6391C28.1202 26.6599 29.3719 26.1383 30.4356 25.0953C31.4579 24.0731 31.9795 22.8423 31.9795 21.4241C32.0002 19.9639 31.4788 18.7124 30.4356 17.6692L28.746 16.0005L26.6601 18.0866Z",
    fill: "#88AD6E"
  }), _jsx("path", {
    d: "M26.6603 18.0864L28.7463 16.0004L26.6603 13.9143L24.5952 16.0004L26.6603 18.0864Z",
    fill: "#497167"
  }), _jsx("path", {
    d: "M13.9145 5.29919L10.6394 8.55331L12.7255 10.6394L15.9798 7.38492L13.9145 5.29919Z",
    fill: "#2982BE"
  }), _jsx("path", {
    d: "M12.2459 3.63032L13.9146 5.2992L15.9799 3.21298L14.3318 1.5441C13.268 0.501354 12.0163 -0.0202618 10.5561 0.000641823C9.13762 0.000641823 7.90691 0.542917 6.88478 1.5441C5.84176 2.58733 5.32032 3.85977 5.34108 5.34076C3.88097 5.2992 2.60848 5.79967 1.5235 6.88446C0.543139 7.88577 0.0217878 9.11647 0.000845352 10.5559C-0.020001 12.0161 0.501543 13.2884 1.5235 14.3315L3.21329 16.0005L5.29929 13.9144L3.63061 12.2454C3.17161 11.7865 2.9422 11.2233 2.9422 10.5976C2.9422 9.95081 3.17161 9.42927 3.63061 8.97044C4.08951 8.51153 4.65277 8.28203 5.29929 8.30289C5.90421 8.30289 6.44651 8.53239 6.88478 8.97044L8.55363 10.6394L10.6395 8.55332L8.97085 6.88446C8.49099 6.40465 8.26149 5.82057 8.28244 5.17402C8.32403 4.54813 8.55363 4.04742 8.97085 3.63032C9.45061 3.15051 10.0137 2.94196 10.6813 2.94196C11.2862 2.96286 11.8078 3.19207 12.2459 3.63032Z",
    fill: "#E13348"
  }), _jsx("path", {
    d: "M13.9146 5.29928L15.9799 7.38501L18.0657 5.29928L15.9799 3.21306L13.9146 5.29928Z",
    fill: "#6E5D63"
  }), _jsx("path", {
    d: "M3.21305 16.0005L1.52325 17.6691C0.501298 18.7124 -0.0202452 19.9639 0.000601211 21.4241C0.0215437 22.8636 0.542895 24.0938 1.52325 25.0952C2.60823 26.1798 3.88072 26.7015 5.34083 26.639C5.32008 28.1202 5.84152 29.3927 6.88453 30.4357C7.90667 31.4577 9.13737 31.9791 10.5559 32C12.0161 32 13.2677 31.4993 14.3316 30.4357L15.9797 28.7874L13.9144 26.6805L12.2457 28.37C11.8075 28.7874 11.286 29.0171 10.681 29.038C10.0135 29.0589 9.45037 28.8294 8.97061 28.37C8.55339 27.9324 8.32379 27.4316 8.2822 26.8268C8.26125 26.1592 8.49075 25.5962 8.97061 25.0952L10.6393 23.4266L8.55339 21.3405L6.88453 23.0094C6.44626 23.4472 5.90396 23.6766 5.29905 23.698C4.65252 23.698 4.08927 23.4893 3.63037 23.0094C3.17136 22.5714 2.94196 22.0288 2.94196 21.3821C2.94196 20.7564 3.17136 20.2139 3.63037 19.7552L5.29905 18.0865L3.21305 16.0005Z",
    fill: "#F2962A"
  }), _jsx("path", {
    d: "M5.29914 18.0864L7.38521 16.0004L5.29914 13.9143L3.21313 16.0004L5.29914 18.0864Z",
    fill: "#BE5547"
  }), _jsx("path", {
    d: "M7.38515 16.0006L5.29907 18.0866L8.55341 21.3406L10.389 19.5259L10.6393 19.2545L7.38515 16.0006Z",
    fill: "#E13348"
  }), _jsx("path", {
    d: "M5.29907 13.9144L7.38515 16.0005L10.6393 12.7252L8.55341 10.6394L5.29907 13.9144Z",
    fill: "#F2962A"
  }), _jsx("path", {
    d: "M15.9798 13.9144L12.7255 10.6394L10.6394 12.7252L13.9145 16.0005L15.9798 13.9144Z",
    fill: "#E13348"
  }), _jsx("path", {
    d: "M8.55347 10.6393L10.6393 12.7251L12.7254 10.6393L10.6393 8.55322L8.55347 10.6393Z",
    fill: "#6F5D59"
  }), _jsx("path", {
    d: "M10.6393 19.2543L10.389 19.5257L8.55347 21.3404L10.6393 23.4265L12.7254 21.3404L10.6393 19.2543Z",
    fill: "#8E6258"
  }), _jsx("path", {
    d: "M13.9146 16.0004L15.9799 18.0864L18.0657 16.0004L15.9799 13.9143L13.9146 16.0004Z",
    fill: "#5C5557"
  }), _jsx("path", {
    d: "M15.9798 18.0866L13.9145 16.0006L10.6394 19.2545L12.7255 21.3406L15.9798 18.0866Z",
    fill: "#F2962A"
  }), _jsx("path", {
    d: "M15.9798 24.5944L12.7255 21.3404L10.6394 23.4265L13.9145 26.6804L15.9798 24.5944Z",
    fill: "#88AD6E"
  }), _jsx("path", {
    d: "M13.9146 26.6804L15.9799 28.7874L18.0657 26.6804L15.9799 24.5944L13.9146 26.6804Z",
    fill: "#8B876A"
  }), _jsx("path", {
    d: "M21.3406 23.4265L23.4267 21.3404L21.3406 19.2543L19.2546 21.3404L21.3406 23.4265Z",
    fill: "#5A6B68"
  }), _jsx("path", {
    d: "M18.0655 26.6804L21.3406 23.4265L19.2546 21.3404L15.9797 24.5944L18.0655 26.6804Z",
    fill: "#F2962A"
  })]
}), 'MeredithLogo', 'Company');
export default MeredithLogo;