/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PaypalLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 98,
  height: 32,
  viewBox: "0 0 98 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.7085 3.00122H4.11941C3.6 3.00122 3.15838 3.37861 3.07735 3.89145L0.00796933 23.3519C-0.0530782 23.7357 0.244382 24.082 0.63382 24.082H4.25696C4.77629 24.082 5.2179 23.7048 5.29893 23.191L6.12675 17.9423C6.20668 17.4283 6.64939 17.051 7.16762 17.051H9.57011C14.5692 17.051 17.4544 14.6319 18.208 9.83809C18.5474 7.74076 18.2222 6.09294 17.2402 4.93888C16.1616 3.6715 14.2485 3.00122 11.7085 3.00122ZM12.584 10.1089C12.169 12.8321 10.0883 12.8321 8.07646 12.8321H6.9313L7.73468 7.7464C7.78244 7.43898 8.04871 7.21261 8.35947 7.21261H8.88429C10.2547 7.21261 11.5476 7.21261 12.2156 7.99375C12.614 8.45988 12.7361 9.15229 12.584 10.1089Z",
    fill: "#283B82"
  }), _jsx("path", {
    d: "M34.3938 10.021H30.7599C30.4502 10.021 30.1828 10.2473 30.135 10.5548L29.9743 11.5712L29.72 11.2027C28.9333 10.0608 27.1788 9.6792 25.4278 9.6792C21.4118 9.6792 17.9818 12.7208 17.3138 16.9875C16.9665 19.1159 17.4603 21.151 18.6676 22.5702C19.775 23.8752 21.3596 24.419 23.2452 24.419C26.481 24.419 28.2752 22.3384 28.2752 22.3384L28.1132 23.3483C28.052 23.7345 28.3496 24.0806 28.7369 24.0806H32.0103C32.5308 24.0806 32.9703 23.7033 33.0524 23.1896L35.0164 10.7511C35.0787 10.3683 34.7824 10.021 34.3938 10.021ZM29.3282 17.0941C28.9778 19.1702 27.3298 20.5641 25.2281 20.5641C24.1729 20.5641 23.3293 20.2256 22.7878 19.5842C22.2508 18.9472 22.0466 18.0406 22.2174 17.0308C22.5448 14.9723 24.2206 13.5332 26.29 13.5332C27.322 13.5332 28.1609 13.876 28.7136 14.5229C29.2674 15.1765 29.4869 16.0886 29.3282 17.0941Z",
    fill: "#283B82"
  }), _jsx("path", {
    d: "M53.7484 10.021H50.0966C49.7481 10.021 49.4208 10.1941 49.2232 10.4838L44.1863 17.9031L42.0514 10.7734C41.9171 10.3272 41.5056 10.021 41.0394 10.021H37.4507C37.0145 10.021 36.7117 10.4471 36.8504 10.8577L40.8729 22.6624L37.0912 28.0012C36.7937 28.4217 37.0932 29 37.6073 29H41.2548C41.6009 29 41.925 28.8312 42.1213 28.5472L54.2679 11.0142C54.5586 10.5947 54.2601 10.021 53.7484 10.021Z",
    fill: "#283B82"
  }), _jsx("path", {
    d: "M65.8398 3.00098H58.2496C57.7314 3.00098 57.2897 3.37837 57.2086 3.89121L54.1393 23.3516C54.0783 23.7355 54.3757 24.0818 54.763 24.0818H58.658C59.0197 24.0818 59.3295 23.8178 59.3858 23.4581L60.2568 17.942C60.3368 17.4281 60.7798 17.0508 61.2978 17.0508H63.6992C68.6995 17.0508 71.5835 14.6317 72.3381 9.83784C72.6788 7.74052 72.3515 6.0927 71.3694 4.93863C70.2918 3.67125 68.3799 3.00098 65.8398 3.00098ZM66.7155 10.1086C66.3014 12.8318 64.2209 12.8318 62.2078 12.8318H61.0638L61.8683 7.74615C61.916 7.43873 62.18 7.21236 62.4918 7.21236H63.0167C64.3862 7.21236 65.68 7.21237 66.348 7.9935C66.7463 8.45963 66.8673 9.15204 66.7155 10.1086Z",
    fill: "#469BDB"
  }), _jsx("path", {
    d: "M88.5241 10.021H84.8921C84.5803 10.021 84.3149 10.2473 84.2683 10.5548L84.1074 11.5712L83.8523 11.2027C83.0656 10.0608 81.3122 9.6792 79.5611 9.6792C75.5451 9.6792 72.1163 12.7208 71.4482 16.9875C71.1021 19.1159 71.5936 21.151 72.801 22.5702C73.9107 23.8752 75.4929 24.419 77.3783 24.419C80.6141 24.419 82.4085 22.3384 82.4085 22.3384L82.2464 23.3483C82.1855 23.7345 82.4828 24.0806 82.8724 24.0806H86.1449C86.6631 24.0806 87.1049 23.7033 87.1858 23.1896L89.151 10.7511C89.2108 10.3683 88.9136 10.021 88.5241 10.021ZM83.4583 17.0941C83.1098 19.1702 81.4597 20.5641 79.3582 20.5641C78.305 20.5641 77.4594 20.2256 76.9177 19.5842C76.3809 18.9472 76.1787 18.0406 76.3475 17.0308C76.677 14.9723 78.3505 13.5332 80.42 13.5332C81.4521 13.5332 82.2908 13.876 82.8435 14.5229C83.3995 15.1765 83.6192 16.0886 83.4583 17.0941Z",
    fill: "#469BDB"
  }), _jsx("path", {
    d: "M92.8085 3.53483L89.6935 23.3516C89.6326 23.7354 89.9298 24.0817 90.3171 24.0817H93.4486C93.9691 24.0817 94.4108 23.7045 94.4908 23.1907L97.5623 3.7314C97.6233 3.34729 97.3261 3 96.9386 3H93.432C93.1225 3.00093 92.8562 3.22759 92.8085 3.53483Z",
    fill: "#469BDB"
  })]
}), 'PaypalLogo', 'Company');
export default PaypalLogo;