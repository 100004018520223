/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LensOnlineLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 194,
  height: 32,
  viewBox: "0 0 194 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M83.872 12.6843C83.5827 11.9054 83.1377 11.1933 82.5368 10.5702C81.8915 9.90264 81.0459 9.41307 80.089 9.05702C79.0653 8.70097 77.9082 8.5452 76.5952 8.5452C75.1265 8.5452 73.8136 8.65646 72.6342 8.90125C71.477 9.14603 70.4089 9.47983 69.452 9.92489L69.274 10.0139L68.8066 10.2364V29.3519H74.0584V12.9736C74.2809 12.9068 74.5034 12.84 74.7037 12.7955C75.082 12.7065 75.6384 12.662 76.3505 12.662C76.9735 12.662 77.4631 12.751 77.7746 12.8846C78.153 13.0403 78.3532 13.1961 78.509 13.3741C78.687 13.5967 78.8205 13.8192 78.8873 14.0862C78.9763 14.3978 79.0208 14.7316 79.0208 15.1099V29.3296H84.2726V15.0431C84.2726 14.2197 84.139 13.4186 83.872 12.6843Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M98.1366 19.4047C97.736 18.8039 97.1797 18.2921 96.5344 17.847C95.889 17.402 95.1547 17.0237 94.3091 16.6899L94.2868 16.6676L94.2423 16.6453C93.3077 16.2893 92.6623 15.911 92.2618 15.5549C92.0838 15.3992 91.9947 15.2657 91.928 15.1099C91.8612 14.9541 91.8167 14.7538 91.8167 14.4868C91.8167 14.1975 91.8612 13.975 91.9502 13.7747C92.0392 13.5744 92.1505 13.3964 92.3285 13.2184C92.4843 13.0626 92.6401 12.9513 92.8849 12.8623C93.1074 12.7733 93.4189 12.7288 93.7972 12.7288C94.3091 12.7288 94.7541 12.8178 95.1324 12.9513C95.5775 13.1294 95.9335 13.3296 96.2006 13.5077L97.3132 14.3533L99.0045 10.4367L98.2479 9.9249C97.6025 9.50209 96.8904 9.1683 96.0893 8.90126C95.2437 8.63422 94.3313 8.5007 93.3744 8.5007H93.3522C92.4398 8.5007 91.5719 8.63422 90.7708 8.94576C89.9697 9.23506 89.2576 9.68012 88.6568 10.281C88.0559 10.8595 87.5886 11.5271 87.2771 12.2615C86.9655 12.9958 86.8098 13.7969 86.8098 14.6203V14.6426C86.8098 15.2211 86.8765 15.7775 87.01 16.3338C87.1436 16.9346 87.4106 17.5132 87.7666 18.0473C88.1449 18.6481 88.679 19.16 89.3244 19.6273C89.992 20.1168 90.7931 20.5396 91.6832 20.8734C92.6623 21.2517 93.3299 21.6523 93.6637 21.9861C93.7972 22.1196 93.9085 22.2754 93.9753 22.4757C94.042 22.6759 94.0865 22.9207 94.0865 23.2323C94.0865 23.5661 94.0198 23.8109 93.9308 24.0556C93.8417 24.2782 93.686 24.4784 93.4634 24.701C93.2409 24.9013 93.0184 25.0348 92.7513 25.146C92.4843 25.2351 92.1728 25.3018 91.7722 25.3018C91.1046 25.3018 90.5483 25.2128 90.0587 25.0348C89.5246 24.8568 89.1463 24.6342 88.9238 24.4562L87.7889 23.5216L86.0977 27.6161L86.7653 28.128C87.4106 28.6175 88.2117 28.9736 89.1241 29.2406C90.0587 29.4854 91.0601 29.6189 92.106 29.6189C93.1296 29.6189 94.1088 29.4409 94.9989 29.1071C95.889 28.7733 96.6679 28.306 97.3132 27.7052C97.9363 27.1266 98.4481 26.4145 98.8042 25.6579C99.1602 24.879 99.3383 24.0556 99.3383 23.21V23.1878V23.1433C99.2938 21.7858 98.8932 20.4951 98.1366 19.4047Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M42.1033 3.8053H36.8516V29.3296H49.1798V25.1015H42.1033V3.8053Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M66.4259 18.6259C66.4259 17.0236 66.2701 15.5994 65.9363 14.3533C65.6025 13.1071 65.0907 12.0167 64.3563 11.1266C63.6442 10.2587 62.7764 9.5911 61.775 9.14604C60.7513 8.70098 59.6387 8.5007 58.3925 8.5007H58.3702C57.1241 8.5007 55.9669 8.72323 54.921 9.2128C53.8751 9.68012 52.9627 10.4145 52.1839 11.3268C51.405 12.2615 50.8264 13.3964 50.4704 14.6871C50.0921 15.9778 49.9363 17.402 49.9141 19.0042V19.0264C49.9141 20.6732 50.1143 22.1641 50.5149 23.4771C50.9155 24.79 51.5385 25.9249 52.4064 26.8595C53.252 27.7719 54.2534 28.484 55.3883 28.9513C56.5232 29.4186 57.7694 29.6412 59.1491 29.6412H59.1714C60.4175 29.6412 61.5747 29.5077 62.6206 29.2406C63.6442 28.9958 64.5121 28.662 65.2465 28.217L65.469 28.0835L66.0476 27.7274L64.4454 23.6996L63.3327 24.3449C62.9099 24.5897 62.3536 24.8345 61.6637 24.9903C61.0184 25.146 60.2618 25.2351 59.3716 25.2351C58.615 25.2351 57.992 25.1015 57.4579 24.8568C56.9238 24.6342 56.4787 24.2782 56.0782 23.8109C55.7221 23.388 55.4328 22.7427 55.1881 21.9193C55.0323 21.2963 54.8988 20.5396 54.8543 19.6718H66.4036L66.4259 18.6259ZM55.099 15.7775C55.1881 15.3992 55.2771 15.0654 55.4106 14.7538C55.6109 14.2198 55.8556 13.8192 56.1227 13.5077C56.4342 13.1516 56.7458 12.9291 57.0796 12.7733C57.4134 12.6175 57.8139 12.5285 58.259 12.5285C58.7931 12.5285 59.2159 12.6175 59.5719 12.7733C59.928 12.9291 60.2395 13.1516 60.5066 13.4854C60.7513 13.7747 60.9739 14.1752 61.1296 14.6871C61.2187 14.9986 61.3077 15.3769 61.3522 15.7775H55.099Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M117.251 7.56605C116.539 6.36438 115.605 5.4075 114.447 4.71765C113.268 4.00555 111.888 3.67175 110.353 3.67175C108.84 3.67175 107.46 4.00555 106.281 4.71765C105.123 5.4075 104.189 6.38663 103.477 7.56605C102.787 8.74547 102.275 10.1029 101.963 11.6384C101.652 13.1516 101.496 14.7983 101.496 16.5563C101.496 18.3143 101.652 19.961 101.963 21.4965C102.275 23.032 102.787 24.4117 103.499 25.5911C104.211 26.7928 105.146 27.7496 106.303 28.4395C107.46 29.1293 108.84 29.4631 110.353 29.4631C111.888 29.4631 113.268 29.1293 114.447 28.4395C115.582 27.7719 116.539 26.7928 117.251 25.5911C117.963 24.4117 118.475 23.032 118.787 21.4965C119.098 19.961 119.254 18.3143 119.254 16.5563C119.254 14.7983 119.098 13.1516 118.787 11.6384C118.453 10.1029 117.941 8.74547 117.251 7.56605ZM115.493 20.3616C115.315 21.541 115.026 22.5869 114.603 23.5215C114.203 24.4117 113.669 25.1015 112.956 25.6356C112.623 25.8804 112.244 26.0584 111.822 26.1919C111.399 26.3254 110.909 26.3922 110.353 26.3922C109.797 26.3922 109.329 26.3254 108.884 26.1919C108.461 26.0584 108.083 25.8804 107.749 25.6356C107.059 25.1015 106.503 24.4339 106.125 23.5215C105.724 22.5869 105.413 21.5188 105.235 20.3616C105.057 19.1822 104.968 17.8915 104.968 16.5341C104.968 15.1989 105.057 13.9304 105.257 12.7288C105.435 11.5494 105.746 10.4812 106.192 9.52433C106.614 8.61195 107.171 7.89985 107.86 7.38803C108.194 7.14324 108.55 6.96522 108.951 6.8317C109.351 6.69818 109.819 6.63142 110.353 6.63142C110.865 6.63142 111.332 6.69818 111.755 6.8317C112.178 6.96522 112.534 7.14324 112.867 7.38803C113.58 7.9221 114.114 8.61195 114.536 9.52433C114.959 10.459 115.271 11.5271 115.471 12.7065C115.649 13.9082 115.76 15.1989 115.76 16.5563C115.738 17.9138 115.671 19.1822 115.493 20.3616Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M136.146 12.751C135.923 12.0389 135.545 11.3936 135.011 10.7928C134.454 10.1697 133.742 9.70235 132.897 9.3463C132.007 8.968 130.938 8.81223 129.737 8.81223C128.357 8.81223 127.155 8.92349 126.087 9.14602C125.019 9.36855 124.062 9.7246 123.194 10.1919L122.771 10.4145V29.0181H126.265V12.1502C126.644 11.9944 127.022 11.9054 127.378 11.8164C127.867 11.7274 128.58 11.6606 129.47 11.6606C130.249 11.6606 130.849 11.7719 131.294 11.9499C131.784 12.1502 132.096 12.395 132.318 12.6398C132.563 12.9291 132.719 13.2629 132.808 13.6189C132.919 14.0195 132.963 14.4645 132.963 14.9096V28.9958H136.457V14.8428C136.457 14.1307 136.346 13.4186 136.146 12.751Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M144.357 0.823364H140.863V29.0181H144.357V0.823364Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M152.123 3.40473C151.922 3.2267 151.7 3.07093 151.455 2.95967C151.21 2.8484 150.943 2.80389 150.676 2.80389C150.409 2.80389 150.142 2.8484 149.897 2.95967C149.653 3.04868 149.43 3.20445 149.23 3.40473C149.029 3.58275 148.874 3.80528 148.785 4.07232C148.673 4.31711 148.629 4.58414 148.629 4.85118C148.629 5.11822 148.673 5.38526 148.785 5.63004C148.896 5.87483 149.052 6.09736 149.23 6.27538C149.43 6.45341 149.653 6.60918 149.897 6.72044C150.142 6.83171 150.409 6.87622 150.676 6.87622C150.943 6.87622 151.21 6.83171 151.455 6.72044C151.7 6.63143 151.922 6.47566 152.123 6.27538C152.301 6.09736 152.456 5.87483 152.568 5.63004C152.679 5.38526 152.723 5.11822 152.723 4.85118C152.723 4.58414 152.679 4.31711 152.568 4.07232C152.456 3.80528 152.301 3.58275 152.123 3.40473Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M152.412 9.16827H148.918V29.0403H152.412V9.16827Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M170.081 12.7511C169.859 12.039 169.48 11.3936 168.946 10.815C168.39 10.1919 167.678 9.72463 166.832 9.36858C165.942 8.99027 164.874 8.8345 163.672 8.8345C162.293 8.8345 161.069 8.94577 160.023 9.1683C158.955 9.39083 157.998 9.74688 157.13 10.2142L156.707 10.4367V29.0403H160.223V12.1502C160.601 11.9944 160.98 11.9054 161.336 11.8164C161.825 11.7274 162.537 11.6607 163.427 11.6607C164.206 11.6607 164.807 11.7719 165.252 11.9499C165.742 12.1502 166.053 12.395 166.276 12.6398C166.521 12.9291 166.676 13.2629 166.765 13.6189C166.877 14.0195 166.921 14.4423 166.921 14.9096V28.9958H170.415V14.8428C170.415 14.1307 170.304 13.4186 170.081 12.7511Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M186.927 11.1933C186.282 10.4145 185.481 9.81364 184.568 9.41308C183.656 9.01253 182.632 8.8345 181.497 8.8345C180.385 8.8345 179.316 9.05703 178.382 9.52435C177.425 9.96941 176.602 10.6593 175.889 11.5494C175.155 12.4395 174.643 13.5299 174.287 14.7983C173.931 16.0445 173.775 17.4687 173.775 19.0709C173.775 20.8067 173.976 22.3199 174.421 23.6328C174.844 24.9235 175.444 26.0139 176.223 26.8818C177.002 27.7274 177.937 28.3728 179.005 28.7733C180.051 29.1739 181.186 29.3741 182.41 29.3741C183.545 29.3741 184.546 29.2406 185.436 28.9513C186.282 28.6843 187.038 28.3505 187.728 27.9277L188.351 27.5494L187.172 24.968L186.393 25.4131C185.926 25.6801 185.347 25.9249 184.679 26.1029C184.034 26.281 183.3 26.37 182.432 26.37C181.542 26.37 180.785 26.2142 180.162 25.9249C179.517 25.6356 178.983 25.1906 178.515 24.5897C178.048 23.9889 177.692 23.2323 177.447 22.2976C177.202 21.363 177.069 20.2726 177.069 19.0042V18.9597H188.819V18.1586C188.819 16.6454 188.663 15.2879 188.351 14.1307C188.084 12.9958 187.595 11.9944 186.927 11.1933ZM177.314 16.2448C177.425 15.6217 177.558 15.0654 177.736 14.5758C177.981 13.8637 178.315 13.3296 178.693 12.9068C179.072 12.484 179.494 12.1725 179.939 11.9722C180.385 11.7719 180.896 11.6607 181.475 11.6607C182.143 11.6607 182.721 11.7719 183.211 11.9722C183.7 12.1725 184.101 12.484 184.457 12.8846C184.791 13.2851 185.08 13.8192 185.258 14.5091C185.392 14.9986 185.481 15.5772 185.525 16.2448H177.314Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M193.179 7.96663C193.179 7.32129 192.823 7.12101 192.223 7.09875H190.643V9.9249H191.266V8.78999H191.755L192.378 9.9249H193.09L192.423 8.74549C192.912 8.70098 193.179 8.47845 193.179 7.96663ZM192.111 8.32268H191.288V7.58832H191.978C192.245 7.58832 192.579 7.61058 192.579 7.92212C192.579 8.25592 192.423 8.32268 192.111 8.32268Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M191.867 5.89706C190.02 5.89706 189.264 7.29901 189.264 8.50068C189.264 9.74686 190.043 11.1265 191.867 11.1265C193.47 11.1265 194.493 9.94713 194.493 8.50068C194.493 7.14324 193.581 5.89706 191.867 5.89706ZM191.845 10.4812C190.666 10.4812 189.887 9.6801 189.887 8.50068C189.887 7.32126 190.666 6.52015 191.845 6.52015C193.024 6.52015 193.826 7.29901 193.826 8.45617C193.848 9.65784 193.024 10.4812 191.845 10.4812Z",
    fill: "#00294A"
  }), _jsx("path", {
    d: "M28.9288 8.38943C26.2362 3.84979 21.296 0.823364 15.6437 0.823364C7.6771 0.823364 1.11243 6.87622 0.289062 14.6426C5.9191 11.6829 13.4184 7.4993 20.6952 7.4993C23.4323 7.47705 26.1695 7.72183 28.9288 8.38943Z",
    fill: "#91D1EB"
  }), _jsx("path", {
    d: "M2.04688 23.5661C4.65049 28.395 9.76871 31.6885 15.6435 31.6885C19.6936 31.6885 23.3654 30.1307 26.1248 27.5939C18.937 23.0097 12.9732 21.541 2.04688 23.5661Z",
    fill: "#3894CA"
  }), _jsx("path", {
    d: "M31.0877 15.7997C29.7748 15.0876 23.0544 11.7051 16.0891 11.7051C8.52307 11.7051 1.35757 15.6217 0.222656 16.2448V16.267C0.222656 18.4478 0.667719 20.5174 1.49108 22.3866C3.13782 21.9193 11.505 20.0501 16.0891 20.0501C21.6302 20.0501 25.7247 21.363 29.4188 23.2545C30.4869 21.1627 31.0877 18.7816 31.0877 16.267C31.0877 16.1113 31.0877 15.9555 31.0877 15.7997Z",
    fill: "#65B3DB"
  })]
}), 'LensOnlineLogo', 'Company');
export default LensOnlineLogo;