/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VacasaLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 187,
  height: 37,
  viewBox: "0 0 187 37",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M23.492 16.4971L22.6452 16.0093L20.1074 14.5431C19.932 14.4421 19.733 14.3889 19.5306 14.3889C19.3281 14.3889 19.1292 14.4421 18.9537 14.5431L16.4146 16.0093L15.576 16.493L13.0368 17.9592C12.8615 18.0604 12.7159 18.206 12.6146 18.3813C12.5133 18.5567 12.46 18.7556 12.46 18.9581V29.9709H15.576V20.7559C15.576 20.5534 15.6293 20.3545 15.7306 20.1791C15.8319 20.0038 15.9775 19.8582 16.1528 19.7569L18.9537 18.14C19.1292 18.039 19.3281 17.9859 19.5306 17.9859C19.733 17.9859 19.932 18.039 20.1074 18.14L22.9151 19.7611C23.0905 19.8623 23.2361 20.0079 23.3374 20.1832C23.4386 20.3586 23.492 20.5575 23.492 20.76V29.9709H26.6066V18.9622C26.6069 18.7597 26.5536 18.5606 26.4524 18.3852C26.3511 18.2099 26.2053 18.0643 26.0297 17.9633L23.492 16.4971Z",
    fill: "#FFE700"
  }), _jsx("path", {
    d: "M29.7226 12.9002L22.6452 8.81404L20.1074 7.34922C19.932 7.24822 19.733 7.19507 19.5306 7.19507C19.3281 7.19507 19.1292 7.24822 18.9537 7.34922L16.4146 8.81404L9.34534 12.8961L6.8076 14.3623C6.63205 14.4633 6.48628 14.6089 6.38499 14.7842C6.2837 14.9596 6.23048 15.1587 6.23071 15.3612V29.971H9.34534V17.159C9.34552 16.9565 9.39891 16.7577 9.50016 16.5824C9.60141 16.407 9.74696 16.2614 9.92222 16.1601L18.9537 10.9462C19.1292 10.8452 19.3281 10.792 19.5306 10.792C19.733 10.792 19.932 10.8452 20.1074 10.9462L29.1512 16.1642C29.3265 16.2655 29.472 16.4111 29.5733 16.5865C29.6745 16.7618 29.7279 16.9606 29.7281 17.1631V29.971H32.8427V15.3653C32.843 15.1628 32.7897 14.9637 32.6885 14.7884C32.5872 14.613 32.4414 14.4674 32.2658 14.3664L29.7226 12.9002Z",
    fill: "#FFD00A"
  }), _jsx("path", {
    d: "M38.4909 10.7693L20.1073 0.155214C19.9321 0.0535459 19.7331 0 19.5305 0C19.3279 0 19.1288 0.0535459 18.9536 0.155214L0.576885 10.7652C0.401341 10.8662 0.255564 11.0118 0.154274 11.1872C0.0529847 11.3626 -0.000231179 11.5616 7.54903e-07 11.7641V35.0478C7.54903e-07 35.3538 0.121558 35.6472 0.337931 35.8636C0.554305 36.08 0.84777 36.2015 1.15377 36.2015H15.5759V33.0869H3.11462V13.566C3.11481 13.3636 3.1682 13.1647 3.26945 12.9894C3.3707 12.8141 3.51625 12.6685 3.69151 12.5671L18.9536 3.75628C19.1288 3.65462 19.3279 3.60107 19.5305 3.60107C19.7331 3.60107 19.9321 3.65462 20.1073 3.75628L35.3763 12.5671C35.5514 12.6685 35.6967 12.8142 35.7977 12.9896C35.8987 13.1649 35.9519 13.3637 35.9518 13.566V33.0869H23.4919V36.2015H37.914C38.22 36.2015 38.5135 36.08 38.7298 35.8636C38.9462 35.6472 39.0678 35.3538 39.0678 35.0478V11.7683C39.0677 11.5658 39.0144 11.3669 38.9131 11.1915C38.8119 11.0162 38.6662 10.8706 38.4909 10.7693Z",
    fill: "#FFAD02"
  }), _jsx("path", {
    d: "M81.0433 11.1969H80.9241C77.4765 11.1969 74.5798 12.2096 72.7683 14.0485C71.7567 15.0653 71.0712 16.3605 70.7992 17.7687C70.7922 17.807 70.7936 17.8463 70.8035 17.8839C70.8133 17.9215 70.8313 17.9565 70.8561 17.9864C70.8809 18.0164 70.912 18.0405 70.9471 18.0571C70.9823 18.0737 71.0207 18.0824 71.0596 18.0825H74.3756C75.0676 15.6709 77.3655 14.339 80.8762 14.339H80.9967C84.8924 14.339 87.2178 15.7188 87.2178 18.0209V21.1095C87.2182 21.1471 87.2043 21.1835 87.1788 21.2113C87.1534 21.239 87.1183 21.256 87.0807 21.2588L79.0564 21.6082C72.5463 21.9508 69.3823 24.3488 69.3823 28.9515C69.3823 34.2956 74.1536 36.2016 78.6193 36.2016C82.0587 36.2016 84.5732 35.263 86.7779 33.15L87.2685 32.6019V35.7207H90.601V18.4991C90.5969 13.8607 87.115 11.2024 81.0433 11.1969ZM87.2178 26.211C87.1506 29.3489 84.8883 33.0116 78.7564 33.0116C75.0292 33.0116 72.8039 31.4947 72.8039 28.9515C72.8039 26.1795 74.9456 24.753 79.5388 24.4666L87.2123 24.2282L87.2178 26.211Z",
    fill: "#003349"
  }), _jsx("path", {
    d: "M105.319 11.1969C98.4759 11.1969 93.6717 16.8274 93.6717 23.7472C93.6717 30.615 98.2622 36.2016 105.319 36.2016C109.139 36.2016 112.221 34.6121 114.396 31.7277C114.889 31.0649 115.31 30.351 115.651 29.5983C115.668 29.5568 115.676 29.5117 115.671 29.4669C115.667 29.4221 115.652 29.3789 115.628 29.3413C115.603 29.3036 115.57 29.2726 115.53 29.251C115.491 29.2294 115.446 29.2178 115.401 29.2174H111.959C111.52 29.9206 110.983 30.5585 110.366 31.1124C108.913 32.384 107.311 33.0116 105.319 33.0116C100.905 33.0116 97.1495 28.8775 97.1495 23.7431C97.1522 18.5594 100.905 14.3883 105.319 14.3883C107.435 14.3883 109.134 15.1159 110.648 16.5807C111.113 17.0394 111.53 17.5445 111.892 18.088H115.355C115.401 18.0877 115.446 18.0758 115.486 18.0536C115.526 18.0313 115.56 17.9994 115.584 17.9607C115.609 17.922 115.623 17.8777 115.626 17.832C115.629 17.7862 115.621 17.7405 115.602 17.6989C115.122 16.6961 114.504 15.7661 113.764 14.9364C111.604 12.522 108.746 11.1969 105.319 11.1969Z",
    fill: "#003349"
  }), _jsx("path", {
    d: "M129.637 11.1969H129.518C126.07 11.1969 123.174 12.2096 121.362 14.0485C120.356 15.0604 119.672 16.3479 119.397 17.7482C119.39 17.7887 119.391 17.8304 119.402 17.8702C119.412 17.9101 119.431 17.9473 119.457 17.9792C119.483 18.0111 119.516 18.037 119.553 18.0551C119.59 18.0731 119.63 18.083 119.671 18.0839H122.96C123.652 15.6722 125.951 14.3403 129.462 14.3403H129.581C133.475 14.3403 135.802 15.7202 135.802 18.0222V21.1108C135.803 21.1485 135.789 21.1849 135.763 21.2127C135.738 21.2404 135.703 21.2574 135.665 21.2602L127.641 21.6096C121.131 21.9522 117.965 24.3501 117.965 28.9529C117.965 34.2969 122.738 36.203 127.204 36.203C130.643 36.203 133.157 35.2644 135.362 33.1514L135.853 32.6033V35.7234H139.185V18.4991C139.191 13.8607 135.709 11.2024 129.637 11.1969ZM135.812 26.211C135.743 29.3489 133.482 33.0116 127.35 33.0116C123.623 33.0116 121.398 31.4947 121.398 28.9515C121.398 26.1795 123.54 24.753 128.133 24.4666L135.806 24.2282L135.812 26.211Z",
    fill: "#003349"
  }), _jsx("path", {
    d: "M152.155 11.1956C145.787 11.1956 142.888 14.7994 142.888 18.1483C142.888 19.6556 143.249 20.8286 143.994 21.711C145.672 23.7075 148.03 24.1953 152.366 24.479C156.643 24.7599 158.779 26.1603 158.892 28.7597C158.924 29.242 158.855 29.7255 158.689 30.1798C158.524 30.634 158.267 31.0491 157.933 31.3988C156.926 32.4512 155.164 33.0075 152.839 33.0075H152.618C149.971 32.9692 147.744 32.0154 146.511 30.3903C146.239 30.0329 146.018 29.639 145.856 29.2201H142.608C142.565 29.2183 142.521 29.2269 142.482 29.2452C142.442 29.2636 142.407 29.2911 142.38 29.3256C142.354 29.36 142.335 29.4004 142.327 29.4433C142.319 29.4862 142.322 29.5304 142.334 29.5722C143.489 33.6584 147.301 36.1263 152.624 36.1989H152.872C156.185 36.1989 158.901 35.2876 160.523 33.6351C161.747 32.3868 162.347 30.7452 162.304 28.7542C162.215 24.294 159.168 21.8754 152.987 21.3547C148.998 21.0218 146.342 20.3243 146.342 18.0003C146.342 15.9052 148.836 14.3253 152.143 14.3253C154.771 14.3253 156.76 15.2968 157.744 17.0658C157.916 17.3884 158.054 17.7283 158.155 18.0798H161.418C161.457 18.0793 161.495 18.0703 161.529 18.0535C161.564 18.0367 161.595 18.0125 161.619 17.9826C161.644 17.9527 161.661 17.9178 161.671 17.8804C161.68 17.843 161.681 17.8039 161.674 17.766C161.61 17.4306 161.526 17.0993 161.422 16.7739C160.268 13.229 156.896 11.1956 152.155 11.1956Z",
    fill: "#003349"
  }), _jsx("path", {
    d: "M176.584 11.1969H176.465C173.017 11.1969 170.121 12.2096 168.309 14.0485C167.296 15.0674 166.61 16.365 166.339 17.7756C166.332 17.813 166.333 17.8515 166.343 17.8882C166.353 17.925 166.37 17.9593 166.394 17.9885C166.419 18.0178 166.449 18.0413 166.483 18.0576C166.518 18.0738 166.555 18.0823 166.593 18.0825H169.914C170.599 15.6709 172.905 14.339 176.416 14.339H176.535C180.43 14.339 182.756 15.7188 182.756 18.0209V21.1095C182.756 21.1471 182.742 21.1835 182.717 21.2113C182.691 21.239 182.656 21.256 182.619 21.2588L174.596 21.6082C168.086 21.9508 164.92 24.3488 164.92 28.9515C164.92 34.2956 169.692 36.2016 174.157 36.2016C177.597 36.2016 180.111 35.263 182.316 33.15L182.806 32.6019V35.722H186.139V18.4991C186.138 13.8607 182.657 11.2024 176.584 11.1969ZM182.758 26.211C182.691 29.3489 180.429 33.0116 174.298 33.0116C170.57 33.0116 168.346 31.4947 168.346 28.9515C168.346 26.1795 170.486 24.753 175.08 24.4666L182.753 24.2282L182.758 26.211Z",
    fill: "#003349"
  }), _jsx("path", {
    d: "M65.8909 11.7628L58.4983 31.8414L50.9837 11.7628H47.6718C47.6289 11.7639 47.5869 11.7749 47.5492 11.7951C47.5114 11.8153 47.4789 11.8441 47.4542 11.8791C47.4295 11.9142 47.4135 11.9545 47.4072 11.9969C47.401 12.0393 47.4048 12.0826 47.4183 12.1232L56.4278 35.7261H60.5194L69.4563 12.1232C69.4699 12.0822 69.4737 12.0386 69.4672 11.9959C69.4608 11.9532 69.4443 11.9127 69.4192 11.8775C69.3941 11.8424 69.361 11.8137 69.3227 11.7938C69.2844 11.7738 69.2419 11.7632 69.1987 11.7628H65.8909Z",
    fill: "#003349"
  })]
}), 'VacasaLogo', 'Company');
export default VacasaLogo;