/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MuralLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 173,
  height: 32,
  viewBox: "0 0 173 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#MuralLogo_svg__clip0_9094_60999)",
    children: [_jsx("path", {
      d: "M172.407 31.3772C172.407 31.7126 172.12 32 171.784 32H141.078C140.742 32 140.455 31.7126 140.455 31.3772V0.622754C140.455 0.287425 140.742 0 141.078 0H171.784C172.12 0 172.407 0.287425 172.407 0.622754V31.3772Z",
      fill: "#FF0065"
    }), _jsx("path", {
      d: "M32 31.3772C32 31.7126 31.7126 32 31.3772 32H0.622754C0.287425 32 0 31.7126 0 31.3772V0.622754C0 0.287425 0.287425 0 0.622754 0H31.3293C31.6647 0 31.9521 0.287425 31.9521 0.622754V31.3772H32Z",
      fill: "#FF0065"
    }), _jsx("path", {
      d: "M102.228 0.958084C102.228 0.431138 101.796 0 101.269 0H71.1856C70.6587 0 70.2275 0.431138 70.2275 0.958084V31.0419C70.2275 31.5689 70.6587 32 71.1856 32H93.6048L102.228 23.3772V0.958084Z",
      fill: "#FF0065"
    }), _jsx("path", {
      d: "M67.1138 31.3772C67.1138 31.7126 66.8263 32 66.491 32H35.7365C35.4012 32 35.1138 31.7126 35.1138 31.3772V0.622754C35.1138 0.287425 35.4012 0 35.7365 0H66.4431C66.7784 0 67.0659 0.287425 67.0659 0.622754V31.3772H67.1138Z",
      fill: "#FF0065"
    }), _jsx("path", {
      d: "M137.293 31.3772C137.293 31.7126 137.006 32 136.671 32H105.964C105.629 32 105.341 31.7126 105.341 31.3772V0.622754C105.341 0.287425 105.629 0 105.964 0H136.671C137.006 0 137.293 0.287425 137.293 0.622754V31.3772Z",
      fill: "#FF0065"
    }), _jsx("path", {
      d: "M86.6584 10.9221C87.1375 10.9221 87.8081 11.0179 88.0955 11.1137C89.1494 11.3533 89.9638 11.9281 90.5387 12.8383C91.4488 14.3233 91.2572 16.479 89.6764 17.5329C89.4848 17.6287 89.3411 17.7724 89.1494 17.9161C89.1973 18.0119 90.6345 20.2634 91.1614 21.0299C91.2572 21.1257 91.1614 21.3173 91.0177 21.3173H87.856C87.7123 21.3173 87.6165 21.2694 87.5207 21.1257L85.844 18.491H84.5027V20.9341C84.5027 21.1257 84.3111 21.3173 84.1195 21.3173H81.7243C81.5327 21.3173 81.3411 21.1257 81.3411 20.9341V11.3533C81.3411 11.1616 81.5327 10.97 81.7243 10.97H86.6584V10.9221ZM84.5027 15.9042H86.6584C86.85 15.9042 87.0417 15.8563 87.2333 15.7604C87.6644 15.5688 87.856 15.1856 87.856 14.7065C87.856 14.1796 87.6644 13.9401 87.2812 13.7006C87.0417 13.5568 86.8021 13.5089 86.5626 13.5089C85.892 13.5089 85.1734 13.5089 84.5027 13.5089V15.9042Z",
      fill: "white"
    }), _jsx("path", {
      d: "M22.2753 11.2574V20.8862C22.2753 21.0778 22.1315 21.2215 21.9399 21.2215H19.4489C19.2573 21.2215 19.1136 21.0778 19.1136 20.8862C19.1136 19.7365 19.1136 16.2395 19.1136 16.0958C18.1076 17.485 17.0537 18.8742 16.0477 20.3592C15.8561 20.0718 13.0298 16.1437 12.886 15.9042V20.9341C12.886 21.1257 12.7423 21.2694 12.5507 21.2694H10.0597C9.86808 21.2694 9.72437 21.1257 9.72437 20.9341V11.2574C9.72437 11.0658 9.86808 10.9221 10.0597 10.9221H12.2633C12.3591 10.9221 12.4549 10.97 12.5507 11.0658L15.9519 15.7604L19.401 11.0658C19.4489 10.97 19.5926 10.9221 19.6884 10.9221H21.9399C22.0836 10.9221 22.2753 11.0658 22.2753 11.2574Z",
      fill: "white"
    }), _jsx("path", {
      d: "M56.1915 10.9221C56.3831 10.9221 56.5747 11.0658 56.5747 11.3054V16.7185C56.5268 17.964 56.1436 19.1616 55.1855 20.0718C54.419 20.8383 53.4609 21.2215 52.407 21.4131C51.7843 21.5089 51.1136 21.5569 50.4909 21.5089C49.1495 21.4131 47.904 20.982 46.9459 19.976C46.3232 19.3054 45.94 18.491 45.7962 17.5329C45.7004 17.0539 45.7004 16.5269 45.7004 16.0479C45.7004 14.8503 45.7004 12.3113 45.7004 11.3533C45.7004 11.1616 45.8921 10.97 46.0837 10.97H48.431C48.6226 10.97 48.8142 11.1137 48.8142 11.3533V16C48.8142 16.5269 48.8621 17.1018 49.0537 17.5808C49.2454 18.0119 49.4849 18.3473 49.916 18.5868C50.4909 18.8742 51.0657 18.9221 51.6885 18.7784C52.5028 18.6347 53.0298 18.1557 53.2214 17.3413C53.3172 17.0539 53.3172 16.7664 53.3172 16.479C53.3172 15.1377 53.3172 12.3592 53.3172 11.3533C53.3172 11.1616 53.4609 10.97 53.7004 10.97H56.1915V10.9221Z",
      fill: "white"
    }), _jsx("path", {
      d: "M122.826 10.9221L127.425 20.9341C127.473 21.0778 127.377 21.2694 127.234 21.2694H124.455C124.263 21.2694 124.12 21.1736 124.024 20.982L123.593 19.9281H119.138L118.707 20.982C118.611 21.1736 118.467 21.2694 118.275 21.2694H115.497C115.305 21.2694 115.21 21.0778 115.305 20.9341L119.952 10.9221H122.826ZM122.395 17.4371L121.293 14.5628L120.192 17.4371H122.395Z",
      fill: "white"
    }), _jsx("path", {
      d: "M155.593 11.3054V18.5868H159.952C160.144 18.5868 160.335 18.7784 160.335 18.97V20.8862C160.335 21.0778 160.144 21.2694 159.952 21.2694H152.814C152.623 21.2694 152.431 21.0778 152.431 20.8862V11.3054C152.431 11.1137 152.623 10.9221 152.814 10.9221H155.162C155.449 10.9221 155.593 11.0658 155.593 11.3054Z",
      fill: "white"
    }), _jsx("path", {
      d: "M85.5569 31.9999L93.8443 23.6646H101.94L93.6048 31.9999H85.5569Z",
      fill: "#B0044B"
    }), _jsx("path", {
      d: "M102.227 23.3772H94.5628C94.0359 23.3772 93.6047 23.8083 93.6047 24.3353V32L102.227 23.3772Z",
      fill: "#FF5BA0"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "MuralLogo_svg__clip0_9094_60999",
      children: _jsx("rect", {
        width: 172.407,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'MuralLogo', 'Company');
export default MuralLogo;