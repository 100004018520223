/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AaaLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 54,
  height: 32,
  viewBox: "0 0 54 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AaaLogo_svg__clip0_12854_184872)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M52.8903 1.2471C51.3347 -1.40324 43.1535 0.32522 32.9555 4.99196C33.4742 5.39543 33.9927 5.85625 34.3959 6.31719C42.2317 2.97556 48.281 1.93863 49.5488 4.12779C50.9892 6.60537 45.2849 12.7701 36.1241 18.8774L36.2396 18.9924C47.417 11.733 54.6189 4.2432 52.8903 1.2471Z",
      fill: "#0A357E"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.60916 30.0549C3.74487 28.4991 5.58863 25.5608 9.2759 22.0464C8.98784 21.9886 8.69991 21.8733 8.41173 21.7581C8.35414 21.7581 8.35414 21.7003 8.35414 21.7003C8.06621 21.5852 7.77803 21.47 7.48985 21.355C3.05358 25.445 0.806592 28.9022 1.84377 30.7463C3.11129 32.8779 8.58449 32.1865 15.9016 29.4786L15.8441 29.3635C9.96719 31.3799 5.64621 31.7831 4.60916 30.0549Z",
      fill: "#0A357E"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 12.1365C0 5.85644 8.29655 0.728638 18.4943 0.728638C28.7497 0.728638 37.0463 5.85644 37.0463 12.1365C37.0463 18.4741 28.7497 23.6018 18.4943 23.6018C8.29655 23.6016 0 18.4741 0 12.1365ZM10.4858 3.2638L14.0006 15.19L17.6877 1.70798C15.095 1.82352 12.6176 2.34168 10.4858 3.2638ZM17.5149 8.21872L15.6135 15.19H19.4738L17.5149 8.21872ZM22.9883 15.19L26.3299 3.14851C24.1406 2.28422 21.6632 1.76557 19.0704 1.70798L22.9883 15.19ZM10.4284 15.19L8.52678 8.21872L6.62579 15.19H10.4284ZM32.3795 16.5727C33.3012 15.2475 33.82 13.7496 33.82 12.1363C33.82 9.08277 31.9187 6.37484 28.8073 4.41579L32.3795 16.5727ZM28.4619 15.19L26.5029 8.21872L24.6017 15.19H28.4619ZM28.8652 16.5727H24.1982L23.7951 18.0707L24.8896 21.6423C26.6757 21.1239 28.2887 20.3174 29.6141 19.3385L28.8652 16.5727ZM12.2144 21.6425L10.8315 16.5729H6.22244L5.81897 18.0129C7.43226 19.6261 9.62154 20.8938 12.2143 21.7003V21.6425H12.2144ZM19.8773 16.5727H15.2103L13.7123 22.1035C15.2103 22.4495 16.8237 22.6221 18.4943 22.6221C19.5315 22.6221 20.5108 22.5647 21.4903 22.3917L19.8773 16.5727ZM7.89319 4.6465C5.0125 6.54761 3.22646 9.19819 3.22646 12.1365C3.22646 13.7498 3.74486 15.19 4.60915 16.5152L7.89319 4.6465Z",
      fill: "#FD000D"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AaaLogo_svg__clip0_12854_184872",
      children: _jsx("rect", {
        width: 53.1459,
        height: 32,
        fill: "white",
        transform: "translate(0 0.000732422)"
      })
    })
  })]
}), 'AaaLogo', 'Company');
export default AaaLogo;