/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SmartcarLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 102,
  height: 32,
  viewBox: "0 0 102 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.929 0C7.14 0 0 7.15475 0 16C0 24.8453 7.123 32 15.929 32C24.718 32 31.858 24.8453 31.858 16C31.858 7.17182 24.718 0 15.929 0ZM15.929 3.99573C21.114 3.99573 25.483 7.34258 27.149 11.9872H4.709C6.358 7.35966 10.744 3.99573 15.929 3.99573ZM15.929 17.9979C14.824 17.9979 13.94 17.1099 13.94 16C13.94 15.4707 14.144 14.9584 14.518 14.5827C15.3 13.7972 16.558 13.7972 17.34 14.5827C18.122 15.3682 18.122 16.6318 17.34 17.4173C17.153 17.6051 16.932 17.7417 16.694 17.8442C16.439 17.9466 16.184 17.9979 15.929 17.9979ZM3.978 16C9.418 16 13.821 21.2593 13.923 27.7994C8.296 26.826 3.978 21.9253 3.978 16ZM17.935 27.7994C18.02 21.2764 22.44 16 27.88 16C27.863 21.9253 23.562 26.826 17.935 27.7994Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M44.9819 14.9925C43.6219 14.8218 42.6529 14.7193 42.1089 14.7193C41.5649 14.7193 41.2079 14.7705 41.0549 14.873C40.9019 14.9755 40.8169 15.1291 40.8169 15.3511C40.8169 15.5731 40.9189 15.7097 41.1399 15.7951C41.3609 15.8805 41.9049 16 42.7889 16.1537C43.6729 16.3074 44.3019 16.5806 44.6759 16.9392C45.0499 17.2978 45.2369 17.8954 45.2369 18.715C45.2369 20.508 44.1319 21.413 41.9049 21.413C41.1739 21.413 40.2899 21.3106 39.2529 21.1057L38.7259 21.0032L38.7939 19.1419C40.1539 19.3127 41.1059 19.4152 41.6499 19.4152C42.1769 19.4152 42.5509 19.3639 42.7379 19.2615C42.9249 19.1761 43.0269 18.9883 43.0269 18.7833C43.0269 18.5784 42.9249 18.4248 42.7209 18.3223C42.5169 18.2198 41.9899 18.1003 41.1399 17.9637C40.2899 17.8271 39.6439 17.571 39.2359 17.2295C38.8109 16.8879 38.6069 16.2732 38.6069 15.4023C38.6069 14.5144 38.9129 13.8655 39.5079 13.4045C40.1029 12.9605 40.8679 12.7385 41.8029 12.7385C42.4489 12.7385 43.3329 12.841 44.4719 13.0459L45.0159 13.1483L44.9819 14.9925Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M46.8521 21.2081H49.0621V14.9925L49.266 14.9242C49.674 14.7705 50.099 14.6851 50.541 14.6851C51.0171 14.6851 51.34 14.8559 51.4761 15.1974C51.6291 15.5389 51.6971 16.1195 51.6971 16.9391V21.2081H53.9071V16.905C53.9071 16.4781 53.8901 15.8463 53.8391 15.0096L54.0941 14.9242C54.6041 14.7705 55.0291 14.6851 55.3861 14.6851C55.8451 14.6851 56.1511 14.8559 56.3041 15.1974C56.4571 15.5389 56.5421 16.0683 56.5421 16.7684V21.2081H58.7521V16.7001C58.7521 15.3169 58.5481 14.2924 58.1231 13.6606C57.6981 13.0288 56.9331 12.7043 55.8451 12.7043C55.0121 12.7043 54.0941 12.9776 53.0741 13.4898C52.5811 12.9605 51.9011 12.7043 51.0001 12.7043C50.4561 12.7043 49.8101 12.9263 49.0451 13.3703V12.9093H46.8521V21.2081Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M67.0481 15.5048V18.9541C67.0651 19.2273 67.1161 19.3981 67.1841 19.5176C67.2521 19.6201 67.3881 19.7055 67.5921 19.7396L67.5241 21.413C66.9631 21.413 66.5211 21.3789 66.1811 21.2935C65.8241 21.2081 65.4671 21.0374 65.1611 20.8325C64.4131 21.2081 63.5801 21.413 62.7301 21.413C61.0131 21.413 60.1631 20.508 60.1631 18.698C60.1631 17.81 60.4011 17.1782 60.8771 16.8196C61.3531 16.444 62.0841 16.222 63.0531 16.1708L64.8211 16.0342V15.5219C64.8211 15.1804 64.7361 14.9413 64.5831 14.8218C64.4301 14.7023 64.1751 14.634 63.8351 14.634L60.7241 14.7706L60.6561 13.2337C61.7441 12.9264 62.8831 12.7556 64.0221 12.7556C65.0931 12.7556 65.8581 12.9776 66.3171 13.4216C66.8101 13.8143 67.0481 14.5315 67.0481 15.5048ZM63.3251 17.6905C62.7131 17.7417 62.4071 18.0833 62.4071 18.6809C62.4071 19.2956 62.6791 19.5859 63.2231 19.5859C63.6821 19.5859 64.1411 19.5176 64.6001 19.381L64.8551 19.3127V17.5368L63.3251 17.6905Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M69.1729 12.9093V21.2081L71.3999 21.2252V15.6585L71.7229 15.5389C72.3519 15.334 73.1169 15.1462 74.0179 14.9584V12.7214C73.1679 12.8922 72.2839 13.2508 71.3659 13.7972V12.9093H69.1729Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M79.8999 14.8047H77.8599V18.2027C77.8599 18.4418 77.8599 18.6809 77.8769 18.9028C77.8939 19.0394 77.9619 19.159 78.0639 19.2614C78.1659 19.3639 78.3359 19.4151 78.5569 19.4151L79.8149 19.381L79.9169 21.1568C79.1859 21.3276 78.6079 21.413 78.2339 21.413C77.2479 21.413 76.5679 21.191 76.1939 20.747C75.8369 20.3031 75.6499 19.4663 75.6499 18.271V14.8217H74.6809V12.9263H75.6499V10.6211H77.8599V12.9263H79.8999V14.8047Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M86.632 12.9776C85.748 12.7897 85.017 12.7043 84.439 12.7043C83.215 12.7043 82.314 13.0459 81.77 13.7118C81.209 14.3948 80.937 15.5048 80.937 17.0245C80.937 18.5613 81.209 19.6713 81.736 20.3543C82.263 21.0544 83.147 21.3959 84.371 21.3959C85 21.3959 85.901 21.2935 87.074 21.0544L87.006 19.2785L85.085 19.4151C84.32 19.4151 83.81 19.2444 83.555 18.9029C83.3 18.5784 83.181 17.9466 83.181 17.0416C83.181 16.1366 83.3 15.5218 83.555 15.1974C83.81 14.873 84.303 14.7022 85.068 14.7022C85.493 14.7022 86.139 14.7534 87.006 14.8388L87.074 13.08L86.632 12.9776Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M95.0471 15.5048V18.9541C95.0641 19.2273 95.1151 19.3981 95.1831 19.5176C95.2511 19.6201 95.3871 19.7055 95.5911 19.7396L95.5231 21.413C94.9621 21.413 94.5201 21.3789 94.1801 21.2935C93.8231 21.2081 93.4661 21.0374 93.1601 20.8325C92.4121 21.2081 91.5791 21.413 90.7291 21.413C89.0121 21.413 88.1621 20.508 88.1621 18.698C88.1621 17.81 88.4001 17.1782 88.8761 16.8196C89.3521 16.444 90.0831 16.222 91.0521 16.1708L92.8201 16.0342V15.5219C92.8201 15.1804 92.7351 14.9413 92.5821 14.8218C92.4291 14.7023 92.1741 14.634 91.8341 14.634L88.7231 14.7706L88.6551 13.2337C89.7431 12.9264 90.8821 12.7556 92.0211 12.7556C93.0921 12.7556 93.8571 12.9776 94.3161 13.4216C94.8091 13.8143 95.0471 14.5315 95.0471 15.5048ZM91.3241 17.6905C90.7121 17.7417 90.4061 18.0833 90.4061 18.6809C90.4061 19.2956 90.6781 19.5859 91.2221 19.5859C91.6811 19.5859 92.1401 19.5176 92.5991 19.381L92.8541 19.3127V17.5368L91.3241 17.6905Z",
    fill: "#2F80ED"
  }), _jsx("path", {
    d: "M97.155 12.9093V21.2081L99.382 21.2252V15.6585L99.705 15.5389C100.334 15.334 101.116 15.1462 102 14.9584V12.7214C101.15 12.8922 100.266 13.2508 99.348 13.7972V12.9093H97.155Z",
    fill: "#2F80ED"
  })]
}), 'SmartcarLogo', 'Company');
export default SmartcarLogo;