/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PeerspaceLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 110,
  height: 33,
  viewBox: "0 0 110 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M28.6674 14.725C28.6674 15.101 28.7407 15.4551 28.8854 15.7884C29.0302 16.1208 29.2286 16.4108 29.4792 16.6579C29.7303 16.9034 30.0224 17.0969 30.3635 17.2442C30.6969 17.3874 31.066 17.4599 31.4607 17.4599C31.8672 17.4599 32.2322 17.3874 32.5614 17.2442C32.8878 17.0969 33.1675 16.9034 33.3988 16.6579C33.6326 16.4108 33.8067 16.1208 33.9294 15.7884C34.0505 15.4551 34.1082 15.101 34.1082 14.725C34.1082 14.35 34.0505 13.9936 33.9294 13.6615C33.8067 13.3292 33.6326 13.0412 33.3988 12.7931C33.1675 12.547 32.8878 12.3511 32.5614 12.2072C32.2322 12.0615 31.8672 11.9909 31.4607 11.9909C31.066 11.9909 30.6969 12.0615 30.3635 12.2072C30.0224 12.3511 29.7303 12.547 29.4792 12.7931C29.2286 13.0412 29.0302 13.3292 28.8854 13.6615C28.7407 13.9936 28.6674 14.35 28.6674 14.725ZM27.8882 11.3835H28.7551V12.5546H28.7832C28.9208 12.3231 29.091 12.1233 29.2914 11.9547C29.4919 11.7851 29.7139 11.6444 29.9513 11.5341C30.1868 11.4229 30.4351 11.3418 30.6927 11.2866C30.9554 11.235 31.2104 11.2092 31.4607 11.2092C31.9715 11.2092 32.4417 11.2974 32.8739 11.4693C33.2991 11.6427 33.671 11.8868 33.9863 12.2009C34.299 12.5156 34.5425 12.8858 34.7174 13.315C34.8901 13.7435 34.9776 14.2137 34.9776 14.725C34.9776 15.2368 34.8901 15.7072 34.7174 16.1338C34.5425 16.5661 34.299 16.9367 33.9863 17.2506C33.671 17.5637 33.2991 17.8066 32.8739 17.9807C32.4417 18.1559 31.9715 18.2403 31.4607 18.2403C31.2104 18.2403 30.9554 18.2144 30.6927 18.1603C30.4351 18.1087 30.1868 18.0264 29.9513 17.9138C29.7139 17.8051 29.4919 17.6663 29.2914 17.4977C29.091 17.328 28.9208 17.1275 28.7832 16.8949H28.7551V21.3658H27.8882V11.3835Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M43.8674 14.1614C43.8674 13.8617 43.8056 13.582 43.6866 13.3211C43.5645 13.0608 43.4018 12.8316 43.1957 12.6338C42.9868 12.4359 42.7404 12.2795 42.4557 12.1645C42.1704 12.0494 41.8702 11.9908 41.5505 11.9908C41.0778 11.9908 40.685 12.089 40.3711 12.2795C40.0585 12.4721 39.8095 12.6951 39.6292 12.9442C39.4424 13.1958 39.3146 13.4397 39.237 13.677C39.1585 13.912 39.1217 14.074 39.1217 14.1614H43.8674ZM44.5311 16.8096C44.1926 17.3219 43.7743 17.6864 43.2834 17.9087C42.785 18.1314 42.2379 18.2401 41.6375 18.2401C41.1272 18.2401 40.667 18.1558 40.2493 17.9807C39.8344 17.8065 39.4781 17.5635 39.1801 17.2505C38.8805 16.9366 38.6497 16.5659 38.491 16.1337C38.3295 15.707 38.2515 15.2323 38.2515 14.7104C38.2515 14.2096 38.3332 13.7467 38.499 13.3211C38.6649 12.8971 38.8932 12.5287 39.1927 12.2155C39.4903 11.9019 39.8427 11.6564 40.2493 11.4761C40.655 11.299 41.1045 11.2092 41.5937 11.2092C42.1081 11.2092 42.5579 11.299 42.9463 11.4761C43.3403 11.6564 43.6676 11.89 43.9307 12.1848C44.1953 12.4804 44.3987 12.8148 44.5311 13.1898C44.6692 13.5671 44.7344 13.9497 44.7344 14.3349V14.9415H39.1217C39.1217 15.1261 39.1551 15.3592 39.2309 15.6455C39.3034 15.9288 39.4343 16.2066 39.6292 16.4757C39.82 16.7456 40.078 16.9787 40.4004 17.1716C40.7249 17.3636 41.1406 17.4599 41.6521 17.4599C42.0948 17.4599 42.5246 17.3541 42.9407 17.1409C43.3572 16.9291 43.6732 16.6409 43.8941 16.2732L44.5311 16.8096Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M53.7277 14.1614C53.7277 13.8617 53.6642 13.582 53.5469 13.3211C53.4233 13.0608 53.2601 12.8316 53.0547 12.6338C52.8449 12.4359 52.5987 12.2795 52.3151 12.1645C52.0309 12.0494 51.7293 11.9908 51.4149 11.9908C50.939 11.9908 50.5468 12.089 50.2304 12.2795C49.9173 12.4721 49.6703 12.6951 49.4866 12.9442C49.3012 13.1958 49.1747 13.4397 49.098 13.677C49.0181 13.912 48.9783 14.074 48.9783 14.1614H53.7277ZM54.3919 16.8096C54.0552 17.3219 53.6375 17.6864 53.1404 17.9087C52.6416 18.1314 52.0957 18.2401 51.4957 18.2401C50.9867 18.2401 50.524 18.1558 50.1097 17.9807C49.6932 17.8065 49.3388 17.5635 49.0369 17.2505C48.7374 16.9366 48.5092 16.5659 48.3517 16.1337C48.1912 15.707 48.1123 15.2323 48.1123 14.7104C48.1123 14.2096 48.1927 13.7467 48.358 13.3211C48.5224 12.8971 48.7526 12.5287 49.0523 12.2155C49.3495 11.9019 49.7037 11.6564 50.1097 11.4761C50.5143 11.299 50.96 11.2092 51.454 11.2092C51.965 11.2092 52.4169 11.299 52.8073 11.4761C53.1981 11.6564 53.5259 11.89 53.7907 12.1848C54.0555 12.4804 54.2555 12.8148 54.3919 13.1898C54.5259 13.5671 54.5937 13.9497 54.5937 14.3349V14.9415H48.9783C48.9783 15.1261 49.0181 15.3592 49.0886 15.6455C49.1609 15.9288 49.2919 16.2066 49.4866 16.4757C49.6788 16.7456 49.9356 16.9787 50.2614 17.1716C50.5826 17.3636 51.0014 17.4599 51.5111 17.4599C51.956 17.4599 52.3856 17.3541 52.7999 17.1409C53.2167 16.9291 53.5328 16.6409 53.7535 16.2732L54.3919 16.8096Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M58.8557 13.2637C58.8557 13.1387 58.8513 12.9879 58.8428 12.8089C58.8303 12.6294 58.822 12.4483 58.82 12.2668C58.8156 12.0832 58.8076 11.9105 58.7992 11.7509C58.7887 11.5936 58.7822 11.4691 58.7822 11.3835H59.6518C59.6628 11.6349 59.6716 11.8758 59.6737 12.1069C59.6781 12.3377 59.6901 12.487 59.7109 12.5546C59.9291 12.1582 60.2163 11.8372 60.5646 11.5868C60.9103 11.336 61.3339 11.2092 61.8368 11.2092C61.9226 11.2092 62.0069 11.217 62.0884 11.2316C62.1707 11.2463 62.2555 11.2622 62.3434 11.2825L62.2396 12.1342C62.126 12.0971 62.0144 12.0761 61.9098 12.0761C61.5308 12.0761 61.2059 12.1374 60.9316 12.2578C60.6558 12.3792 60.4325 12.5461 60.25 12.7583C60.0724 12.9701 59.941 13.2196 59.8538 13.5017C59.7654 13.7875 59.7243 14.093 59.7243 14.4211V18.0676H58.8557V13.2637Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M66.1628 16.5391C66.3474 16.8953 66.6181 17.1676 66.9743 17.3566C67.335 17.545 67.7085 17.6378 68.1023 17.6378C68.2868 17.6378 68.4673 17.6095 68.6534 17.5519C68.838 17.4935 69.0017 17.4121 69.1449 17.3059C69.2888 17.1998 69.4063 17.0709 69.5001 16.9206C69.5904 16.7717 69.6366 16.6006 69.6366 16.4077C69.6366 16.0992 69.5403 15.8681 69.3413 15.7129C69.1434 15.5596 68.9009 15.4386 68.6077 15.3527C68.32 15.2659 68.001 15.1905 67.6509 15.1265C67.3072 15.0646 66.9887 14.9674 66.6975 14.8374C66.4098 14.7083 66.1654 14.528 65.9685 14.2962C65.7705 14.0631 65.6698 13.7377 65.6698 13.3127C65.6698 12.9837 65.7392 12.6996 65.8731 12.4575C66.0115 12.2176 66.1857 12.017 66.4014 11.8568C66.6196 11.6976 66.8633 11.5786 67.1345 11.5031C67.4064 11.4264 67.6777 11.3875 67.9594 11.3875C68.5066 11.3875 68.9837 11.4889 69.384 11.6915C69.7829 11.8944 70.0949 12.2123 70.3176 12.6449L69.5364 13.1083C69.3706 12.7999 69.1657 12.5682 68.9208 12.4065C68.6747 12.2493 68.3537 12.1685 67.9594 12.1685C67.8041 12.1685 67.6406 12.1922 67.4748 12.2393C67.3052 12.2882 67.1553 12.3573 67.017 12.445C66.8816 12.5293 66.7679 12.6421 66.6808 12.7753C66.5859 12.9119 66.5417 13.066 66.5417 13.2384C66.5417 13.5392 66.6391 13.7602 66.8379 13.9053C67.0348 14.0509 67.2759 14.1623 67.5676 14.2453C67.8563 14.3276 68.1776 14.3965 68.519 14.4537C68.8683 14.5135 69.1881 14.6042 69.4773 14.7381C69.769 14.8661 70.0111 15.0556 70.2089 15.3021C70.4073 15.5468 70.5026 15.8954 70.5026 16.35C70.5026 16.6978 70.4386 17 70.3096 17.2563C70.1798 17.5113 70.0032 17.7226 69.7729 17.8992C69.5487 18.0729 69.2888 18.2023 69.0017 18.2896C68.7095 18.375 68.4059 18.4181 68.0884 18.4181C67.5105 18.4181 66.9813 18.3094 66.5045 18.0871C66.0264 17.865 65.6593 17.5213 65.3999 17.0599L66.1628 16.5391Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M75.1657 14.725C75.1657 15.101 75.2392 15.4551 75.3814 15.7884C75.5264 16.1208 75.7233 16.4108 75.9777 16.6579C76.2253 16.9034 76.5214 17.0969 76.8602 17.2442C77.1949 17.3874 77.5645 17.4599 77.9589 17.4599C78.3666 17.4599 78.7303 17.3874 79.0575 17.2442C79.386 17.0969 79.6647 16.9034 79.8983 16.6579C80.1289 16.4108 80.3037 16.1208 80.4261 15.7884C80.5471 15.4551 80.6032 15.101 80.6032 14.725C80.6032 14.35 80.5471 13.9936 80.4261 13.6615C80.3037 13.3292 80.1289 13.0412 79.8983 12.7931C79.6647 12.547 79.386 12.3511 79.0575 12.2072C78.7303 12.0615 78.3666 11.9909 77.9589 11.9909C77.5645 11.9909 77.1949 12.0615 76.8602 12.2072C76.5214 12.3511 76.2253 12.547 75.9777 12.7931C75.7233 13.0412 75.5264 13.3292 75.3814 13.6615C75.2392 13.9936 75.1657 14.35 75.1657 14.725ZM74.3845 11.3835H75.2521V12.5546H75.2819C75.4177 12.3231 75.5873 12.1233 75.7868 11.9547C75.9921 11.7851 76.2104 11.6444 76.4479 11.5341C76.6821 11.4229 76.933 11.3418 77.191 11.2866C77.4535 11.235 77.7088 11.2092 77.9589 11.2092C78.4683 11.2092 78.9375 11.2974 79.368 11.4693C79.7971 11.6427 80.1682 11.8868 80.4852 12.2009C80.7962 12.5156 81.0407 12.8858 81.2149 13.315C81.3864 13.7435 81.4748 14.2137 81.4748 14.725C81.4748 15.2368 81.3864 15.7072 81.2149 16.1338C81.0407 16.5661 80.7962 16.9367 80.4852 17.2506C80.1682 17.5637 79.7971 17.8066 79.368 17.9807C78.9375 18.1559 78.4683 18.2403 77.9589 18.2403C77.7088 18.2403 77.4535 18.2144 77.191 18.1603C76.933 18.1087 76.6821 18.0264 76.4479 17.9138C76.2104 17.8051 75.9921 17.6663 75.7868 17.4977C75.5873 17.328 75.4177 17.1275 75.2819 16.8949H75.2521V21.3658H74.3845V11.3835Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.3926 14.7106C88.025 14.7106 87.6728 14.7358 87.3315 14.7841C86.9873 14.8317 86.6788 14.9134 86.403 15.0306C86.1277 15.1456 85.9094 15.2985 85.7457 15.4928C85.582 15.6858 85.5002 15.9273 85.5002 16.2148C85.5002 16.4173 85.5459 16.5998 85.6362 16.7596C85.7289 16.9188 85.8485 17.0463 85.9888 17.1489C86.1377 17.2506 86.2973 17.328 86.4754 17.381C86.6559 17.4347 86.8351 17.4599 87.0186 17.4599C87.4803 17.4599 87.8638 17.392 88.1609 17.2586C88.4591 17.1247 88.6992 16.9465 88.8766 16.7273C89.0563 16.5126 89.1778 16.2668 89.2442 15.9918C89.3141 15.716 89.3455 15.4388 89.3455 15.1581V14.7106H88.3926ZM90.1297 15.2607C90.1297 15.5895 90.1297 15.8838 90.1377 16.1423C90.1421 16.4044 90.1495 16.6445 90.159 16.8671C90.1678 17.0879 90.1803 17.2957 90.1952 17.4887C90.2076 17.6832 90.2303 17.8745 90.2591 18.0676H89.4486C89.3896 17.7411 89.3648 17.3796 89.3648 16.9828H89.3336C89.081 17.4172 88.7681 17.7332 88.401 17.938C88.0299 18.14 87.5597 18.2403 86.9913 18.2403C86.6996 18.2403 86.4153 18.2045 86.1377 18.1331C85.8568 18.0596 85.6048 17.9455 85.3832 17.7863C85.1634 17.6251 84.9809 17.4226 84.8419 17.1717C84.7016 16.9193 84.6306 16.6167 84.6306 16.2593C84.6306 15.7478 84.7622 15.3416 85.0205 15.0435C85.283 14.7448 85.6028 14.5203 85.9838 14.3708C86.3647 14.222 86.7734 14.1255 87.1986 14.0818C87.6286 14.0384 88.0077 14.0156 88.335 14.0156H89.3455V13.6089C89.3455 13.0322 89.1719 12.6179 88.8131 12.3673C88.4566 12.1159 88.0077 11.9908 87.4674 11.9908C86.6957 11.9908 86.0166 12.2414 85.4264 12.7424L84.9208 12.1492C85.2383 11.8418 85.6362 11.6081 86.1084 11.4475C86.5806 11.2866 87.033 11.2092 87.4674 11.2092C88.2766 11.2092 88.9234 11.3987 89.4056 11.7817C89.8866 12.1619 90.1297 12.7766 90.1297 13.6245V15.2607Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M99.7056 17.0823C99.3763 17.4807 99.0052 17.7708 98.591 17.9609C98.1769 18.1473 97.7284 18.2401 97.2463 18.2401C96.7343 18.2401 96.2601 18.1558 95.8262 17.9807C95.3931 17.8065 95.0196 17.5635 94.7131 17.2505C94.405 16.9366 94.162 16.5659 93.9903 16.1337C93.8183 15.707 93.7319 15.2368 93.7319 14.7248C93.7319 14.2137 93.8183 13.7433 93.9903 13.3148C94.162 12.8856 94.405 12.5155 94.7131 12.2007C95.0196 11.8868 95.3931 11.6425 95.8262 11.4691C96.2601 11.2973 96.7343 11.2092 97.2463 11.2092C97.7394 11.2092 98.1937 11.3108 98.6113 11.5135C99.0324 11.7161 99.4056 11.9989 99.7349 12.3672L99.0389 12.8876C98.7874 12.6094 98.5147 12.39 98.215 12.2288C97.9139 12.071 97.5939 11.9908 97.2463 11.9908C96.8415 11.9908 96.4724 12.0614 96.1456 12.207C95.8182 12.3511 95.5395 12.5468 95.3059 12.793C95.0761 13.041 94.8985 13.329 94.7785 13.6614C94.6589 13.9934 94.5995 14.3498 94.5995 14.7248C94.5995 15.1009 94.6589 15.4551 94.7785 15.7883C94.8985 16.1206 95.0761 16.4107 95.3059 16.6577C95.5395 16.9032 95.8182 17.0967 96.1456 17.244C96.4724 17.3874 96.8415 17.4599 97.2463 17.4599C97.6138 17.4599 97.9487 17.3769 98.2548 17.2059C98.5553 17.0381 98.8188 16.813 99.0389 16.5327L99.7056 17.0823Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M108.833 14.1614C108.833 13.8617 108.77 13.582 108.65 13.3211C108.529 13.0608 108.363 12.8316 108.156 12.6338C107.95 12.4359 107.702 12.2795 107.419 12.1645C107.134 12.0494 106.833 11.9908 106.516 11.9908C106.042 11.9908 105.65 12.089 105.337 12.2795C105.021 12.4721 104.775 12.6951 104.589 12.9442C104.407 13.1958 104.277 13.4397 104.199 13.677C104.123 13.912 104.086 14.074 104.086 14.1614H108.833ZM109.495 16.8096C109.16 17.3219 108.742 17.6864 108.243 17.9087C107.746 18.1314 107.199 18.2401 106.603 18.2401C106.092 18.2401 105.629 18.1558 105.212 17.9807C104.798 17.8065 104.443 17.5635 104.14 17.2505C103.845 16.9366 103.613 16.5659 103.457 16.1337C103.295 15.707 103.217 15.2323 103.217 14.7104C103.217 14.2096 103.299 13.7467 103.461 13.3211C103.627 12.8971 103.857 12.5287 104.156 12.2155C104.457 11.9019 104.811 11.6564 105.212 11.4761C105.618 11.299 106.067 11.2092 106.558 11.2092C107.072 11.2092 107.52 11.299 107.909 11.4761C108.3 11.6564 108.629 11.89 108.895 12.1848C109.16 12.4804 109.361 12.8148 109.495 13.1898C109.634 13.5671 109.7 13.9497 109.7 14.3349V14.9415H104.086C104.086 15.1261 104.122 15.3592 104.193 15.6455C104.266 15.9288 104.401 16.2066 104.589 16.4757C104.784 16.7456 105.043 16.9787 105.366 17.1716C105.689 17.3636 106.103 17.4599 106.618 17.4599C107.061 17.4599 107.491 17.3541 107.906 17.1409C108.317 16.9291 108.637 16.6409 108.86 16.2732L109.495 16.8096Z",
    fill: "#575757"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0.00537109 21.4371V0.26123L10.595 10.8485L0.00537109 21.4371Z",
    fill: "#841F45"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.1857 21.4309H0.010498L10.5981 10.8428L21.1857 21.4309Z",
    fill: "#52A2B1"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.1775 0.25415V21.431L10.5906 10.8429L21.1775 0.25415Z",
    fill: "#90CCD5"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0 0.25415H21.1774L10.5905 10.8429L0 0.25415Z",
    fill: "#E72A54"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.5702 21.4341L0.00634766 32V21.4341H10.5702Z",
    fill: "#E72A54"
  })]
}), 'PeerspaceLogo', 'Company');
export default PeerspaceLogo;