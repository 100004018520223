/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CrossFitLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 100,
  height: 32,
  viewBox: "0 0 100 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#CrossFitLogo_svg__clip0_2301_2652)",
    children: [_jsx("path", {
      d: "M6.796 29C2.73664 29 0 26.3054 0 23.7684V8.23135C0 5.69382 2.73664 3 6.796 3C10.7948 3 13.4115 5.69379 13.4115 8.23135V13.0554H7.93873V8.98298C7.93873 8.32545 7.42735 7.91795 6.79595 7.91795C6.10394 7.91795 5.59316 8.32545 5.59316 8.98298V23.016C5.59316 23.6742 6.10394 24.0819 6.79595 24.0819C7.42735 24.0819 7.93873 23.6742 7.93873 23.016V18.9451H13.4115V23.7684C13.4115 26.3056 10.7948 29 6.796 29Z",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M20.2666 17.4507V28.5297H14.9736V9.70344H19.9662L20.296 11.6764C21.0784 10.5174 22.1606 9.70344 24.596 9.70344V14.151C21.6791 14.151 20.2665 15.7591 20.2665 17.4504",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M33.2263 14.558C33.2263 13.9324 32.7158 13.6505 32.0841 13.6505C31.4527 13.6505 30.9717 13.9324 30.9717 14.558V23.9875C30.9717 24.6133 31.4527 24.8956 32.0841 24.8956C32.7158 24.8956 33.2263 24.6133 33.2263 23.9875V14.558ZM32.0841 28.8429C28.5358 28.8429 25.6489 27.0258 25.6489 24.2375V14.3394C25.6489 11.5197 28.5358 9.70343 32.0841 9.70343C35.6325 9.70343 38.5193 11.5196 38.5193 14.3394V24.2375C38.5193 27.0259 35.6325 28.8429 32.0841 28.8429Z",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M46.093 28.8431C42.2142 28.8431 39.8989 27.0261 39.8989 24.2378V22.327H44.74V23.9245C44.74 24.5514 45.2217 24.9899 46.0034 24.9899C46.7848 24.9899 47.236 24.6766 47.236 23.6117C47.236 21.9515 46.0034 21.45 43.2069 19.9776C40.5907 18.6625 39.9896 16.8141 39.9896 14.8095C39.9896 10.9563 42.7858 9.70354 46.093 9.70354C49.6714 9.70354 52.1371 11.5198 52.1371 14.3395V16.0936H47.3261V14.6528C47.3261 14.0259 46.9954 13.6505 46.2134 13.6505C45.3719 13.6505 44.9811 14.0259 44.9811 14.9347C44.9811 16.3134 45.763 16.4381 47.8079 17.5033C51.6261 19.4142 52.228 21.7941 52.228 23.7682C52.228 26.3995 50.4539 28.843 46.093 28.843",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M59.5945 28.8431C55.7156 28.8431 53.4004 27.0261 53.4004 24.2378V22.327H58.2417V23.9245C58.2417 24.5514 58.7234 24.9899 59.5046 24.9899C60.2868 24.9899 60.7377 24.6766 60.7377 23.6117C60.7377 21.9515 59.5046 21.45 56.7079 19.9776C54.0919 18.6625 53.4913 16.8141 53.4913 14.8095C53.4913 10.9563 56.2875 9.70354 59.5945 9.70354C63.1729 9.70354 65.6391 11.5198 65.6391 14.3395V16.0936H60.8281V14.6528C60.8281 14.0259 60.4971 13.6505 59.7146 13.6505C58.8739 13.6505 58.482 14.0259 58.482 14.9347C58.482 16.3134 59.2645 16.4381 61.3091 17.5033C65.1281 19.4142 65.7292 21.7941 65.7292 23.7682C65.7292 26.3995 63.9558 28.843 59.5945 28.843",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M72.7358 7.91778V13.6189H76.735V18.2546H72.7358V28.5294H67.2021V3.46915H78.0876V7.91752H72.7358",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M79.3481 28.5299V10.0478H84.6712V28.5299H79.3481ZM79.3481 7.91174V3.46964H84.6712V7.91174H79.3481Z",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M93.5289 13.8073V23.3922C93.5289 23.9244 94.0396 24.301 94.6416 24.301H96.0246V28.5299H93.4988C90.1305 28.5299 88.2364 26.212 88.2364 23.831V13.8075H85.9507V10.0478H88.2364V6.8534H93.5289V10.0478H96.265V13.8075H93.5289",
      fill: "#1B1918"
    }), _jsx("path", {
      d: "M98.3805 10.6831C98.3296 10.6548 98.2478 10.6406 98.1365 10.6406H97.9646V11.0546H98.1464C98.2316 11.0546 98.2956 11.046 98.3382 11.0292C98.4174 10.998 98.4563 10.9382 98.4563 10.8501C98.4563 10.7674 98.4309 10.7113 98.3805 10.6831ZM98.1629 10.5028C98.305 10.5028 98.4087 10.5165 98.4748 10.5443C98.5929 10.5932 98.6522 10.6901 98.6522 10.8338C98.6522 10.936 98.6151 11.0112 98.5407 11.0589C98.5007 11.085 98.4458 11.1037 98.3753 11.1145C98.4652 11.1286 98.5305 11.1662 98.5718 11.2267C98.613 11.287 98.6337 11.3463 98.6337 11.4039V11.4871C98.6337 11.5139 98.6347 11.5416 98.6363 11.5721C98.6386 11.6025 98.6418 11.6227 98.6462 11.6313L98.6538 11.6461H98.4652C98.4641 11.6423 98.4631 11.6385 98.4623 11.6341C98.4613 11.6308 98.4602 11.6264 98.4594 11.6221L98.4552 11.5852V11.4948C98.4552 11.3626 98.4187 11.2756 98.3468 11.233C98.305 11.2086 98.2298 11.1962 98.1227 11.1962H97.9646V11.6461H97.7624V10.5028H98.1629ZM97.5497 10.4447C97.3763 10.6198 97.2893 10.8309 97.2893 11.0786C97.2893 11.3283 97.376 11.5411 97.5487 11.7168C97.723 11.8931 97.9338 11.9808 98.1825 11.9808C98.4312 11.9808 98.6423 11.8931 98.8171 11.7168C98.9906 11.5411 99.0775 11.3283 99.0775 11.0786C99.0775 10.8309 98.9906 10.6198 98.8171 10.4447C98.6418 10.2685 98.4302 10.1808 98.1825 10.1808C97.9361 10.1808 97.7253 10.2685 97.5497 10.4447ZM98.9205 11.8166C98.7168 12.0203 98.4709 12.1228 98.1825 12.1228C97.8941 12.1228 97.649 12.0203 97.4465 11.8166C97.2454 11.613 97.1445 11.367 97.1445 11.077C97.1445 10.7907 97.2462 10.5458 97.4492 10.3425C97.6516 10.1404 97.8959 10.0388 98.1825 10.0388C98.4709 10.0388 98.7168 10.1404 98.9205 10.3425C99.1233 10.5448 99.2252 10.7897 99.2252 11.077C99.2252 11.366 99.1233 11.6118 98.9205 11.8166Z",
      fill: "white"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "CrossFitLogo_svg__clip0_2301_2652",
      children: _jsx("rect", {
        width: 99.2251,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'CrossFitLogo', 'Company');
export default CrossFitLogo;