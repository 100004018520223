/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DominosLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 145,
  height: 32,
  viewBox: "0 0 145 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M31.4294 9.26523L22.735 0.570867C22.5546 0.389917 22.3403 0.246347 22.1043 0.148386C21.8684 0.0504256 21.6154 0 21.3599 0C21.1044 0 20.8514 0.0504256 20.6154 0.148386C20.3794 0.246347 20.1651 0.389917 19.9847 0.570867L12.6872 7.85385L0.567274 19.9883C0.203941 20.3537 0 20.8481 0 21.3635C0 21.8788 0.203941 22.3732 0.567274 22.7386L9.26164 31.433C9.6266 31.7961 10.1205 32 10.6354 32C11.1502 32 11.6441 31.7961 12.0091 31.433L22.8828 20.5679L31.4352 12.0127C31.799 11.6481 32.0034 11.154 32.0034 10.6389C32.0034 10.1239 31.799 9.62982 31.4352 9.26523H31.4294Z",
    fill: "white"
  }), _jsx("path", {
    d: "M30.8151 9.87379L22.1207 1.17942C21.9192 0.97838 21.6461 0.865479 21.3614 0.865479C21.0767 0.865479 20.8037 0.97838 20.6021 1.17942L11.1919 10.5809L21.4339 20.7997L30.8412 11.3924C31.0374 11.1867 31.1447 10.912 31.1398 10.6277C31.1349 10.3435 31.0182 10.0726 30.8151 9.87379ZM23.1727 12.3894C22.7608 12.8002 22.2192 13.0556 21.6402 13.1121C21.0611 13.1685 20.4804 13.0225 19.9969 12.6989C19.5134 12.3752 19.1571 11.894 18.9885 11.3372C18.8199 10.7803 18.8496 10.1823 19.0724 9.64486C19.2953 9.10743 19.6975 8.66385 20.2107 8.38967C20.7238 8.11548 21.3161 8.02763 21.8867 8.14108C22.4574 8.25454 22.971 8.56227 23.3403 9.0119C23.7095 9.46152 23.9114 10.0252 23.9118 10.607C23.9118 10.9381 23.8465 11.2659 23.7197 11.5718C23.5929 11.8776 23.407 12.1554 23.1727 12.3894Z",
    fill: "#E31937"
  }), _jsx("path", {
    d: "M1.14699 20.6086C0.946476 20.811 0.833984 21.0844 0.833984 21.3693C0.833984 21.6543 0.946476 21.9277 1.14699 22.1301L9.84136 30.8245C10.0423 31.0244 10.3143 31.1367 10.5978 31.1367C10.8813 31.1367 11.1532 31.0244 11.3542 30.8245L20.7789 21.4374L10.5601 11.21L1.14699 20.6086ZM8.68211 23.1155C8.26909 23.5308 7.72477 23.7899 7.14201 23.8487C6.55924 23.9074 5.97415 23.7622 5.48653 23.4377C4.99891 23.1132 4.63898 22.6296 4.46813 22.0693C4.29729 21.5091 4.32612 20.9069 4.54971 20.3656C4.7733 19.8242 5.17779 19.3772 5.69418 19.1008C6.21057 18.8243 6.80687 18.7357 7.38135 18.8498C7.95583 18.964 8.4729 19.274 8.84436 19.7268C9.21582 20.1797 9.41864 20.7474 9.41824 21.3331C9.41809 22.0012 9.15342 22.642 8.68211 23.1155ZM12.525 19.6551C12.8772 19.3029 13.326 19.0631 13.8145 18.9659C14.3031 18.8687 14.8094 18.9186 15.2696 19.1092C15.7298 19.2999 16.1231 19.6227 16.3998 20.0368C16.6765 20.451 16.8242 20.9379 16.8242 21.436C16.8242 21.9341 16.6765 22.421 16.3998 22.8352C16.1231 23.2493 15.7298 23.5721 15.2696 23.7627C14.8094 23.9534 14.3031 24.0032 13.8145 23.9061C13.326 23.8089 12.8772 23.5691 12.525 23.2169C12.2908 22.9832 12.105 22.7056 11.9782 22.4C11.8514 22.0944 11.7861 21.7668 11.7861 21.436C11.7861 21.1051 11.8514 20.7775 11.9782 20.4719C12.105 20.1663 12.2908 19.8888 12.525 19.6551Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M43.4015 13.4414H35.9505C35.8924 13.4406 35.8347 13.4513 35.7808 13.4729C35.7268 13.4946 35.6777 13.5266 35.6362 13.5673C35.5948 13.608 35.5617 13.6564 35.5391 13.7099C35.5164 13.7634 35.5045 13.8209 35.5042 13.879V30.6881C35.5042 30.8057 35.5509 30.9185 35.634 31.0016C35.7172 31.0848 35.83 31.1315 35.9476 31.1315H43.4247C49.2992 31.1315 53.0958 27.6538 53.0958 22.2864C53.0958 16.9191 49.2905 13.4529 43.4015 13.4529V13.4414ZM43.4015 26.4597H40.8512V18.1015H43.4189C46.0736 18.1015 47.6589 19.6549 47.6589 22.2603C47.6589 24.3412 46.2098 26.4481 43.3957 26.4481",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M86.1257 17.9972C85.2549 17.992 84.3953 18.1934 83.6174 18.5848C82.8396 18.9763 82.1656 19.5466 81.651 20.249C81.1032 18.7536 79.8628 17.9972 77.9703 17.9972C75.875 17.9972 74.2868 19.0579 73.7304 19.7709V18.7594C73.7313 18.6455 73.688 18.5357 73.6096 18.453C73.5313 18.3704 73.424 18.3212 73.3102 18.316H69.4093C69.3511 18.3148 69.2933 18.3253 69.2392 18.3468C69.1851 18.3683 69.1358 18.4003 69.0942 18.4411C69.0527 18.4818 69.0196 18.5304 68.9971 18.5841C68.9746 18.6378 68.963 18.6954 68.963 18.7536V30.662C68.9626 30.7199 68.9736 30.7772 68.9954 30.8308C69.0172 30.8844 69.0493 30.9332 69.09 30.9743C69.1306 31.0155 69.1789 31.0483 69.2322 31.0708C69.2855 31.0933 69.3427 31.105 69.4006 31.1054H73.3102C73.4268 31.1054 73.5387 31.0595 73.6217 30.9776C73.7047 30.8957 73.7521 30.7844 73.7536 30.6678V23.2138C73.9771 22.9094 74.2677 22.6606 74.603 22.4867C74.9382 22.3128 75.309 22.2185 75.6866 22.2111C76.6256 22.2111 77.1357 22.7182 77.1357 23.6399V30.6649C77.1357 30.7825 77.1824 30.8953 77.2656 30.9784C77.3487 31.0616 77.4615 31.1083 77.5791 31.1083H81.4568C81.5742 31.1083 81.6868 31.0621 81.7703 30.9797C81.8539 30.8972 81.9016 30.7852 81.9031 30.6678V23.2138C82.129 22.9061 82.4233 22.6551 82.7627 22.4805C83.1022 22.306 83.4776 22.2128 83.8593 22.2082C84.7867 22.2082 85.3084 22.7298 85.3084 23.637V30.662C85.308 30.7199 85.319 30.7772 85.3408 30.8308C85.3626 30.8844 85.3947 30.9332 85.4354 30.9743C85.476 31.0155 85.5244 31.0483 85.5777 31.0708C85.631 31.0933 85.6882 31.105 85.746 31.1054H89.6295C89.7469 31.1054 89.8595 31.0592 89.9431 30.9768C90.0266 30.8943 90.0743 30.7823 90.0758 30.6649V21.8372C90.0758 19.3622 88.6731 17.9972 86.1257 17.9972Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M96.3387 18.316H92.4639C92.4055 18.3148 92.3475 18.3253 92.2933 18.3467C92.239 18.3682 92.1896 18.4002 92.1478 18.4409C92.106 18.4816 92.0727 18.5302 92.0498 18.5839C92.027 18.6376 92.015 18.6953 92.0147 18.7536V30.662C92.0147 30.7796 92.0614 30.8924 92.1445 30.9755C92.2277 31.0587 92.3405 31.1054 92.4581 31.1054H96.3387C96.456 31.1054 96.5687 31.0592 96.6522 30.9768C96.7358 30.8943 96.7834 30.7823 96.785 30.6649V18.7594C96.785 18.6418 96.7383 18.529 96.6551 18.4459C96.5719 18.3627 96.4592 18.316 96.3416 18.316",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M94.5186 12.0358C93.9896 12.0358 93.4724 12.1927 93.0325 12.4866C92.5926 12.7805 92.2497 13.1983 92.0473 13.6871C91.8448 14.1759 91.7919 14.7137 91.8951 15.2326C91.9983 15.7515 92.253 16.2281 92.6271 16.6022C93.0012 16.9763 93.4779 17.2311 93.9968 17.3343C94.5157 17.4375 95.0535 17.3845 95.5423 17.1821C96.0311 16.9796 96.4489 16.6368 96.7428 16.1969C97.0367 15.757 97.1936 15.2398 97.1936 14.7107C97.1936 14.0013 96.9118 13.3209 96.4101 12.8192C95.9085 12.3176 95.2281 12.0358 94.5186 12.0358Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M107.969 17.9972C105.497 17.9972 104.152 19.1072 103.572 19.7709V18.7594C103.572 18.6418 103.526 18.529 103.442 18.4459C103.359 18.3627 103.247 18.316 103.129 18.316H99.2513C99.1354 18.3168 99.0246 18.3631 98.9427 18.445C98.8608 18.5269 98.8144 18.6378 98.8137 18.7536V30.662C98.8137 30.7796 98.8604 30.8924 98.9435 30.9755C99.0267 31.0587 99.1395 31.1054 99.2571 31.1054H103.135C103.252 31.1054 103.365 31.0592 103.448 30.9768C103.532 30.8943 103.58 30.7823 103.581 30.6649V23.2138C103.819 22.901 104.125 22.6474 104.477 22.4729C104.829 22.2984 105.217 22.2078 105.61 22.2082C106.841 22.2082 107.465 22.8226 107.465 24.0311V30.662C107.465 30.7796 107.511 30.8924 107.594 30.9755C107.678 31.0587 107.79 31.1054 107.908 31.1054H111.786C111.903 31.1054 112.016 31.0592 112.099 30.9768C112.183 30.8943 112.23 30.7823 112.232 30.6649V22.0169C112.232 19.4955 110.638 17.9914 107.966 17.9914",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M60.7816 17.9392C59.446 17.9392 58.1405 18.3353 57.03 19.0773C55.9196 19.8193 55.0541 20.8739 54.543 22.1078C54.0319 23.3416 53.8982 24.6994 54.1587 26.0092C54.4193 27.3191 55.0624 28.5223 56.0068 29.4667C56.9511 30.4111 58.1543 31.0542 59.4642 31.3147C60.7741 31.5753 62.1318 31.4416 63.3657 30.9305C64.5996 30.4194 65.6542 29.5539 66.3962 28.4434C67.1382 27.333 67.5342 26.0274 67.5342 24.6919C67.5338 23.8055 67.3589 22.9278 67.0193 22.1091C66.6798 21.2903 66.1822 20.5464 65.5552 19.9199C64.9282 19.2934 64.1839 18.7966 63.3648 18.4577C62.5458 18.1189 61.668 17.9447 60.7816 17.945V17.9392ZM60.7816 26.906C60.3224 26.9089 59.8727 26.7754 59.4896 26.5224C59.1064 26.2694 58.807 25.9083 58.6293 25.4849C58.4516 25.0615 58.4036 24.5949 58.4914 24.1442C58.5792 23.6935 58.7989 23.279 59.1225 22.9533C59.4462 22.6276 59.8593 22.4053 60.3094 22.3147C60.7596 22.2241 61.2265 22.2691 61.651 22.4442C62.0755 22.6192 62.4384 22.9164 62.6938 23.298C62.9492 23.6795 63.0856 24.1284 63.0856 24.5875C63.0856 25.1999 62.8433 25.7875 62.4116 26.2219C61.98 26.6562 61.394 26.9022 60.7816 26.906Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M120.399 17.9595C119.064 17.9607 117.759 18.3577 116.649 19.1004C115.539 19.8431 114.675 20.8981 114.164 22.1321C113.654 23.366 113.521 24.7236 113.783 26.0331C114.044 27.3426 114.687 28.5452 115.632 29.489C116.576 30.4328 117.78 31.0754 119.089 31.3355C120.399 31.5955 121.757 31.4615 122.99 30.9502C124.224 30.439 125.278 29.5735 126.02 28.4631C126.761 27.3528 127.157 26.0475 127.157 24.7122C127.157 23.8249 126.982 22.9463 126.643 22.1267C126.303 21.3071 125.805 20.5624 125.177 19.9353C124.55 19.3082 123.805 18.8109 122.985 18.4719C122.165 18.1329 121.286 17.9588 120.399 17.9595ZM120.399 26.9205C119.94 26.9205 119.492 26.7845 119.111 26.5298C118.73 26.275 118.432 25.9129 118.257 25.4893C118.081 25.0656 118.035 24.5995 118.125 24.1497C118.214 23.7 118.435 23.2869 118.759 22.9626C119.084 22.6384 119.497 22.4175 119.947 22.3281C120.396 22.2386 120.862 22.2845 121.286 22.46C121.71 22.6355 122.072 22.9327 122.327 23.3139C122.581 23.6952 122.717 24.1435 122.717 24.602C122.717 25.2169 122.473 25.8067 122.038 26.2415C121.604 26.6763 121.014 26.9205 120.399 26.9205Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M130.881 14.192V14.163C130.706 13.4888 130.28 12.9069 129.69 12.5355C129.101 12.1641 128.392 12.031 127.708 12.1633C127.373 12.2204 127.052 12.3432 126.764 12.5246C126.477 12.706 126.228 12.9424 126.032 13.2203C125.836 13.4983 125.696 13.8121 125.622 14.144C125.548 14.4759 125.539 14.8191 125.598 15.1541C125.728 15.6896 126.029 16.1683 126.455 16.5176C126.881 16.8669 127.41 17.0678 127.96 17.0901C127.904 17.3117 127.803 17.5195 127.664 17.7005C127.524 17.8815 127.349 18.0318 127.149 18.1421C127.118 18.1597 127.092 18.1832 127.071 18.2113C127.049 18.2393 127.034 18.2712 127.026 18.3052C127.017 18.3392 127.015 18.3746 127.02 18.4093C127.026 18.444 127.038 18.4773 127.056 18.5073L127.346 18.9449C127.374 19.015 127.429 19.0712 127.498 19.1016C127.567 19.1319 127.646 19.1339 127.717 19.1072C130.693 17.9045 131.186 15.3889 130.881 14.192Z",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M134.316 22.1212C134.316 21.643 134.924 21.4315 135.698 21.4315C137.243 21.4315 138.121 21.872 139.106 22.5212C139.205 22.5851 139.325 22.6075 139.44 22.5836C139.555 22.5597 139.656 22.4915 139.721 22.3937C139.724 22.385 139.729 22.3771 139.735 22.3705L141.045 20.0925C141.102 19.9916 141.118 19.8722 141.088 19.7601C141.058 19.6479 140.986 19.5519 140.886 19.4926C139.276 18.481 137.402 17.9688 135.501 18.0204C131.554 18.0204 129.444 19.8781 129.797 22.5473C130.418 27.2335 137.712 25.6309 137.599 27.2335C137.573 27.6045 136.927 27.8798 135.811 27.8798C134.362 27.8798 132.725 27.1553 131.69 26.5148C131.639 26.4831 131.582 26.4621 131.523 26.4531C131.464 26.4441 131.403 26.4474 131.345 26.4626C131.285 26.4761 131.228 26.5018 131.178 26.5382C131.128 26.5746 131.086 26.6209 131.055 26.6742L129.542 29.1956C129.49 29.2923 129.475 29.405 129.501 29.512C129.527 29.619 129.591 29.7127 129.682 29.7752C131.131 30.7547 133.475 31.4126 135.513 31.4126C139.46 31.4126 141.738 29.7172 141.738 26.9292C141.738 21.7851 134.333 23.379 134.301 22.1096",
    fill: "#0078AE"
  }), _jsx("path", {
    d: "M144.772 18.713C144.773 19.0522 144.65 19.38 144.427 19.6354C144.204 19.8907 143.895 20.0561 143.559 20.1007C143.223 20.1453 142.882 20.0661 142.6 19.8777C142.318 19.6894 142.114 19.4049 142.026 19.0773C141.938 18.7497 141.973 18.4013 142.123 18.0972C142.273 17.7931 142.529 17.5541 142.842 17.4247C143.156 17.2953 143.506 17.2844 143.827 17.394C144.148 17.5036 144.418 17.7263 144.587 18.0204C144.709 18.2308 144.773 18.4698 144.772 18.713ZM144.505 18.713C144.508 18.5665 144.48 18.4211 144.424 18.2855C144.368 18.15 144.286 18.0273 144.181 17.9248C144.08 17.8215 143.961 17.7391 143.828 17.6821C143.696 17.6252 143.554 17.595 143.41 17.5931C143.266 17.5912 143.123 17.6177 142.989 17.6712C142.855 17.7246 142.733 17.8039 142.63 17.9045L142.613 17.9248C142.405 18.1352 142.289 18.4189 142.289 18.7145C142.289 19.0101 142.405 19.2938 142.613 19.5042C142.816 19.7115 143.093 19.8297 143.383 19.833C143.673 19.8362 143.953 19.7243 144.161 19.5216L144.181 19.5042C144.286 19.4013 144.369 19.278 144.424 19.142C144.48 19.006 144.508 18.86 144.505 18.713ZM142.767 17.974H143.445C143.588 17.9602 143.732 18.0005 143.848 18.087C143.887 18.1239 143.919 18.1688 143.941 18.2187C143.962 18.2687 143.973 18.3225 143.972 18.3769C143.971 18.4714 143.938 18.5626 143.877 18.6348C143.797 18.7139 143.696 18.7682 143.587 18.7913C143.632 18.8064 143.675 18.83 143.711 18.8608C143.759 18.9044 143.799 18.9553 143.83 19.0115L144.068 19.4405H143.636C143.554 19.2643 143.457 19.0956 143.346 18.9362C143.33 18.9121 143.309 18.892 143.283 18.8775C143.258 18.8629 143.23 18.8542 143.201 18.8522H143.155V19.4318H142.79L142.767 17.974ZM143.143 18.6029H143.303C143.381 18.6093 143.459 18.5911 143.526 18.5507C143.546 18.5344 143.561 18.5141 143.572 18.4911C143.583 18.4681 143.589 18.4429 143.59 18.4174C143.59 18.392 143.585 18.3668 143.575 18.3437C143.564 18.3205 143.549 18.3002 143.529 18.2841C143.466 18.2431 143.392 18.2247 143.317 18.232H143.155L143.143 18.6029Z",
    fill: "#0078AE"
  })]
}), 'DominosLogo', 'Company');
export default DominosLogo;