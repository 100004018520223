/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EventbriteLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 154,
  height: 28,
  viewBox: "0 0 154 28",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M75.7942 27.5105V14.0783H72.9746V9.614H75.7942V3.34826L80.5914 2.23218V9.614H84.1942V14.0783H80.5914V27.5105H75.7942Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M24.7872 27.5105L17.6012 9.61401H22.6529L27.2152 21.7539L31.8166 9.61401H36.8683L29.6823 27.5105H24.7872Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M85.7605 27.5105V1.07692L90.4011 0V10.9455C91.8304 9.77063 93.5339 9.28112 95.3745 9.28112C100.642 9.28112 104.871 13.4322 104.871 18.621C104.871 23.8098 100.661 28 95.3745 28C93.5339 28 91.8304 27.5105 90.4011 26.3357V27.5105H85.7605ZM90.2836 18.8559C90.4011 21.4601 92.6332 23.6531 95.3549 23.6531C98.0766 23.6531 100.387 21.3427 100.387 18.621C100.387 15.8993 98.1157 13.628 95.3549 13.628C92.5941 13.628 90.4011 15.8601 90.2836 18.4252V18.8559Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M117.011 3.32864C117.011 1.5664 118.362 0.254517 120.124 0.254517C121.887 0.254517 123.198 1.5664 123.198 3.32864C123.198 5.09088 121.887 6.44193 120.124 6.44193C118.362 6.44193 117.011 5.09088 117.011 3.32864ZM117.794 27.5105V9.61396H122.435V27.5105H117.794Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M116.208 9.67276C113.839 9.79024 112.449 10.1623 111.019 11.2196V9.61401H106.398V27.5105H111.019V18.7385C111.019 15.9776 111.685 14.4112 116.208 14.2546V9.67276Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M126.821 27.5105V14.1567H124.001V9.614H126.821V3.34827H131.618V9.614H135.221V14.1567H131.618V27.5105H126.821Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M48.8124 21.4797C48.127 22.4979 47.0501 23.2811 45.7578 23.5748C43.6823 24.0644 41.6068 23.1832 40.4515 21.5189L49.6152 19.4042L51.1033 19.0518L53.962 18.386C53.9424 17.779 53.8641 17.1525 53.727 16.5259C52.5718 11.4937 47.5396 8.34128 42.5075 9.51611C37.4753 10.6714 34.3229 15.7035 35.4977 20.7357C36.6529 25.7679 41.6851 28.9203 46.7173 27.7455C49.6935 27.0602 51.9844 25.0434 53.1592 22.4588L48.8124 21.4797ZM43.4865 13.7259C45.562 13.256 47.618 14.1175 48.7732 15.7427L39.6291 17.8574C39.9424 15.8797 41.411 14.1958 43.4865 13.7259Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M148.77 21.4797C148.085 22.4979 147.008 23.2811 145.716 23.5748C143.64 24.0644 141.565 23.1832 140.409 21.5189L149.573 19.4042L151.061 19.0518L153.92 18.386C153.9 17.779 153.822 17.1525 153.685 16.5259C152.53 11.4937 147.498 8.34128 142.465 9.51611C137.433 10.6714 134.281 15.7035 135.456 20.7357C136.611 25.7679 141.643 28.9203 146.675 27.7455C149.651 27.0602 151.942 25.0434 153.117 22.4588L148.77 21.4797ZM143.425 13.7259C145.5 13.256 147.556 14.1175 148.712 15.7427L139.567 17.8574C139.881 15.8797 141.369 14.1958 143.425 13.7259Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M13.9984 21.4797C13.3131 22.4979 12.2362 23.2811 10.9438 23.5748C8.86833 24.0644 6.7928 23.1832 5.63756 21.5189L14.8012 19.4042L16.2893 19.0518L19.148 18.386C19.1285 17.779 19.0501 17.1525 18.9131 16.5259C17.7578 11.4937 12.7257 8.34128 7.6935 9.51611C2.66133 10.6714 -0.491114 15.7035 0.683711 20.7357C1.83896 25.7679 6.87112 28.9203 11.9033 27.7455C14.8795 27.0602 17.1704 25.0434 18.3452 22.4588L13.9984 21.4797ZM8.67252 13.7063C10.748 13.2364 12.804 14.0979 13.9592 15.7231L4.7956 17.8574C5.12847 15.8797 6.597 14.1958 8.67252 13.7063Z",
    fill: "#F05537"
  }), _jsx("path", {
    d: "M67.5508 27.5106V17.9553C67.5508 14.4896 65.7102 13.7455 63.8697 13.7455C62.0291 13.7455 60.1885 14.47 60.1885 17.8966V27.5302H55.548V9.63365H60.1885V10.9651C61.2067 10.2211 62.4403 9.30078 64.8878 9.30078C68.8039 9.30078 72.1913 11.5917 72.1913 17.2113V27.5302H67.5508V27.5106Z",
    fill: "#F05537"
  })]
}), 'EventbriteLogo', 'Company');
export default EventbriteLogo;