/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AgorapulseLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 147,
  height: 32,
  viewBox: "0 0 147 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AgorapulseLogo_svg__clip0_11055_126668)",
    children: [_jsx("path", {
      d: "M6.8131 24.0665C8.55325 24.0665 9.85092 23.4766 10.7947 22.2674L10.8538 22.2968V23.8011H15.2483V9.37848H10.6178V10.8827L10.5589 10.9122C9.55604 9.73241 8.11084 9.11304 6.57716 9.11304C2.86091 9.11304 0 12.1804 0 16.6045C0 21.0582 2.86091 24.0665 6.8131 24.0665ZM7.78641 19.9963C5.95779 19.9963 4.68954 18.6395 4.68954 16.6045C4.68954 14.54 6.01678 13.1832 7.78641 13.1832C9.49701 13.1832 10.8243 14.6284 10.8243 16.6045C10.8243 18.6691 9.43811 19.9963 7.78641 19.9963Z",
      fill: "#FF6726"
    }), _jsx("path", {
      d: "M24.4122 29.9064C30.252 29.9064 32.1987 26.603 32.1987 21.9135V9.37848H27.5681V10.7942H27.5091C26.4179 9.67342 24.9432 9.11304 23.3799 9.11304C19.7522 9.11304 16.9798 12.1509 16.9798 16.4276C16.9798 20.5272 19.7522 23.5355 23.6455 23.5355C25.3266 23.5355 26.6539 22.9458 27.6271 21.9429L27.6862 21.9724V23.2997C27.6862 24.8038 26.4179 26.0426 23.9109 26.0426C22.3182 26.0426 20.7256 25.4822 19.5162 24.7744L18.4839 28.52C20.2536 29.4934 22.0822 29.9064 24.4122 29.9064ZM24.7367 19.436C22.9966 19.436 21.6693 18.1972 21.6693 16.3391C21.6693 14.4809 22.9671 13.2127 24.7367 13.2127C26.4473 13.2127 27.7746 14.4809 27.7746 16.3095C27.7746 18.1383 26.4768 19.436 24.7367 19.436Z",
      fill: "#FF6726"
    }), _jsx("path", {
      d: "M41.7769 24.0665C46.3189 24.0665 49.5632 21.0286 49.5632 16.6045C49.5632 12.1509 46.3189 9.11304 41.7769 9.11304C37.1168 9.11304 33.961 12.21 33.961 16.6045C33.961 20.9697 37.1168 24.0665 41.7769 24.0665ZM41.7769 19.9963C39.9483 19.9963 38.621 18.5216 38.621 16.6045C38.621 14.6284 39.9187 13.2127 41.7769 13.2127C43.576 13.2127 44.9032 14.5989 44.9032 16.6045C44.9032 18.5806 43.517 19.9963 41.7769 19.9963Z",
      fill: "#FF6726"
    }), _jsx("path", {
      d: "M51.447 23.8011H56.048V17.3124C56.048 15.4247 57.1687 14.0386 59.3514 14.0386C59.5873 14.0386 60.0002 14.068 60.4425 14.0976V9.11304H60.2656C58.673 9.11304 57.1687 9.90938 56.107 11.3841H56.048V9.37848H51.447V23.8011Z",
      fill: "#FF6726"
    }), _jsx("path", {
      d: "M67.762 24.0665C69.5021 24.0665 70.7998 23.4766 71.7436 22.2674L71.8027 22.2968V23.8011H76.1973V9.37848H71.5667V10.8827L71.5077 10.9122C70.505 9.73241 69.0597 9.11304 67.526 9.11304C63.8098 9.11304 60.9488 12.1804 60.9488 16.6045C60.9488 21.0582 63.8098 24.0665 67.762 24.0665ZM68.7353 19.9963C66.9067 19.9963 65.6384 18.6395 65.6384 16.6045C65.6384 14.54 66.9656 13.1832 68.7353 13.1832C70.4459 13.1832 71.7731 14.6284 71.7731 16.6045C71.7731 18.6691 70.3869 19.9963 68.7353 19.9963Z",
      fill: "#FF6726"
    }), _jsx("path", {
      d: "M80.1353 29.6409H83.999V22.2085L84.058 22.179C85.0607 23.3883 86.4765 24.0665 88.2756 24.0665C92.2867 24.0665 95.2066 21.0877 95.2066 16.6341C95.2066 12.269 92.2867 9.17212 88.3936 9.17212C86.6239 9.17212 85.0903 9.82099 84.058 11.0302H83.999V9.37858H80.1353V29.6409ZM87.5973 20.5863C85.4737 20.5863 83.822 19.0231 83.822 16.6635C83.822 14.3335 85.3557 12.6229 87.5677 12.6229C89.6322 12.6229 91.284 14.186 91.284 16.6635C91.284 18.9641 89.7503 20.5863 87.5973 20.5863Z",
      fill: "#344563"
    }), _jsx("path", {
      d: "M102.411 24.0666C104.033 24.0666 105.567 23.3884 106.658 22.0611L106.717 22.0905V23.8012H110.61V9.37866H106.717V16.3982C106.717 19.1707 105.213 20.5864 103.532 20.5864C101.732 20.5864 100.936 19.7016 100.936 17.0766V9.37866H97.1019V18.0794C97.1019 22.474 99.2844 24.0666 102.411 24.0666Z",
      fill: "#344563"
    }), _jsx("path", {
      d: "M113.624 23.801H117.488V2.09351H113.624V23.801Z",
      fill: "#344563"
    }), _jsx("path", {
      d: "M125.287 24.0665C129.062 24.0665 130.862 22.12 130.862 19.5835C130.862 16.7226 129.033 16.0442 125.907 14.894C124.49 14.3631 123.783 13.9502 123.783 13.3603C123.783 12.5934 124.55 12.2985 125.139 12.2985C126.201 12.2985 127.499 12.7704 128.59 13.8321L130.479 10.9713C128.826 9.70301 127.205 9.17212 125.229 9.17212C122.249 9.17212 120.008 10.8533 120.008 13.4488C120.008 16.2507 122.073 17.3715 124.461 18.1678C126.32 18.7872 127.057 19.1116 127.057 19.731C127.057 20.4388 126.437 20.9108 125.317 20.9108C123.724 20.9108 122.485 20.3503 121.306 19.2591L119.448 21.943C121.011 23.2998 122.987 24.0665 125.287 24.0665Z",
      fill: "#344563"
    }), _jsx("path", {
      d: "M146.296 16.4571C146.296 12.2395 143.465 9.17212 139.395 9.17212C135.118 9.17212 132.08 12.1805 132.08 16.5751C132.08 21.0877 135.206 24.0665 139.631 24.0665C142.845 24.0665 144.704 22.8868 145.913 21.3237L143.406 18.9936C142.639 19.9374 141.518 20.6157 139.896 20.6157C137.949 20.6157 136.564 19.5835 136.092 17.8433H146.208C146.238 17.6074 146.296 16.929 146.296 16.4571ZM139.365 12.5344C141.046 12.5344 142.079 13.5372 142.463 15.0709H136.121C136.564 13.4193 137.802 12.5344 139.365 12.5344Z",
      fill: "#344563"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AgorapulseLogo_svg__clip0_11055_126668",
      children: _jsx("rect", {
        width: 146.296,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'AgorapulseLogo', 'Company');
export default AgorapulseLogo;