/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FidelityLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 119,
  height: 32,
  viewBox: "0 0 119 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M19.7985 11.0163H12.077L13.0667 7.42519H20.7881L22.2341 2.41779H7.09925L1.18518 23.52H8.56888L10.7733 15.6267H18.5007L19.7985 11.0163Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M22.6844 8.10669L18.3585 23.52H25.6533L29.9852 8.10669H22.6844Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M31.5674 2.41779H24.2667L23.0815 6.63112H30.3763L31.5674 2.41779Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M43.2 2.41779L41.1615 9.85482C40.5689 8.72297 39.04 7.8163 36.9778 7.8163C34.5719 7.8163 32.5393 8.8889 31.4074 10.957C29.8489 13.7304 28.8059 17.6889 28.8059 20.3496C28.8059 22.7556 30.3052 23.8874 32.6815 23.8874C35.0281 23.8874 36.4978 23.0104 37.9437 21.7956L37.4341 23.52H44.5926L50.5067 2.41779H43.2ZM38.7852 18.3704C38.477 18.5659 38.1096 18.7378 37.5407 18.7378C36.8356 18.7378 36.4089 18.2282 36.4089 17.6356C36.4089 16.3615 36.8889 15.0874 37.4281 13.7896C37.7956 12.883 38.4474 12.6874 39.0993 12.6874C39.5496 12.6874 40.0059 12.9422 40.2607 13.3096L38.7852 18.3704Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M65.1911 16.7882C65.5881 15.5141 65.9852 13.4519 65.9852 12.5156C65.9852 8.66963 63.0459 7.73334 58.5185 7.73334C55.5793 7.73334 52.3793 8.64 50.5422 11.04C49.0133 13.0489 47.8578 16.8356 47.8578 19.1289C47.8578 23.2889 51.2237 23.9111 55.5496 23.9111C58.7437 23.9111 63.9526 22.4119 64.8533 18.0267H57.6948C57.2681 19.5259 56.8178 19.923 55.7985 19.923C54.7793 19.923 54.7259 19.1289 54.7259 18.8207C54.7259 18.1689 54.9215 17.6059 55.123 16.7822H65.1911V16.7882ZM57.8963 11.6148C58.6607 11.6148 59.1704 11.8696 59.1704 12.717C59.1704 13.0015 59.0578 13.4815 58.9748 13.7896H55.9763C56.3674 12.237 56.9896 11.6148 57.8963 11.6148Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M71.1407 2.41779L65.2267 23.52H72.5274L78.4356 2.41779H71.1407Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M88.083 2.41779H80.7822L79.5911 6.63112H86.8918L88.083 2.41779Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M79.2 8.10669L74.8681 23.52H82.1689L86.4948 8.10669H79.2Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M111.686 20.4207C110.59 20.4207 109.701 21.3096 109.701 22.4059C109.701 23.5022 110.59 24.3911 111.686 24.3911C112.776 24.3911 113.671 23.5022 113.671 22.4059C113.671 21.3096 112.782 20.4207 111.686 20.4207ZM111.686 24.0356C110.785 24.0356 110.056 23.3067 110.056 22.4059C110.056 21.5052 110.785 20.7763 111.686 20.7763C112.587 20.7763 113.316 21.5052 113.316 22.4059C113.321 23.3067 112.587 24.0356 111.686 24.0356Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M112.48 21.9911C112.48 21.5941 112.249 21.3748 111.757 21.3748H110.987V23.4193H111.342V22.5837H111.615L112.124 23.4193H112.521L111.988 22.5541C112.273 22.5244 112.48 22.3467 112.48 21.9911ZM111.348 22.2696V21.6948H111.621C111.852 21.6889 112.107 21.7067 112.107 21.9793C112.107 22.2519 111.852 22.2696 111.621 22.2696H111.348Z",
    fill: "#008900"
  }), _jsx("path", {
    d: "M109.861 8.10667L105.902 16.6519L106.015 8.10667H100.012H98.6311H96.0474L97.0074 4.68149H89.7067L85.7481 18.7378C85.523 19.5319 85.4341 20.237 85.4341 20.8889C85.4341 22.4711 86.2518 23.52 88.403 23.52H94.9096L95.9822 19.7274H94.4237C93.4341 19.7274 93.1496 19.5022 93.1496 18.8207C93.1496 18.4533 93.2622 17.8904 93.4044 17.4341L95.0459 11.5793H98.9393L100.036 23.5141C99.8578 24.4148 99.5556 24.9245 98.9926 25.1793C98.3704 25.4578 96.9718 25.4045 96.48 25.3807L96.3911 25.3867L95.2296 29.5763L100.213 29.5822C103.153 29.5822 104.599 28.1007 106.69 24.8237L117.333 8.10667H109.861Z",
    fill: "#008900"
  })]
}), 'FidelityLogo', 'Company');
export default FidelityLogo;