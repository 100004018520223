/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ShiftLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 80,
  height: 32,
  viewBox: "0 0 80 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M13.2197 13.3403H9.16932C8.98437 13.3403 8.84422 13.1734 8.87634 12.9913L9.41344 9.9475C9.43855 9.8054 9.56207 9.70178 9.70642 9.70178H15.9374C17.301 9.70178 18.4678 8.72331 18.7049 7.381L19.1723 4.7348C19.2045 4.55272 19.0643 4.38574 18.8794 4.38574H7.35281C5.44356 4.38574 3.80984 5.75601 3.47821 7.63562L2.30343 14.2936C1.90183 16.5696 3.65334 18.6564 5.96538 18.6564H10.0284C10.2134 18.6564 10.3535 18.8234 10.3214 19.0055L9.78362 22.0502C9.75852 22.1923 9.635 22.2959 9.4907 22.2959H3.23949C1.87592 22.2959 0.709127 23.2744 0.472022 24.6167L0.00461531 27.2629C-0.0275601 27.445 0.112586 27.612 0.297541 27.612H11.8308C13.7397 27.612 15.3733 26.2421 15.7053 24.3629L16.8815 17.7039C17.2836 15.4276 15.532 13.3403 13.2197 13.3403Z",
    fill: "#353535"
  }), _jsx("path", {
    d: "M53.6663 18.9025L52.1709 27.3682C52.1458 27.5103 52.0223 27.6139 51.8778 27.614H46.1386C45.9537 27.614 45.8135 27.447 45.8457 27.2649L49.8436 4.6314C49.8687 4.4893 49.9922 4.38574 50.1366 4.38574H79.0662C79.2511 4.38574 79.3913 4.55272 79.3591 4.7348L78.8917 7.381C78.6546 8.72331 77.4878 9.70178 76.1242 9.70178H72.7488C72.6045 9.70178 72.481 9.80535 72.4559 9.94744L69.3787 27.3683C69.3535 27.5104 69.2301 27.6139 69.0857 27.6139H63.3466C63.1616 27.6139 63.0215 27.4469 63.0536 27.2648L66.0943 10.0508C66.1265 9.86876 65.9864 9.70178 65.8014 9.70178H55.5411C55.3968 9.70178 55.2732 9.80535 55.2481 9.94744L54.7104 12.9917C54.6782 13.1738 54.8183 13.3408 55.0033 13.3408H60.5139C60.6989 13.3408 60.8391 13.5078 60.8069 13.6899L59.9725 18.4109C59.9474 18.5531 59.8237 18.6569 59.6792 18.6569H53.9592C53.8149 18.6569 53.6914 18.7604 53.6663 18.9025Z",
    fill: "#353535"
  }), _jsx("path", {
    d: "M36.8244 27.2649L40.8224 4.63183C40.8475 4.48974 40.971 4.38612 41.1153 4.38623H46.8545C47.0394 4.38623 47.1796 4.5532 47.1474 4.73529L43.1494 27.3684C43.1243 27.5105 43.0009 27.614 42.8565 27.614H37.1174C36.9324 27.614 36.7923 27.447 36.8244 27.2649Z",
    fill: "#353535"
  }), _jsx("path", {
    d: "M30.4252 13.0952L31.9201 4.63183C31.9452 4.48974 32.0687 4.38612 32.2131 4.38634H37.9522C38.1371 4.38634 38.2773 4.55331 38.2451 4.7354L34.2471 27.3685C34.222 27.5106 34.0986 27.6141 33.9542 27.6141H28.215C28.0301 27.6141 27.8899 27.4471 27.9221 27.265L29.381 19.0061C29.4131 18.824 29.2731 18.6571 29.088 18.6571H25.4778C25.3335 18.6571 25.21 18.7606 25.1849 18.9027L23.6895 27.3684C23.6644 27.5105 23.5409 27.6141 23.3966 27.6141H17.6574C17.4724 27.6141 17.3323 27.4471 17.3645 27.265L21.3624 4.63189C21.3875 4.48979 21.511 4.38623 21.6554 4.38623H27.3946C27.5795 4.38623 27.7197 4.5532 27.6875 4.73529L26.2291 12.9918C26.1969 13.1739 26.337 13.3409 26.522 13.3409H30.1322C30.2765 13.3409 30.4001 13.2373 30.4252 13.0952Z",
    fill: "#353535"
  })]
}), 'ShiftLogo', 'Company');
export default ShiftLogo;