/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BabylistLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 146,
  height: 32,
  viewBox: "0 0 146 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("mask", {
    id: "BabylistLogo_svg__mask0_1362_10682",
    style: {
      maskType: 'luminance'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 0,
    width: 145,
    height: 32,
    children: _jsx("path", {
      d: "M144.667 0H0.000976562V31.6594H144.667V0Z",
      fill: "white"
    })
  }), _jsxs("g", {
    mask: "url(#BabylistLogo_svg__mask0_1362_10682)",
    children: [_jsx("path", {
      d: "M3.72175 4.37987C6.35961 2.77785 10.2963 3.81278 12.1594 6.60032C12.3555 6.89379 12.538 7.19635 12.7682 7.56017C13.7975 5.46133 15.4253 4.23737 17.6245 3.74971C21.1312 2.97213 24.9421 5.56961 25.4081 9.08151C25.8137 12.1378 24.6853 14.7252 22.8678 17.0774C22.3 17.8122 21.7247 18.5547 20.9206 19.0656C20.7306 18.8764 20.7306 18.6293 20.7467 18.3944C20.795 17.6886 20.6252 17.0223 20.4124 16.3623C20.3378 16.0964 20.265 15.8292 20.1328 15.5835C20.03 15.3402 19.8481 15.1511 19.689 14.9461C19.5442 14.7157 19.3631 14.521 19.1299 14.376C17.4125 13.2117 15.542 13.2173 13.6113 13.6372C12.5588 13.8661 11.562 14.2916 10.5034 14.4972C9.55512 14.7198 8.60049 14.8842 7.62088 14.8216C7.54248 14.8219 7.46444 14.813 7.38748 14.7987C6.7735 14.6312 6.1159 14.6389 5.53071 14.3502C5.18547 14.1636 4.82629 13.9974 4.54122 13.7161C3.99071 13.1732 3.44007 12.6297 3.157 11.8842C2.98781 11.4043 2.83743 10.919 2.73288 10.4201C2.69663 9.93937 2.49041 9.46381 2.68489 8.97581C2.82464 8.60197 2.83835 8.19005 3.02531 7.83005C3.29802 7.23258 3.48466 6.59093 3.91476 6.0731C4.1806 5.65957 4.55144 5.3228 4.83926 4.83882C4.38755 4.8122 3.96122 4.79198 3.72175 4.37987Z",
      fill: "#FE7BAB"
    }), _jsx("path", {
      d: "M97.5411 19.9409C98.6105 17.5636 99.6154 15.3352 100.616 13.1049C101.304 11.5718 101.997 10.0412 102.661 8.49786C102.827 8.11288 103.028 7.93626 103.464 7.96444C104.063 8.00315 104.668 8.00475 105.266 7.96594C105.883 7.92594 105.878 8.14751 105.657 8.61724C104.475 11.1386 103.317 13.6707 102.146 16.1972C99.8549 21.1414 97.5545 26.0814 95.2789 31.0326C95.0698 31.4871 94.8391 31.6897 94.3204 31.656C93.5627 31.6067 92.7999 31.6433 91.9395 31.6433C92.5078 30.4247 93.0306 29.2985 93.5571 28.1741C94.2895 26.6087 95.0084 25.0365 95.7702 23.4855C95.9741 23.0711 95.9504 22.7532 95.7609 22.3466C93.6433 17.7984 91.5442 13.2412 89.4425 8.68568C89.1163 7.97926 89.1375 8.08011 89.9085 7.95319C91.6025 7.67438 92.4226 8.36052 93.0487 9.94782C94.2807 13.0701 95.7733 16.0898 97.1596 19.1513C97.2577 19.3681 97.3661 19.5802 97.5411 19.9409Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M125.586 24.4492C123.45 24.4145 121.314 23.543 119.563 21.7369C119.219 21.3812 119.205 21.1363 119.581 20.8081C119.942 20.4937 120.283 20.1456 120.579 19.77C120.884 19.3826 121.061 19.4877 121.351 19.7991C122.917 21.4836 124.83 22.2359 127.132 21.8184C127.979 21.6647 128.759 21.3475 129.195 20.5273C129.737 19.5103 129.352 18.3875 128.267 17.7803C127.39 17.2894 126.396 17.1817 125.444 16.9464C124.37 16.6813 123.287 16.454 122.307 15.9092C120.745 15.041 119.91 13.7379 119.917 11.9306C119.924 10.0957 120.768 8.71631 122.377 7.91553C125.154 6.53401 127.835 6.89685 130.334 8.66108C131.825 9.71403 131.804 9.74247 130.549 11.0662C130.45 11.1708 130.333 11.2639 130.254 11.3818C129.972 11.8011 129.751 11.7017 129.43 11.382C128.134 10.0882 126.593 9.42985 124.73 9.72668C123.206 9.96952 122.357 11.0824 122.699 12.3879C122.951 13.3453 123.719 13.7541 124.572 14.0016C125.448 14.2555 126.345 14.4351 127.234 14.6437C128.031 14.8308 128.816 15.0536 129.55 15.4254C131.104 16.2119 132.064 17.4043 132.126 19.2038C132.19 21.0763 131.404 22.4998 129.814 23.4731C128.632 24.1969 127.318 24.4401 125.586 24.4492Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M137.152 15.4417C137.152 14.0485 137.128 12.6547 137.164 11.2624C137.177 10.7209 137.066 10.4832 136.464 10.5281C135.722 10.5833 134.974 10.5263 134.228 10.5456C133.839 10.5557 133.69 10.4416 133.675 10.0195C133.601 7.93938 133.587 7.9399 135.671 7.93856C137.155 7.93758 137.245 7.93035 137.124 6.42904C137.034 5.31079 137.385 4.67039 138.446 4.36864C138.963 4.22138 139.441 3.58461 139.901 3.82848C140.13 3.94957 139.973 4.80822 139.978 5.33329C139.985 6.02981 140 6.72736 139.971 7.42274C139.954 7.84096 140.111 7.95951 140.512 7.95244C141.69 7.93159 142.869 7.96457 144.047 7.93453C144.517 7.92256 144.698 8.06037 144.663 8.54042C144.627 9.01815 144.629 9.50248 144.662 9.98052C144.694 10.4351 144.507 10.5568 144.079 10.549C142.901 10.5277 141.722 10.5581 140.545 10.5329C140.115 10.5237 139.962 10.6389 139.967 11.0924C139.989 13.7825 139.966 16.473 139.99 19.1631C140.007 21.1585 140.869 21.9432 142.868 21.8553C143.061 21.8469 143.253 21.8386 143.444 21.8162C144.248 21.7219 144.65 22.0951 144.65 22.9358C144.651 24.1272 144.649 24.1185 143.444 24.3345C142.703 24.4674 141.957 24.4934 141.217 24.3947C138.894 24.0846 137.524 22.6653 137.235 20.3379C137.134 19.5234 137.154 18.7078 137.149 17.8917C137.144 17.0751 137.148 16.2584 137.148 15.4417H137.152Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M107.851 11.8185C107.851 8.16889 107.864 4.51913 107.838 0.869642C107.834 0.272766 107.928 -0.0378504 108.61 0.0590013C109.243 0.148963 110.068 -0.209107 110.477 0.21159C110.85 0.596166 110.585 1.40152 110.586 2.02038C110.593 8.98355 110.578 15.9468 110.608 22.9098C110.612 23.6274 110.407 23.8395 109.728 23.7528C109.138 23.6773 108.375 23.9937 107.986 23.6319C107.589 23.262 107.861 22.4943 107.858 21.9031C107.844 18.5417 107.851 15.1801 107.851 11.8185Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M113.911 15.7831C113.911 13.3806 113.925 10.978 113.899 8.57581C113.894 8.06412 114.056 7.89173 114.554 7.93787C115.219 7.99955 116.076 7.66845 116.507 8.06758C116.948 8.4764 116.643 9.34143 116.645 10.0041C116.66 14.3285 116.637 18.6531 116.67 22.9773C116.674 23.631 116.488 23.8241 115.865 23.7538C115.248 23.6841 114.433 24.0044 114.053 23.6338C113.635 23.2278 113.923 22.4047 113.918 21.7652C113.899 19.7713 113.911 17.7772 113.911 15.7831Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M20.512 16.2126C21.0291 17.1093 20.9417 18.0923 20.9202 19.0659C20.8811 19.0873 20.8399 19.1035 20.7966 19.1143C20.5611 18.8977 20.6062 18.6086 20.5931 18.335C20.5579 17.5522 20.5549 17.6112 19.7635 17.5701C18.0147 17.4791 16.2643 17.4996 14.518 17.5209C12.5529 17.5448 10.5881 17.5185 8.62372 17.5226C7.06857 17.5258 5.51358 17.5909 3.95846 17.5517C3.69162 17.545 3.43039 17.5542 3.18083 17.6599C2.81754 17.3426 2.52233 16.971 2.29207 16.5477C2.26966 16.1024 2.60818 16.0637 2.91504 16.0177C3.27179 15.9643 3.63442 15.9898 3.99456 15.9898C7.5015 15.9904 11.0089 15.9573 14.5153 16.0115C16.0283 16.0348 17.5375 15.9438 19.0485 15.9682C19.544 15.9762 20.0656 15.8876 20.512 16.2126Z",
      fill: "#FE5269"
    }), _jsx("path", {
      d: "M3.18164 17.6588C3.19795 17.5465 3.16785 17.329 3.27489 17.3503C3.85772 17.4666 4.43679 17.239 5.02442 17.3831C5.4364 17.4842 5.87862 17.3611 6.30272 17.3432C8.23558 17.2617 10.1677 17.3748 12.1017 17.2921C13.5167 17.2315 14.9426 17.293 16.3605 17.3C17.4204 17.3051 18.52 17.158 19.5781 17.4543C19.6177 17.4654 19.6681 17.4349 19.714 17.4269C20.735 17.249 20.7859 17.2874 20.7905 18.2358C20.6407 18.5491 20.3897 18.6825 20.0447 18.7064C18.4085 18.8194 16.7843 18.7236 15.1637 18.4858C14.4974 18.388 13.8607 18.6769 13.1908 18.6781C12.1839 18.6798 11.1708 18.6216 10.177 18.6526C9.30303 18.6798 8.43986 18.5157 7.52982 18.6604C6.45514 18.8313 5.29462 18.7614 4.17192 18.7072C3.78489 18.4114 3.45744 18.0595 3.18164 17.6588Z",
      fill: "#FE4F64"
    }), _jsx("path", {
      d: "M20.5125 16.2125C18.8673 16.2472 17.208 16.3512 15.5794 16.2977C11.4373 16.1616 7.29809 16.2914 3.1587 16.2189C2.83237 16.2132 2.46552 16.1346 2.29257 16.5475C2.00907 16.2329 1.74278 15.9072 1.6084 15.4959C2.30442 15.1945 3.04497 15.1635 3.78032 15.1992C4.97463 15.2574 6.16271 15.2904 7.35259 15.1169C7.70139 15.066 8.07233 15.0935 8.42113 15.2031C8.7001 15.2906 8.98733 15.3263 9.27424 15.3014C10.8963 15.1611 12.523 15.2866 14.1459 15.2094C14.4875 15.1931 14.8054 15.0968 15.1154 14.9627C15.2204 14.9284 15.3283 14.9125 15.4385 14.9142C16.101 15.2566 16.7678 15.5065 17.4672 14.9976C17.5413 14.9845 17.614 14.9889 17.6862 15.0085C18.2694 15.2016 18.8614 15.3303 19.4797 15.2321C19.7776 15.1847 20.0441 15.2034 20.217 15.4952C20.4369 15.6844 20.508 15.9347 20.5125 16.2125Z",
      fill: "#FE566F"
    }), _jsx("path", {
      d: "M4.1709 18.7088C4.98324 18.4806 5.80391 18.4219 6.64669 18.5116C7.08711 18.5584 7.57758 18.7596 7.95554 18.303C8.16489 18.0502 8.42274 18.188 8.59461 18.3258C8.78083 18.4751 8.98873 18.6018 9.17676 18.5126C9.98145 18.1308 10.7645 18.569 11.5595 18.5542C12.3861 18.539 13.3566 18.6804 14.0085 18.4185C14.8959 18.0618 15.6065 18.4561 16.3783 18.4448C17.5323 18.4278 18.6931 18.6042 19.8609 18.5359C20.213 18.5153 20.5176 18.455 20.7896 18.2373C20.7918 18.5298 20.7941 18.8223 20.7964 19.1148C20.7617 19.3626 20.5745 19.4704 20.3781 19.5698C18.6291 19.6055 16.8817 19.6155 15.1311 19.5819C13.1431 19.5437 11.1507 19.6356 9.16107 19.5878C7.84019 19.5561 6.52282 19.5476 5.20612 19.6617C4.80022 19.41 4.47401 19.0719 4.1709 18.7088Z",
      fill: "#FE4C5E"
    }), _jsx("path", {
      d: "M5.20703 19.6601C5.25116 19.3497 5.49054 19.3879 5.70241 19.3887C8.4396 19.3986 11.1768 19.4173 13.9139 19.4191C15.8024 19.4204 17.6908 19.3943 19.5793 19.3927C19.8511 19.3924 20.1639 19.2807 20.379 19.5682C20.0378 19.949 19.6761 20.3044 19.1981 20.5149C16.2763 20.6747 13.349 20.4843 10.4297 20.6253C9.0587 20.6915 7.70086 20.4903 6.33632 20.5863C5.90608 20.343 5.52966 20.0342 5.20703 19.6601Z",
      fill: "#FE4959"
    }), _jsx("path", {
      d: "M6.33594 20.5872C6.4098 20.4315 6.49078 20.2775 6.69955 20.2997C8.35462 20.4752 10.0146 20.2967 11.6607 20.3372C13.7811 20.3894 15.9042 20.2351 18.018 20.4168C18.4094 20.4504 18.8567 19.9623 19.1977 20.5159C18.7125 20.9586 18.2488 21.4309 17.6145 21.6698C16.1101 21.6468 14.6068 21.6596 13.1019 21.6881C11.9073 21.7107 10.7084 21.5896 9.50729 21.6364C8.97909 21.657 8.45439 21.713 7.92691 21.732C7.33609 21.4343 6.81361 21.0418 6.33594 20.5872Z",
      fill: "#FE4553"
    }), _jsx("path", {
      d: "M117.191 2.68543C117.177 3.74456 116.308 4.57998 115.239 4.56149C114.209 4.54367 113.375 3.70314 113.366 2.67326C113.357 1.6193 114.222 0.758979 115.287 0.761725C116.352 0.764471 117.205 1.62631 117.191 2.68543Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M7.92676 21.7327C8.60975 21.1587 9.43009 21.4898 10.1798 21.4327C11.0034 21.37 11.8398 21.4116 12.6786 21.5052C13.3567 21.5808 14.0874 21.5103 14.7804 21.4366C15.6605 21.3429 16.5288 21.5935 17.401 21.4274C17.5352 21.4018 17.5898 21.5469 17.6144 21.6704C17.1403 21.9799 16.6663 22.2894 16.1923 22.5989C13.968 22.7529 11.7347 22.5035 9.51251 22.749C8.95592 22.4538 8.40113 22.1558 7.92676 21.7327Z",
      fill: "#FE424E"
    }), _jsx("path", {
      d: "M9.5127 22.7494C9.63827 22.5453 9.82408 22.5492 10.047 22.511C10.9134 22.3628 11.8036 22.4328 12.6583 22.4063C13.3735 22.384 14.1687 22.3396 14.9206 22.4941C15.3324 22.5787 15.7945 22.2013 16.1924 22.5992C15.1949 23.3017 14.0869 23.8028 13.0049 24.3523C12.7779 24.4676 12.5991 24.4054 12.4 24.3065C11.4202 23.8194 10.4291 23.3536 9.5127 22.7494Z",
      fill: "#FE404A"
    }), _jsx("path", {
      d: "M15.162 15.0638C14.917 15.3918 14.5083 15.4372 14.1893 15.4314C12.452 15.4 10.7155 15.4011 8.98281 15.5137C8.60206 15.5384 8.33099 15.2996 7.95731 15.3146C7.01935 15.3524 6.08733 15.4631 5.13958 15.4202C3.966 15.367 2.78558 15.4643 1.60811 15.4966C1.55254 15.4046 1.49698 15.3128 1.44141 15.2208C1.70196 14.8526 2.27081 14.6254 2.88918 14.6477C3.81581 14.681 4.73455 14.8192 5.669 14.7798C6.24005 14.7557 6.80299 14.699 7.36753 14.6309C7.41595 14.6316 7.46431 14.6324 7.51272 14.6332C8.09677 14.7217 8.6802 14.8136 9.27416 14.7283C9.75334 14.6594 10.2252 14.7327 10.7004 14.7938C11.7029 14.9227 12.7012 14.8046 13.6999 14.7141C14.2221 14.6667 14.7557 14.6103 15.162 15.0638Z",
      fill: "#FE5872"
    }), _jsx("path", {
      d: "M3.32529 11.8916C3.60994 12.5791 4.17612 13.056 4.62248 13.6226C4.41775 13.8113 4.17493 13.7363 3.93999 13.7227C2.86587 13.6606 1.78943 13.698 0.71498 13.649C0.463733 13.1537 0.281194 12.6393 0.2785 12.0752C0.193421 11.8939 0.222273 11.6935 0.42042 11.6793C1.39669 11.6093 2.37843 11.5352 3.32529 11.8916Z",
      fill: "#FE5F7F"
    }), _jsx("path", {
      d: "M3.32515 11.8905C2.51052 11.8412 1.69041 11.965 0.876207 11.7681C0.69729 11.7248 0.324964 11.6798 0.27843 12.0738C0.106358 11.8514 0.134843 11.5916 0.141883 11.336C0.00886646 10.3803 0.147462 10.1468 1.05821 10.0724C1.68991 10.0208 2.35287 9.98349 2.89412 10.4483C3.0378 10.929 3.18148 11.4098 3.32515 11.8905Z",
      fill: "#FE6488"
    }), _jsx("path", {
      d: "M0.714844 13.6484C0.744604 13.5063 0.787613 13.3239 0.961719 13.3654C2.16916 13.653 3.42024 13.2912 4.62237 13.6219C4.91385 13.8124 5.20536 14.0028 5.49687 14.1933C5.5091 14.5392 5.41728 14.7412 4.99752 14.6608C4.05897 14.4812 3.11488 14.7074 2.17275 14.6561C1.84792 14.6384 1.57641 14.8056 1.29679 14.9384C1.03126 14.5406 0.755148 14.1475 0.714844 13.6484Z",
      fill: "#FE5B77"
    }), _jsx("path", {
      d: "M2.89433 10.4483C2.31101 10.3794 1.72657 10.248 1.14467 10.2583C0.503222 10.2698 -0.0291906 10.509 0.142128 11.3357C-0.0688602 10.5589 -0.0238847 9.78266 0.149163 9.00687C0.93381 8.51851 2.3321 8.51856 2.89316 9.00692C2.55579 9.48759 3.01435 9.9677 2.89433 10.4483Z",
      fill: "#FE688F"
    }), _jsx("path", {
      d: "M2.8934 9.00745C1.97874 8.71387 1.06408 8.86892 0.149414 9.0074C0.191886 8.38107 0.350415 7.79104 0.69751 7.26004C1.40957 7.03904 2.12516 7.19842 2.8363 7.25731C3.17821 7.28559 3.36264 7.48409 3.18186 7.85395C3.0857 8.23842 2.98956 8.62293 2.8934 9.00745Z",
      fill: "#FE6C95"
    }), _jsx("path", {
      d: "M3.18155 7.85475C3.21801 7.43157 2.93945 7.4453 2.63242 7.41366C1.98818 7.34718 1.32654 7.50011 0.697266 7.26089C0.824739 6.93511 0.996208 6.63797 1.25941 6.40071C1.95512 5.63715 2.85982 5.73392 3.75243 5.81093C3.90059 5.82367 4.08643 5.89232 4.04773 6.12473C3.75901 6.7014 3.47028 7.27807 3.18155 7.85475Z",
      fill: "#FE719D"
    }), _jsx("path", {
      d: "M4.04786 6.12511C3.07249 5.75277 2.15551 5.96882 1.25977 6.40113C1.83801 5.43282 2.74411 4.86303 3.7221 4.38086C4.11734 4.45193 4.45429 4.87243 4.91633 4.57093C4.98712 4.52474 5.12502 4.55701 5.13177 4.67563C5.13631 4.75564 5.08303 4.85217 5.02893 4.92009C4.70622 5.32516 4.37574 5.72407 4.04786 6.12511Z",
      fill: "#FE76A5"
    }), _jsx("path", {
      d: "M1.29688 14.9384C1.46492 14.2927 2.05979 14.4398 2.43436 14.4653C3.17311 14.5158 3.89436 14.3897 4.62382 14.4022C4.73906 14.4042 4.90715 14.3528 4.96121 14.4103C5.35062 14.824 5.31826 14.2559 5.4969 14.1934C6.12053 14.3388 6.74412 14.4843 7.36771 14.6298C6.98567 15.1071 6.40513 14.952 5.94495 14.9665C4.85865 15.0008 3.76784 14.8568 2.67767 14.8229C2.23594 14.8092 1.84529 15.0589 1.4416 15.2198C1.39335 15.126 1.34512 15.0322 1.29688 14.9384Z",
      fill: "#FE5873"
    }), _jsx("path", {
      d: "M10.542 14.3409C12.4017 13.7478 14.2442 13.0504 16.2477 13.1951C17.3828 13.277 18.4642 13.547 19.3409 14.3438C18.6187 14.88 17.7476 14.7826 16.9515 14.7813C15.197 14.7786 13.4424 14.637 11.6851 14.6427C11.2751 14.644 10.8703 14.6414 10.542 14.3409Z",
      fill: "#FE5B78"
    }), _jsx("path", {
      d: "M10.5416 14.3418C12.1787 14.5761 13.8338 14.4639 15.4801 14.5811C16.7494 14.6716 18.0695 14.6677 19.3406 14.3446C19.5765 14.4672 19.7593 14.6323 19.7956 14.9141C19.6951 15.0682 19.5316 15.1629 19.3726 15.1098C18.7875 14.9149 18.1959 15.2275 17.6157 15.0633C17.5676 15.0633 17.5195 15.0633 17.4714 15.0633C16.9493 15.1706 16.4282 15.1395 15.9135 15.0262C15.7467 14.9894 15.5955 14.9217 15.4506 15.0632H15.162C14.3539 14.7359 13.5307 14.9517 12.7098 14.9839C10.9661 15.0525 9.22655 15.0209 7.5127 14.6325C8.53928 14.7117 9.53417 14.4615 10.5416 14.3418Z",
      fill: "#FE5873"
    }), _jsx("path", {
      d: "M17.6162 15.0626C18.3216 14.7049 19.0684 14.9502 19.796 14.9134C20.0165 15.0492 20.1991 15.2123 20.2171 15.4945C19.5655 15.2106 18.8716 15.6471 18.2052 15.3671C17.9961 15.2792 17.7331 15.3266 17.6162 15.0626Z",
      fill: "#FE5872"
    }), _jsx("path", {
      d: "M75.0205 9.23644C79.7302 5.54524 84.9762 7.2849 87.3044 10.0551C90.0642 13.3391 90.1607 18.025 87.4355 21.3226C85.9067 23.173 83.9119 24.1992 81.5258 24.4074C79.1423 24.6154 77.035 23.8558 75.1831 22.3989C75.1119 22.4663 75.0556 22.4936 75.0525 22.5253C74.9854 23.3322 74.5261 23.7357 73.675 23.7357C72.7109 23.7357 72.2288 23.243 72.2288 22.2575C72.2293 15.1231 72.2376 7.98869 72.2149 0.854402C72.2128 0.226127 72.3604 -0.0202933 73.0097 0.0608371C73.6471 0.140445 74.4781 -0.199669 74.8874 0.194943C75.3018 0.594753 75.0123 1.42575 75.0174 2.06623C75.0334 4.17999 75.0216 6.29393 75.0205 8.40784C75.0205 8.64108 75.0205 8.87432 75.0205 9.23644ZM86.8962 15.7296C86.8698 12.3845 84.156 9.69202 80.8094 9.69114C77.4205 9.69022 74.7165 12.4026 74.7269 15.7918C74.7372 19.1865 77.4515 21.8855 80.8363 21.8671C84.2133 21.8489 86.9225 19.105 86.8962 15.7296Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M36.8958 9.22126C39.0745 7.45739 41.5263 6.73538 44.2965 7.28557C46.2818 7.6799 47.918 8.66183 49.2033 10.2235C51.891 13.4894 51.8864 18.1605 49.176 21.3682C47.6272 23.201 45.6327 24.2188 43.2454 24.4112C40.8625 24.6032 38.7604 23.8346 36.8904 22.3382C36.6628 22.8144 37.0438 23.4473 36.6658 23.657C36.2943 23.8631 35.7308 23.7323 35.2514 23.7342C34.0144 23.739 34.0139 23.7357 34.0138 22.4826C34.0136 15.2781 34.0208 8.0737 34.001 0.869306C33.9993 0.265225 34.1078 -0.0242513 34.7801 0.0600429C35.4178 0.139981 36.2484 -0.195653 36.6603 0.188182C37.0805 0.579758 36.7873 1.41453 36.7926 2.05329C36.8103 4.19045 36.7948 6.32785 36.8001 8.46519C36.8007 8.69414 36.7318 8.936 36.8958 9.22126ZM36.4959 15.774C36.4973 19.1314 39.1769 21.8411 42.5217 21.8675C45.9096 21.8942 48.6515 19.1942 48.6631 15.82C48.6749 12.4218 45.9872 9.70416 42.6029 9.69213C39.2197 9.6801 36.4945 12.3941 36.4959 15.774Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M66.9451 22.3012C64.7423 24.0237 62.3644 24.7773 59.6666 24.2959C57.6496 23.936 55.9779 22.9638 54.6421 21.4175C51.9288 18.2765 51.8669 13.6057 54.4852 10.3326C56.0259 8.40741 58.0419 7.36658 60.4811 7.14114C62.8986 6.91776 65.0132 7.69799 66.8966 9.24504C67.0344 8.38744 67.535 7.95822 68.398 7.95755C69.3054 7.95678 69.7595 8.40534 69.7595 9.30325C69.7595 13.8893 69.7513 18.4753 69.7719 23.0612C69.774 23.5837 69.663 23.8261 69.1035 23.7415C68.8687 23.7059 68.6235 23.7356 68.3835 23.7356C67.486 23.7355 67.0065 23.2574 66.9451 22.3012ZM67.2811 15.768C67.2801 12.3903 64.5503 9.67605 61.1681 9.69045C57.8143 9.7047 55.1293 12.3935 55.1139 15.7533C55.0979 19.1377 57.807 21.8638 61.1887 21.8669C64.5714 21.8699 67.2821 19.156 67.2811 15.768Z",
      fill: "#6E3265"
    }), _jsx("path", {
      d: "M15.4512 15.0638C15.5112 14.9923 15.5844 14.8512 15.6292 14.8597C16.2388 14.9753 16.8748 14.852 17.472 15.0639C16.8678 15.6459 16.3715 15.667 15.7142 15.1499C15.6461 15.0963 15.5397 15.0914 15.4512 15.0638Z",
      fill: "#FE5872"
    })]
  })]
}), 'BabylistLogo', 'Company');
export default BabylistLogo;