/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BookingLogo = createCompanyLogosComponent(_jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 169,
  height: 32,
  viewBox: "0 0 169 32",
  fill: "none",
  children: [_jsx("path", {
    fill: "#273B7D",
    d: "M66.149 4.565A2.559 2.559 0 0 1 68.702 2a2.564 2.564 0 0 1 0 5.129 2.56 2.56 0 0 1-2.553-2.564Z"
  }), _jsx("path", {
    fill: "#499FDD",
    d: "M106.508 21.623a2.559 2.559 0 0 1 2.552-2.567 2.565 2.565 0 0 1 0 5.13 2.56 2.56 0 0 1-2.552-2.563Z"
  }), _jsx("path", {
    fill: "#273B7D",
    d: "M24.167 20.514c-2.203 0-3.734-1.75-3.734-4.25s1.531-4.247 3.734-4.247c2.216 0 3.764 1.748 3.764 4.247 0 2.54-1.516 4.25-3.764 4.25Zm0-12.233c-4.66 0-8.043 3.359-8.043 7.983 0 4.625 3.383 7.983 8.043 7.983 4.679 0 8.075-3.358 8.075-7.983 0-4.624-3.396-7.983-8.075-7.983ZM61.194 16.664a3.808 3.808 0 0 0-.61-.86l-.14-.149.148-.142c.214-.225.432-.493.643-.803l4.113-6.114h-4.993l-3.09 4.783c-.175.256-.528.386-1.057.386h-.704V4.723c0-1.809-1.127-2.055-2.344-2.055h-2.084l.004 21.343h4.424V17.61h.414c.505 0 .848.058 1.005.333l2.441 4.606c.682 1.25 1.361 1.464 2.64 1.464h3.39l-2.525-4.177-1.675-3.171ZM82.672 8.248c-2.25 0-3.686 1-4.49 1.845l-.27.271-.094-.37c-.236-.905-1.033-1.403-2.232-1.403h-1.983l.012 15.415h4.394v-7.105c0-.694.09-1.297.274-1.847.49-1.665 1.854-2.7 3.56-2.7 1.37 0 1.907.724 1.907 2.595v6.714c0 1.596.738 2.343 2.336 2.343h2.091l-.007-9.804c0-3.896-1.9-5.954-5.496-5.954M68.76 8.597h-2.083l.014 11.92v3.493h2.222c.028 0 .05.003.079.003l1.035-.003h1.03v-.007h.008l.009-13.065c0-1.577-.754-2.34-2.313-2.34M41.427 20.514c-2.203 0-3.737-1.75-3.737-4.25s1.534-4.247 3.737-4.247c2.211 0 3.763 1.748 3.763 4.247 0 2.54-1.515 4.25-3.763 4.25Zm0-12.233c-4.665 0-8.048 3.359-8.048 7.983 0 4.625 3.383 7.983 8.048 7.983 4.673 0 8.075-3.358 8.075-7.983 0-4.624-3.402-7.983-8.075-7.983Z"
  }), _jsx("path", {
    fill: "#499FDD",
    d: "M135.615 20.514c-2.202 0-3.737-1.75-3.737-4.25s1.535-4.247 3.737-4.247c2.211 0 3.764 1.748 3.764 4.247 0 2.54-1.516 4.25-3.764 4.25Zm0-12.233c-4.664 0-8.047 3.359-8.047 7.983 0 4.625 3.383 7.983 8.047 7.983 4.674 0 8.075-3.358 8.075-7.983 0-4.624-3.401-7.983-8.075-7.983Z"
  }), _jsx("path", {
    fill: "#273B7D",
    d: "M97.435 19.836c-2.402 0-3.257-2.096-3.257-4.06 0-.865.219-3.686 3.026-3.686 1.396 0 3.255.4 3.255 3.831 0 3.236-1.645 3.915-3.024 3.915Zm5.306-11.28c-.834 0-1.476.333-1.797.94l-.122.236-.204-.177c-.709-.614-1.98-1.342-4.046-1.342-4.109 0-6.875 3.086-6.875 7.678 0 4.587 2.862 7.793 6.958 7.793 1.399 0 2.504-.326 3.381-.99l.339-.255v.429c0 2.059-1.332 3.195-3.746 3.195-1.173 0-2.24-.286-2.954-.545-.933-.281-1.479-.048-1.857.887l-.349.863-.493 1.26.305.163c1.545.82 3.556 1.31 5.374 1.31 3.742 0 8.112-1.916 8.112-7.307l.016-14.138h-2.042M7.803 20.337l-3.577-.003v-4.277c0-.914.354-1.389 1.137-1.498h2.44c1.74 0 2.866 1.098 2.867 2.874-.001 1.823-1.098 2.903-2.867 2.904ZM4.226 8.801V7.676c0-.986.417-1.454 1.331-1.514h1.83c1.57 0 2.51.94 2.51 2.51 0 1.2-.643 2.598-2.452 2.598h-3.22V8.8Zm8.145 4.268-.647-.363.565-.483c.656-.565 1.757-1.833 1.757-4.026 0-3.355-2.602-5.52-6.63-5.52H2.82v-.001h-.523A2.243 2.243 0 0 0 .134 4.892v19.042h2.11l.008.001 5.255-.001c4.477 0 7.367-2.437 7.367-6.212 0-2.032-.934-3.77-2.504-4.653"
  }), _jsx("path", {
    fill: "#499FDD",
    d: "M163.319 8.297a6.057 6.057 0 0 0-4.717 2.286l-.319.403-.249-.452c-.814-1.484-2.215-2.237-4.163-2.237-2.042 0-3.412 1.14-4.048 1.817l-.417.45-.16-.592c-.232-.854-.992-1.324-2.144-1.324h-1.847l-.018 15.355h4.197v-6.778c0-.594.073-1.181.222-1.796.401-1.638 1.502-3.4 3.352-3.223 1.142.11 1.699.991 1.699 2.694v9.103h4.226v-6.778c0-.742.069-1.298.237-1.855.34-1.563 1.489-3.167 3.274-3.167 1.292 0 1.77.732 1.77 2.697v6.859c0 1.551.692 2.244 2.243 2.244h1.975l.003-9.803c0-3.916-1.724-5.903-5.117-5.903M125.138 18.672c-.012.015-1.818 1.92-4.196 1.92-2.166 0-4.354-1.33-4.354-4.297 0-2.561 1.697-4.352 4.125-4.352.789 0 1.686.283 1.826.758l.021.08c.323 1.078 1.303 1.136 1.495 1.136l2.299.002v-2.01c0-2.65-3.372-3.612-5.641-3.612-4.852 0-8.372 3.378-8.372 8.03 0 4.65 3.482 8.024 8.284 8.024 4.165 0 6.431-2.738 6.452-2.766l.121-.148-1.821-3.02-.239.255Z"
  })]
}), 'BookingLogo', 'Company');
export default BookingLogo;