/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TaxfixLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 33,
  viewBox: "0 0 87 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.2625 16.4094H8.15412V22.3493C8.15412 23.659 8.86082 24.01 9.92089 24.01C10.3526 24.0033 10.7823 23.9496 11.2024 23.8499V27.8746C11.0104 27.97 10.2095 28.3222 8.67119 28.3222C5.37321 28.3222 3.35085 26.374 3.35085 23.2115V16.4094H0.526367V12.1632H1.3273C2.99396 12.1632 3.80079 11.0455 3.80079 9.57197V7.53076H8.15883V12.1632H11.2625V16.4094Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M21.5878 24.3425C23.543 24.3425 25.2097 22.8113 25.2097 20.1258C25.2097 17.3802 23.5748 15.9421 21.5878 15.9421C19.6337 15.9421 18.0625 17.3802 18.0625 20.1258C18.0625 22.7453 19.6973 24.3425 21.5878 24.3425ZM25.2391 26.035C24.5324 27.5662 22.9317 28.5262 20.9447 28.5262C16.3946 28.5262 13.1567 24.9809 13.1567 20.0951C13.1567 15.3673 16.2015 11.7324 20.7845 11.7324C23.6372 11.7324 24.8869 13.2966 25.2073 14.0316V12.2059H29.9187L29.9505 28.0786H25.2097L25.2415 26.194L25.2391 26.035Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M35.6038 28.3694L32.2705 25.4636L43.8711 12.0503L47.301 14.956L35.6038 28.3694Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M35.1232 12.273C35.6863 12.2697 36.2376 12.4337 36.7074 12.7441C37.1771 13.0546 37.5441 13.4975 37.7618 14.0168C37.9796 14.536 38.0382 15.1082 37.9304 15.6609C37.8226 16.2135 37.5531 16.7217 37.1561 17.121C36.7591 17.5203 36.2525 17.7927 35.7005 17.9038C35.1485 18.0148 34.576 17.9595 34.0555 17.7448C33.5349 17.53 33.0899 17.1656 32.7767 16.6977C32.4636 16.2298 32.2964 15.6794 32.2964 15.1163C32.2958 14.3649 32.5929 13.6438 33.1227 13.1109C33.6525 12.578 34.3718 12.2767 35.1232 12.273Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M51.3222 28.3257V16.5472H48.791V12.453H51.3222V11.0796C51.3222 7.4389 53.5295 4.94775 57.4164 4.94775C58.4105 4.94775 59.2751 5.07614 59.725 5.30111V9.25986C59.2514 9.14217 58.7641 9.08869 58.2762 9.10085C57.4105 9.10085 56.1938 9.48365 56.1938 11.2398V12.453H59.6543V16.5413H56.1938V28.3257H51.3222Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M62.4884 28.1951H67.3588V12.3224H62.4884V28.1951ZM64.8912 4.62515C65.2678 4.62127 65.6415 4.69178 65.9907 4.83264C66.34 4.9735 66.6581 5.18194 66.9266 5.446C67.1952 5.71005 67.4089 6.02454 67.5556 6.3714C67.7024 6.71827 67.7792 7.09069 67.7816 7.4673C67.7837 8.03035 67.619 8.58142 67.3085 9.05108C66.9979 9.52075 66.5553 9.88802 66.0365 10.1066C65.5176 10.3252 64.9456 10.3854 64.3926 10.2795C63.8396 10.1737 63.3303 9.90654 62.9288 9.51177C62.5273 9.117 62.2516 8.61225 62.1365 8.06109C62.0213 7.50994 62.0718 6.93703 62.2817 6.41454C62.4915 5.89204 62.8512 5.44332 63.3156 5.1249C63.78 4.80648 64.3282 4.6326 64.8912 4.62515Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M77.8849 23.4507L72.6941 28.625L69.521 25.4625L74.7129 20.2893L69.6494 15.2752L72.8213 12.1457L77.8531 17.1598L82.9803 12.0503L86.1534 15.2116L81.0262 20.3211L86.2182 25.4625L83.0451 28.625L77.8849 23.4507Z",
    fill: "#3CAA36"
  }), _jsx("path", {
    d: "M47.3327 25.2718C47.3176 26.0182 47.0104 26.729 46.4772 27.2515C45.9439 27.774 45.2271 28.0666 44.4806 28.0666C43.734 28.0666 43.0172 27.774 42.484 27.2515C41.9507 26.729 41.6436 26.0182 41.6284 25.2718C41.6436 24.5254 41.9507 23.8147 42.484 23.2922C43.0172 22.7697 43.734 22.4771 44.4806 22.4771C45.2271 22.4771 45.9439 22.7697 46.4772 23.2922C47.0104 23.8147 47.3176 24.5254 47.3327 25.2718Z",
    fill: "#3CAA36"
  })]
}), 'TaxfixLogo', 'Company');
export default TaxfixLogo;