/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BugHerdLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 90,
  height: 32,
  viewBox: "0 0 90 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.52429 11.2382C6.25854 11.1488 6.80019 10.6858 6.83781 9.97212C6.91373 8.53231 6.91618 7.08024 6.80333 5.64465C6.77375 5.26818 6.26849 4.73814 5.87908 4.62228C5.16909 4.41083 4.38237 4.45617 3.61064 4.39299V11.3477C4.27415 11.3128 4.90399 11.3139 5.52429 11.2382ZM3.61432 21.9881C4.48568 21.9319 5.32352 21.9418 6.13736 21.8069C6.92177 21.6769 7.4712 21.1274 7.51004 20.3277C7.57778 18.9326 7.58746 17.5285 7.5065 16.1348C7.45757 15.2936 6.89587 14.7102 6.03269 14.593C5.2551 14.4875 4.4621 14.4946 3.61432 14.4487V21.9881ZM8.72761 12.7883C10.6988 13.7719 11.2902 15.4464 11.2113 17.4769C11.1655 18.6525 11.2806 19.8514 11.0901 21.0012C10.6841 23.4507 8.71453 25.0512 6.21656 25.1015C4.71916 25.1318 3.22041 25.1179 1.72247 25.1043C0.696953 25.095 0.00754772 24.4225 0.00618472 23.3992C-0.00240217 16.5972 -0.00144807 9.79512 0.00536692 2.99307C0.00618472 2.07091 0.587368 1.31241 1.43897 1.30111C3.25012 1.27714 5.07968 1.21873 6.87025 1.43426C9.11211 1.70398 10.4672 3.38002 10.5371 5.64451C10.5756 6.89031 10.5767 8.13923 10.5377 9.38503C10.4939 10.7825 9.81678 11.8821 8.72761 12.7883Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M42.4211 14.6652C42.4211 17.6717 42.427 20.6185 42.4187 23.5651C42.4144 25.0631 40.9164 25.8939 39.6184 25.1318C38.9476 24.7381 38.7849 24.1108 38.7863 23.3892C38.7923 20.0095 38.7889 16.6296 38.7889 13.2498C38.7889 9.80199 38.7839 6.35406 38.7917 2.90627C38.7944 1.66592 39.4858 0.980934 40.6739 1.0004C41.7149 1.01742 42.4147 1.70894 42.4182 2.7629C42.427 5.30337 42.4211 7.84398 42.4211 10.3844C42.4212 10.6314 42.4211 10.8784 42.4211 11.1763H46.3855C46.3973 10.9252 46.4196 10.6645 46.42 10.4039C46.4227 7.88605 46.4132 5.36818 46.4259 2.85045C46.4319 1.66238 47.1318 0.989239 48.2945 1.0004C49.3502 1.01048 50.0465 1.68389 50.0506 2.74057C50.0605 5.2357 50.0539 7.73097 50.0539 10.2261C50.0539 14.6266 50.0547 19.0271 50.0535 23.4277C50.0531 24.704 49.4153 25.3853 48.2346 25.3802C47.0713 25.3751 46.4255 24.6779 46.4231 23.4012C46.4184 20.7699 46.4217 18.1386 46.4215 15.5075V14.6652H42.4211Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M30.5175 24.2895C30.5169 24.2974 30.5164 24.3054 30.5158 24.3133C30.3572 24.3133 30.1985 24.3132 30.0399 24.3133C29.158 24.3142 28.7818 24.6381 28.4805 25.6561C28.213 26.5596 28.4338 27.3585 29.0685 27.784C30.0061 28.4126 31.9681 28.2773 32.7731 27.5003C33.3353 26.9574 33.5369 26.293 33.3182 25.5179C33.101 24.7478 32.5283 24.4571 31.803 24.3777C31.3764 24.331 30.9461 24.3178 30.5175 24.2895ZM32.3277 13.6672H32.3251C32.3251 13.034 32.3581 12.3989 32.3174 11.7685C32.255 10.8025 31.659 10.2569 30.7447 10.2633C29.8426 10.2697 29.2387 10.8337 29.2088 11.8042C29.1705 13.0466 29.1703 14.2918 29.209 15.5341C29.2392 16.5009 29.8492 17.07 30.7471 17.0761C31.6778 17.0825 32.2719 16.5053 32.3221 15.4981C32.3525 14.8893 32.3277 14.2775 32.3277 13.6672ZM34.9454 10.0292C35.6871 10.8466 35.781 11.7997 35.7428 12.7882C35.6982 13.941 35.7806 15.115 35.5874 16.2421C35.2037 18.4818 33.3818 19.913 31.1104 19.9334C30.5247 19.9386 29.939 19.9343 29.1816 19.9343C29.1704 20.1887 29.0201 20.6379 29.1761 20.9151C29.324 21.1776 29.7829 21.3681 30.1227 21.3994C30.8883 21.4701 31.6648 21.4205 32.4368 21.4236C35.4094 21.4355 37.2425 23.5977 36.7775 26.5415C36.4258 28.7673 34.9317 30.2725 32.4848 30.7804C30.9329 31.1027 29.3657 31.0755 27.8302 30.6644C25.0507 29.9205 24.0068 26.9064 25.8016 24.8219C26.1054 24.4691 26.5058 24.1991 26.9136 23.8458C26.0043 23.2083 25.6205 22.3177 25.7119 21.2208C25.8014 20.1506 26.375 19.3725 27.2718 18.7255C25.8665 17.338 25.6879 15.6053 25.7831 13.7932C25.8328 12.8449 25.7813 11.8802 25.9409 10.9511C26.2961 8.88371 27.988 7.52886 30.0888 7.38957C31.6909 7.28337 33.2854 7.06267 34.8832 6.89275C35.0185 6.87832 35.1534 6.85776 35.2891 6.84768C36.4758 6.75987 36.9703 7.09875 37.0845 8.07578C37.2125 9.17154 36.7511 9.7668 35.6171 9.95918C35.4206 9.9924 35.2198 10.0013 34.9454 10.0292Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M79.486 16.4828C79.486 14.8728 79.4503 13.2615 79.5037 11.6533C79.5247 11.0232 79.2395 10.7442 78.7339 10.5507C77.5614 10.1022 76.6428 10.7257 76.637 12.0104C76.6237 14.9583 76.6289 17.9062 76.6392 20.8541C76.6402 21.143 76.6888 21.4457 76.7849 21.7173C76.9978 22.3183 77.6283 22.6049 78.3547 22.475C79.1747 22.3282 79.485 21.9927 79.4856 21.2447C79.4868 19.6574 79.486 18.07 79.486 16.4828ZM79.5338 23.9475C78.8255 24.8994 77.9703 25.4838 76.8213 25.5142C74.6667 25.5711 73.1679 24.1912 73.1473 22.031C73.1115 18.2646 73.1202 14.4975 73.1468 10.731C73.1557 9.45946 73.5971 8.34342 74.829 7.76518C76.126 7.1563 77.4277 7.22656 78.6566 7.99841C78.9214 8.16479 79.1456 8.39557 79.486 8.67727V7.43814C79.486 5.91786 79.4846 4.39744 79.4864 2.87716C79.4879 1.6266 80.0564 1.00711 81.2032 1.0003C82.3811 0.993357 82.9796 1.62238 82.9803 2.89922C82.9837 9.52495 82.9819 16.1507 82.9819 22.7765C82.9819 23.0715 82.9891 23.3668 82.9797 23.6614C82.9502 24.5853 82.4719 25.2242 81.7145 25.3628C80.7557 25.538 80.0498 25.1758 79.6642 24.3079C79.6225 24.2143 79.5939 24.1149 79.5338 23.9475Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.8504 23.9595C18.9595 25.4507 17.5934 25.732 16.061 25.4332C14.5482 25.1383 13.5529 23.8906 13.5356 22.1552C13.4923 17.7994 13.5108 13.4428 13.5248 9.08662C13.5277 8.20721 14.1599 7.59807 15.02 7.53694C16.1089 7.45946 16.8078 7.93777 16.9705 8.8907C17.0121 9.13428 17.011 9.38684 17.0111 9.63532C17.0136 13.1294 17.0119 16.6235 17.0144 20.1177C17.0145 20.4119 17.025 20.7072 17.05 21.0004C17.141 22.0735 17.78 22.5224 18.8187 22.2478C19.4024 22.0936 19.7273 21.8052 19.7217 21.1079C19.6906 17.2057 19.7035 13.303 19.709 9.40046C19.7107 8.20476 20.3101 7.55259 21.3893 7.53571C22.5648 7.51719 23.2004 8.13437 23.2013 9.32108C23.205 14.0404 23.2031 18.7597 23.2027 23.479C23.2027 24.6143 22.8389 25.1995 22.0368 25.359C20.9552 25.5743 20.29 25.1624 19.8504 23.9595Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M59.0792 14.7005C59.0792 13.5672 59.1325 12.5074 59.0614 11.4562C59.0095 10.6895 58.5049 10.3095 57.799 10.3126C57.1359 10.3156 56.5751 10.7047 56.5284 11.3901C56.454 12.4815 56.5085 13.5816 56.5085 14.7005H59.0792ZM56.4725 17.4835C56.4725 18.8208 56.4405 20.0655 56.4836 21.3078C56.5134 22.1678 57.0386 22.6433 57.8091 22.6395C58.594 22.6357 59.0722 22.1721 59.162 21.3238C59.174 21.2114 59.1736 21.0975 59.1768 20.9844C59.2108 19.8359 59.7475 19.2924 60.839 19.3021C61.9169 19.3115 62.4664 19.8874 62.4793 21.0205C62.5099 23.7341 60.7703 25.5112 58.047 25.5074C57.3953 25.5064 56.7236 25.471 56.0956 25.3139C54.2467 24.8513 53.0886 23.523 53.0176 21.4008C52.9092 18.1644 52.896 14.9162 53.0454 11.6823C53.1983 8.37326 55.7355 6.99308 58.8256 7.47424C61.1282 7.83273 62.488 9.48522 62.5674 11.8762C62.614 13.2802 62.5901 14.6872 62.5714 16.0926C62.5593 17.0134 62.0503 17.4791 61.1024 17.4823C59.5853 17.487 58.0682 17.4835 56.4725 17.4835Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M68.4113 9.26347C68.7038 7.95409 69.4692 7.42241 70.7034 7.53283C71.6788 7.61997 72.1593 8.21809 72.1343 9.3792C72.1114 10.4455 71.5556 11.0954 70.6267 11.0989C70.2906 11.1002 69.9534 11.0271 69.6181 10.9784C68.8543 10.8673 68.5911 11.0875 68.5909 11.8528C68.5904 15.7766 68.595 19.7005 68.5883 23.6243C68.586 24.9771 67.4223 25.7589 66.1501 25.2814C65.4075 25.0027 65.0961 24.4622 65.0955 23.4197C65.0936 18.7927 65.0937 14.1658 65.0954 9.53877C65.0956 8.37575 65.4936 7.79888 66.3647 7.68083C67.5361 7.52208 68.0433 7.88928 68.4113 9.26347Z",
    fill: "#222222"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M89.8386 23.2545C89.8299 24.5248 88.9524 25.3838 87.6825 25.3653C86.4672 25.3476 85.6299 24.4683 85.6343 23.2145C85.6388 21.9434 86.508 21.0975 87.7901 21.1164C89.0175 21.1344 89.8472 22.0004 89.8386 23.2545Z",
    fill: "#86BAD3"
  })]
}), 'BugHerdLogo', 'Company');
export default BugHerdLogo;