/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VmwareLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 148,
  height: 33,
  viewBox: "0 0 148 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M1.78606 5.74397C3.39739 5.02805 5.12311 5.72129 5.82129 7.25176L11.3633 20.3179L15.6835 10.1342C17.146 6.79025 19.5432 5.46293 22.5618 5.46293C24.5133 5.46293 26.8702 6.54077 28.2547 8.33453C28.2547 8.33453 31.2357 5.46293 34.6418 5.46293C37.8694 5.46293 39.7766 6.54077 41.1601 8.33453C41.1601 8.33453 43.7635 5.45898 47.92 5.45898C52.0815 5.45898 55.5398 8.4716 55.5398 12.7721V24.4745C55.5398 26.4112 54.4196 27.9999 52.3556 27.9999C50.2907 27.9999 49.2138 26.4112 49.2138 24.4745V14.1921C49.2138 12.2554 47.92 10.9221 45.9862 10.9221C44.0485 10.9221 42.6334 12.209 42.6334 14.1921V24.4745C42.6334 26.4112 41.5112 27.9999 39.4462 27.9999C37.3832 27.9999 36.3054 26.4112 36.3054 24.4745V14.1921C36.3054 12.2554 35.0165 10.9221 33.0798 10.9221C31.143 10.9221 29.722 12.209 29.722 14.1921V24.4745C29.722 26.4112 28.6057 27.9999 26.5408 27.9999C24.4739 27.9999 23.4 26.4112 23.4 24.4745V11.9092C23.4 11.3649 22.973 10.9596 22.4651 10.9566C21.8646 10.9527 21.6752 11.3679 21.6151 11.5039C21.5559 11.6381 15.6549 24.4873 15.6549 24.4873C14.5919 26.6844 13.6117 27.9999 11.3633 27.9999C9.25892 27.9999 8.1377 26.7919 7.07367 24.4873L0.307853 9.78118C-0.420895 8.24972 0.172752 6.46089 1.78606 5.74397Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88.2417 21.3356V21.2528C88.2417 16.76 91.9535 14.3657 97.3525 14.3657C100.075 14.3657 102.012 14.7365 103.912 15.2759V14.5304C103.912 10.6993 101.555 8.71715 97.5606 8.71715C95.4158 8.71715 94.1673 8.99031 92.5974 9.68947C92.3943 9.77231 92.1862 9.81471 92.0255 9.81471C91.2829 9.81471 90.6202 9.19444 90.6202 8.45386C90.6202 7.79611 90.9072 7.38884 91.488 7.13146C93.5875 6.22028 95.1702 5.91754 97.89 5.91754C100.898 5.91754 103.207 6.69855 104.771 8.26649C106.217 9.7092 106.959 11.7702 106.959 14.4929V26.2554C106.959 27.1597 106.3 27.8214 105.429 27.8214C104.526 27.8214 103.913 27.1607 103.913 26.3373V24.3039C102.426 26.2456 99.95 27.9742 96.2057 27.9742C92.2454 27.9713 88.2417 25.7061 88.2417 21.3356ZM103.953 19.6858V17.6238C102.383 17.1712 100.277 16.7166 97.6819 16.7166C93.6881 16.7166 91.4584 18.4502 91.4584 21.1276V21.2094C91.4584 23.8887 93.9297 25.4557 96.819 25.4557C100.734 25.4557 103.953 23.0633 103.953 19.6858Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M122.693 16.9514C122.693 23.4983 127.393 27.9625 133.149 27.9625C136.712 27.9625 139.065 26.6894 141.048 24.7675C141.376 24.4844 141.538 24.1146 141.538 23.7478C141.538 23.0082 140.928 22.3939 140.16 22.3939C139.794 22.3939 139.511 22.5576 139.267 22.7597C137.725 24.1926 135.826 25.2161 133.232 25.2161C129.499 25.2161 126.3 22.6384 125.859 18.0579H141.007C141.773 18.0579 142.468 17.4415 142.468 16.5797C142.468 10.9696 138.94 5.8575 132.744 5.8575C126.949 5.8575 122.693 10.7694 122.693 16.8666V16.9514ZM132.659 8.52004C136.833 8.52004 139.022 11.8729 139.348 15.7237H125.857C126.257 11.5879 129.012 8.52004 132.659 8.52004Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M63.5785 26.3499L57.0987 8.30576L57.0839 8.26166L57.0703 8.22131C56.9904 7.98481 56.8926 7.69534 56.8926 7.43797C56.8926 6.65597 57.5533 5.91342 58.5 5.91342C59.3263 5.91342 59.8648 6.44987 60.1113 7.23187L65.4808 22.7801L70.8887 7.18848C71.1352 6.44888 71.6312 5.91243 72.4527 5.91243H72.6203C73.4891 5.91243 74.0255 6.44888 74.2721 7.18848L79.6356 22.7801L85.0899 7.15002C85.296 6.48636 85.7466 5.91243 86.6164 5.91243C87.5236 5.91243 88.1853 6.61061 88.1853 7.39655C88.1853 7.64301 88.0936 7.91908 88.0164 8.15119L87.9951 8.21555L87.9792 8.26434L81.458 26.3499C81.1247 27.2946 80.4669 27.7906 79.7224 27.7906H79.5991C78.7728 27.7906 78.1525 27.2946 77.8616 26.3499L72.5394 11.0166L67.171 26.3499C66.882 27.2946 66.2608 27.7906 65.4374 27.7906H65.3506C64.5686 27.7906 63.9108 27.2946 63.5785 26.3499Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M110.813 7.48633C110.813 6.61558 111.515 5.87204 112.382 5.87204C113.296 5.87204 113.996 6.57515 113.996 7.48633V11.1478C115.566 7.59185 119.048 5.91346 121.356 5.91346C122.311 5.91346 122.932 6.61558 122.932 7.52479C122.932 8.39456 122.356 9.01187 121.522 9.13612C117.385 9.63214 113.997 12.7355 113.997 18.8988V26.1498C113.997 27.0185 113.337 27.7611 112.428 27.7611C111.517 27.7611 110.814 27.055 110.814 26.1498L110.813 7.48633Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M144.635 7.18289C144.477 7.18289 144.351 7.30813 144.351 7.46492L144.352 9.47761C144.352 9.63342 144.478 9.75767 144.636 9.75767C144.794 9.75767 144.915 9.6344 144.915 9.47761V8.92143H145.364L145.911 9.61369C145.977 9.69456 146.062 9.75865 146.187 9.75865C146.322 9.75865 146.454 9.6561 146.454 9.50029C146.454 9.41351 146.417 9.35533 146.363 9.28433L145.974 8.81099C146.281 8.6818 146.484 8.43626 146.484 8.03392V8.02603C146.484 7.78936 146.405 7.58523 146.26 7.44421C146.102 7.27657 145.846 7.18289 145.515 7.18289H144.635ZM145.908 8.06252C145.908 8.28045 145.758 8.42344 145.483 8.42344L144.912 8.42245V7.68779H145.474C145.747 7.68779 145.908 7.81598 145.908 8.05364V8.06252Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M142.725 8.52579V8.50804C142.725 7.06928 143.893 5.85734 145.36 5.85734C146.848 5.85734 148 7.05647 148 8.4972V8.50804C148 9.95173 146.835 11.1637 145.36 11.1637C143.872 11.1637 142.725 9.9685 142.725 8.52579ZM147.493 8.50804V8.50114C147.493 7.31779 146.579 6.34744 145.36 6.34744C144.152 6.34744 143.224 7.33455 143.224 8.50804V8.52185C143.224 9.70224 144.141 10.6726 145.36 10.6726C146.564 10.6726 147.493 9.68745 147.493 8.50804Z",
    fill: "#0F1632"
  })]
}), 'VmwareLogo', 'Company');
export default VmwareLogo;