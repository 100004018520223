/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RocketMoneyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 144,
  height: 32,
  viewBox: "0 0 144 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M87.9662 12.1406L97.7807 24.7665H90.7315L81.745 12.9698V24.7654H75.8027V0.712891H81.7461V11.4488L90.4086 0.712891H97.414L87.9662 12.1406ZM106.352 19.7895H117.78V24.7665H100.408V0.712891H117.226V5.64264H106.352V10.0661H116.167V14.8586H106.352V19.7895ZM120.176 0.712891V5.64264H127.502V24.7665H133.4V5.64264H140.681V0.712891H120.176Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M120.176 0.712891V5.64264H127.502V24.7654H133.4V5.64377H140.681V0.712891H120.176ZM14.1007 15.5044L21.3345 24.7665H14.5148L5.89725 13.1543V24.7654H0V0.712891H10.8293C15.9896 0.712891 19.584 3.89214 19.584 8.50014C19.584 11.7716 17.5106 14.7214 14.1007 15.5044ZM13.7318 8.59239C13.7318 6.79464 12.303 5.55039 10.2758 5.55039H5.9445V11.6333H10.2758C12.3491 11.6333 13.7318 10.389 13.7318 8.59239Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M35.5725 5.45642C39.9049 5.45642 43.3147 8.77517 43.3147 13.337C43.3147 16.9303 41.5181 19.097 39.1219 20.7092L42.4395 25.1327C46.5401 22.3224 49.1209 18.4513 49.1209 13.337C49.1209 5.7793 43.1302 0.294922 35.5736 0.294922C28.017 0.294922 22.0264 5.7793 22.0264 13.3359C22.0264 18.4513 24.606 22.3224 28.7077 25.1327L32.0254 20.7092C29.6291 19.097 27.8325 16.9303 27.8325 13.337C27.8775 8.77517 31.2412 5.45642 35.5725 5.45642Z",
    fill: "#DA1A32"
  }), _jsx("path", {
    d: "M69.9028 18.0824L72.9898 22.2764C70.7319 24.2575 67.9678 25.1339 64.5118 25.1339C57.737 25.1339 51.8848 20.2019 51.8848 12.6464C51.8848 5.0875 57.692 0.25 64.5118 0.25C67.7833 0.25 70.7319 1.07912 72.9436 2.87687L69.7183 7.25312C68.4279 6.24062 66.8608 5.4565 64.5106 5.4565C60.779 5.4565 57.7831 8.40625 57.7831 12.7836C57.7831 17.161 60.779 19.9724 64.6029 19.9724C66.9069 19.9724 68.5201 19.1421 69.9016 18.0824H69.9028Z",
    fill: "#231F20"
  })]
}), 'RocketMoneyLogo', 'Company');
export default RocketMoneyLogo;