/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CleanChoiceEnergyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 87,
  height: 33,
  viewBox: "0 0 87 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M18.1718 21.7948C17.4741 22.0985 16.719 22.2478 15.9581 22.2324C15.2379 22.2404 14.5224 22.1137 13.8488 21.8586C13.2219 21.6224 12.6505 21.2594 12.1704 20.7922C11.6903 20.3251 11.3117 19.7638 11.0585 19.1436C10.5157 17.7757 10.5157 16.2522 11.0585 14.8843C11.3179 14.2657 11.7049 13.7089 12.1943 13.2501C12.6855 12.7946 13.2628 12.4419 13.8922 12.2128C15.2343 11.7253 16.7051 11.7253 18.0472 12.2128C18.6741 12.4287 19.2362 12.7995 19.6814 13.2907L18.0733 14.9046C17.8532 14.6005 17.552 14.3646 17.2041 14.2237C16.8515 14.0767 16.4734 14.0009 16.0914 14.0006C15.6933 13.9943 15.2983 14.0723 14.9324 14.2295C14.5926 14.3771 14.2869 14.5931 14.0342 14.864C13.7811 15.1413 13.5843 15.465 13.4547 15.8173C13.3121 16.203 13.2414 16.6115 13.2461 17.0227C13.2414 17.4404 13.3121 17.8556 13.4547 18.2483C13.5831 18.6012 13.7801 18.9251 14.0342 19.2016C14.2806 19.4705 14.5807 19.6847 14.915 19.8303C15.2714 19.9816 15.6551 20.0576 16.0422 20.0535C16.4731 20.0608 16.8992 19.9613 17.2823 19.7637C17.6182 19.5922 17.909 19.344 18.1313 19.0393L19.7973 20.604C19.3482 21.114 18.7935 21.5203 18.1718 21.7948Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M20.4956 21.9688V11.4451H22.8136V21.9688H20.4956Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M31.0049 18.5872V18.877C31.0096 18.9648 31.0096 19.0528 31.0049 19.1406H25.9661C25.982 19.3285 26.0415 19.5101 26.14 19.6709C26.2388 19.8303 26.3666 19.9698 26.5166 20.0823C26.8368 20.3167 27.2239 20.4417 27.6206 20.4387C27.9388 20.4484 28.2545 20.3787 28.5391 20.2359C28.7793 20.11 28.9882 19.9317 29.1505 19.7144L30.7383 20.7169C30.4146 21.1892 29.9704 21.5664 29.4518 21.8092C28.8692 22.0745 28.2345 22.2052 27.5945 22.1917C27.0927 22.1946 26.594 22.1123 26.1197 21.9483C25.671 21.7946 25.2574 21.5533 24.9027 21.2384C24.5482 20.9165 24.2683 20.5209 24.0827 20.0794C23.8788 19.5856 23.7801 19.0547 23.793 18.5206C23.7846 17.9934 23.8831 17.47 24.0827 16.982C24.2658 16.5457 24.5359 16.1513 24.8766 15.823C25.2173 15.4987 25.6192 15.2455 26.0588 15.0784C26.5298 14.8996 27.0299 14.8102 27.5337 14.8147C28.0143 14.8094 28.4915 14.897 28.9389 15.0726C29.3561 15.2372 29.7319 15.4914 30.04 15.8172C30.356 16.1589 30.5994 16.561 30.7557 16.9994C30.9322 17.5097 31.0166 18.0474 31.0049 18.5872ZM28.9042 17.718C28.9112 17.3751 28.7892 17.0421 28.5623 16.785C28.4311 16.6485 28.2714 16.5428 28.0946 16.4751C27.9178 16.4075 27.7282 16.3798 27.5395 16.3938C27.3311 16.3917 27.124 16.427 26.9281 16.4981C26.7498 16.5648 26.5849 16.6629 26.4413 16.7879C26.3042 16.9063 26.1911 17.0499 26.1081 17.2109C26.0261 17.3681 25.9787 17.541 25.969 17.718H28.9042Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M31.7582 15.9825C32.1668 15.5955 32.6526 15.2993 33.1838 15.1133C33.7171 14.9209 34.2799 14.8229 34.8469 14.8235C35.3502 14.8115 35.8518 14.885 36.3305 15.0408C36.7128 15.1701 37.0525 15.4016 37.3127 15.7102C37.5795 16.0446 37.7677 16.4348 37.8632 16.8518C37.9875 17.3874 38.0459 17.9362 38.0371 18.486V21.9688H35.948V21.2328H35.9074C35.7166 21.5334 35.4372 21.7672 35.1077 21.9021C34.7423 22.0616 34.3474 22.1426 33.9487 22.1397C33.6555 22.1389 33.3636 22.102 33.0795 22.0296C32.7853 21.9573 32.5066 21.8326 32.2566 21.6616C32.0082 21.4869 31.8038 21.2567 31.6597 20.9894C31.4925 20.673 31.4106 20.3185 31.4221 19.9608C31.4003 19.5195 31.5475 19.0864 31.8335 18.7496C32.1243 18.4319 32.4869 18.1885 32.8911 18.0398C33.3571 17.8649 33.8443 17.7528 34.3399 17.7065C34.8528 17.6516 35.3683 17.6236 35.8842 17.6225V17.5124C35.895 17.3653 35.8673 17.2179 35.8038 17.0848C35.7403 16.9517 35.6432 16.8374 35.5221 16.7533C35.2568 16.5844 34.9468 16.4996 34.6325 16.5099C34.3081 16.5099 33.9876 16.5811 33.6937 16.7185C33.4083 16.8448 33.1443 17.0149 32.9114 17.2227L31.7582 15.9825ZM35.948 18.9612H35.6582C35.4071 18.9612 35.1541 18.9727 34.8991 18.9959C34.6679 19.0149 34.4395 19.0596 34.2182 19.1292C34.0329 19.1864 33.8634 19.2856 33.7227 19.419C33.6578 19.483 33.6071 19.5599 33.5736 19.6448C33.5402 19.7296 33.5249 19.8204 33.5286 19.9115C33.5251 20.029 33.5563 20.1449 33.6184 20.2447C33.6766 20.3357 33.7549 20.412 33.8473 20.4679C33.9457 20.526 34.0536 20.5662 34.166 20.5867C34.2806 20.6092 34.397 20.6208 34.5137 20.6214C34.7078 20.6344 34.9024 20.6071 35.0854 20.5413C35.2684 20.4755 35.4358 20.3725 35.5771 20.239C35.7039 20.0989 35.8016 19.9351 35.8648 19.7571C35.9279 19.5791 35.9552 19.3903 35.9451 19.2016L35.948 18.9612Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M43.7973 21.9687V18.1845C43.7978 17.998 43.7813 17.8118 43.748 17.6282C43.7202 17.4652 43.6664 17.3077 43.5887 17.1617C43.5154 17.0289 43.4072 16.9187 43.2758 16.843C43.1202 16.7591 42.9453 16.7181 42.7687 16.7242C42.5831 16.7203 42.3992 16.761 42.2327 16.843C42.0812 16.9206 41.9502 17.0327 41.8502 17.1704C41.7454 17.3156 41.6679 17.4785 41.6213 17.6514C41.5701 17.8353 41.5448 18.0255 41.5459 18.2164V21.9831H39.2482V15.0378H41.4619V15.9969H41.4909C41.5822 15.8385 41.6921 15.6917 41.8183 15.5594C41.9554 15.4146 42.1137 15.2915 42.2877 15.1943C42.4714 15.0908 42.6657 15.0073 42.8672 14.9451C43.0881 14.8788 43.3175 14.8456 43.5481 14.8466C43.9564 14.8344 44.3617 14.9199 44.7303 15.0958C45.0377 15.2488 45.3062 15.4698 45.5155 15.7419C45.7225 16.0097 45.8742 16.3161 45.9617 16.643C46.0494 16.9799 46.0942 17.3265 46.095 17.6746V21.9629L43.7973 21.9687Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M56.048 20.3692C55.8718 20.6283 55.6667 20.8665 55.4366 21.0791C55.1881 21.3066 54.9116 21.5013 54.6137 21.6586C54.2883 21.8338 53.9429 21.969 53.5851 22.0614C53.1827 22.1633 52.7688 22.213 52.3537 22.2092C51.6479 22.2157 50.9478 22.0828 50.2935 21.818C49.6863 21.5735 49.1355 21.2076 48.6746 20.7427C48.2138 20.2779 47.8526 19.7239 47.6133 19.1146C47.3526 18.4544 47.2227 17.7498 47.2309 17.04C47.221 16.331 47.351 15.627 47.6133 14.9683C47.8584 14.3568 48.2244 13.8012 48.6894 13.3346C49.1545 12.8681 49.7089 12.5003 50.3196 12.2533C50.9632 11.9904 51.6526 11.8574 52.3479 11.8622C53.0564 11.8616 53.7573 12.0097 54.4051 12.2968C54.7013 12.4282 54.9814 12.5935 55.2396 12.7894C55.4808 12.9717 55.6919 13.1907 55.8654 13.4384L55.0309 14.0353C54.914 13.8613 54.7758 13.7027 54.6195 13.563C54.4356 13.4051 54.2321 13.2717 54.0139 13.1661C53.7719 13.0428 53.5174 12.9457 53.2548 12.8763C52.9664 12.8007 52.6692 12.7637 52.371 12.7662C51.7799 12.7567 51.1937 12.8753 50.6528 13.1139C50.1599 13.3274 49.7169 13.6412 49.3518 14.0353C48.9955 14.4339 48.7209 14.8986 48.5434 15.4029C48.3526 15.9277 48.2546 16.4816 48.2537 17.04C48.2525 17.5986 48.3506 18.1529 48.5434 18.6771C48.7247 19.1809 49.0011 19.6452 49.3576 20.0447C49.7227 20.4484 50.169 20.7703 50.6673 20.9893C51.2043 21.2274 51.7866 21.346 52.3739 21.337C52.9307 21.3389 53.4813 21.2203 53.9878 20.9893C54.5112 20.7467 54.9552 20.361 55.2685 19.8767L56.048 20.3692Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M57.8705 16.5273C58.0537 16.1391 58.359 15.8215 58.7397 15.6233C59.1408 15.4026 59.5916 15.2879 60.0494 15.2901C60.8742 15.2901 61.4933 15.5363 61.9067 16.0289C62.3408 16.5834 62.5609 17.2756 62.5267 17.9789V21.9688H61.5793V18.3643C61.5816 18.0513 61.5535 17.7388 61.4952 17.4313C61.4469 17.1759 61.3485 16.9327 61.2055 16.7156C61.0694 16.5148 60.8824 16.3538 60.6637 16.2491C60.3907 16.127 60.0932 16.0695 59.7944 16.0811C59.543 16.0815 59.2945 16.1338 59.0642 16.2346C58.8207 16.3411 58.604 16.5005 58.4297 16.7011C58.2299 16.9337 58.0782 17.2035 57.9835 17.495C57.8649 17.8607 57.8081 18.2436 57.8154 18.628V21.9688H56.8853V11.4451H57.8328V16.5273H57.8705Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M70.3268 18.7119C70.3315 19.1794 70.2439 19.6431 70.069 20.0767C69.9026 20.4837 69.6563 20.8531 69.3446 21.1632C69.0302 21.4743 68.6555 21.7179 68.2435 21.8789C67.796 22.0544 67.3189 22.142 66.8382 22.1368C66.3624 22.1411 65.8901 22.0536 65.4474 21.8789C65.0354 21.7179 64.6607 21.4744 64.3464 21.1632C64.0377 20.8525 63.7943 20.483 63.6307 20.0767C63.2869 19.1993 63.2869 18.2245 63.6307 17.3472C63.7943 16.9409 64.0377 16.5714 64.3464 16.2606C64.6608 15.9496 65.0355 15.7061 65.4474 15.545C65.8901 15.3703 66.3624 15.2827 66.8382 15.2871C67.3189 15.2819 67.796 15.3694 68.2435 15.545C68.6554 15.7061 69.0301 15.9497 69.3446 16.2606C69.6532 16.5715 69.8965 16.9409 70.0603 17.3472C70.2381 17.7801 70.3287 18.2439 70.3268 18.7119ZM69.3243 18.7119C69.3265 18.3635 69.2677 18.0173 69.1504 17.6891C69.0413 17.3813 68.873 17.0978 68.655 16.8546C68.4372 16.6142 68.1718 16.4218 67.8755 16.2896C67.5517 16.1469 67.2008 16.0757 66.8469 16.081C66.4949 16.0751 66.1458 16.1463 65.8241 16.2896C65.5303 16.423 65.2669 16.6153 65.0505 16.8546C64.8335 17.0981 64.6662 17.3815 64.5579 17.6891C64.3258 18.3512 64.3258 19.0726 64.5579 19.7347C64.6662 20.0423 64.8336 20.3258 65.0505 20.5692C65.2661 20.8073 65.5298 20.9969 65.8241 21.1255C66.1469 21.2647 66.4955 21.3338 66.8469 21.3284C67.2002 21.3332 67.5505 21.2641 67.8755 21.1255C68.1722 20.9981 68.4381 20.8084 68.655 20.5692C68.8729 20.326 69.0412 20.0425 69.1504 19.7347C69.2677 19.4066 69.3265 19.0604 69.3243 18.7119Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M72.6652 12.9488C72.6678 13.0394 72.6505 13.1294 72.6145 13.2126C72.5784 13.2957 72.5245 13.3699 72.4565 13.4298C72.3341 13.5503 72.1691 13.6178 71.9973 13.6178C71.8255 13.6178 71.6605 13.5503 71.538 13.4298C71.472 13.3683 71.4194 13.2938 71.3835 13.211C71.3475 13.1283 71.3289 13.039 71.3289 12.9488C71.3289 12.8586 71.3475 12.7693 71.3835 12.6866C71.4194 12.6038 71.472 12.5294 71.538 12.4678C71.6605 12.3473 71.8255 12.2798 71.9973 12.2798C72.1691 12.2798 72.3341 12.3473 72.4565 12.4678C72.5245 12.5278 72.5784 12.6019 72.6145 12.6851C72.6505 12.7682 72.6678 12.8582 72.6652 12.9488ZM72.471 21.9687H71.5235V15.4552H72.471V21.9687Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M78.8339 16.9328C78.6623 16.6693 78.4245 16.4555 78.1443 16.3127C77.8427 16.1499 77.5048 16.0662 77.1621 16.0693C76.8088 16.0645 76.4584 16.1336 76.1334 16.2722C75.8359 16.401 75.5685 16.5905 75.3482 16.8285C75.1266 17.0739 74.9571 17.3617 74.8499 17.6746C74.7317 18.0075 74.6728 18.3586 74.676 18.7119C74.6726 19.0623 74.7314 19.4106 74.8499 19.7405C74.9592 20.0458 75.1265 20.3272 75.3424 20.5692C75.5595 20.8081 75.8253 20.9978 76.1219 21.1255C76.4478 21.2643 76.7992 21.3334 77.1534 21.3283C77.4951 21.3432 77.8352 21.2736 78.1436 21.1255C78.4519 20.9774 78.7189 20.7554 78.9208 20.4793L79.6307 21.0096C79.3546 21.3517 79.0089 21.6312 78.6166 21.8296C78.1645 22.0443 77.6682 22.1495 77.1679 22.1367C76.6776 22.1438 76.1907 22.0562 75.7336 21.8788C75.3237 21.7187 74.9517 21.475 74.6412 21.1631C74.3359 20.8519 74.0965 20.4824 73.9371 20.0766C73.6028 19.1975 73.6028 18.2262 73.9371 17.3471C74.0965 16.9413 74.3359 16.5718 74.6412 16.2606C74.9475 15.9504 75.3144 15.7068 75.7191 15.5449C76.1762 15.3675 76.6632 15.28 77.1534 15.287C77.6117 15.2931 78.064 15.3916 78.4833 15.5768C78.9165 15.7546 79.2939 16.0459 79.5757 16.4199L78.8339 16.9328Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M86.4601 18.5179V18.7265C86.4645 18.8056 86.4645 18.8849 86.4601 18.9641H81.0447C81.0517 19.2797 81.1175 19.5912 81.2388 19.8826C81.3551 20.1632 81.5255 20.4181 81.7401 20.633C81.956 20.8479 82.2106 21.02 82.4906 21.1401C82.8457 21.2874 83.2301 21.3505 83.6136 21.3244C83.9972 21.2982 84.3695 21.1836 84.7014 20.9894C85.0281 20.7919 85.3063 20.5235 85.5156 20.2042L86.2254 20.7605C85.887 21.2106 85.4459 21.5732 84.9389 21.8181C84.4647 22.0365 83.9486 22.1492 83.4265 22.1484C82.9656 22.1524 82.5086 22.0647 82.082 21.8905C81.6811 21.7279 81.3181 21.4842 81.0157 21.1749C80.7135 20.8612 80.4744 20.4923 80.3116 20.0883C80.1366 19.6548 80.0489 19.191 80.0538 18.7236C80.0485 18.2569 80.1331 17.7936 80.3029 17.3589C80.4611 16.9545 80.6974 16.5853 80.9983 16.2723C81.2947 15.9664 81.6494 15.7231 82.0414 15.5566C82.4509 15.3827 82.8918 15.2949 83.3366 15.2987C83.7998 15.2897 84.2597 15.3775 84.6869 15.5566C85.0579 15.7151 85.3901 15.9524 85.6604 16.252C85.9194 16.5512 86.1163 16.8989 86.2399 17.2748C86.3818 17.6741 86.4563 18.0942 86.4601 18.5179ZM85.5011 18.2107C85.496 17.6498 85.2904 17.1092 84.9216 16.6866C84.7199 16.4788 84.4756 16.3171 84.2055 16.2126C83.9354 16.1081 83.646 16.0632 83.3569 16.0811C83.0583 16.0792 82.7625 16.1383 82.4877 16.2549C82.2239 16.3671 81.9832 16.5272 81.7778 16.7272C81.5762 16.9233 81.4114 17.1539 81.291 17.4081C81.1716 17.6591 81.1008 17.9305 81.0824 18.2078L85.5011 18.2107Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M23.8538 9.36755C23.596 9.3649 23.3489 9.26383 23.1631 9.08502C22.9772 8.9062 22.8668 8.66315 22.8542 8.40558C22.8491 8.27477 22.8703 8.14426 22.9165 8.02181C22.9628 7.89937 23.0333 7.78747 23.1237 7.69279C23.2153 7.59007 23.3279 7.5082 23.4538 7.45271C23.5798 7.39723 23.7162 7.36942 23.8538 7.37117C24.0472 7.37192 24.2361 7.42871 24.3978 7.53464C24.5595 7.64058 24.687 7.79113 24.7649 7.96805C24.8428 8.14497 24.8677 8.34068 24.8366 8.53147C24.8055 8.72227 24.7198 8.89996 24.5898 9.04303C24.4969 9.14585 24.3833 9.2279 24.2565 9.28382C24.1296 9.33974 23.9924 9.36827 23.8538 9.36755Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M7.81906 17.0488C7.76696 17.0524 7.71468 17.0524 7.66259 17.0488C7.51871 17.0318 7.37971 16.9861 7.2538 16.9144C7.12788 16.8428 7.01762 16.7466 6.92952 16.6316C6.79051 16.4569 6.70709 16.2446 6.69003 16.0221C6.67297 15.7995 6.72307 15.577 6.83384 15.3832C6.94462 15.1894 7.11097 15.0333 7.3114 14.9351C7.51183 14.8369 7.73712 14.801 7.95814 14.8322C8.24047 14.8676 8.4987 15.0093 8.68024 15.2285C8.86177 15.4476 8.95296 15.7276 8.93523 16.0116C8.9175 16.2956 8.79219 16.5622 8.58482 16.7571C8.37745 16.9519 8.10361 17.0604 7.81906 17.0604V17.0488Z",
    fill: "#4CA950"
  }), _jsx("path", {
    d: "M8.74916 14.2063C8.55967 14.2067 8.3732 14.1589 8.20733 14.0672C8.07945 13.9997 7.9664 13.9071 7.87484 13.7952C7.78327 13.6832 7.71505 13.554 7.67419 13.4153C7.6243 13.2477 7.61407 13.0709 7.64431 12.8987C7.67455 12.7265 7.74442 12.5637 7.84841 12.4232C7.95241 12.2827 8.08766 12.1682 8.24349 12.089C8.39932 12.0097 8.57145 11.9678 8.74626 11.9666C9.03342 11.9812 9.304 12.1055 9.50208 12.3139C9.70016 12.5224 9.81061 12.7989 9.81061 13.0864C9.81061 13.374 9.70016 13.6505 9.50208 13.8589C9.304 14.0673 9.03342 14.1917 8.74626 14.2063H8.74916Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M10.6441 11.8824C10.4831 11.8819 10.3241 11.8461 10.1785 11.7775C10.0328 11.709 9.90391 11.6093 9.80094 11.4855C9.70585 11.3765 9.63353 11.2496 9.58825 11.1123C9.54297 10.9749 9.52563 10.8299 9.53727 10.6857C9.56116 10.4036 9.68981 10.1407 9.89789 9.94871C10.106 9.75672 10.3784 9.6496 10.6615 9.64844C10.8906 9.64821 11.1143 9.71874 11.3018 9.85038C11.4894 9.98202 11.6317 10.1684 11.7094 10.3839C11.787 10.5995 11.7963 10.8338 11.7358 11.0548C11.6753 11.2758 11.548 11.4728 11.3714 11.6187C11.1674 11.7892 10.91 11.8826 10.6441 11.8824Z",
    fill: "#F4B824"
  }), _jsx("path", {
    d: "M13.2635 10.4019C13.0778 10.3988 12.8958 10.3497 12.7339 10.2587C12.572 10.1678 12.4353 10.038 12.3361 9.881C12.2369 9.72402 12.1783 9.54481 12.1657 9.35955C12.1531 9.17429 12.1868 8.9888 12.2638 8.81982C12.3574 8.62897 12.5025 8.46811 12.6828 8.35546C12.863 8.2428 13.0712 8.18285 13.2837 8.18237C13.5474 8.18327 13.8021 8.27775 14.0026 8.44899C14.2031 8.62022 14.3362 8.85707 14.3783 9.11733C14.4204 9.37759 14.3687 9.64434 14.2325 9.87005C14.0962 10.0957 13.8843 10.2657 13.6343 10.3497C13.5144 10.3874 13.3891 10.405 13.2635 10.4019Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M16.1957 9.97891C16.0496 9.97606 15.9055 9.94445 15.7716 9.8859C15.6377 9.82735 15.5166 9.74299 15.4153 9.63766C15.2108 9.42492 15.0991 9.13963 15.1048 8.84454C15.1077 8.69842 15.1393 8.5543 15.1978 8.4204C15.2564 8.2865 15.3407 8.16545 15.4461 8.06415C15.5514 7.96285 15.6757 7.88329 15.8118 7.83001C15.9478 7.77673 16.0931 7.75078 16.2392 7.75363H16.2885C16.5843 7.76498 16.8635 7.89314 17.065 8.11002C17.211 8.26949 17.3075 8.46796 17.3428 8.68128C17.3781 8.8946 17.3507 9.11358 17.2638 9.31158C17.1769 9.50959 17.0344 9.67807 16.8535 9.79655C16.6726 9.91502 16.4612 9.97838 16.245 9.97891H16.1957Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M19.1831 10.6394C19.0275 10.6439 18.8726 10.6163 18.7282 10.5583C18.4928 10.4575 18.2994 10.2787 18.1806 10.052C18.0618 9.82522 18.0248 9.56444 18.0758 9.31358C18.1269 9.06273 18.2629 8.83715 18.4609 8.67489C18.6589 8.51263 18.9068 8.42362 19.1628 8.42285C19.3194 8.42285 19.4743 8.45542 19.6177 8.51847C19.885 8.63082 20.098 8.84282 20.2117 9.10956C20.278 9.27797 20.3023 9.45998 20.2825 9.63988C20.2628 9.81979 20.1996 9.99218 20.0983 10.1422C19.9971 10.2922 19.8608 10.4153 19.7014 10.501C19.5419 10.5866 19.364 10.6321 19.1831 10.6336V10.6394Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M19.9596 25.2544C19.7133 25.2546 19.474 25.1731 19.2791 25.0227C19.0841 24.8723 18.9445 24.6615 18.8823 24.4233C18.82 24.185 18.8385 23.9329 18.9348 23.7063C19.0312 23.4797 19.2 23.2915 19.4149 23.1711C19.5857 23.0721 19.7794 23.0191 19.977 23.0176C20.1461 23.0199 20.3125 23.0605 20.4637 23.1363C20.6149 23.2121 20.747 23.3211 20.8501 23.4551C20.9532 23.5892 21.0247 23.7448 21.0591 23.9104C21.0936 24.076 21.0901 24.2472 21.049 24.4113C20.9837 24.6512 20.8415 24.863 20.6441 25.0143C20.4468 25.1655 20.2053 25.2479 19.9567 25.2487L19.9596 25.2544Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M17.1287 26.2366C16.8476 26.2364 16.577 26.1298 16.3713 25.9382C16.1655 25.7466 16.04 25.4843 16.0198 25.2039C15.9996 24.9235 16.0863 24.6458 16.2625 24.4268C16.4386 24.2077 16.6912 24.0634 16.9694 24.0229C17.0263 24.0187 17.0834 24.0187 17.1403 24.0229C17.4067 24.0209 17.6647 24.1155 17.8666 24.2892C18.0685 24.4629 18.2006 24.7039 18.2385 24.9675C18.2625 25.1102 18.2577 25.2563 18.2244 25.3972C18.191 25.538 18.1298 25.6707 18.0443 25.7875C17.9562 25.9086 17.8446 26.0108 17.7161 26.0879C17.5877 26.1651 17.4451 26.2156 17.2968 26.2366C17.2408 26.2408 17.1847 26.2408 17.1287 26.2366Z",
    fill: "#F4B824"
  }), _jsx("path", {
    d: "M14.1414 26.1325C14.0566 26.1321 13.972 26.1224 13.8893 26.1035C13.6219 26.0446 13.3856 25.8891 13.2257 25.6668C13.0657 25.4446 12.9933 25.1711 13.0224 24.8989C13.0515 24.6266 13.1799 24.3746 13.3832 24.1911C13.5864 24.0076 13.8502 23.9055 14.124 23.9043C14.2098 23.9045 14.2953 23.9142 14.379 23.9333C14.6467 23.9923 14.8831 24.148 15.0431 24.3705C15.203 24.5931 15.2752 24.8669 15.2457 25.1393C15.2163 25.4118 15.0873 25.6639 14.8835 25.8471C14.6797 26.0304 14.4155 26.132 14.1414 26.1325Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M11.3772 24.9474C11.1533 24.9473 10.9344 24.8808 10.7484 24.7561C10.5988 24.6536 10.4766 24.516 10.3923 24.3554C10.3081 24.1948 10.2643 24.0161 10.265 23.8347C10.2656 23.6533 10.3105 23.4749 10.3959 23.3148C10.4812 23.1548 10.6044 23.0181 10.7547 22.9165C10.9049 22.815 11.0778 22.7517 11.2581 22.7323C11.4384 22.7128 11.6207 22.7377 11.7892 22.8048C11.9577 22.8719 12.1073 22.9791 12.2248 23.1172C12.3424 23.2553 12.4244 23.4201 12.4637 23.5971C12.4956 23.7586 12.4916 23.925 12.452 24.0847C12.4123 24.2444 12.338 24.3935 12.2343 24.5212C12.1306 24.649 12.0001 24.7523 11.8519 24.824C11.7038 24.8956 11.5417 24.9338 11.3772 24.9358V24.9474Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M9.22432 22.8523C8.93716 22.8377 8.66659 22.7133 8.46851 22.5049C8.27042 22.2965 8.15997 22.02 8.15997 21.7324C8.15997 21.4449 8.27042 21.1684 8.46851 20.9599C8.66659 20.7515 8.93716 20.6272 9.22432 20.6125C9.45995 20.6153 9.68872 20.6923 9.87814 20.8324C10.0676 20.9726 10.208 21.1689 10.2795 21.3934C10.3511 21.618 10.35 21.8593 10.2766 22.0832C10.2032 22.3072 10.0611 22.5022 9.87046 22.6408C9.68335 22.7788 9.45682 22.853 9.22432 22.8523Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M7.96393 20.1202C7.76685 20.1173 7.57398 20.0627 7.40467 19.9617C7.23537 19.8608 7.09557 19.7172 6.9993 19.5452C6.90303 19.3732 6.85367 19.1789 6.85617 18.9818C6.85867 18.7847 6.91295 18.5917 7.01355 18.4222C7.08805 18.2965 7.1869 18.1868 7.30432 18.0997C7.42175 18.0127 7.55537 17.9499 7.69736 17.9152C7.7921 17.8914 7.88945 17.8797 7.98711 17.8804C8.28412 17.8804 8.56897 17.9984 8.77899 18.2084C8.98901 18.4184 9.107 18.7033 9.107 19.0003C9.107 19.2973 8.98901 19.5821 8.77899 19.7922C8.56897 20.0022 8.28412 20.1202 7.98711 20.1202H7.96393Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M1.11137 14.4005C1.04309 14.4006 0.975035 14.3928 0.908544 14.3773C0.800667 14.352 0.698823 14.3058 0.608835 14.2411C0.518847 14.1765 0.442479 14.0948 0.384097 14.0006C0.30614 13.8799 0.259309 13.7418 0.247793 13.5986C0.236276 13.4554 0.260434 13.3116 0.318104 13.18C0.375775 13.0484 0.46516 12.9332 0.578266 12.8446C0.691371 12.756 0.824669 12.6968 0.966237 12.6724C1.10781 12.6479 1.25323 12.6589 1.3895 12.7044C1.52578 12.7499 1.64865 12.8285 1.74713 12.9331C1.84561 13.0377 1.91663 13.165 1.95384 13.3038C1.99105 13.4426 1.99328 13.5884 1.96034 13.7282C1.91575 13.9198 1.80752 14.0906 1.65332 14.2127C1.49911 14.3348 1.30806 14.401 1.11137 14.4005Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M2.16312 11.3898C2.03383 11.39 1.90613 11.3612 1.78934 11.3058C1.6866 11.2595 1.59417 11.1931 1.51753 11.1105C1.44089 11.0279 1.38159 10.9307 1.34312 10.8248C1.29272 10.6928 1.27526 10.5505 1.29226 10.4102C1.30927 10.2699 1.36022 10.1359 1.4407 10.0197C1.52118 9.90354 1.62877 9.80876 1.75415 9.74356C1.87952 9.67836 2.01891 9.64471 2.16022 9.64552C2.28755 9.64624 2.41318 9.67493 2.5282 9.72955C2.71123 9.81108 2.86047 9.95334 2.95067 10.1322C3.04088 10.3112 3.0665 10.5157 3.02322 10.7114C2.97993 10.907 2.8704 11.0817 2.71314 11.2058C2.55588 11.33 2.36056 11.396 2.16022 11.3927L2.16312 11.3898Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M5.98493 6.36848C5.86195 6.36905 5.74024 6.34352 5.62786 6.29357C5.51548 6.24362 5.41497 6.1704 5.33299 6.07873C5.25763 5.99495 5.19982 5.89691 5.163 5.79041C5.12617 5.68391 5.11106 5.5711 5.11857 5.45867C5.13117 5.23677 5.22828 5.02813 5.38995 4.87562C5.55162 4.72311 5.76557 4.63832 5.98782 4.63867C6.16436 4.64051 6.33616 4.69606 6.48035 4.79793C6.62454 4.8998 6.7343 5.04317 6.79501 5.20895C6.85573 5.37473 6.86452 5.55507 6.82022 5.72597C6.77593 5.89688 6.68064 6.05024 6.54704 6.16566C6.38953 6.29757 6.19038 6.36943 5.98493 6.36848Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M8.61587 4.54319C8.48247 4.54224 8.35107 4.5106 8.23186 4.45071C8.11265 4.39083 8.00882 4.3043 7.92841 4.19785C7.84801 4.09139 7.79319 3.96785 7.7682 3.83681C7.7432 3.70576 7.74871 3.57072 7.78429 3.44214C7.81566 3.32991 7.86928 3.22514 7.94195 3.13405C8.01462 3.04296 8.10487 2.96741 8.20733 2.9119C8.33607 2.84125 8.48061 2.80436 8.62746 2.80469C8.81985 2.80617 9.00632 2.87144 9.15763 2.99027C9.30895 3.1091 9.41656 3.27477 9.46361 3.46133C9.51067 3.64788 9.4945 3.84478 9.41764 4.02116C9.34078 4.19754 9.20758 4.34343 9.03891 4.43598C8.90899 4.50629 8.7636 4.54314 8.61587 4.54319Z",
    fill: "#F4B824"
  }), _jsx("path", {
    d: "M14.6746 2.73509C14.4565 2.73591 14.2461 2.65474 14.0851 2.50768C13.9241 2.36062 13.8242 2.15842 13.8053 1.94117C13.7939 1.82804 13.8054 1.71376 13.8393 1.60521C13.8731 1.49666 13.9286 1.39608 14.0023 1.30952C14.077 1.21925 14.1691 1.14499 14.2732 1.09122C14.3772 1.03744 14.4911 1.00525 14.6079 0.996588H14.6804C14.8984 0.995768 15.1088 1.07694 15.2698 1.224C15.4308 1.37106 15.5307 1.57326 15.5496 1.7905C15.5599 1.90542 15.5472 2.02123 15.5123 2.13119C15.4774 2.24115 15.421 2.34307 15.3462 2.43099C15.2715 2.51891 15.1801 2.59108 15.0772 2.64329C14.9743 2.6955 14.8621 2.7267 14.747 2.73509H14.6746Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M20.9563 3.53469C20.8575 3.53346 20.7596 3.51584 20.6666 3.48253C20.4492 3.40828 20.27 3.25098 20.1682 3.04501C20.1043 2.91214 20.0753 2.7652 20.0838 2.61802C20.0923 2.47083 20.1382 2.32823 20.217 2.20363C20.2958 2.07904 20.405 1.97653 20.5343 1.90576C20.6637 1.83499 20.8089 1.79828 20.9563 1.79909C21.055 1.79932 21.1529 1.81597 21.2461 1.84834C21.464 1.92328 21.6433 2.08169 21.7444 2.28876C21.8083 2.42163 21.8374 2.56857 21.8288 2.71576C21.8203 2.86294 21.7745 3.00554 21.6957 3.13014C21.6168 3.25474 21.5076 3.35724 21.3783 3.42801C21.249 3.49878 21.1037 3.53549 20.9563 3.53469Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M28.1653 12.2564C27.9405 12.2522 27.7257 12.1628 27.5643 12.0063C27.4029 11.8498 27.3071 11.6378 27.2961 11.4132C27.2914 11.2989 27.3097 11.1849 27.35 11.0778C27.3904 10.9708 27.4518 10.873 27.5308 10.7902C27.609 10.7001 27.7055 10.6276 27.8139 10.5776C27.9223 10.5276 28.0402 10.5014 28.1595 10.5005C28.3861 10.5004 28.6038 10.5888 28.7661 10.7468C28.9285 10.9049 29.0227 11.1201 29.0288 11.3466C29.0327 11.4631 29.0131 11.5793 28.9711 11.6881C28.9292 11.7969 28.8658 11.8962 28.7847 11.98C28.7035 12.0638 28.6064 12.1305 28.499 12.176C28.3916 12.2214 28.2762 12.2448 28.1595 12.2448L28.1653 12.2564Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M31.6771 9.6282C31.4523 9.62406 31.2375 9.53467 31.0761 9.37813C30.9147 9.22159 30.8188 9.00959 30.8078 8.78503C30.8031 8.67075 30.8215 8.5567 30.8618 8.44967C30.9021 8.34264 30.9636 8.24483 31.0425 8.16206C31.1208 8.07188 31.2173 7.99939 31.3257 7.94943C31.4341 7.89946 31.5519 7.87318 31.6713 7.87231C31.8979 7.87223 32.1155 7.96063 32.2779 8.11866C32.4403 8.2767 32.5345 8.49189 32.5406 8.71838C32.5444 8.83495 32.5248 8.95111 32.4829 9.05994C32.4409 9.16877 32.3775 9.26804 32.2964 9.35185C32.2153 9.43567 32.1182 9.5023 32.0108 9.54778C31.9034 9.59327 31.7879 9.61667 31.6713 9.61661L31.6771 9.6282Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M28.0262 27.1205C27.8638 27.1213 27.7044 27.0766 27.5661 26.9915C27.4278 26.9063 27.3161 26.7841 27.2437 26.6387C27.1713 26.4933 27.1411 26.3306 27.1565 26.1689C27.1719 26.0072 27.2323 25.853 27.3308 25.7239C27.4115 25.6164 27.516 25.529 27.6362 25.4686C27.7564 25.4083 27.8889 25.3767 28.0233 25.3762C28.2121 25.3779 28.3951 25.4409 28.5449 25.5559C28.6356 25.6224 28.712 25.7064 28.7697 25.803C28.8275 25.8995 28.8653 26.0066 28.881 26.118C28.9004 26.2428 28.8923 26.3703 28.8574 26.4917C28.8225 26.613 28.7615 26.7253 28.6788 26.8208C28.596 26.9162 28.4935 26.9924 28.3783 27.0442C28.2631 27.096 28.138 27.122 28.0117 27.1205H28.0262Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M23.2309 31.2495C23.0394 31.2469 22.8542 31.1811 22.704 31.0625C22.5537 30.9438 22.4469 30.7789 22.4 30.5933C22.3531 30.4076 22.3688 30.2117 22.4446 30.0359C22.5205 29.8601 22.6522 29.7143 22.8194 29.6211C22.9499 29.5492 23.0964 29.5113 23.2454 29.511C23.3788 29.5119 23.5102 29.5436 23.6294 29.6035C23.7486 29.6633 23.8524 29.7499 23.9328 29.8563C24.0132 29.9628 24.0681 30.0863 24.093 30.2174C24.118 30.3484 24.1125 30.4835 24.077 30.612C24.0442 30.7222 23.99 30.8247 23.9174 30.9137C23.8447 31.0027 23.7552 31.0765 23.6539 31.1307C23.5254 31.2059 23.3797 31.2468 23.2309 31.2495Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M33.9951 26.1614C33.8037 26.1587 33.6184 26.093 33.4682 25.9743C33.318 25.8557 33.2111 25.6907 33.1642 25.5051C33.1173 25.3195 33.133 25.1236 33.2088 24.9478C33.2847 24.772 33.4164 24.6262 33.5837 24.533C33.7141 24.4611 33.8606 24.4232 34.0096 24.4229C34.143 24.4238 34.2744 24.4554 34.3936 24.5153C34.5128 24.5752 34.6166 24.6617 34.697 24.7682C34.7774 24.8747 34.8323 24.9982 34.8573 25.1292C34.8823 25.2603 34.8767 25.3953 34.8412 25.5239C34.8098 25.6361 34.7562 25.7409 34.6835 25.832C34.6108 25.9231 34.5206 25.9986 34.4181 26.0541C34.2885 26.1253 34.1429 26.1622 33.9951 26.1614Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M13.985 32.9967H13.872C13.7586 32.9828 13.6491 32.9468 13.5496 32.8906C13.4502 32.8344 13.3628 32.7592 13.2925 32.6692C13.193 32.5411 13.1313 32.3878 13.1145 32.2264C13.0976 32.0651 13.1262 31.9023 13.197 31.7564C13.2679 31.6105 13.3781 31.4873 13.5153 31.4008C13.6525 31.3143 13.8112 31.2679 13.9734 31.2668H14.0893C14.3178 31.294 14.5262 31.4107 14.6688 31.5914C14.7692 31.7196 14.8316 31.8736 14.8489 32.0356C14.8661 32.1976 14.8374 32.3612 14.7661 32.5077C14.6948 32.6543 14.5838 32.7778 14.4456 32.8642C14.3075 32.9507 14.1479 32.9966 13.985 32.9967Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M5.49236 28.9806C5.26578 28.9807 5.04812 28.8923 4.88576 28.7342C4.72339 28.5762 4.62915 28.361 4.62311 28.1345C4.6188 28.0207 4.63735 27.9072 4.67766 27.8007C4.71797 27.6942 4.77923 27.5969 4.85781 27.5144C4.97592 27.387 5.12952 27.2979 5.29878 27.2586C5.46804 27.2193 5.64519 27.2316 5.80737 27.294C5.96955 27.3564 6.10931 27.466 6.20862 27.6085C6.30793 27.7511 6.36223 27.9202 6.36451 28.0939C6.36823 28.2081 6.34943 28.3219 6.30916 28.4288C6.26888 28.5357 6.20794 28.6336 6.12981 28.7169C6.04826 28.8035 5.94928 28.8717 5.83939 28.9172C5.7295 28.9626 5.61122 28.9842 5.49236 28.9806Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M0.963572 20.7374C0.733032 20.7581 0.503693 20.6864 0.326005 20.5381C0.148318 20.3897 0.0368376 20.1769 0.016089 19.9463C-0.00465954 19.7158 0.0670232 19.4865 0.215368 19.3088C0.363712 19.1311 0.576567 19.0196 0.807107 18.9989C0.859079 18.9934 0.911431 18.9924 0.963572 18.996C1.07772 18.9853 1.19286 18.9972 1.3024 19.0311C1.41193 19.0649 1.51374 19.12 1.60199 19.1932C1.69024 19.2664 1.76321 19.3562 1.81674 19.4576C1.87026 19.559 1.9033 19.6699 1.91395 19.7841C1.92461 19.8982 1.91267 20.0134 1.87883 20.1229C1.84499 20.2324 1.78991 20.3343 1.71672 20.4225C1.64354 20.5108 1.55369 20.5837 1.4523 20.6373C1.35092 20.6908 1.23998 20.7238 1.12583 20.7345C1.07196 20.7406 1.01763 20.7416 0.963572 20.7374Z",
    fill: "#4CA950"
  }), _jsx("path", {
    d: "M4.15081 17.3124C4.02226 17.3072 3.89605 17.2763 3.77961 17.2215C3.66317 17.1668 3.55885 17.0893 3.4728 16.9937C3.34405 16.8509 3.25921 16.674 3.22845 16.4842C3.19769 16.2944 3.22232 16.0997 3.29939 15.9236C3.37645 15.7474 3.50269 15.5973 3.66295 15.491C3.82321 15.3848 4.01071 15.3271 4.20297 15.3247H4.26382C4.39468 15.3309 4.52297 15.3633 4.64114 15.4198C4.75931 15.4764 4.86498 15.556 4.95192 15.654C5.03885 15.752 5.10531 15.8664 5.14736 15.9905C5.18942 16.1146 5.20623 16.2458 5.19681 16.3765C5.18559 16.6291 5.07708 16.8676 4.89399 17.0421C4.71091 17.2165 4.46744 17.3134 4.21456 17.3124H4.15081Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M4.75932 14.4235C4.65111 14.4244 4.54348 14.4078 4.44059 14.3743C4.31693 14.3374 4.20194 14.276 4.1024 14.1939C4.00286 14.1118 3.92081 14.0106 3.86109 13.8962C3.75993 13.705 3.72406 13.4861 3.75896 13.2727C3.79386 13.0593 3.89761 12.8631 4.05438 12.7142C4.21115 12.5652 4.41234 12.4717 4.62725 12.4477C4.84217 12.4238 5.05901 12.4708 5.2447 12.5816C5.4304 12.6924 5.57475 12.861 5.65574 13.0615C5.73673 13.262 5.74991 13.4835 5.69327 13.6921C5.63663 13.9008 5.51328 14.0853 5.34204 14.2173C5.1708 14.3494 4.96106 14.4218 4.74483 14.4235H4.75932Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M5.99943 11.7638C5.81236 11.7627 5.62944 11.7085 5.47208 11.6073C5.35987 11.542 5.262 11.4547 5.18433 11.3506C5.10666 11.2466 5.05079 11.1279 5.02007 11.0017C4.98674 10.8553 4.98664 10.7033 5.01976 10.5568C5.05289 10.4103 5.11842 10.2731 5.21152 10.1553C5.30462 10.0374 5.42294 9.94195 5.55778 9.87583C5.69262 9.80971 5.84056 9.77464 5.99074 9.77319C6.25509 9.77319 6.50861 9.87821 6.69554 10.0651C6.88246 10.2521 6.98748 10.5056 6.98748 10.7699C6.98748 11.0343 6.88246 11.2878 6.69554 11.4747C6.50861 11.6617 6.25509 11.7667 5.99074 11.7667L5.99943 11.7638Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M7.86833 9.48912C7.60372 9.48606 7.35111 9.3782 7.16589 9.18919C6.98068 9.00017 6.87797 8.74542 6.88029 8.48079C6.88706 8.21891 6.99548 7.96995 7.18261 7.78661C7.36973 7.60327 7.62086 7.49996 7.88282 7.49854C8.01704 7.49809 8.14991 7.5254 8.27307 7.57876C8.39623 7.63211 8.50703 7.71035 8.5985 7.80857C8.68921 7.90293 8.7602 8.01442 8.80734 8.13652C8.85448 8.25862 8.87681 8.3889 8.87303 8.51973C8.86925 8.65056 8.83945 8.77932 8.78534 8.89851C8.73124 9.01769 8.65394 9.12489 8.55794 9.21386C8.37194 9.39075 8.12502 9.48931 7.86833 9.48912Z",
    fill: "#4CA950"
  }), _jsx("path", {
    d: "M10.2356 7.75362C10.0841 7.75162 9.93502 7.7151 9.79974 7.64684C9.66446 7.57859 9.54653 7.48038 9.4549 7.35971C9.36328 7.23903 9.30037 7.09905 9.27096 6.95041C9.24156 6.80177 9.24643 6.64838 9.2852 6.5019C9.33912 6.31414 9.44703 6.14636 9.59551 6.01941C9.74399 5.89247 9.92651 5.81195 10.1204 5.78787C10.3142 5.7638 10.5109 5.79722 10.6859 5.88398C10.8609 5.97074 11.0066 6.10703 11.1048 6.2759C11.1691 6.38978 11.2101 6.51533 11.2253 6.64521C11.2406 6.77509 11.2298 6.90671 11.1937 7.03239C11.1576 7.15807 11.0968 7.2753 11.0148 7.37724C10.9329 7.47918 10.8315 7.56379 10.7166 7.62613C10.5693 7.70791 10.404 7.75173 10.2356 7.75362Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M12.9795 6.66109C12.8054 6.66186 12.6341 6.61688 12.4829 6.53064C12.3316 6.44439 12.2056 6.31992 12.1176 6.16969C12.0296 6.01946 11.9826 5.84873 11.9813 5.67461C11.98 5.50049 12.0245 5.32909 12.1103 5.17757C12.1772 5.06231 12.2665 4.96163 12.373 4.8815C12.4796 4.80138 12.601 4.74342 12.7303 4.71107C12.8127 4.68995 12.8974 4.67924 12.9824 4.6792C13.2227 4.6813 13.4541 4.77066 13.6334 4.93063C13.8128 5.09061 13.9278 5.3103 13.9573 5.54881C13.9867 5.78732 13.9284 6.0284 13.7933 6.22715C13.6582 6.4259 13.4555 6.56879 13.2229 6.62922C13.1433 6.64949 13.0616 6.66019 12.9795 6.66109Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M15.9176 6.29041C15.7873 6.29234 15.6579 6.26824 15.537 6.21953C15.4161 6.17081 15.3062 6.09846 15.2136 6.00671C15.1211 5.91496 15.0477 5.80565 14.998 5.68521C14.9482 5.56476 14.9229 5.43559 14.9237 5.30526C14.9228 5.17582 14.9479 5.04751 14.9977 4.92802C15.0475 4.80853 15.1209 4.70031 15.2135 4.60986C15.3529 4.47776 15.5275 4.38863 15.7163 4.35315C15.9051 4.31766 16.1001 4.33731 16.278 4.40976C16.4559 4.4822 16.6091 4.60437 16.7194 4.76164C16.8297 4.91891 16.8923 5.10463 16.8998 5.29657C16.901 5.42643 16.8764 5.55523 16.8276 5.67557C16.7788 5.79591 16.7067 5.90541 16.6154 5.99777C16.5241 6.09014 16.4155 6.16354 16.2957 6.21376C16.1759 6.26399 16.0474 6.29003 15.9176 6.29041Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M18.8382 6.6584C18.7523 6.65854 18.6666 6.64783 18.5833 6.62653C18.3509 6.56619 18.1483 6.42354 18.0132 6.22508C17.8781 6.02663 17.8196 5.78586 17.8486 5.54754C17.8777 5.30922 17.9923 5.08954 18.1711 4.92933C18.3499 4.76913 18.5808 4.67929 18.8209 4.67651C18.9059 4.6765 18.9906 4.68721 19.0729 4.70839C19.2008 4.73949 19.3212 4.79576 19.4271 4.8739C19.533 4.95204 19.6223 5.05049 19.6897 5.16351C19.7572 5.27653 19.8014 5.40185 19.8199 5.53215C19.8383 5.66245 19.8307 5.79513 19.7973 5.92244C19.7433 6.13499 19.6194 6.32318 19.4454 6.45669C19.2714 6.5902 19.0575 6.66125 18.8382 6.6584Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M21.5793 7.74483C21.4099 7.74354 21.2435 7.6997 21.0954 7.61734C20.8664 7.49417 20.6947 7.28612 20.6173 7.03784C20.5744 6.89011 20.5662 6.73444 20.5935 6.58303C20.6207 6.43161 20.6827 6.28857 20.7744 6.16509C20.8662 6.0416 20.9853 5.94104 21.1224 5.87128C21.2595 5.80151 21.411 5.76443 21.5648 5.76294C21.7349 5.76285 21.9024 5.80571 22.0516 5.88753C22.2441 5.99293 22.3963 6.15929 22.4841 6.36051C22.5719 6.56172 22.5904 6.78639 22.5366 6.99926C22.4829 7.21212 22.3601 7.40112 22.1873 7.53658C22.0146 7.67205 21.8017 7.74632 21.5822 7.74773L21.5793 7.74483Z",
    fill: "#4CA950"
  }), _jsx("path", {
    d: "M24.9433 25.5152C24.6886 25.5015 24.4489 25.3906 24.2734 25.2055C24.098 25.0204 24.0002 24.775 24.0002 24.5199C24.0002 24.2649 24.098 24.0195 24.2734 23.8344C24.4489 23.6493 24.6886 23.5384 24.9433 23.5247C25.1875 23.528 25.4222 23.6201 25.6035 23.7838C25.7848 23.9475 25.9003 24.1716 25.9284 24.4142C25.9421 24.5533 25.9267 24.6937 25.883 24.8265C25.8394 24.9593 25.7686 25.0816 25.6751 25.1854C25.5815 25.2893 25.4674 25.3726 25.3399 25.4298C25.2124 25.4871 25.0743 25.5172 24.9346 25.5181L24.9433 25.5152Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M22.7875 27.5289C22.632 27.5299 22.4784 27.4936 22.3398 27.423C22.2012 27.3524 22.0815 27.2497 21.9907 27.1233C21.9127 27.0181 21.8565 26.8983 21.8255 26.7711C21.7944 26.6438 21.7891 26.5117 21.8099 26.3823C21.8307 26.253 21.8772 26.1292 21.9465 26.0181C22.0159 25.907 22.1068 25.8109 22.2138 25.7354C22.382 25.6118 22.5846 25.5439 22.7933 25.5413C22.9351 25.5428 23.0749 25.5745 23.2034 25.6343C23.3319 25.6941 23.4462 25.7807 23.5386 25.8882C23.6311 25.9957 23.6995 26.1216 23.7393 26.2577C23.7792 26.3937 23.7896 26.5367 23.7698 26.6771C23.7326 26.9136 23.6125 27.1292 23.431 27.2853C23.2494 27.4413 23.0183 27.5277 22.7788 27.5289H22.7875Z",
    fill: "#F4B824"
  }), _jsx("path", {
    d: "M20.2117 28.9401C19.9818 28.9382 19.7597 28.8564 19.5835 28.7088C19.4072 28.5611 19.2878 28.3568 19.2457 28.1307C19.2036 27.9047 19.2414 27.6711 19.3526 27.4699C19.4638 27.2686 19.6415 27.1123 19.8553 27.0278C19.9742 26.9802 20.101 26.9556 20.2291 26.9553C20.4267 26.955 20.6197 27.0147 20.7827 27.1265C20.9456 27.2382 21.0708 27.3968 21.1418 27.5812C21.1919 27.6999 21.2177 27.8275 21.2177 27.9564C21.2177 28.0853 21.1919 28.2129 21.1418 28.3316C21.0638 28.5129 20.9344 28.6674 20.7696 28.776C20.6049 28.8846 20.4119 28.9427 20.2146 28.943L20.2117 28.9401Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M17.3518 29.6674C17.1016 29.6638 16.8619 29.5656 16.6811 29.3926C16.5002 29.2195 16.3916 28.9845 16.3769 28.7346C16.3622 28.4848 16.4426 28.2386 16.602 28.0456C16.7613 27.8526 16.9878 27.727 17.2359 27.6942H17.3634C17.6058 27.6913 17.8407 27.7786 18.0222 27.9393C18.2038 28.1 18.3191 28.3224 18.3457 28.5634C18.3637 28.6908 18.3561 28.8205 18.3232 28.9449C18.2904 29.0693 18.233 29.1859 18.1544 29.2878C18.0721 29.3913 17.9701 29.4774 17.8542 29.5411C17.7383 29.6048 17.6108 29.6447 17.4793 29.6587L17.3518 29.6674Z",
    fill: "#7ECBEF"
  }), _jsx("path", {
    d: "M14.4312 29.6587H14.3008C14.0398 29.6264 13.8022 29.492 13.6402 29.2849C13.5264 29.1387 13.4559 28.9635 13.4367 28.7792C13.4175 28.5949 13.4503 28.4089 13.5315 28.2424C13.6126 28.0758 13.7389 27.9353 13.8959 27.8369C14.0529 27.7385 14.2343 27.686 14.4196 27.6855C14.464 27.6826 14.5085 27.6826 14.5529 27.6855C14.8133 27.7174 15.0507 27.8506 15.2135 28.0564C15.3271 28.2031 15.3974 28.3787 15.4163 28.5633C15.4353 28.7479 15.4022 28.9342 15.3208 29.1009C15.2394 29.2677 15.1129 29.4083 14.9557 29.5069C14.7984 29.6055 14.6167 29.6581 14.4312 29.6587Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M11.5742 28.9198C11.4452 28.92 11.3173 28.8954 11.1975 28.8474C10.9837 28.7628 10.806 28.6065 10.6948 28.4053C10.5836 28.2041 10.5458 27.9704 10.5879 27.7444C10.6301 27.5184 10.7495 27.3141 10.9257 27.1664C11.1019 27.0187 11.324 26.937 11.5539 26.9351C11.6829 26.935 11.8108 26.9596 11.9306 27.0075C12.1753 27.1031 12.3722 27.2916 12.4782 27.5319C12.5433 27.6827 12.5701 27.8473 12.5561 28.0109C12.542 28.1746 12.4877 28.3322 12.3978 28.4697C12.308 28.6072 12.1855 28.7202 12.0412 28.7988C11.897 28.8774 11.7355 28.9189 11.5713 28.9198H11.5742Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M9.00702 27.4971C8.7934 27.4973 8.58529 27.4293 8.41303 27.303C8.29405 27.2329 8.19103 27.1387 8.11058 27.0264C8.03014 26.9142 7.97405 26.7863 7.9459 26.6511C7.91776 26.5159 7.91819 26.3763 7.94716 26.2413C7.97613 26.1063 8.03301 25.9788 8.11414 25.867C8.19527 25.7553 8.29886 25.6617 8.41828 25.5923C8.53769 25.523 8.67029 25.4793 8.80756 25.4642C8.94483 25.4491 9.08374 25.4628 9.21539 25.5045C9.34704 25.5463 9.46852 25.615 9.57203 25.7065C9.67698 25.7811 9.76617 25.8758 9.8345 25.985C9.90282 26.0941 9.94893 26.2157 9.9702 26.3428C9.99146 26.4698 9.98745 26.5998 9.95841 26.7253C9.92936 26.8508 9.87585 26.9693 9.80093 27.0741C9.7131 27.2039 9.59484 27.3104 9.45647 27.3841C9.3181 27.4578 9.16381 27.4966 9.00702 27.4971Z",
    fill: "#4CA950"
  }), _jsx("path", {
    d: "M6.85996 25.4776C6.69148 25.4785 6.52551 25.4367 6.37755 25.3561C6.2296 25.2755 6.1045 25.1587 6.01393 25.0167C5.92336 24.8746 5.87028 24.7119 5.85965 24.5437C5.84902 24.3756 5.88119 24.2075 5.95316 24.0551C6.02512 23.9028 6.13453 23.7712 6.27115 23.6726C6.40778 23.574 6.56717 23.5117 6.73443 23.4914C6.90169 23.4711 7.07136 23.4935 7.22759 23.5566C7.38382 23.6197 7.52151 23.7214 7.6278 23.8521C7.7471 23.997 7.82293 24.1727 7.84651 24.359C7.87008 24.5452 7.84044 24.7343 7.76102 24.9043C7.68159 25.0744 7.55562 25.2185 7.3977 25.32C7.23978 25.4214 7.05635 25.4761 6.86865 25.4776H6.85996Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M5.28086 22.9886C5.01651 22.9886 4.76298 22.8836 4.57606 22.6967C4.38913 22.5097 4.28412 22.2562 4.28412 21.9919C4.28412 21.7275 4.38913 21.474 4.57606 21.2871C4.76298 21.1001 5.01651 20.9951 5.28086 20.9951C5.46991 20.9942 5.65522 21.0478 5.81455 21.1496C5.97388 21.2513 6.10045 21.3969 6.17908 21.5688C6.29189 21.8057 6.30647 22.0775 6.21965 22.3251C6.17614 22.4483 6.10832 22.5616 6.02023 22.6582C5.93213 22.7547 5.82555 22.8326 5.70679 22.8872C5.5745 22.9533 5.42874 22.988 5.28086 22.9886Z",
    fill: "#0193D7"
  }), _jsx("path", {
    d: "M4.37682 20.1752C4.19737 20.171 4.02234 20.1187 3.8701 20.0236C3.71786 19.9285 3.59404 19.7941 3.51164 19.6347C3.42924 19.4752 3.3913 19.2965 3.4018 19.1173C3.4123 18.9381 3.47085 18.7651 3.57132 18.6163C3.64401 18.5083 3.73763 18.416 3.84662 18.3448C3.95561 18.2737 4.07777 18.2251 4.20587 18.202C4.26798 18.1904 4.33103 18.1846 4.39421 18.1846C4.65818 18.1846 4.91133 18.2894 5.09798 18.4761C5.28464 18.6627 5.3895 18.9159 5.3895 19.1799C5.3895 19.4438 5.28464 19.697 5.09798 19.8836C4.91133 20.0703 4.65818 20.1752 4.39421 20.1752H4.37682Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M57.3055 30.0439H59.9132V30.4843H56.8071V25.6021H59.835V26.0425H57.3026V27.7201H59.6699V28.1605H57.3026L57.3055 30.0439Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M65.0447 29.76V25.6021H65.5402V30.4843H64.9201L62.1008 26.283V30.4843H61.6053V25.6021H62.2254L65.0447 29.76Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M68.0378 30.0439H70.6456V30.4843H67.5394V25.6021H70.5673V26.0425H68.0349V27.7201H70.4022V28.1605H68.0349L68.0378 30.0439Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M72.8216 30.4844H72.3261V25.6021H73.7749C73.9989 25.6008 74.2224 25.6242 74.4413 25.6716C74.6307 25.7112 74.8108 25.7869 74.9715 25.8947C75.12 25.9971 75.2397 26.1357 75.3192 26.2975C75.4071 26.487 75.4498 26.6943 75.4438 26.9031C75.4458 27.0647 75.4149 27.2251 75.3532 27.3745C75.2914 27.5239 75.2 27.6592 75.0845 27.7723C74.9707 27.8824 74.837 27.97 74.6905 28.0302C74.5325 28.0964 74.3655 28.1384 74.195 28.1548L75.6235 30.4728H75.0179L73.6648 28.1896H72.8216V30.4844ZM72.8216 27.7752H73.6908C74.0136 27.7947 74.3349 27.7191 74.6151 27.5579C74.7213 27.4869 74.8072 27.3894 74.8641 27.2751C74.921 27.1607 74.9471 27.0335 74.9397 26.906C74.9438 26.7654 74.9129 26.626 74.8498 26.5003C74.7909 26.3909 74.7051 26.2982 74.6007 26.2309C74.4825 26.1575 74.3519 26.1064 74.2153 26.0802C74.0494 26.0463 73.8804 26.0298 73.7111 26.0309H72.8419L72.8216 27.7752Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M80.839 26.4946C80.6683 26.315 80.462 26.1729 80.2334 26.0774C79.9766 25.9744 79.7016 25.9241 79.425 25.9296C79.1339 25.9246 78.8452 25.9829 78.5789 26.1005C78.3366 26.2093 78.1196 26.3671 77.9415 26.5641C77.7651 26.7623 77.6284 26.9925 77.5387 27.2422C77.3505 27.7676 77.3505 28.3422 77.5387 28.8677C77.6277 29.1168 77.7645 29.3461 77.9415 29.5428C78.1201 29.7384 78.3371 29.8952 78.5789 30.0035C78.845 30.1221 79.1337 30.1814 79.425 30.1773C79.6808 30.1797 79.9359 30.1495 80.1841 30.0875C80.3814 30.0411 80.5701 29.9638 80.7434 29.8586V28.3664H79.6046V27.926H81.2388V30.131C80.9686 30.2934 80.6756 30.4145 80.3696 30.4903C80.0561 30.5698 79.7339 30.6098 79.4105 30.6091C79.0611 30.6119 78.7145 30.5459 78.3906 30.4149C78.0879 30.294 77.8132 30.1123 77.5834 29.8811C77.3537 29.6499 77.1738 29.374 77.0548 29.0705C76.9249 28.7433 76.8599 28.3939 76.8636 28.0419C76.86 27.6896 76.926 27.34 77.0577 27.0132C77.1795 26.7105 77.3611 26.4354 77.5916 26.2044C77.8221 25.9734 78.0968 25.7913 78.3993 25.6688C78.7201 25.5375 79.0639 25.4715 79.4105 25.4747C79.7587 25.4676 80.1049 25.5276 80.4304 25.6514C80.7093 25.7611 80.9627 25.9268 81.1751 26.1382L80.839 26.4946Z",
    fill: "#8E9A9A"
  }), _jsx("path", {
    d: "M84.5449 30.4843H84.0494V28.3808L82.2211 25.6021H82.8411L84.3102 27.9693L85.7937 25.6021H86.3732L84.5449 28.3808V30.4843Z",
    fill: "#8E9A9A"
  })]
}), 'CleanChoiceEnergyLogo', 'Company');
export default CleanChoiceEnergyLogo;