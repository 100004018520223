/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AttributionLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 171,
  height: 32,
  viewBox: "0 0 171 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M47.0331 22.7429H39.9567V25.0349H47.0331V22.7429Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M169.128 22.7429V14.893C169.128 12.6011 167.589 11.4551 165.69 11.4551H164.105C163.045 11.5257 161.627 12.4196 160.782 13.5281V11.5888H158.413H156.351V13.8807H158.413V22.7429H156.351V25.0349H162.739V22.7429H160.782V14.9312C161.126 14.1672 162.233 13.7279 163.016 13.747H165.461C166.415 13.747 166.607 14.1856 166.607 14.893V21.597C166.607 21.6311 166.607 22.217 166.606 22.7429H164.716V25.0349H170.532V22.7429H169.128Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M51.0153 8.50404C50.5856 7.38672 50.0413 7.38672 50.0413 7.38672H48.5228H48.2936H47.3482C47.3482 7.38672 46.8039 7.38672 46.3741 8.50404L41.3605 24.1466L44.0822 23.8888L45.428 19.5627H51.7227L52.7193 22.7428H50.1848V25.0347H57.2612V22.7428H55.5795L51.0153 8.50404ZM46.1231 17.3281L48.5634 9.48256L51.0222 17.3281H46.1231Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M62.5897 22.9721C61.8448 23.0008 61.7875 22.2559 61.7875 22.2559V13.7757H65.1108V11.4837H61.7875V8.07446H59.295V11.4837H57.2609V13.7757H59.295V22.2559C59.295 22.2559 59.1804 25.2668 61.6443 25.2354C61.9666 25.2314 63.2394 25.3606 65.1306 24.5584L65.1494 22.9909C65.1494 22.9912 62.8145 22.9636 62.5897 22.9721Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M71.987 22.9721C71.2421 23.0008 71.1848 22.2559 71.1848 22.2559V13.7757H74.5082V11.4837H71.1848V8.07446H68.6923V11.4837H66.6582V13.7757H68.6923V22.2559C68.6923 22.2559 68.5777 25.2668 71.0416 25.2354C71.3642 25.2314 72.6367 25.3606 74.5279 24.5584L74.5467 22.9909C74.5464 22.9912 72.2114 22.9636 71.987 22.9721Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M130.604 22.9721C129.859 23.0008 129.802 22.2559 129.802 22.2559V13.7757H133.125V11.4837H129.802V8.07446H127.309V11.4837H125.275V13.7757H127.309V22.2559C127.309 22.2559 127.195 25.2668 129.659 25.2354C129.981 25.2314 131.254 25.3606 133.145 24.5584L133.163 22.9909C133.163 22.9912 130.829 22.9636 130.604 22.9721Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M80.6773 13.4616V13.4223V11.5888H75.9311V13.8807H78.118V22.7429H75.9311V25.0349H82.8165V22.7429H80.6773V14.6901C82.1562 14.1034 83.59 14.1768 83.59 14.1768H85.2517V11.4551H83.59C82.5034 11.4551 81.5089 12.5137 80.6773 13.4616Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M105.02 11.4551H103.014C101.847 11.4551 100.619 12.1737 99.7483 13.203V9.22042V8.93392V6.92847H94.9925V9.22042H97.1698V22.7429H94.9925V25.0349H99.7483V24.2613V23.3701C100.502 24.2736 101.524 24.9602 102.642 25.0349H105.02C106.918 25.0349 108.458 23.8889 108.458 21.597V14.893C108.458 12.5151 106.919 11.4551 105.02 11.4551ZM105.937 21.597C105.937 22.3043 105.745 22.7429 104.791 22.7429H101.868C101.373 22.7429 100.499 22.6464 99.7483 22.0264V14.5707C100.39 14.1287 101.442 13.747 101.868 13.747H104.791C105.765 13.747 105.937 14.1164 105.937 14.893V21.597Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M122.85 13.747V13.5369V11.4551H118.113V13.747H120.481V21.6925C120.137 22.4564 119.03 22.8957 118.247 22.8766H115.802C114.848 22.8766 114.656 22.438 114.656 21.7307V15.0267C114.656 14.9292 114.659 13.0042 114.665 12.9196C114.659 12.9169 114.653 12.9148 114.646 12.9118V11.4551H110.091V13.747H112.135V21.7307C112.135 24.0226 113.674 25.1686 115.573 25.1686H117.158C118.218 25.098 119.636 24.2041 120.481 23.0956V25.0349H122.85H124.912V22.7429H122.85V13.747Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M151.146 11.522H150.229H149.226H146.705C144.807 11.522 143.267 12.582 143.267 14.9599V21.6639C143.267 23.9558 144.807 25.1018 146.705 25.1018H149.226H149.771H151.146C153.045 25.1018 154.584 23.9558 154.584 21.6639V14.9599C154.584 12.582 153.045 11.522 151.146 11.522ZM152.063 21.6639C152.063 22.3712 151.871 22.8098 150.917 22.8098H150H149.226H146.935C145.98 22.8098 145.789 22.3712 145.789 21.6639V14.9599C145.789 14.1833 145.96 13.8139 146.935 13.8139H149.226H150H150.917C151.891 13.8139 152.063 14.1833 152.063 14.9599V21.6639Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M91.4782 13.8808V13.4224V11.5889H86.7319V13.8808H88.9188V22.743H86.7319V25.035H93.6173V22.743H91.4782V13.8808Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M139.839 13.8808V13.4224V11.5889H135.092V13.8808H137.279V22.743H135.092V25.035H141.978V22.743H139.839V13.8808Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M91.5546 6.87109H88.575V9.76468H91.5546V6.87109Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M139.915 6.87109H136.936V9.76468H139.915V6.87109Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M23.0344 29.7334C26.2623 29.7334 28.8789 27.1167 28.8789 23.8889C28.8789 20.6611 26.2623 18.0444 23.0344 18.0444C19.8066 18.0444 17.19 20.6611 17.19 23.8889C17.19 27.1167 19.8066 29.7334 23.0344 29.7334Z",
    fill: "#5EBE8A"
  }), _jsx("path", {
    d: "M21.4963 11.6679C22.3274 10.5973 22.9195 9.30667 22.9195 7.84521C22.9195 4.61738 20.3029 2.00073 17.075 2.00073C15.1061 2.00073 13.7756 2.91001 12.5972 4.08907C9.37858 7.3094 13.8363 13.7238 3.72033 18.7119C3.46078 18.8131 3.2101 18.9325 2.96999 19.0683C1.19714 20.072 0 21.9738 0 24.1563C0 27.3841 2.61664 30.0007 5.84448 30.0007C9.07231 30.0007 11.689 27.3841 11.689 24.1563C11.689 23.983 11.6859 23.425 11.6975 23.3179C12.6668 14.3844 19.7343 13.938 21.4963 11.6679Z",
    fill: "#5EBE8A"
  })]
}), 'AttributionLogo', 'Company');
export default AttributionLogo;