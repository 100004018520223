/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EarnestLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 118,
  height: 32,
  viewBox: "0 0 118 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M9.85176 9.45923C4.97762 9.45923 0.437988 13.5115 0.437988 19.628C0.437988 26.1818 5.08514 29.9951 10.3296 29.9951C14.9768 29.9951 18.0351 27.2546 19.0672 23.8785L15.1751 22.6457C14.5348 24.5547 13.1012 26.0241 10.3296 26.0241C8.96076 26.0517 7.63659 25.5365 6.64634 24.591C5.65609 23.6456 5.08023 22.3466 5.04452 20.978H19.1867C19.2788 20.4938 19.3188 20.0012 19.3062 19.5085C19.3133 13.392 15.8106 9.45923 9.85176 9.45923ZM5.16399 17.6019C5.25472 16.4182 5.79529 15.3141 6.67466 14.5165C7.55402 13.7189 8.70543 13.2883 9.89238 13.3131C13.0701 13.3131 14.5801 15.3369 14.6709 17.6019H5.16399Z",
    fill: "#11B580"
  }), _jsx("path", {
    d: "M37.1015 16.6892C37.1015 12.8353 34.8365 9.45923 28.8776 9.45923C24.0991 9.45923 21.0934 12.4387 20.735 15.7741L24.9449 16.7298C25.1432 14.7825 26.4931 13.2367 28.9183 13.2367C31.4581 13.2367 32.5715 14.5484 32.5715 16.1779C32.5715 16.8517 32.2537 17.4084 31.1379 17.5685L26.1706 18.3211C22.8758 18.7989 20.3312 20.7104 20.3312 24.1605C20.3312 27.2188 22.8351 29.9999 26.8874 29.9999C30.0245 29.9999 31.9311 28.411 32.8462 26.9416C32.8462 28.4899 33.0039 29.2855 33.0445 29.405H37.3333C37.1632 28.3786 37.0832 27.3392 37.0944 26.2989L37.1015 16.6892ZM32.5619 21.3746C32.5619 25.1496 30.3375 26.3921 27.7547 26.3921C25.9293 26.3921 24.9353 25.1974 24.9353 23.8905C24.9353 22.2992 26.087 21.5012 27.5181 21.2623L32.5619 20.5097V21.3746Z",
    fill: "#11B580"
  }), _jsx("path", {
    d: "M44.1619 13.1532V10.0471H39.6796V29.4003H44.2885V20.1848C44.2885 16.5675 45.918 14.5031 49.4923 14.5031C49.998 14.5052 50.5027 14.5451 51.0024 14.6226V9.93721C50.5961 9.85837 50.1833 9.81837 49.7695 9.81775C47.2297 9.81775 45.0841 11.0482 44.1619 13.1532Z",
    fill: "#11B580"
  }), _jsx("path", {
    d: "M62.877 9.49988C60.7266 9.49988 58.4282 10.4126 57.1571 12.637V10.0542H52.6676V29.4074H57.2765V18.2375C57.2765 15.6953 58.6671 13.6692 61.2881 13.6692C64.1863 13.6692 65.2591 15.5806 65.2591 17.9699V29.4098H69.868V17.1647C69.868 12.9142 67.603 9.49988 62.877 9.49988Z",
    fill: "#11B580"
  }), _jsx("path", {
    d: "M80.9568 9.45923C76.0683 9.45923 71.5406 13.521 71.5406 19.628C71.5406 26.1818 76.1878 29.9951 81.4323 29.9951C86.0794 29.9951 89.1377 27.2546 90.1723 23.8785L86.2777 22.6457C85.6422 24.5571 84.2134 26.0241 81.4323 26.0241C80.0624 26.0536 78.7367 25.5392 77.7452 24.5935C76.7538 23.6477 76.1775 22.3477 76.1424 20.978H90.275C90.3684 20.494 90.4085 20.0012 90.3945 19.5085C90.4016 13.392 86.9061 9.45923 80.9568 9.45923ZM76.2619 17.6019C76.352 16.4184 76.8921 15.3143 77.7711 14.5167C78.65 13.719 79.8012 13.2883 80.9879 13.3131C84.1656 13.3131 85.6756 15.3369 85.7545 17.6019H76.2619Z",
    fill: "#11B580"
  }), _jsx("path", {
    d: "M117.274 15.908V11.8176H113.212V8.95042C113.212 7.10706 112.48 5.33919 111.176 4.03573C109.873 2.73227 108.105 2 106.262 2C104.418 2 102.65 2.73227 101.347 4.03573C100.043 5.33919 99.3111 7.10706 99.3111 8.95042C99.3111 9.12484 99.3255 9.29687 99.3374 9.4689C99.2036 9.4689 99.0746 9.45217 98.9336 9.45217C94.922 9.45217 91.8231 12.3193 91.8231 15.6882C91.8231 18.3499 93.6103 20.5743 97.027 21.3293L99.8941 21.9648C101.442 22.2802 102.159 23.0759 102.159 24.1152C102.159 25.3098 101.165 26.342 99.2586 26.342C96.7952 26.342 95.4859 24.7914 95.3258 23.0448L91.3142 24.1558C91.5531 26.342 93.7035 29.9952 99.2968 29.9952C104.224 29.9952 106.608 26.7386 106.608 23.7974C106.608 20.9303 104.697 18.7154 101.046 17.9222L98.1069 17.3249C96.9123 17.0859 96.1597 16.3309 96.1597 15.2987C96.1597 14.1041 97.3137 13.0743 98.9408 13.0743C99.8008 13.0372 100.647 13.3036 101.33 13.8269C101.354 13.8485 101.397 13.8795 101.397 13.8795L103.667 11.679C103.236 11.2672 102.909 10.7597 102.711 10.1976C102.571 9.79998 102.5 9.38146 102.501 8.95998C102.498 8.22268 102.711 7.50071 103.114 6.8831C103.517 6.26549 104.091 5.77924 104.767 5.4843C105.443 5.18935 106.19 5.09862 106.917 5.22327C107.644 5.34792 108.318 5.68252 108.857 6.1858C109.396 6.68908 109.775 7.33905 109.949 8.05557C110.123 8.77208 110.083 9.52381 109.835 10.2181C109.587 10.9123 109.141 11.5188 108.552 11.9626C107.964 12.4065 107.258 12.6684 106.522 12.7159V15.908H108.656V23.8787C108.656 27.4936 110.88 29.6392 114.457 29.6392C116.129 29.6392 116.997 29.3191 117.276 29.202V25.3791C116.752 25.4773 116.22 25.5309 115.687 25.5392C113.979 25.5392 113.224 24.8224 113.224 23.1165V15.908H117.274Z",
    fill: "#11B580"
  })]
}), 'EarnestLogo', 'Company');
export default EarnestLogo;