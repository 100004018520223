/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TuroLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 88,
  height: 32,
  viewBox: "0 0 88 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M64.0089 8.72729C59.9938 8.72729 56.7273 11.9178 56.7273 15.8392C56.7273 20.0077 59.9182 23.2728 63.9914 23.2728C68.0062 23.2728 71.2727 20.0343 71.2727 16.0537C71.2727 14.0682 70.5153 12.2175 69.1402 10.8426C67.7759 9.47842 65.9538 8.72729 64.0089 8.72729ZM67.4523 19.406C66.5339 20.3084 65.3109 20.8053 64.0089 20.8053C62.7404 20.8053 61.4868 20.3141 60.5695 19.4575C59.6166 18.5677 59.0916 17.3652 59.0916 16.0716C59.0916 14.7008 59.5917 13.4545 60.4997 12.5629C61.398 11.6807 62.638 11.1949 63.9914 11.1949C65.3015 11.1949 66.5266 11.6693 67.4405 12.5308C68.3759 13.412 68.8909 14.6251 68.8909 15.9466C68.8909 17.266 68.3798 18.4947 67.4523 19.406Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M10.1819 11.9019H14.8288V23.2728H17.1713V11.9019H21.8182V9.45459H10.1819V11.9019Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M34.4984 20.2368C35.0963 19.7686 35.3871 18.9742 35.3871 17.8084V9.45459H37.818V17.8084C37.818 19.1038 37.7459 20.1118 36.8115 21.2861C35.4586 23.0145 32.9149 23.2728 31.5194 23.2728C28.1887 23.2728 26.7272 21.691 26.2535 21.0109C25.5146 19.9509 25.4543 18.9334 25.4543 17.8084V9.45459H27.9034V17.8084C27.9034 18.692 27.9522 19.3652 28.5851 19.981C29.2011 20.5806 30.261 20.872 31.825 20.872C33.0959 20.872 33.9453 20.6702 34.4984 20.2368Z",
    fill: "#1C0732"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M49.4509 9.45459C52.1039 9.45459 53.818 11.3459 53.818 13.884C53.818 15.849 52.7674 17.5282 50.9219 18.0962L53.8022 23.2728H51.0823L48.3396 18.2598H43.8583V23.2728H41.4543V9.45459H49.4509ZM43.8583 15.8125H49.4509C51.0877 15.8125 51.4316 14.5907 51.4316 13.7767C51.4316 12.6877 50.7651 11.884 49.4509 11.884H43.8583V15.8125Z",
    fill: "#1C0732"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M88 16.0001L77.1142 1.9342L77.1076 1.92512C77.1055 1.92166 77.1033 1.91821 77.1004 1.91489C76.9564 1.72839 76.7978 1.55114 76.624 1.38476C75.6938 0.491899 74.4553 0 73.1367 0C73.1331 0 73.1295 0.000267231 73.1265 0.000532204L73.1185 0.00100593V0H0V32H73.1185V31.999C73.1209 31.9991 73.1233 31.9993 73.1257 31.9994C73.1292 31.9997 73.1328 32 73.1367 32C74.4553 32 75.6938 31.5081 76.624 30.6153C76.7978 30.4489 76.9564 30.2716 77.1004 30.0851C77.1024 30.0827 77.1041 30.0802 77.1059 30.0777C77.1074 30.0757 77.1089 30.0736 77.1105 30.0716C77.1113 30.0697 77.1127 30.0677 77.1142 30.0658L88 16.0001ZM85.0066 16.0001L75.2509 28.6056C75.2466 28.6115 75.2465 28.6118 75.2465 28.6118C75.2465 28.6118 75.2466 28.6116 75.2415 28.6179C75.1687 28.7128 75.0698 28.8281 74.9825 28.912C74.4945 29.3801 73.84 29.6373 73.1367 29.6373C73.1338 29.6373 73.1311 29.6371 73.1284 29.637C73.1256 29.6368 73.1229 29.6367 73.12 29.6367H2.3699V2.36353H73.12C73.1258 2.36353 73.1309 2.36273 73.1367 2.36273C73.84 2.36273 74.4945 2.61984 74.9825 3.088C75.0698 3.17189 75.1687 3.28717 75.2415 3.38213C75.2458 3.388 75.2465 3.38827 75.2465 3.38827C75.2465 3.38827 75.2466 3.388 75.2509 3.39441L85.0066 16.0001Z",
    fill: "#1C0732"
  })]
}), 'TuroLogo', 'Company');
export default TuroLogo;