/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FubotvLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 74,
  height: 32,
  viewBox: "0 0 74 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.24357 9.87817C8.39203 8.93367 8.92337 8.40038 9.91865 8.36326L11.9288 8.3994L12.7463 4.14674C7.39382 3.4474 4.25462 5.22993 3.49863 9.87817L1.00505 9.82347L0.111347 14.0947L2.6606 14.0761L0 27.6009L4.6707 27.5823L7.36842 14.1123L10.029 14.0761L10.8475 9.85961L8.24259 9.87817H8.24357Z",
    fill: "black"
  }), _jsx("path", {
    d: "M23.8586 9.69556L21.9832 18.743C21.5818 20.6798 21.0993 22.6089 19.1146 23.5211C18.4563 23.8239 17.7609 23.9186 17.085 23.8229C14.6471 23.4029 15.0377 21.176 15.4099 19.1688L17.2891 9.67798H12.3771L10.4022 19.959C9.89528 23.0621 10.3563 25.5127 12.3791 26.9113C14.4625 28.2612 17.9543 28.5093 20.8249 26.6525L20.6383 27.5286L24.9554 27.4739L28.5654 9.67798L23.8576 9.69654L23.8586 9.69556Z",
    fill: "black"
  }), _jsx("path", {
    d: "M46.6505 11.0121C47.7034 12.2916 48.1781 13.7548 48.5111 15.3019C50.2477 13.0506 53.6038 13.3182 55.1724 14.6817C56.5183 15.845 56.8944 18.5642 55.8844 20.7042C55.1011 22.3636 53.6731 23.2613 51.8672 23.5357C48.6889 24.0182 46.9904 21.508 46.836 18.8982C46.7999 16.2933 46.586 14.417 45.6054 12.9011C44.9314 11.858 43.8404 10.9076 42.723 10.3441C40.3047 9.1251 36.9037 9.03622 34.5547 10.4652L35.802 4.18579L31.1684 4.16724L26.5533 27.5305L31.1684 27.512L31.4477 26.0703C34.2822 29.0757 40.7637 28.0843 43.0219 26.1065C41.7776 24.8563 41.2511 23.3716 41.0489 21.7805C39.4422 23.8365 36.2913 24.0866 34.666 22.8569C33.1404 21.7932 32.7448 19.6728 33.1414 17.8355C33.4998 16.1761 34.6113 14.7061 36.2337 14.0673C38.1539 13.3113 40.457 13.7948 41.5871 15.2648C43.4898 18.3014 41.9602 20.8273 43.2905 23.5465C44.0827 25.1649 45.6454 26.5392 47.3078 27.2424C49.3081 28.0873 51.7519 28.2504 54.014 27.6057C56.6707 26.8478 58.8547 24.9725 60.059 22.5404C61.4918 19.6474 61.5924 16.0765 60.0883 13.3924C57.5986 8.94831 50.8299 7.86317 46.6475 11.0121H46.6505Z",
    fill: "black"
  }), _jsx("path", {
    d: "M71.3195 4.0188L68.6765 8.87411L68.2301 4.0188L60.3968 4.00024L59.968 5.55129L62.0338 5.51515L60.6566 11.1548L62.4616 11.1372L63.8945 5.49659L66.5394 5.47901L67.356 11.1548L68.993 11.1372L73.2544 4.0188H71.3195Z",
    fill: "black"
  })]
}), 'FubotvLogo', 'Company');
export default FubotvLogo;