/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var QuartzLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 129,
  height: 32,
  viewBox: "0 0 129 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.5538 21.4317C18.7836 19.7712 19.4462 17.7589 19.4435 15.6925C19.4435 10.3494 15.0786 6 9.72142 6C4.36421 6 0 10.352 0 15.6925C0 21.0331 4.35907 25.3851 9.72142 25.3851C11.7503 25.3885 13.7289 24.7537 15.3771 23.5704L17.8163 26L19.9801 23.8445L17.5563 21.4265L17.5538 21.4317ZM15.3546 19.239L11.7601 15.6566L9.59882 17.8153L13.1729 21.3778C12.1319 22.0089 10.9375 22.342 9.72014 22.3406C6.04403 22.3406 3.05668 19.3616 3.05668 15.6964C3.05668 12.0312 6.0466 9.05154 9.7227 9.05154C13.3988 9.05154 16.3836 12.0338 16.3836 15.6957C16.385 16.9504 16.0287 18.1795 15.3566 19.239H15.3546Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M107.184 6.4801V9.56181H101.401V24.9774H98.2709V9.56181H92.4907V6.4801H107.184Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M58.8512 6.4801H55.0923L47.9448 24.9774H51.2262L52.5651 21.3739H61.3802L62.7179 24.9774H66.0005L58.8512 6.4801ZM53.7571 18.4905L56.9749 10.1408L60.1844 18.4905H53.7571Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M128.378 9.56177L118.011 21.854H128.378V24.9774H113.928V21.6909L124.331 9.56177H114.649V6.51086H128.378V9.56177Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M41.8831 6.4801V18.1817C41.8831 22.1493 38.6505 25.3754 34.6734 25.3754C30.6962 25.3754 27.4604 22.148 27.4604 18.1817V6.4801H30.589V18.1817C30.5203 20.4662 32.3831 22.3239 34.6746 22.3239C36.9662 22.3239 38.8238 20.4662 38.7789 18.1817V6.4801H41.8831Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M82.3174 18.7126C83.3907 18.184 84.2949 17.3661 84.928 16.351C85.5611 15.3359 85.8979 14.164 85.9004 12.9677C85.9004 9.57722 83.0979 6.54878 79.6099 6.4801H72.7417V24.9768H75.8716V19.4065H78.7087L85.1636 25.9281L87.3396 23.7842L82.3181 18.7132L82.3174 18.7126ZM79.3801 16.3543H75.8716V9.58107H79.3808C81.2891 9.58107 82.8425 11.0998 82.8425 12.9683C82.8425 14.8369 81.2891 16.3543 79.3808 16.3543H79.3801Z",
    fill: "black"
  })]
}), 'QuartzLogo', 'Company');
export default QuartzLogo;