/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var VwoLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 73,
  height: 32,
  viewBox: "0 0 73 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M60.9819 4C60.5335 4 59.9954 4 59.547 4.08889L56.9463 8.62222C58.1121 7.91111 59.547 7.55556 60.9819 7.55556C65.6452 7.55556 69.5014 11.3778 69.5014 16C69.5014 20.6222 65.6452 24.4444 60.9819 24.4444C56.3186 24.4444 52.552 20.7111 52.4623 16.0889L49.772 20.6222C51.5656 24.9778 55.9598 28 60.8922 28C67.5285 28 72.9989 22.5778 72.9989 16C73.0886 9.42222 67.6181 4 60.9819 4Z",
    fill: "#BF3078"
  }), _jsx("path", {
    d: "M24.3926 16.5333L26.4552 12.9778L31.1185 20.8889L36.4993 11.7333L41.88 20.8889L51.117 5.33333C51.5654 4.53333 52.4622 4 53.4487 4H56.0494L41.88 28L36.4993 18.8444L31.1185 28L24.3926 16.5333Z",
    fill: "#802050"
  }), _jsx("path", {
    d: "M28.249 4L14.0796 28L0 4H2.6007C3.58717 4 4.48396 4.53333 4.93236 5.33333L14.0796 20.9778L23.3166 5.33333C23.765 4.53333 24.6618 4 25.6483 4H28.249Z",
    fill: "#26134D"
  })]
}), 'VwoLogo', 'Company');
export default VwoLogo;