/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DemandCurveLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 164,
  height: 48,
  viewBox: "0 0 164 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#DemandCurveLogo_svg__clip0_8181_54473)",
    children: [_jsx("path", {
      d: "M71.6777 21.1359V6.30884H77.5211C82.1594 6.30884 85.3766 9.26969 85.3766 13.711C85.3766 18.1978 82.1594 21.1359 77.5438 21.1359H71.6777ZM82.1594 13.711C82.1594 11.1031 80.5564 9.08749 77.5438 9.08749H74.8381V18.3572H77.5211C80.4541 18.3572 82.1594 16.2505 82.1594 13.711Z",
      fill: "black"
    }), _jsx("path", {
      d: "M87.4004 21.1359V6.30884H97.882V9.08749H90.5494V12.1964H97.7229V14.975H90.5494V18.3572H97.882V21.1359H87.4004Z",
      fill: "black"
    }), _jsx("path", {
      d: "M112.991 21.1359V10.4426L108.818 21.1359H107.443L103.271 10.4426V21.1359H100.122V6.30884H104.544L108.136 15.5786L111.729 6.30884H116.174V21.1473H112.991V21.1359Z",
      fill: "black"
    }), _jsx("path", {
      d: "M129.111 21.1359L128.179 18.6192H121.824L120.891 21.1359H117.322L123.029 6.29749H126.985L132.692 21.1359H129.111ZM125.007 9.46332L122.699 15.8519H127.315L125.007 9.46332Z",
      fill: "black"
    }), _jsx("path", {
      d: "M144.049 21.1359L136.989 11.4562V21.1359H133.84V6.30884H137.08L143.935 15.6241V6.30884H147.084V21.1473H144.049V21.1359Z",
      fill: "black"
    }), _jsx("path", {
      d: "M149.801 21.1359V6.30884H155.645C160.283 6.30884 163.5 9.26969 163.5 13.711C163.5 18.1978 160.283 21.1359 155.667 21.1359H149.801ZM160.272 13.711C160.272 11.1031 158.669 9.08749 155.656 9.08749H152.95V18.3572H155.633C158.566 18.3572 160.272 16.2505 160.272 13.711Z",
      fill: "black"
    }), _jsx("path", {
      d: "M70.9729 34.0157C70.9729 29.4377 74.4175 26.3402 78.8739 26.3402C82.1139 26.3402 84.0011 28.0939 85.0469 29.9616L82.3413 31.2939C81.716 30.0868 80.3859 29.1416 78.8739 29.1416C76.1682 29.1416 74.2129 31.2142 74.2129 34.0157C74.2129 36.8171 76.1682 38.8897 78.8739 38.8897C80.3859 38.8897 81.716 37.9331 82.3413 36.7374L85.0469 38.047C84.0011 39.8918 82.1139 41.6911 78.8739 41.6911C74.4061 41.6911 70.9729 38.5822 70.9729 34.0157Z",
      fill: "black"
    }), _jsx("path", {
      d: "M86.7065 35.4846V26.5907H89.9011V35.3935C89.9011 37.4661 91.0947 38.8896 93.3912 38.8896C95.6876 38.8896 96.8585 37.4661 96.8585 35.3935V26.5907H100.076V35.4846C100.076 39.1516 97.9044 41.6911 93.3912 41.6911C88.8779 41.6911 86.7065 39.1516 86.7065 35.4846Z",
      fill: "black"
    }), _jsx("path", {
      d: "M111.149 41.4291L108.239 36.1565H105.931V41.4291H102.782V26.5907H109.705C112.786 26.5907 114.696 28.6177 114.696 31.3736C114.696 33.9814 113.036 35.4049 111.433 35.7807L114.787 41.4291H111.149ZM111.456 31.3508C111.456 30.1323 110.501 29.3693 109.262 29.3693H105.931V33.3779H109.262C110.501 33.3779 111.456 32.6149 111.456 31.3508Z",
      fill: "black"
    }), _jsx("path", {
      d: "M120.766 41.4291L115.06 26.5907H118.629L122.733 37.9786L126.837 26.5907H130.407L124.7 41.4291H120.766Z",
      fill: "black"
    }), _jsx("path", {
      d: "M131.566 41.4291V26.5907H142.048V29.3693H134.727V32.4782H141.9V35.2569H134.727V38.6391H142.048V41.4177H131.566V41.4291Z",
      fill: "black"
    }), _jsx("path", {
      d: "M35.8787 32.558C34.81 36.0199 33.1048 39.1971 30.8879 41.9644C35.0033 45.4035 40.2896 47.4761 46.042 47.4761H54.9093V37.227H46.042C41.9835 37.227 38.3456 35.4163 35.8787 32.558Z",
      fill: "#114AFF"
    }), _jsx("path", {
      d: "M19.2808 15.1915C20.4403 11.7751 22.2252 8.64342 24.5102 5.95587C20.338 2.26619 14.8698 0 8.86733 0H0V10.2491H8.86733C13.0623 10.2491 16.8138 12.1737 19.2808 15.1915Z",
      fill: "#114AFF"
    }), _jsx("path", {
      d: "M46.0419 0.512451C32.9683 0.512451 22.3389 11.1715 22.3389 24.2562C22.3389 31.6925 16.3023 37.7509 8.86733 37.7509H0V48H8.86733C21.9296 48 32.5704 37.3523 32.5704 24.2562C32.5704 16.8199 38.607 10.7616 46.0419 10.7616H54.9093V0.512451H46.0419Z",
      fill: "#114AFF"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "DemandCurveLogo_svg__clip0_8181_54473",
      children: _jsx("rect", {
        width: 163.5,
        height: 48,
        fill: "white"
      })
    })
  })]
}), 'DemandCurveLogo', 'Company');
export default DemandCurveLogo;