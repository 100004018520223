/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HotelTonight2Logo = createCompanyLogosComponent(_jsxs("svg", {
  width: 72,
  height: 32,
  viewBox: "0 0 72 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M6.45 0H0V32H6.52166V21.3688H65.0016V32H71.4516V14.9192H6.45V0Z",
    fill: "black"
  }), _jsx("path", {
    d: "M11.144 10.8793H12.4699V7.40639H15.229V10.8438H16.4832V2.83496H15.1932V6.20152H12.3982V2.83496H11.144V10.8793Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M54.4308 10.3831C53.1766 9.74528 53.5349 8.93021 54.5024 8.46953C54.1441 8.22147 53.8933 7.83165 53.7858 7.4064C53.5708 6.37872 54.2516 5.35104 55.2908 5.13841C55.5058 5.10297 55.7566 5.10297 55.9716 5.10297C56.1149 5.10297 56.2583 5.13841 56.4016 5.17385C57.0824 5.3156 57.2616 5.24472 57.5124 4.57141H58.2291C58.5158 5.35103 58.2649 5.74085 57.4766 5.84716C57.6199 6.13066 57.7274 6.4496 57.7991 6.73309C57.9783 7.65447 57.3691 8.5404 56.4374 8.68215C56.3658 8.68215 56.3299 8.68215 56.2583 8.71759C56.0659 8.74137 55.8573 8.73323 55.6543 8.72531C55.5548 8.72142 55.4567 8.71759 55.3624 8.71759C55.1833 8.71759 54.9683 8.75303 54.7891 8.85934C54.6099 8.96565 54.5741 9.17828 54.7174 9.35546C54.7533 9.42634 54.8249 9.46178 54.8966 9.49721C55.1833 9.56809 55.4699 9.63896 55.7924 9.6744C55.9212 9.68598 56.05 9.69377 56.1775 9.70149C56.4403 9.7174 56.6978 9.73299 56.9391 9.78071C57.5124 9.88702 57.9783 10.0997 58.1574 10.7021C58.3366 11.3045 58.1216 11.9424 57.6558 12.3322C57.0466 12.8638 56.1866 13.0764 55.3983 12.8992C54.9683 12.8283 54.5741 12.6866 54.1799 12.5094C53.8574 12.3676 53.6066 12.0841 53.5349 11.7298C53.3916 11.2691 53.6066 10.773 54.0366 10.5958C54.1441 10.5249 54.2875 10.454 54.4308 10.3831ZM54.7891 6.94572C54.7533 7.47728 55.1474 7.93797 55.6849 7.9734C56.2224 8.00884 56.6883 7.61903 56.7241 7.08747V6.98116C56.7958 6.4496 56.4374 5.98891 55.8999 5.91803C55.3624 5.84716 54.8966 6.20153 54.8249 6.73309C54.7891 6.83941 54.7891 6.87484 54.7891 6.94572ZM55.8641 12.0133C56.2941 12.0133 56.5091 11.9424 56.7958 11.7652C57.0466 11.6235 57.1899 11.34 57.0824 11.0565C57.0466 10.8438 56.8674 10.6666 56.6524 10.6312C56.1149 10.4895 55.5416 10.4895 55.0041 10.6312C54.6816 10.7021 54.4666 10.9856 54.5024 11.34C54.5383 11.588 54.7174 11.7298 54.9324 11.8006L54.9669 11.8158C55.2658 11.9475 55.4152 12.0133 55.8641 12.0133Z",
    fill: "black"
  }), _jsx("path", {
    d: "M59.125 2.83496V10.8793L60.3075 10.9147V7.68989C60.3075 7.19377 60.4867 6.73308 60.7733 6.3787C60.9525 6.13064 61.3108 5.98889 61.6333 6.09521C61.9917 6.16608 62.2067 6.44958 62.2067 6.80395V10.8793H63.3533V6.87483C63.3533 6.73308 63.3533 6.62677 63.3175 6.48502C63.2817 5.88258 62.8517 5.38646 62.2783 5.20927C61.6692 5.03208 60.9883 5.24471 60.5942 5.77627C60.4867 5.88258 60.415 5.98889 60.3433 6.09521L60.2717 6.05977V2.83496H59.125Z",
    fill: "black"
  }), _jsx("path", {
    d: "M37.6967 4.00436H35.7258V2.87036H40.9217V3.96892H38.9867V10.8438H37.6608L37.6967 4.00436Z",
    fill: "black"
  }), _jsx("path", {
    d: "M49.0917 10.8439L50.2025 10.8793C50.2025 10.3932 50.2065 9.91098 50.2105 9.43144V9.42902C50.2185 8.47629 50.2263 7.53371 50.2025 6.59138C50.1667 6.09526 49.9158 5.63457 49.4858 5.38651C48.7333 4.92582 47.8017 5.17388 47.3358 5.88263C47.3 5.95351 47.2552 6.02438 47.2104 6.09526C47.1656 6.16613 47.1208 6.23701 47.085 6.30788L47.0133 6.27245L46.9417 5.20932H46.4758L46.01 5.24476V10.8439H47.1567V7.65451C47.1567 7.26469 47.2642 6.87488 47.4792 6.52051C47.6583 6.09526 48.0883 5.88263 48.5183 5.98895C48.8767 6.05982 49.0917 6.37876 49.0917 6.91032V10.8439Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M44.9351 8.00891C44.9235 8.16872 44.9157 8.32485 44.908 8.47848C44.8918 8.80182 44.8762 9.11411 44.8276 9.42641C44.7201 10.2769 44.0034 10.9148 43.1434 10.9502C42.8926 10.9857 42.6776 10.9857 42.4268 10.9502C41.6026 10.9148 40.8859 10.2769 40.8142 9.46185C40.7426 8.54047 40.7426 7.6191 40.8142 6.69773C40.8859 5.88267 41.5309 5.24479 42.3551 5.17392C42.6776 5.13848 43.0359 5.13848 43.3584 5.17392C44.1109 5.28023 44.7201 5.88267 44.8276 6.62685C44.9351 7.08754 44.9351 7.54822 44.9351 8.00891ZM41.8892 8.07979V9.21378C41.8892 9.67447 42.2476 10.0643 42.7492 10.0643H42.8209C43.2867 10.0997 43.6809 9.78078 43.7167 9.3201V9.21378C43.7526 8.43416 43.7526 7.68997 43.7167 6.91035C43.7167 6.41423 43.3226 5.98898 42.7851 5.98898C42.2834 5.98898 41.8534 6.37879 41.8534 6.91035C41.8892 7.30016 41.9251 7.68997 41.8892 8.07979Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.7948 8.46958C21.7908 8.33965 21.7869 8.20971 21.7869 8.07977C21.8227 7.7254 21.8227 7.33559 21.8227 6.98121C21.751 5.81178 20.9627 5.10303 19.7444 5.10303C18.526 5.10303 17.7019 5.84721 17.666 6.98121C17.6302 7.68996 17.6302 8.43414 17.666 9.14289C17.666 10.0288 18.311 10.773 19.171 10.9148C19.4577 10.9502 19.7802 10.9856 20.0669 10.9502C20.9985 10.9148 21.751 10.206 21.7869 9.24921C21.8108 8.98933 21.8028 8.72946 21.7948 8.46958ZM20.676 8.07977C20.676 8.19661 20.6799 8.3173 20.6839 8.43931C20.6919 8.68734 20.7001 8.9408 20.676 9.17833V9.24921C20.6402 9.74533 20.246 10.0997 19.7802 10.0643H19.6727C19.2427 10.0643 18.8485 9.70989 18.8485 9.24921C18.8127 8.46958 18.8127 7.65452 18.8485 6.8749V6.80402C18.9202 6.34334 19.3502 5.98896 19.816 6.05984H19.8519C20.3177 6.09528 20.7119 6.48509 20.676 6.98121V8.07977Z",
    fill: "black"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M30.3152 8.07969H27.4127C27.4127 8.47441 27.4436 8.86912 27.4767 9.29218L27.4844 9.39087C27.556 9.81612 27.9502 10.1351 28.416 10.0642H28.4519C28.9177 10.0996 29.276 9.74525 29.3119 9.28456C29.3119 9.23523 29.3196 9.17827 29.3279 9.11721C29.3374 9.0467 29.3477 8.97071 29.3477 8.89475H30.3152C30.4585 10.0996 29.7419 10.9501 28.5594 10.9856C27.4485 11.021 26.696 10.5957 26.4452 9.70981C26.3735 9.49719 26.3377 9.28456 26.3377 9.0365C26.3377 8.82517 26.3338 8.60995 26.3298 8.39342C26.3218 7.9564 26.3137 7.51397 26.3377 7.08744C26.3377 5.95344 27.3052 5.0675 28.416 5.0675C28.7385 5.0675 29.061 5.13838 29.3477 5.31557C29.921 5.56363 30.3152 6.13063 30.351 6.7685C30.351 7.19375 30.351 7.65444 30.3152 8.07969ZM29.276 7.44181C29.2783 7.40997 29.2808 7.37827 29.2832 7.34675C29.3187 6.88714 29.3513 6.46453 28.8819 6.16607C28.5235 5.95344 28.0935 5.95344 27.7352 6.2015C27.3345 6.4987 27.3694 6.91899 27.405 7.34766L27.4101 7.40916L27.4127 7.44181H29.276Z",
    fill: "black"
  }), _jsx("path", {
    d: "M32.5369 2.83496H31.426V10.8793H32.5369V2.83496Z",
    fill: "black"
  }), _jsx("path", {
    d: "M25.4777 6.23694H24.5818C24.6177 6.75735 24.6057 7.27776 24.5937 7.79554C24.5699 8.82911 24.5462 9.85219 24.9043 10.8438H23.686C23.5785 10.4894 23.5068 10.1705 23.471 9.81612C23.4352 8.82388 23.4352 7.79619 23.4352 6.80394V6.27238L22.7185 6.23694V5.31557L23.471 5.28013V4.25245L24.6177 3.89807V5.28013L25.5135 5.31557L25.4777 6.23694Z",
    fill: "black"
  }), _jsx("path", {
    d: "M64.2493 6.20151L65.0018 6.27238C65.0018 6.44951 65.0018 6.59135 65.0376 6.73307C65.0376 7.79619 65.0376 8.82388 65.0734 9.85156C65.0734 10.2059 65.1451 10.5603 65.3243 10.8438H66.5068C66.1484 9.32 66.0768 7.76075 66.2201 6.23694L67.0801 6.20151V5.31557L66.1484 5.28013V3.89807L65.0376 4.25245V5.28013L64.2493 5.31557V6.20151Z",
    fill: "black"
  }), _jsx("path", {
    d: "M51.4565 5.24475H52.5674V10.8439H51.4565V5.24475Z",
    fill: "black"
  }), _jsx("path", {
    d: "M51.4924 4.21697H52.5674V3.11841H51.4924V4.21697Z",
    fill: "black"
  })]
}), 'HotelTonight2Logo', 'Company');
export default HotelTonight2Logo;