/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StaplesLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 172,
  height: 32,
  viewBox: "0 0 172 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#StaplesLogo_svg__clip0_6690_49496)",
    children: [_jsx("path", {
      d: "M64.4491 10.2838C61.8326 9.73831 59.1462 9.16273 59.1462 7.08527C59.1462 4.79528 61.5902 4.31531 63.6787 4.31531C65.8602 4.32686 68.0124 4.81052 69.9836 5.73217L70.1722 5.8225C70.198 5.8351 70.2278 5.83734 70.2552 5.82875C70.2827 5.82017 70.3057 5.80142 70.3195 5.77645L72.2032 2.48934C72.211 2.47633 72.2159 2.46189 72.2177 2.44691C72.2196 2.43193 72.2182 2.41674 72.2139 2.40228C72.2095 2.38782 72.2021 2.37441 72.1923 2.36288C72.1824 2.35135 72.1702 2.34195 72.1565 2.33526L71.9554 2.23608C69.1126 0.96446 66.4531 0.376465 63.5907 0.376465C58.0346 0.376465 54.443 3.03307 54.443 7.14902C54.443 11.8901 58.4691 13.3655 62.8149 14.251C65.5516 14.8089 68.2022 15.3615 68.2022 17.5239C68.2022 19.86 65.5732 20.3577 63.3321 20.3577C60.9635 20.3577 58.5877 19.674 56.0664 18.2678L55.9874 18.2218C55.9746 18.2142 55.9604 18.2092 55.9456 18.2072C55.9308 18.2052 55.9158 18.2061 55.9014 18.2099C55.887 18.2137 55.8736 18.2202 55.8618 18.2293C55.85 18.2384 55.8402 18.2496 55.833 18.2624L53.8827 21.6789C53.8687 21.704 53.865 21.7333 53.8724 21.761C53.8797 21.7888 53.8976 21.8126 53.9222 21.8277L54.0031 21.8755C56.8521 23.4667 60.0669 24.3119 63.3411 24.3301C69.4251 24.3301 72.9143 21.8383 72.9143 17.4921C72.9107 12.0655 68.0442 11.0347 64.4491 10.2838Z",
      fill: "#CB2026"
    }), _jsx("path", {
      d: "M87.0637 20.0035C87.0586 19.9892 87.0511 19.976 87.041 19.9648C87.0309 19.9536 87.0182 19.9445 87.0047 19.9381C86.9913 19.9316 86.9761 19.928 86.961 19.9273C86.9458 19.9267 86.9306 19.929 86.9163 19.9344L86.6679 20.0282C85.6927 20.4002 85.0216 20.5595 83.6154 20.5595C81.9831 20.5595 80.9218 19.69 80.9218 18.3457V10.1546H86.7454C86.7605 10.1548 86.7748 10.1521 86.7883 10.1467C86.8026 10.1413 86.8144 10.1333 86.8254 10.123C86.8355 10.1128 86.8439 10.1006 86.8498 10.0872C86.8557 10.0737 86.8582 10.0593 86.8582 10.0448V6.73108C86.8582 6.70196 86.8473 6.67402 86.8262 6.65343C86.8052 6.63284 86.7765 6.62127 86.7471 6.62127H80.929V2.30341C80.929 2.27429 80.9173 2.24635 80.8964 2.22576C80.8755 2.20517 80.8472 2.1936 80.8176 2.1936H76.5545C76.5397 2.1936 76.5251 2.1965 76.5114 2.20213C76.4978 2.20775 76.4855 2.216 76.4752 2.22639C76.4648 2.23677 76.4566 2.24909 76.4511 2.26261C76.4456 2.27614 76.4429 2.29061 76.4431 2.30518V6.6195H72.963C72.9482 6.6195 72.9336 6.6224 72.9199 6.62802C72.9063 6.63365 72.8939 6.6419 72.8836 6.65228C72.8732 6.66266 72.865 6.67498 72.8596 6.68851C72.8541 6.70204 72.8514 6.71651 72.8516 6.73108V10.0518C72.8514 10.0666 72.8541 10.0811 72.8597 10.0948C72.8653 10.1084 72.8736 10.1209 72.8842 10.1312C72.8948 10.1417 72.9073 10.1498 72.9211 10.1554C72.935 10.161 72.9498 10.1637 72.9647 10.1634H76.4449V18.7956C76.4449 22.2616 78.7956 24.3301 82.7301 24.3301C85.0165 24.3301 86.3521 23.8608 87.7441 23.2941L88.0077 23.1825C88.033 23.1712 88.054 23.151 88.065 23.1258C88.0759 23.1007 88.0776 23.0723 88.0692 23.0462L87.0637 20.0035Z",
      fill: "#CB2026"
    }), _jsx("path", {
      d: "M38.8144 0.86377H5.95176C2.83789 0.86377 0.458496 3.35921 0.458496 6.43555V23.769C0.458496 23.8004 0.471172 23.8306 0.493737 23.8529C0.5163 23.8751 0.546903 23.8876 0.578813 23.8876H4.82761C4.85952 23.8876 4.89012 23.8751 4.91268 23.8529C4.93525 23.8306 4.94792 23.8004 4.94792 23.769V6.17697H39.82V23.769C39.82 23.8004 39.8327 23.8306 39.8553 23.8529C39.8778 23.8751 39.9084 23.8876 39.9403 23.8876H44.1945C44.2264 23.8876 44.257 23.8751 44.2796 23.8529C44.3022 23.8306 44.3148 23.8004 44.3148 23.769V6.43555C44.3148 3.35921 41.9408 0.86377 38.8144 0.86377Z",
      fill: "#CB2026"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M106.979 20.6551H106.303C105.468 20.6551 105.025 20.2053 105.025 19.3569V12.4994C105.025 8.24881 102.69 6.17666 97.8628 6.17666C95.2009 6.13387 92.5626 6.67846 90.1415 7.77062C90.1163 7.78157 90.0952 7.80143 90.0843 7.82631C90.0733 7.85118 90.0716 7.87931 90.08 7.90522L91.1453 11.1144C91.1495 11.1286 91.1571 11.1418 91.1672 11.153C91.1773 11.1642 91.1899 11.1733 91.2034 11.1798C91.2177 11.1863 91.232 11.1899 91.2472 11.1905C91.2632 11.1912 91.2784 11.1887 91.2918 11.1834C93.6396 10.2678 95.7331 9.7666 97.195 9.7666C98.8539 9.7666 100.539 10.0694 100.539 12.3081V13.2627C100.539 13.2627 100.275 13.2821 94.9466 13.7055C89.6186 14.1287 88.8413 17.809 88.8413 19.3002C88.8413 22.2615 91.2262 24.3318 94.6417 24.3318C97.0198 24.3651 99.328 23.5378 101.129 22.0064C101.528 22.83 102.656 24.2114 105.732 24.2114H106.989C107.004 24.2117 107.018 24.209 107.032 24.2036C107.046 24.1982 107.059 24.1901 107.069 24.1799C107.08 24.1697 107.088 24.1575 107.094 24.144C107.099 24.1306 107.102 24.1162 107.102 24.1016V20.7649C107.102 20.7494 107.099 20.734 107.093 20.7199C107.086 20.7056 107.077 20.6929 107.065 20.6826C107.054 20.6722 107.04 20.6644 107.025 20.6597C107.01 20.6549 106.994 20.6533 106.979 20.6551ZM100.536 19.0364C98.5617 20.416 97.1571 20.9845 95.7154 20.9845C94.2139 20.9845 93.2009 20.2195 93.2009 19.0806C93.2009 18.5972 93.259 17.0776 95.3904 16.8084C97.5217 16.5391 100.533 16.1637 100.533 16.1637L100.536 19.0364Z",
      fill: "#CB2026"
    }), _jsx("path", {
      d: "M164.708 13.4239C161.834 12.9262 160.415 12.6641 160.415 11.3305C160.415 10.5388 160.796 9.49206 163.902 9.49206C165.817 9.49299 167.701 9.97063 169.38 10.8806L169.5 10.9426C169.526 10.9556 169.556 10.9584 169.583 10.9505C169.611 10.9426 169.635 10.9246 169.649 10.9001L171.316 8.04512C171.323 8.032 171.328 8.01745 171.33 8.0024C171.331 7.98733 171.33 7.97209 171.326 7.95761C171.321 7.94314 171.313 7.92976 171.304 7.9183C171.294 7.90684 171.281 7.89755 171.267 7.89104L171.127 7.82374C169.116 6.91163 167.187 6.15894 163.926 6.15894C158.939 6.15894 156.191 8.05752 156.191 11.504C156.191 15.4252 159.887 16.3603 163.349 16.927C166.413 17.4282 167.645 17.8533 167.645 19.1869C167.645 20.4019 166.458 20.9916 163.923 20.9916C161.588 20.9916 159.212 20.014 157.621 19.1922L157.52 19.1444C157.495 19.1322 157.465 19.1302 157.438 19.1388C157.411 19.1473 157.387 19.1658 157.373 19.1904L155.719 22.0737C155.712 22.0865 155.707 22.1007 155.705 22.1152C155.704 22.1298 155.705 22.1446 155.709 22.1588L155.711 22.1666C155.712 22.1694 155.713 22.1722 155.715 22.175C155.717 22.1792 155.719 22.1834 155.722 22.1874C155.724 22.1909 155.726 22.1944 155.729 22.1977C155.733 22.2037 155.739 22.2093 155.745 22.2142L155.747 22.2154C155.749 22.2169 155.75 22.2183 155.752 22.2196L155.757 22.2228C155.758 22.2239 155.76 22.225 155.763 22.226L155.861 22.2792C158.465 23.6093 161.248 24.3124 163.923 24.3124C169.143 24.3124 171.79 22.4935 171.79 18.9035C171.791 14.9913 168.288 14.0437 164.708 13.4239Z",
      fill: "#CB2026"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M118.898 6.177C116.871 6.177 115.158 6.83762 113.511 8.25624V6.73135C113.511 6.70223 113.5 6.6743 113.479 6.6537C113.457 6.63311 113.43 6.62154 113.4 6.62154H109.133C109.118 6.62154 109.104 6.62444 109.09 6.63007C109.077 6.6357 109.064 6.64394 109.054 6.65433C109.043 6.66472 109.036 6.67703 109.03 6.69055C109.025 6.70408 109.021 6.71855 109.022 6.73312V31.0747C109.021 31.0894 109.025 31.104 109.03 31.1177C109.036 31.1313 109.044 31.1437 109.054 31.1541C109.065 31.1645 109.078 31.1727 109.091 31.1783C109.105 31.1838 109.12 31.1865 109.135 31.1863H113.394C113.409 31.1865 113.424 31.1838 113.437 31.1784C113.452 31.173 113.464 31.1649 113.474 31.1547C113.485 31.1445 113.494 31.1323 113.499 31.1189C113.505 31.1054 113.508 31.091 113.508 31.0765V22.3097C115.075 23.5955 117.038 24.3322 118.895 24.3322C123.657 24.3322 126.976 20.6006 126.976 15.2555C126.979 10.1636 123.429 6.177 118.898 6.177ZM117.776 20.5669C116.217 20.5669 114.903 19.8425 113.505 18.2185V12.1385C114.942 10.6171 116.266 9.94056 117.776 9.94056C120.641 9.94056 122.49 12.0269 122.49 15.2537C122.49 18.431 120.595 20.5669 117.776 20.5669Z",
      fill: "#CB2026"
    }), _jsx("path", {
      d: "M133.78 0.86377H129.517C129.454 0.86377 129.404 0.913723 129.404 0.975349V23.7761C129.404 23.8377 129.454 23.8876 129.517 23.8876H133.78C133.843 23.8876 133.893 23.8377 133.893 23.7761V0.975349C133.893 0.913723 133.843 0.86377 133.78 0.86377Z",
      fill: "#CB2026"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M152.201 19.2156C152.187 19.1907 152.162 19.1723 152.135 19.1644C152.106 19.1565 152.076 19.1596 152.05 19.1731L151.905 19.251C151.84 19.2842 151.777 19.3173 151.713 19.3505L151.684 19.3658C150.315 20.079 149.001 20.7636 146.388 20.7636C143.402 20.7636 141.374 19.1252 140.968 16.3819H153.616C153.631 16.3822 153.645 16.3795 153.66 16.3741C153.673 16.3686 153.686 16.3606 153.696 16.3503C153.707 16.3401 153.715 16.3279 153.72 16.3145C153.726 16.3011 153.73 16.2867 153.73 16.2721V15.066C153.73 9.57568 150.406 6.177 145.054 6.177C139.927 6.177 136.344 9.96355 136.344 15.3865C136.344 20.7369 140.218 24.3322 145.982 24.3322C148.718 24.3678 151.41 23.6483 153.752 22.2548L153.828 22.2069C153.852 22.1913 153.869 22.1673 153.876 22.1396C153.883 22.1119 153.879 22.0828 153.864 22.0581L152.201 19.2156ZM145.095 9.76338C147.43 9.76338 148.866 10.9607 149.25 13.2276H140.99C141.385 10.9908 142.844 9.76338 145.095 9.76338Z",
      fill: "#CB2026"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "StaplesLogo_svg__clip0_6690_49496",
      children: _jsx("rect", {
        width: 171.789,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'StaplesLogo', 'Company');
export default StaplesLogo;