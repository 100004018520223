/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MicrosoftLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 132,
  height: 33,
  viewBox: "0 0 132 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M0.108887 2.99854H13.4284V16.3181H0.108887V2.99854Z",
    fill: "#F25022"
  }), _jsx("path", {
    d: "M14.7886 2.99854H28.1094C28.1094 7.43881 28.1094 11.8787 28.1094 16.3181H14.7898C14.7898 11.8778 14.7898 7.43797 14.7898 2.99854H14.7886Z",
    fill: "#80BA01"
  }), _jsx("path", {
    d: "M0.108887 17.6775C4.54916 17.6775 8.98901 17.6775 13.4284 17.6775C13.4284 22.1178 13.4284 26.5576 13.4284 30.997H0.108887V17.6775Z",
    fill: "#02A4EF"
  }), _jsx("path", {
    d: "M14.7898 17.679H28.1094V30.9985H14.7886C14.7886 26.5582 14.7886 22.1184 14.7886 17.679H14.7898Z",
    fill: "#FFB902"
  }), _jsx("path", {
    d: "M128.377 13.3601C128.355 12.1585 128.377 10.9593 128.367 9.75383C127.417 10.0407 126.471 10.3452 125.516 10.6169C125.493 11.5292 125.516 12.4415 125.505 13.3538C124.1 13.3538 122.694 13.3538 121.291 13.3538C121.308 12.6348 121.24 11.9108 121.344 11.1969C121.43 10.6333 121.756 10.071 122.304 9.85239C122.996 9.56935 123.77 9.75762 124.428 10.047C124.428 9.22438 124.428 8.40431 124.422 7.57792C123.038 7.19884 121.452 7.27213 120.224 8.07072C119.433 8.59016 118.856 9.37759 118.599 10.2883C118.293 11.2853 118.449 12.3366 118.401 13.3589C117.734 13.3589 117.069 13.3475 116.404 13.3589C116.404 14.1284 116.404 14.8992 116.404 15.6675C117.073 15.6814 117.741 15.6675 118.408 15.6776C118.403 18.9157 118.403 22.1539 118.408 25.3921H121.288C121.288 22.1531 121.288 18.9141 121.288 15.675C122.692 15.675 124.097 15.675 125.503 15.675C125.503 17.7398 125.503 19.8032 125.503 21.8667C125.516 22.8282 125.68 23.8556 126.317 24.615C126.868 25.2872 127.743 25.5943 128.592 25.6574C129.489 25.7353 130.392 25.5966 131.225 25.2531V22.8977C130.593 23.3198 129.721 23.4878 129.035 23.1037C128.625 22.8763 128.465 22.3923 128.412 21.9538C128.325 21.2121 128.381 20.4628 128.365 19.7185C128.365 18.3728 128.365 17.0283 128.365 15.6839C129.32 15.6839 130.274 15.6573 131.228 15.6965V13.3601C130.277 13.3601 129.327 13.35 128.377 13.3601Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M58.545 8.24093C57.7451 8.33317 57.0628 9.05974 57.0843 9.87602C57.0514 10.6506 57.6743 11.3456 58.4224 11.4808C59.1426 11.6387 59.9703 11.3329 60.3405 10.6771C60.5017 10.3828 60.5688 10.0461 60.5329 9.71245C60.497 9.37879 60.3597 9.06413 60.1396 8.81081C59.9427 8.59006 59.6935 8.42222 59.4149 8.32266C59.1363 8.2231 58.8372 8.195 58.545 8.24093Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M50.6197 8.60762C48.9556 12.7509 47.2535 16.8778 45.5767 21.0161C45.2267 20.1948 44.9146 19.357 44.581 18.5281C43.2762 15.2175 41.9683 11.9077 40.6576 8.59877H36.4688C36.4688 14.1982 36.4688 19.8001 36.4688 25.4046C37.3823 25.4046 38.2959 25.4046 39.2108 25.4046C39.2108 22.8976 39.2108 20.3906 39.2108 17.8837C39.2209 16.0729 39.1905 14.2609 39.2259 12.4502C39.279 12.5336 39.3257 12.6183 39.3712 12.7029C41.0796 16.936 42.7754 21.1753 44.4938 25.4046C45.1838 25.3932 45.8737 25.416 46.5636 25.3919C48.2189 21.1576 49.9159 16.941 51.5801 12.7105C51.6244 12.6178 51.6751 12.5283 51.7317 12.4426C51.7444 12.6284 51.7507 12.8129 51.7494 12.9986C51.7494 17.1318 51.7494 21.2638 51.7494 25.3957C52.7186 25.4096 53.6878 25.3957 54.6557 25.3957C54.6557 19.7972 54.6557 14.1982 54.6557 8.59877C53.3062 8.60383 51.9629 8.58614 50.6197 8.60762Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M70.7524 13.3601C69.3547 12.9618 67.874 12.9574 66.4739 13.3475C65.1786 13.7066 64.0485 14.5049 63.277 15.6055C62.3836 16.902 62.0677 18.5219 62.1398 20.0749C62.2093 21.5684 62.7981 23.0595 63.8823 24.1057C64.8805 25.0749 66.2566 25.5968 67.6351 25.6701C68.9834 25.737 70.3948 25.5551 71.5725 24.855C71.5624 23.9857 71.5725 23.1151 71.5725 22.2457C70.8171 22.8165 69.9269 23.1822 68.9884 23.3071C68.0382 23.3792 67.0198 23.1504 66.2932 22.5048C65.5932 21.9083 65.2217 21.0099 65.1294 20.1115C65.0031 18.9616 65.1711 17.7107 65.8876 16.7756C66.1715 16.3999 66.5306 16.0875 66.942 15.8584C67.3534 15.6293 67.8081 15.4885 68.277 15.445C69.4585 15.2972 70.6362 15.747 71.5624 16.4559C71.5712 15.5373 71.5624 14.6187 71.5712 13.7013C71.3138 13.5535 71.0387 13.4388 70.7524 13.3601Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M90.1152 13.6251C88.8327 13.0413 87.3732 12.963 85.9909 13.1386C84.539 13.3218 83.1263 14.0054 82.2001 15.1604C81.3585 16.194 80.9491 17.522 80.8708 18.84C80.7545 20.3929 81.0136 22.0406 81.9259 23.3358C82.7229 24.4522 83.9113 25.2273 85.2542 25.5067C86.3665 25.7379 87.5144 25.7379 88.6267 25.5067C89.939 25.2463 91.114 24.523 91.9373 23.4685C92.722 22.4728 93.115 21.2168 93.2009 19.9633C93.2907 18.6795 93.1618 17.3502 92.6273 16.1725C92.1254 15.0476 91.233 14.1427 90.1152 13.6251ZM89.8486 21.6995C89.5766 22.3295 89.0692 22.8282 88.4347 23.0894C87.6815 23.3655 86.8612 23.4012 86.0869 23.1918C85.6978 23.084 85.3357 22.8953 85.0244 22.6382C84.713 22.3811 84.4594 22.0612 84.28 21.6995C83.8162 20.7733 83.7467 19.703 83.8428 18.6871C83.9299 17.7621 84.2509 16.8119 84.956 16.175C85.4813 15.7124 86.1517 15.4478 86.8514 15.427C87.7169 15.3701 88.6394 15.614 89.261 16.2445C89.927 16.8991 90.1847 17.848 90.2555 18.754C90.306 19.7434 90.2656 20.7771 89.8486 21.6995Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M100.582 18.8351C99.7355 18.3461 98.7613 18.1136 97.945 17.5716C97.4674 17.2696 97.3322 16.6049 97.5267 16.0982C97.7731 15.5928 98.3645 15.359 98.9028 15.34C99.9808 15.3089 101.041 15.6218 101.929 16.2334C101.929 15.3489 101.929 14.4644 101.929 13.5799C100.661 13.0882 99.2846 12.9447 97.9425 13.1641C96.8166 13.365 95.7122 13.9501 95.0754 14.9256C94.5194 15.7621 94.4436 16.8412 94.6773 17.799C94.8419 18.4494 95.2212 19.0251 95.7539 19.4328C96.4994 20.0052 97.3966 20.3249 98.2394 20.7065C98.6918 20.9188 99.1707 21.1362 99.503 21.5291C99.8518 22.0346 99.7456 22.8736 99.158 23.1718C98.5452 23.4662 97.8287 23.4624 97.1704 23.355C96.2247 23.1831 95.3309 22.7971 94.5573 22.2266C94.5459 23.1541 94.5573 24.0829 94.5573 25.0065C95.4866 25.4009 96.4781 25.6285 97.4863 25.6788C98.8965 25.7306 100.43 25.4564 101.494 24.4607C102.61 23.4751 102.884 21.7288 102.244 20.4071C101.895 19.6994 101.249 19.2092 100.582 18.8351Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M113.227 13.7164C111.915 13.0542 110.389 12.9582 108.951 13.1351C107.792 13.2842 106.653 13.7239 105.768 14.4973C104.688 15.4273 104.055 16.7957 103.873 18.1945C103.691 19.5933 103.755 21.0667 104.326 22.377C104.888 23.6733 105.94 24.6943 107.253 25.2163C108.535 25.7218 109.961 25.7774 111.31 25.5588C112.708 25.3383 113.971 24.5993 114.848 23.489C115.761 22.3353 116.154 20.843 116.151 19.3886C116.186 18.4121 116.033 17.4381 115.701 16.519C115.258 15.3068 114.375 14.306 113.227 13.7164ZM113.101 20.5979C112.991 21.2297 112.771 21.8615 112.356 22.3669C111.925 22.8638 111.326 23.1855 110.673 23.2717C109.979 23.3885 109.265 23.3107 108.612 23.0467C107.919 22.7345 107.375 22.1642 107.096 21.4571C106.755 20.632 106.687 19.7172 106.755 18.8339C106.821 17.9797 107.063 17.1015 107.628 16.4331C107.891 16.1303 108.214 15.8845 108.575 15.7107C108.937 15.5369 109.33 15.4387 109.731 15.4222C110.543 15.3653 111.413 15.5486 112.038 16.102C112.616 16.5809 112.934 17.2974 113.081 18.0189C113.24 18.8705 113.248 19.7436 113.103 20.5979H113.101Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M78.0155 13.5419C77.2927 13.9514 76.8353 14.6792 76.527 15.4285C76.527 14.7386 76.527 14.0499 76.527 13.3613C75.578 13.3613 74.6316 13.3524 73.6826 13.3613C73.6877 17.3736 73.6877 21.3851 73.6826 25.3957H76.5219C76.5219 23.4768 76.5219 21.5578 76.5219 19.6388C76.4992 18.8048 76.5687 17.9418 76.9326 17.1798C77.2687 16.4596 77.9169 15.8316 78.7345 15.7393C79.4164 15.6695 80.1021 15.8306 80.6817 16.1968C80.6817 15.2364 80.6817 14.2748 80.6817 13.3145C79.8212 13.0251 78.8103 13.0643 78.0155 13.5419Z",
    fill: "#777777"
  }), _jsx("path", {
    d: "M57.3496 13.3601C57.3496 17.3733 57.3496 21.3856 57.3496 25.3971H60.2091C60.2091 21.3864 60.2028 17.3758 60.2091 13.3651C59.2576 13.3474 58.3011 13.3601 57.3496 13.3601Z",
    fill: "#777777"
  })]
}), 'MicrosoftLogo', 'Company');
export default MicrosoftLogo;