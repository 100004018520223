/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var LyftLogo = createCompanyLogosComponent(_jsx("svg", {
  width: 46,
  height: 32,
  viewBox: "0 0 46 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0 0.558439H6.86572V18.9844C6.86572 21.9004 8.19958 23.6374 9.25433 24.382C8.13768 25.3746 4.72538 26.2432 2.18168 24.1337C0.682004 22.8903 0 20.8456 0 18.9223V0.558439ZM42.908 15.8645V13.9281H45.002V7.13477H42.7024C41.8197 3.05623 38.1885 0 33.846 0C28.8433 0 24.7881 4.05548 24.7881 9.0579V24.9224C26.2133 25.1227 27.9112 24.8975 29.3559 23.6995C30.8553 22.4559 31.5373 20.4115 31.5373 18.4881V17.9036H34.9663V11.1103H31.5373V9.0579H31.5455C31.5455 7.78754 32.5754 6.75768 33.846 6.75768C35.1163 6.75768 36.1503 7.78754 36.1503 9.0579V15.8645C36.1503 20.8669 40.2099 24.9224 45.2123 24.9224V18.1647C43.942 18.1647 42.908 17.1348 42.908 15.8645ZM16.9051 7.13477V17.1047C16.9051 17.6707 16.4355 18.1295 15.856 18.1295C15.2766 18.1295 14.807 17.6707 14.807 17.1047V7.13477H8.01343V18.8604C8.01343 20.9697 8.72878 23.6374 11.9842 24.506C15.243 25.3755 17.1335 23.5755 17.1335 23.5755C16.9612 24.7613 15.8444 25.63 14.0453 25.8161C12.684 25.9568 10.9431 25.5059 10.0747 25.1336V31.3459C12.2875 31.9984 14.6279 32.2093 16.9183 31.7648C21.075 30.9583 23.6984 27.4841 23.6984 22.862V7.13477H16.9051Z",
    fill: "#EA0B8C"
  })
}), 'LyftLogo', 'Company');
export default LyftLogo;