/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CazooLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 133,
  height: 33,
  viewBox: "0 0 133 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M50.8 12.7851C50.354 12.7146 49.9033 12.6818 49.4667 12.5879C48.664 12.4236 48.0584 12.5879 47.7908 13.6348C46.8519 11.5457 45.913 9.62091 45.0726 7.67735C44.0445 5.34884 42.3451 4.10947 39.7349 4.1705C37.9369 4.21745 36.1341 4.1705 34.3361 4.1705C31.3645 4.1705 29.9044 5.16575 28.7026 7.92616C27.8999 9.75705 27.0314 11.5598 26.1863 13.3907C25.8483 13.0761 25.5714 12.616 25.3178 12.6301C24.3152 12.612 23.3171 12.7709 22.3697 13.0996C21.9612 13.278 21.8439 14.2545 21.7453 14.8929C21.7453 15.0103 22.4307 15.3013 22.825 15.3952C23.3978 15.5314 23.9893 15.5642 24.5855 15.644C24.4229 16.1419 24.3097 16.6545 24.2475 17.1745C24.2162 20.2823 24.2162 23.3885 24.2475 26.4932C24.2475 27.526 24.8296 28.0283 25.8953 28.0565C26.7591 28.0565 27.6182 28.0565 28.482 28.0565C29.6087 28.0283 30.1204 27.526 30.1533 26.404C30.1814 25.5074 30.1533 24.606 30.1533 23.7093C30.1533 23.0943 30.3974 22.8455 31.0264 22.8455C34.9605 22.8643 38.8961 22.8643 42.8333 22.8455C43.5422 22.8455 43.7722 23.1554 43.7347 23.7844C43.7347 24.6482 43.7347 25.5074 43.7347 26.3665C43.7722 27.5166 44.2605 28.0143 45.3825 28.0424C46.2463 28.0424 47.1054 28.0424 47.9692 28.0424C49.0677 28.0143 49.678 27.4885 49.678 26.404C49.678 22.8033 49.6357 19.2072 49.6029 15.522C50.045 15.5055 50.4848 15.4505 50.9174 15.3577C51.3868 15.2309 52.152 15.4985 52.1943 14.6159C52.2173 14.1949 52.0897 13.7793 51.8342 13.4438C51.5787 13.1083 51.2121 12.8748 50.8 12.7851ZM31.3973 16.921C31.571 16.4046 31.7072 15.9304 31.8903 15.4703C32.4771 13.9775 33.1155 12.5034 33.6601 10.9964C33.7218 10.6819 33.903 10.4034 34.1656 10.2196C34.4282 10.0358 34.7518 9.96085 35.0685 10.0106C36.3736 10.0622 37.6881 10.0106 38.9931 10.0481C39.3452 10.0481 39.8804 10.2594 39.9931 10.5176C40.8898 12.5926 41.7066 14.7004 42.5939 16.9163L31.3973 16.921Z",
    fill: "#DF4E3C"
  }), _jsx("path", {
    d: "M132.993 15.7661C132.758 8.94959 127.275 3.95456 120.284 4.18459C113.937 4.39115 108.787 10.0106 108.994 16.5079C109.2 23.0051 114.754 28.357 121.143 28.1316C128.04 27.8875 133.223 22.4747 132.993 15.7661ZM121.003 22.1554C119.413 22.1326 117.894 21.4932 116.767 20.3721C115.64 19.251 114.992 17.7359 114.961 16.1464C114.992 14.5569 115.636 13.0409 116.758 11.915C117.881 10.7892 119.395 10.1405 120.984 10.1045C122.553 10.1482 124.043 10.8023 125.137 11.9275C126.231 13.0527 126.843 14.5604 126.843 16.1299C126.843 17.6995 126.231 19.2072 125.137 20.3324C124.043 21.4576 122.553 22.1116 120.984 22.1554H121.003Z",
    fill: "#DF4E3C"
  }), _jsx("path", {
    d: "M91.8447 4.17992C89.4866 4.23723 87.1979 4.98936 85.2655 6.34202C83.3331 7.69468 81.843 9.58766 80.982 11.7837C80.1209 13.9797 79.9273 16.381 80.4254 18.6866C80.9234 20.9922 82.091 23.0995 83.7817 24.7443C85.4724 26.3891 87.611 27.4982 89.9295 27.9326C92.2479 28.367 94.643 28.1073 96.8145 27.1862C98.986 26.265 100.837 24.7233 102.136 22.7544C103.435 20.7855 104.124 18.477 104.116 16.1182C104.093 9.3909 98.6894 4.13766 91.8447 4.17992ZM92.0747 22.1554C90.476 22.1517 88.9443 21.5131 87.8165 20.38C86.6887 19.2469 86.0572 17.7122 86.061 16.1135C86.0647 14.5148 86.7034 12.9831 87.8364 11.8553C88.9695 10.7275 90.5042 10.096 92.1029 10.0998C93.7016 10.1035 95.2333 10.7422 96.3611 11.8752C97.4889 13.0083 98.1204 14.543 98.1166 16.1417C98.1129 17.7404 97.4742 19.2721 96.3412 20.3999C95.2081 21.5277 93.6734 22.1592 92.0747 22.1554Z",
    fill: "#DF4E3C"
  }), _jsx("path", {
    d: "M74.2072 10.7476C74.7878 10.1776 75.234 9.48528 75.5133 8.7211C75.7927 7.95692 75.8982 7.14008 75.8221 6.33C75.7001 4.49912 75.3057 4.02966 73.4467 4.02966H59.0531C56.0486 4.02966 55.8749 4.20336 55.8749 7.1985C55.8749 9.57396 56.1706 9.87441 58.5038 9.87441H67.0573C66.5879 10.4002 66.3391 10.7335 66.0574 11.0574C63.0998 14.4375 60.2408 17.9115 57.1377 21.1555C55.6167 22.7422 55.2599 24.4417 55.4195 26.4369C55.5087 27.4509 55.9875 28.0612 57.0344 28.0659C62.7336 28.0659 68.4313 28.0659 74.1274 28.0659C75.193 28.0659 75.7423 27.4603 75.7846 26.3618C75.8127 25.6482 75.7846 24.9534 75.7846 24.2257C75.7846 22.8174 75.2118 22.254 73.7894 22.2446C71.0149 22.2446 68.2451 22.2446 65.4706 22.2446H64.1983C64.5598 21.7752 64.7664 21.4841 65.0011 21.2118C68.0714 17.705 71.0571 14.1512 74.2072 10.7476Z",
    fill: "#DF4E3C"
  }), _jsx("path", {
    d: "M17.7126 22.8079C16.7737 21.3995 16.7737 21.3995 15.2245 22.0943C11.0463 23.9205 6.17806 20.8972 6.01845 16.4233C5.83536 11.3015 11.7083 7.88385 15.4921 10.4706C16.2573 11.0011 16.8723 10.7898 17.3699 10.0011C17.8676 9.21242 18.4309 8.46129 18.9473 7.68199C19.6046 6.70082 19.4496 6.03888 18.3933 5.51309C10.5769 1.6119 0.32862 6.14686 0 15.9914C0.107975 20.5686 2.00928 24.1787 6.03253 26.4509C10.1403 28.77 14.3889 28.7372 18.614 26.6481C19.398 26.2631 19.52 25.5777 19.0835 24.8501C18.6469 24.1224 18.1821 23.4792 17.7126 22.8079Z",
    fill: "#DF4E3C"
  })]
}), 'CazooLogo', 'Company');
export default CazooLogo;