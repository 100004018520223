/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AmericanAutomobileAssociationLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 54,
  height: 32,
  viewBox: "0 0 54 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#AmericanAutomobileAssociationLogo_svg__clip0_12854_184872)",
    children: [_jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M52.8903 1.24637C51.3347 -1.40398 43.1535 0.324488 32.9555 4.99123C33.4742 5.3947 33.9927 5.85552 34.3959 6.31646C42.2317 2.97483 48.281 1.9379 49.5488 4.12706C50.9892 6.60464 45.2849 12.7694 36.1241 18.8766L36.2396 18.9917C47.417 11.7323 54.6189 4.24247 52.8903 1.24637Z",
      fill: "#0A357E"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.60916 30.0541C3.74487 28.4983 5.58863 25.56 9.2759 22.0457C8.98784 21.9878 8.69991 21.8725 8.41173 21.7574C8.35414 21.7574 8.35414 21.6995 8.35414 21.6995C8.06621 21.5845 7.77803 21.4693 7.48985 21.3542C3.05358 25.4443 0.806592 28.9014 1.84377 30.7456C3.11129 32.8771 8.58449 32.1857 15.9016 29.4779L15.8441 29.3627C9.96719 31.3791 5.64621 31.7824 4.60916 30.0541Z",
      fill: "#0A357E"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 12.1357C0 5.85571 8.29655 0.727905 18.4943 0.727905C28.7497 0.727905 37.0463 5.85571 37.0463 12.1357C37.0463 18.4733 28.7497 23.601 18.4943 23.601C8.29655 23.6009 0 18.4733 0 12.1357ZM10.4858 3.26307L14.0006 15.1893L17.6877 1.70725C15.095 1.82279 12.6176 2.34095 10.4858 3.26307ZM17.5149 8.21799L15.6135 15.1893H19.4738L17.5149 8.21799ZM22.9883 15.1893L26.3299 3.14778C24.1406 2.28349 21.6632 1.76484 19.0704 1.70725L22.9883 15.1893ZM10.4284 15.1893L8.52678 8.21799L6.62579 15.1893H10.4284ZM32.3795 16.572C33.3012 15.2468 33.82 13.7489 33.82 12.1356C33.82 9.08204 31.9187 6.37411 28.8073 4.41506L32.3795 16.572ZM28.4619 15.1893L26.5029 8.21799L24.6017 15.1893H28.4619ZM28.8652 16.572H24.1982L23.7951 18.07L24.8896 21.6416C26.6757 21.1232 28.2887 20.3166 29.6141 19.3378L28.8652 16.572ZM12.2144 21.6417L10.8315 16.5721H6.22244L5.81897 18.0122C7.43226 19.6253 9.62154 20.8931 12.2143 21.6996V21.6417H12.2144ZM19.8773 16.572H15.2103L13.7123 22.1028C15.2103 22.4488 16.8237 22.6213 18.4943 22.6213C19.5315 22.6213 20.5108 22.564 21.4903 22.391L19.8773 16.572ZM7.89319 4.64577C5.0125 6.54687 3.22646 9.19746 3.22646 12.1357C3.22646 13.749 3.74486 15.1893 4.60915 16.5144L7.89319 4.64577Z",
      fill: "#FD000D"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "AmericanAutomobileAssociationLogo_svg__clip0_12854_184872",
      children: _jsx("rect", {
        width: 53.1459,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'AmericanAutomobileAssociationLogo', 'Company');
export default AmericanAutomobileAssociationLogo;