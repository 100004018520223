/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ClearScoreLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 157,
  height: 32,
  viewBox: "0 0 157 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M42.0826 19.1072L44.4074 21.4616C42.3146 23.816 39.7529 24.7588 36.6927 24.7588C31.1448 24.7588 27.1172 20.8101 27.1172 15.3412C27.1172 9.87232 31.1596 5.93848 36.7075 5.93848C39.7677 5.93848 42.3294 6.87628 44.4222 9.23067L42.0826 11.5752C41.4434 10.8334 40.658 10.2314 39.7756 9.80674C38.8933 9.38211 37.9328 9.144 36.9543 9.10727C33.3955 9.10727 30.9375 11.437 30.9375 15.3313C30.9375 19.2257 33.4054 21.5603 36.9543 21.5603C39.0372 21.5702 40.7894 20.4942 42.0826 19.1072Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M46.1172 5.8866H49.518V24.7167H46.1172V5.8866Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M64.358 19.3039H55.6216C55.8585 21.3424 57.1024 22.4135 58.9187 22.4135C59.5193 22.443 60.1195 22.3482 60.6818 22.135C61.244 21.9219 61.7562 21.5949 62.1863 21.1746L64.1211 23.1094C62.637 24.5034 60.6629 25.2554 58.6275 25.2022C54.6789 25.2022 52.1172 22.6405 52.1172 18.5339C52.1172 14.4273 55.0441 11.8607 58.5782 11.8607C62.3146 11.8607 64.3827 14.1657 64.3827 17.9318L64.358 19.3039ZM60.8536 17.0038C60.8536 15.5873 60.0688 14.4619 58.4449 14.4619C56.9642 14.4619 56.0412 15.38 55.7006 17.0038H60.8536Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M75.1724 23.1706C74.722 23.8418 74.1036 24.3832 73.3787 24.7409C72.6538 25.0986 71.8479 25.26 71.0412 25.2091C68.7657 25.2091 67.1172 23.7284 67.1172 21.5468C67.1172 18.8024 69.4963 17.2822 74.9651 17.2822V16.527C74.9651 15.3227 74.2297 14.4836 72.6897 14.4836C71.9929 14.4795 71.3054 14.6438 70.6857 14.9627C70.0661 15.2815 69.5327 15.7454 69.131 16.3148L67.1962 14.38C67.8841 13.5514 68.7546 12.8934 69.7393 12.4575C70.7241 12.0216 71.7965 11.8197 72.8724 11.8676C76.2731 11.8676 78.3659 13.3484 78.3659 17.0502V24.9475H75.1724V23.1706ZM70.5969 21.1963C70.5969 21.9811 71.1695 22.5833 72.3738 22.5833C73.9977 22.5833 74.9651 21.5369 74.9651 19.9179V19.4244C71.4064 19.454 70.5969 20.0858 70.5969 21.1963Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M84.513 20.3059V24.9357H81.1172V12.1026H84.513V14.7679C85.4064 12.6455 87.1043 11.8607 89.0688 11.8607V15.4737C85.4558 15.4688 84.513 17.1174 84.513 20.3059Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M97.9187 24.6963C95.4553 24.756 93.0488 23.9509 91.1172 22.4209L92.9484 19.573C94.3926 20.7547 96.1824 21.4339 98.0471 21.5078C100.14 21.5078 101.162 20.7477 101.162 19.3607C101.162 15.9599 91.902 18.4723 91.902 11.4091C91.902 7.95404 94.7499 5.86619 98.4666 5.86619C100.731 5.78748 102.943 6.56062 104.666 8.03301L102.835 10.8119C101.64 9.75087 100.117 9.13231 98.5209 9.05966C96.8181 9.05966 95.7223 9.86914 95.7223 11.1278C95.7223 14.7359 104.952 12.1149 104.952 19.0251C104.952 22.2926 102.47 24.6963 97.9187 24.6963Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M118.082 14.0503L115.954 16.1925C115.59 15.7443 115.125 15.388 114.598 15.1522C114.071 14.9164 113.496 14.8078 112.919 14.8351C110.984 14.8351 109.622 16.0642 109.622 18.5222C109.622 20.9802 110.984 22.2093 112.919 22.2093C113.495 22.2301 114.068 22.1185 114.594 21.8832C115.12 21.6479 115.586 21.2951 115.954 20.8519L118.096 22.9941C117.438 23.7057 116.636 24.2695 115.743 24.6481C114.851 25.0267 113.888 25.2116 112.919 25.1905C108.97 25.1905 106.117 22.6535 106.117 18.5222C106.117 14.3909 108.97 11.849 112.919 11.849C113.886 11.8309 114.846 12.0177 115.736 12.3971C116.626 12.7765 117.425 13.34 118.082 14.0503Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M125.761 11.8478C129.631 11.8478 132.38 14.4934 132.38 18.521C132.38 22.5486 129.606 25.1893 125.761 25.1893C121.916 25.1893 119.117 22.5486 119.117 18.521C119.117 14.4934 121.866 11.8478 125.761 11.8478ZM125.761 22.213C127.799 22.213 128.9 20.7767 128.9 18.5259C128.9 16.2752 127.799 14.8389 125.761 14.8389C123.722 14.8389 122.622 16.2752 122.622 18.5259C122.622 20.7767 123.698 22.2081 125.761 22.2081V22.213Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M138.518 20.293V24.9228H135.117V12.0896H138.518V14.755C139.406 12.6326 141.109 11.8478 143.069 11.8478V15.4608C139.461 15.4559 138.518 17.1044 138.518 20.293Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M156.358 19.3058H147.622C147.859 21.3443 149.102 22.4154 150.919 22.4154C151.513 22.4394 152.106 22.3419 152.661 22.1289C153.216 21.9159 153.721 21.5918 154.147 21.1765L156.121 23.0965C154.637 24.4904 152.663 25.2425 150.628 25.1893C146.679 25.1893 144.117 22.6276 144.117 18.521C144.117 14.4144 147.044 11.8478 150.578 11.8478C154.315 11.8478 156.383 14.1528 156.383 17.9188L156.358 19.3058ZM152.854 17.0057C152.854 15.5891 152.069 14.4638 150.445 14.4638C148.964 14.4638 148.041 15.3818 147.701 17.0057H152.854Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M1 5.84778V17.1705C2.11138 16.8414 3.29433 16.8414 4.40571 17.1705V9.27324H16.4294V21.2771H8.53206C8.69476 21.8319 8.77785 22.4069 8.77885 22.9849C8.77826 23.5598 8.69515 24.1316 8.53206 24.6829H19.8351V5.84778H1Z",
    fill: "#1C0732"
  }), _jsx("path", {
    d: "M2.65547 26.1522C4.12205 26.1522 5.31094 24.9634 5.31094 23.4968C5.31094 22.0302 4.12205 20.8413 2.65547 20.8413C1.18889 20.8413 0 22.0302 0 23.4968C0 24.9634 1.18889 26.1522 2.65547 26.1522Z",
    fill: "#1C0732"
  })]
}), 'ClearScoreLogo', 'Company');
export default ClearScoreLogo;