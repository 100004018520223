/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PackhelpLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 127,
  height: 32,
  viewBox: "0 0 127 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.1202 13.5403C21.1206 14.978 20.6945 16.3835 19.8957 17.579C19.097 18.7745 17.9616 19.7061 16.6332 20.256C15.3047 20.8059 13.843 20.9494 12.4331 20.6682C11.0231 20.3871 9.72821 19.6939 8.71232 18.6765C7.69643 17.6592 7.0052 16.3633 6.72611 14.9529C6.44702 13.5425 6.59261 12.081 7.14447 10.7534C7.69633 9.42576 8.62964 8.29171 9.82628 7.49474C11.0229 6.69778 12.4291 6.27372 13.8668 6.27626C15.7915 6.27908 17.6364 7.04564 18.9964 8.4076C20.3563 9.76956 21.1202 11.6156 21.1202 13.5403Z",
    fill: "white"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.8667 6.27413C15.5087 6.27752 17.1011 6.83663 18.3849 7.86044C19.6686 8.88424 20.568 10.3124 20.9365 11.9125L27.712 8L13.8667 0L0 8L6.7776 11.9125C7.14621 10.3089 8.04823 8.87781 9.33604 7.85353C10.6238 6.82925 12.2212 6.27241 13.8667 6.27413Z",
    fill: "#3900C0"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M27.712 24V8L13.8667 16V32L27.712 24Z",
    fill: "#3B61FF"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.8667 16L0 8V24L13.8667 32V16Z",
    fill: "#48C7F4"
  }), _jsx("path", {
    d: "M55.4454 10.1675C52.0193 10.1675 50.4235 11.5136 50.4235 14.4021V14.5067L52.9174 14.4832V14.3787C52.9174 12.608 53.9371 12.2453 55.4774 12.2453C57.6876 12.2453 58.2081 13.1477 58.287 14.6133L54.8523 14.9547C51.5073 15.2768 50.0801 16.2987 50.0801 18.368C50.0801 20.5931 51.7441 21.9221 54.5323 21.9221C56.6998 21.9221 58.2486 21.0944 58.9078 20.2155L59.3494 21.7664H60.8001V15.2789C60.8001 12.5867 60.3905 10.1675 55.4454 10.1675ZM58.2828 16.8384C58.2828 18.7392 57.0988 19.8741 55.1169 19.8741C53.5574 19.8741 52.6934 19.3344 52.6934 18.3467C52.6934 17.632 53.1051 17.1733 55.2129 16.9365L58.2785 16.5973L58.2828 16.8384Z",
    fill: "black"
  }), _jsx("path", {
    d: "M67.8082 10.1675C63.1298 10.1675 62.1484 13.3675 62.1484 16.0469C62.1484 19.9488 64.0684 21.9285 67.8508 21.9285C71.1468 21.9285 72.9708 20.2688 72.9708 17.2565V17.1499H70.3682V17.2459C70.1868 18.9867 69.4487 19.6971 67.7868 19.6971C65.7154 19.6971 64.7511 18.5301 64.7511 16.0256C64.7511 13.4656 65.7026 12.288 67.7378 12.288C69.4103 12.288 70.1932 13.0752 70.3554 14.9163V15.0123H72.9602V14.9056C72.9602 11.8507 71.1319 10.1675 67.8082 10.1675Z",
    fill: "black"
  }), _jsx("path", {
    d: "M43.1508 7.51147H37.9731V21.7749H40.5993V17.0304H43.3257C49.1177 17.0304 49.1177 13.8453 49.1177 12.3157C49.1177 11.0016 49.1177 7.51147 43.1508 7.51147ZM46.4276 12.3157C46.4276 13.5957 46.0564 14.8885 43.3044 14.8885H40.5993V9.69814H43.2382C46.0649 9.69814 46.4276 11.0976 46.4276 12.3157Z",
    fill: "black"
  }), _jsx("path", {
    d: "M84.8276 10.3211H81.2436L77.1562 14.5792V6.04163H74.5962V21.7728H77.1562V16.8832L81.7471 21.7728H85.2607L78.9823 15.7419L84.8276 10.3211Z",
    fill: "black"
  }), _jsx("path", {
    d: "M92.0254 10.1675C90.3571 10.1675 89.1923 10.607 88.5523 11.471V6.04163H86.0371V21.775H88.5523L88.595 15.375C88.595 13.3355 89.011 12.3627 91.3683 12.3627C93.6424 12.3627 94.0094 13.2502 94.0094 15.375V21.775H96.5459V15.5883C96.5438 12.335 95.7864 10.1675 92.0254 10.1675Z",
    fill: "black"
  }), _jsx("path", {
    d: "M106.283 18.1973C106.104 18.9355 105.643 19.9168 103.706 19.9168C101.877 19.9168 100.866 18.9611 100.702 17.0752H108.898V16.9792C109.088 14.6325 108.621 12.768 107.548 11.6053C106.669 10.6517 105.395 10.1675 103.765 10.1675C99.9659 10.1675 98.0396 12.1387 98.0396 16.0256C98.0396 19.9125 99.9745 21.9264 103.8 21.9264C107.951 21.9264 108.811 19.6117 108.954 18.2336L108.964 18.1163H106.302L106.283 18.1973ZM100.723 14.9973C100.883 13.0773 101.79 12.1984 103.552 12.1984C104.416 12.1984 105.09 12.4373 105.555 12.9109C106.153 13.5147 106.283 14.368 106.281 14.9973H100.723Z",
    fill: "black"
  }), _jsx("path", {
    d: "M121.446 10.1675C118.72 10.1675 117.589 11.1787 117.156 11.7824L116.717 10.3232H115.26V26.1653H117.777V20.6016C118.27 21.1861 119.324 21.9264 121.491 21.9264C124.708 21.9264 126.338 19.9573 126.338 16.0704C126.338 12.1835 124.649 10.1675 121.446 10.1675ZM120.745 19.7675C118.255 19.7675 117.758 18.3552 117.758 16.0747C117.758 13.7941 118.278 12.3157 120.815 12.3157C123.279 12.3157 123.785 13.8091 123.785 16.0747C123.78 18.2869 123.264 19.7632 120.747 19.7632L120.745 19.7675Z",
    fill: "black"
  }), _jsx("path", {
    d: "M113.188 6.03943H110.628V21.7728H113.188V6.03943Z",
    fill: "black"
  })]
}), 'PackhelpLogo', 'Company');
export default PackhelpLogo;