/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var TradesyLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 166,
  height: 33,
  viewBox: "0 0 166 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M60.5962 6.06958H59.865L54.1527 21.0587C52.9645 24.1662 51.8221 25.6286 49.4001 25.6286H48.9888V26.3597H57.9457V25.6286H57.4887C54.9753 25.6286 53.9699 25.0802 53.9699 23.8463C53.9699 23.2979 54.3355 22.2469 55.2495 20.0076H62.3784L63.9779 24.4404C63.9901 24.5015 64.0023 24.556 64.0137 24.6066C64.0447 24.7452 64.0693 24.855 64.0693 24.9888C64.0693 25.4458 63.7037 25.6286 63.0639 25.6286H60.2763V26.3597H70.787V25.6286H69.1418C67.9537 25.6286 67.4967 25.4458 66.9483 23.8463L60.5962 6.06958ZM55.478 19.2765L58.8597 10.3652L62.1043 19.2765H55.478Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M0 6.2522H18.8278V14.3865H18.2337C17.3198 10.3651 15.9031 8.17154 15.4918 7.76025C14.7149 6.98337 14.258 6.84628 13.344 6.84628H11.1048V25.5827H16.4058V26.3139H2.42202V25.5827H7.67736V6.84628H5.62092C4.61556 6.84628 4.29567 6.84628 3.65589 7.48606C2.37633 8.71992 1.18816 11.6903 0.594081 14.3865H0V6.2522Z",
    fill: "#231F20"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.0779 6.2522H35.5079C40.9003 6.2522 43.4137 8.44573 43.4137 11.0505C43.4137 11.6903 43.1395 15.2548 36.2848 16.0774C39.1638 17.2656 40.2605 18.9107 40.9917 20.83C41.3851 21.7879 41.6057 22.7393 41.7947 23.555C42.1108 24.9185 42.3389 25.9026 43.1395 25.9026C44.4191 25.9026 45.2417 24.3031 45.2417 21.8811V20.9214H45.8815V21.9268C45.8815 24.4402 45.1046 26.8166 42.0428 26.8166C39.895 26.8166 38.2498 25.2628 38.2498 22.2467V21.2413C38.2498 18.408 38.0213 16.2602 33.9541 16.2602H31.6692V25.5827H36.1477V26.3596H23.3978V25.5827H28.2418V7.02907H23.0779V6.2522ZM31.6692 6.84628V15.6661H34.3197C37.6557 15.6661 39.7579 14.5236 39.7579 10.9135C39.7579 6.84628 37.0616 6.84628 34.4111 6.84628H31.6692Z",
    fill: "#231F20"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M86.5074 6.2522C92.7681 6.2522 96.3326 12.0559 96.3326 16.0317C96.3326 18.865 94.7788 21.6069 93.2708 23.0693C91.1687 25.1714 89.1122 26.3596 85.7762 26.3596H73.6204V25.6284H78.1903V7.02907H73.5747V6.2522H86.5074ZM81.6634 6.80058V25.6741H85.5477C89.2036 25.6741 92.3568 24.3945 92.3568 15.9403C92.3568 9.03981 90.666 6.80058 85.8676 6.80058H81.6634Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M117.537 6.2522H98.252V7.02907H103.096V25.5827H98.4348V26.3596H118.314L118.405 18.0881H117.811C117.445 21.2413 116.12 23.709 115.252 24.5316C114.429 25.3999 113.607 25.7198 111.733 25.7198H106.478V16.2145H108.351C110.545 16.2145 111.459 16.9457 111.962 21.8811H112.601V10.2737H112.053C111.505 14.8435 110.636 15.6204 108.717 15.6204H106.478V6.84628H111.962C114.566 6.84628 116.349 9.58819 116.988 13.3355H117.537V6.2522Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M125.351 18.134C126.722 22.6582 129.784 26.3597 133.531 26.3597C135.953 26.3597 138.192 24.806 138.192 22.5668C138.192 21.8356 137.964 20.8759 137.187 20.1447C136.038 18.9957 134.066 18.5091 132.036 18.0082C129.868 17.4731 127.634 16.9217 126.265 15.5292C125.351 14.6609 124.574 13.4727 124.574 11.3249C124.574 8.17171 127.088 6.06958 130.104 6.06958C131.566 6.06958 133.211 6.70936 134.674 8.17171C134.743 8.24116 134.807 8.30639 134.867 8.36708L134.868 8.36814C135.2 8.70612 135.394 8.90289 135.588 8.90289C136.104 8.90289 136.863 6.5139 136.988 6.11911L137.004 6.06958H137.644V13.5641H137.004C135.816 8.85719 132.617 6.66366 130.195 6.66366C127.636 6.66366 125.808 8.35451 125.808 10.0911C125.808 10.9136 126.219 11.6905 126.585 12.0104C127.712 13.1596 129.801 13.6782 131.973 14.2175C134.293 14.7934 136.707 15.3928 138.147 16.8087C139.472 18.134 139.7 19.8249 139.7 20.8759C139.7 24.806 137.004 26.9995 133.485 26.9995C131.292 26.9995 129.327 25.5829 128.413 24.6689C128.19 24.4462 128.001 24.2379 127.838 24.0582C127.499 23.6849 127.273 23.435 127.088 23.435C126.539 23.435 125.808 25.6743 125.442 26.9995H124.848V18.134H125.351Z",
    fill: "#231F20"
  }), _jsx("path", {
    d: "M153.364 6.2522H142.351V7.02907H144.727C145.23 7.02907 145.459 7.12047 145.916 7.89735L152.496 18.8193V25.6741H148.109V26.4053H160.219V25.6741H155.878V18.2252L160.813 9.77099C161.727 8.26293 163.053 7.02907 164.972 7.02907H165.977V6.2522H156.518V6.98337H157.706C158.803 6.98337 160.311 7.44036 160.311 8.71992C160.311 9.13121 160.174 9.58819 159.854 10.1366L155.604 17.4483L149.663 7.57746C149.617 7.48606 149.571 7.39466 149.571 7.34896C149.571 7.16617 149.8 7.02907 150.348 7.02907H153.364V6.2522Z",
    fill: "#231F20"
  })]
}), 'TradesyLogo', 'Company');
export default TradesyLogo;