/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var EtsyLogo = createCompanyLogosComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 60,
  height: 32,
  viewBox: "0 0 60 32",
  fill: "none",
  children: _jsx("path", {
    fill: "#F45800",
    d: "M6.677 4.147v7.937s2.797 0 4.294-.113c1.177-.207 1.394-.32 1.61-1.497l.434-1.724h1.28l-.217 3.768.114 3.86H12.9l-.32-1.508c-.32-1.072-.753-1.29-1.61-1.393-1.073-.114-4.294-.114-4.294-.114v6.657c0 1.28.65 1.827 2.147 1.827h4.51c1.394 0 2.787-.113 3.654-2.146l1.167-2.58h1.083c-.113.536-.65 5.263-.753 6.326 0 0-4.077-.103-5.79-.103H4.964l-4.614.103v-1.166l1.497-.33c1.083-.217 1.403-.537 1.403-1.394 0 0 .114-2.9.114-7.732 0-4.82-.114-7.72-.114-7.72 0-.96-.32-1.177-1.403-1.394L.35 3.394V2.217l4.51.092h8.588C15.16 2.31 18.05 2 18.05 2s-.103 1.817-.217 6.11h-1.177l-.433-1.507c-.423-1.93-1.064-2.89-2.25-2.89h-6.75c-.547 0-.547.104-.547.434Zm17.38.847h1.292v4.51l4.407-.217-.217 2.044-4.293-.33v7.948c0 2.25.753 3.116 2.043 3.116 1.167 0 2.034-.65 2.353-1.186l.64.753c-.64 1.497-2.456 2.25-4.18 2.25-2.137 0-3.871-1.28-3.871-3.747v-9.022h-2.56V10.04c2.148-.216 3.748-1.507 4.387-5.046Zm9.238 13.84.856 2.044c.32.856 1.073 1.724 2.787 1.724 1.827 0 2.58-.97 2.58-2.147 0-3.654-7.194-2.58-7.194-7.515 0-2.786 2.25-4.087 5.15-4.087 1.29 0 3.22.216 4.19.65-.216 1.072-.33 2.466-.33 3.54l-1.063.103L39.518 11c-.217-.525-1.084-.96-2.147-.96-1.29 0-2.58.536-2.58 1.93 0 3.324 7.41 2.58 7.41 7.515 0 2.797-2.467 4.293-5.48 4.293-2.25 0-4.51-.866-4.51-.866.216-1.28.113-2.673 0-4.077h1.084Zm8.37 10.3c.32-1.177.537-2.672.754-4.076l1.177-.103.433 2.25c.103.536.423.97 1.28.97 1.394 0 3.222-.857 4.944-3.861-.763-1.827-3.013-7.72-5.057-12.231-.536-1.187-.64-1.29-1.383-1.508l-.547-.206V9.286l3.23.114 3.963-.217v1.074l-.97.217c-.752.103-1.063.525-1.063.96 0 .114 0 .217.103.434.206.65 1.93 5.47 3.221 8.69 1.064-2.25 3.107-7.297 3.427-8.154.114-.433.217-.537.217-.856 0-.547-.32-.867-1.064-1.073l-.763-.218V9.184l3.013.103 2.786-.103v1.073l-.536.423c-1.073.433-1.186.536-1.61 1.403l-4.717 11.043C49.706 29.464 46.806 30 44.772 30c-1.29.001-2.26-.329-3.106-.865Z"
  })
}), 'EtsyLogo', 'Company');
export default EtsyLogo;