/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BetterMortgageLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 92,
  height: 32,
  viewBox: "0 0 92 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#BetterMortgageLogo_svg__clip0_12853_184857)",
    children: [_jsx("path", {
      d: "M15.2694 15.5546C17.4161 14.5915 18.862 12.7021 18.862 10.4439C18.8608 6.55476 15.4544 4 11.1218 4H0.422424V27.7378H11.6033C15.8968 27.7378 19.27 25.1095 19.27 21.1089C19.27 18.5541 17.6404 16.5189 15.2694 15.5546ZM6.16174 8.30081H10.0852C11.7503 8.30081 13.0835 9.33742 13.0835 10.9291C13.0835 12.6323 11.7503 13.6689 10.0852 13.6689H6.16174V8.30081ZM10.3817 23.4051H6.16174V17.7785H10.3817C12.1204 17.7785 13.565 18.8543 13.565 20.6653C13.565 22.3685 12.1192 23.4051 10.3817 23.4051Z",
      fill: "#292B29"
    }), _jsx("path", {
      d: "M77.9534 14.04C77.9534 11.1875 75.0274 8.78223 70.9545 8.78223C65.5472 8.78223 61.1803 12.7449 61.1803 18.5222C61.1803 23.9649 65.6587 27.9999 71.2902 27.9999C73.6992 27.9962 76.1424 27.2941 77.9191 26.0345V21.1088C76.0689 22.6661 73.5865 23.5177 71.5868 23.5177C69.5871 23.5177 67.8484 22.4076 67.0311 20.6309C74.3621 20.1138 77.9534 17.5934 77.9534 14.04ZM66.514 17.9634C66.514 14.9259 68.4414 12.8919 70.7732 12.8919C72.4763 12.8919 73.3279 13.817 73.3279 14.7078C73.3279 16.0029 72.2913 17.3018 66.514 17.9646V17.9634Z",
      fill: "#292B29"
    }), _jsx("path", {
      d: "M37.2991 14.04C37.2991 11.1875 34.3743 8.78223 30.3002 8.78223C24.8929 8.78223 20.526 12.7449 20.526 18.5222C20.526 23.9649 25.0045 27.9999 30.6359 27.9999C33.0449 27.9962 35.4881 27.2941 37.2648 26.0345V21.1088C35.4146 22.6661 32.9322 23.5177 30.9325 23.5177C28.9328 23.5177 27.1941 22.4076 26.3768 20.6309C33.7078 20.1138 37.2991 17.5934 37.2991 14.04ZM25.8597 17.9634C25.8597 14.9259 27.7834 12.8919 30.1189 12.8919C31.822 12.8919 32.6736 13.817 32.6736 14.7078C32.6736 16.0029 31.637 17.3018 25.8597 17.9646V17.9634Z",
      fill: "#292B29"
    }), _jsx("path", {
      d: "M85.4731 13.8513V9.22209H79.8809V27.7376H85.4731V16.7038C85.9939 15.0386 88.4335 13.4812 91.9525 14.2605V8.85205C89.0277 8.85205 86.511 10.7795 85.4731 13.8513Z",
      fill: "#292B29"
    }), _jsx("path", {
      d: "M57.1098 21.9996V12.892H60.9217V9.26508L57.1098 9.30306V4.85889L46.5183 12.3406V5.8232L37.6692 12.4876V12.8956H41.0032V23.0399C41.0032 26.2625 43.3742 27.9656 46.5955 27.9656C48.0021 27.9656 49.1894 27.7071 50.2604 27.0798V23.2642C49.7396 23.5607 49.1123 23.7849 48.4102 23.7849C47.2265 23.7849 46.522 23.1919 46.522 22.154V12.8956H51.5947V23.0056C51.5947 26.2625 53.9644 27.9656 57.2213 27.9656C58.6671 27.9656 59.8496 27.7806 60.9989 27.0798V23.2299C60.5174 23.5264 59.7773 23.7849 59.0372 23.7849C57.8499 23.7776 57.1098 23.149 57.1098 22.0009V21.9996Z",
      fill: "#292B29"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "BetterMortgageLogo_svg__clip0_12853_184857",
      children: _jsx("rect", {
        width: 91.5301,
        height: 32,
        fill: "white",
        transform: "translate(0.422424)"
      })
    })
  })]
}), 'BetterMortgageLogo', 'Company');
export default BetterMortgageLogo;