/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SplitLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 79,
  height: 33,
  viewBox: "0 0 79 33",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M44.8717 10.48H47.6617V12.16C47.7501 12.0962 47.8337 12.0261 47.9117 11.95C48.5478 11.1897 49.3913 10.6307 50.3394 10.3413C51.2876 10.0519 52.2995 10.0445 53.2517 10.32C54.1192 10.505 54.9218 10.9174 55.5772 11.5149C56.2327 12.1124 56.7174 12.8734 56.9817 13.72C57.4215 14.8449 57.6186 16.05 57.56 17.2564C57.5014 18.4627 57.1885 19.6431 56.6417 20.72C56.2319 21.4899 55.6398 22.1476 54.9171 22.6357C54.1944 23.1239 53.3631 23.4276 52.4959 23.5203C51.6288 23.6131 50.7521 23.492 49.9425 23.1677C49.1329 22.8434 48.4151 22.3258 47.8517 21.66L47.6817 21.52V28.15H45.0517C44.9917 28.15 44.9117 28.03 44.8717 27.96C44.8618 27.8904 44.8618 27.8197 44.8717 27.75V10.48ZM51.2817 21.13C51.933 21.1519 52.5766 20.984 53.1341 20.6466C53.6916 20.3093 54.139 19.8171 54.4217 19.23C55.0103 18.0562 55.1422 16.7056 54.7917 15.44C54.691 14.9333 54.4814 14.4546 54.1775 14.0367C53.8737 13.6189 53.4827 13.272 53.0317 13.02C52.2255 12.6323 51.3126 12.5259 50.4388 12.7181C49.565 12.9103 48.7809 13.3898 48.2117 14.08C48.0893 14.2296 48.0222 14.4168 48.0217 14.61C48.0217 16.12 48.0217 17.61 48.0217 19.12C48.023 19.3024 48.0865 19.4788 48.2017 19.62C48.5816 20.0724 49.0518 20.4404 49.5822 20.7004C50.1126 20.9605 50.6915 21.1068 51.2817 21.13Z",
    fill: "black"
  }), _jsx("path", {
    d: "M33.4219 19.6002C34.6606 20.7576 36.2967 21.3948 37.9919 21.3802C38.6185 21.4063 39.2364 21.2273 39.7519 20.8702C39.9539 20.7448 40.1169 20.5657 40.2228 20.3529C40.3287 20.1401 40.3732 19.902 40.3514 19.6653C40.3296 19.4286 40.2423 19.2027 40.0994 19.0128C39.9564 18.8229 39.7634 18.6766 39.5419 18.5902C38.7319 18.2902 37.8819 18.1002 37.0519 17.8802C36.0013 17.6699 34.9894 17.2989 34.0519 16.7802C33.3889 16.3364 32.909 15.6674 32.7013 14.8971C32.4935 14.1267 32.5719 13.3072 32.9219 12.5902C33.238 11.9623 33.7049 11.4225 34.2809 11.0194C34.8568 10.6162 35.5237 10.3623 36.2219 10.2802C37.8543 9.94977 39.55 10.1604 41.0519 10.8802C41.6168 11.1827 42.1643 11.5165 42.6919 11.8802L41.5319 13.8102C41.0788 13.4846 40.6081 13.1841 40.1219 12.9102C39.0913 12.3764 37.9083 12.214 36.7719 12.4502C36.4616 12.4824 36.1659 12.598 35.9159 12.7846C35.6659 12.9712 35.4711 13.2219 35.3519 13.5102C35.2899 13.628 35.2543 13.7579 35.2474 13.8908C35.2405 14.0237 35.2625 14.1566 35.3119 14.2802C35.3614 14.4038 35.437 14.5152 35.5337 14.6067C35.6304 14.6982 35.7458 14.7676 35.8719 14.8102C36.5217 15.073 37.1902 15.2869 37.8719 15.4502C38.9662 15.6628 40.0329 15.9982 41.0519 16.4502C41.6641 16.7179 42.182 17.1631 42.5385 17.7282C42.895 18.2933 43.0739 18.9524 43.0519 19.6202C43.0699 20.2252 42.9288 20.8243 42.6428 21.3578C42.3567 21.8912 41.9358 22.3403 41.4219 22.6602C40.1804 23.3848 38.7433 23.703 37.3119 23.5702C36.0368 23.5278 34.7897 23.1852 33.6719 22.5702C33.1719 22.2902 32.6719 21.9302 32.2119 21.5702L33.4219 19.6002Z",
    fill: "black"
  }), _jsx("path", {
    d: "M78.1517 20.75C78.3517 21.36 78.5617 21.97 78.7317 22.58C78.7345 22.6492 78.7205 22.7182 78.6909 22.7808C78.6613 22.8435 78.617 22.8981 78.5617 22.94C77.9916 23.336 77.3158 23.552 76.6217 23.56C75.7911 23.6659 74.9481 23.5085 74.2117 23.11C73.7669 22.8329 73.4061 22.4397 73.1682 21.9727C72.9303 21.5058 72.8243 20.9827 72.8617 20.46C72.8617 18.09 72.8617 15.72 72.8617 13.34V12.9H70.7417V10.47H72.8517V6.97998H75.6317V10.45H78.2417V12.88H75.6717C75.6717 13.04 75.6717 13.15 75.6717 13.27V19.43C75.6714 19.6514 75.6914 19.8723 75.7317 20.09C75.7493 20.2715 75.8084 20.4465 75.9044 20.6015C76.0005 20.7565 76.1308 20.8874 76.2854 20.984C76.44 21.0807 76.6148 21.1405 76.7962 21.1588C76.9776 21.1772 77.1608 21.1537 77.3317 21.09C77.6105 20.9904 77.8842 20.8769 78.1517 20.75Z",
    fill: "black"
  }), _jsx("path", {
    d: "M60.1621 5.58008H62.8921V23.2301H60.1621V5.58008Z",
    fill: "black"
  }), _jsx("path", {
    d: "M66.082 10.48H68.812V23.23H66.082V10.48Z",
    fill: "black"
  }), _jsx("path", {
    d: "M67.4217 5.46008C67.6566 5.44208 67.8927 5.4728 68.1151 5.55032C68.3376 5.62785 68.5416 5.75049 68.7144 5.91058C68.8873 6.07066 69.0251 6.26472 69.1194 6.4806C69.2137 6.69648 69.2624 6.92951 69.2624 7.16508C69.2624 7.40065 69.2137 7.63368 69.1194 7.84956C69.0251 8.06543 68.8873 8.2595 68.7144 8.41958C68.5416 8.57966 68.3376 8.70231 68.1151 8.77983C67.8927 8.85736 67.6566 8.88808 67.4217 8.87008C67.1868 8.88808 66.9508 8.85736 66.7283 8.77983C66.5059 8.70231 66.3018 8.57966 66.129 8.41958C65.9562 8.2595 65.8183 8.06543 65.724 7.84956C65.6297 7.63368 65.5811 7.40065 65.5811 7.16508C65.5811 6.92951 65.6297 6.69648 65.724 6.4806C65.8183 6.26472 65.9562 6.07066 66.129 5.91058C66.3018 5.75049 66.5059 5.62785 66.7283 5.55032C66.9508 5.4728 67.1868 5.44208 67.4217 5.46008Z",
    fill: "black"
  }), _jsx("path", {
    d: "M13.7719 9.43994L8.48193 12.4699L20.9719 19.6099L16.3819 22.2399L18.2819 21.1499V27.1999L12.9919 24.1799L8.48193 26.7599V32.8099L26.3519 22.5999V19.6099V16.6299L13.7719 9.43994Z",
    fill: "#67C7FF"
  }), _jsx("path", {
    d: "M18.2819 0.810059L0.421875 11.0201V14.0101V16.9901L12.9919 24.1801L18.2819 21.1501L5.79187 14.0101L8.48188 12.4701V6.42006L13.7719 9.44006L18.2819 6.86006V0.810059Z",
    fill: "#1F8CEC"
  }), _jsx("path", {
    d: "M18.2817 21.1501L12.9917 24.1801L18.2817 27.2001V21.1501Z",
    fill: "#1B73C0"
  }), _jsx("path", {
    d: "M8.48193 12.4702L13.7719 9.44017L8.48193 6.42017V12.4702Z",
    fill: "#1B73C0"
  })]
}), 'SplitLogo', 'Company');
export default SplitLogo;