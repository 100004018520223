/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BrandlessLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 122,
  height: 32,
  viewBox: "0 0 122 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M24.3709 17.6258C24.3343 17.5153 24.2266 17.4456 24.1121 17.4585H23.0266V20.7319H20.5823V11.0644H24.9173C25.5679 11.0585 26.2114 11.2002 26.8008 11.479C27.8439 11.9786 28.4525 13.0989 28.3105 14.2577C28.2534 15.3584 27.6009 16.3376 26.614 16.8038L28.5837 20.7537H26.1538C26.0022 20.7724 25.8584 20.6815 25.8087 20.5355L24.3709 17.6258ZM25.78 14.5051C25.9021 13.6467 25.6075 13.0139 24.6729 12.9193V12.8975C24.3101 12.8627 23.9439 12.872 23.5721 12.8814C23.3987 12.8858 23.2242 12.8902 23.0481 12.8902V15.458H24.3996C24.5217 15.4652 24.6442 15.4652 24.7663 15.458C25.29 15.4364 25.7202 15.032 25.78 14.5051Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M77.6418 11.057V13.0794L73.4437 13.0429V14.8179C73.4437 14.9365 73.5201 14.9294 73.5912 14.9227C73.6073 14.9212 73.6232 14.9197 73.6378 14.9197H77.0882V16.8694H73.494V18.7461H77.8935V20.7246H71.0494V11.057H77.6418Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M69.1806 18.7097V20.7246H62.5306V11.057H64.9604V18.7097H69.1806Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M43.1272 20.7319V14.5487L43.1847 14.5414C43.2039 14.584 43.2228 14.6262 43.2414 14.668C43.3344 14.8761 43.4232 15.075 43.5369 15.2689C44.601 17.0632 45.6721 18.8503 46.7504 20.6301C46.8071 20.6998 46.8915 20.7398 46.9804 20.7392H49.2378V11.0498H47.0095V17.1166C46.8934 16.9266 46.793 16.7343 46.6934 16.5435C46.6138 16.3911 46.5347 16.2395 46.4485 16.0909C45.6782 14.7478 44.8835 13.4097 44.0845 12.0643C43.9141 11.7774 43.7435 11.4902 43.5729 11.2025C43.5224 11.0988 43.4134 11.0378 43.2997 11.0498H40.8842V20.7319H43.1272Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M55.5426 20.7611H52.2286L52.0055 20.7392V11.0424H55.5067C56.4332 11.0396 57.3487 11.2432 58.1882 11.639C59.253 12.137 60.0325 13.1026 60.3017 14.2577C60.6304 15.4533 60.5931 16.7216 60.1939 17.895C59.732 19.2604 58.5828 20.2709 57.1818 20.5428C56.6453 20.6771 56.0953 20.7502 55.5426 20.7611ZM55.7298 13.0357H54.4358L54.4501 18.8189C54.638 18.8053 54.8245 18.7959 55.0095 18.7866C55.4194 18.766 55.8224 18.7457 56.2184 18.6806C57.0071 18.5612 57.6419 17.9625 57.8143 17.1747C57.9853 16.5824 58.0319 15.9605 57.9512 15.3489C57.9139 14.9688 57.8217 14.5962 57.6779 14.2432C57.3206 13.487 56.5577 13.014 55.7298 13.0357Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M15.0754 20.7392H10.8339L10.5679 20.7319V11.0424H15.1977C15.8909 11.0237 16.5739 11.2136 17.1603 11.5881C17.824 12.0231 18.1992 12.7907 18.1379 13.5885C18.1536 14.4264 17.6641 15.1896 16.9015 15.5163L16.4917 15.6835L16.8584 15.7854C16.9288 15.8035 16.9983 15.8254 17.0666 15.8506C18.2042 16.2704 18.7901 17.5439 18.3752 18.6951C18.0836 19.702 17.2431 20.4476 16.2185 20.6083C15.8442 20.6988 15.4604 20.7429 15.0754 20.7392ZM12.9906 16.862V18.0477L12.9978 18.8989C13.2375 18.8989 13.4771 18.9061 13.7167 18.9134C14.196 18.9279 14.6753 18.9425 15.1546 18.8989C15.2046 18.8983 15.2545 18.894 15.3039 18.8859C15.8408 18.7975 16.2052 18.2854 16.1178 17.7422C16.1172 17.7212 16.116 17.7003 16.1143 17.6794C16.0623 17.079 15.5394 16.6348 14.946 16.6873H13.1631C13.0337 16.6873 12.9906 16.7238 12.9906 16.862ZM15.8703 14.0126C15.9532 13.4604 15.578 12.9449 15.0323 12.8612H15.018C14.6495 12.825 14.2789 12.8132 13.9073 12.8014C13.6046 12.7918 13.3012 12.7821 12.9977 12.7593V14.9416C13.2315 14.9319 13.4637 14.9279 13.6943 14.9239C14.1587 14.9158 14.6167 14.9078 15.0682 14.8543C15.4851 14.7745 15.8067 14.4369 15.8703 14.0126Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M35.9022 11.1953L39.1157 20.6519V20.6156C39.1399 20.6451 39.1616 20.6768 39.1804 20.7102H36.8224C36.772 20.7102 36.6715 20.6301 36.6715 20.572C36.4773 19.99 36.2904 19.408 36.1178 18.8189C36.0747 18.6661 36.0243 18.6661 35.9023 18.6661H32.7965C32.6876 18.6531 32.5886 18.7308 32.5736 18.8406C32.487 19.1327 32.3968 19.4248 32.3066 19.7169C32.2171 20.0067 32.1276 20.2966 32.0416 20.5864C32.0129 20.6811 31.9769 20.7319 31.862 20.7319H29.6046C29.6788 20.5026 29.7553 20.2756 29.8315 20.0497C29.8882 19.8815 29.9447 19.7138 30 19.5462C30.346 18.5304 30.6931 17.5156 31.04 16.501C31.6372 14.7547 32.2341 13.0092 32.8253 11.2608C32.8508 11.1173 32.9848 11.0205 33.1272 11.0424H35.6794C35.7813 11.0283 35.8774 11.0942 35.9022 11.1953ZM33.2582 16.6297C33.2396 16.6882 33.2206 16.7477 33.2206 16.8111H35.4133V16.7456C35.354 16.5435 35.2948 16.3411 35.2354 16.1385C34.9638 15.2114 34.6913 14.2811 34.4141 13.3558C34.3997 13.3121 34.3061 13.2394 34.3061 13.2394C34.2687 13.2807 34.2371 13.3272 34.2127 13.3775C33.8996 14.4292 33.5934 15.4807 33.2873 16.5323L33.2781 16.5637C33.2722 16.5857 33.2652 16.6076 33.2582 16.6297Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M96.1178 11.3553C96.9194 11.7554 97.5021 12.4971 97.7068 13.3775V13.3558C97.7137 13.3995 97.7137 13.4358 97.7068 13.5376L96.0533 13.7485C95.9954 13.7569 95.937 13.7671 95.8782 13.7773C95.7321 13.8027 95.5835 13.8286 95.4346 13.8286C95.3655 13.8219 95.3023 13.7847 95.2622 13.7267C95.0562 13.0594 94.4103 12.6349 93.7239 12.7155C93.4418 12.7244 93.1629 12.7785 92.8969 12.8756C92.6042 12.9723 92.4042 13.2474 92.4009 13.5594C92.3701 13.8616 92.5272 14.1517 92.7965 14.2868C93.1081 14.4331 93.4331 14.5476 93.7673 14.6287C94.0366 14.6982 94.3098 14.7599 94.5834 14.8216C95.0544 14.9278 95.5264 15.0343 95.9813 15.1816C96.8148 15.395 97.479 16.0313 97.7354 16.862C97.7358 16.8626 97.7358 16.8633 97.7363 16.8639C98.2733 18.3762 97.4975 20.0428 96.003 20.5864C94.409 21.2467 92.607 21.1561 91.0858 20.3391C90.3035 19.9434 89.7333 19.2199 89.5254 18.3605C89.4825 18.2805 89.4825 18.2005 89.4825 18.1058L91.9625 17.793C92.063 18.1885 92.3037 18.5328 92.6383 18.7607C93.2759 19.1602 94.0687 19.212 94.7519 18.8989C95.1101 18.7635 95.336 18.4042 95.3055 18.0186C95.3152 17.6541 95.0778 17.3299 94.7302 17.233C94.4266 17.1305 94.1346 17.0609 93.828 16.9877C93.6557 16.9466 93.4787 16.9044 93.2925 16.8547C93.1176 16.8079 92.9403 16.7662 92.7627 16.7245C92.4147 16.6426 92.0658 16.5605 91.7325 16.4401C90.6792 16.1373 89.9476 15.1695 89.9352 14.0614C89.8172 12.8849 90.4658 11.7666 91.5385 11.297C92.2963 10.9435 93.1284 10.786 93.9613 10.8388C94.7104 10.8359 95.4494 11.013 96.1178 11.3553Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M85.8804 11.2316C86.7821 11.5832 87.4616 12.3519 87.7068 13.2976V13.2758C87.71 13.2905 87.7129 13.3052 87.7158 13.3201C87.7258 13.3717 87.7365 13.4262 87.764 13.494L85.507 13.7849C85.4135 13.7995 85.3415 13.7922 85.3056 13.6758C85.1078 13.0483 84.5178 12.6336 83.8678 12.6646C83.5363 12.6372 83.2035 12.7 82.9044 12.8465C82.7536 12.9155 82.6287 13.0309 82.5466 13.1762C82.3327 13.5568 82.4637 14.0409 82.8398 14.2577C83.2058 14.4377 83.5917 14.5721 83.99 14.6579C84.2508 14.7281 84.5128 14.7885 84.7741 14.8488C85.1897 14.9447 85.6038 15.0403 86.01 15.1743C86.8033 15.3712 87.4477 15.9549 87.7285 16.7311C87.7414 16.7649 87.7538 16.7991 87.7658 16.8334C88.301 18.3805 87.4957 20.0737 85.9666 20.6156C85.1295 20.9205 84.2366 21.0371 83.3501 20.9575C82.5153 20.9237 81.7035 20.6732 80.9922 20.2301C80.2653 19.8079 79.7444 19.0991 79.554 18.2732V18.135C79.5517 18.1109 79.5517 18.0864 79.554 18.0623L82.0054 17.7567C82.1433 18.3016 82.5434 18.7393 83.0698 18.9205C83.5963 19.1171 84.1753 19.1171 84.7017 18.9205C85.0802 18.8139 85.3438 18.4673 85.3484 18.0694C85.3964 17.6678 85.1405 17.293 84.7519 17.1966C84.4456 17.0962 84.1674 17.0331 83.8713 16.9659C83.6951 16.9259 83.5126 16.8844 83.3142 16.8329C83.1496 16.7901 82.9844 16.7516 82.8195 16.7131C82.4513 16.6271 82.085 16.5416 81.7325 16.411C80.6967 16.0972 79.9813 15.1407 79.9642 14.0468C79.8619 12.9165 80.4676 11.8417 81.4809 11.3553C82.0709 11.0452 82.7218 10.8713 83.3861 10.8461C84.2361 10.7695 85.0917 10.9017 85.8804 11.2316Z",
    fill: "#0F1632"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M104.702 0C104.759 0.0124657 104.816 0.0245993 104.873 0.0367585C105.084 0.081335 105.296 0.126256 105.5 0.189144C107.374 0.730313 108.733 2.37318 108.929 4.33547C108.939 4.53902 108.939 4.743 108.929 4.94656V27.0752C108.944 29.7789 106.791 31.9838 104.119 31.9998H4.79513C2.37302 32.0209 0.315769 30.2107 0 27.7807V4.22641C0.20744 2.41978 1.41617 0.891563 3.11288 0.290922C3.47825 0.171885 3.85035 0.0746312 4.22716 0H104.702ZM104.004 30.756C104.308 30.7597 104.612 30.7305 104.91 30.6686C106.591 30.2966 107.775 28.7718 107.735 27.0314V4.5101C107.554 2.67749 106.047 1.27158 104.227 1.23669H4.74483C2.75959 1.23547 1.14931 2.86295 1.14816 4.87165C1.14816 4.91363 1.14885 4.95551 1.15023 4.99745V26.9879C1.14654 27.2488 1.16812 27.5095 1.21495 27.7662C1.528 29.5189 3.04941 30.7844 4.80946 30.756H104.004Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M114.809 0.982054V0H110.913V0.99656H112.258V4.83741H113.486V0.982054H114.809Z",
    fill: "#0F1632"
  }), _jsx("path", {
    d: "M121.143 4.83741V0H119.583C119.325 0.730456 119.076 1.46095 118.835 2.17006L118.832 2.17762L118.827 2.19427C118.708 2.54313 118.591 2.88676 118.476 3.22258C118.117 2.18231 117.735 1.09839 117.347 0H115.679V4.83741H116.844V1.69492C117.189 2.72782 117.549 3.78996 117.908 4.84469H118.871L119.943 1.67314V4.83741H121.143Z",
    fill: "#0F1632"
  })]
}), 'BrandlessLogo', 'Company');
export default BrandlessLogo;