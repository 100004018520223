/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DeepnoteLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 151,
  height: 32,
  viewBox: "0 0 151 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#DeepnoteLogo_svg__clip0_12332_178348)",
    children: [_jsx("path", {
      d: "M34.71 7.33325H41.73C47.684 7.33325 50.4183 10.3666 50.4183 15.8331C50.4183 21.2996 47.138 24.6211 41.73 24.6211H34.71V7.33325ZM37.9881 10.0698V21.9019H41.47C45.526 21.9019 47.1358 19.7114 47.1358 15.8223C47.1358 11.7598 45.4393 10.0589 41.2858 10.0589H37.9881V10.0698Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M52.0542 18.0128C52.0542 13.4325 54.6434 10.7241 58.8727 10.7241C62.9569 10.7241 64.9394 13.2028 64.9394 16.9186C64.9394 17.4365 64.9394 18.0431 64.883 18.7343H55.133C55.3627 21.1826 56.5999 22.4176 58.9009 22.4176C61.0675 22.4176 61.8064 21.3798 62.179 20.0841L64.8267 20.8338C64.194 23.2821 62.4975 24.9505 58.8727 24.9505C54.73 24.957 52.0542 22.5368 52.0542 18.0128ZM55.1612 16.65H62.0945C61.9797 14.3165 60.944 13.1053 58.786 13.1053C56.6865 13.1161 55.4494 14.2385 55.1612 16.65Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M66.6683 18.0128C66.6683 13.4325 69.2575 10.7241 73.4868 10.7241C77.571 10.7241 79.5535 13.2028 79.5535 16.9186C79.5535 17.4365 79.5535 18.0431 79.4972 18.7343H69.7472C69.9768 21.1826 71.214 22.4176 73.515 22.4176C75.6817 22.4176 76.4205 21.3798 76.7932 20.0841L79.4408 20.8338C78.8082 23.2821 77.1117 24.9505 73.4868 24.9505C69.3442 24.957 66.6683 22.5368 66.6683 18.0128ZM69.7667 16.65H76.7C76.5852 14.3165 75.5495 13.1053 73.3915 13.1053C71.3007 13.1161 70.0635 14.2385 69.7667 16.65Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M90.0554 24.9851C87.5811 24.9851 85.9994 23.371 85.3386 21.0093H85.1371V29H82.0582V11.0708H85.1371V14.5851H85.3386C86.0579 12.2516 87.5529 10.7241 89.9991 10.7241C93.1927 10.7241 94.9824 13.2591 94.9824 17.8741C94.9824 22.4891 93.1061 24.9851 90.0554 24.9851ZM91.9252 17.8698C91.9252 14.9318 90.8896 13.5191 88.6167 13.5191C86.4891 13.5191 85.1371 15.0185 85.1371 17.3801V18.418C85.1371 20.6951 86.5172 22.2508 88.5886 22.2508C90.8614 22.2486 91.9252 20.7796 91.9252 17.8698Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M100.499 24.6103H97.4198V11.0686H100.44V14.6414H100.7C101.448 12.1346 103.174 10.7219 105.447 10.7219C108.264 10.7219 109.618 12.6243 109.618 15.5623V24.6103H106.539V16.3769C106.539 14.7346 105.82 13.5234 103.939 13.5234C101.868 13.5234 100.488 14.9058 100.488 16.9511V24.6103H100.499Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M111.919 17.8113C111.919 13.3458 114.595 10.7241 118.794 10.7241C122.937 10.7241 125.613 13.3458 125.613 17.8113C125.613 22.2768 123.082 24.9851 118.794 24.9851C114.45 24.9851 111.919 22.2486 111.919 17.8113ZM115.026 17.7831C115.026 20.5781 116.177 22.3071 118.766 22.3071C121.355 22.3071 122.534 20.5738 122.534 17.7831C122.534 14.9925 121.268 13.3458 118.766 13.3458C116.263 13.3458 115.026 14.9881 115.026 17.7831Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M128.834 13.5755H126.936V11.0709H128.834V7.41138H131.95V11.0709H135.919V13.5777H131.95V20.0604C131.95 21.3864 132.44 22.0775 133.792 22.0775C134.456 22.0632 135.113 21.9462 135.742 21.7309L136.117 24.4977C135.138 24.8417 134.105 25.0075 133.068 24.9874C130.106 24.9874 128.839 23.0374 128.839 20.4937V13.5755H128.834Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M137.523 18.0128C137.523 13.4325 140.112 10.7241 144.341 10.7241C148.425 10.7241 150.408 13.2028 150.408 16.9186C150.408 17.4365 150.408 18.0431 150.352 18.7343H140.602C140.831 21.1826 142.068 22.4176 144.369 22.4176C146.536 22.4176 147.275 21.3798 147.648 20.0841L150.295 20.8338C149.663 23.2821 147.966 24.9505 144.341 24.9505C140.196 24.957 137.523 22.5368 137.523 18.0128ZM140.63 16.65H147.563C147.448 14.3165 146.413 13.1053 144.255 13.1053C142.153 13.1161 140.916 14.2385 140.63 16.65Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M7.64617 18.6672L0 29.0001H10.8767C10.8767 29.0001 14.313 27.2212 14.95 24.0687C15.587 20.9162 12.4453 18.6672 7.64617 18.6672Z",
      fill: "#1560AB"
    }), _jsx("path", {
      d: "M17.1773 21.4341C17.1773 21.3821 17.1773 21.3322 17.1773 21.2824C17.1773 21.1719 17.1578 21.0657 17.1448 20.9596C17.1448 20.9054 17.1318 20.8491 17.1231 20.7949C17.1101 20.6952 17.0928 20.5977 17.0754 20.5024C17.0754 20.4526 17.056 20.4006 17.0451 20.3507C17.0234 20.2446 16.9975 20.1341 16.9715 20.0409C16.9715 20.0062 16.9541 19.9694 16.9455 19.9369C16.9065 19.8026 16.8653 19.6726 16.8198 19.5491C16.8198 19.5166 16.796 19.4862 16.783 19.4537C16.7483 19.3613 16.7114 19.2703 16.6725 19.1807C16.653 19.1374 16.6313 19.0941 16.6118 19.0529C16.5757 18.9763 16.5388 18.9041 16.5013 18.8362L16.4298 18.7084C16.3886 18.6369 16.3475 18.5676 16.302 18.4917C16.2565 18.4159 16.2565 18.4181 16.2326 18.3812C16.172 18.2931 16.1091 18.2065 16.0441 18.1212C16.0441 18.1061 16.0225 18.0887 16.0095 18.0736C15.9401 17.9847 15.8665 17.8981 15.7928 17.8136L15.7018 17.7182C15.6498 17.6619 15.5978 17.6077 15.5436 17.5557L15.4353 17.4539L15.275 17.3131L15.1623 17.2177C15.1038 17.1701 15.0431 17.1246 14.9846 17.0812L14.8785 17.0011C14.7853 16.9361 14.6921 16.8732 14.5968 16.8126L14.4733 16.7367L14.2913 16.6306L14.1418 16.5482L13.9771 16.4637L13.8146 16.3857L13.65 16.3142L13.4788 16.2406L13.3228 16.1777C13.2015 16.1301 13.0801 16.0846 12.9566 16.0434L12.831 16.0001L12.6143 15.9307L12.4908 15.8939L12.2741 15.8311L12.1615 15.8007C12.0813 15.7812 12.0011 15.7596 11.921 15.7422L11.8473 15.7249C11.44 15.6317 11.0261 15.5581 10.6166 15.5082C10.4 15.4801 10.1833 15.4562 9.96662 15.4346L7.64612 18.6672C12.4453 18.6672 15.5848 20.9162 14.95 24.0687C14.3151 27.2212 10.8766 29.0001 10.8766 29.0001H10.894C11.2645 28.9264 17.1925 27.6329 17.1925 21.9324C17.1925 21.7591 17.186 21.5944 17.1773 21.4341Z",
      fill: "#012447"
    }), _jsx("path", {
      d: "M25.6642 12.2733C23.595 3.6695 12.5797 3 9.3405 3H8.4955L1.46033 13.2137L0 15.3327H7.81733H8.27667H8.37633C8.71 15.3327 9.05233 15.3543 9.4055 15.3782L9.9645 15.4237C10.1812 15.4453 10.3978 15.4692 10.6145 15.4973C11.024 15.5537 11.4378 15.6273 11.8452 15.714L11.9188 15.7313C11.999 15.7487 12.0792 15.7703 12.1593 15.7898L12.272 15.8202L12.4887 15.883L12.6122 15.9198L12.8288 15.9892L12.9458 16.0303C13.0693 16.0715 13.1907 16.117 13.312 16.1647L13.468 16.2275L13.6392 16.3012L13.7995 16.3748L13.962 16.4528L14.1267 16.5373L14.2762 16.6197L14.4582 16.7258L14.5817 16.8017C14.677 16.8623 14.7702 16.9252 14.8633 16.9902L14.9695 17.0703C15.028 17.1137 15.0887 17.1592 15.1472 17.2068L15.2598 17.3022L15.4202 17.443L15.5285 17.5448C15.5827 17.5968 15.6347 17.651 15.6867 17.7073L15.7777 17.8027C15.8535 17.8872 15.9272 17.9738 15.9943 18.0627C16.0073 18.0778 16.0182 18.0952 16.029 18.1103C16.094 18.1941 16.1568 18.2808 16.2175 18.3703C16.2413 18.4072 16.2652 18.444 16.2868 18.4808C16.3085 18.5177 16.3735 18.6195 16.4147 18.6975L16.4862 18.8253C16.5237 18.8976 16.5606 18.9698 16.5967 19.042C16.6162 19.0832 16.6378 19.1265 16.6573 19.1698C16.6963 19.2579 16.7332 19.3489 16.7678 19.4428C16.7808 19.4753 16.7938 19.5057 16.8047 19.5382C16.8502 19.6617 16.8913 19.7917 16.9303 19.926C16.9303 19.9585 16.9477 19.9953 16.9563 20.03C16.9823 20.1318 17.0083 20.2337 17.03 20.3398C17.03 20.3897 17.0517 20.4417 17.0603 20.4915C17.0777 20.5868 17.095 20.6843 17.108 20.784C17.108 20.8382 17.1232 20.8945 17.1297 20.9487C17.1427 21.0548 17.1535 21.1653 17.1622 21.2715C17.1622 21.3213 17.1622 21.3712 17.1622 21.4232C17.1622 21.5835 17.1773 21.7482 17.1773 21.9172C17.1773 27.6177 11.2493 28.9112 10.8788 28.9848H10.8615H10.9048C11.791 28.9458 25.9848 28.1182 25.9848 15.2503C25.9946 14.2489 25.887 13.2497 25.6642 12.2733Z",
      fill: "#3793EF"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "DeepnoteLogo_svg__clip0_12332_178348",
      children: _jsx("rect", {
        width: 150.408,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'DeepnoteLogo', 'Company');
export default DeepnoteLogo;