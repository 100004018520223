/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createCompanyLogosComponent from '../../utils/createCompanyLogosComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CampingWorldHomeLogo = createCompanyLogosComponent(_jsxs("svg", {
  width: 203,
  height: 32,
  viewBox: "0 0 203 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#CampingWorldHomeLogo_svg__clip0_18194_4640)",
    children: [_jsx("path", {
      d: "M27.388 25.5504L25.966 21.5694H19.9927L18.5999 25.5504H14.4471L21.5865 6.2998H24.628L31.7675 25.5504H27.388ZM23.5789 14.2325C23.3778 13.6952 23.1804 12.975 23.0086 12.2877H22.9538C22.8112 12.975 22.5845 13.6952 22.3835 14.2325L21.0748 18.0636H24.8876L23.5789 14.2325Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M49.5851 25.5503L48.9893 18.5132C48.9052 17.4055 48.8175 16.2065 48.8175 14.9818H48.7626C48.3349 16.2101 47.7683 17.4969 47.2273 18.6046L43.8129 25.6088H42.0509L38.6109 18.6046C38.0699 17.4969 37.5289 16.2394 37.0756 15.0111H37.0171C37.0171 16.2686 36.933 17.4384 36.8453 18.5132L36.275 25.5503H32.0381L33.6027 6.44958H37.1852L41.1406 14.4737C41.6817 15.5814 42.4786 17.2886 42.9611 18.5461H43.016C43.4729 17.2886 44.1273 15.7934 44.7524 14.4737L48.6786 6.44958H52.2903L53.9098 25.5503H49.5851Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M61.6597 18.0343H59.2433V25.5503H55.0064V6.44958H61.6633C64.7926 6.44958 67.5233 8.63566 67.5233 12.2291C67.516 15.819 64.7852 18.0343 61.6597 18.0343ZM61.0346 10.1016H59.2433V14.3823H61.0346C62.1715 14.3823 63.3084 13.7243 63.3084 12.2255C63.3084 10.7888 62.1715 10.1016 61.0346 10.1016Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M68.6237 25.5503V6.44958H73.0324V25.5503H68.6237Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M89.0477 25.7587L81.996 17.6139C80.8591 16.2979 79.5796 14.6784 78.6108 13.4209L78.556 13.4501C78.6108 14.7991 78.6401 16.265 78.6401 17.464V25.5466H74.2314V6.44958H77.7847L83.9298 13.5744C84.7816 14.5614 86.0647 16.1187 86.8872 17.2264L86.9457 17.1972C86.8872 16.0603 86.8616 14.832 86.8616 13.6329V6.44958H91.2703V25.7623H89.0477V25.7587Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M102.19 26C97.1851 26 92.1221 22.5271 92.1221 16.0018C92.1221 9.41437 97.2985 6.00366 102.076 6.00366C104.606 6.00366 106.598 6.66168 107.849 7.53172L107.509 11.6041C106.258 10.6463 104.522 10.0176 102.533 10.0176C99.2323 10.0176 96.5015 12.3535 96.5015 16.1261C96.5015 19.7196 99.0897 22.0519 102.391 22.0519C103.444 22.0519 104.241 21.8728 104.866 21.4853V18.7911H101.565V15.2268H108.901V24.0223C107.366 25.3127 105.118 26 102.19 26Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M132.736 25.6709H129.921L127.275 17.2264C126.876 15.9396 126.478 14.6528 126.079 12.9749H126.021C125.622 14.6492 125.224 15.9689 124.825 17.2264L122.179 25.6709H119.364L113.307 6.44958H117.543L119.905 14.1739C120.274 15.3438 120.618 16.5684 120.874 18.0051H120.932C121.217 16.5684 121.528 15.3693 121.897 14.1739L124.284 6.44958H128.097L130.459 14.1447C130.773 15.1939 131.113 16.6305 131.398 18.0087H131.453C131.709 16.6305 132.078 15.2853 132.422 14.1776L134.838 6.45324H138.79L132.736 25.6709Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M147.202 26C142.197 26 137.445 22.136 137.445 15.9726C137.445 9.86402 142.193 6.00366 147.202 6.00366C152.235 6.00366 156.984 9.86402 156.984 15.9726C156.988 22.136 152.235 26 147.202 26ZM147.202 10.0724C144.471 10.0724 141.912 12.4083 141.912 15.9689C141.912 19.5624 144.471 21.8984 147.202 21.8984C149.932 21.8984 152.521 19.5624 152.521 15.9689C152.521 12.4083 149.932 10.0724 147.202 10.0724Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M168.942 25.5503L164.05 18.0343H162.486V25.5503H158.249V6.44958H165.019C168.119 6.44958 170.879 8.63566 170.879 12.2291C170.879 14.4737 169.629 16.2723 168.094 17.018L173.866 25.5503H168.942ZM164.365 10.1016H162.489V14.3823H164.365C165.502 14.3823 166.639 13.7243 166.639 12.2255C166.639 10.7888 165.502 10.1016 164.365 10.1016Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M173.943 25.5503V6.44958H178.351V21.5693H185.633V25.5503H173.943Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M192.374 25.5503H186.258V6.44958H192.374C197.606 6.44958 202.102 10.0431 202.102 16.0018C202.102 21.9605 197.606 25.5503 192.374 25.5503ZM191.863 10.4306H190.499V21.5693H191.863C195.021 21.5693 197.693 19.7122 197.693 16.0018C197.693 12.2876 195.017 10.4306 191.863 10.4306Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M10.5502 21.9569C7.11022 21.9569 4.46353 19.5917 4.46353 15.9982C4.46353 12.3754 7.11022 10.0102 10.4076 10.0102C12.3707 10.0102 13.7927 10.5805 14.7322 11.2093L15.0466 7.16615C13.9645 6.50813 12.1733 6 9.89947 6C5.15079 6 0 9.41437 0 15.9982C0 22.5564 5.11789 25.9963 9.89947 25.9963C11.2557 25.9963 12.3597 25.8465 13.248 25.5833L15.0137 20.82C14.0157 21.5109 12.5096 21.9569 10.5502 21.9569Z",
      fill: "#0C67A1"
    }), _jsx("path", {
      d: "M200.548 25.4187C199.952 25.4187 199.492 24.9508 199.492 24.3184C199.492 23.6786 199.949 23.218 200.548 23.218C201.141 23.218 201.605 23.6786 201.605 24.3184C201.605 24.9508 201.141 25.4187 200.548 25.4187ZM200.548 23.4556C200.084 23.4556 199.733 23.8139 199.733 24.3184C199.733 24.8155 200.084 25.1738 200.548 25.1738C201.005 25.1738 201.356 24.8119 201.356 24.3184C201.36 23.8139 201.009 23.4556 200.548 23.4556ZM200.852 24.9691L200.541 24.4829H200.45V24.9581H200.172V23.675H200.563C200.775 23.675 200.991 23.8249 200.991 24.0807C200.991 24.2233 200.914 24.3403 200.815 24.4061L201.093 24.8119L200.852 24.9691ZM200.537 23.9272H200.45V24.2306H200.537C200.625 24.2306 200.698 24.1831 200.698 24.0807C200.698 23.9747 200.625 23.9272 200.537 23.9272Z",
      fill: "#0C67A1"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "CampingWorldHomeLogo_svg__clip0_18194_4640",
      children: _jsx("rect", {
        width: 202.102,
        height: 32,
        fill: "white"
      })
    })
  })]
}), 'CampingWorldHomeLogo', 'Company');
export default CampingWorldHomeLogo;